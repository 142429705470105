import styled from "@emotion/styled";
import { Stack, Paper, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { ReactNode } from "react";

interface PopupProps {
  children: ReactNode;
  onMouseEnter?: React.MouseEventHandler<HTMLDivElement>;
  onMouseLeave?: React.MouseEventHandler<HTMLDivElement>;
}

export function Popup({ children, onMouseEnter, onMouseLeave }: PopupProps) {
  return (
    <Paper onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      <Stack
        sx={{
          padding: "1rem",
          gap: "1rem",
          minWidth: "320px",
          maxWidth: "400px",
        }}
      >
        {children}
      </Stack>
    </Paper>
  );
}
