import { Box } from "@mui/material";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { ReactNode } from "react";

interface BannerProps {
  icon: ReactNode;
  title?: ReactNode;
  subtitle: ReactNode;
  variant?: "warning";
}

export function Banner(props: BannerProps) {
  const { icon, subtitle, title, variant } = props;
  return (
    <Paper
      variant="outlined"
      sx={{
        padding: 1.5,
        display: "flex",
        alignItems: "center",
        mb: 3,
        border: "2px solid black",
        backgroundColor: variant ? "#ffdc9c" : "#ffffff",
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center", mr: 1 }}>{icon}</Box>
      {title && (
        <Typography
          variant="subtitle1"
          component="div"
          sx={{ fontWeight: "bold", display: "inline-block", marginRight: 1 }}
        >
          {title}
        </Typography>
      )}

      {subtitle}
    </Paper>
  );
}
