import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { UserAvatar } from "./InlineUser";

interface UserProps {
  fullName: string;
  companyName: string;
  onClick: (event: React.MouseEvent<HTMLElement>) => void;
}

export function User(props: UserProps) {
  const { companyName, fullName, onClick } = props;
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        ".MuiAvatar-root": {
          mr: 1.5,
        },
      }}
      onClick={onClick}
    >
      <UserAvatar name={fullName} />
      <Box
        sx={{
          mr: 1.5,
        }}
      >
        <Typography variant="body1" component="p">
          {fullName}
        </Typography>
        <Typography variant="body1" component="p">
          {companyName}
        </Typography>
      </Box>
      <KeyboardArrowDownIcon />
    </Box>
  );
}
