import { enqueueSnackbar } from "notistack";
import { useMutation, useQueryClient } from "react-query";
import { axiosClient } from "src/lib/axiosClient";
import {
  enqueueSavingSnackbar,
  enqueueSuccessSnackbar,
} from "src/shared/snackbar/SnackbarHelper";
import { useClaimId } from "./useClaimId";
import { AssessmentAttachment } from "@athena/server/src/api/types/claimAssessment";
import { CapacitorHttp } from "@capacitor/core";
import { config } from "src/lib/config";

export const useAttachmentUploads = (
  queryKey: string,
  onDeleteComplete?: (id: string) => void
) => {
  const queryClient = useQueryClient();
  const claimId = useClaimId();

  const { mutate: deleteUpload } = useMutation(
    async (attachmentId: string): Promise<undefined> => {
      const finishedSaving = enqueueSavingSnackbar("Deleting");

      const res = (
        await axiosClient.delete(
          `/claims/${claimId}/attachment/${attachmentId}`
        )
      ).data;
      finishedSaving();
      enqueueSuccessSnackbar("Deleted");

      return res;
    },
    {
      onSuccess: (data, variables) => {
        onDeleteComplete?.(variables);
      },
      onError: () => {
        enqueueSnackbar("Something went wrong", { variant: "error" });
      },
      onSettled: () => {
        queryClient.invalidateQueries({
          queryKey: [queryKey],
        });
      },
    }
  );

  const { mutate: updateAttachment } = useMutation(
    async (attachment: Partial<AssessmentAttachment>): Promise<undefined> => {
      const finishedSaving = enqueueSavingSnackbar("Deleting");

      const res = (
        await axiosClient.put(
          `/claims/${claimId}/attachment/${attachment.assessmentAttachmentId}`,
          attachment
        )
      ).data;
      finishedSaving();
      enqueueSuccessSnackbar("Deleted");

      return res;
    },
    {
      onError: () => {
        enqueueSnackbar("Something went wrong", { variant: "error" });
      },
      onSettled: () => {
        queryClient.invalidateQueries({
          queryKey: [queryKey],
        });
      },
    }
  );
  const uploadAttachment = async (
    file: any,
    featureId?: string,
    id?: string
  ) => {
    const formData = new FormData();
    formData.append("image", file);
    if (featureId) {
      formData.append("featureId", featureId);
    }

    if (id) {
      formData.append("id", id);
    }
    const res = await fetch(`${config.apiUrl}/claims/${claimId}/attachment`, {
      method: "POST",
      // dataType: "file",
      body: formData,
      credentials: "include",
      // headers: {
      //   "Content-Type": "multipart/form-data",
      // },
    });
    const data = await res.json();
    queryClient.invalidateQueries({
      queryKey: [queryKey],
    });

    return data;
  };

  return { uploadAttachment, deleteUpload, updateAttachment };
};
