import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from "@mui/material";
import { Box, Stack } from "@mui/system";
import { JSONContent } from "@tiptap/core";
import { useRef, useState } from "react";
import { InlineEditor, InlineEditorRef } from "../InlineEditor";
import { TemplateVariables } from "../TemplateVariables";

type SummaryTableProps = {
  showModal: boolean;
  onClose: VoidFunction;
  setDirty: (state: boolean) => void;
  onSave: (context: Context) => void;
  context: Context;
};

type Context = {
  isThisNaturalDisasterDamageText: string;
  landWithin8mOfDwellingOrAppurtenantStructuresText: string;
  areaOfInsuredLandDamagedText: string;
  areaOfInsuredLandDamagedEvacuatedText: string;
  areaOfInsuredLandDamagedInundatedText: string;
  mainAccessWayWithin60mOfDwellingText: string;
  insuredAreaOfInsuredLandDamagedOnOrSupportingMainAccessWayText: string;
  insuredAreaOfInsuredLandDamagedOnOrSupportingMainAccessWayEvacuatedText: string;
  insuredAreaOfInsuredLandDamagedOnOrSupportingMainAccessWayInundatedText: string;
  insuredAreaOfInsuredLandAtImminentRiskOnOrSupportingMainAccessWayText: string;
  insuredAreaOfInsuredLandAtImminentRiskOnOrSupportingMainAccessWayEvacuationText: string;
  insuredAreaOfInsuredLandAtImminentRiskOnOrSupportingMainAccessWayNewInundationText: string;
  insuredAreaOfInsuredLandAtImminentRiskOnOrSupportingMainAccessWayReInundationText: string;
  retainingWallsSectionText: string;
  retainingWallsReferText: string;
  dwellingAndAppurtenantStructuresText: string;
  dwellingOrAppurtenantDamagedFromNaturalDisasterText: string;
  costToRepairText: string;
  isDamageToDwellingOrAppurtenantImminentFromNaturalDisasterText: string;
  costToRemoveImminentLossThreatText: string;
  valueOfImminentRiskDamageText: string;
  insuredAreaOfInsuredLandAtImminentRisk: string;
  insuredAreaOfInsuredLandAtImminentRiskEvacuation: string;
  insuredAreaOfInsuredLandAtImminentRiskNewInundation: string;
  insuredAreaOfInsuredLandAtImminentRiskReInundation: string;
  servicesText: string;
  servicesDamageText: string;
  serviceDamaged: JSONContent | string;
  serviceAtImminentRisk: JSONContent | string;
  bridgesOrCulvertsOnInsuredLandText: string;
  bridgesOrCulvertsReferText: string;
  conceptualRemedialWorksText: string;
  serviceAtImminentRiskText: string;
};

const emptyContext: Context = {
  isThisNaturalDisasterDamageText: "Is this natural disaster damage?",
  landWithin8mOfDwellingOrAppurtenantStructuresText:
    "Land within 8 m of dwelling or appurtenant structures ",
  areaOfInsuredLandDamagedText: "Area of insured land damaged",
  areaOfInsuredLandDamagedEvacuatedText: "Evacuated:",
  areaOfInsuredLandDamagedInundatedText: "Inundated:",
  insuredAreaOfInsuredLandAtImminentRisk:
    "Insured area of insured land at imminent risk",
  insuredAreaOfInsuredLandAtImminentRiskEvacuation: "Evacuation:",
  insuredAreaOfInsuredLandAtImminentRiskNewInundation: "New Inundation:",
  insuredAreaOfInsuredLandAtImminentRiskReInundation: "Re-inundation:",
  mainAccessWayWithin60mOfDwellingText:
    "Main access way within 60 m of dwelling ",
  insuredAreaOfInsuredLandDamagedOnOrSupportingMainAccessWayText:
    "Insured area of insured land damaged on or supporting main access way:",
  insuredAreaOfInsuredLandDamagedOnOrSupportingMainAccessWayEvacuatedText:
    "Evacuated: ",
  insuredAreaOfInsuredLandDamagedOnOrSupportingMainAccessWayInundatedText:
    "Inundated:",
  insuredAreaOfInsuredLandAtImminentRiskOnOrSupportingMainAccessWayText:
    "Insured area of insured land at imminent risk on or supporting main access way:",
  insuredAreaOfInsuredLandAtImminentRiskOnOrSupportingMainAccessWayEvacuationText:
    "Evacuation:",
  insuredAreaOfInsuredLandAtImminentRiskOnOrSupportingMainAccessWayNewInundationText:
    "New Inundation:",
  insuredAreaOfInsuredLandAtImminentRiskOnOrSupportingMainAccessWayReInundationText:
    "Re-inundation:",
  retainingWallsSectionText:
    "Retaining Walls supporting or protecting insured buildings and/or land located within 60 m of Dwelling (or an appurtenant Structure)",
  retainingWallsReferText: "Refer to retaining wall summary below",
  dwellingAndAppurtenantStructuresText: "Dwelling and appurtenant structure(s)",
  dwellingOrAppurtenantDamagedFromNaturalDisasterText:
    "Has the dwelling or appurtenant structure been damaged as a result of the natural disaster?",
  costToRepairText: "Cost to repair damage",
  isDamageToDwellingOrAppurtenantImminentFromNaturalDisasterText:
    "Is damage to the dwelling (or appurtenant structure) imminent as the direct result of a natural disaster?",
  costToRemoveImminentLossThreatText:
    "Cost to remove imminent loss threat to dwelling (or appurtenant structure)",
  valueOfImminentRiskDamageText:
    "Value of imminent risk damage to dwelling (or appurtenant structures)",
  servicesText: "Services within 60 m of dwelling",
  servicesDamageText: "Services Damaged:",
  serviceDamaged: "Placeholder",
  serviceAtImminentRiskText: "Services at imminent risk:",
  serviceAtImminentRisk: "Placeholder",
  bridgesOrCulvertsOnInsuredLandText:
    "Bridges or culverts situated on insured land",
  bridgesOrCulvertsReferText: "Refer to bridge and culvert summary below",
  conceptualRemedialWorksText: "Conceptual remedial works:",
};

export const SummaryTable = ({
  showModal,
  onClose,
  setDirty,
  onSave,
  context,
}: SummaryTableProps) => {
  const [texts, setTexts] = useState(context || emptyContext);
  const servicesRef = useRef<InlineEditorRef>(null);
  const servicesAtImminentRiskRef = useRef<InlineEditorRef>(null);
  `
  `;
  const handleChange = (change: Partial<typeof texts>) => {
    setTexts({ ...texts, ...change });
    setDirty(true);
  };

  return (
    <>
      {showModal && (
        <Dialog
          open={showModal}
          onClose={onClose}
          maxWidth="lg"
          sx={{ " .MuiPaper-root": { flexWrap: "nowrap" } }}
        >
          <DialogTitle>Summary of Information</DialogTitle>
          <DialogContent>
            <Stack>
              <TextField
                value={texts.isThisNaturalDisasterDamageText}
                onChange={(e) =>
                  handleChange({
                    isThisNaturalDisasterDamageText: e.target.value,
                  })
                }
              />
              <TextField
                value={texts.landWithin8mOfDwellingOrAppurtenantStructuresText}
                onChange={(e) =>
                  handleChange({
                    landWithin8mOfDwellingOrAppurtenantStructuresText:
                      e.target.value,
                  })
                }
              />
              <TextField
                value={texts.areaOfInsuredLandDamagedText}
                onChange={(e) =>
                  handleChange({
                    areaOfInsuredLandDamagedText: e.target.value,
                  })
                }
              />
              <TextField
                value={texts.areaOfInsuredLandDamagedEvacuatedText}
                onChange={(e) =>
                  handleChange({
                    areaOfInsuredLandDamagedEvacuatedText: e.target.value,
                  })
                }
              />
              <TextField
                value={texts.areaOfInsuredLandDamagedInundatedText}
                onChange={(e) =>
                  handleChange({
                    areaOfInsuredLandDamagedInundatedText: e.target.value,
                  })
                }
              />
              <TextField
                value={texts.insuredAreaOfInsuredLandAtImminentRisk}
                onChange={(e) =>
                  handleChange({
                    insuredAreaOfInsuredLandAtImminentRisk: e.target.value,
                  })
                }
              />
              <TextField
                value={texts.insuredAreaOfInsuredLandAtImminentRiskEvacuation}
                onChange={(e) =>
                  handleChange({
                    insuredAreaOfInsuredLandAtImminentRiskEvacuation:
                      e.target.value,
                  })
                }
              />
              <TextField
                value={
                  texts.insuredAreaOfInsuredLandAtImminentRiskNewInundation
                }
                onChange={(e) =>
                  handleChange({
                    insuredAreaOfInsuredLandAtImminentRiskNewInundation:
                      e.target.value,
                  })
                }
              />
              <TextField
                value={texts.insuredAreaOfInsuredLandAtImminentRiskReInundation}
                onChange={(e) =>
                  handleChange({
                    insuredAreaOfInsuredLandAtImminentRiskReInundation:
                      e.target.value,
                  })
                }
              />
              <TextField
                value={texts.mainAccessWayWithin60mOfDwellingText}
                onChange={(e) =>
                  handleChange({
                    mainAccessWayWithin60mOfDwellingText: e.target.value,
                  })
                }
              />
              <TextField
                value={
                  texts.insuredAreaOfInsuredLandDamagedOnOrSupportingMainAccessWayText
                }
                onChange={(e) =>
                  handleChange({
                    insuredAreaOfInsuredLandDamagedOnOrSupportingMainAccessWayText:
                      e.target.value,
                  })
                }
              />
              <TextField
                value={
                  texts.insuredAreaOfInsuredLandDamagedOnOrSupportingMainAccessWayEvacuatedText
                }
                onChange={(e) =>
                  handleChange({
                    insuredAreaOfInsuredLandDamagedOnOrSupportingMainAccessWayEvacuatedText:
                      e.target.value,
                  })
                }
              />
              <TextField
                value={
                  texts.insuredAreaOfInsuredLandDamagedOnOrSupportingMainAccessWayInundatedText
                }
                onChange={(e) =>
                  handleChange({
                    insuredAreaOfInsuredLandDamagedOnOrSupportingMainAccessWayInundatedText:
                      e.target.value,
                  })
                }
              />
              <TextField
                value={
                  texts.insuredAreaOfInsuredLandAtImminentRiskOnOrSupportingMainAccessWayText
                }
                onChange={(e) =>
                  handleChange({
                    insuredAreaOfInsuredLandAtImminentRiskOnOrSupportingMainAccessWayText:
                      e.target.value,
                  })
                }
              />
              <TextField
                value={
                  texts.insuredAreaOfInsuredLandAtImminentRiskOnOrSupportingMainAccessWayEvacuationText
                }
                onChange={(e) =>
                  handleChange({
                    insuredAreaOfInsuredLandAtImminentRiskOnOrSupportingMainAccessWayEvacuationText:
                      e.target.value,
                  })
                }
              />
              <TextField
                value={
                  texts.insuredAreaOfInsuredLandAtImminentRiskOnOrSupportingMainAccessWayNewInundationText
                }
                onChange={(e) =>
                  handleChange({
                    insuredAreaOfInsuredLandAtImminentRiskOnOrSupportingMainAccessWayNewInundationText:
                      e.target.value,
                  })
                }
              />
              <TextField
                value={
                  texts.insuredAreaOfInsuredLandAtImminentRiskOnOrSupportingMainAccessWayReInundationText
                }
                onChange={(e) =>
                  handleChange({
                    insuredAreaOfInsuredLandAtImminentRiskOnOrSupportingMainAccessWayReInundationText:
                      e.target.value,
                  })
                }
              />
              <TextField
                value={texts.retainingWallsSectionText}
                onChange={(e) =>
                  handleChange({
                    retainingWallsSectionText: e.target.value,
                  })
                }
              />
              <TextField
                value={texts.retainingWallsReferText}
                onChange={(e) =>
                  handleChange({
                    retainingWallsReferText: e.target.value,
                  })
                }
              />
              <TextField
                value={texts.dwellingAndAppurtenantStructuresText}
                onChange={(e) =>
                  handleChange({
                    dwellingAndAppurtenantStructuresText: e.target.value,
                  })
                }
              />
              <TextField
                value={
                  texts.dwellingOrAppurtenantDamagedFromNaturalDisasterText
                }
                onChange={(e) =>
                  handleChange({
                    dwellingOrAppurtenantDamagedFromNaturalDisasterText:
                      e.target.value,
                  })
                }
              />
              <TextField
                value={texts.costToRepairText}
                onChange={(e) =>
                  handleChange({
                    costToRepairText: e.target.value,
                  })
                }
              />
              <TextField
                value={
                  texts.isDamageToDwellingOrAppurtenantImminentFromNaturalDisasterText
                }
                onChange={(e) =>
                  handleChange({
                    isDamageToDwellingOrAppurtenantImminentFromNaturalDisasterText:
                      e.target.value,
                  })
                }
              />
              <TextField
                value={texts.costToRemoveImminentLossThreatText}
                onChange={(e) =>
                  handleChange({
                    costToRemoveImminentLossThreatText: e.target.value,
                  })
                }
              />
              <TextField
                value={texts.valueOfImminentRiskDamageText}
                onChange={(e) =>
                  handleChange({
                    valueOfImminentRiskDamageText: e.target.value,
                  })
                }
              />
              <TextField
                value={texts.servicesText}
                onChange={(e) =>
                  handleChange({
                    servicesText: e.target.value,
                  })
                }
              />
              <TextField
                value={texts.servicesDamageText}
                onChange={(e) =>
                  handleChange({
                    servicesDamageText: e.target.value,
                  })
                }
              />{" "}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  border: "1px solid lightgrey",
                  padding: "1rem",
                  borderRadius: 4,
                }}
              >
                <Box sx={{ width: "50%" }}>
                  <Typography variant="h4">For each service damaged</Typography>
                  <InlineEditor
                    content={texts.serviceDamaged}
                    onContentChange={(content) =>
                      setTexts({
                        ...texts,
                        serviceDamaged: content,
                      })
                    }
                    ref={servicesRef}
                  />{" "}
                </Box>{" "}
                <TemplateVariables
                  onVariableClick={(value, label, description, hasConfig) => {
                    if (servicesRef.current) {
                      servicesRef.current.addTemplateVariable(
                        value,
                        label,
                        description,
                        hasConfig
                      );
                    }
                  }}
                />
              </Box>{" "}
              <TextField
                value={texts.serviceAtImminentRiskText}
                onChange={(e) =>
                  handleChange({
                    serviceAtImminentRiskText: e.target.value,
                  })
                }
              />{" "}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  border: "1px solid lightgrey",
                  padding: "1rem",
                  borderRadius: 4,
                }}
              >
                <Box sx={{ width: "50%" }}>
                  <Typography variant="h4">
                    For each service at imminent risk
                  </Typography>
                  <InlineEditor
                    content={texts.serviceAtImminentRisk}
                    onContentChange={(content) =>
                      setTexts({
                        ...texts,
                        serviceAtImminentRisk: content,
                      })
                    }
                    ref={servicesAtImminentRiskRef}
                  />
                </Box>{" "}
                <TemplateVariables
                  onVariableClick={(value, label, description, hasConfig) => {
                    if (servicesAtImminentRiskRef.current) {
                      servicesAtImminentRiskRef.current.addTemplateVariable(
                        value,
                        label,
                        description,
                        hasConfig
                      );
                    }
                  }}
                />
              </Box>{" "}
              <TextField
                value={texts.bridgesOrCulvertsOnInsuredLandText}
                onChange={(e) =>
                  handleChange({
                    bridgesOrCulvertsOnInsuredLandText: e.target.value,
                  })
                }
              />
              <TextField
                value={texts.bridgesOrCulvertsReferText}
                onChange={(e) =>
                  handleChange({
                    bridgesOrCulvertsReferText: e.target.value,
                  })
                }
              />
              <TextField
                value={texts.conceptualRemedialWorksText}
                onChange={(e) =>
                  handleChange({
                    conceptualRemedialWorksText: e.target.value,
                  })
                }
              />
            </Stack>
          </DialogContent>
          <DialogActions
            sx={{
              display: "flex",
              flexDirection: "row",
              margin: "0 1rem 1rem 1rem",
              alignItems: "flex-end",
            }}
          >
            <Button
              variant="contained"
              onClick={() => {
                onSave(texts);
              }}
              sx={{ width: 200 }}
            >
              Save
            </Button>
            <Button
              variant="outlined"
              onClick={() => {
                onClose();
              }}
              sx={{ width: 200 }}
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};
