import { LoadingSpinner } from "@athena/components";
import {
  TReportTemplate,
  TReportTemplateStyles,
  mergeWithDefaultStyles,
} from "@athena/server/src/api/types/reportTemplate";
import { variables } from "@athena/server/src/api/types/templateVariables";
import styled from "@emotion/styled";
import { Save } from "@mui/icons-material";
import EditIcon from "@mui/icons-material/Edit";
import {
  Button,
  Menu,
  MenuItem,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { JSONContent } from "@tiptap/core";
import { useEffect, useRef, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { axiosClient } from "src/lib/axiosClient";
import { config } from "src/lib/config";
import { useCurrentUserContext } from "src/shared/hooks/useCurrentUserContext";
import {
  enqueueErrorSnackbar,
  enqueueSavingSnackbar,
  enqueueSuccessSnackbar,
} from "src/shared/snackbar/SnackbarHelper";
import { TemplateVariables } from "../../shared/reportBuilder/TemplateVariables";
import {
  TextEditor,
  TextEditorRef,
} from "../../shared/reportBuilder/TextEditor";
import { CSSModal } from "./CSSModal";
import { FooterModal } from "./FooterModal";
import { TemplateModal } from "./TemplateModal";
import { TemplateStylesModal } from "./TemplateStylesModal";

export const ReportTemplate = () => {
  const { templateId } = useParams();

  const [showFooterModal, setShowFooterModal] = useState(false);
  const [showHeaderModal, setShowHeaderModal] = useState(false);
  const [showStylesModal, setShowStylesModal] = useState(false);
  const [showTemplateStylesModal, setShowTemplateStylesModal] = useState(false);

  const { user } = useCurrentUserContext();
  const [template, setTemplate] = useState<JSONContent | string>();
  const [header, setHeader] = useState<JSONContent | string>();
  const [footer, setFooter] = useState<JSONContent | string>();
  const [styles, setStyles] = useState<TReportTemplateStyles>();
  const [editingName, setEditingName] = useState(false);
  const [templateName, setTemplateName] = useState<string>();
  const [css, setCss] = useState<string>();
  const [footerHeight, setFooterHeight] = useState<string>();
  const [usageCounts, setUsageCounts] = useState({});
  const editorRef = useRef<TextEditorRef>(null);
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [otherSettingsEl, setOtherSettingsEl] = useState<null | HTMLElement>(
    null
  );
  const handleOtherSettingsClick = (e: React.MouseEvent<HTMLElement>) => {
    setOtherSettingsEl(otherSettingsEl ? null : e.currentTarget);
  };
  const { isLoading: loadingTemplate, data: reportTemplate } = useQuery(
    `reportTemplates${templateId}`,
    async (): Promise<TReportTemplate> =>
      (await axiosClient.get(`/reportTemplates/${templateId}`)).data,
    {
      onSuccess(data) {
        setTemplate(data.template);
        setHeader(data.header);
        setFooter(data.footer);
        setCss(data.additionalCss);
        setFooterHeight(data.footerHeight);
        setTemplateName(data.name || "Report Template");

        const merged = mergeWithDefaultStyles(data.styles || {});
        setStyles(merged);
      },
      enabled: templateId !== "new",
      cacheTime: 0,
    }
  );

  useEffect(() => {
    (async () => {
      if (templateId === "new") {
        const newTemplate = await axiosClient.post(`/reportTemplates`, {
          template: "",
          isPrimary: false,
        });
        navigate("/admin/reportTemplates/" + newTemplate.data.reportTemplateId);
      }
    })();
  }, []);
  const { mutate: saveTemplate } = useMutation(
    async () => {
      const finishSaving = enqueueSavingSnackbar();
      const res = (
        await axiosClient.put(`/reportTemplates/${templateId}`, {
          template,
          header,
          footer,
          footerHeight,
          styles,
          name: templateName,
          additionalCss: css,
        })
      ).data;
      finishSaving();
      return res;
    },
    {
      onSuccess: () => {
        enqueueSuccessSnackbar();
      },
      onError: () => {
        enqueueErrorSnackbar();
      },
      onSettled: () => {
        queryClient.invalidateQueries({
          queryKey: [`reportTemplates`],
        });
      },
    }
  );

  const updateUsageCounts = () => {
    const templateString = JSON.stringify(template);
    const newUsageCounts = variables.reduce((newUsageCounts, variable) => {
      const regex = new RegExp(`"value":"${variable.value}"`, "g");

      const matches = (templateString.match(regex) || []).length;
      return {
        ...newUsageCounts,
        [variable.value]: matches,
      };
    }, {});
    setUsageCounts(newUsageCounts);
  };
  useEffect(() => {
    if (template) {
      updateUsageCounts();
    }
  }, [template]);

  const uploadAttachment = async (file: any) => {
    const formData = new FormData();
    formData.append("image", file);
    const finishedSaving = enqueueSavingSnackbar();
    const res = (
      await axiosClient.post(`/reportTemplates/${templateId}/image`, formData)
    ).data;
    finishedSaving();
    enqueueSuccessSnackbar("Uploaded");
    return {
      ...res,
      url: `${config.apiUrl}/${res.url}`,
    };
  };
  if (loadingTemplate) return <LoadingSpinner />;
  return (
    <TemplateGrid>
      {showFooterModal && (
        <FooterModal
          content={footer || "Footer"}
          onChange={({ content, footerHeight }) => {
            if (content) {
              setFooter(content);
            }
            setFooterHeight(footerHeight);
          }}
          onClose={() => setShowFooterModal(false)}
          onImageUpload={uploadAttachment}
          title="Edit Footer"
          footerHeight={footerHeight}
        />
      )}
      {showTemplateStylesModal && (
        <TemplateStylesModal
          onSave={(styling) => {
            // setCss(JSON.stringify(styling, null, 2));
            setStyles(styling);
          }}
          styles={styles}
          onClose={() => setShowTemplateStylesModal(false)}
        />
      )}
      {showStylesModal && (
        <CSSModal
          css={css || ""}
          onChange={(css) => {
            setCss(css);
          }}
          onClose={() => setShowStylesModal(false)}
        />
      )}
      {showHeaderModal && (
        <TemplateModal
          content={header || "Header"}
          onChange={(html) => {
            setHeader("<header>" + html + "</header>");
          }}
          onClose={() => setShowHeaderModal(false)}
          onImageUpload={uploadAttachment}
          includeStyles={false}
          title="Edit Header"
        />
      )}
      <Paper sx={{ padding: 2 }}>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Menu
            open={!!otherSettingsEl}
            anchorEl={otherSettingsEl}
            onClose={() => {
              setOtherSettingsEl(null);
            }}
          >
            <MenuItem
              onClick={() => {
                setShowHeaderModal(true);
                setOtherSettingsEl(null);
              }}
            >
              Header
            </MenuItem>
            <MenuItem
              onClick={() => {
                setShowFooterModal(true);
                setOtherSettingsEl(null);
              }}
            >
              Footer
            </MenuItem>
            {/* <MenuItem onClick={() => setShowStylesModal(true)}>Styles</MenuItem> */}
          </Menu>
          <Box
            sx={{
              display: "flex",
              flex: "1 1 auto",
              mr: 2,
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
              {!editingName ? (
                <>
                  <Typography variant="h3" sx={{ ml: 2 }}>
                    {templateName}
                  </Typography>
                  <EditIcon
                    sx={{
                      cursor: "pointer",
                      color: "primary.main",
                    }}
                    onClick={() => {
                      setEditingName(true);
                    }}
                  />
                </>
              ) : (
                <>
                  <TextField
                    value={templateName}
                    onChange={(e) => {
                      setTemplateName(e.target.value);
                    }}
                  />
                  <Save
                    sx={{
                      cursor: "pointer",
                      color: "primary.main",
                    }}
                    onClick={() => {
                      setEditingName(false);
                      saveTemplate();
                    }}
                  />
                </>
              )}
            </Box>
            <Box sx={{ gap: 2, display: "flex" }}>
              <Button
                variant="outlined"
                onClick={() => {
                  setShowTemplateStylesModal(true);
                  setOtherSettingsEl(null);
                }}
              >
                Template Styles
              </Button>
              <Button variant="outlined" onClick={handleOtherSettingsClick}>
                Other Settings
              </Button>
            </Box>
          </Box>
          <Box>
            <Button
              variant="contained"
              sx={{ width: 150 }}
              onClick={() => saveTemplate()}
            >
              Save
            </Button>
            <Button
              variant="outlined"
              sx={{ width: 150, ml: 2 }}
              onClick={() => navigate("/admin/reportTemplates")}
            >
              Back
            </Button>
          </Box>
        </Box>
        {(loadingTemplate || !reportTemplate) && <LoadingSpinner />}
        {!loadingTemplate && reportTemplate && (
          <TextEditor
            className="report-editor"
            styles={styles}
            currentUser={user}
            trackedChangesEnabled={false}
            disableInlineMenu
            content={template as string}
            onContentChange={(content) => setTemplate(content)}
            onImageUpload={uploadAttachment}
            ref={editorRef}
            id={`report-template-editor-${reportTemplate.reportTemplateId}`}
            key={reportTemplate.reportTemplateId}
          />
        )}
      </Paper>
      <Paper
        sx={{
          padding: 2,
          maxHeight: "fit-content",
          height: "fit-content",
          position: "sticky",
          top: "64px",
        }}
      >
        <TemplateVariables
          usageCounts={usageCounts}
          onVariableClick={(value, label, description, hasConfig) => {
            if (editorRef.current) {
              editorRef.current.addTemplateVariable(
                value,
                label,
                description,
                hasConfig
              );
            }
          }}
        />
      </Paper>
    </TemplateGrid>
  );
};

const TemplateGrid = styled.div`
  display: grid;
  grid-template-columns: 3.5fr 1fr;
  gap: 1.5rem;

  @media (max-width: 1200px) {
    grid-template-columns: 1fr;
  }
`;
