export const acceptedImages = [
  "image/png",
  "image/jpeg",
  "image/gif",
  "image/tiff",
  "image/webp",
];

export const acceptedAttachments = acceptedImages.concat([
  "application/pdf",
  "text/csv",
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/epub+zip",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/vnd.ms-excel",
  "text/html",
  "application/json",
  "audio/mpeg",
  "video/mp4",
  "video/mpeg",
  "application/vnd.ms-powerpoint",
  "application/rtf",
  "image/svg+xml",
  "text/plain",
]);
