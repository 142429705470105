import { useQuery } from "react-query";

import { IntelligencerFilter, IntelligencerPit } from "../types";
import { getIntelligencerPitData } from "../getIntelligencerPitData";

export function useIntelligencerPitData<TPit extends IntelligencerPit>(pit: TPit, filter?: IntelligencerFilter) {
  return useQuery(
    [pit, ...Object.values(filter || {})],
    () => getIntelligencerPitData(pit, filter)
  );
}