import TableCell from "@tiptap/extension-table-cell";

export const TableCellExtension = TableCell.extend({
  addAttributes() {
    return {
      ...this.parent?.(),
      backgroundColor: {
        default: "white",
        renderHTML: (attributes) => {
          // … and return an object with HTML attributes.
          return {
            style: ``,
          };
        },
      },
      borderColor: {
        default: "black",
        renderHTML: (attributes) => {
          // … and return an object with HTML attributes.
          return {
            style: ``,
          };
        },
      },
    };
  },
});
