import { Urgency } from "@athena/server/src/api/types/claim";
import { Status } from "@athena/server/src/api/types/claimStatuses";
import styled from "@emotion/styled";
import { zodResolver } from "@hookform/resolvers/zod";
import RemoveIcon from "@mui/icons-material/Remove";
import {
  Autocomplete,
  Button,
  Checkbox,
  FormControlLabel,
  InputAdornment,
  Stack,
  SxProps,
  TextField,
  Theme,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { useCallback, useState } from "react";
import {
  Control,
  Controller,
  FieldValues,
  Path,
  UseFormSetValue,
  UseFormWatch,
  useForm,
} from "react-hook-form";

import { useAthenaMapControlContext } from "src/modules/spatialPortal/mapLib/components/Map";
import { z } from "zod";

interface ClaimFilterProps {
  onChange?: (filters: ClaimFilters) => void;
  filters: ClaimFilters;
  insurerOptions: Array<string>;
  lossAdjustorOptions: Array<string>;
  engineeringCompanyOptions: Array<string>;
  lossCauseOptions: Array<string>;
  assignedOfficeOptions: Array<string>;
  regionalCoordinatorDirectory: Record<string, string[]>;
  referenceOptions: Array<string>;
  insuranceReferenceOptions: Array<string>;
  eventNameOptions: Array<string>;
  assignedEngineerOptions: Array<string>;
  engineeringDirectory: Record<string, string[]>;
  urgencyOptions: Array<Urgency>;
  minimumDate?: Date;
}

export const ClaimFiltersSchema = z.object({
  age: z.number({ coerce: true }).int().nullable(),
  assignedEngineers: z.array(z.string()),
  assignedOffices: z.array(z.string()),
  engineeringCompanies: z.array(z.string()),
  insurers: z.array(z.string()),
  lossAdjustorCompanies: z.array(z.string()),
  lossCauses: z.array(z.string()),
  references: z.array(z.string()),
  insuranceReferences: z.array(z.string()),
  eventNames: z.array(z.string()),
  regionalCoordinators: z.array(z.string()),
  statuses: z.record(z.nativeEnum(Status), z.boolean()),
  urgencies: z.array(z.nativeEnum(Urgency)),
  dates: z.object({
    from: z.date({ coerce: true }).nullable(),
    to: z.date({ coerce: true }).nullable(),
  }),
});

export type ClaimFilters = z.infer<typeof ClaimFiltersSchema>;

export const emptyClaimFilters: ClaimFilters = {
  age: null,
  assignedEngineers: [],
  assignedOffices: [],
  engineeringCompanies: [],
  insurers: [],
  lossAdjustorCompanies: [],
  lossCauses: [],
  references: [],
  regionalCoordinators: [],
  insuranceReferences: [],
  eventNames: [],
  statuses: Object.values(Status).reduce(
    (s, v) => ({ ...s, [v]: false }),
    {} as Record<Status, boolean>
  ),
  urgencies: [],
  dates: {
    from: null,
    to: null,
  },
};

interface ClaimFilterStatusRowProps {
  control: Control<ClaimFilters, any>;
  statuses: Array<Status>;
  headerLabel: string;
  setValue: UseFormSetValue<ClaimFilters>;
}
interface ClaimFilterStatusSelectionButtonProps {
  statuses: Array<Status>;
  watch: UseFormWatch<ClaimFilters>;
  setValue: UseFormSetValue<ClaimFilters>;
}

function ClaimFilterStatusRow({
  statuses,
  control,
  headerLabel,
}: ClaimFilterStatusRowProps) {
  return (
    <ClaimStatusFiltersSection>
      <Stack direction={"row"} justifyContent={"space-between"}>
        <div>
          <ClaimStatusFiltersSectionHeader>
            {headerLabel}
          </ClaimStatusFiltersSectionHeader>
          <Stack direction={"row"} justifyContent={"start"}>
            {statuses.map((status) => (
              <Controller
                key={status}
                render={({ field }) => {
                  return (
                    <FormControlLabel
                      sx={{
                        width: "200px",
                      }}
                      key={status}
                      control={<Checkbox {...field} checked={field.value} />}
                      label={status}
                    />
                  );
                }}
                name={`statuses.${status}`}
                control={control}
              />
            ))}
          </Stack>
        </div>
      </Stack>
    </ClaimStatusFiltersSection>
  );
}

const ClaimFilterStatusSelectionButton = ({
  statuses,
  setValue,
  watch,
}: ClaimFilterStatusSelectionButtonProps) => {
  const deselectAll = useCallback(() => {
    for (const status of statuses) {
      // @ts-expect-error For some reason react-form-hook fails here with `never` type on second argument
      setValue(`statuses.${status}`, false);
    }
  }, [statuses, setValue]);

  const selectAll = useCallback(() => {
    for (const status of statuses) {
      // @ts-expect-error For some reason react-form-hook fails here with `never` type on second argument
      setValue(`statuses.${status}`, true);
    }
  }, [statuses, setValue]);

  const fieldsToWatch = statuses.map((status) => `statuses.${status}`);
  // @ts-expect-error
  const sub = watch(fieldsToWatch) as boolean[];

  return (
    <Button
      variant="outlined"
      sx={{ width: "136px" }}
      onClick={sub.includes(true) ? deselectAll : selectAll}
    >
      {sub.includes(true) ? "Deselect All" : "Select All"}
    </Button>
  );
};

const TextFieldStyle: SxProps<Theme> = {
  marginTop: "1rem",
  ".MuiInputBase-root": {
    width: "450px",
    padding: "6px",
  },
  ".MuiChip-root": {
    height: "28px",
  },
  ".MuiInputBase-input": {},
};

interface ClaimFilterAutocompleteFieldProps<TControl extends FieldValues> {
  control: Control<TControl, any>;
  label: string;
  name: Path<TControl>;
  placeholder: string;
  options: Array<string>;
  multiple?: boolean;
  onValueChange?: (value: string | string[] | null) => void;
}

export function ClaimFilterAutocompleteField<TControl extends FieldValues>({
  control,
  label,
  placeholder,
  name,
  options,
  multiple = true,
  onValueChange,
}: ClaimFilterAutocompleteFieldProps<TControl>) {
  return (
    <Controller
      render={({ field }) => {
        const { onChange, ...props } = field;
        const disabled = options != undefined ? options.length === 0 : true;
        return (
          <Autocomplete
            disabled={disabled}
            multiple={multiple}
            options={options || []}
            limitTags={3}
            filterSelectedOptions
            {...props}
            onChange={(e, data) => {
              onChange(data);
              onValueChange?.(data);
            }}
            renderInput={(params) => (
              <TextField
                sx={TextFieldStyle}
                {...params}
                label={label}
                InputLabelProps={{ shrink: true }}
                placeholder={placeholder}
              />
            )}
          />
        );
      }}
      name={name}
      control={control}
    />
  );
}

export function ClaimFilterModal({
  onChange,
  filters,
  insurerOptions,
  lossAdjustorOptions,
  lossCauseOptions,
  assignedOfficeOptions,
  referenceOptions,
  insuranceReferenceOptions,
  urgencyOptions,
  minimumDate,
  eventNameOptions,
  regionalCoordinatorDirectory,
  engineeringDirectory,
}: ClaimFilterProps) {
  const athenaMapControlContext = useAthenaMapControlContext();
  const [engineeringEmployees, setEngineeringEmployees] = useState<string[]>();
  const [eventCoordinators, setEventCoordinators] = useState<string[]>();

  const {
    handleSubmit,
    control,
    formState: { errors, isDirty },
    reset,
    setValue,
    getValues,
    watch,
  } = useForm<ClaimFilters>({
    resolver: zodResolver(ClaimFiltersSchema),
    defaultValues: filters,
  });

  const onSubmit = useCallback(
    (values: ClaimFilters) => {
      onChange?.(values);
      athenaMapControlContext.resetControl?.();
    },
    [onChange, athenaMapControlContext.resetControl]
  );

  const fromDate = watch("dates.from");

  const onEngineeringCompanyChange = (value: string | string[] | null) => {
    if (!value) return;
    if (Array.isArray(value)) {
      const employees = value.flatMap(
        (company) => engineeringDirectory[company] || []
      );
      setEngineeringEmployees(employees);
      return;
    }

    setEngineeringEmployees(engineeringDirectory[value] || []);
  };

  const onEventChanged = (value: string | string[] | null) => {
    if (!value) return;
    if (Array.isArray(value)) {
      const coordinators = [
        ...new Set(
          value.flatMap((event) => regionalCoordinatorDirectory[event] || [])
        ),
      ];
      setEventCoordinators(coordinators);
      return;
    }

    setEventCoordinators(regionalCoordinatorDirectory[value] || []);
  };

  return (
    <Stack direction={"column"}>
      <FiltersHeader>Filter</FiltersHeader>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FiltersSection>
          <FiltersSectionHeader>Insurance</FiltersSectionHeader>
          <Stack direction={"row"}>
            <ClaimFilterAutocompleteField
              name={"insurers"}
              control={control}
              label="Insurers"
              placeholder="Set insurer name..."
              options={insurerOptions}
            />
            <ClaimFilterAutocompleteField
              name={"insuranceReferences"}
              control={control}
              label="Insurance claim number"
              placeholder="Set claim numbers..."
              options={insuranceReferenceOptions}
            />
            <ClaimFilterAutocompleteField
              name={"lossAdjustorCompanies"}
              control={control}
              label="Loss Adjustor Company"
              placeholder="Set lost adjustor..."
              options={lossAdjustorOptions}
            />
          </Stack>
        </FiltersSection>
        <FiltersSection>
          <FiltersSectionHeader>Claim</FiltersSectionHeader>
          <Stack direction={"row"}>
            <ClaimFilterAutocompleteField
              name={"references"}
              control={control}
              label="Engineering Project Number"
              placeholder="Set Engineering Project Numbers..."
              options={referenceOptions}
            />
            <ClaimFilterAutocompleteField
              name={"engineeringCompanies"}
              control={control}
              label="Engineering Company"
              placeholder="Set engineering company..."
              onValueChange={onEngineeringCompanyChange}
              options={Object.keys(engineeringDirectory)}
            />
            <ClaimFilterAutocompleteField
              name={"assignedEngineers"}
              control={control}
              label="Assigned Engineer"
              placeholder="Set engineers..."
              options={engineeringEmployees || []}
            />
          </Stack>
          <Stack direction={"row"}>
            <Controller
              render={({ field }) => {
                const { onChange, ...props } = field;
                const propsToPass = {
                  ...props,
                  value: props.value === null ? "" : props.value,
                };

                return (
                  <TextField
                    {...propsToPass}
                    // type="number"
                    sx={TextFieldStyle}
                    label="Claim Age"
                    placeholder="Set claim age"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">Days</InputAdornment>
                      ),
                    }}
                    onChange={(event) => {
                      const integerValue = parseInt(
                        event.target.value.toString().replace(/[^\d]/g, "")
                      );

                      if (!isNaN(integerValue)) {
                        onChange(integerValue);
                      } else {
                        onChange(0);
                      }
                    }}
                  />
                );
              }}
              name={"age"}
              control={control}
            />
            <ClaimFilterAutocompleteField
              name={"lossCauses"}
              control={control}
              label="Loss Types"
              placeholder="Set loss type..."
              options={lossCauseOptions}
            />
            <ClaimFilterAutocompleteField
              name={"urgencies"}
              control={control}
              label="Priority"
              placeholder="Set priorities..."
              options={urgencyOptions}
            />
          </Stack>
        </FiltersSection>
        <ClaimStatusFilters>
          <ClaimStatusFiltersSection>
            <ClaimStatusFiltersHeader>
              Claim Status
              <ClaimFilterStatusSelectionButton
                watch={watch}
                statuses={Object.values(Status)}
                setValue={setValue}
              />
            </ClaimStatusFiltersHeader>
          </ClaimStatusFiltersSection>
          <ClaimFilterStatusRow
            headerLabel="Pre-visit"
            control={control}
            statuses={[
              Status.Setup,
              Status.NeedsEngineer,
              Status.WaitingForCustomer,
              Status.VisitBooked,
            ]}
            setValue={setValue}
          />
          <ClaimFilterStatusRow
            headerLabel="Post-visit"
            control={control}
            statuses={[Status.PreparingDraft]}
            setValue={setValue}
          />
          <ClaimFilterStatusRow
            headerLabel="Review"
            control={control}
            statuses={[
              Status.UnderInternalReview,
              Status.UnderClientReview,
              Status.ClientAccepted,
            ]}
            setValue={setValue}
          />
          <ClaimFilterStatusRow
            headerLabel="Completed"
            control={control}
            statuses={[Status.Done]}
            setValue={setValue}
          />
        </ClaimStatusFilters>
        <FiltersSection>
          <FiltersSectionHeader>Event</FiltersSectionHeader>
          <Stack direction={"row"}>
            <ClaimFilterAutocompleteField
              name={"eventNames"}
              control={control}
              label="Events"
              placeholder="Set event names..."
              onValueChange={onEventChanged}
              options={Object.keys(regionalCoordinatorDirectory)}
            />
            <ClaimFilterAutocompleteField
              name={"regionalCoordinators"}
              control={control}
              label="Coordinator"
              placeholder="Set coordinator names..."
              options={eventCoordinators || []}
            />
            <ClaimFilterAutocompleteField
              name={"assignedOffices"}
              control={control}
              label="Region / Assigned Office"
              placeholder="Set region / office..."
              options={assignedOfficeOptions}
            />
          </Stack>
        </FiltersSection>
        <FiltersSection>
          <FiltersSectionHeader>Dates</FiltersSectionHeader>
          <Stack direction={"row"}>
            <Controller
              render={({ field }) => {
                return (
                  <DatePicker
                    sx={TextFieldStyle}
                    label="From"
                    minDate={minimumDate}
                    slotProps={{
                      textField: {
                        placeholder: "Enter start date range",
                      },
                    }}
                    {...field}
                  />
                );
              }}
              name={"dates.from"}
              control={control}
            />
            <DatesRangeIcon>
              <RemoveIcon sx={{ height: "48px" }} />
            </DatesRangeIcon>

            <Controller
              render={({ field }) => {
                return (
                  <DatePicker
                    sx={TextFieldStyle}
                    label="To"
                    minDate={fromDate || minimumDate}
                    slotProps={{
                      textField: {
                        placeholder: "Enter end date range",
                      },
                    }}
                    {...field}
                  />
                );
              }}
              name={"dates.to"}
              control={control}
            />
          </Stack>
        </FiltersSection>
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          sx={{
            pt: "1rem",
            pb: "1rem",
            position: "sticky",
            bottom: "-1rem",
            backgroundColor: "white",
            zIndex: 1000,
          }}
        >
          <Stack direction={"row"} justifyContent={"space-between"}>
            <Button
              variant="contained"
              sx={{ minWidth: 200 }}
              type="submit"
              // disabled={!isDirty}
            >
              <span>Save</span>
            </Button>
            <Button
              variant="outlined"
              sx={{ minWidth: 200 }}
              // disabled={claim.claimId === acknowledgingClaimId}
              onClick={() => reset(emptyClaimFilters)}
            >
              <span>Clear</span>
            </Button>
          </Stack>
          <Button
            variant="outlined"
            sx={{ minWidth: 200 }}
            // disabled={claim.claimId === acknowledgingClaimId}
            onClick={athenaMapControlContext.resetControl}
          >
            <span>Cancel</span>
          </Button>
        </Stack>
      </form>
    </Stack>
  );
}

const FiltersHeader = styled.h1`
  font-size: 32px;
  font-weight: 600;
  /* padding: 0.5em 0; */
`;

const FiltersSection = styled.div`
  border-top: 1px solid lightgray;
  padding: 0.75rem 0 1rem 0;
`;

const FiltersSectionHeader = styled.h2`
  font-size: 24px;
  font-weight: 600;
`;

const ClaimStatusFilters = styled.div`
  border: 1px solid lightgray;
  border-radius: 12px;
  padding-left: 0;
  padding-right: 0;
  margin: 1rem 0;
`;

const ClaimStatusFiltersHeader = styled.h2`
  font-size: 20px;
  font-weight: 600;
  padding: 0.5em 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ClaimStatusFiltersSectionHeader = styled.h3`
  padding-top: 0.5em;
  font-size: 16px;
  font-weight: 600;
`;

const ClaimStatusFiltersSection = styled.div`
  :not(:last-child) {
    border-bottom: 1px solid lightgray;
  }
  padding: 0.25rem 1rem;
`;

const DatesRangeIcon = styled.div`
  height: 48px;
  display: inline-flex;
  align-self: end;
  color: lightgray;
`;
