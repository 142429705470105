export enum GeoJsonCollectionName {
  AccesswayAreaV1 = "accessway_area_v1",
  AppurtenantStructureAreaV1 = "appurtenant_structure_area_v1",
  AppurtenantStructurePointV1 = "appurtenant_structure_point_v1",
  ClaimPointV1 = "claim_point_v1",
  DroneSurveyAreaV1 = "drone_survey_area_v1",
  InsurerClaimPointV1 = "insurer_claim_point_v1",
  InsurerPolicyPointV1 = "insurer_policy_point_v1",
  EvacuatedLandAreaV1 = "evacuated_land_area_v1",
  HeadscarpLineV1 = "headscarp_line_v1",
  ImminentRiskAreaV1 = "imminent_risk_area_v1",
  InsuredLandAreaV1 = "insured_land_area_v1",
  InundatedLandAreaV1 = "inundated_land_area_v1",
  TensionCrackLineV1 = "tension_crack_line_v1",
  RetainingWallLineV1 = "retaining_wall_line_v1",
  PrimaryDwellingAreaV1 = "primary_dwelling_area_v1",
  ExclusiveUseAreaV1 = "exclusive_use_area_v1",
  DamagedServiceLineV1 = "damaged_service_line_v1",
  DamagedServicePointV1 = "damaged_service_point_v1",
  ProposedRemediationAreaV1 = "proposed_remediation_area_v1",
  ProposedRemediationLineV1 = "proposed_remediation_line_v1",
  ObservationAreaV1 = "observation_area_v1",
  ObservationLineV1 = "observation_line_v1",
  ObservationPointV1 = "observation_point_v1",
  CrossSectionLineV1 = "cross_section_line_v1",
  StructuralDamageAreaV1 = "structural_damage_area_v1",
  StructuralDamageLineV1 = "structural_damage_line_v1",
  StructuralDamagePointV1 = "structural_damage_point_v1",
  HistoricHeadscarpLineV1 = "historic_headscarp_line_v1",
}

export enum VectorTilesCollectionName {
  NzPrimaryParcelsV1 = "nz_primary_parcels_v1",
  NzBuildingOutlinesV1 = "nz_building_outlines_v1",
}
