import { useState, useEffect, useCallback } from "react";
import { DrawLineIcon, DrawPointIcon, DrawPolygonIcon } from "src/lib/icons";
import { Button } from "@mui/material";
import { EditableGeoJsonLayer } from "@nebula.gl/layers";

import {
  DrawToolbar,
  DrawToolbarButton,
  DrawingType,
} from "../../components/DrawToolbar";
import { Menu } from "../../components/Menu";
import { AthenaMeasureAreaMode } from "./modes/MeasureAreaMode";
import { AthenaModifyMode } from "./modes/EditMode";
import { AthenaMeasureDistanceMode } from "./modes/MeasureDistanceMode";
import { AthenaViewMode } from "./modes/ViewMode";
import { AthenaMeasurePointMode } from "./modes/MeasurePointMode";
import { TooltipWithType } from "./functions/getTooltips";
import { deckGlColorFromHex } from "../../functions/deckGlColor";
import { AthenaMapControl } from "../../components/Map";
import { IconImgExtraLarge } from "../../components/styled";

const defaultEditedFeatures: GeoJSON.FeatureCollection<GeoJSON.Polygon> = {
  type: "FeatureCollection",
  features: [],
};

// const getFeatureDrawingType = (feature) => {
//   if (feature.featureType === "polygons") {
//     return DrawingType.Polygon;
//   } else if (feature.coordinate.length === 2) {
//     return DrawingType.Point;
//   } else {
//     return DrawingType.Line;
//   }
// };

const getModeFromDrawingType = (
  drawingMode?: DrawingType,
  selectedIndex?: number
) => {
  if (selectedIndex !== undefined) {
    return new AthenaModifyMode();
  }

  switch (drawingMode) {
    case DrawingType.Polygon: {
      return new AthenaMeasureAreaMode();
    }

    case DrawingType.Line: {
      return new AthenaMeasureDistanceMode();
    }

    case DrawingType.Point: {
      return new AthenaMeasurePointMode();
    }

    default: {
      return new AthenaViewMode();
    }
  }
};

export function useMeasurementTool() {
  const [selectedOption, setSelectedOption] = useState<
    DrawingType | undefined
  >();
  const [isActive, setIsActive] = useState<boolean>(false);
  const [measurementResult, setMeasurementResult] = useState<string>();

  const [selectedFeatureIndex, setSelectedFeatureIndex] = useState<
    number | undefined
  >(undefined);

  const [editedFeaturesHistoryIndex, setEditedFeaturesHistoryIndex] =
    useState<number>(0);

  const [editedFeaturesHistory, setEditedFeaturesHistory] = useState<
    Array<GeoJSON.FeatureCollection>
  >([defaultEditedFeatures]);

  const [editedFeatures, setEditedFeatures] =
    useState<GeoJSON.FeatureCollection>(defaultEditedFeatures);

  const [editLayer, setEditLayer] = useState<
    EditableGeoJsonLayer | undefined
  >();

  const isUndoAvailable = editedFeaturesHistoryIndex > 0;

  const isRedoAvailable =
    editedFeaturesHistoryIndex < editedFeaturesHistory.length - 1;

  const isClearAvailable = editedFeatures.features.length > 0;

  const updateHistory = useCallback(
    (data: any) => {
      const historyUpToCurrentMoment = editedFeaturesHistory.slice(
        0,
        editedFeaturesHistoryIndex + 1
      );

      const newHistory = [...historyUpToCurrentMoment, data];

      setEditedFeaturesHistory(newHistory);
      setEditedFeaturesHistoryIndex(newHistory.length - 1);
    },
    [editedFeaturesHistory, editedFeaturesHistoryIndex]
  );

  const onUndo = useCallback(() => {
    if (!isUndoAvailable) {
      return;
    }

    const newHistoryIndex = editedFeaturesHistoryIndex - 1;
    setEditedFeaturesHistoryIndex(newHistoryIndex);
    const data = editedFeaturesHistory[newHistoryIndex];
    setEditedFeatures(data);
  }, [
    isUndoAvailable,
    editedFeatures,
    editedFeaturesHistory,
    editedFeaturesHistoryIndex,
  ]);

  const onRedo = useCallback(() => {
    if (!isRedoAvailable) {
      return;
    }

    const newHistoryIndex = editedFeaturesHistoryIndex + 1;
    setEditedFeaturesHistoryIndex(newHistoryIndex);
    const data = editedFeaturesHistory[newHistoryIndex];
    setEditedFeatures(data);
  }, [
    isRedoAvailable,
    editedFeatures,
    editedFeaturesHistory,
    editedFeaturesHistoryIndex,
  ]);

  const onClear = useCallback(() => {
    if (!isClearAvailable) {
      return;
    }

    setEditedFeaturesHistory([]);
    setEditedFeaturesHistoryIndex(0);
    setEditedFeatures(defaultEditedFeatures);
    setSelectedFeatureIndex(undefined);
    setSelectedOption(undefined);
  }, [
    isRedoAvailable,
    editedFeatures,
    editedFeaturesHistory,
    editedFeaturesHistoryIndex,
  ]);

  useEffect(() => {
    // @ts-ignore
    const newEditLayer = new EditableGeoJsonLayer({
      id: "edit-layer",
      wrapLongitude: true,
      data: editedFeatures,
      mode: getModeFromDrawingType(selectedOption, selectedFeatureIndex),
      selectedFeatureIndexes:
        selectedFeatureIndex !== undefined ? [selectedFeatureIndex] : [],
      getEditHandlePointColor: () => deckGlColorFromHex("#0067df"),
      getEditHandlePointRadius: (handle: any) => {
        switch (handle.properties.editHandleType) {
          case "existing":
            return 5;
          case "snap":
            return 6;
          case "intermediate":
          default:
            return 6;
        }
      },
      _subLayerProps: {
        geojson: {
          getLineColor: () => deckGlColorFromHex("#0067df"),
          getFillColor: () => deckGlColorFromHex("#0067df", 0.3),
        },
        guides: {
          getLineColor: () => deckGlColorFromHex("#0067df"),
          getFillColor: () => deckGlColorFromHex("#0067df", 0.3),
        },
        tooltips: {
          getAlignmentBaseline: (tooltip: TooltipWithType) => {
            if (tooltip.type === DrawingType.Point) {
              return "bottom";
            } else {
              return "center";
            }
          },
          getSize: (tooltip: TooltipWithType) => {
            return 16;
          },
          getColor: (tooltip: TooltipWithType) => {
            return [0, 0, 255];
          },
          characterSet: "auto",
          fontSettings: {
            sdf: true,
            radius: 16,
            smoothing: 0.2,
            buffer: 16,
          },
          useDevicePixels: true,
          outlineWidth: 32,
          outlineColor: [255, 255, 255, 255],
          // getBackgroundColor: (tooltip: TooltipWithType) => {
          //   return [255, 255, 255, 255];
          // },
          // background: true,
        },
      },
      onClick: (feature: any) => {
        // @ts-ignore
        if (!isActive || feature.isGuide) {
          return;
        }

        setSelectedOption(undefined);
        setSelectedFeatureIndex(feature.index);
        setEditedFeaturesHistory([defaultEditedFeatures]);
        setEditedFeaturesHistoryIndex(0);
      },
      onEdit: (data: any) => {
        const { updatedData, editType, editContext } = data;
        setEditedFeatures(updatedData);
        if (editType === "addFeature") {
          setEditedFeaturesHistory([updatedData]);
        } else if (["finishMovePosition", "addPosition"].includes(editType)) {
          updateHistory(updatedData);
        }

        if (editType === "addFeature") {
          const { featureIndexes } = editContext;

          setSelectedFeatureIndex(featureIndexes[0]);
          setSelectedOption(undefined);
        }
      },
    });

    setEditLayer(newEditLayer);
  }, [editedFeatures, selectedFeatureIndex, selectedOption, isActive]);

  const toolbar = (
    <DrawToolbar
      key="measure-toolbar"
      buttons={{
        [DrawToolbarButton.Undo]: {
          isDisabled: !isUndoAvailable,
        },
        [DrawToolbarButton.Redo]: {
          isDisabled: !isRedoAvailable,
        },
        [DrawToolbarButton.Confirm]: {
          isDisabled: false,
        },
        [DrawToolbarButton.Remove]: true,
      }}
      onClick={(button) => {
        switch (button) {
          case DrawToolbarButton.Undo: {
            onUndo();
            break;
          }
          case DrawToolbarButton.Redo: {
            onRedo();
            break;
          }
          case DrawToolbarButton.Remove: {
            if (selectedFeatureIndex === undefined) {
              return;
            }

            const features = [...editedFeatures.features];
            features.splice(selectedFeatureIndex, 1);

            setEditedFeatures({
              ...editedFeatures,
              features,
            });
            setSelectedOption(undefined);
            break;
          }

          case DrawToolbarButton.Confirm: {
            setSelectedFeatureIndex(undefined);
            setSelectedOption(undefined);
            break;
          }
        }
      }}
    />
  );

  const dropdown = (
    <>
      <span>Drawing Type</span>
      <Menu
        selectedOption={selectedOption}
        closeOnSelect={false}
        options={[
          {
            key: DrawingType.Polygon,
            label: "Shape",
            thumbnail: <DrawPolygonIcon stroke="#0067DF" fillOpacity={0} />,
          },
          {
            key: DrawingType.Line,
            label: "Line",
            thumbnail: <DrawLineIcon fill="#0067DF" stroke="#0067DF" />,
          },
          {
            key: DrawingType.Point,
            label: "Point",
            thumbnail: <DrawPointIcon fill="#0067DF" />,
          },
        ]}
        onSelected={(drawingType) => {
          setSelectedOption(drawingType);
          setSelectedFeatureIndex(undefined);
        }}
      />
      {editedFeatures.features.length > 0 && (
        <Button
          variant="outlined"
          sx={{ width: "100%", marginTop: "1rem" }}
          onClick={onClear}
        >
          <span>Clear</span>
        </Button>
      )}
    </>
  );

  const isEditing =
    selectedFeatureIndex !== undefined && selectedOption == undefined;

  const icon = <IconImgExtraLarge src="/images/mapping/measure.svg" />;

  const control: AthenaMapControl = {
    icon,
    title: "Select",
    handlers: {
      onOpen: () => {
        setIsActive(true);
      },
      onClose: () => {
        setSelectedOption(undefined);
        setSelectedFeatureIndex(undefined);
        setIsActive(false);
      },
    },
    components: {
      dropdown,
      toolbar: isActive && isEditing && toolbar,
    },
  };

  return {
    layer: editLayer,
    control,
  };
}
