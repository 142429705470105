interface Config {
  loginUrl: string;
  clientUrl: string;
  authClientId: string;
  apiUrl: string;
  trpcUrl: string;
  gisApiUrl: string;
}

export const config: Config = {
  loginUrl: import.meta.env.VITE_LOGIN_URL,
  clientUrl: import.meta.env.VITE_CLIENT_URL,
  authClientId: import.meta.env.VITE_AUTH_CLIENT_ID,
  apiUrl: `${import.meta.env.VITE_API_URL}/rest`,
  trpcUrl: `${import.meta.env.VITE_API_URL}/trpc`,
  gisApiUrl: import.meta.env.VITE_API_URL.replace("/api", ""),
};
