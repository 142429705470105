import { Status } from "@athena/server/src/api/types/claimStatuses";
import { GetOfficeCapacity } from "@athena/server/src/trpc/routers/offices/schema";
import styled from "@emotion/styled";
import { Paper } from "@mui/material";
import { addWeeks, formatISO, subWeeks } from "date-fns";
import { FC, useMemo } from "react";
import { trpc } from "src/lib/api/trpc";
import { getEndOfWeek, getStartOfWeek } from "src/lib/date";
import { useIntelligencerPitData } from "src/modules/spatialPortal/mapLib/api/intelligencer/hooks/useIntelligencerPitData";
import {
  IntelligencerFilter,
  IntelligencerPit,
} from "src/modules/spatialPortal/mapLib/api/intelligencer/types";
import { useCurrentUser } from "src/shared/hooks/useCurrentUser";
import { AppliedFilters, FiltersValues } from "./NewEngineeringClaimFilters";
import {
  ActiveAndOverdueClaimHealth,
  FutureCapacityData,
  PastHealthData,
} from "./charts/ActiveAndOverdueClaimHealth";
import {
  BreakdownOfEngineeringCapacity,
  FutureData,
  PastBreakdownData,
  PastBreakdownDataKey,
} from "./charts/BreakdownOfEngineeringCapacity";
import {
  ClaimCompletionChart,
  PastCompletionData,
} from "./charts/ClaimCompletionChart";

const getOfficeWeeks = (now: Date, endDate: Date) => {
  let start = getStartOfWeek(now);

  const weeks: Array<Date> = [];

  while (start <= endDate) {
    weeks.push(start);
    start = getStartOfWeek(addWeeks(start, 1));
  }

  return weeks.map((x) => formatISO(x, { representation: "date" }));
};

const WEEKS_INTO_THE_PAST = 4;
const WEEKS_INTO_THE_FUTURE = 6;

interface Props {
  filters: FiltersValues;
}

export const ClaimCompletion: FC<Props> = ({ filters }) => {
  const { userData } = useCurrentUser();

  const now = useMemo(() => new Date(), []);
  const startPastDate = useMemo(
    () => getStartOfWeek(subWeeks(now, WEEKS_INTO_THE_PAST)),
    [now]
  );
  const endFutureDate = useMemo(
    () => addWeeks(now, WEEKS_INTO_THE_FUTURE),
    [now]
  );
  const minCapacityWeek = useMemo(
    () => formatISO(now, { representation: "date" }),
    [now]
  );
  const startOfWeekISO = useMemo(
    () => formatISO(getStartOfWeek(now), { representation: "date" }),
    [now]
  );

  const filter: IntelligencerFilter = {
    startDate: startPastDate,
    endDate: getEndOfWeek(now),
    insuranceClaimNo: filters.claimReference,
    eventIds: filters.eventIds,
    statuses: filters.statuses,
    region: filters.region,
    urgency: filters.urgency,
    additionalStatuses: filters.additionalStatuses,
    // assignedOffice: filters.assignedOfficeId,
  };

  const { data: claimCountData } = useIntelligencerPitData(
    IntelligencerPit.ClaimCount,
    filter
  );

  const { data: claimStatusData } = useIntelligencerPitData(
    IntelligencerPit.ClaimStatus,
    filter
  );

  const { data: officeCapacities, isLoading: isLoadingOfficeCapacities } =
    trpc.offices.getOfficeCapacities.useQuery();

  const { data: engineeringWorkload, isLoading: isLoadingEngineeringWorkload } =
    trpc.engineeringWorkload.getWorkload.useQuery();

  const pastHealthReport: Array<PastHealthData> | undefined =
    claimStatusData &&
    Object.keys(claimStatusData).map((date) => {
      const dateRecord = claimStatusData[date];
      return Object.keys(dateRecord).reduce(
        (sum, engOrgId) => {
          const orgRecord = dateRecord[engOrgId];

          return {
            ...sum,
            onTime1to2days: sum.onTime1to2days + (orgRecord.onTime["1"] || 0),
            onTime2to3days:
              sum.onTime2to3days +
              (orgRecord.onTime["2"] || 0) +
              (orgRecord.onTime["3"] || 0),
            onTime4plusDays:
              sum.onTime4plusDays + (orgRecord.onTime["4+"] || 0),

            overdue1to2days:
              sum.overdue1to2days + (orgRecord.overdue["1"] || 0),
            overdue2to3days:
              sum.overdue2to3days +
              (orgRecord.overdue["2"] || 0) +
              (orgRecord.overdue["3"] || 0),
            overdue4plusDays:
              sum.overdue4plusDays + (orgRecord.overdue["4+"] || 0),
          };
        },
        {
          onTime1to2days: 0,
          onTime2to3days: 0,
          onTime4plusDays: 0,
          overdue1to2days: 0,
          overdue2to3days: 0,
          overdue4plusDays: 0,
          date: new Date(date),
        }
      );
    });

  const claimCompletionReport: Array<PastCompletionData> | undefined =
    claimCountData &&
    Object.keys(claimCountData).map((date) => {
      const dateRecord = claimCountData[date];

      return {
        startedCount: dateRecord.started_cumulative,
        finishedCount: dateRecord.total_completed,
        date: new Date(date),
      };
    });

  const officeCapacitiesGroupedByWeek = officeCapacities?.reduce(
    (sum, officeCapacity) => {
      sum[officeCapacity.weekStart] = [
        ...(sum[officeCapacity.weekStart] || []),
        officeCapacity,
      ];
      return sum;
    },
    {} as Record<string, Array<GetOfficeCapacity>>
  );

  const weeks = getOfficeWeeks(addWeeks(now, 1), endFutureDate);
  // console.log(officeCapacities);
  const pastBreakdown = Object.keys(engineeringWorkload?.workload || {}).reduce(
    (sum: PastBreakdownData, orgId) => {
      const orgName = engineeringWorkload?.orgNames[orgId] || "";
      const orgRecord = engineeringWorkload?.workload[orgId];
      if (!orgRecord) {
        return sum;
      }

      sum[PastBreakdownDataKey.NotStarted][orgName] = [
        orgRecord[Status.NeedsEngineer],
        orgRecord[Status.WaitingForCustomer],
        orgRecord[Status.VisitBooked],
      ].reduce((sum, val) => {
        if (val === undefined) return sum;
        return sum + val;
      }, 0);

      sum[PastBreakdownDataKey.SiteVisitComplete][orgName] = [
        orgRecord[Status.PreparingDraft],
        orgRecord[Status.UnderInternalReview],
      ].reduce((sum, val) => {
        if (val === undefined) return sum;
        return sum + val;
      }, 0);

      sum[PastBreakdownDataKey.DraftReportComplete][orgName] = [
        orgRecord[Status.UnderClientReview],
        orgRecord[Status.ClientAccepted],
      ].reduce((sum, val) => {
        if (val === undefined) return sum;
        return sum + val;
      }, 0);

      return sum;
    },
    {
      [PastBreakdownDataKey.NotStarted]: {},
      [PastBreakdownDataKey.SiteVisitComplete]: {},
      [PastBreakdownDataKey.DraftReportComplete]: {},
    } as PastBreakdownData
  );

  const companiesCapacity: Array<FutureData> = [];
  const futureCapacity: Array<FutureCapacityData> = [];
  const startingWeekCapacity = (
    officeCapacitiesGroupedByWeek?.[startOfWeekISO] || []
  ).reduce((sum, cap) => sum + cap.capacity, 0);

  for (let week of weeks || []) {
    if (week < minCapacityWeek) {
      continue;
    }

    const officeCapacity = officeCapacitiesGroupedByWeek?.[week];

    if (officeCapacity) {
      companiesCapacity.push({
        week: week,
        companies: officeCapacity.reduce((sum, cap) => {
          const orgName =
            engineeringWorkload?.orgNames[cap.organisationId] || "";
          if (!sum[orgName]) {
            sum[orgName] = 0;
          }

          sum[orgName] += cap.capacity;

          return sum;
        }, {} as Record<string, number>),
      });

      const summedOfficeCapacity = officeCapacity.reduce(
        (sum, item) => sum + item.capacity,
        0
      );

      futureCapacity.push({
        capacity: summedOfficeCapacity,
        date: new Date(week),
      });
    } else {
      companiesCapacity.push({
        week: week,
        companies: {} as Record<string, number>,
      });
      futureCapacity.push({
        capacity: 0,
        date: new Date(week),
      });
    }
  }

  const blankPastData = Object.values([0, 1, 2, 3, 4, 5])
    .map((week) => ({
      date: getStartOfWeek(subWeeks(now, week)),
    }))
    .sort((a, b) => a.date.getTime() - b.date.getTime());

  // console.log("blankPastData", JSON.stringify(blankPastData, null, 2));

  // const filter: IntelligencerFilter = {
  //   startDate: startPastDate,
  //   endDate: getEndOfWeek(now),
  //   insuranceClaimNo: filters.claimReference,
  //   eventIds: filters.eventIds,
  //   statuses: filters.statuses,
  //   region: filters.region,
  //   urgency: filters.urgency,
  //   additionalStatuses: filters.additionalStatuses,
  //   assignedOffice: filters.assignedOfficeId,
  // };

  return (
    <>
      <Paper sx={{ mt: "3rem", padding: "1.5rem" }}>
        <Title>
          <div className="title-period">
            <h3>Estimated vs Actual Claim Completion</h3>
            <AppliedFilters
              filters={filters}
              availableFilters={[
                "claimReference",
                "events",
                "statuses",
                "region",
                "urgency",
                "additionalStatuses",
                "assignedOfficeId",
              ]}
            />
          </div>
        </Title>
        <ClaimCompletionChart
          isComingSoon={userData?.organisation?.orgType === "eqc"}
          pastData={claimCompletionReport || []}
          futureData={futureCapacity}
          startingWeekCapacity={startingWeekCapacity || 0}
        />
      </Paper>
      <Paper sx={{ mt: "3rem", padding: "1.5rem" }}>
        <Title>
          <div className="title-period">
            <h3>Active and Overdue Claim - Health Status</h3>
            <AppliedFilters
              filters={filters}
              availableFilters={[
                "claimReference",
                "events",
                "statuses",
                "region",
                "urgency",
                "additionalStatuses",
                "assignedOfficeId",
              ]}
            />
          </div>
        </Title>
        <ActiveAndOverdueClaimHealth
          isComingSoon={userData?.organisation?.orgType === "eqc"}
          pastData={pastHealthReport || (blankPastData as PastHealthData[])}
          futureData={futureCapacity}
          startingWeekCapacity={startingWeekCapacity || 0}
        />
      </Paper>
      <Paper sx={{ mt: "3rem", padding: "1.5rem" }}>
        <Title>
          <div className="title-period">
            <h3>Breakdown of Engineering Capacity</h3>
            <AppliedFilters filters={filters} availableFilters={["None"]} />
          </div>
        </Title>
        <BreakdownOfEngineeringCapacity
          isComingSoon={userData?.organisation?.orgType === "eqc"}
          pastBreakdownData={pastBreakdown}
          prediction={companiesCapacity}
          companies={Object.values(engineeringWorkload?.orgNames || {})}
        />
      </Paper>
    </>
  );
};

const Title = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  border-bottom: 1px solid lightgray;
  .title-link {
    text-decoration: none;
    color: #0067df;
  }
  .title-period {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5rem;
    aside {
      color: #666;
    }
  }
`;
