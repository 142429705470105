import { Button, Paper, Stack, TextField } from "@mui/material";
import { Editor } from "@tiptap/react";
import { useEffect, useState } from "react";

interface AddCommentCardProps {
  commitComment: (comment: string) => void;
  abortComment: () => void;
  editor: Editor;
}

export const AddCommentCard = ({
  commitComment,
  abortComment,
  editor,
}: AddCommentCardProps) => {
  const [comment, setComment] = useState("");

  // Listen for selection update to hide the comment card
  useEffect(() => {
    editor.on("selectionUpdate", abortComment);
    return () => {
      editor.off("selectionUpdate", abortComment);
    };
  }, [editor]);

  let yTranslate = "-105%";

  const from = editor.state.selection.$from.pos;
  const to = editor.state.selection.$to.pos;

  let centerOfSelection = { top: 0, left: 0 };

  // Calculate the center of the selection
  if (from !== to) {
    const fromCoords = editor.view.coordsAtPos(from);
    const toCoords = editor.view.coordsAtPos(to);
    centerOfSelection = {
      top: (fromCoords.top + toCoords.top) / 2,
      left: (fromCoords.left + toCoords.left) / 2,
    };
  }

  if (centerOfSelection.top < 200) {
    yTranslate = "+10%";
  }

  return (
    <Paper
      sx={{
        position: "fixed",
        transform: `translate(-50%, ${yTranslate})`,
        left: centerOfSelection.left > 0 ? centerOfSelection.left : "50%",
        top: centerOfSelection.top > 0 ? centerOfSelection.top : "50%",
        padding: "1rem",
        zIndex: 10000,
      }}
      elevation={3}
    >
      <Stack>
        <TextField
          id={"comment"}
          placeholder="Enter Comment"
          className="textarea-bordered textarea textarea-md w-full max-w-xs"
          value={comment}
          onChange={(e) => setComment(e.target.value)}
          minRows={2}
          multiline
          autoFocus
        />
        <label className="label">
          <span
            className="label-text-alt text-red-700"
            id={`comment-error`}
          ></span>
        </label>
        {/* TODO remove end */}

        <div className="card-actions justify-end">
          <Button
            sx={{ width: 150 }}
            variant="contained"
            onClick={() => {
              commitComment(comment);
            }}
          >
            Comment
          </Button>
          <Button sx={{ width: 150 }} onClick={abortComment}>
            Cancel
          </Button>
        </div>
      </Stack>
    </Paper>
  );
};
