import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  ResponsiveContainer,
  Tooltip,
  Label,
  LabelList,
} from "recharts";

const renderCustomizedLabel = () => {
  return <Label fontSize="12" fill="#FFFFFF" fontWeight="Bold" />;
};

type SummaryGraphProps = {
  completed: number;
  prepared: number;
  assessment: number;
  clientReview: number;
};

export const SummaryGraph = (props: SummaryGraphProps) => {
  const { assessment, clientReview, completed, prepared } = props;
  const data = [{ assessment, clientReview, completed, prepared }];

  return (
    <div className="content c-white">
      <ResponsiveContainer height={40} width={"100%"}>
        <BarChart
          layout="vertical"
          data={data}
          margin={{ left: 0, right: 0 }}
          stackOffset="expand"
        >
          <XAxis hide type="number" />
          <YAxis
            width={0}
            type="category"
            dataKey="name"
            stroke="#FFFFFF"
            fontSize="12"
          />
          <Tooltip />
          <Bar dataKey="prepared" name="Being prepared" fill="#000" stackId="a">
            <LabelList
              dataKey="prepared"
              position="center"
              content={renderCustomizedLabel}
            />
          </Bar>
          <Bar
            dataKey="assessment"
            name="Under assessment"
            fill="#bee8ff"
            stackId="a"
          >
            <LabelList
              dataKey="assessment"
              position="center"
              content={renderCustomizedLabel}
            />
          </Bar>
          <Bar
            dataKey="clientReview"
            name="Client review"
            fill="#aa66ff"
            stackId="a"
          >
            <LabelList
              dataKey="clientReview"
              position="center"
              content={renderCustomizedLabel}
            />
          </Bar>
          <Bar dataKey="completed" name="Completed" fill="#51f44b" stackId="a">
            <LabelList
              dataKey="completed"
              position="center"
              content={renderCustomizedLabel}
            />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};
