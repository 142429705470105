import styled from "@emotion/styled";
import { Menu } from "@mui/material";
import React, { ReactElement } from "react";
import remixiconUrl from "remixicon/fonts/remixicon.symbol.svg";

export type MenuItemProps = {
  icon: string;
  title: string;
  action: VoidFunction;
  isActive?: () => boolean;
  menuChildren?: ReactElement;
};

const MenuItemButton = styled.button`
  background-color: transparent;
  border: none;
  border-radius: 0.4rem;
  color: #fff;
  cursor: pointer;
  height: 2rem;
  margin-right: 0.25rem;
  padding: 0.25rem;
  width: 2rem;

  svg {
    fill: #000000;
    height: 100%;
    width: 100%;
  }

  &:hover,
  &.is-active {
    svg {
      fill: #ffffff;
      height: 100%;
      width: 100%;
    }
    background-color: #303030;
  }
`;

export const MenuItem = ({
  icon,
  title,
  action,
  isActive,
  menuChildren,
}: MenuItemProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <MenuItemButton
        className={isActive && isActive() ? "is-active" : ""}
        onClick={(e) => {
          if (menuChildren) {
            handleClick(e);
          } else {
            action();
          }
        }}
        title={title}
      >
        <svg className="remix">
          <use xlinkHref={`${remixiconUrl}#ri-${icon}`} />
        </svg>
      </MenuItemButton>
      {menuChildren && (
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          {menuChildren}
        </Menu>
      )}
    </>
  );
};
