import { Status } from "@athena/server/src/api/types/claimStatuses";
import { differenceInDays } from "date-fns";
import { ClaimFilters } from "../components/ClaimFilterModal";
import { GeoJsonCollectionName } from "src/modules/spatialPortal/mapLib/api/constants";
import { InferFeatureCollectionType, InferFeaturePropertiesType } from "src/modules/spatialPortal/mapLib/api/types";

interface FilterClaimsProps {
  claims?: InferFeatureCollectionType<GeoJsonCollectionName.ClaimPointV1>;
  filter?: ClaimFilters;
}

export const filterClaims = (props: FilterClaimsProps) => {
  const filterPredicates: Array<(p: InferFeaturePropertiesType<GeoJsonCollectionName.ClaimPointV1>) => boolean> = [];
  if (props.filter && Object.keys(props.filter).length > 0) {
    const {
      age,
      assignedEngineers,
      assignedOffices,
      engineeringCompanies,
      regionalCoordinators,
      insurers,
      lossAdjustorCompanies,
      lossCauses,
      references,
      insuranceReferences,
      statuses,
      urgencies,
      eventNames,
      dates
    } = props.filter;

    const statusesList = statuses === undefined ? [] : Object.values(Status).filter(s => statuses[s]);

    if (statusesList.length > 0) {
      filterPredicates.push(
        (p) => p.claim_status !== null && statusesList.includes(p.claim_status)
      );
    }

    if (urgencies && urgencies.length > 0) {
      filterPredicates.push((p) => p.urgency !== null && urgencies.includes(p.urgency));
    }

    if (insurers && insurers.length > 0) {
      filterPredicates.push(
        (p) => p.insurer_name !== null && insurers.includes(p.insurer_name)
      );
    }

    if (lossAdjustorCompanies && lossAdjustorCompanies.length > 0) {
      filterPredicates.push(
        (p) =>
          p.loss_adjuster_name !== null &&
          lossAdjustorCompanies.includes(p.loss_adjuster_name)
      );
    }

    if (engineeringCompanies && engineeringCompanies.length > 0) {
      filterPredicates.push(
        (p) =>
          p.engineering_company !== null &&
          engineeringCompanies.includes(p.engineering_company)
      );
    }

    if (lossCauses && lossCauses.length > 0) {
      filterPredicates.push(
        (p) =>
          p.loss_cause_name !== null && lossCauses.includes(p.loss_cause_name)
      );
    }

    if (assignedOffices && assignedOffices.length > 0) {
      filterPredicates.push(
        (p) =>
          p.assigned_office !== null &&
          assignedOffices.includes(p.assigned_office)
      );
    }

    if (assignedEngineers && assignedEngineers.length > 0) {
      filterPredicates.push(
        (p) => p.assigned_engineers.flatMap(x => x.user_name).some(x => assignedEngineers.includes(x))
      );
    }

    if (dates) {
      if (dates.from) {
        filterPredicates.push(
          (p) => dates.from !== null && p.service_request_date !== null && differenceInDays(p.service_request_date, dates.from) >=0
        );
      }

      if (dates.to) {
        filterPredicates.push(
          (p) => dates.to !== null && p.service_request_date !== null && differenceInDays(p.service_request_date, dates.to) <=0
        );
      }
    }

    if (regionalCoordinators && regionalCoordinators.length > 0) {
      filterPredicates.push(
        (p) =>
          p.regional_coordinator_name !== null && regionalCoordinators.includes(p.regional_coordinator_name)
      );
    }

    if (references && references.length > 0) {
      filterPredicates.push(
        (p) =>
          p.reference !== null &&
          references.includes(p.reference)
      );
    }

    if (insuranceReferences && insuranceReferences.length > 0) {
      filterPredicates.push(
        (p) =>
          p.insurer_reference !== null &&
          insuranceReferences.includes(p.insurer_reference)
      );
    }

    if (age !== null) {
      filterPredicates.push(
        (p) => p.claim_age >= age
      );
    }

    if (eventNames.length > 0) {
      filterPredicates.push(
        (p) => p.events.flatMap(x => x.event_name).some(x => eventNames.includes(x))
      );
    }
  }

  const claims:
    | InferFeatureCollectionType<GeoJsonCollectionName.ClaimPointV1>
    | undefined = props.claims
    ? {
        ...props.claims,
        features: props.claims.features.filter((x) =>
          filterPredicates.every((predicate) => predicate(x.properties))
        ),
      }
    : undefined;

  return claims;
};
