import Lock from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import { Tooltip } from "@mui/material";

export const PrivacyChip = ({ shared }: { shared: boolean }) => {
  const variants = {
    shared: {
      label: "Shared",
      tooltip:
        "This section is shared with anyone who has access to this claim",
      background: "#FFEAC1",
      icon: <LockOpenIcon color="warning" fontSize="inherit" />,
    },
    private: {
      label: "Private",
      tooltip: "This section is private to your organisation",
      background: "#f5dcff",
      icon: (
        <Lock color="warning" fontSize="inherit" sx={{ color: "purple" }} />
      ),
    },
  };

  const variant = shared ? variants.shared : variants.private;

  return (
    <Tooltip title={variant.tooltip}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          backgroundColor: variant.background,
          padding: "0.4rem 0.75rem",
          borderRadius: "20px",
          gap: "0.25rem",
          color: "#444",

          fontSize: "1rem",
          marginLeft: "0.5rem",
          fontWeight: "400",
        }}
      >
        {variant.icon}
        <p>{variant.label}</p>
      </div>
    </Tooltip>
  );
};
