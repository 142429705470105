import { AssessmentAttachment } from "@athena/server/src/api/types/claimAssessment";
import { config } from "src/lib/config";

type PhotographsProps = {
  photographs: AssessmentAttachment[];
  address: string;
};

export const Photographs = ({ photographs, address }: PhotographsProps) => {
  if (!photographs.length) return null;
  return (
    <div style={{ width: "100%" }}>
      <span
        id="photograph_heading"
        style={{
          pageBreakBefore: "always",
          marginTop: 0,
          marginBottom: 0,
        }}
      >
        Photographs 1 to {photographs.length} - {address}
      </span>
      {photographs.map((photograph, i) => {
        return (
          <div
            key={photograph.imageUrl || photograph.annotatedImageUrl}
            id="photograph_container"
            style={{
              display: "flex",
              width: "100%",
              minHeight: 435,
              flexDirection: "column",
              pageBreakAfter: "auto",
              marginTop: i != 0 ? "-1px" : 0,
            }}
          >
            <div
              style={{
                padding: "0.5rem",
                flex: 1,
                display: "flex",
                justifyContent: "center",
                maxHeight: 400,
                minHeight: 400,
                width: "100%",
              }}
            >
              <img
                style={{
                  maxWidth: "100%",
                  maxHeight: "100%",
                  objectFit: "contain",
                }}
                src={`${config.apiUrl}/${
                  photograph.annotatedImageUrl || photograph.imageUrl
                }`}
              />
            </div>
            <div
              id="photograph_caption"
              style={{
                height: "fit-content",
              }}
            >
              Photograph {i + 1}: {photograph.caption}
            </div>
          </div>
        );
      })}
    </div>
  );
};
