import Menu from "../../menu/Menu";

import { PageNameContext } from "src/App";
import { useContext, useEffect } from "react";
import { InsuranceClaimsList } from "../components/InsuranceClaimsList";

export function InsuranceClaimsPage() {
  const { setPageName } = useContext(PageNameContext);
  useEffect(() => {
    setPageName("Insurance Claims");
  }, [setPageName]);

  return (
    <Menu>
      <InsuranceClaimsList />
    </Menu>
  );
}
