import CloseIcon from "@mui/icons-material/Close";

import styled from "@emotion/styled";

import Papa from "papaparse";
import { useCallback, useEffect, useState } from "react";
import { Alert, Dialog, IconButton } from "@mui/material";
import { Stack } from "@mui/system";
import {
  DialogSection,
  DialogSectionContentHorizontal,
  SelectFile,
} from "@athena/components";
import {
  CreateInsuranceClaim,
  CreateInsuranceClaimSchema,
} from "@athena/server/src/trpc/routers/insuranceClaims/schema";
import { trpc } from "src/lib/api/trpc";
import { LoadingSpinner } from "@athena/components";

import { CSVHeaders, Column, MapColumns } from "./components/MapColumns";

const ROW_LIMIT = 500;
interface ParseCSVProps {
  onClose: () => void;
}

const ParseCSVDialogTitle = styled.h4`
  font-size: 1.375rem;
  font-weight: 600;
  line-height: 2rem;
`;

const columnMatchers: Record<Column, Array<string>> = {
  [Column.ClaimReference]: [
    "claim ref",
    "claim reference",
    "claim num",
    "claim number",
  ],
  [Column.PolicyReference]: [
    "policy ref",
    "policy reference",
    "policy num",
    "policy number",
  ],
  [Column.Address]: ["address", "addr"],
  [Column.Status]: ["status"],
  // [Column.LossAdjuster]: ["loss Adjuster", "loss adj"],
};

export enum HiddenColumn {
  Latitude = "latitude",
  Longitude = "longitude",
}
export type CSVHiddenHeaders = Partial<Record<HiddenColumn, string>>;

const hiddenColumnMatchers: Record<HiddenColumn, Array<string>> = {
  [HiddenColumn.Latitude]: ["latitude"],
  [HiddenColumn.Longitude]: ["longitude"],
};

export function ImportCsvFile({ onClose }: ParseCSVProps) {
  return (
    <Dialog
      onClose={onClose}
      open={true}
      maxWidth="md"
      fullWidth
      // sx={{
      //   "& .MuiDialog-container": {
      //     "& .MuiPaper-root": {
      //       width: "100%",
      //       maxWidth: "50vw",
      //     },
      //   },
      // }}
    >
      <DialogContent onClose={onClose} />
    </Dialog>
  );
}

function DialogContent({ onClose }: ParseCSVProps) {
  const [amountOfRowsOverLimit, setAmountOfRowsOverLimit] = useState(0);

  const [hiddenHeaders, setHiddenHeaders] = useState<CSVHiddenHeaders>({});
  const [csvFile, setCsvFile] = useState<File | undefined>(undefined);
  const [defaultMappedColumns, setDefaultMappedColumns] = useState<CSVHeaders>(
    {}
  );
  const [mappedColumns, setMappedColumns] = useState<CSVHeaders | undefined>(
    undefined
  );
  const [isParsing, setIsParsing] = useState(false);
  const [error, setError] = useState<string | undefined>(undefined);
  const [unmappedHeaderOptions, setUnmappedHeaderOptions] = useState<
    Array<string>
  >([]);
  const [claims, setClaims] = useState<Array<CreateInsuranceClaim>>([]);

  const [parsedCSV, setParsedCSV] = useState<
    Papa.ParseResult<unknown> | undefined
  >(undefined);

  const trpcContext = trpc.useContext();

  const insuranceClaimsMutation = trpc.insuranceClaims.importClaims.useMutation(
    {
      onSuccess: () => {
        trpcContext.insuranceClaims.invalidate();
      },
    }
  );

  // Parse CSV file and try to automatch columns
  const onFileSelected = useCallback((file: File) => {
    setAmountOfRowsOverLimit(0);
    setIsParsing(true);
    setCsvFile(file);
    Papa.parse(file, {
      header: true,
      worker: true,
      skipEmptyLines: true,
      // dynamicTyping: true,
      complete: function (results) {
        const limitedResults = {
          ...results,
          data: results.data.slice(0, ROW_LIMIT),
        };

        const newAmountOfRowsOverLimit =
          results.data.length - limitedResults.data.length;

        if (newAmountOfRowsOverLimit > 0) {
          setAmountOfRowsOverLimit(newAmountOfRowsOverLimit);
        }

        setParsedCSV(limitedResults);
        setIsParsing(false);
        if (
          !limitedResults.meta.fields ||
          limitedResults.meta.fields.length < Object.keys(Column).length
        ) {
          setError("CSV has to contain header");
        } else {
          setUnmappedHeaderOptions(limitedResults.meta.fields);

          // Automatching columns if possible
          const headers: CSVHeaders = {};
          const hiddenHeaders: CSVHiddenHeaders = {};

          for (const field of limitedResults.meta.fields) {
            const fieldLowerCase = field.toLocaleLowerCase();
            const matched = Object.values(Column).filter((column) => {
              const columnMatcher = columnMatchers[column];
              return columnMatcher.some((m) => {
                const matchWords = m.split(" ");
                return matchWords.every((w) => fieldLowerCase.includes(w));
              });
            });

            if (matched.length === 1) {
              headers[matched[0]] = field;
              continue;
            }

            const hiddenColumnsMatched = Object.values(HiddenColumn).filter(
              (column) => {
                const columnMatcher = hiddenColumnMatchers[column];
                return columnMatcher.some((m) => {
                  const matchWords = m.split(" ");
                  return matchWords.every((w) => fieldLowerCase.includes(w));
                });
              }
            );

            if (hiddenColumnsMatched.length === 1) {
              hiddenHeaders[hiddenColumnsMatched[0]] = field;
            }
          }

          setDefaultMappedColumns(headers);
          setHiddenHeaders(hiddenHeaders);
        }
      },
    });
  }, []);

  useEffect(() => {
    if (!mappedColumns) {
      return;
    }
    const mappedColumnNames = Object.values(mappedColumns);
    const hiddenColumnNames = Object.values(hiddenHeaders);

    const newClaims: Array<CreateInsuranceClaim> = (parsedCSV?.data || []).map(
      (r) => {
        const row = r as Record<string, string>;

        const result = [
          ...Object.entries(mappedColumns),
          ...Object.entries(hiddenHeaders),
        ].reduce(
          (sum, [fieldName, colName]) => ({
            ...sum,
            [fieldName]: row[colName],
          }),
          {}
        );

        const newClaim = CreateInsuranceClaimSchema.parse({
          ...result,
          additionalData: Object.getOwnPropertyNames(row)
            .filter(
              (rowKey) =>
                rowKey &&
                !mappedColumnNames.includes(rowKey) &&
                !hiddenColumnNames.includes(rowKey)
            )
            .reduce(
              (sum, rowKey) => ({
                ...sum,
                [rowKey]: row[rowKey],
              }),
              {}
            ),
        });

        return newClaim;
      }
    );

    setClaims(newClaims);
  }, [parsedCSV, mappedColumns, hiddenHeaders]);

  const onSubmit = useCallback(() => {
    insuranceClaimsMutation.mutate(
      { claims },
      {
        onSuccess: () => {
          onClose();
        },
      }
    );
  }, [claims, insuranceClaimsMutation, onClose]);

  if (isParsing) {
    return (
      <LoadingContainer>
        <LoadingSpinner />
        <p>Uploading file {csvFile?.name || ""}...</p>
      </LoadingContainer>
    );
  }

  if (insuranceClaimsMutation.isLoading) {
    return (
      <LoadingContainer>
        <LoadingSpinner />
        <p>Saving insurance claims...</p>
      </LoadingContainer>
    );
  }

  return (
    <Stack direction={"column"} sx={{ padding: "1rem" }}>
      <DialogSection>
        <DialogSectionContentHorizontal>
          <ParseCSVDialogTitle>
            {(csvFile && `Import claims from ${csvFile.name}`) ||
              "Upload Claims"}
          </ParseCSVDialogTitle>
          <Stack
            direction={"row"}
            flexWrap={"nowrap"}
            rowGap={0}
            gap={0}
            columnGap={0}
          >
            <IconButton
              aria-label="Close"
              sx={{
                height: "2rem",
                padding: 0,
              }}
              onClick={onClose}
            >
              <CloseIcon
                sx={{
                  color: "#005AC4",
                  verticalAlign: "middle",
                  fontSize: "2rem",
                  height: "2rem",
                }}
              />
            </IconButton>
          </Stack>
        </DialogSectionContentHorizontal>
      </DialogSection>
      {amountOfRowsOverLimit > 0 && (
        <Alert severity="warning" variant="standard">
          {`File is too large, only first ${ROW_LIMIT} rows will be imported, skipping ${amountOfRowsOverLimit} rows`}
        </Alert>
      )}
      {(parsedCSV && (
        <MapColumns
          options={unmappedHeaderOptions}
          claims={claims}
          onChange={(values) => {
            setMappedColumns(values);
          }}
          onSubmit={onSubmit}
          defaultMappedHeaders={defaultMappedColumns}
        />
      )) || <SelectFile onFileSelected={onFileSelected} />}
    </Stack>
  );
}

const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;
  min-height: 400px;
  border-bottom: 1px solid lightgray;
`;
