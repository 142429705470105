export type IconProps = {
  color: string;
};

export const PolylineDashedIcon = (props: IconProps) => {
  const { color } = props;
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_542_16789)">
        <path
          d="M3 21L12 3"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeDasharray="2 4"
        />
        <path
          d="M21 12L12 3"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeDasharray="2 4"
        />
        <circle cx="12" cy="3" r="3" fill={color} />
        <circle cx="21" cy="12" r="3" fill={color} />
        <circle cx="3" cy="21" r="3" fill={color} />
      </g>
      <defs>
        <clipPath id="clip0_542_16789">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
