// https://stackoverflow.com/questions/14484787/wrap-text-in-javascript
import { Fill, Stroke, Text } from "ol/style";

type TextWrapStrategy = "hide" | "shorten" | "wrap";

function stringDivider(
  str: string,
  width: number,
  spaceReplacer: string
): string {
  if (str.length > width) {
    let p = width;
    while (p > 0 && str[p] != " " && str[p] != "-") {
      p--;
    }
    if (p > 0) {
      let left;
      if (str.substring(p, p + 1) == "-") {
        left = str.substring(0, p + 1);
      } else {
        left = str.substring(0, p);
      }
      const right = str.substring(p + 1);
      return left + spaceReplacer + stringDivider(right, width, spaceReplacer);
    }
  }
  return str;
}

const getText = function (
  text: string,
  resolution: number,
  type: TextWrapStrategy,
  maxResolution: number,
  placement: "point" | "line"
) {
  if (resolution > maxResolution) {
    text = "";
  } else if (type == "hide") {
    text = "";
  } else if (type == "shorten") {
    text = text.substring(0, 12);
  } else if (type == "wrap" && (!placement || placement != "line")) {
    text = stringDivider(text, 16, "\n");
  }

  return text;
};

export const createText = (
  textToShow: string,
  resolution: number,
  textWrapStrategy: TextWrapStrategy = "wrap"
) => {
  return new Text({
    text: getText(textToShow, resolution, textWrapStrategy, 800, "point"),
    fill: new Fill({ color: "blue" }),
    stroke: new Stroke({ color: "#ffffff", width: 3 }),
    overflow: true,
  });
};
