import { z } from "zod";

export const CreateInsuranceClaimSchema = z.object({
  claimReference: z.string(),
  policyReference: z.string(),
  status: z.string(),
  address: z.string(),
  latitude: z.number({ coerce: true}).optional(),
  longitude: z.number({ coerce: true}).optional(),
  additionalData: z.record(z.string(), z.string()),
});

export type CreateInsuranceClaim = z.infer<typeof CreateInsuranceClaimSchema>;

export const GetInsuranceClaimSchema = z.object({
  claimReference: z.string(),
  policyReference: z.string(),
  created: z.date({ coerce: true }),
  updated: z.date({ coerce: true }),
  status: z.string(),
  additionalData: z.record(z.string(), z.string()),
  location: z.object({
    geo: z.object({
      type: z.literal("Point"),
      coordinates: z.tuple([z.number(), z.number()]),
    }),
    originalAddress: z.string(),
    address: z.string(),
    // region: z.string(),
  }),
  issues: z.object({
    dataMismatch: z.object({
      status: z.string().optional(),
      address: z.string().optional(),
      latitude: z.number({ coerce: true}).optional(),
      longitude: z.number({ coerce: true }).optional(),
      policyReference: z.string().optional(),
    }).optional(),
  }).optional(),
});

export type GetInsuranceClaim = z.infer<typeof GetInsuranceClaimSchema>;

export const UpdateInsuranceClaimSchema = z.object({
  claimReference: z.string(),
  policyReference: z.string().optional(),
  status: z.string().optional(),
  address: z.string().optional(),
  latitude: z.number({ coerce: true}).optional(),
  longitude: z.number({ coerce: true}).optional(),
});

export type UpdateInsuranceClaim = z.infer<typeof GetInsuranceClaimSchema>;