import { Capacitor } from "@capacitor/core";
import { axiosClient } from "src/lib/axiosClient";

export const downloadFile = async (url: string, filename: string) => {
  // Capacitor doesn't auto add cookies, so for mobile, we need to pass url param access token
  if (Capacitor.getPlatform() === "web") {
    const response = await axiosClient.get(url, {
      responseType: "blob",
      withCredentials: Capacitor.getPlatform() === "web",
    });

    const blob = response.data;
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = filename; // Set the desired filename for download
    link.click();
  } else {
    const link = document.createElement("a");
    link.href = url;
    link.click();
  }
};
