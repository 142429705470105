import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Autocomplete,
} from "@mui/material";
import styled from "@emotion/styled";
import { useState } from "react";
import { enqueueSnackbar } from "notistack";
import { useMutation, useQueryClient } from "react-query";
import { axiosClient } from "src/lib/axiosClient";
import {
  enqueueSavingSnackbar,
  enqueueSuccessSnackbar,
} from "src/shared/snackbar/SnackbarHelper";
import { User } from "src/lib/types";

type EditUserModalProps = {
  onClose: VoidFunction;
  user: User;
  options: string[];
};

export const EditUserModal = (props: EditUserModalProps) => {
  const { onClose, options } = props;
  const [user, setUser] = useState<User>(props.user);
  const queryClient = useQueryClient();
  const [roles, setRoles] = useState<string[]>(props.user.roles);
  const { mutate: saveUser } = useMutation(
    async (user: User): Promise<User> => {
      const finishSaving = enqueueSavingSnackbar();

      const res = (
        await axiosClient.put(`/users/${user.userId}`, {
          roles,
        })
      ).data;
      finishSaving();

      return res;
    },
    {
      onSuccess: () => {
        enqueueSuccessSnackbar();
      },
      onError: () => {
        enqueueSnackbar("Something went wrong", { variant: "error" });
      },
      onSettled: () => {
        queryClient.invalidateQueries({
          queryKey: [`users`],
        });
      },
    }
  );

  return (
    <Dialog open onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Edit User</DialogTitle>
      <DialogContent>
        <Wrapper>
          <Autocomplete
            size="small"
            options={options}
            multiple
            value={roles}
            forcePopupIcon={false}
            getOptionLabel={(opt) => {
              return opt
                .split("-")
                .map((word) => word[0].toUpperCase() + word.slice(1))
                .join(" ");
            }}
            onChange={(_, newRoles) => {
              return setRoles(newRoles);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label={"Roles"}
                InputProps={{
                  ...params.InputProps,
                  notched: false,
                }}
              />
            )}
          />
        </Wrapper>
      </DialogContent>
      <DialogActions
        sx={{
          display: "flex",
          flexDirection: "column",
          margin: "0 1rem 1rem 1rem",
        }}
      >
        <Button
          onClick={() => {
            saveUser(user);
            onClose();
          }}
          variant="contained"
          sx={{ width: "100%", margin: "1rem 0 0 0 !important" }}
        >
          Save
        </Button>
        <Button
          onClick={() => {
            onClose();
          }}
          variant="outlined"
          sx={{ width: "100%", margin: "1rem 0 0 0 !important" }}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const Wrapper = styled.div`
  & > :not(:last-child) {
    margin-bottom: 1rem;
  }
`;
