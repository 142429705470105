import { LoadingSpinner } from "@athena/components";
import { Event } from "@athena/server/src/api/types/event";
import styled from "@emotion/styled";
import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  Button,
  InputAdornment,
  Paper,
  TextField,
  Toolbar,
} from "@mui/material";
import { useState } from "react";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import { axiosClient } from "src/lib/axiosClient";
import Menu from "src/modules/menu/Menu";
import { useCurrentUser } from "src/shared/hooks/useCurrentUser";
import { usePageName } from "src/shared/hooks/usePageName";
import { EventsTable } from "./EventsTable";

interface EventTableToolbarProps {
  searchValue: string;
  onSearchChange: (value: string) => void;
}

function EventTableToolbar({
  searchValue,
  onSearchChange,
}: EventTableToolbarProps) {
  const { userData } = useCurrentUser();
  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
      }}
    >
      <TextField
        value={searchValue}
        onChange={(e) => onSearchChange(e.target.value)}
        placeholder="Search"
        sx={{ width: 300 }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
      {userData?.organisation?.orgType !== "eqc" && (
        <AssignContainer>
          <Link to="/event/new">
            <Button variant="contained" sx={{ width: 250 }}>
              Request Event
            </Button>
          </Link>
        </AssignContainer>
      )}
    </Toolbar>
  );
}

const AssignContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: lightgray;
  gap: 1rem;
  margin-left: auto;
`;

export const EventsList = () => {
  usePageName("Events");
  const { isLoading: loadingEvents, data: events } = useQuery(
    `events`,
    async (): Promise<Event[]> => (await axiosClient.get(`/events/global`)).data
  );
  const [searchValue, setSearchValue] = useState("");
  const { userData } = useCurrentUser();

  return (
    <Menu>
      <Box sx={{ width: "100%" }}>
        <Paper
          sx={{ width: "100%", mb: 2, borderRadius: 0, boxShadow: "none" }}
        >
          {loadingEvents ? (
            <LoadingSpinner />
          ) : (
            <>
              <EventTableToolbar
                searchValue={searchValue}
                onSearchChange={(value) => setSearchValue(value)}
              />
              <EventsTable
                events={(events || []).filter((event) => {
                  if (
                    event.status === "Requested" &&
                    event.requestedByOrganisationId !==
                      userData?.organisation?.organisationId
                  ) {
                    return false;
                  }
                  return event.name
                    ?.toLowerCase()
                    .includes(searchValue.toLowerCase());
                })}
              />
            </>
          )}
        </Paper>
      </Box>
    </Menu>
  );
};
