import { axiosClient } from "src/lib/axiosClient";
import { store } from "src/lib/storage";
import {
  enqueueSavingSnackbar,
  enqueueSuccessSnackbar,
} from "src/shared/snackbar/SnackbarHelper";

export const uploadFile = async (file: File, claimId: string) => {
  const { signedUrl, key } = (
    await axiosClient.post(`/claims/${claimId}/presigned-upload-url`, {
      fileName: file.name,
    })
  ).data;
  await fetch(signedUrl, {
    method: "PUT",
    body: file,
    headers: {
      "Content-Type": file.type, // Set the content type based on the file type
    },
  });
  return (
    await axiosClient.post(
      `/claims/${claimId}/presigned-upload-url/${key}/completed`,
      { fileName: file.name }
    )
  ).data;
};

export const backupClaimData = async (claimId: string) => {
  const finish = enqueueSavingSnackbar(
    "Backing up assessment data to the claim details attachments."
  );
  const claim = JSON.parse(await store.get("claim-" + claimId));
  const featuresFailedSync = JSON.parse(
    await store.get("featuresFailedSync-" + claimId)
  );
  const featuresToCreate = JSON.parse(
    await store.get("featuresToCreate-" + claimId)
  );
  const featuresToDelete = JSON.parse(
    await store.get("featuresToDelete-" + claimId)
  );
  const featuresToUpdate = JSON.parse(
    await store.get("featuresToUpdate-" + claimId)
  );
  // Convert the JSON object to a Blob object
  const jsonBlob = new Blob(
    [
      JSON.stringify(
        {
          claim,
          featuresFailedSync,
          featuresToCreate,
          featuresToUpdate,
          featuresToDelete,
        },
        null,
        2
      ),
    ],
    {
      type: "application/json",
    }
  );

  // Create a File object from the Blob object
  const jsonFile = new File(
    [jsonBlob],
    `failed-to-sync-data-backup-for-infinity-${new Date().toDateString()}.json`,
    {
      type: "application/json",
    }
  );

  await uploadFile(jsonFile, claimId);
  finish();
  enqueueSuccessSnackbar(
    "The assessment data has been backed up to the claim details attachments. Please contact Infinity Studio if you need assistance."
  );
};
