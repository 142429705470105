import { createTheme, ThemeProvider } from "@mui/material/styles";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import {
  MutationCache,
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";

import { trpc } from "./lib/api/trpc";
import { httpBatchLink, TRPCClientError } from "@trpc/client";
import { App as CapacitorApp, URLOpenListenerEvent } from "@capacitor/app";

import enGB from "date-fns/locale/en-GB";
import { createContext, ReactNode, useEffect, useState } from "react";
import { SnackbarProvider } from "notistack";
import { Capacitor, CapacitorCookies } from "@capacitor/core";
import { NetworkContext } from "./shared/hooks/useNetworkContext";
import { Network } from "@capacitor/network";
import { config } from "./lib/config";
import { AppRoutes } from "./Routes";
import { redirectToAuth } from "./lib/redirectToAuth";
import {
  BrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  Route,
  Routes,
  useLocation,
  useNavigationType,
} from "react-router-dom";
import {
  AuthenticateWithRedirectCallback,
  ClerkProvider,
  SignedIn,
  SignedOut,
  useAuth,
  useOrganizationList,
  useSession,
  useSignIn,
  useSignUp,
  useUser,
} from "@clerk/clerk-react";
import { SignIn } from "./modules/signIn/SignIn";
import { clearClerk, clerk, clerkPubKey, initClerk } from "./lib/auth";
import { LoadingPage } from "@athena/components";
import { useTimeout } from "ahooks";
import * as Sentry from "@sentry/react";
import { ActiveOrg } from "./modules/signIn/ActiveOrg";
import { VerifyEmail } from "./modules/signIn/VerifyEmail";

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

// import "@fontsource/inter"; disabled until we can fix the font weight issue

declare module "@mui/material/styles" {
  interface Theme {
    status: {
      danger: string;
    };
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    status?: {
      danger?: string;
    };
  }
}
const theme = createTheme({
  typography: {
    fontFamily: ["Inter"].join(","),
    htmlFontSize: 16,
    allVariants: {
      color: "#000",
    },
    h1: {
      fontSize: "2.5rem",
      fontWeight: "600",
      letterSpacing: "-0.5px",
      "@media (max-width:1200px)": {
        fontSize: "2.25rem",
      },
      "@media (max-width:600px)": {
        fontSize: "2rem",
      },
    },
    h2: {
      fontSize: "2rem",
      fontWeight: "600",
      letterSpacing: "-0.5px",
      "@media (max-width:1200px)": {
        fontSize: "1.75rem",
      },
      "@media (max-width:600px)": {
        fontSize: "1.5rem",
      },
    },
    h3: {
      fontSize: "1.75rem",
      fontWeight: "600",
      letterSpacing: "-0.5px",
      "@media (max-width:1200px)": {
        fontSize: "1.5rem",
      },
      "@media (max-width:600px)": {
        fontSize: "1.25rem",
      },
    },
    h4: {
      fontSize: "1.5rem",
      fontWeight: "600",
      letterSpacing: "-0.5px",
      "@media (max-width:1200px)": {
        fontSize: "1.25rem",
      },
      "@media (max-width:600px)": {
        fontSize: "1.15rem",
      },
    },
    h5: {
      fontSize: "1.25rem",
      fontWeight: "600",
      letterSpacing: "-0.5px",
      "@media (max-width:1200px)": {
        fontSize: "1.15rem",
      },
      "@media (max-width:600px)": {
        fontSize: "1.1rem",
      },
    },
    h6: {
      fontSize: "1.15rem",
      fontWeight: "600",
      letterSpacing: "-0.5px",
      "@media (max-width:1200px)": {
        fontSize: "1.05rem",
      },
      "@media (max-width:600px)": {
        fontSize: "1rem",
      },
    },
    body1: {
      fontSize: "1rem",
      fontWeight: "400",
    },
    subtitle2: {
      fontSize: "12px", // Walt feel free to change this
      fontWeight: "400",
    },
  },
  components: {
    MuiStack: {
      defaultProps: {
        flexWrap: "wrap",
        columnGap: "1rem",
        rowGap: "0.5rem",
      },
    },
    MuiAutocomplete: {
      defaultProps: {
        autoHighlight: true,
      },
    },

    MuiTextField: {
      defaultProps: {
        InputLabelProps: {
          shrink: true,
        },
        InputProps: {
          notched: false,
        },
      },
      styleOverrides: {
        root: {
          "& fieldset legend span": {
            background: "rgba(0,0,0,0)",
          },
          "& fieldset legend": {
            width: "0",
          },
        },
      },
    },

    MuiInputLabel: {
      styleOverrides: {
        root: {
          position: "relative",
          transform: "initial",
          fontSize: "14px",
          color: "inherit",
          whiteSpace: "break-spaces",
          maxWidth: "initial",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
          fontSize: "1rem",
          padding: "0.5rem 1rem",
          borderRadius: "8px",
          "&.MuiButton-outlined": {
            color: "#0067DF",
            border: "2px solid #0067DF",
          },
          "&.MuiButton-outlined.MuiButton-outlinedSuccess": {
            color: "#2e7d32",
            border: "2px solid #2e7d32",
          },
          "&.MuiButton-outlined.Mui-disabled": {
            color: "rgba(0,0,0,0.26)",
            border: "1px solid rgba(0,0,0,0.12)",
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          paddingTop: 8.5,
          paddingBottom: 8.5,
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: "12px",
          boxShadow: "0px 4px 32px rgba(0,0,0,0.1)",
          flexWrap: "wrap",
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: "none",
          color: "#0067DF",
          fontWeight: "500",
          fontSize: "1rem",
          "&.Mui-selected": {
            color: "#333",
          },
        },
      },
    },
  },
});

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN || "##SENTRY_DSN##",
  environment: import.meta.env.VITE_SENTRY_ENV || "##SENTRY_ENVIRONMENT##",
  release: import.meta.env.VITE_SENTRY_RELEASE || "##SENTRY_RELEASE##",
  integrations: [
    new Sentry.BrowserTracing({
      // See docs for support of different versions of variation of react router
      // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      ),
    }),
    new Sentry.Replay(),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  tracesSampleRate: 1.0,

  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  // tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

type PageNameContext = {
  pageName: string;
  setPageName: React.Dispatch<React.SetStateAction<string>>;
};

export const PageNameContext = createContext<PageNameContext>({
  pageName: "",
  setPageName: () => {},
});

function App() {
  const [pageName, setPageName] = useState("");
  const [online, setOnline] = useState<boolean>();
  useEffect(() => {
    (async () =>
      setOnline((await Network.getStatus()).connectionType !== "none"))();
    Network.addListener("networkStatusChange", (status) => {
      if (status.connectionType === "none") {
        setOnline(false);
        // clearClerk();
      } else {
        // initClerk();
        // We have to reload the user so that clerk doesn't get into a weird broken state
        clerk?.user?.reload().then(() => {
          setOnline(true);
        });
      }
    });
  }, []);

  const [queryClient] = useState(
    () =>
      new QueryClient({
        mutationCache: new MutationCache({
          onError: (error, variables, context, mutation) => {
            if (error instanceof TRPCClientError && error.data) {
              switch (error.data.code) {
                case "UNAUTHORIZED": {
                  return redirectToAuth();
                }
              }
            }
            Sentry.withScope((scope) => {
              scope.setContext("mutation", {
                mutationId: mutation.mutationId,
                variables: mutation.state.variables,
              });

              if (mutation.options.mutationKey) {
                scope.setFingerprint(
                  mutation.options.mutationKey.map((x) =>
                    typeof x === "string" ? x : JSON.stringify(x)
                  )
                );
              }
              Sentry.captureException(error);
            });
          },
        }),
        queryCache: new QueryCache({
          onError: (error, query) => {
            if (error instanceof TRPCClientError && error.data) {
              switch (error.data.code) {
                case "UNAUTHORIZED": {
                  return redirectToAuth();
                }
              }
            }

            Sentry.withScope((scope) => {
              scope.setContext("query", {
                queryHash: query.queryHash,
                queryKey: query.queryKey.map((x) =>
                  typeof x === "string" ? x : JSON.stringify(x)
                ),
              });

              scope.setFingerprint([query.queryHash]);
              Sentry.captureException(error);
            });
          },
        }),
      })
  );

  const [trpcClient] = useState(() =>
    trpc.createClient({
      links: [
        httpBatchLink({
          url: config.trpcUrl,
          fetch(url, options) {
            return fetch(url, {
              ...options,
              credentials: "include",
            });
          },
        }),
      ],
    })
  );

  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enGB}>
        <PageNameContext.Provider value={{ pageName, setPageName }}>
          <ClerkProvider publishableKey={clerkPubKey} Clerk={clerk}>
            <RedirectComp />
            <NetworkContext.Provider value={{ online: !!online }}>
              <SnackbarProvider
                anchorOrigin={{ horizontal: "right", vertical: "top" }}
              />
              <trpc.Provider client={trpcClient} queryClient={queryClient}>
                <QueryClientProvider client={queryClient}>
                  <BrowserRouter>
                    <SentryRoutes>
                      <Route path="/verify-email" element={<VerifyEmail />} />
                      <Route path="/sso-callback" element={<RedirectComp />} />
                      <Route
                        path="/*"
                        element={
                          online && clerk !== undefined ? (
                            <>
                              <SignedIn>
                                <ActiveOrg>
                                  <AppRoutes />{" "}
                                </ActiveOrg>
                              </SignedIn>
                              <SignedOut>
                                <SignIn />
                              </SignedOut>
                            </>
                          ) : (
                            <AppRoutes />
                          )
                        }
                      />
                    </SentryRoutes>
                  </BrowserRouter>
                </QueryClientProvider>
              </trpc.Provider>
            </NetworkContext.Provider>
          </ClerkProvider>
        </PageNameContext.Provider>
      </LocalizationProvider>
    </ThemeProvider>
  );
}

export default App;

const RedirectComp = () => {
  const { signIn, setActive, isLoaded } = useSignIn();
  const { signUp } = useSignUp();
  const { isLoaded: isSessionLoaded, session } = useSession();
  useEffect(() => {
    session?.getToken().then((token) => {
      if (!token) return;
      CapacitorCookies.setCookie({
        url: config.clientUrl,
        key: "__session",
        value: token,
      });
    });
  }, [isSessionLoaded, session]);
  useEffect(() => {
    const listener = CapacitorApp.addListener("appUrlOpen", async (data) => {
      if (!signIn || !signUp) return;
      // App opened with URL
      const url = new URL(data.url);

      const params = url.searchParams;

      const rotatingTokenNonce = params.get("rotating_token_nonce") || "";
      if (!rotatingTokenNonce) return;
      await signIn.reload({ rotatingTokenNonce });

      const { status, firstFactorVerification } = signIn;
      let createdSessionId = "";

      if (status === "complete") {
        createdSessionId = signIn.createdSessionId!;
        await setActive({
          session: createdSessionId,
        });
      } else if (firstFactorVerification.status === "transferable") {
        await signUp.create({
          transfer: true,
        });
      }
    });

    return () => listener.remove() as any;
  }, [signIn, signUp, isLoaded]);
  return null;
};
