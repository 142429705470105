export const getAccessTokenFromCookie = () => {
  const cookies = document.cookie.split(";");

  for (const cookie of cookies) {
    const [name, value] = cookie.trim().split("=");
    if (name === "__session") {
      return value;
    }
  }

  return null;
};
