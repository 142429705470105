import styled from "@emotion/styled";
import { CSSProperties, HTMLAttributes } from "react";

type variants = "sqaure" | "circle";

interface IconProps extends HTMLAttributes<HTMLDivElement> {
  variant?: variants;
  bg?: CSSProperties["background"];
}

const Container = styled.div<{
  bg?: CSSProperties["background"];
  variant: variants;
}>`
  display: flex;
  align-items: center;
  border-radius: ${(props) => {
    if (props.variant === "circle") return "50%";
    return "4px";
  }};
  ${(props) => (props.bg ? `background: ${props.bg};` : "")}
`;

export const Icon = ({ children, variant = "sqaure", ...rest }: IconProps) => {
  return (
    <Container {...rest} variant={variant}>
      {children}
    </Container>
  );
};
