import { Button } from "@mui/material";
import { enqueueSnackbar, closeSnackbar } from "notistack";
import { v4 as uuidv4 } from "uuid";

export const enqueueSavingSnackbar = (message?: string) => {
  const key = uuidv4();
  enqueueSnackbar(message || "Saving", {
    variant: "info",
    key,
    autoHideDuration: null,
  });
  return () => {
    closeSnackbar(key);
  };
};

export const enqueueRedirectSnackbar = (
  message: string,
  redirectUrl: string
) => {
  const key = uuidv4();
  enqueueSnackbar(message, {
    key,
    autoHideDuration: null,
    action: (snackbarId) => (
      <>
        <Button onClick={() => (window.location.href = redirectUrl)}>
          Back to Report
        </Button>
        <Button
          onClick={() => {
            closeSnackbar(snackbarId);
          }}
          color="warning"
        >
          Dismiss
        </Button>
      </>
    ),
  });
  return () => {
    closeSnackbar(key);
  };
};

export const enqueueSuccessSnackbar = (message?: string) => {
  const key = uuidv4();
  enqueueSnackbar(message || "Successfully saved", { variant: "success", key });
  return () => {
    closeSnackbar(key);
  };
};

export const enqueueWarningSnackbar = (message?: string) => {
  const key = uuidv4();
  enqueueSnackbar(message || "Warning", {
    variant: "warning",
    key,
    preventDuplicate: true,
  });
  return () => {
    closeSnackbar(key);
  };
};

export const enqueueErrorSnackbar = (message?: string) => {
  const key = uuidv4();
  enqueueSnackbar(message || "Sorry, something went wrong", {
    variant: "error",
    key,
  });
  return () => {
    closeSnackbar(key);
  };
};
