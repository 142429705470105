import axios, { AxiosInstance } from "axios";
import { config } from "src/lib/config";
import * as Sentry from "@sentry/react";
import { redirectToAuth } from "./redirectToAuth";

export const axiosClient: AxiosInstance = getAxiosClient();

function getAxiosClient() {
  const apiUrl = config.apiUrl;
  const client = axios.create({
    baseURL: apiUrl,
    withCredentials: true,
  });
  client.interceptors.request.use((req: any) => {
    req.meta = req.meta || {};
    req.meta.isBlob = req.responseType === "blob";
    return req;
  });
  // Create an interceptor that when the response type is blob, it will convert the response to a blob
  client.interceptors.response.use((response: any) => {
    // Check if the response is base64, unfortunately capacitor converts blob response to base64.
    if (response.config.meta.isBlob && typeof response.data === "string") {
      return {
        ...response,
        data: new Blob([response.data], { type: "application/octet-stream" }),
      };
    }

    return response;
  });
  client.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error && error.response && error.response.status === 401) {
        return redirectToAuth();
      } else if (
        error &&
        error.response &&
        error.response.status >= 400 &&
        error.response.status <= 600
      ) {
        Sentry.captureException(error);
      }
      throw error;
    }
  );
  return client;
}
