import styled from "@emotion/styled";
import {
  Button,
  Chip,
  InputAdornment,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { NewSurveyModal } from "./NewSurveyModal";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { axiosClient } from "src/lib/axiosClient";
import { EditSurveyModal } from "./EditSurveyModal";
import { formatDate } from "src/lib/date";
import { config } from "src/lib/config";

export type Survey = {
  surveyName?: string;
  surveyDate?: string;
  surveyId?: string;
  totalFileSize?: number;
  status?: string;
};

type SurveyFeature = {
  properties: {
    survey_id: string;
    survey_name: string;
    survey_date: string;
  };
};

export const SurveyManagement = () => {
  const [search, setSearch] = useState("");

  const { isLoading, data: surveys } = useQuery(
    `surveys`,
    async (): Promise<Survey[]> => (await axiosClient.get(`/surveys`)).data
  );
  const queryClient = useQueryClient();
  const [showAddSurvey, setShowAddSurvey] = useState(false);
  const [editSurveyId, setEditSurveyId] = useState<string>();
  const { mutate: saveSurvey } = useMutation(
    async (survey: Survey): Promise<Survey> => {
      const res = (
        await axiosClient.put(`/surveys/${survey.surveyId}`, {
          surveyName: survey.surveyName,
          surveyDate: survey.surveyDate,
          completedUpload: true,
          status: survey.status,
        })
      ).data;

      return res;
    },
    {
      onSettled: () => {
        queryClient.invalidateQueries({
          queryKey: [`surveys`],
        });
      },
    }
  );
  useEffect(() => {
    for (const survey of (surveys || []).filter(
      (s) => s.status !== "Active" && s.surveyName
    )) {
      axiosClient
        .get(
          `${config.gisApiUrl}/geo/ath/features/collections/drone_survey_area_v1/items?survey_id=${survey.surveyId}`
        )
        .then((response) => {
          const features = response.data.features;
          if (
            features.find(
              (f: SurveyFeature) => f.properties.survey_id === survey.surveyId
            )
          ) {
            saveSurvey({ ...survey, status: "Active" });
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [isLoading]);
  if (isLoading) return <></>;
  const editingSurvey = surveys?.find(
    (survey) => survey.surveyId === editSurveyId
  );

  return (
    <div>
      {showAddSurvey && (
        <NewSurveyModal
          onClose={() => {
            setShowAddSurvey(false);
          }}
        />
      )}
      {editSurveyId && editingSurvey && (
        <EditSurveyModal
          onClose={() => {
            setEditSurveyId(undefined);
          }}
          survey={editingSurvey}
        />
      )}
      <Paper
        sx={{
          padding: 2,
          mb: 2,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <TextField
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          value={search}
          placeholder="Search surveys"
          onChange={(e) => setSearch(e.target.value)}
        />
        <Button
          variant="contained"
          sx={{ width: 200 }}
          onClick={() => {
            setShowAddSurvey(true);
          }}
        >
          Add Survey
        </Button>
      </Paper>
      <Paper sx={{ padding: 2 }}>
        <BaseSurveyTable>
          <Typography variant="subtitle1" sx={{ color: "#0067DF" }}>
            Survey
          </Typography>
          <Typography variant="subtitle1" sx={{ color: "#0067DF" }}>
            Size
          </Typography>
          <Typography variant="subtitle1" sx={{ color: "#0067DF" }}>
            Date of survey
          </Typography>
          <Typography variant="subtitle1" sx={{ color: "#0067DF" }}>
            Status
          </Typography>
          <div></div>
          {surveys
            ?.filter(
              (survey) =>
                survey.surveyName &&
                survey.surveyName?.toLowerCase().includes(search.toLowerCase())
            )
            .map((basesurvey) => {
              return (
                <React.Fragment key={basesurvey.surveyId}>
                  <BaseSurveyCell>
                    <BaseSurveyInfo>
                      <Typography variant={"caption"}>
                        {basesurvey.surveyName}
                      </Typography>
                    </BaseSurveyInfo>
                  </BaseSurveyCell>
                  <BaseSurveyCell>
                    <Typography variant={"caption"}>
                      {bytesToSize(basesurvey?.totalFileSize)}
                    </Typography>
                  </BaseSurveyCell>
                  <BaseSurveyCell>
                    <Typography variant={"caption"}>
                      {basesurvey?.surveyDate
                        ? formatDate(basesurvey?.surveyDate)
                        : ""}
                    </Typography>
                  </BaseSurveyCell>
                  <BaseSurveyCell>
                    <Chip
                      label={basesurvey.status}
                      sx={{
                        ...(basesurvey.status === "Active"
                          ? { backgroundColor: "#009706", color: "#ffffff" }
                          : {}),
                        ...(basesurvey.status === "Processing"
                          ? { backgroundColor: "#34a2eb", color: "#ffffff" }
                          : {}),
                        ...(basesurvey.status === "Inactive"
                          ? { backgroundColor: "red", color: "#ffffff" }
                          : {}),
                        paddingLeft: 4,
                        paddingRight: 4,
                        width: 180,
                      }}
                    />
                  </BaseSurveyCell>
                  <BaseSurveyCell>
                    <Button
                      variant="outlined"
                      sx={{ width: 100 }}
                      onClick={() => {
                        setEditSurveyId(basesurvey.surveyId);
                      }}
                    >
                      Edit
                    </Button>
                  </BaseSurveyCell>
                </React.Fragment>
              );
            })}
        </BaseSurveyTable>
      </Paper>
    </div>
  );
};

export const bytesToSize = (bytes = 0) => {
  const sizes = ["Bytes", "KB", "MB", "GB"];
  if (bytes === 0) return "0 Byte";
  const i = Math.floor(Math.log(bytes) / Math.log(1024));
  return parseFloat((bytes / Math.pow(1024, i)).toFixed(2)) + " " + sizes[i];
};

const BaseSurveyInfo = styled.div``;
const BaseSurveyCell = styled.div`
  &:not(:nth-last-of-type(-n + 5)) {
    border-bottom: 1px solid #d3d3d3;
  }
  padding-top: 1rem;
  padding-bottom: 1rem;
  display: flex;
  align-items: center;
`;
const BaseSurveyTable = styled.div`
  display: grid;
  grid-template-columns: 3fr 1fr 1fr 1fr 1fr;
  width: 100%;
`;
