import { TReportTemplateStyles } from "@athena/server/src/api/types/reportTemplate";
import { Box, MenuItem } from "@mui/material";
import { Control, FieldValues } from "react-hook-form";
import { FormSelect } from "src/shared/form/FormSelect";
import { FormTextField } from "src/shared/form/FormTextField";

interface BorderStyleProps<T extends FieldValues> {
  type: "table" | "photograph";
  control: Control<T>;
  errors?: any;
}

export const BorderStyle = (props: BorderStyleProps<TReportTemplateStyles>) => {
  const { type, control, errors } = props;
  return (
    <>
      <div>
        <h3 style={{ marginBottom: "1rem" }}>{type} border styles</h3>

        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            gap: "1rem",
          }}
        >
          <FormTextField
            control={control}
            name={`${type}.width`}
            label="Table Width"
            sx={{ minWidth: 100, maxWidth: 100 }}
            InputProps={{
              endAdornment: "%",
            }}
          />
          <FormTextField
            control={control}
            name={`${type}.border.width`}
            label="Border Width"
            sx={{ minWidth: 100, maxWidth: 100 }}
            InputProps={{
              endAdornment: "pt",
            }}
          />

          <FormSelect
            control={control}
            name={`${type}.border.style`}
            placeholder="Border Style"
            label="Border Style"
            sx={{ minWidth: 150, maxWidth: 150 }}
          >
            <MenuItem value="dotted">Dotted</MenuItem>
            <MenuItem value="dashed">Dashed</MenuItem>
            <MenuItem value="solid">Solid</MenuItem>
            <MenuItem value="double">Double</MenuItem>
            <MenuItem value="groove">Groove</MenuItem>
            <MenuItem value="ridge">Ridge</MenuItem>
            <MenuItem value="inset">Inset</MenuItem>
            <MenuItem value="outset">Outset</MenuItem>
            <MenuItem value="none">None</MenuItem>
            <MenuItem value="hidden">Hidden</MenuItem>
          </FormSelect>
          <FormTextField
            control={control}
            name={`${type}.border.color`}
            type="color"
            label="Border Color"
            sx={{ minWidth: 70 }}
          />
          <FormSelect
            control={control}
            name={`${type}.border.collapse`}
            placeholder="Collapse"
            label="Collapse"
            sx={{ minWidth: 150, maxWidth: 150 }}
          >
            <MenuItem value="collapse">Collapse</MenuItem>
            <MenuItem value="separate">Separate</MenuItem>
            <MenuItem value="initial">Initial</MenuItem>
            <MenuItem value="inherit">Inherit</MenuItem>
          </FormSelect>
        </Box>
      </div>
    </>
  );
};
