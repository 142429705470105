import {} from "@clerk/clerk-js";
import { useSignUp } from "@clerk/clerk-react";
import { Button, Dialog, DialogContent, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ReactCodeInput from "react-verification-code-input";

export function VerifyEmail() {
  const { signUp, setActive, isLoaded } = useSignUp();
  const [validating, setValidating] = useState(false);
  const [validatingError, setValidatingError] = useState<string>();
  const navigate = useNavigate();
  useEffect(() => {
    if (isLoaded) {
      signUp?.prepareEmailAddressVerification();
    }
  }, [signUp, isLoaded]);

  const verify = async (val: string) => {
    if (!signUp) return;
    setValidating(true);
    setValidatingError(undefined);

    // Attempt to verify the user providing the
    // one-time code they received.
    try {
      await signUp.attemptEmailAddressVerification({
        code: val,
      });
      await setActive({ session: signUp.createdSessionId });
      navigate("/", { replace: true });
    } catch (e) {
      console.log(e);
      setValidatingError("Invalid code");
    }

    setValidating(false);
  };

  return (
    <Dialog open>
      <DialogContent sx={{ minWidth: 400 }}>
        <Typography variant="h4">Verify Email</Typography>

        <Box
          sx={{
            mt: 2,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <Typography variant="subtitle1">
            Check your email and enter the code below
          </Typography>
          {validatingError && <Typography color="red">Invalid code</Typography>}

          <ReactCodeInput
            className="code-input"
            onChange={(val) => {
              if (val.length === 6) {
                verify(val);
              }
            }}
            loading={validating}
          />
          <Button
            variant="outlined"
            sx={{ mt: 2 }}
            onClick={() => {
              window.location.href = "/";
            }}
          >
            Login with another method
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
