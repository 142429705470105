import {
  enqueueSavingSnackbar,
  enqueueSuccessSnackbar,
} from "src/shared/snackbar/SnackbarHelper";

import { LoadingSpinner } from "@athena/components";
import CloseIcon from "@mui/icons-material/Close";
import { Button, Divider, Paper, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { axiosClient } from "src/lib/axiosClient";

interface DomainRequest {
  name: string;
  domain: string;
}

export const DomainManager = () => {
  const queryClient = useQueryClient();
  const [currentDomains, setCurrentDomains] = useState<DomainRequest[]>([]);
  const [newDomain, setNewDomain] = useState<DomainRequest>({
    name: "",
    domain: "",
  });

  const { isLoading: loading, data: domains } = useQuery(
    `infinityGuardianDomains`,
    async (): Promise<DomainRequest[]> =>
      (await axiosClient.get("/admin/domains")).data,
    {
      onSuccess: (data) => {
        setCurrentDomains((prev) => {
          if (prev.length === 0) return data;
          return prev;
        });
      },
    }
  );

  const saveDomains = async () => {
    const finishedSaving = enqueueSavingSnackbar();
    const res = (await axiosClient.put(`/admin/domains`, currentDomains)).data;
    finishedSaving();
    enqueueSuccessSnackbar("Saved domains.");
    queryClient.invalidateQueries({
      queryKey: [`infinityGuardianDomains`],
    });
  };

  const handleAddDomain = () => {
    setCurrentDomains([...(currentDomains || []), newDomain]);
    setNewDomain({ name: "", domain: "" });
  };

  if (loading) return <LoadingSpinner />;
  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: 2,
          mb: 2,
        }}
      >
        <TextField
          label="Name"
          value={newDomain.name}
          onChange={(e) => setNewDomain({ ...newDomain, name: e.target.value })}
        />
        <TextField
          label="Domain"
          value={newDomain.domain}
          onChange={(e) =>
            setNewDomain({ ...newDomain, domain: e.target.value })
          }
        />
        <Button
          onClick={handleAddDomain}
          variant="contained"
          sx={{ minWidth: 100, height: 40, mt: 2.5 }}
        >
          Add
        </Button>

        <Button
          onClick={saveDomains}
          variant="contained"
          sx={{ minWidth: 100, height: 40, mt: 2.5 }}
        >
          Save
        </Button>
      </Box>
      <Divider />
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          gap: 1,
          mt: 2,
        }}
      >
        {(!currentDomains || currentDomains?.length === 0) && (
          <span>No domains found.</span>
        )}
        {currentDomains
          ?.sort((a, b) => a.name.localeCompare(b.name))
          .map((domain, index) => (
            <Paper
              key={index}
              sx={{
                p: 2,
                width: "fit-content",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 2,
                  width: "fit-content",
                }}
              >
                <Typography>{domain.name} </Typography>
                <Typography>{domain.domain} </Typography>
              </Box>
              <CloseIcon
                sx={{ cursor: "pointer" }}
                onClick={() => {
                  setCurrentDomains(
                    currentDomains.filter((_, i) => i !== index)
                  );
                }}
              />
            </Paper>
          ))}
      </Box>
    </Box>
  );
};
