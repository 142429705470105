import styled from "@emotion/styled";
import { IconButton, Stack } from "@mui/material";
import { FC, ReactNode, Suspense, useCallback, useRef, useState } from "react";

import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import CloseIcon from "@mui/icons-material/Close";

import { PickingInfoWithPopups } from "src/modules/spatialPortal/mapLib/types";
import { FeaturePopupErrorBoundary } from "./components/FeaturePopupErrorBoundary";
import { FeaturePopupSkeleton } from "./components/FeaturePopupSkeleton";
import {
  PopupSection,
  PopupSectionContentHorizontal,
} from "./components/styled";

interface PopupBodyProps {
  picked: Array<Required<PickingInfoWithPopups>>;
  onClose: () => void;
}

interface Page {
  title: string;
  render: () => ReactNode;
}

export const FeaturePopup: FC<PopupBodyProps> = (props) => {
  return (
    <Suspense fallback={<FeaturePopupSkeleton />}>
      <PopupPageSelector {...props} />
    </Suspense>
  );
};

const PopupPageSelector: FC<PopupBodyProps> = (props: PopupBodyProps) => {
  const boundaryRef = useRef<FeaturePopupErrorBoundary>(null);
  const pages: Array<Page> = [
    ...new Map(
      props.picked.flatMap((x) => x.popups).map((p) => [p.key, p])
    ).values(),
  ].map((popup) => ({
    title: popup.title,
    render: () => popup.component,
  }));

  const [page, setPage] = useState<number>(0);
  const maxPage = pages.length - 1;

  const previousPage = useCallback(() => {
    let newValue = page - 1;
    if (newValue < 0) {
      newValue = maxPage;
    }

    setPage(newValue);
    boundaryRef.current?.reset();
  }, [page]);

  const nextPage = useCallback(() => {
    let newValue = page + 1;
    if (newValue > maxPage) {
      newValue = 0;
    }

    setPage(newValue);
    boundaryRef.current?.reset();
  }, [page]);

  const selectedPage = pages[page];

  if (!selectedPage) {
    return <>🚧UNSUPPORTED🚧</>;
  }
  return (
    <Stack>
      <PopupSection>
        <PopupSectionContentHorizontal>
          <PopupHeading>{selectedPage.title}</PopupHeading>
          <Stack
            direction={"row"}
            flexWrap={"nowrap"}
            rowGap={0}
            gap={0}
            columnGap={0}
          >
            <IconButton
              aria-label="Previous"
              sx={{
                height: "2rem",
                padding: 0,
              }}
              onClick={previousPage}
            >
              <ChevronLeftIcon
                sx={{
                  color: "#005AC4",
                  verticalAlign: "middle",
                  fontSize: "2rem",
                  height: "2rem",
                }}
              />
            </IconButton>
            <PopupPageIndicator>
              {page + 1}/{maxPage + 1}
            </PopupPageIndicator>
            <IconButton
              aria-label="Next"
              sx={{
                height: "2rem",
                padding: 0,
              }}
              onClick={nextPage}
            >
              <ChevronRightIcon
                sx={{
                  color: "#005AC4",
                  verticalAlign: "middle",
                  fontSize: "2rem",
                  height: "2rem",
                }}
              />
            </IconButton>
            <IconButton
              aria-label="Close"
              sx={{
                height: "2rem",
                padding: 0,
              }}
              onClick={props.onClose}
            >
              <CloseIcon
                sx={{
                  color: "#005AC4",
                  verticalAlign: "middle",
                  fontSize: "2rem",
                  height: "2rem",
                }}
              />
            </IconButton>
          </Stack>
        </PopupSectionContentHorizontal>
      </PopupSection>
      <FeaturePopupErrorBoundary ref={boundaryRef}>
        {selectedPage.render()}
      </FeaturePopupErrorBoundary>
    </Stack>
  );
};

const PopupHeading = styled.h4`
  font-size: 1.375rem;
  font-weight: 600;
  line-height: 2rem;
`;

const PopupPageIndicator = styled.span`
  font-size: 1rem;
  font-weight: 700;
  line-height: 2rem;
`;
