import styled from "@emotion/styled";
import { JSONContent } from "@tiptap/core";
import BubbleMenuExtension from "@tiptap/extension-bubble-menu";
import Table from "@tiptap/extension-table";
import TableHeader from "@tiptap/extension-table-header";
import TableRow from "@tiptap/extension-table-row";
import TextAlign from "@tiptap/extension-text-align";
import Underline from "@tiptap/extension-underline";
import { EditorContent, useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import {
  Ref,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { TextStyleExtended } from "./FontSize";
import { ImageResize } from "./ImageResize";
import { LockedValue } from "./LockedValue";
import { MenuBar } from "./MenuBar";
import { TableCellExtension } from "./TableCellExtension";
import { TemplateVariable } from "./TemplateVariable";
import "./style.css";
import { CommentExtension } from "./trackChanges/CommentExtension";
import TrackChangeExtension from "./trackChanges/TrackedChangesExtension";
// https://github.com/sereneinserenade/tiptap-comment-extension-react
import { UpdateClaim } from "@athena/server/src/api/types/claimAssessment";
import Color from "@tiptap/extension-color";
import Superscript from "@tiptap/extension-superscript";
import uniqolor from "uniqolor";
import { useCurrentUserContext } from "../hooks/useCurrentUserContext";
import { PageBreak } from "./PageBreak";
interface InlineEditorProps {
  className?: string;
  content: string | JSONContent;
  onContentChange?: (content: JSONContent) => void;
  onImageUpload?: (file: File) => Promise<{ url: string }>;
  readOnly?: boolean;
  claim?: UpdateClaim;
  editorId?: string;
  org?: { logoUrl: string };
  orientation?: "portrait" | "landscape";
  onLoad?: VoidFunction;
  includeStyles?: boolean;
}

export type InlineEditorRef = {
  // Define the methods or properties that you want to expose
  // through the ref object
  addTemplateVariable: (
    value: string,
    label: string,
    description: string,
    hasConfig: boolean
  ) => void;

  getHtml: () => string | undefined;
};

export const InlineEditor = forwardRef(
  (
    {
      className,
      content,
      onContentChange,
      onImageUpload,
      readOnly,
      claim,
      editorId,
      org,
      orientation = "portrait",
      onLoad,
      includeStyles = true,
    }: InlineEditorProps,
    ref: Ref<InlineEditorRef>
  ) => {
    const [mountedEditor, setMountedEditor] = useState(false);

    useEffect(() => {
      // Hack, useEditor on create is triggering before content dom elements are rendered
      if (mountedEditor) return;
      const element = document.getElementById(editorId || "editor");
      if (element) {
        setMountedEditor(true);
        onLoad?.();
      }
    });
    const editorRef = useRef<HTMLDivElement>(null);
    const fileInput = useRef<HTMLInputElement>(null);
    const { user } = useCurrentUserContext();
    const editor = useEditor({
      editorProps: {
        attributes: {
          id: editorId || "editor",
          class: includeStyles ? "report-editor" : "",
          style: "position: relative; margin: 0;",
        },
      },
      editable: !readOnly,
      extensions: [
        // HardBreak,
        PageBreak,
        StarterKit,
        Table,
        TableCellExtension,
        TableRow,
        TableHeader,
        Superscript,
        BubbleMenuExtension,
        ImageResize,
        Color.configure({
          types: ["textStyle"],
        }),
        LockedValue.configure({
          getData: () => {
            return { claim, org };
          },
        }),
        Underline,
        TextStyleExtended,
        TemplateVariable,
        TextAlign.configure({
          types: ["heading", "paragraph"],
        }),
        TrackChangeExtension.configure({
          enabled: false,
          userId: user.userId,
          userName: user.firstName + " " + user.lastName,
        }),
        // ReadOnlyValueExtension,
        CommentExtension.configure({
          userId: user.userId,
          userName: user.firstName + " " + user.lastName,
          color: uniqolor(user.userId, {
            format: "rgb",
            saturation: 80,
            lightness: [70, 80],
          }).color,
        }),
      ],
      onUpdate: (e) => {
        onContentChange?.(e.editor.getJSON());
      },
      content,
    });

    useImperativeHandle(ref, () => ({
      getHtml: () => {
        if (editor) {
          return editor.getHTML();
        }
      },
      addTemplateVariable(
        value: string,
        label: string,
        description: string,
        hasConfig: boolean
      ) {
        if (editor) {
          editor
            .chain()
            .focus()
            .setTemplateVariable({ label, value, description, hasConfig })
            .run();
        }
      },
    }));

    return (
      <div style={{ width: "100%" }}>
        <input
          type="file"
          name="file"
          accept="image/*"
          onChange={(event) => {
            if (!event.target.files) return;
            onImageUpload?.(event.target.files[0]).then((res) => {
              if (editor && fileInput.current) {
                fileInput.current.value = "";
                editor.chain().focus().setImage({ src: res.url }).run();
              }
            });
          }}
          ref={fileInput}
          style={{ display: "none" }}
        />

        <EditorContainer ref={editorRef} className={className}>
          {editor && !readOnly && (
            <MenuBar
              editor={editor}
              onImageAdd={() => {
                if (fileInput.current) {
                  fileInput.current.click();
                }
              }}
              trackedChangesEnabled={false}
            />
          )}
          <ContentContainer readOnly={readOnly} orientation={orientation}>
            <EditorContent editor={editor} />
          </ContentContainer>
        </EditorContainer>
      </div>
    );
  }
);

const EditorContainer = styled.div`
  border-radius: 4px;
`;

type ContentContainerProps = {
  readOnly?: boolean;
  orientation: "portrait" | "landscape";
};
const ContentContainer = styled.div<ContentContainerProps>`
  display: flex;
  margin-left: auto;
  margin-right: auto;
  border: ${(props) => (props.readOnly ? "initial" : "white")};
  border-radius: 4px;
  position: relative;
  flex: 1;
  & > div {
    width: 100%;
  }
  width: ${(props) =>
    props.orientation === "landscape"
      ? "218mm"
      : "297mm"}; /* Width of A4 paper in millimeters */
  height: ${(props) =>
    props.readOnly ? "auto" : "200px"}; /* Height of A4 paper in millimeters */
  border: ${(props) =>
    props.readOnly
      ? "initial"
      : "1px solid #ccc"}; /* Optional: Add border for visual separation */
  overflow-y: ${(props) =>
    props.readOnly ? "initial" : "scroll"}; /* Enable vertical scrolling */
  padding: 2rem;
  @media screen {
    width: 100% !important;
    height: auto !important;
  }
`;
