import { useParams } from "react-router-dom";

export const useClaimId = () => {
  const { claimId } = useParams();

  if (!claimId) {
    throw new Error("Claim ID not found");
  }

  return claimId;
};
