import styled from "@emotion/styled";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import DescriptionIcon from "@mui/icons-material/Description";
import EqualizerIcon from "@mui/icons-material/Equalizer";
import EventIcon from "@mui/icons-material/Event";
import HomeIcon from "@mui/icons-material/Home";
import ListIcon from "@mui/icons-material/List";
import MapIcon from "@mui/icons-material/Map";
import PolicyIcon from "@mui/icons-material/Policy";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import SettingsIcon from "@mui/icons-material/Settings";
import IconButton from "@mui/material/IconButton";
import * as React from "react";
import { Link } from "react-router-dom";
import { useCurrentUser } from "src/shared/hooks/useCurrentUser";
import { Toolbar } from "../../shared/toolbar/Toolbar";

import Tooltip from "@mui/material/Tooltip";
import { Box } from "@mui/system";

export default function Menu({
  children,
  hideOverflow = false,
}: {
  children: React.ReactNode;
  hideOverflow?: boolean;
}) {
  const { userData, isFetched } = useCurrentUser();
  const { user, organisation } = userData || {};

  if (!isFetched || !user) {
    return null;
  }

  return (
    <>
      <Toolbar />
      <Main>
        <Sidebar>
          <div className="SidebarChildren">
            {organisation?.orgType === "loss-adjuster" && (
              <>
                <Link to="/">
                  <Tooltip title="Home" placement="right">
                    <IconButton
                      edge="start"
                      aria-label="menu"
                      sx={{
                        display: "flex",
                        width: "4rem",
                        height: "3rem",
                        alignItems: "center",
                        justifyItems: "center",
                        margin: 0,

                        color: "#0067DF",
                      }}
                    >
                      <HomeIcon fontSize="medium" sx={{}} />
                    </IconButton>
                  </Tooltip>
                </Link>
                <Link to="/home">
                  <Tooltip title="Overview" placement="right">
                    <IconButton
                      edge="start"
                      aria-label="menu"
                      sx={{
                        display: "flex",
                        width: "4rem",
                        height: "3rem",
                        alignItems: "center",
                        justifyItems: "center",
                        margin: 0,

                        color: "#0067DF",
                      }}
                    >
                      <EqualizerIcon fontSize="medium" sx={{}} />
                    </IconButton>
                  </Tooltip>
                </Link>
                <Link to="/map">
                  <Tooltip title="Spatial Portal" placement="right">
                    <IconButton
                      edge="start"
                      aria-label="menu"
                      sx={{
                        display: "flex",
                        width: "4rem",
                        height: "3rem",
                        alignItems: "center",
                        justifyItems: "center",
                        margin: 0,
                        color: "#0067DF",
                      }}
                    >
                      <MapIcon fontSize="medium" sx={{}} />
                    </IconButton>
                  </Tooltip>
                </Link>
                <Link to="/claims">
                  <IconButton
                    edge="start"
                    aria-label="menu"
                    sx={{
                      display: "flex",
                      width: "4rem",
                      height: "3rem",
                      alignItems: "center",
                      justifyItems: "center",
                      margin: 0,
                      color: "#0067DF",
                    }}
                  >
                    <ListIcon fontSize="medium" sx={{}} />
                  </IconButton>
                </Link>
              </>
            )}
            {["eqc", "insurer"].includes(organisation?.orgType || "") && (
              <>
                <Link to="/">
                  <Tooltip title="Home" placement="right">
                    <IconButton
                      edge="start"
                      aria-label="menu"
                      sx={{
                        display: "flex",
                        width: "4rem",
                        height: "3rem",
                        alignItems: "center",
                        justifyItems: "center",
                        margin: 0,

                        color: "#0067DF",
                      }}
                    >
                      <HomeIcon fontSize="medium" sx={{}} />
                    </IconButton>
                  </Tooltip>
                </Link>
                <Link to="/engineering-claims">
                  <IconButton
                    edge="start"
                    aria-label="menu"
                    sx={{
                      display: "flex",
                      width: "4rem",
                      height: "3rem",
                      alignItems: "center",
                      justifyItems: "center",
                      margin: 0,
                      color: "#0067DF",
                    }}
                  >
                    <ListIcon fontSize="medium" sx={{}} />
                  </IconButton>
                </Link>
                {organisation?.orgType === "insurer" && (
                  <>
                    <Link to="/insurance-policies">
                      <IconButton
                        edge="start"
                        aria-label="menu"
                        sx={{
                          display: "flex",
                          width: "4rem",
                          height: "3rem",
                          alignItems: "center",
                          justifyItems: "center",
                          margin: 0,
                          color: "#0067DF",
                        }}
                      >
                        <PolicyIcon fontSize="medium" sx={{}} />
                      </IconButton>
                    </Link>
                    <Link to="/insurance-claims">
                      <IconButton
                        edge="start"
                        aria-label="menu"
                        sx={{
                          display: "flex",
                          width: "4rem",
                          height: "3rem",
                          alignItems: "center",
                          justifyItems: "center",
                          margin: 0,
                          color: "#0067DF",
                        }}
                      >
                        <ReceiptLongIcon fontSize="medium" sx={{}} />
                      </IconButton>
                    </Link>
                  </>
                )}
                <Link to="/intelligence">
                  <IconButton
                    edge="start"
                    aria-label="menu"
                    sx={{
                      display: "flex",
                      width: "4rem",
                      height: "3rem",
                      alignItems: "center",
                      justifyItems: "center",
                      margin: 0,
                      color: "#0067DF",
                    }}
                  >
                    <QueryStatsIcon fontSize="medium" sx={{}} />
                  </IconButton>
                </Link>
                <Link to="/events">
                  <IconButton
                    edge="start"
                    aria-label="menu"
                    sx={{
                      display: "flex",
                      width: "4rem",
                      height: "3rem",
                      alignItems: "center",
                      justifyItems: "center",
                      margin: 0,
                      color: "#0067DF",
                    }}
                  >
                    <EventIcon fontSize="medium" sx={{}} />
                  </IconButton>
                </Link>
                {user.roles.includes("admin") && (
                  <Link to="/admin/users">
                    <IconButton
                      edge="start"
                      aria-label="menu"
                      sx={{
                        display: "flex",
                        width: "4rem",
                        height: "3rem",
                        alignItems: "center",
                        justifyItems: "center",
                        margin: 0,
                        color: "#0067DF",
                      }}
                    >
                      <AdminPanelSettingsIcon fontSize="medium" sx={{}} />
                    </IconButton>
                  </Link>
                )}
              </>
            )}
            {(organisation?.orgType === "engineering" ||
              !organisation?.orgType) && (
              <>
                <Link to="/">
                  <Tooltip title="Home" placement="right">
                    <IconButton
                      edge="start"
                      aria-label="menu"
                      sx={{
                        display: "flex",
                        width: "4rem",
                        height: "3rem",
                        alignItems: "center",
                        justifyItems: "center",
                        margin: 0,

                        color: "#0067DF",
                      }}
                    >
                      <HomeIcon fontSize="medium" sx={{}} />
                    </IconButton>
                  </Tooltip>
                </Link>

                <Link to="/claim">
                  <Tooltip title="Claims" placement="right">
                    <IconButton
                      edge="start"
                      aria-label="menu"
                      sx={{
                        display: "flex",
                        width: "4rem",
                        height: "3rem",
                        alignItems: "center",
                        justifyItems: "center",
                        margin: 0,
                        color: "#0067DF",
                      }}
                    >
                      <ListIcon fontSize="medium" sx={{}} />
                    </IconButton>
                  </Tooltip>
                </Link>
                <Link to="/reports">
                  <Tooltip title="Reports" placement="right">
                    <IconButton
                      edge="start"
                      aria-label="menu"
                      sx={{
                        display: "flex",
                        width: "4rem",
                        height: "3rem",
                        alignItems: "center",
                        justifyItems: "center",
                        margin: 0,
                        color: "#0067DF",
                      }}
                    >
                      <DescriptionIcon fontSize="medium" sx={{}} />
                    </IconButton>
                  </Tooltip>
                </Link>

                <Link to="/map">
                  <Tooltip title="Spatial Portal" placement="right">
                    <IconButton
                      edge="start"
                      aria-label="menu"
                      sx={{
                        display: "flex",
                        width: "4rem",
                        height: "3rem",
                        alignItems: "center",
                        justifyItems: "center",
                        margin: 0,
                        color: "#0067DF",
                      }}
                    >
                      <MapIcon fontSize="medium" sx={{}} />
                    </IconButton>
                  </Tooltip>
                </Link>
                {(user.roles.includes("coordinator") ||
                  user.roles.includes("admin")) && (
                  <Link to="/performance">
                    <IconButton
                      edge="start"
                      aria-label="menu"
                      sx={{
                        display: "flex",
                        width: "4rem",
                        height: "3rem",
                        alignItems: "center",
                        justifyItems: "center",
                        margin: 0,
                        color: "#0067DF",
                      }}
                    >
                      <EqualizerIcon fontSize="medium" sx={{}} />
                    </IconButton>
                  </Link>
                )}
                <Link to="/settings/general">
                  <Tooltip title="Settings" placement="right">
                    <IconButton
                      edge="start"
                      aria-label="menu"
                      sx={{
                        display: "flex",
                        width: "4rem",
                        height: "3rem",
                        alignItems: "center",
                        justifyItems: "center",
                        margin: 0,
                        color: "#0067DF",
                      }}
                    >
                      <SettingsIcon fontSize="medium" sx={{}} />
                    </IconButton>
                  </Tooltip>
                </Link>
                {user.roles.includes("admin") && (
                  <Link to="/admin/reportTemplates">
                    <IconButton
                      edge="start"
                      aria-label="menu"
                      sx={{
                        display: "flex",
                        width: "4rem",
                        height: "3rem",
                        alignItems: "center",
                        justifyItems: "center",
                        margin: 0,
                        color: "#0067DF",
                      }}
                    >
                      <AdminPanelSettingsIcon fontSize="medium" sx={{}} />
                    </IconButton>
                  </Link>
                )}
              </>
            )}
          </div>
        </Sidebar>

        <Box
          sx={{
            backgroundColor: "#ececec",
            overflow: hideOverflow ? "hidden" : "unset",
            position: "relative",
          }}
        >
          {children}
        </Box>
      </Main>
    </>
  );
}

const Sidebar = styled.div`
  border-right: 1px solid lightgray;
  width: auto;
  background-color: white;
  .SidebarChildren {
    position: sticky;
    top: 64px;
  }
  @media (max-width: 960px) {
    display: none;
  }
`;

const Main = styled.div`
  display: grid;
  grid-template-columns: 64px 1fr;
  min-height: calc(100vh - 65px);
  @media (max-width: 960px) {
    grid-template-columns: 1fr;
  }
`;

const Content = styled.div`
  background-color: #ececec;
  position: relative;
`;
