import styled from "@emotion/styled";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import { JSONContent } from "@tiptap/core";
import { useRef, useState } from "react";
import { InlineEditor, InlineEditorRef } from "../InlineEditor";
import { TemplateVariables } from "../TemplateVariables";

type ConstructionIssuesTableProps = {
  showModal: boolean;
  onClose: VoidFunction;
  setDirty: (state: boolean) => void;
  onSave: (context: Context) => void;
  context: Context;
};

type Context = {
  hasCostEstimate: JSONContent;
};

export const ConstructionIssuesTable = ({
  showModal,
  onClose,
  setDirty,
  onSave,
  context,
}: ConstructionIssuesTableProps) => {
  const [hasCostEstimate, setHasCostEstimate] = useState(
    context?.hasCostEstimate || "Placeholder"
  );
  const costEstimateRef = useRef<InlineEditorRef>(null);

  return (
    <>
      {showModal && (
        <Dialog
          open={showModal}
          onClose={onClose}
          maxWidth="lg"
          sx={{ " .MuiPaper-root": { flexWrap: "nowrap" } }}
        >
          <DialogTitle>EQC Consideration Details</DialogTitle>
          <DialogContent>
            <ContentWrapper>
              <Stack>
                <Typography variant="h4">
                  If there is a cost estimate
                </Typography>
                <InlineEditor
                  content={hasCostEstimate}
                  onContentChange={(change) => {
                    setHasCostEstimate(change);
                    setDirty(true);
                  }}
                  ref={costEstimateRef}
                />
              </Stack>
              <TemplateVariables
                onVariableClick={(value, label, description, hasConfig) => {
                  if (costEstimateRef.current) {
                    costEstimateRef.current.addTemplateVariable(
                      value,
                      label,
                      description,
                      hasConfig
                    );
                  }
                }}
              />
            </ContentWrapper>
          </DialogContent>
          <DialogActions
            sx={{
              display: "flex",
              flexDirection: "row",
              margin: "0 1rem 1rem 1rem",
              alignItems: "flex-end",
            }}
          >
            <Button
              variant="contained"
              onClick={() => {
                onSave({
                  hasCostEstimate,
                });
              }}
              sx={{ width: 200 }}
            >
              Save
            </Button>
            <Button
              variant="outlined"
              onClick={() => {
                onClose();
              }}
              sx={{ width: 200 }}
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;
