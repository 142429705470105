import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import Select from "@mui/material/Select";
import { ClaimStatusChips } from "./ClaimStatusChips";
import styled from "@emotion/styled";
import Button from "@mui/material/Button";
import {
  AdditionalStatus,
  Status,
} from "@athena/server/src/api/types/claimStatuses";
import { ClaimStatusModal } from "./ClaimStatusModal";
import { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { axiosClient } from "src/lib/axiosClient";
import {
  enqueueErrorSnackbar,
  enqueueSavingSnackbar,
  enqueueSuccessSnackbar,
} from "src/shared/snackbar/SnackbarHelper";
import { Urgency } from "@athena/server/src/api/types/claim";
import { UserPickerMulti } from "src/shared/userPicker/UserPickerMulti";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  TextField,
  DialogActions,
  LinearProgress,
  Checkbox,
  Divider,
  FormControlLabel,
} from "@mui/material";
import { calcBbox, useOfflineTiles } from "src/lib/offlineMaps";
import { useClaimId } from "./hooks/useClaimId";
import { LoadingSpinner } from "@athena/components";
import { useOfflineClaim } from "./useOfflineClaim";
import { Point } from "ol/geom";
import { config } from "src/lib/config";
import { formatDate } from "src/lib/date";

// function getStyles(name: string, personName: readonly string[], theme: Theme) {
//   return {
//     fontWeight:
//       personName.indexOf(name) === -1
//         ? theme.typography.fontWeightRegular
//         : theme.typography.fontWeightMedium,
//   };
// }

type ClaimStatusProps = {
  claimId?: string;
  status: Status;
  additionalStatuses: AdditionalStatus[];
  reviewingEngineers: { id: string; name: string }[];
  assignedEngineers: { id: string; name: string }[];
  urgency?: string;
};

export interface Survey {
  id: string;
  name: string;
  date: string;
}

type SurveyFeature = {
  properties: {
    survey_id: string;
    survey_name: string;
    survey_date: string;
  };
};

export function ClaimStatus(props: ClaimStatusProps) {
  const queryClient = useQueryClient();
  const [showEditStatus, setShowEditStatus] = useState(false);
  const [defaultMapSize, setDefaultMapSize] = useState(100);
  const [showDownloadModal, setShowDownloadModal] = useState(false);
  const [showRemoveDownloadModal, setShowRemoveDownloadModal] = useState(false);
  const claimId = useClaimId();
  const { claim } = useOfflineClaim(claimId);
  const {
    downloadTiles,
    downloading,
    isCached,
    progress,
    clearTiles,
    deleting,
  } = useOfflineTiles();
  const { mutate } = useMutation(
    async (additionalStatuses: AdditionalStatus[]) => {
      const finishSaving = enqueueSavingSnackbar();
      const res = (
        await axiosClient.put(`/claims/${props.claimId}/additionalStatuses`, {
          additionalStatuses,
        })
      ).data;
      finishSaving();
      return res;
    },
    {
      onSuccess: () => {
        enqueueSuccessSnackbar();
        setShowEditStatus(false);
      },
      onError: () => {
        enqueueErrorSnackbar();
      },
      onSettled: (data) => {
        queryClient.invalidateQueries({
          queryKey: [`claim${data.claimId}`],
        });
      },
    }
  );

  const { mutate: mutateUrgency } = useMutation(
    async (urgency: Urgency) => {
      const finishSaving = enqueueSavingSnackbar();
      const res = (
        await axiosClient.put(`/claims/${props.claimId}/urgency`, {
          urgency,
        })
      ).data;
      finishSaving();
      return res;
    },
    {
      onSuccess: () => {
        enqueueSuccessSnackbar();
        setShowEditStatus(false);
      },
      onError: () => {
        enqueueErrorSnackbar();
      },
      onSettled: (data) => {
        queryClient.invalidateQueries({
          queryKey: [`claim${data.claimId}`],
        });
      },
    }
  );

  const { mutate: saveReviewingEngineers } = useMutation(
    async (userIds: string[] | null) => {
      const finishSaving = enqueueSavingSnackbar();

      const res = (
        await axiosClient.put(`/claims/${props.claimId}/reviewingEngineers`, {
          userIds,
        })
      ).data;
      finishSaving();
      return res;
    },
    {
      onSuccess: () => {
        enqueueSuccessSnackbar();
        setShowEditStatus(false);
      },
      onError: () => {
        enqueueErrorSnackbar();
      },
      onSettled: (data) => {
        queryClient.invalidateQueries({
          queryKey: [`claim${data.claimId}`],
        });
      },
    }
  );

  const { mutate: saveAssignedEngineers } = useMutation(
    async (userIds: string[] | null) => {
      const finishSaving = enqueueSavingSnackbar();
      const res = (
        await axiosClient.put(`/claims/${props.claimId}/assignedEngineers`, {
          userIds,
        })
      ).data;
      finishSaving();
      return res;
    },
    {
      onSuccess: () => {
        enqueueSuccessSnackbar();
        setShowEditStatus(false);
      },
      onError: () => {
        enqueueErrorSnackbar();
      },
      onSettled: (data) => {
        queryClient.invalidateQueries({
          queryKey: [`claim${data.claimId}`],
        });
      },
    }
  );
  const { isLoading: loadingSurveys, data: surveys } = useQuery(
    `claimSurveys${claimId}${claim?.location.geo.coordinates}${defaultMapSize}`,
    async (): Promise<Survey[]> =>
      (
        await axiosClient.get(
          `${
            config.gisApiUrl
          }/geo/ath/features/collections/drone_survey_area_v1/items?bbox=${calcBbox(
            new Point(claim?.location.geo.coordinates || [0, 0]),
            defaultMapSize
          )}`
        )
      ).data.features.map((feature: SurveyFeature) => ({
        id: feature.properties.survey_id,
        name: feature.properties.survey_name,
        date: feature.properties.survey_date,
      })),
    {
      enabled: !!claim?.location.geo.coordinates,
    }
  );
  const [selectedSurveys, setSelectedSurveys] = useState<Survey[]>([]);
  const {
    additionalStatuses,
    status,
    assignedEngineers = [],
    reviewingEngineers = [],
  } = props;
  return (
    <Paper
      sx={{
        padding: 1.5,
        display: "flex",
        alignItems: "center",
        mb: 3,
        justifyContent: "space-between",
      }}
      elevation={4}
    >
      {showEditStatus && (
        <ClaimStatusModal
          onClose={() => setShowEditStatus(false)}
          onAdditionalStatusesSave={(additionalStatuses) => {
            mutate(additionalStatuses);
          }}
          status={status}
          additionalStatuses={additionalStatuses}
        />
      )}
      <Box>
        <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
          <InputLabel id="demo-simple-select-helper-label1" shrink>
            Status
          </InputLabel>
          <StatusChipsContainer>
            {props.claimId ? (
              <>
                <StatusChips>
                  <ClaimStatusChips
                    additionalStatuses={additionalStatuses}
                    status={status}
                    size="small"
                  />
                </StatusChips>
                <StatusChipsAdd>
                  <Button
                    sx={{ padding: "0.25rem", width: 75 }}
                    onClick={() => setShowEditStatus(true)}
                  >
                    Edit +
                  </Button>
                </StatusChipsAdd>
              </>
            ) : (
              <StatusChipsReadOnly>
                <ClaimStatusChips
                  additionalStatuses={additionalStatuses}
                  status={status}
                  size="small"
                />
              </StatusChipsReadOnly>
            )}
          </StatusChipsContainer>
        </FormControl>
        <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
          <InputLabel id="demo-simple-select-helper-label1" shrink>
            Urgency
          </InputLabel>
          <Select
            labelId="demo-multiple-chip-label1"
            id="demo-multiple-chip1"
            value={props.urgency}
            renderValue={(selected) => (
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                <Chip key={selected} label={selected} size="small" />
              </Box>
            )}
            onChange={(e) => {
              mutateUrgency(e.target.value as Urgency);
            }}
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: 48 * 4.5 + 8,
                  width: 250,
                },
              },
            }}
          >
            <MenuItem value="Normal">Normal</MenuItem>
            <MenuItem value="Urgent">Urgent</MenuItem>
          </Select>
        </FormControl>
      </Box>
      <Box sx={{ ml: 2 }}>
        <InputLabel id="demo-simple-select-helper-label1" shrink>
          Offline Availability
        </InputLabel>
        <StatusChipsContainer>
          {props.claimId ? (
            <>
              <StatusChips>
                <Chip
                  sx={{
                    backgroundColor: isCached ? "#CAFFC1" : "#FFEAC1",
                  }}
                  size="small"
                  label={isCached ? "Downloaded" : "Not Downloaded"}
                />
              </StatusChips>
              <StatusChipsAdd>
                <Button
                  sx={{ padding: "0.25rem", width: 100 }}
                  onClick={() => {
                    if (isCached) {
                      setShowRemoveDownloadModal(true);
                    } else {
                      setShowDownloadModal(true);
                    }
                  }}
                >
                  {isCached ? "Delete" : "Download"}
                </Button>
              </StatusChipsAdd>
            </>
          ) : (
            <StatusChipsReadOnly>
              <ClaimStatusChips
                additionalStatuses={additionalStatuses}
                status={status}
                size="small"
              />
            </StatusChipsReadOnly>
          )}
        </StatusChipsContainer>
      </Box>
      {showDownloadModal && (
        <Dialog
          open
          onClose={() => {
            setShowDownloadModal(false);
          }}
          fullWidth
          maxWidth="md"
          sx={{
            border: "1px solid lightgray",
            " .MuiPaper-root": {
              flexWrap: "nowrap",
            },
          }}
          disableEnforceFocus // Fixes canvas bug where you can't edit text
          disableEscapeKeyDown
        >
          <DialogTitle
            sx={{
              borderBottom: "1px solid lightgray",
              justifyContent: "space-between",
              display: "flex",
            }}
          >
            <span>Download Claim</span>
          </DialogTitle>
          <DialogContent sx={{ overflow: "visible" }}>
            {downloading ? (
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Box sx={{ width: "100%", mr: 1 }}>
                  <LinearProgress
                    variant="determinate"
                    value={(progress.current / progress.total) * 100}
                  />
                </Box>
                <Box sx={{ minWidth: 35 }}>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                  >{`${Math.round(
                    (progress.current / progress.total) * 100
                  )}%`}</Typography>
                </Box>
              </Box>
            ) : (
              <Box
                sx={{
                  justifyContent: "space-between",
                  mb: 2,
                  mt: 2,
                }}
              >
                <Typography>
                  To make the claim available offline, select a map size, which
                  will then be centered on the current claim pin location.
                </Typography>
                <TextField
                  label={<span>Map Radius (m)</span>}
                  placeholder="Map Name"
                  value={defaultMapSize}
                  onChange={(e) => {
                    setDefaultMapSize(parseInt(e.target.value));
                  }}
                  type="number"
                />
                {loadingSurveys ? (
                  <LoadingSpinner />
                ) : (
                  <>
                    <Typography
                      variant="h4"
                      sx={{ flex: 1, fontSize: "1rem", mb: 1, mt: 2 }}
                    >
                      Available UAV Surveys within radius of {defaultMapSize}{" "}
                      (m)
                    </Typography>
                    {surveys?.length === 0 && (
                      <>
                        <Divider sx={{ mb: 2 }} />
                        None
                      </>
                    )}
                    {(surveys || []).map((survey: Survey) => (
                      <Box
                        key={survey.id}
                        sx={{
                          display: "inline-flex",
                          alignItems: "center",
                          flexDirection: "row",
                        }}
                      >
                        <FormControlLabel
                          control={
                            <Checkbox
                              onChange={(e) => {
                                if (e.target.checked) {
                                  setSelectedSurveys([
                                    ...selectedSurveys,
                                    survey,
                                  ]);
                                } else {
                                  setSelectedSurveys(
                                    selectedSurveys.filter(
                                      (s) => s.id !== survey.id
                                    )
                                  );
                                }
                              }}
                            />
                          }
                          label={`${survey.name} - (
                            ${formatDate(survey.date)})`}
                        />

                        <Typography></Typography>
                      </Box>
                    ))}
                  </>
                )}
              </Box>
            )}
          </DialogContent>
          <DialogActions
            sx={{
              display: "flex",
              margin: "0 1rem 1rem 1rem",
            }}
          >
            <Button
              onClick={() => {
                downloadTiles(defaultMapSize, selectedSurveys).then(() => {
                  setShowDownloadModal(false);
                });
              }}
              variant="contained"
              sx={{ width: 150, mr: 2 }}
              disabled={downloading}
            >
              {"Download"}
            </Button>
            <Button
              onClick={() => {
                setShowDownloadModal(false);
              }}
              variant="outlined"
              sx={{ width: 150 }}
            >
              {"Cancel"}
            </Button>
          </DialogActions>
        </Dialog>
      )}
      {showRemoveDownloadModal && (
        <Dialog
          open
          onClose={() => {
            setShowDownloadModal(false);
          }}
          fullWidth
          maxWidth="md"
          sx={{
            border: "1px solid lightgray",
            " .MuiPaper-root": {
              flexWrap: "nowrap",
            },
          }}
          disableEnforceFocus // Fixes canvas bug where you can't edit text
          disableEscapeKeyDown
        >
          <DialogTitle
            sx={{
              borderBottom: "1px solid lightgray",
              justifyContent: "space-between",
              display: "flex",
            }}
          >
            <span>Remove Claim Download</span>
          </DialogTitle>
          <DialogContent sx={{ overflow: "visible" }}>
            {deleting ? (
              <LoadingSpinner />
            ) : (
              <Box
                sx={{
                  justifyContent: "space-between",
                  mb: 2,
                  mt: 2,
                }}
              >
                <Typography sx={{ mb: 2 }}>
                  Removing the download will make the claim unavailable offline,
                  but will clear space on your device.
                </Typography>
              </Box>
            )}
          </DialogContent>
          <DialogActions
            sx={{
              display: "flex",
              margin: "0 1rem 1rem 1rem",
            }}
          >
            <Button
              onClick={() => {
                clearTiles().then(() => {
                  setShowRemoveDownloadModal(false);
                });
              }}
              variant="contained"
              sx={{ width: 150, mr: 2 }}
            >
              {"Remove"}
            </Button>
            <Button
              onClick={() => {
                setShowRemoveDownloadModal(false);
              }}
              variant="outlined"
              sx={{ width: 150 }}
            >
              {"Cancel"}
            </Button>
          </DialogActions>
        </Dialog>
      )}
      <Box sx={{ flexGrow: 1 }} />
      <Box>
        <FormControl sx={{ m: 1, minWidth: 250 }} size="small">
          <UserPickerMulti
            id="assigned-engineer"
            label="Assigned Engineers"
            placeholder="Search Engineers"
            value={assignedEngineers}
            onChange={(users) => {
              saveAssignedEngineers(users.map((u) => u.id));
            }}
            role="assessing-engineer"
          />
        </FormControl>
        <FormControl sx={{ m: 1, minWidth: 250 }} size="small">
          <UserPickerMulti
            id="assigned-engineer"
            label="Reviewing Engineers"
            placeholder="Search Engineers"
            value={reviewingEngineers}
            onChange={(users) => {
              saveReviewingEngineers(users.map((u) => u.id));
            }}
            role="reviewing-engineer"
          />
        </FormControl>
      </Box>
    </Paper>
  );
}

const InfoText = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #d8e9ff;
  border-radius: 12px;
  padding: 0.5rem;
  border: 1px solid black;
`;

const StatusChipsContainer = styled.div`
  display: flex;
`;
const StatusChips = styled.div`
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  padding: 7.25px 14px;
`;
const StatusChipsReadOnly = styled.div`
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.25);
  padding: 7.25px 14px;
`;
const StatusChipsAdd = styled.div`
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-left: 0;
  display: flex;
`;
