import { Status } from "@athena/server/src/api/types/claimStatuses";

export const statusColorMap = {
  [Status.Setup]: "#8C510A",
  [Status.NeedsEngineer]: "#ED5151",
  [Status.WaitingForCustomer]: "#F199C3",
  [Status.VisitBooked]: "#FFE35B",
  [Status.VisitCompleted]: "#f38115",
  [Status.PreparingDraft]: "#8181DC",
  [Status.UnderInternalReview]: "#BDE579",
  [Status.UnderClientReview]: "#37ACD5",
  [Status.ClientAccepted]: "#C791C8",
  [Status.Done]: "#4EB265",
};
