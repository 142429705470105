import { Tab, TabProps } from "@mui/material";
import { useNavigate } from "react-router-dom";

interface LinkTabProps extends Omit<TabProps, "onClick"> {
  link: string;
}

export const LinkTab = ({ link, ...rest }: LinkTabProps) => {
  const navigate = useNavigate();

  return <Tab {...rest} onClick={() => navigate(link)} />;
};
