import GeoJSON from "ol/format/GeoJSON";
import { Geometry } from "ol/geom";

export const geoJson = new GeoJSON({
  dataProjection: "EPSG:4326",
  featureProjection: "EPSG:3857",
});

export const olToTurf = (poly: Geometry) => {
  return geoJson.writeGeometryObject(poly);
};
