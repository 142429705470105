import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import { Stack } from "@mui/system";
import { JSONContent } from "@tiptap/core";
import { useState } from "react";
import { TemplateWithVariables } from "./TemplateWithVariables";

type BridgesAndCulvertsTableProps = {
  showModal: boolean;
  onClose: VoidFunction;
  setDirty: (state: boolean) => void;
  onSave: (context: Context) => void;
  context: Context;
};

type Context = {
  damagedText: string;
  imminentDamageText: string;
  sectionHeader: string | JSONContent;
  tableHeader: string;
};

const emptyContext: Context = {
  damagedText: "Placeholder",
  imminentDamageText: "Placeholder",
  sectionHeader: "Placeholder",
  tableHeader: "Placeholder",
};

export const BridgesAndCulvertsTable = ({
  showModal,
  onClose,
  setDirty,
  onSave,
  context,
}: BridgesAndCulvertsTableProps) => {
  const [texts, setTexts] = useState(context || emptyContext);
  const handleChange = (change: Partial<typeof texts>) => {
    setTexts({ ...texts, ...change });
    setDirty(true);
  };
  return (
    <>
      {showModal && (
        <Dialog
          open={showModal}
          onClose={onClose}
          maxWidth="xl"
          sx={{ " .MuiPaper-root": { flexWrap: "nowrap" } }}
        >
          <DialogTitle>Bridges and Culverts Table</DialogTitle>
          <DialogContent>
            <Stack>
              <TemplateWithVariables
                title="Bridges and Culverts Section Header"
                content={texts.sectionHeader}
                onContentChange={(content) =>
                  handleChange({
                    sectionHeader: content,
                  })
                }
              />
              <TextField
                value={texts.tableHeader}
                onChange={(e) =>
                  handleChange({
                    tableHeader: e.target.value,
                  })
                }
              />
              <TextField
                value={texts.damagedText}
                onChange={(e) =>
                  handleChange({
                    damagedText: e.target.value,
                  })
                }
              />
              <TextField
                value={texts.imminentDamageText}
                onChange={(e) =>
                  handleChange({
                    imminentDamageText: e.target.value,
                  })
                }
              />
            </Stack>
          </DialogContent>
          <DialogActions
            sx={{
              display: "flex",
              flexDirection: "row",
              margin: "0 1rem 1rem 1rem",
              alignItems: "flex-end",
            }}
          >
            <Button
              variant="contained"
              onClick={() => {
                onSave(texts);
              }}
              sx={{ width: 200 }}
            >
              Save
            </Button>
            <Button
              variant="outlined"
              onClick={() => {
                onClose();
              }}
              sx={{ width: 200 }}
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};
