import { useState } from "react";
import { Button, Dialog, Paper, TextField, Typography } from "@mui/material";
import { useCurrentUser } from "src/shared/hooks/useCurrentUser";
import { Box, Stack } from "@mui/system";
import { useMutation, useQueryClient } from "react-query";
import {
  enqueueErrorSnackbar,
  enqueueSavingSnackbar,
  enqueueSuccessSnackbar,
} from "src/shared/snackbar/SnackbarHelper";
import { axiosClient } from "src/lib/axiosClient";
import { trpc } from "src/lib/api/trpc";

export function SignUp() {
  const { userData } = useCurrentUser();
  const { user } = userData || {};
  const trpcContext = trpc.useContext();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const queryClient = useQueryClient();
  const { mutate: saveUser } = useMutation(
    async () => {
      const finishSaving = enqueueSavingSnackbar();
      const res = (
        await axiosClient.put(`/users/${user?.userId}`, {
          firstName,
          lastName,
        })
      ).data;
      finishSaving();
      return res;
    },
    {
      onSuccess: () => {
        enqueueSuccessSnackbar();
      },
      onError: () => {
        enqueueErrorSnackbar();
      },
      onSettled: () => {
        queryClient.invalidateQueries({
          queryKey: [`user`],
        });
        trpcContext.user.invalidate();
      },
    }
  );
  return (
    <Box
      sx={{
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Dialog open>
        <Paper sx={{ padding: 2 }}>
          <Stack>
            <Typography variant="h4" sx={{ mb: 2 }}>
              Welcome, please finish sign up.
            </Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                mb: 2,
                flexDirection: "column",
              }}
            >
              <TextField
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                label="First Name"
                sx={{ mb: 2, width: 300 }}
              />
              <TextField
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                label="Last Name"
                sx={{ mb: 2, width: 300 }}
              />
            </Box>
            <Button
              onClick={() => {
                saveUser();
              }}
              variant="contained"
              disabled={
                !firstName || !lastName || firstName === "" || lastName === ""
              }
            >
              Next
            </Button>
          </Stack>
        </Paper>
      </Dialog>
    </Box>
  );
}
