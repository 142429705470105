import styled from "@emotion/styled";

export const DialogSection = styled.section`
  :not(:last-child) {
    border-bottom: 1px solid #d3d3d3;
    padding-bottom: 0.75rem;
  }
`;

export const DialogSectionContentVertical = styled.div`
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
  display: flex;
  flex-direction: column;
`;

export const DialogSectionContentHorizontal = styled.div`
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const DialogSectionHeader = styled.h4`
  margin-top: 0.5rem;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5rem;
`;

export const DialogSectionBody = styled.div`
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
  min-height: 1rem;
`;
