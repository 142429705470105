import { Box } from "@mui/system";
import { LoadingSpinner } from "@athena/components";

export const LoadingPage = () => {
  return (
    <Box
      sx={{
        // make this absolutely positioned and center
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <LoadingSpinner />
      <h1
        style={{
          fontFamily: "'Inter', sans-self",
          fontWeight: "500",
          margin: "1.5rem 0 0 0",
        }}
      >
        Athena is loading
      </h1>
      <p className="loading-subtext">
        Engineered by your friends at Infinity Studio
      </p>
    </Box>
  );
};
