import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import { JSONContent } from "@tiptap/core";
import { useState } from "react";
import { InlineEditor } from "../InlineEditor";

type EQCConsiderationDetailsProps = {
  showModal: boolean;
  onClose: VoidFunction;
  setDirty: (state: boolean) => void;
  onSave: (context: Context) => void;
  context: Context;
};

type Context = {
  hasNaturalDamageDetails: JSONContent;
  noNaturalDamageDetails: JSONContent;
};

export const EQCConsiderationDetails = ({
  showModal,
  onClose,
  setDirty,
  onSave,
  context,
}: EQCConsiderationDetailsProps) => {
  const [hasNaturalDamageDetails, setHasNaturalDamageDetails] = useState(
    context?.hasNaturalDamageDetails || "Placeholder"
  );
  const [noNaturalDamageDetails, setNoNaturalDamageDetails] = useState(
    context?.noNaturalDamageDetails || "Placeholder"
  );

  return (
    <>
      {showModal && (
        <Dialog open={showModal} onClose={onClose} maxWidth="lg">
          <DialogTitle>EQC Consideration Details</DialogTitle>
          <DialogContent>
            <Stack>
              <Typography variant="h4">If there is natural damage</Typography>
              <InlineEditor
                content={hasNaturalDamageDetails}
                onContentChange={(change) => {
                  setHasNaturalDamageDetails(change);
                  setDirty(true);
                }}
              />
              <Typography variant="h4">
                If there is no natural damage
              </Typography>
              <InlineEditor
                content={noNaturalDamageDetails}
                onContentChange={(change) => {
                  setNoNaturalDamageDetails(change);
                  setDirty(true);
                }}
              />
            </Stack>
          </DialogContent>
          <DialogActions
            sx={{
              display: "flex",
              flexDirection: "row",
              margin: "0 1rem 1rem 1rem",
              alignItems: "flex-end",
            }}
          >
            <Button
              variant="contained"
              onClick={() => {
                onSave({
                  hasNaturalDamageDetails,
                  noNaturalDamageDetails,
                });
              }}
              sx={{ width: 200 }}
            >
              Save
            </Button>
            <Button
              variant="outlined"
              onClick={() => {
                onClose();
              }}
              sx={{ width: 200 }}
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};
