import {
  Bar,
  CartesianGrid,
  ComposedChart,
  Legend,
  Line,
  ReferenceLine,
  ResponsiveContainer,
  Text,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

import Color from "color";
import { formatDate, getStartOfWeek } from "src/lib/date";
import { ChartComingSoon } from "./ChartLoader";

export enum PastBreakdownDataKey {
  NotStarted = "not-started",
  SiteVisitComplete = "site-visit-complete",
  DraftReportComplete = "draft-report-complete",
}

export interface PastBreakdownData {
  [PastBreakdownDataKey.NotStarted]: Record<string, number>;
  [PastBreakdownDataKey.SiteVisitComplete]: Record<string, number>;
  [PastBreakdownDataKey.DraftReportComplete]: Record<string, number>;
}

export interface FutureData {
  week: string;
  companies: Record<string, number>;
}

interface Props {
  pastBreakdownData: PastBreakdownData;
  prediction: Array<FutureData>;
  companies: Array<string>;
  isComingSoon?: boolean;
}

interface DataPoint {
  label: string;
  capacityByCompany: Record<string, number>;
  total?: number;
}

interface TickProps {
  x: number;
  y: number;
  payload: {
    value: string;
  };
}

const CustomXAxisTick = ({ x, y, payload }: TickProps) => {
  return (
    <Text width={"12px"} x={x} y={y} textAnchor="middle" verticalAnchor="start">
      {payload.value}
    </Text>
  );
};

const COLORS = [
  "#EE3377",
  "#CC3311",
  "#009988",
  "#0077BB",
  "#33BBEE",
  "#EE7733",
  "#EFF542",
  "#009988",
  "#42F5B6",
];

export const BreakdownOfEngineeringCapacity = ({
  pastBreakdownData,
  prediction,
  companies,
  isComingSoon,
}: Props) => {
  const startOfTheWeek = getStartOfWeek(new Date());
  const companyColors = companies.reduce(
    (sum, company, index) => ({
      ...sum,
      [company]:
        COLORS[index] ||
        new Color(COLORS[index % COLORS.length], "hex")
          .desaturate(0.1 + 0.02 * index)
          .toString(),
    }),
    {} as Record<string, string>
  );

  const data: Array<DataPoint> = [];
  data.push({
    label: "Not started",
    capacityByCompany: pastBreakdownData[PastBreakdownDataKey.NotStarted],
  });

  data.push({
    label: "Site visit complete",
    capacityByCompany:
      pastBreakdownData[PastBreakdownDataKey.SiteVisitComplete],
  });

  data.push({
    label: "Draft report complete",
    capacityByCompany:
      pastBreakdownData[PastBreakdownDataKey.DraftReportComplete],
  });

  data.push({
    label: formatDate(startOfTheWeek),
    capacityByCompany: {},
  });

  for (const future of prediction) {
    data.push({
      label: formatDate(new Date(future.week)),
      capacityByCompany: future.companies,
      total: Object.values(future.companies).reduce((sum, c) => sum + c, 0),
    });
  }

  return (
    <div style={{ width: "100%", marginTop: "1.5rem", position: "relative" }}>
      {isComingSoon && <ChartComingSoon />}
      <ResponsiveContainer width="100%" height={300}>
        <ComposedChart data={data}>
          <CartesianGrid horizontal={false} fill={"#EFEFEF"} />
          <XAxis
            dataKey={(x: DataPoint) => x.label}
            xAxisId={"middleBand"}
            height={40}
            tickSize={0}
            tickMargin={15}
            interval={0}
            tick={CustomXAxisTick}
          />
          <XAxis
            dataKey={(x: DataPoint) => x.label}
            height={40}
            scale={"band"}
            tickSize={0}
            tickMargin={15}
            interval={0}
            tick={CustomXAxisTick}
            hide
          />

          <YAxis tickSize={0} tickMargin={5} />
          <Tooltip />
          {companies.map((company) => (
            <Bar
              xAxisId={"middleBand"}
              key={company}
              dataKey={`capacityByCompany.${company}`}
              name={company}
              stackId="a"
              fill={companyColors[company]}
            />
          ))}
          <ReferenceLine
            x={formatDate(startOfTheWeek)}
            stroke={"#777777"}
            label={{ value: "Now", angle: -90, position: "center", dx: -10 }}
          />
          <Line
            type="step"
            dataKey="total"
            name="Total Capacity"
            stroke="#8BB1DE"
            strokeWidth={3}
            activeDot={{ r: 8 }}
          />
          <Legend iconType="circle" align={"right"} />
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
};
