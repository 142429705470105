import { ReactNode, useCallback, useEffect, useState } from "react";
import { IconImgExtraLarge, ListItem } from "./styled";
import { useAthenaMapControlContext } from "./Map";

export interface MenuOption<TKey extends string> {
  thumbnail?: string | ReactNode;
  key: TKey;
  label: string;
}

interface MenuBodyProps<TKey extends string> {
  options: Array<MenuOption<TKey>>;
  selectedOption?: TKey;
  onSelected: (key: TKey) => void;
}

function MenuBody<TKey extends string>({
  options,
  selectedOption,
  onSelected,
}: MenuBodyProps<TKey>) {
  return (
    <>
      {options.map((option) => {
        let icon: ReactNode | undefined;
        if (option.thumbnail) {
          if (typeof option.thumbnail === "string") {
            icon = <IconImgExtraLarge src={option.thumbnail} />;
          } else {
            icon = option.thumbnail;
          }
        }

        return (
          <ListItem
            key={option.key}
            isSelected={selectedOption === option.key}
            onClick={() => onSelected(option.key)}
          >
            {icon}
            {option.label}
          </ListItem>
        );
      })}
    </>
  );
}

interface MenuProps<TKey extends string> extends MenuBodyProps<TKey> {
  closeOnSelect?: boolean;
  selectedOption?: TKey;
}

export function Menu<TKey extends string>({
  options,
  onSelected,
  closeOnSelect = true,
  selectedOption: selectedOptionProp,
}: MenuProps<TKey>) {
  const [selectedOption, setSelectedOption] = useState<TKey | undefined>();

  const athenaMapControlContext = useAthenaMapControlContext();

  useEffect(() => {
    setSelectedOption(selectedOptionProp);
  }, [selectedOptionProp]);

  const onSelectedHandler = useCallback(
    (key: TKey) => {
      onSelected(key);
      if (closeOnSelect) {
        athenaMapControlContext.resetControl();
      } else if (!selectedOptionProp) {
        setSelectedOption(key);
      }
    },
    [closeOnSelect, onSelected]
  );

  return (
    <MenuBody
      selectedOption={selectedOption}
      options={options}
      onSelected={onSelectedHandler}
    />
  );
}
