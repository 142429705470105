import {
  FlyToInterpolator,
  Layer,
  MapView,
  TransitionInterpolator,
} from "@deck.gl/core/typed";
import DeckGL, { DeckGLRef } from "@deck.gl/react/typed";
import { Stack } from "@mui/material";
import Box from "@mui/material/Box";
import type GeoJSON from "geojson";
import {
  FC,
  ReactNode,
  Ref,
  createContext,
  useCallback,
  useContext,
  useRef,
  useState,
} from "react";
import { renderToString } from "react-dom/server";
import MapLibreMap, {
  Popup as MapLibrePopup,
  ViewState as MapLibreViewState,
  MapRef,
  ScaleControl,
} from "react-map-gl/maplibre";
import { Compass } from "../controls/Compass";
import { GeoLocation } from "../controls/GeoLocation";
import { RequestFullscreen } from "../controls/RequestFullscreen";
import { ZoomButtons } from "../controls/ZoomButtons";
import { ButtonStyle } from "./styled";

import { WebMercatorViewport } from "@deck.gl/core/typed";
import { TooltipContent } from "@deck.gl/core/typed/lib/tooltip";
import { GeoBoundingBox, TileLayer } from "@deck.gl/geo-layers/typed";
import { BitmapLayer, GeoJsonLayer } from "@deck.gl/layers/typed";
import { useDebounceEffect } from "ahooks";
import { BBox, Position } from "geojson";
import { Offset } from "maplibre-gl";
import "maplibre-gl/dist/maplibre-gl.css";
import { config } from "src/lib/config";
import { useCoverBlock } from "src/modules/olr/hooks/useCoverBlock";
import { CornerDropdown } from "../components/CornerDropdown";
import { deckGlColorFromHex } from "../functions/deckGlColor";
import {
  PickingInfoWithPopups,
  isPickingInfoWithPopup,
  isPickingInfoWithTooltip,
} from "../types";
import { FeaturePopup } from "./FeaturePopup";
import { Modal } from "./Modal";
import { Popup } from "./Popup";
import { SearchBox, SearchSuggestion, Searchable } from "./SearchBox";
import "./styles.css";
interface TransitionProps {
  duration: number;
  interpolator: TransitionInterpolator;
}

const defaultTransitionProps: TransitionProps = {
  duration: 69,
  interpolator: new FlyToInterpolator(),
};

const transitionPropsToViewState = (
  transitionProps: TransitionProps
): Pick<ViewState, "transitionDuration" | "transitionInterpolator"> => ({
  transitionDuration: transitionProps.duration,
  transitionInterpolator: transitionProps.interpolator,
});

// Deck GL's viewstate comes untyped unfortunately
interface ViewState {
  longitude: number;
  latitude: number;
  zoom: number;
  pitch: number;
  bearing: number;
  transitionDuration?: number;
  transitionInterpolator?: TransitionInterpolator;
}

// Deckgl doesnt expose CursorState type
type CursorState = Parameters<
  Exclude<Parameters<typeof DeckGL>[0]["getCursor"], undefined>
>[0];

export interface AthenaMapControlContextType {
  resetControl: () => void;
}

const AthenaMapControlContext =
  createContext<AthenaMapControlContextType | null>(null);

export const useAthenaMapControlContext = () => {
  const mapControlContext = useContext(AthenaMapControlContext);

  if (!mapControlContext) {
    throw new Error(
      "useAthenaMapControlContext must be used within an AthenaMapControlContext.Provider"
    );
  }

  return mapControlContext;
};

export interface AthenaMapControl {
  title: string;
  icon: ReactNode;
  handlers?: {
    onOpen?: () => void;
    onClose?: () => void;
  };
  components?: {
    toolbar?: ReactNode;
    dropdown?: ReactNode;
    popup?: ReactNode;
    modal?: ReactNode;
  };
}

export interface AthenaMapProps {
  layers: Array<Layer>;
  searchables?: Array<Searchable>;
  children?: ReactNode;
  controls: Array<AthenaMapControl>;
  baseMapStyleUrl: string;
  active?: boolean;
  additionalBaseMaps?: Array<{
    url: string;
    bbox: BBox;
  }>;
  mapRef: React.RefObject<MapRef>;
  layersIdsToIgnoreOnHover?: Array<string>;
  getCursor?: (cursorState: CursorState) => string | undefined;
  onChangeZoom?: (zoom: number) => void;
  onSearch?: (searchResult: SearchSuggestion | undefined) => void;
  onBoundsChange?: (bounds: GeoBoundingBox) => void;
  containerStyle: React.CSSProperties;
  initialState: {
    longitude: number;
    latitude: number;
    zoom: number;
  };
}

interface AthenaMapControlContextProps {
  children: ReactNode;
  onResetControl: () => void;
  setMapPosition: (props: {
    position: Position;
    transition?: TransitionProps;
  }) => void;
  setMapZoom: (props: { zoom: number; transition?: TransitionProps }) => void;
}

const AthenaMapControlContextProvider: FC<AthenaMapControlContextProps> = ({
  onResetControl,
  children,
}) => {
  return (
    <AthenaMapControlContext.Provider
      value={{
        resetControl: onResetControl,
      }}
    >
      {children}
    </AthenaMapControlContext.Provider>
  );
};

export const sortLayers = (layers: Array<Layer>) => {
  return [...layers].sort((a, b) => {
    const zIndexA =
      ("zIndex" in a.props &&
        typeof a.props.zIndex === "number" &&
        a.props.zIndex) ||
      0;
    const zIndexB =
      ("zIndex" in b.props &&
        typeof b.props.zIndex === "number" &&
        b.props.zIndex) ||
      0;
    return zIndexA - zIndexB;
  });
};

function AthenaMap({
  layers: propLayers,
  searchables,
  controls,
  baseMapStyleUrl,
  active = true,
  additionalBaseMaps,
  mapRef,
  layersIdsToIgnoreOnHover,
  getCursor,
  onChangeZoom,
  onSearch: onSearchProp,
  onBoundsChange,
  containerStyle,
  initialState,
}: AthenaMapProps) {
  const [viewState, setViewState] = useState<ViewState>({
    ...initialState,
    pitch: 0,
    bearing: 0,
  });
  const [activeControlIndex, setActiveControlIndex] = useState<
    number | undefined
  >(undefined);
  const [hoveredFeature, setHoveredFeature] = useState<
    GeoJSON.Feature | undefined
  >(undefined);
  const [tooltip, setTooltip] = useState<TooltipContent | undefined>(undefined);

  // const [popupComponent, setPopupComponent] = useState<ReactNode | undefined>(undefined);

  const [showPopup, setShowPopup] = useState<
    | {
        position: {
          lng: number;
          lat: number;
        };
        pickingInfos: Array<Required<PickingInfoWithPopups>>;
      }
    | undefined
  >(undefined);

  const [isMouseInPopup, setIsMouseInPopup] = useState<boolean>(false);

  const activeControl =
    activeControlIndex !== undefined ? controls[activeControlIndex] : undefined;

  const mapContainerRef = useRef<HTMLDivElement>(null);
  const coverBlock = useCoverBlock({
    mapActive: active,
    mapRef: mapContainerRef,
  });

  const layers = sortLayers(propLayers);

  const deckGlRef = useRef<DeckGLRef>();
  const updateViewState = useCallback(
    (viewStateUpdates: Partial<ViewState>) => {
      setViewState({
        ...viewState,
        ...viewStateUpdates,
      });
    },
    [viewState]
  );

  useDebounceEffect(
    () => {
      onChangeZoom?.(viewState.zoom);
    },
    [onChangeZoom, viewState],
    {
      wait: 333,
    }
  );

  const [searchResult, setSearchResult] = useState<SearchSuggestion>();

  const onSearch = useCallback(
    (value: SearchSuggestion | undefined) => {
      setSearchResult(value);
      onSearchProp?.(value);
      if (!value) {
        return;
      }

      updateViewState({
        ...transitionPropsToViewState(defaultTransitionProps),
        longitude: value.position[0],
        latitude: value.position[1],
        zoom: 18,
      });
    },
    [updateViewState]
  );

  const [bounds, setBounds] = useState<GeoBoundingBox>();

  useDebounceEffect(
    () => {
      setBounds(bounds);

      if (!onBoundsChange) {
        return;
      }

      const viewport = new WebMercatorViewport(viewState);

      const nw = viewport.unproject([0, 0]);
      const se = viewport.unproject([viewport.width, viewport.height]);

      onBoundsChange({
        north: nw[1],
        west: nw[0],
        south: se[1],
        east: se[0],
      });
    },
    [viewState, onBoundsChange],
    {
      leading: false,
      trailing: true,
      wait: 1000,
    }
  );

  const showSearchPinLayer = new GeoJsonLayer({
    id: "show-search-pin-layer",
    data: {
      type: "FeatureCollection",
      features:
        (searchResult?.position && [
          {
            type: "Feature",
            geometry: { type: "Point", coordinates: searchResult.position },
          },
        ]) ||
        [],
    },
    wrapLongitude: true,
    pointType: "circle+text",
    stroked: true,
    filled: true,
    getLineColor: deckGlColorFromHex("#FFFFFF"),
    getFillColor: deckGlColorFromHex("#737373"),
    pointRadiusUnits: "pixels",
    getPointRadius: 5,
    getLineWidth: 2,
    lineWidthUnits: "pixels",
    getText: () => searchResult?.subtitle,
    textMaxWidth: 15,
    getTextSize: 12,
    textSizeUnits: "pixels",
    textBackground: true,
    textBackgroundPadding: [5, 5],
    getTextBackgroundColor: deckGlColorFromHex("#FFFFFF", 0.8),
    getTextBorderColor: deckGlColorFromHex("#000000"),
    getTextBorderWidth: 1,
    getTextAnchor: "middle",
    getTextAlignmentBaseline: "top",
    getTextPixelOffset: [0, 15],
    visible: !!searchResult,
  });

  const additionalBaseMapLayers = (additionalBaseMaps || [])?.map(
    (x) =>
      new TileLayer({
        data: x.url,
        tileSize: 256,
        minZoom: 0,
        maxZoom: 21,
        loadOptions: {
          fetch: {
            credentials: "include",
          },
        },
        extent: x.bbox,
        renderSubLayers: (props) => {
          if (!props.data) {
            return null;
          }

          const [[west, south], [east, north]] = props.tile.boundingBox;

          return new BitmapLayer(props, {
            data: undefined,
            image: props.data,
            bounds: [west, south, east, north],
          });
        },
      })
  );

  return (
    <Box
      style={containerStyle}
      ref={mapContainerRef}
      sx={{
        "& .maplibregl-canvas-container": {
          cursor: "unset",
        },
      }}
      onContextMenu={(evt) => import.meta.env.PROD && evt.preventDefault()}
    >
      {coverBlock}
      <DeckGL
        ref={deckGlRef as Ref<DeckGLRef>}
        // glOptions={{ stencil: true, antialias: true }}
        // initialViewState={INITIAL_VIEW_STATE}
        views={new MapView({ repeat: true })}
        viewState={viewState}
        onViewStateChange={(viewState) =>
          setViewState(viewState.viewState as ViewState)
        }
        controller={{
          doubleClickZoom: false,
        }}
        layers={[...additionalBaseMapLayers, ...layers, showSearchPinLayer]}
        onClick={(info) => {
          if (isMouseInPopup) {
            return;
          }

          if (info.picked && info.coordinate && info.object) {
            //extract the coordernates from the feature
            let { coordinates } = info.object?.geometry;

            //if theres no coordinates on the feature, default to the mouse point location
            if (!coordinates) {
              coordinates = info.coordinate;
            }

            const infos = deckGlRef.current?.pickMultipleObjects({
              x: info.x,
              y: info.y,
              radius: 5,
            });

            if (!infos) {
              setShowPopup(undefined);
              return;
            }

            const pickingInfosWithPopup = infos.filter(isPickingInfoWithPopup);
            if (pickingInfosWithPopup.length === 0) {
              setShowPopup(undefined);
              return;
            }

            //Lock the coordinates to the first featuers location on the map, each feature type returns a differant coordinate structure
            const mainFeature = pickingInfosWithPopup[0];
            const position = {
              lng: mainFeature.coordinate[0],
              lat: mainFeature.coordinate[1],
            };

            setShowPopup({
              pickingInfos: pickingInfosWithPopup,
              position,
            });
          }
        }}
        getTooltip={() => tooltip || null}
        onHover={(info) => {
          if (isMouseInPopup) {
            if (hoveredFeature) {
              setHoveredFeature(undefined);
            }
            if (tooltip) {
              setTooltip(undefined);
            }
            return;
          }

          const layerShouldBeExcluded =
            info.layer && layersIdsToIgnoreOnHover?.includes(info.layer.id);
          if (info.picked && !layerShouldBeExcluded) {
            setHoveredFeature(info.object);
          } else {
            setHoveredFeature(undefined);
            setTooltip(undefined);
          }

          if (info.picked && info.coordinate && info.object) {
            const infos = deckGlRef.current?.pickMultipleObjects({
              x: info.x,
              y: info.y,
              radius: 5,
            });

            if (!infos) {
              setTooltip(undefined);
              return;
            }

            const pickingInfosWithTooltip = infos.filter(
              isPickingInfoWithTooltip
            );
            if (pickingInfosWithTooltip.length === 0) {
              setTooltip(undefined);
              return;
            }

            const tooltips = pickingInfosWithTooltip
              .flatMap((x) => x.tooltips)
              .map((x) => x.component);

            setTooltip({
              html: renderToString(
                <>
                  {tooltips.map((x, i) => (
                    <p key={i}>{x}</p>
                  ))}
                </>
              ),
            });
          }
        }}
        getCursor={(state) => {
          if (hoveredFeature) {
            return "pointer";
          }
          const cursor = getCursor?.(state);
          if (cursor) {
            return cursor;
          }

          return "auto";
          deckGlRef;
        }}
      >
        <MapLibreMap
          ref={mapRef}
          // viewState={
          //   viewState as MapLibreViewState & { width: number; height: number }
          // }
          // style={{ position: "absolute" }}
          mapStyle={baseMapStyleUrl}
          transformRequest={(originalUrl) => {
            let url = originalUrl.replaceAll(
              "{config.gisApiUrl}",
              config.gisApiUrl
            );

            if (!import.meta.env.PROD) {
              url = url.replaceAll(window.location.origin, config.gisApiUrl);
            }

            if (url.startsWith(config.gisApiUrl)) {
              return {
                url,
                credentials: "include",
              };
            } else {
              return {
                url,
              };
            }
          }}
        >
          <ScaleControl />
        </MapLibreMap>
        <MapLibreMap
          viewState={
            viewState as MapLibreViewState & { width: number; height: number }
          }
          style={{ position: "absolute" }}
        >
          {showPopup?.pickingInfos && showPopup.pickingInfos.length > 0 && (
            <MapLibrePopup
              key={showPopup.position.lng + showPopup.position.lat}
              longitude={showPopup.position.lng}
              latitude={showPopup.position.lat}
              // onClose={() => setShowPopup(undefined)}
              className="maplibregl-popup-overrides"
              closeButton={false}
              offset={[0, -15] as Offset}
              closeOnClick={false}
              style={{ zIndex: 2000 }}
            >
              <Popup
                onMouseEnter={() => setIsMouseInPopup(true)}
                onMouseLeave={() => setIsMouseInPopup(false)}
              >
                <FeaturePopup
                  onClose={() => {
                    setShowPopup(undefined);
                    setIsMouseInPopup(false);
                  }}
                  picked={showPopup.pickingInfos}
                />
              </Popup>
            </MapLibrePopup>
          )}
        </MapLibreMap>
      </DeckGL>
      <AthenaMapControlContextProvider
        onResetControl={() => {
          setActiveControlIndex(undefined);
        }}
        setMapPosition={(props) =>
          updateViewState({
            ...(props.transition
              ? transitionPropsToViewState(props.transition)
              : {}),
            latitude: props.position[1],
            longitude: props.position[0],
          })
        }
        setMapZoom={(props) =>
          updateViewState({
            ...(props.transition
              ? transitionPropsToViewState(props.transition)
              : {}),
            zoom: props.zoom,
          })
        }
      >
        {activeControl?.components?.toolbar}
        {activeControl?.components?.dropdown && (
          <CornerDropdown
            title={activeControl.title}
            onClose={() => {
              activeControl?.handlers?.onClose?.();
              setActiveControlIndex(undefined);
            }}
          >
            {activeControl.components.dropdown}
          </CornerDropdown>
        )}
        {activeControl?.components?.modal && (
          <Modal>{activeControl.components.modal}</Modal>
        )}
      </AthenaMapControlContextProvider>
      <Box width={"100%"} height={"100%"} display={"absolute"}>
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          padding={"0.5rem"}
          zIndex={3000}
        >
          <Stack
            direction={"column"}
            gap={"0.5rem"}
            // padding={"0.5rem"}
          >
            {searchables && searchables.length > 0 && (
              <SearchBox
                searchables={searchables}
                onSearch={onSearch}
                containerElement={mapContainerRef.current}
              />
            )}
            <ZoomButtons
              onIncrement={() =>
                updateViewState({
                  ...transitionPropsToViewState(defaultTransitionProps),
                  zoom: viewState.zoom + 1,
                })
              }
              onDecrement={() =>
                updateViewState({
                  ...transitionPropsToViewState(defaultTransitionProps),
                  zoom: viewState.zoom - 1,
                })
              }
            />
            <Compass
              onClick={() =>
                updateViewState({
                  ...transitionPropsToViewState(defaultTransitionProps),
                  bearing: 0,
                })
              }
              angle={-viewState.bearing}
            />
            <GeoLocation
              onLocationReceived={(location) =>
                updateViewState({
                  ...transitionPropsToViewState(defaultTransitionProps),
                  latitude: location.coords.latitude,
                  longitude: location.coords.longitude,
                  zoom: 13,
                })
              }
            />
          </Stack>
          <Stack direction={"row"} gap="0.5rem" height={"3.5rem"}>
            <RequestFullscreen targetRef={mapContainerRef} />
            {controls.map((control, controlIndex) => (
              <ButtonStyle
                key={control.title}
                onClick={() => {
                  if (activeControlIndex !== controlIndex) {
                    activeControl?.handlers?.onClose?.();
                    control.handlers?.onOpen?.();
                    setActiveControlIndex(controlIndex);
                  } else {
                    setActiveControlIndex(undefined);
                  }
                }}
              >
                {control.icon}
              </ButtonStyle>
            ))}
          </Stack>
        </Stack>
      </Box>
    </Box>
  );
}

export { AthenaMap };
