import { TReportTemplateStyles } from "@athena/server/src/api/types/reportTemplate";
import { Control, FieldValues, Path } from "react-hook-form";
import { FormTextField } from "src/shared/form/FormTextField";
import { BorderStyle } from "./BorderStyle";
import { FontStyle } from "./FontStyle";

interface TableStyleProps<T extends FieldValues> {
  type: "table.title" | "table.content" | "table";
  control: Control<T>;
  setValue: (path: Path<TReportTemplateStyles>, value: any) => void;
  errors?: any;
}

export const TableStyle = (props: TableStyleProps<TReportTemplateStyles>) => {
  const { type, control, errors } = props;
  return (
    <div
      style={{
        display: "flex",
        flexWrap: "wrap",
        gap: "1rem",
        paddingLeft: "1rem",
      }}
    >
      {type === "table" ? (
        <BorderStyle {...props} type="table" />
      ) : (
        <FontStyle {...props} type={`${type}.font`}>
          <FormTextField
            control={control}
            name={`${type}.background`}
            type="color"
            label="Background Color"
            sx={{ minWidth: 70 }}
          />
        </FontStyle>
      )}
    </div>
  );
};
