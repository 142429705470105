import { LoadingSpinner } from "@athena/components";
import { GetInsurancePolicySchema } from "@athena/server/src/trpc/routers/insurancePolicies/schema";
import styled from "@emotion/styled";
import SearchIcon from "@mui/icons-material/Search";
import Button from "@mui/material/Button";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import { useState } from "react";
import { InsurancePoliciesTable } from "./components/InsurancePoliciesTable";

import { trpc } from "src/lib/api/trpc";
import { ImportCsvFile } from "../ImportPoliciesCsvFile";

export function InsurancePoliciesList() {
  const [isChoosingFile, setIsChoosingFile] = useState<boolean>(false);
  const [policySearch, setPolicySearch] = useState<string>("");

  const insurancePoliciesQuery = trpc.insurancePolicies.getAll.useQuery();

  const policies = (insurancePoliciesQuery.data || []).map((x) =>
    GetInsurancePolicySchema.parse(x)
  );
  const isLoading = insurancePoliciesQuery.isLoading;

  const lowerContains = (a: string, b: string) => {
    return a.toLowerCase().includes(b.toLowerCase());
  };

  const filteredPolicies = policies.filter(
    (x) =>
      lowerContains(x.policyReference, policySearch) ||
      lowerContains(x.location.address, policySearch)
  );

  return (
    <>
      <PoliciesHeader>
        <div className="policyActions">
          <TextField
            sx={{
              minHeight: "3rem",
              ".MuiOutlinedInput-root ": { height: "100%" },
            }}
            id="input-with-icon-textfield"
            placeholder="Search Policies"
            onChange={(e) => setPolicySearch(e.target.value)}
            value={policySearch}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            variant="outlined"
          />
          <Button
            variant="outlined"
            disabled={policySearch === ""}
            onClick={() => {
              setPolicySearch("");
            }}
            sx={{ width: 100 }}
          >
            Clear
          </Button>
        </div>
        <Button
          component="label"
          variant="contained"
          sx={{ width: "240px" }}
          onClick={() => setIsChoosingFile(true)}
        >
          Upload Policies
        </Button>
      </PoliciesHeader>
      {isLoading ? (
        <LoadingContainer>
          <LoadingSpinner />
          <p>Loading Policies...</p>
        </LoadingContainer>
      ) : (
        <InsurancePoliciesTable policies={filteredPolicies} pageSize={25} />
      )}
      {isChoosingFile && (
        <ImportCsvFile onClose={() => setIsChoosingFile(false)} />
      )}
    </>
  );
}
const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;
  height: 70vh;
`;

const PoliciesHeader = styled.div`
  background-color: white;
  padding: 1.5rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  .policyActions {
    display: flex;
    gap: 1rem;
  }
`;
