import Color from "color";

export const deckGlColorFromHex = (hex: string, alpha?: number) => {
  const color = new Color(hex, "hex").alpha(alpha || 1);
  return getDeckGlColor(color);
};

export const getDeckGlColor = (color: Color): [number, number, number, number] => {
  const alpha = Math.ceil(color.alpha() * 255);
  return [color.red(), color.green(), color.blue(), alpha];
};