import {
  Dialog,
  DialogTitle,
  Button,
  DialogContent,
  Typography,
  Popover,
  Paper,
} from "@mui/material";

type EditStatusBarProps = {
  onClose: VoidFunction;
  propertyLabel: string;
  anchorEl: Element;
};

export const EditStatusBar = (props: EditStatusBarProps) => {
  const { onClose, propertyLabel, anchorEl } = props;
  return (
    <Popover
      open
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      disableRestoreFocus
    >
      <Paper sx={{ maxWidth: 300, padding: "1rem" }} elevation={1}>
        <Typography>
          To edit {propertyLabel}, edit the value in the status bar above the
          report.
        </Typography>
      </Paper>
    </Popover>
  );
};
