import { Chip } from "@mui/material";
import { mergeAttributes, Node } from "@tiptap/core";
import { ReactNodeViewRenderer } from "@tiptap/react";
import { NodeViewWrapper } from "@tiptap/react";

export interface TemplateVariableOptions {
  inline: boolean;
  allowBase64: boolean;
  HTMLAttributes: Record<string, any>;
}

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    PageBreak: {
      /**
       * Add a TemplateVariable
       */
      setPageBreak: () => ReturnType;
    };
  }
}

export const inputRegex =
  /(?:^|\s)(!\[(.+|:?)]\((\S+)(?:(?:\s+)["'](\S+)["'])?\))$/;

export const PageBreak = Node.create<TemplateVariableOptions>({
  name: "pageBreak",
  group: "inline",
  content: "inline*",
  draggable: true,
  inline: true,
  atom: true,
  addAttributes() {
    return {
      pageBreak: {
        default: null,
        parseHTML: (element) => element.style.breakAfter,
        renderHTML: () => {
          return {
            style: `break-before: page; margin-bottom: 80px;`,
          };
        },
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: "div",
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ["div", mergeAttributes(HTMLAttributes), 0];
  },

  addCommands() {
    return {
      setPageBreak: () => () => {
        return this.editor
          .chain()
          .insertContentAt(this.editor.state.selection.head, {
            type: this.name,
          })
          .focus()
          .run();
      },
    };
  },

  addNodeView() {
    return ReactNodeViewRenderer(TemplateVar);
  },
});

const TemplateVar = () => {
  return (
    <NodeViewWrapper>
      <Chip
        sx={{ backgroundColor: "#AEE2FF", height: 16 }}
        label={"Page Break"}
        size="small"
      />
    </NodeViewWrapper>
  );
};
