import { Box, Button, Paper, Typography } from "@mui/material";
import type { Editor } from "@tiptap/react";
import { BubbleMenu } from "@tiptap/react";
import { useState } from "react";

interface FloatingToolbarProps {
  editor: Editor;
  toggleAddingComment: () => void;
}

interface FloatingToolbarButtonProps {
  children: React.ReactNode;
  onClick: () => void;
}

const FloatingToolbarButton = ({
  children,
  onClick,
}: FloatingToolbarButtonProps) => {
  return (
    <Button onClick={onClick} variant={"text"}>
      {children}
    </Button>
  );
};

export const FloatingToolbar = ({
  editor,
  toggleAddingComment,
}: FloatingToolbarProps) => {
  const [addedComment, setAddedComment] = useState(false);
  const activeMarks = editor.state.selection.$from.marks();
  const commentMark = activeMarks.find((mark) => mark.type.name === "comment");
  const active = commentMark ? commentMark.attrs.active : null;
  const selection = window.getSelection();
  let sizePt = -1;

  if (selection && selection.anchorNode && selection.anchorNode.parentElement) {
    const size = window
      .getComputedStyle(selection.anchorNode.parentElement, null)
      .getPropertyValue("font-size")
      .split("px")[0];

    if (size && Number(size)) {
      sizePt = Math.round(Number(size) * 0.75);
    }
  }
  return (
    <BubbleMenu editor={editor} tippyOptions={{ duration: 100 }}>
      {/* {!addedComment && ( */}

      <Paper sx={{ padding: "1rem", display: "flex" }} elevation={3}>
        <Typography
          variant={"caption"}
          style={{ wordBreak: "keep-all", paddingLeft: "1rem" }}
        >
          Font: {sizePt}pt
        </Typography>
        <Box>
          <FloatingToolbarButton
            onClick={() => editor.chain().focus().toggleBold().run()}
          >
            bold
          </FloatingToolbarButton>
          <FloatingToolbarButton
            onClick={() => editor.chain().focus().toggleItalic().run()}
          >
            italic
          </FloatingToolbarButton>
          <FloatingToolbarButton
            onClick={() => {
              // editor
              //   .chain()
              //   .focus()
              //   .command(({ tr, state }) => {
              //     tr.setSelection(
              //       TextSelection.create(tr.doc, state.selection.$anchor.pos)
              //     );
              //     return true;
              //   })
              //   .run();

              // // A bit dirty, we have to make sure that the toggleAddingComment is run after the transaction is completed, otherwise
              // // the auto focused input will no longer be focused when the text selection is changed.
              // // We have to change the text selection so that the floating toolbar is hidden after toggling the comment
              // setTimeout(() => {
              //   if (!editor.isActive("comment") || !active) {
              //     toggleAddingComment();
              //   }
              // }, 0);
              (!editor.isActive("comment") || !active) && toggleAddingComment();
            }}
          >
            {!editor.isActive("Comment") && !active && "Add Comment"}
          </FloatingToolbarButton>
        </Box>
      </Paper>
      {/* )} */}
    </BubbleMenu>
  );
};
