import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { JSONContent } from "@tiptap/react";
import { useState } from "react";
import { TemplateWithVariables } from "src/shared/reportBuilder/templateVariables/TemplateWithVariables";

export type TemplateModalProps = {
  content: JSONContent | string;
  title: string;
  onChange: (html: string) => void;
  onClose: VoidFunction;
  includeStyles?: boolean;
  onImageUpload?: (file: File) => Promise<{ url: string }>;
};
export const TemplateModal = (props: TemplateModalProps) => {
  const { content, onChange, onClose, title, includeStyles, onImageUpload } =
    props;
  const [newContent, setNewContent] = useState(content);
  const [html, setHtml] = useState("");
  return (
    <Dialog
      open={true}
      onClose={onClose}
      maxWidth={false}
      sx={{ " .MuiPaper-root": { flexWrap: "nowrap" } }}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent sx={{}}>
        <TemplateWithVariables
          title=""
          content={newContent}
          includeStyles={includeStyles}
          onImageUpload={onImageUpload}
          onContentChange={(content, newHtml) => {
            setNewContent(content);
            setHtml(newHtml);
          }}
        />
      </DialogContent>
      <DialogActions
        sx={{
          display: "flex",
          flexDirection: "column",
          margin: "0 1rem 1rem 1rem",
        }}
      >
        <Button
          variant="contained"
          sx={{ width: "100%", margin: "1rem 0 0 0 !important" }}
          onClick={() => {
            onChange(html);
            onClose();
          }}
        >
          Save
        </Button>
        <Button
          onClick={() => {
            onClose();
          }}
          variant="outlined"
          sx={{ width: "100%", margin: "1rem 0 0 0 !important" }}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};
