import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { format } from "date-fns";
import { Chip } from "@mui/material";
import { Link } from "react-router-dom";
import { Event } from "@athena/server/src/api/types/event";

export enum EventsTableColumn {
  Event = "event",
  Status = "status",
  Updated = "updated",
  Created = "created",
  OutstandingClaims = "outstanding-claims",
  EventDate = "event-date",
}

const columns: GridColDef<Event>[] = [
  {
    field: EventsTableColumn.Event,
    flex: 1,
    headerName: "Event",
    renderCell: (p) => <Link to={`/event/${p.row.eventId}`}>{p.row.name}</Link>,
  },
  {
    field: EventsTableColumn.Status,
    flex: 1,
    headerName: "Status",
    renderCell: (p) => (
      <Chip
        label={p.row.status}
        sx={{
          backgroundColor: p.row.status === "Active" ? "#D7FFD7" : undefined,
        }}
      />
    ),
  },
  {
    field: EventsTableColumn.Updated,
    flex: 1,
    headerName: "Updated",
    valueGetter: (p) =>
      (p.row.insUpdTs && format(new Date(p.row.insUpdTs), "h:mma  dd/MM/yy")) ||
      "",
  },
  {
    field: EventsTableColumn.Created,
    flex: 1,
    headerName: "Created",
    valueGetter: (p) =>
      (p.row.created && format(new Date(p.row.created), "dd/MM/yy")) || "",
  },
  {
    field: EventsTableColumn.OutstandingClaims,
    flex: 1,
    headerName: "Outstanding Claims",
    valueGetter: (p) => 0, // TODO: where is this supposed to come from?
  },
  {
    field: EventsTableColumn.EventDate,
    flex: 1,
    headerName: "Date of Event",
    valueGetter: (p) =>
      (p.row.dateOfEvent && format(new Date(p.row.dateOfEvent), "dd/MM/yy")) ||
      "",
  },
];

interface EventsTableProps {
  events: Array<Event>;
}

export function EventsTable({ events }: EventsTableProps) {
  return (
    <DataGrid
      rows={events}
      columns={columns}
      initialState={{
        pagination: {
          paginationModel: {
            pageSize: 10,
          },
        },
      }}
      getRowId={(row) => row.eventId || ""}
      rowHeight={72}
      autoHeight={true}
      disableRowSelectionOnClick
      sx={{
        ".MuiDataGrid-columnHeadersInner": {
          backgroundColor: "white",
        },
        ".MuiDataGrid-row:nth-child(odd)": {
          backgroundColor: "white",
        },
        ".MuiDataGrid-row:nth-child(even)": {
          backgroundColor: "#fafafa",
        },
        ".MuiDataGrid-footerContainer": {
          backgroundColor: "white",
        },
        ".MuiDataGrid-columnHeader, .MuiDataGrid-cell": {
          borderRight: "1px solid #efefef",
        },
      }}
    />
  );
}
