import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { Component } from "react";

import { PopupSection, PopupSectionBody, PopupSectionHeader } from "./styled";

export class FeaturePopupErrorBoundary extends Component<{
  children: React.ReactNode;
}> {
  state: { error: null | { message: string } } = { error: null };

  static getDerivedStateFromError(error: null | { message: string }) {
    return { error };
  }

  public reset = () => {
    this.setState({ error: null });
  };

  render() {
    if (this.state.error) {
      return (
        <PopupSection>
          <PopupSectionHeader>
            <span>
              <ErrorOutlineIcon />
              Sorry, something went wrong.
            </span>
          </PopupSectionHeader>
          <PopupSectionBody>
            If the problem persists, please contact us.
          </PopupSectionBody>
        </PopupSection>
      );
    } else {
      return this.props.children;
    }
  }
}
