import styled from "@emotion/styled";

export const PopupSection = styled.section`
  :not(:last-child) {
    border-bottom: 1px solid #d3d3d3;
    padding-bottom: 0.75rem;
  }
`;

export const PopupSectionContentVertical = styled.div`
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
  display: flex;
  flex-direction: column;
`;

export const PopupSectionContentHorizontal = styled.div`
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const PopupSectionHeader = styled.h5`
  margin-top: 0.5rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
  /* display: flex;
  flex-direction: row;
  justify-content: space-between;
  & svg {
    height: 0.9rem;
    margin-bottom: -0.1rem;
  } */
`;

export const PopupSectionBody = styled.div`
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
`;
