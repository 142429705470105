import { Button, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";

export class ErrorBoundary extends React.Component<{
  children: React.ReactNode;
  fallback?: React.ReactNode;
}> {
  state: { error: null | { message: string } } = { error: null };

  static getDerivedStateFromError(error: { message: string }) {
    return { error };
  }

  render() {
    if (this.state.error) {
      if (this.props.fallback) {
        return this.props.fallback;
      }
      return (
        <Box
          sx={{
            height: "100%",
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <Typography variant="h2">Sorry, something went wrong.</Typography>
          <Typography variant="h3">Code: E-72864</Typography>
          <Typography>
            If the problem persists, please contact us and quote the error code.
          </Typography>
          <Typography>
            We&apos;ve notified our team to look into this.
          </Typography>
          <Button
            variant="contained"
            sx={{ width: 120, mt: 2 }}
            onClick={() => {
              window.location.reload();
            }}
          >
            Reload
          </Button>
        </Box>
      );
    } else {
      return this.props.children;
    }
  }
}
