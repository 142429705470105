import { z } from "zod";
import {
  AdditionalStatus,
  Status,
} from "@athena/server/src/api/types/claimStatuses";
import { AssignedEngineersAccepted } from "@athena/server/src/trpc/routers/claims/types";
import { Urgency } from "@athena/server/src/api/types/claim";
import styled from "@emotion/styled";
import {
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Button,
  Box,
  Typography,
  Autocomplete,
  SxProps,
  Theme,
  Paper,
} from "@mui/material";

import { useSearchParams } from "react-router-dom";
import {
  useForm,
  Controller,
  FieldValues,
  Control,
  Path,
} from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useCallback } from "react";
import { string } from "prop-types";
import { DatePicker } from "@mui/x-date-pickers";

const UrlFiltersSchema = z.object({
  claimReference: z.string().optional(),
  policyReference: z.string().optional(),
  address: z.string().optional(),
  status: z.string().optional(),
  startDate: z.date({ coerce: true }).optional(),
  endDate: z.date({ coerce: true }).optional(),
});

const InsuranceFiltersFormSchema = z.object({
  claimReference: z.string().optional(),
  policyReference: z.string().optional(),
  address: z.string().optional(),
  status: z.string().optional(),
  createdAfter: z.date({ coerce: true }).optional(),
  createdBefore: z.date({ coerce: true }).optional(),
});

type ClaimFilterProps = {
  onChange: (filters: InsuranceClaimsFiltersValues) => void;
  onFiltersClosed: VoidFunction;
  isOpen: boolean;
};

interface ClaimFilterAutocompleteFieldProps<TControl extends FieldValues> {
  control: Control<TControl, any>;
  label: string;
  name: Path<TControl>;
  placeholder?: string;
  options: Array<string>;
}

export type InsuranceClaimsFiltersValues = z.infer<
  typeof InsuranceFiltersFormSchema
>;

export const InsuranceClaimFilters = (props: ClaimFilterProps) => {
  const [params, setParams] = useSearchParams();
  const paramsObject: Record<string, string | Array<string>> = Array.from(
    params.entries()
  ).reduce(
    (sum, [key, value]) => ({
      ...sum,
      [key]: value.includes(",") ? value.split(",") : value,
    }),
    {} as Record<string, string | Array<string>>
  );

  const filtersFromParams = UrlFiltersSchema.safeParse(paramsObject);

  const { control, handleSubmit, reset, resetField, ...rest } =
    useForm<InsuranceClaimsFiltersValues>({
      resolver: zodResolver(InsuranceFiltersFormSchema),
      defaultValues: {
        claimReference: undefined,
        policyReference: undefined,
        address: undefined,
        status: undefined,
        createdAfter: undefined,
        createdBefore: undefined,
        ...(filtersFromParams.success ? filtersFromParams.data : {}),
      },
    });

  const { onChange, onFiltersClosed, isOpen } = props;

  const onSubmit = useCallback(
    (values: InsuranceClaimsFiltersValues) => {
      onChange?.(values);
      setParams(
        Object.entries(values).reduce(
          (sum, [key, value]) => ({
            ...sum,
            ...(value && { [key]: value }),
          }),
          {}
        )
      );
      onFiltersClosed();
    },
    [onChange, onFiltersClosed]
  );

  if (!isOpen) {
    return <></>;
  }

  return (
    <Paper
      sx={{
        padding: "1.5rem",
        borderRadius: 0,
        borderBottom: "1px solid lightgray",
      }}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        {
          <ClaimFilterSection>
            <h2 style={{ marginBottom: "1rem" }}>Filter</h2>
            <FiltersContainer>
              <Controller
                render={({ field: props, fieldState: { error } }) => {
                  return (
                    <TextField
                      {...props}
                      value={props.value === undefined ? "" : props.value}
                      helperText={error ? error.message : null}
                      label={"Claim Reference"}
                      variant="outlined"
                      size="small"
                    />
                  );
                }}
                name={"claimReference"}
                control={control}
              />
              <Controller
                render={({ field: props, fieldState: { error } }) => {
                  return (
                    <TextField
                      {...props}
                      value={props.value === undefined ? "" : props.value}
                      helperText={error ? error.message : null}
                      label={"Policy Reference"}
                      variant="outlined"
                      size="small"
                    />
                  );
                }}
                name={"policyReference"}
                control={control}
              />
              <Controller
                render={({ field: props, fieldState: { error } }) => {
                  return (
                    <TextField
                      {...props}
                      value={props.value === undefined ? "" : props.value}
                      helperText={error ? error.message : null}
                      label={"Address"}
                      variant="outlined"
                      size="small"
                    />
                  );
                }}
                name={"address"}
                control={control}
              />
              <Controller
                render={({ field: props, fieldState: { error } }) => {
                  return (
                    <TextField
                      {...props}
                      value={props.value === undefined ? "" : props.value}
                      helperText={error ? error.message : null}
                      label={"Status"}
                      variant="outlined"
                      size="small"
                    />
                  );
                }}
                name={"status"}
                control={control}
              />
              <Controller
                render={({ field: props, fieldState: { error } }) => {
                  return (
                    <DatePicker
                      label="From"
                      slotProps={{
                        textField: {
                          placeholder: "Enter start date range",
                        },
                      }}
                      {...props}
                    />
                  );
                }}
                name={"createdAfter"}
                control={control}
              />
              <Controller
                render={({ field: props, fieldState: { error } }) => {
                  return (
                    <DatePicker
                      label="To"
                      slotProps={{
                        textField: {
                          placeholder: "Enter end date range",
                        },
                      }}
                      {...props}
                    />
                  );
                }}
                name={"createdBefore"}
                control={control}
              />
            </FiltersContainer>
            <Button
              variant="contained"
              type="submit"
              sx={{ width: 200, mr: 2 }}
            >
              Apply
            </Button>
            <Button
              variant="outlined"
              onClick={() => {
                onFiltersClosed();
              }}
              sx={{ width: 200 }}
            >
              Cancel
            </Button>
          </ClaimFilterSection>
        }
      </form>
    </Paper>
  );
};

const ClaimFilterSection = styled.div`
  background-color: white;
  padding: 1.5rem;
`;

const FiltersContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1rem;
  & > :not(:last-child) {
    margin-right: 8px;
  }
  & > * {
    width: 300px;
  }
  margin-bottom: 1rem;
`;
