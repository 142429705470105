import { Typography, Tabs } from "@mui/material";
import { Box, Container } from "@mui/system";
import { Outlet, useLocation } from "react-router-dom";
import { LinkTab } from "src/shared/linkTab/LinkTab";
import Menu from "../menu/Menu";
import { useContext, useEffect, useState } from "react";
import { PageNameContext } from "src/App";

export const Settings = () => {
  const location = useLocation();
  const pathSections = location.pathname.split("/");
  const lastSection = pathSections[pathSections.length - 1];
  const [selectedTab, setSelectedTab] = useState(lastSection || "general");
  const { setPageName } = useContext(PageNameContext);
  useEffect(() => {
    setPageName("Settings");
  }, []);
  return (
    <Menu>
      <Box sx={{ flexGrow: 1, mb: 2 }}>
        <Container
          sx={{
            marginTop: 3,
            maxWidth: "1800px",
          }}
          maxWidth={false}
        >
          <Typography
            variant="h1"
            component="h1"
            sx={{ flexGrow: 1, marginBottom: 3 }}
          >
            User Settings
          </Typography>

          <Box sx={{ borderBottom: 2, borderColor: "divider", mb: 3 }}>
            <Tabs
              value={selectedTab}
              onChange={(_, newTab) => {
                setSelectedTab(newTab);
              }}
              aria-label="basic tabs example"
              variant="fullWidth"
            >
              <LinkTab label="General" value={"general"} link={"general"} />
              <LinkTab
                label="Survey Management"
                value={"surveyManagement"}
                link={"surveyManagement"}
              />
            </Tabs>
          </Box>
          <Outlet />
        </Container>
      </Box>
    </Menu>
  );
};
