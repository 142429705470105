import { Role } from "../../../api/types/user.ts";

export enum OrgType {
  Engineering = "engineering",
  Insurer = "insurer",
  LossAdjuster = "loss-adjuster",
  EQC = "eqc",
}

export interface User {
  linz: string;
  userId: string;
  firstName?: string;
  lastName?: string;
  email: string;
  signatureUrl?: string;
  organisation: {
    id: string;
    name: string;
  };
  needsSetup: boolean;
  roles: Role[];
  preferredHomePage?: Role;
}

export type Organisation = {
  organisationId: string;
  orgType: OrgType;
  name: string;
  logoUrl: string;
  draftImageUrl: string;
  assignedInsurerIds?: Array<string>;
};

export enum Permission {
  EngineeringClaimsRead,
  EngineeringClaimsWrite,
  InsurerClaimsRead,
  InsurerClaimsWrite,
  PolicyRead,
  PolicyWrite,
}

export interface UserData {
  user: User;
  organisation?: Organisation;
  permissions: Array<Permission>;
}
