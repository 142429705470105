import { FC, RefObject, useCallback, useEffect, useState } from "react";
import { ButtonStyle } from "../components/styled";
import OpenInFullIcon from "@mui/icons-material/OpenInFull";
import CloseFullscreenIcon from "@mui/icons-material/CloseFullscreen";

interface RequestFullscreenProps {
  targetRef: RefObject<HTMLElement>;
}

export const RequestFullscreen: FC<RequestFullscreenProps> = ({
  targetRef,
}) => {
  const [isFullscreen, setIsFullscreen] = useState(false);

  useEffect(() => {
    function fullscreenChangeHandler() {
      if (!document.fullscreenElement) {
        setIsFullscreen(false);
      }
    }
    if (document.addEventListener) {
      document.addEventListener(
        "fullscreenchange",
        fullscreenChangeHandler,
        false
      );
    }

    return () => {
      document.removeEventListener("fullscreenchange", fullscreenChangeHandler);
    };
  });

  const onClickHandler = useCallback(() => {
    if (targetRef.current && !isFullscreen) {
      targetRef.current.requestFullscreen();
      setIsFullscreen(true);
    } else {
      setIsFullscreen(false);
      document.exitFullscreen();
    }
  }, [targetRef, isFullscreen]);

  return (
    <ButtonStyle onClick={onClickHandler}>
      {isFullscreen ? <CloseFullscreenIcon /> : <OpenInFullIcon />}
    </ButtonStyle>
  );
};
