import { Event as EventType } from "@athena/server/src/api/types/event";
import { EventServiceLevelAgreement } from "@athena/server/src/api/types/eventServiceLevelAgreement";
import { Box, Container, Tab, Tabs } from "@mui/material";
import { useState } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { axiosClient } from "src/lib/axiosClient";
import Menu from "src/modules/menu/Menu";
import { useCurrentUser } from "src/shared/hooks/useCurrentUser";
import { usePageName } from "src/shared/hooks/usePageName";
import { EventDetails } from "./EventDetails";
import { NewEvent } from "./NewEvent";
import { ServiceLevelAgreements } from "./ServiceLevelAgreements";

type EventProps = {};

export const Event = (props: EventProps) => {
  const { eventId } = useParams();
  const { userData } = useCurrentUser();
  const { isLoading: loadingEvent, data: event } = useQuery(
    `event${eventId}`,
    async (): Promise<EventType> =>
      (await axiosClient.get(`/events/global/${eventId}`)).data
  );
  const { isLoading: loadingSLA, data: sla } = useQuery(
    `event-${eventId}-sla`,
    async (): Promise<EventServiceLevelAgreement> =>
      (await axiosClient.get(`/service-level-agreement/${eventId}`)).data
  );
  usePageName(
    eventId === "new" ? "Request Event" : event?.name || "Event Details"
  );
  const [selectedTab, setSelectedTab] = useState("details");
  return (
    <Menu>
      <Box sx={{ flexGrow: 1, mb: 2 }}>
        <Container
          sx={{
            marginTop: 3,
            maxWidth: "1800px",
          }}
          maxWidth={false}
        >
          <Tabs
            value={selectedTab}
            onChange={(_, newTab) => {
              setSelectedTab(newTab);
            }}
            aria-label="basic tabs example"
            variant="fullWidth"
          >
            <Tab label="Event Details" value={"details"} />
            {userData?.organisation?.orgType != "eqc" && (
              <Tab
                label="Service Level Agreement Deadlines"
                value={"service-level-agreement-deadlines"}
                disabled={eventId === "new"}
              />
            )}
          </Tabs>
          {selectedTab === "details" && eventId === "new" && <NewEvent />}
          {selectedTab === "details" && eventId !== "new" && <EventDetails />}
          {selectedTab === "service-level-agreement-deadlines" &&
            userData?.organisation?.orgType != "eqc" &&
            eventId !== "new" &&
            !loadingSLA && (
              <ServiceLevelAgreements serviceLevelAgreements={sla} />
            )}
        </Container>
      </Box>
    </Menu>
  );
};
