import {
  bboxToTile,
  getChildren,
  getParent,
  tileToBBOX,
  // @ts-ignore
} from "@mapbox/tilebelt";
import { axiosClient } from "./axiosClient";
import { store } from "./storage";
import { getTransform } from "ol/proj";
import { Point } from "ol/geom";
import { useEffect, useState } from "react";
import { useOfflineClaim } from "src/modules/claims/useOfflineClaim";
import { useClaimId } from "src/modules/claims/hooks/useClaimId";
import { config } from "./config";
import { enqueueErrorSnackbar } from "src/shared/snackbar/SnackbarHelper";
import { CapacitorHttp } from "@capacitor/core";
import { Survey } from "src/modules/claims/ClaimStatus";
import { useLoadSummarySources } from "src/modules/claims/Maps/hooks/useLoadSummarySources";

export const useOfflineTiles = () => {
  const claimId = useClaimId();
  const { claim } = useOfflineClaim(claimId);
  const [progress, setProgress] = useState({ current: 0, total: 0 });
  const [downloading, setDownloading] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [isCached, setIsCached] = useState(false);
  const parcelsStoreKey = `layer-${`${config.gisApiUrl}/geo/features/collections/nz_primary_parcels_v1/tiles/WebMercatorQuad/{z}/{x}/{y}.pbf`}-${claimId}`;
  const summarySources = useLoadSummarySources(
    claim?.landslips?.map((ls) => ls.landslipId) || [],
    claim?.stormAndFlood?.stormAndFloodId || ""
  );
  useEffect(() => {
    const checkCache = async () => {
      const cached = await store.get(`${claimId}-claim-cached`);
      if (cached) {
        setIsCached(true);
      }
    };
    checkCache();
  }, []);

  const downloadTiles = async (
    layerName: string,
    extent: [number, number, number, number],
    min_level: number,
    max_level: number,
    surveys: Survey[] = []
  ) => {
    const tiles = getTilesForExtent(extent, min_level, max_level);
    setDownloading(true);
    setProgress({ current: 0, total: tiles.length });
    let n = 0;
    let chunk: { promise: Promise<{ data: any }>; storeKey: string }[] = [];
    const tileKeys: string[] = [];
    for (const tile of tiles) {
      n++;

      const storeKey = `${layerName}-${tile[2]}-${tile[0]}-${tile[1]}`;
      const hasKey = await store.get(storeKey);

      if (!hasKey) {
        const promise = CapacitorHttp.get({
          url: `${config.gisApiUrl}/geo/basemaps/collections/aerial/tiles/WebMercatorQuad/${tile[2]}/${tile[0]}/${tile[1]}.webp`,
          webFetchExtra: { credentials: "include" },
          responseType: "blob",
        });

        chunk.push({ promise, storeKey });
        if (chunk.length >= 4) {
          const res = await Promise.all(chunk.map((chunk) => chunk.promise));
          setProgress({ current: n, total: tiles.length });
          let index = 0;
          for (const r of res) {
            const base64 = "data:image/webp;base64," + r.data;
            // const blob = new Blob([base64], { type: "image/webp" });
            await store.set(chunk[index].storeKey, base64);
            tileKeys.push(chunk[index].storeKey);
            index++;
          }
          chunk = [];
        }
      }
      for (const survey of surveys) {
        const storeKey = `${survey.id}-${tile[2]}-${tile[0]}-${tile[1]}`;
        const hasKey = await store.get(storeKey);

        if (!hasKey) {
          const promise = CapacitorHttp.get({
            url: `${config.gisApiUrl}/geo/ath/imagery/collections/${survey.id}/tiles/EPSG:3857/${tile[2]}/${tile[0]}/${tile[1]}.webp`,
            webFetchExtra: { credentials: "include" },
            responseType: "blob",
          });

          chunk.push({ promise, storeKey });
          if (chunk.length >= 4) {
            const res = await Promise.all(chunk.map((chunk) => chunk.promise));
            setProgress({ current: n, total: tiles.length });
            let index = 0;
            for (const r of res) {
              const base64 = "data:image/webp;base64," + r.data;
              // const blob = new Blob([base64], { type: "image/webp" });
              await store.set(chunk[index].storeKey, base64);
              tileKeys.push(chunk[index].storeKey);
              index++;
            }
            chunk = [];
          }
        }
      }
    }
    await store.set(`${claimId}-claim-tiles`, tileKeys);
    await store.set(`${claimId}-claim-cached`, true);
    await store.set(`${claimId}-surveys-cached`, JSON.stringify(surveys));
    const parcels = await axiosClient
      .get(
        `${
          config.gisApiUrl
        }/geo/features/collections/nz_primary_parcels_v1/items?bbox=${extent.join(
          ","
        )}`
      )
      .catch(() => {
        enqueueErrorSnackbar("Failed to download parcels");
      });

    const { landslipSources, ...sources } = summarySources;
    await Promise.all(
      Object.values(sources).map(async (source) => {
        if ((source.getUrl() as string).includes(`damage_source_id=""`)) return;
        const data = await axiosClient
          .get(source.getUrl() as string)
          .catch(() => {
            enqueueErrorSnackbar("Failed to download features");
          });
        if (!data) return;
        await store.set(
          `layer-${source.getUrl()}-${claimId}`,
          JSON.stringify(data.data.features)
        );
      })
    );
    await Promise.all(
      landslipSources.map(async (landslip) => {
        const { landslipId, ...sources } = landslip;
        const collections = await Promise.all(
          Object.values(sources).map(async (source) => {
            const data = await axiosClient
              .get(source.getUrl() as string)
              .catch(() => {
                enqueueErrorSnackbar("Failed to download features");
              });
            if (!data) return;
            await store.set(
              `layer-${source.getUrl()}-${claimId}`,
              JSON.stringify(data.data.features)
            );
          })
        );
        return { landslipId, collections };
      })
    );
    setDownloading(false);

    if (!parcels) return;
    // Key needs to match useOfflineLayer url
    await store.set(parcelsStoreKey, JSON.stringify(parcels.data.features));
    setIsCached(true);
    setProgress({ current: 0, total: 0 });
  };
  return {
    isCached,
    downloading,
    progress,
    clearTiles: async () => {
      setDeleting(true);
      const tileKeys = await store.get(`${claimId}-claim-tiles`);
      if (tileKeys) {
        for (const key of tileKeys) {
          await store.remove(key);
        }
      }
      await store.remove(parcelsStoreKey);
      await store.remove(`${claimId}-claim-tiles`);
      await store.remove(`${claimId}-claim-cached`);
      setIsCached(false);
      setDeleting(false);
    },
    deleting,
    downloadTiles: async (radius: number, selectedSurveys: Survey[]) => {
      if (claim && claim.location?.geo?.coordinates) {
        return downloadTiles(
          `${claimId}-claim`,
          calcBbox(new Point(claim.location.geo.coordinates), radius),
          0,
          21,
          selectedSurveys
        );
      }
    },
  };
};

function crosses_axis(
  a_min: number,
  a_max: number,
  b_min: number,
  b_max: number
): boolean {
  // Dirty single axis intersects check, works fine for small areas, not suitable for large areas if using Lat/Long
  return (
    (a_min >= b_min && a_min <= b_max) ||
    (a_max >= b_min && a_max <= b_max) ||
    (b_min >= a_min && b_min <= a_max) ||
    (b_max >= a_min && b_max <= a_max)
  );
}

function intersect_extent(
  a: [number, number, number, number],
  b: [number, number, number, number]
): boolean {
  // Returns True if the extents a & b intersect
  return (
    crosses_axis(a[0], a[2], b[0], b[2]) && crosses_axis(a[1], a[3], b[1], b[3])
  );
}

function getChildTiles(
  extent: [number, number, number, number],
  tile: [number, number, number]
): Array<[number, number, number]> {
  const child_tiles: Array<[number, number, number]> = getChildren(tile);
  return child_tiles.filter((i) => {
    return intersect_extent(extent, tileToBBOX(i));
  });
}

function getTilesForExtent(
  extent: [number, number, number, number],
  min_level: number,
  max_level: number
): Array<[number, number, number]> {
  // set base tile
  const base_tile: [number, number, number] = bboxToTile(extent);
  const all_tiles: Array<[number, number, number]> = [];
  all_tiles.push(base_tile);

  // get parent
  let next_tile = base_tile;
  while (next_tile[2] > min_level) {
    next_tile = getParent(next_tile);
    all_tiles.push(next_tile);
  }

  // get children (but only if they intersect our target extent)
  // TODO: Make this a recursive function, not a dirty while loop...
  const next_tiles = [base_tile];
  while (next_tiles.length > 0) {
    const tile = next_tiles.pop();
    if (tile) {
      const child_tiles = getChildTiles(extent, tile);

      all_tiles.push(...child_tiles);
      if (child_tiles.length > 0) {
        if (child_tiles[0][2] <= max_level) {
          next_tiles.push(...child_tiles);
        }
      }
    }
  }

  return all_tiles;
}

export const calcBbox = (
  point: Point,
  radius: number
): [number, number, number, number] => {
  const to3857 = getTransform("EPSG:4326", "EPSG:3857");

  point.applyTransform(to3857);

  const bl = point.clone();
  bl.translate(radius * -1.0, radius * -1.0);

  const tr = point.clone();
  tr.translate(radius, radius);

  const to4326 = getTransform("EPSG:3857", "EPSG:4326");
  bl.applyTransform(to4326);
  tr.applyTransform(to4326);
  const blCoords = bl.getCoordinates();
  const trCoords = tr.getCoordinates();
  return [blCoords[0], blCoords[1], trCoords[0], trCoords[1]];
};
