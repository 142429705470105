import { SxProps } from "@mui/material";
import { InputProps as StandardInputProps } from "@mui/material/Input/Input";
import TextField from "@mui/material/TextField";
import { OutlinedTextFieldProps } from "@mui/material/TextField/TextField";
import {
  Control,
  Controller,
  FieldValues,
  Path,
  PathValue,
} from "react-hook-form";

interface FormTextFieldProps<T extends FieldValues>
  extends Pick<OutlinedTextFieldProps, "onBlur" | "fullWidth" | "label"> {
  name: Path<T>;
  control: Control<T>;
  placeholder?: string;
  type?: string;
  defaultValue?: PathValue<T, Path<T>> | undefined;
  multiline?: boolean;
  sx?: SxProps;
  minRows?: number;
  error?: boolean;
  helperText?: string;
  InputProps?: Partial<StandardInputProps>;
  step?: string;
  onChange?: (value: any) => void;
}

export function FormTextField<T extends FieldValues>(
  props: FormTextFieldProps<T>
) {
  const {
    control,
    label,
    name,
    placeholder,
    defaultValue,
    sx,
    multiline,
    minRows,
    error,
    helperText,
    onBlur,
    onChange,
    ...rest
  } = props;
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field }) => (
        <TextField
          sx={sx}
          id={name}
          label={label}
          autoComplete="one-time-code"
          variant="outlined"
          size="small"
          placeholder={placeholder}
          multiline={multiline}
          minRows={minRows}
          error={error}
          helperText={helperText}
          {...field}
          onBlur={(e) => {
            field.onBlur();
            onBlur?.(e);
          }}
          onChange={(e) => {
            field.onChange(e.target.value);
            onChange?.(e.target.value);
          }}
          {...rest}
        />
      )}
    />
  );
}
