import { Event } from "@athena/server/src/api/types/event";
import { User } from "@athena/server/src/dynamo-db/client";
import {
  Box,
  Button,
  Chip,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { axiosClient } from "src/lib/axiosClient";
import {
  enqueueSavingSnackbar,
  enqueueSuccessSnackbar,
} from "src/shared/snackbar/SnackbarHelper";
import Menu from "../menu/Menu";
import { DomainManager } from "./DomainManager";
import { LinzManager } from "./LinzManager";
import { RequestedEvent } from "./RequestedEvent";

export const InfinityGuardian = () => {
  const queryClient = useQueryClient();
  const [adminOrgId, setAdminOrgId] = useState<string>();
  const [adminUserId, setAdminUserId] = useState<string>();
  const removeGuardian = async (userId: string) => {
    const finishedSaving = enqueueSavingSnackbar();
    const res = (await axiosClient.put(`/admin/remove-user/${userId}`)).data;
    finishedSaving();
    enqueueSuccessSnackbar("Removed");
    queryClient.invalidateQueries({
      queryKey: [`infinityGuardianUsers`],
    });
    return res;
  };

  const addGuardian = async (userId: string) => {
    const finishedSaving = enqueueSavingSnackbar();
    const res = (await axiosClient.put(`/admin/add-user/${userId}`)).data;
    finishedSaving();
    enqueueSuccessSnackbar("Added");
    queryClient.invalidateQueries({
      queryKey: [`infinityGuardianUsers`],
    });
    return res;
  };

  const addOrg = async (orgName: string, orgType: string) => {
    const finishedSaving = enqueueSavingSnackbar();
    const res = (
      await axiosClient.post(`/admin/organisation`, { orgName, orgType })
    ).data;
    finishedSaving();
    enqueueSuccessSnackbar(
      "Added organisation. This will take a moment to sync and show on this page."
    );
    queryClient.invalidateQueries({
      queryKey: [`infinityGuardianOrgs`],
    });
    return res;
  };

  const toggleAdmin = async (orgId: string, userId: string) => {
    const finishedSaving = enqueueSavingSnackbar();
    const res = (
      await axiosClient.put(
        `/admin/organisation/${orgId}/user/${userId}/toggle-admin`,
        { orgName, orgType }
      )
    ).data;
    finishedSaving();
    enqueueSuccessSnackbar("Added");
    queryClient.invalidateQueries({
      queryKey: [`users${adminOrgId}`],
    });
    return res;
  };
  const [reIndexing, setReIndexing] = useState(false);
  const [migrating, setMigrating] = useState(false);
  const reIndex = async () => {
    setReIndexing(true);
    const finishedSaving = enqueueSavingSnackbar(
      "Re-indexing... this will take a minute"
    );
    const res = (await axiosClient.post(`/admin/re-index-claims`)).data;
    finishedSaving();
    enqueueSuccessSnackbar(
      "Finished re-indexing, please check home pages, claims list and report list"
    );
    setReIndexing(false);
    return res;
  };

  const runMigrations = async () => {
    setMigrating(true);
    const finishedSaving = enqueueSavingSnackbar(
      "Migrating... this will take a minute"
    );
    const res = (await axiosClient.put(`/admin/migrate`)).data;
    finishedSaving();
    enqueueSuccessSnackbar("Finished migrating");
    setMigrating(false);
    return res;
  };

  const { isLoading: loading, data: config } = useQuery(
    `infinityGuardianUsers`,
    async (): Promise<{
      guardians: { name: string; id: string }[];
    }> => (await axiosClient.get("/admin")).data
  );
  const { isLoading: loadingOrgs, data: orgs } = useQuery(
    `infinityGuardianOrgs`,
    async (): Promise<{
      eqcOrgs: { name: string; organisationId: string; type: string }[];
      engineeringOrgs: { name: string; organisationId: string; type: string }[];
      insurerOrgs: { name: string; organisationId: string; type: string }[];
      lossAdjusterOrgs: {
        name: string;
        organisationId: string;
        type: string;
      }[];
    }> => (await axiosClient.get("/admin/organisation")).data
  );

  const [userId, setUserId] = useState("");
  const [showConfirmRemoveId, setShowConfirmRemoveId] = useState<string>();
  const [showConfirmSync, setShowConfirmSync] = useState(false);
  const [showConfirmMigrate, setShowConfirmMigrate] = useState(false);
  const [orgName, setOrgName] = useState<string>();
  const [orgType, setOrgType] = useState<
    "insurer" | "engineering" | "loss-adjuster" | "eqc"
  >();
  const [eventName, setEventName] = useState<string>();
  const [eventDate, setEventDate] = useState<string>();
  const addEvent = async (name: string, dateOfEvent?: string) => {
    const finishedSaving = enqueueSavingSnackbar();
    const res = (
      await axiosClient.post(`/admin/events/global`, { name, dateOfEvent })
    ).data;
    finishedSaving();
    enqueueSuccessSnackbar("Added");
    queryClient.invalidateQueries({
      queryKey: [`events`],
    });
    return res;
  };

  const approveEvent = async (
    eventId: string,
    name: string,
    dateOfEvent?: string
  ) => {
    const finishedSaving = enqueueSavingSnackbar();
    const res = (
      await axiosClient.put(`/admin/events/request/${eventId}/approve`, {
        name,
        dateOfEvent,
      })
    ).data;
    finishedSaving();
    enqueueSuccessSnackbar("Added");
    queryClient.invalidateQueries({
      queryKey: [`events`],
    });
    return res;
  };
  const { isLoading: loadingUsers, data: users } = useQuery(
    `users${adminOrgId}`,
    async (): Promise<User[]> =>
      (await axiosClient.get(`/admin/organisation/${adminOrgId}/user`)).data,
    { enabled: !!adminOrgId }
  );

  const { isLoading: loadingEvents, data: activeEvents } = useQuery(
    `events`,
    async (): Promise<Event[]> => (await axiosClient.get(`/events/global`)).data
  );
  const { isLoading: loadingRequestedEvents, data: requestedEvents } = useQuery(
    `requested-events`,
    async (): Promise<Event[]> =>
      (await axiosClient.get(`/admin/event-request`)).data
  );
  const selectedAdminUser = users?.find((u) => u.userId === adminUserId);
  const combinedOrgs = orgs?.engineeringOrgs
    .concat(orgs?.insurerOrgs)
    .concat(orgs?.lossAdjusterOrgs);

  return (
    <Menu>
      {showConfirmRemoveId && (
        <>
          <Dialog open>
            <DialogTitle>Remove User?</DialogTitle>
            <DialogActions>
              <Button
                onClick={() => {
                  removeGuardian(showConfirmRemoveId);
                  setShowConfirmRemoveId(undefined);
                }}
                variant="contained"
                color="warning"
              >
                Remove
              </Button>
              <Button onClick={() => setShowConfirmRemoveId(undefined)}>
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
        </>
      )}
      <Box sx={{ flexGrow: 1, mb: 2 }}>
        <Container
          sx={{
            marginTop: 3,
            maxWidth: "1800px",
          }}
          maxWidth={false}
        >
          <Typography
            variant="h1"
            component="h1"
            sx={{ flexGrow: 1, marginBottom: 3 }}
          >
            Infinity Guardian
          </Typography>
          <Paper sx={{ padding: "1.5rem" }}>
            <Typography variant="h4">Infinity Guardians</Typography>
            <Typography variant="body1" sx={{ mt: "0.5rem", mb: 2 }}>
              Administer Infinity Guardians via user id. The user must have an
              @infinitystudio.ai email. <br />
              They will be given permission to add and remove Guardians, as well
              as administer central records such as Insurers, Loss Adjusters,
              and Events across all organisations.
            </Typography>
            <Typography variant="body1" sx={{ mt: "0.5rem", mb: 2 }}>
              Current Guardians
              {config?.guardians.map((user) => {
                return (
                  <Chip
                    key={user.id}
                    label={user.name}
                    onDelete={() => {
                      setShowConfirmRemoveId(user.id);
                    }}
                    sx={{ ml: 2 }}
                  />
                );
              })}
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
              Add a user by ID{" "}
              <TextField
                value={userId}
                onChange={(e) => {
                  setUserId(e.target.value);
                }}
                sx={{ ml: 2, mr: 2 }}
              />
              <Button
                variant={"contained"}
                disabled={!userId}
                onClick={() => {
                  addGuardian(userId);
                }}
                sx={{ minWidth: 100 }}
              >
                Add
              </Button>
            </Box>
            <Divider />
            <Box
              sx={{
                display: "flex",
                mt: 2,
                flexDirection: "column",
              }}
            >
              <Typography variant="h4">Organisations</Typography>
              <Typography variant="subtitle1">
                The following organisations can be logged into using a special
                email format. If the organisation doesn&apos;t yet exist, you
                can create them below.
              </Typography>
              <Typography variant="subtitle1" sx={{ pl: 2 }}>
                - EQC - *your-email*+eqc@infinitystudio.ai <br />
                - Infinity Studio Loss Adjusters -
                *your-email*+lossadjuster@infinitystudio.ai <br />
                - Infinity Studio Insurance -
                *your-email*+insurer@infinitystudio.ai <br />
                - Infinity Studio Engineering 2 -
                *your-email*+org2@infinitystudio.ai
                <br />- Infinity Studio Engineering -
                *your-email*@infinitystudio.ai
              </Typography>
              <Box>
                <Typography variant="subtitle1">Engineering</Typography>
                {orgs?.engineeringOrgs.length === 0 && <span>None</span>}
                {orgs?.engineeringOrgs.map((org) => {
                  return (
                    <Chip
                      key={org.organisationId}
                      label={org.name}
                      sx={{ mr: 2, mb: 2 }}
                    />
                  );
                })}
                <Typography variant="subtitle1" sx={{ mt: 2 }}>
                  Insurers
                </Typography>
                {orgs?.insurerOrgs.length === 0 && <span>None</span>}
                {orgs?.insurerOrgs.map((org) => {
                  return (
                    <Chip
                      key={org.organisationId}
                      label={org.name}
                      sx={{ mr: 2, mb: 2 }}
                    />
                  );
                })}
                <Typography variant="subtitle1" sx={{ mt: 2 }}>
                  Loss Adjusters
                </Typography>
                {orgs?.lossAdjusterOrgs.length === 0 && <span>None</span>}
                {orgs?.lossAdjusterOrgs.map((org) => {
                  return (
                    <Chip
                      key={org.organisationId}
                      label={org.name}
                      sx={{ mr: 2, mb: 2 }}
                    />
                  );
                })}
                <Typography variant="subtitle1" sx={{ mt: 2 }}>
                  EQC
                </Typography>
                {orgs?.eqcOrgs.length === 0 && <span>None</span>}
                {orgs?.eqcOrgs.map((org) => {
                  return (
                    <Chip
                      key={org.organisationId}
                      label={org.name}
                      sx={{ mr: 2, mb: 2 }}
                    />
                  );
                })}
              </Box>

              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Box sx={{ width: 200 }}>Add a new Organisation</Box>

                <FormControl sx={{ m: 1, minWidth: 220, ml: 2 }} size="small">
                  <InputLabel id="demo-simple-select-helper-label1" shrink>
                    Name
                  </InputLabel>
                  <TextField
                    value={orgName}
                    onChange={(e) => {
                      setOrgName(e.target.value);
                    }}
                  />
                </FormControl>
                <FormControl sx={{ m: 1, minWidth: 220 }} size="small">
                  <InputLabel id="demo-simple-select-helper-label1" shrink>
                    Type
                  </InputLabel>
                  <Select
                    labelId="demo-multiple-chip-label1"
                    id="demo-multiple-chip1"
                    value={orgType}
                    onChange={(e) => {
                      setOrgType(e.target.value as any);
                    }}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: 48 * 4.5 + 8,
                          width: 350,
                        },
                      },
                    }}
                  >
                    <MenuItem value="engineering">Engineering</MenuItem>
                    <MenuItem value="insurer">Insurer</MenuItem>
                    <MenuItem value="loss-adjuster">Loss Adjuster</MenuItem>
                    <MenuItem value="eqc">EQC</MenuItem>
                  </Select>
                </FormControl>
                <Button
                  variant={"contained"}
                  disabled={!orgName || !orgType}
                  onClick={() => {
                    if (!orgName || !orgType) return;
                    addOrg(orgName, orgType);
                  }}
                  sx={{ minWidth: 100 }}
                >
                  Add
                </Button>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Box sx={{ width: 207 }}>Toggle an Admin User</Box>
              <FormControl sx={{ m: 1, minWidth: 220 }} size="small">
                <InputLabel id="demo-simple-select-helper-label1" shrink>
                  Organisation
                </InputLabel>
                <Select
                  value={adminOrgId}
                  onChange={(e) => {
                    setAdminOrgId(e.target.value as any);
                  }}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 48 * 4.5 + 8,
                        width: 350,
                      },
                    },
                  }}
                >
                  {orgs?.engineeringOrgs.map((org) => {
                    return (
                      <MenuItem
                        key={org.organisationId}
                        value={org.organisationId}
                      >
                        {org.name}
                      </MenuItem>
                    );
                  })}
                  {orgs?.insurerOrgs.map((org) => {
                    return (
                      <MenuItem
                        key={org.organisationId}
                        value={org.organisationId}
                      >
                        {org.name}
                      </MenuItem>
                    );
                  })}
                  {orgs?.lossAdjusterOrgs.map((org) => {
                    return (
                      <MenuItem
                        key={org.organisationId}
                        value={org.organisationId}
                      >
                        {org.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              <FormControl sx={{ m: 1, minWidth: 220 }} size="small">
                <InputLabel id="demo-simple-select-helper-label1" shrink>
                  User
                </InputLabel>
                <Select
                  labelId="demo-multiple-chip-label1"
                  id="demo-multiple-chip1"
                  value={adminUserId}
                  onChange={(e) => {
                    setAdminUserId(e.target.value as any);
                  }}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 48 * 4.5 + 8,
                        width: 350,
                      },
                    },
                  }}
                >
                  {users?.map((user) => (
                    <MenuItem value={user.userId} key={user.userId}>
                      {user.firstName + " " + user.lastName}
                      {user.roles.includes("admin") && " (admin)"}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Button
                variant={"contained"}
                disabled={!adminOrgId || !adminUserId}
                onClick={() => {
                  if (!adminOrgId || !adminUserId) return;
                  toggleAdmin(adminOrgId, adminUserId);
                }}
                sx={{ minWidth: 100 }}
              >
                {selectedAdminUser && selectedAdminUser.roles.includes("admin")
                  ? "Remove"
                  : "Add"}
              </Button>
            </Box>
            {showConfirmSync && (
              <>
                <Dialog open>
                  <DialogTitle>Re-index all claims?</DialogTitle>
                  <DialogContent>
                    This will incur large DB load, make sure you really need to
                    do this.
                  </DialogContent>
                  <DialogActions>
                    <Button
                      onClick={() => {
                        reIndex();
                        setShowConfirmSync(false);
                      }}
                      variant="contained"
                      color="error"
                      disabled={reIndexing}
                    >
                      Yes 🤠
                    </Button>
                    <Button onClick={() => setShowConfirmSync(false)}>
                      Cancel
                    </Button>
                  </DialogActions>
                </Dialog>
              </>
            )}
            {showConfirmMigrate && (
              <>
                <Dialog open>
                  <DialogTitle>Run migrations?</DialogTitle>
                  <DialogActions>
                    <Button
                      onClick={() => {
                        runMigrations();
                        setShowConfirmMigrate(false);
                      }}
                      variant="contained"
                      color="error"
                      disabled={migrating}
                    >
                      Yes 🤠
                    </Button>
                    <Button onClick={() => setShowConfirmMigrate(false)}>
                      Cancel
                    </Button>
                  </DialogActions>
                </Dialog>
              </>
            )}
            <Divider sx={{ mb: 2, mt: 2 }} />
            <Typography variant="h4">Linz Manager</Typography>
            <Box>
              <LinzManager />
            </Box>
            <Divider sx={{ mb: 2, mt: 2 }} />
            <Typography variant="h4">Domain Management</Typography>
            <Box>
              <DomainManager />
            </Box>
            <Divider sx={{ mb: 2, mt: 2 }} />
            <Typography variant="h4">Events</Typography>
            <Box>
              <Typography variant="subtitle1" sx={{ mt: 2 }}>
                Active Events
              </Typography>
              {activeEvents?.length === 0 && <span>None</span>}
              {activeEvents?.map((e) => {
                return (
                  <Chip key={e.eventId} label={e.name} sx={{ mr: 2, mb: 2 }} />
                );
              })}
              <Typography variant="subtitle1" sx={{ mt: 2 }}>
                Requested Events
              </Typography>
              {requestedEvents?.length === 0 && <span>None</span>}
              {requestedEvents?.map((e) => {
                return (
                  <Box
                    sx={{ display: "flex", alignItems: "center" }}
                    key={e.eventId}
                  >
                    <RequestedEvent
                      addEvent={(eventId, name, date) => {
                        approveEvent(eventId, name, date);
                      }}
                      event={e}
                      requestedByOrgName={
                        combinedOrgs?.find(
                          (o) =>
                            o.organisationId === e.requestedByOrganisationId
                        )?.name || "Unknown"
                      }
                    />
                  </Box>
                );
              })}
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Box sx={{ width: 200 }}>Add a new Event</Box>

              <FormControl sx={{ m: 1, minWidth: 220, ml: 2 }} size="small">
                <InputLabel shrink>Name</InputLabel>
                <TextField
                  value={eventName}
                  onChange={(e) => {
                    setEventName(e.target.value);
                  }}
                />
              </FormControl>
              <FormControl sx={{ m: 1, minWidth: 220 }} size="small">
                <InputLabel shrink>Date of Event</InputLabel>
                <DatePicker
                  onChange={(event) => {
                    setEventDate(event || undefined);
                  }}
                  value={eventDate}
                  sx={{ width: 219 }}
                />
              </FormControl>

              <Button
                variant={"contained"}
                disabled={!eventName}
                onClick={() => {
                  if (!eventName) return;
                  addEvent(eventName, eventDate);
                }}
                sx={{ minWidth: 100 }}
              >
                Add
              </Button>
            </Box>
            <Divider sx={{ mt: 2 }} />
            <Box
              sx={{
                borderRadius: 4,
                border: "1px solid red",
                padding: 2,
                mt: 2,
              }}
            >
              <Typography variant="h4" component="h4" sx={{ flexGrow: 1 }}>
                Danger Zone
              </Typography>
              <Typography
                variant="subtitle1"
                sx={{ flexGrow: 1, mt: 2, mb: 2 }}
              >
                <b>Only use these actions if you know what you are doing</b>
              </Typography>
              <Box>
                Sync <b>all claims</b> for <b>all organisations</b> to our
                search indexing provider (Typesense).
                <Button
                  variant={"contained"}
                  color={"error"}
                  sx={{ ml: 2, width: 120 }}
                  disabled={reIndexing}
                  onClick={() => {
                    setShowConfirmSync(true);
                  }}
                >
                  Sync
                </Button>
              </Box>
              <Box>
                Run all migrations.
                <Button
                  variant={"contained"}
                  color={"error"}
                  sx={{ ml: 2, width: 200 }}
                  disabled={reIndexing}
                  onClick={() => {
                    setShowConfirmMigrate(true);
                  }}
                >
                  Run Migrations
                </Button>
              </Box>
            </Box>
          </Paper>
        </Container>
      </Box>
    </Menu>
  );
};
