import {
  bridgeAndCulvertTable,
  conceptualRemedialWorksList,
  constructionIssuesTable,
  eqcConsiderationDetails,
  imminentRiskDetails,
  pageNumber,
  propertyDamageDetails,
  retainingWallTable,
  summaryTable,
  totalPages,
} from "@athena/server/src/api/types/templateVariables";
import {
  Button,
  Checkbox,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  Paper,
  Popover,
  TextField,
  Typography,
} from "@mui/material";
import { Node, mergeAttributes } from "@tiptap/core";
import { NodeViewWrapper, ReactNodeViewRenderer } from "@tiptap/react";
import { useState } from "react";
import { ImminentRiskDetails } from "./templateVariables/ImminentRiskDetails";

import styled from "@emotion/styled";
import { Stack } from "@mui/system";
import { BridgesAndCulvertsTable } from "./templateVariables/BridgesAndCulvertsTable";
import { ConceptualRemedialWorksList } from "./templateVariables/ConceptualRemedialWorksList";
import { ConstructionIssuesTable } from "./templateVariables/ConstructionIssuesTable";
import { EQCConsiderationDetails } from "./templateVariables/EQCConsiderationDetails";
import { PropertyDamageDetails } from "./templateVariables/PropertyDamageDetails";
import { RetainingWallTable } from "./templateVariables/RetainingWallTable";
import { SummaryTable } from "./templateVariables/SummaryTable";

export interface TemplateVariableOptions {
  inline: boolean;
  allowBase64: boolean;
  HTMLAttributes: Record<string, any>;
}

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    TemplateVariable: {
      /**
       * Add a TemplateVariable
       */
      setTemplateVariable: (options: {
        label: string;
        value: string;
        description: string;
        hasConfig: boolean;
      }) => ReturnType;
    };
  }
}

export const inputRegex =
  /(?:^|\s)(!\[(.+|:?)]\((\S+)(?:(?:\s+)["'](\S+)["'])?\))$/;

export const TemplateVariable = Node.create<TemplateVariableOptions>({
  name: "templateVariable",
  group: "inline",

  inline: true,

  selectable: false,

  atom: true,
  addAttributes() {
    return {
      label: {
        default: null,
        parseHTML: (el) => {
          return (el as HTMLSpanElement).getAttribute("label");
        },
        renderHTML: (attributes) => {
          return {
            label: attributes.label,
          };
        },
      },

      value: {
        default: null,
        parseHTML: (el) => {
          return (el as HTMLSpanElement).getAttribute("value");
        },
        renderHTML: (attributes) => {
          let className = "";
          if (attributes.value === totalPages.value) {
            className = "totalPages";
          }

          if (attributes.value === pageNumber.value) {
            className = "pageNumber";
          }
          return {
            value: attributes.value,
            class: className,
          };
        },
      },
      context: {
        default: null,
        parseHTML: (el) => {
          return (el as HTMLSpanElement).getAttribute("context");
        },
        renderHTML: (attributes) => {
          return {
            context: attributes.context,
          };
        },
      },
      hasConfig: {
        default: null,
        parseHTML: (el) => {
          return (el as HTMLSpanElement).getAttribute("data-hasConfig");
        },
        renderHTML: (attributes) => {
          return {
            hasConfig: attributes.hasConfig,
          };
        },
      },
      description: {
        default: null,
        parseHTML: (el) => {
          return (el as HTMLSpanElement).getAttribute("data-description");
        },
        renderHTML: (attributes) => {
          return {
            description: attributes.description,
          };
        },
      },
      fontSize: {
        default: null,
        parseHTML: (el) => {
          return (el as HTMLSpanElement).getAttribute("fontSize");
        },
        renderHTML: (attributes) => {
          return {
            fontSize: attributes.fontSize,
          };
        },
      },
      isBold: {
        default: false,
        parseHTML: (el) => {
          return (el as HTMLSpanElement).getAttribute("isBold");
        },
        renderHTML: (attributes) => {
          return {
            isBold: attributes.isBold,
          };
        },
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: "span[value]",
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ["span", mergeAttributes(HTMLAttributes), ""];
  },

  addCommands() {
    return {
      setTemplateVariable:
        (options) =>
        ({ chain }) => {
          chain()
            .insertContentAt(this.editor.state.selection.head, {
              type: this.name,
              attrs: options,
            })
            .focus()
            .run();
          return true;
        },
    };
  },

  addNodeView() {
    return ReactNodeViewRenderer(TemplateVar);
  },
});

type TemplateVarProps = {
  node: any;
  updateAttributes: (attrs: Record<string, any>) => void;
};

const TemplateVar = (props: TemplateVarProps) => {
  const [showModal, setShowModal] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [dirty, setDirty] = useState(false);
  const hasConfig = props.node.attrs.hasConfig;
  const [anchorEl, setAnchorEl] = useState<HTMLSpanElement | null>(null);

  const modalProps = {
    context: props.node.attrs.context,
    onSave: (context: any) => {
      props.updateAttributes({
        context,
      });
      setShowModal(false);
      setDirty(false);
    },
    setDirty,
    onClose: () => {
      if (dirty) {
        setShowConfirmation(true);
      } else {
        setShowModal(false);
      }
    },
    showModal,
  };
  return (
    <NodeViewWrapper
      style={{
        display: hasConfig ? "flex" : "inline-flex",
        alignItems: "center",
      }}
      data-value={props.node.attrs.value}
      title={props.node.attrs.label}
    >
      {!hasConfig && (
        <Popover
          open={!!anchorEl}
          anchorEl={anchorEl}
          onClose={() => {
            setAnchorEl(null);
          }}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          disableRestoreFocus
        >
          <Paper sx={{ maxWidth: 300, padding: "1rem" }} elevation={1}>
            <Typography>Font Size</Typography>
            <TextField
              type="number"
              value={props.node.attrs.fontSize || 11}
              onChange={(e) => {
                props.updateAttributes({ fontSize: e.target.value });
              }}
              sx={{ mb: 2 }}
            />
            <Typography>Bold?</Typography>
            <Checkbox
              checked={props.node.attrs.isBold || false}
              onChange={(e) => {
                props.updateAttributes({ isBold: e.target.checked });
              }}
            />
          </Paper>
        </Popover>
      )}
      <Dialog
        open={showConfirmation}
        maxWidth="xl"
        sx={{ " .MuiPaper-root": { flexWrap: "nowrap" } }}
      >
        <DialogTitle>Unsaved changes!</DialogTitle>
        <DialogContent>
          <Stack>
            <Typography>
              You have unsaved changes. Are you sure you want to close the
              modal?
            </Typography>
            <Stack direction="row" spacing={2}>
              <Button
                variant="outlined"
                onClick={() => {
                  setShowConfirmation(false);
                  setShowModal(false);
                  setDirty(false);
                }}
              >
                Close
              </Button>
              <Button
                variant="contained"
                onClick={() => {
                  setShowConfirmation(false);
                }}
              >
                Cancel
              </Button>
            </Stack>
          </Stack>
        </DialogContent>
      </Dialog>
      {hasConfig ? (
        <ConfigurableVariable>
          <Stack sx={{ width: "60%", mr: 2 }}>
            <Chip
              sx={{ backgroundColor: "#D4C9FF", fontWeight: "bold" }}
              label={props.node.attrs.label}
              size="small"
            />
            <Typography variant="caption">
              {props.node.attrs.description}
            </Typography>
          </Stack>
          <div>
            <Button
              variant="outlined"
              sx={{ width: 200 }}
              size="small"
              onClick={() => {
                setShowModal(true);
              }}
            >
              Edit
            </Button>
          </div>
        </ConfigurableVariable>
      ) : (
        <Chip
          sx={{ backgroundColor: "#AEE2FF", height: 16 }}
          label={props.node.attrs.label}
          size="small"
          onClick={(e) => setAnchorEl(e.currentTarget)}
        />
      )}
      {props.node.attrs.value === conceptualRemedialWorksList.value &&
        showModal && <ConceptualRemedialWorksList {...modalProps} />}
      {props.node.attrs.value === eqcConsiderationDetails.value &&
        showModal && <EQCConsiderationDetails {...modalProps} />}
      {props.node.attrs.value === imminentRiskDetails.value && showModal && (
        <ImminentRiskDetails {...modalProps} />
      )}
      {props.node.attrs.value === propertyDamageDetails.value && showModal && (
        <PropertyDamageDetails {...modalProps} />
      )}
      {props.node.attrs.value === constructionIssuesTable.value &&
        showModal && <ConstructionIssuesTable {...modalProps} />}
      {props.node.attrs.value === summaryTable.value && showModal && (
        <SummaryTable {...modalProps} />
      )}
      {props.node.attrs.value === retainingWallTable.value && showModal && (
        <RetainingWallTable {...modalProps} />
      )}
      {props.node.attrs.value === bridgeAndCulvertTable.value && showModal && (
        <BridgesAndCulvertsTable {...modalProps} />
      )}
    </NodeViewWrapper>
  );
};

const ConfigurableVariable = styled.div`
  padding: 1rem;
  border: 1px solid lightgrey;
  display: flex;
  border-radius: 4px;
  flex: 1;
  justify-content: space-between;
  align-items: center;
`;
