import {
  ClaimDetailsSection,
  DialogSection,
  DialogSectionBody,
  DialogSectionContentVertical,
  DialogSectionHeader,
} from "@athena/components";
import { Status } from "@athena/server/src/api/types/claimStatuses";
import { TRPCRootRouter } from "@athena/server/src/trpc";
import { OrgType } from "@athena/server/src/trpc/routers/user/types";
import Layer from "@deck.gl/core/typed/lib/layer";
import { GeoBoundingBox } from "@deck.gl/geo-layers/typed";
import { GeoJsonLayer } from "@deck.gl/layers/typed";
import { Box, Paper, Stack } from "@mui/material";
import { inferRouterOutputs } from "@trpc/server";
import turfBBox from "@turf/bbox";
import { useCallback, useRef, useState } from "react";
import { renderToString } from "react-dom/server";
import { MapRef } from "react-map-gl/dist/esm/exports-maplibre";
import { config } from "src/lib/config";
import { getClaimIconComponent } from "src/modules/spatialPortal/components/map/layers/ClaimGroupLayer";
import { GeoJsonCollectionName } from "src/modules/spatialPortal/mapLib/api/constants";
import { useGeoJsonCollectionQuery } from "src/modules/spatialPortal/mapLib/api/hooks/useGeoJsonCollectionQuery";
import { AthenaMap } from "src/modules/spatialPortal/mapLib/components/Map";
import { useBasemapSelector } from "src/modules/spatialPortal/mapLib/hooks/useBasemapSelector";
import { useMeasurementTool } from "src/modules/spatialPortal/mapLib/hooks/useMeasurementTool";
interface Props {
  claim: inferRouterOutputs<TRPCRootRouter>["claims"]["getClaim"];
}

enum BaseMap {
  Topographic = "topographic",
  Aerial = "aerial",
}

const baseMaps = {
  [BaseMap.Aerial]: {
    label: "Aerial",
    thumbnailUrl: "/images/mapping/basemap.svg",
    styleUrl: `/mapStyles/aerial.json`,
  },
  [BaseMap.Topographic]: {
    label: "Topographic",
    thumbnailUrl: "/images/mapping/basemap.svg",
    styleUrl: `${config.gisApiUrl}/geo/basemaps/collections/topographic/tiles/WebMercatorQuad/style/mvt/style.json`,
  },
};

const getIcon = (icon: JSX.Element) => ({
  url: `data:image/svg+xml;base64,${btoa(renderToString(icon))}`,
  width: 48,
  height: 48,
  anchorY: 48,
});

export function Location({ claim }: Props) {
  const mapRef = useRef<MapRef>(null);
  const [zoom, setZoom] = useState<number | undefined>(undefined);
  const [bounds, setBounds] = useState<GeoBoundingBox>();
  const onBoundsChange = useCallback((bounds: GeoBoundingBox) => {
    setBounds(bounds);
  }, []);

  const { isLoading: isLoadingSurveys, data: surveys } =
    useGeoJsonCollectionQuery({
      collectionName: GeoJsonCollectionName.DroneSurveyAreaV1,
      boundingBox: bounds,
    });

  const additionalBaseMaps = (surveys?.features || []).map((survey) => ({
    label: survey.properties.survey_name,
    thumbnailUrl: "/images/mapping/basemap.svg",
    url: `${config.gisApiUrl}/geo/ath/imagery/collections/${survey.properties.survey_id}/tiles/EPSG:3857/{z}/{x}/{y}.webp`,
    bounds: turfBBox(survey),
  }));

  const {
    control: basemapSelectorControl,
    styleUrl: baseMapStyleUrl,
    additionalBasemap: selectedAdditionalBasemap,
  } = useBasemapSelector({
    baseMaps,
    additionalMaps: {
      title: "Available UAV Surveys in View",
      maps: additionalBaseMaps,
    },
  });

  const { layer: measureLayer, control: measurementToolControl } =
    useMeasurementTool();

  const icons = getClaimIconComponent(
    claim.status || Status.Setup,
    claim.urgency,
    OrgType.Insurer
  );

  const renderedNormalIcon = getIcon(icons.normal);

  const pinLayer = new GeoJsonLayer({
    pointType: "icon",
    data: claim.location.geo,
    getIcon: () => renderedNormalIcon,
    getIconSize: 20,
    wrapLongitude: true,
    getTextSize: 1,
    textSizeMaxPixels: 15,
    textSizeUnits: "meters",
    textBackground: true,
    getTextAnchor: "middle",
    getTextAlignmentBaseline: "bottom",
    textSizeScale: 1,
    pickable: true,
  });

  const layers: Array<Layer> = [pinLayer];

  return (
    <Box
      sx={{
        flexGrow: 1,
        mb: 3,
      }}
    >
      <Paper
        elevation={4}
        sx={{
          padding: "1.5rem",
          mb: "1.5rem",
          scrollMarginTop: "5.5rem",
        }}
        id="location"
      >
        <ClaimDetailsSection title="Location details">
          <Stack
            direction={"column"}
            sx={{
              maxWidth: "1000px",
              mb: 2,
            }}
          >
            <Address claim={claim} />
            <DialogSection>
              <DialogSectionContentVertical>
                <DialogSectionHeader>Map</DialogSectionHeader>
                <DialogSectionBody>
                  <AthenaMap
                    active={false}
                    containerStyle={{
                      marginTop: "0.5rem",
                      borderRadius: "8px",
                      overflow: "hidden",
                      height: "500px",
                      position: "relative",
                    }}
                    initialState={{
                      longitude: claim.location.geo.coordinates[0],
                      latitude: claim.location.geo.coordinates[1],
                      zoom: 15,
                    }}
                    layers={layers}
                    onSearch={() => undefined}
                    searchables={[]}
                    controls={[
                      // featureSelectorTool,
                      measurementToolControl,
                      basemapSelectorControl,
                    ]}
                    baseMapStyleUrl={baseMapStyleUrl}
                    mapRef={mapRef}
                    layersIdsToIgnoreOnHover={["edit-layer"]}
                    // getCursor={(state) => {
                    //   if (measureLayer) {
                    //     return measureLayer.getCursor(state);
                    //   }
                    // }}
                    onChangeZoom={(zoom) => setZoom(zoom)}
                    onBoundsChange={onBoundsChange}
                    additionalBaseMaps={
                      (selectedAdditionalBasemap && [
                        selectedAdditionalBasemap,
                      ]) ||
                      []
                    }
                  />
                </DialogSectionBody>
              </DialogSectionContentVertical>
            </DialogSection>
          </Stack>
        </ClaimDetailsSection>
      </Paper>
    </Box>
  );
}

function Address({ claim }: Props) {
  if (claim?.location.manual?.address) {
    return <ManualAddress claim={claim} />;
  }

  if (claim?.location.parcelAddress) {
    return <ParcelAddress claim={claim} />;
  }

  if (claim?.location.address) {
    return <AutoAddress claim={claim} />;
  }
}

function ManualAddress({ claim }: Props) {
  return (
    <>
      <DialogSection>
        <DialogSectionContentVertical>
          <DialogSectionHeader>Address</DialogSectionHeader>
          <DialogSectionBody>
            {claim?.location.manual?.address}
          </DialogSectionBody>
        </DialogSectionContentVertical>
      </DialogSection>
      <DialogSection>
        <DialogSectionContentVertical>
          <DialogSectionHeader>Suburb</DialogSectionHeader>
          <DialogSectionBody>
            {claim?.location.manual?.suburb}
          </DialogSectionBody>
        </DialogSectionContentVertical>
      </DialogSection>
      <DialogSection>
        <DialogSectionContentVertical>
          <DialogSectionHeader>City</DialogSectionHeader>
          <DialogSectionBody>{claim?.location.manual?.city}</DialogSectionBody>
        </DialogSectionContentVertical>
      </DialogSection>
      <DialogSection>
        <DialogSectionContentVertical>
          <DialogSectionHeader>Region</DialogSectionHeader>
          <DialogSectionBody>
            {claim?.location.manual?.region}
          </DialogSectionBody>
        </DialogSectionContentVertical>
      </DialogSection>
      <DialogSection>
        <DialogSectionContentVertical>
          <DialogSectionHeader>Postal code</DialogSectionHeader>
          <DialogSectionBody>
            {claim?.location.manual?.postcode}
          </DialogSectionBody>
        </DialogSectionContentVertical>
      </DialogSection>
    </>
  );
}

function ParcelAddress({ claim }: Props) {
  return (
    <>
      <DialogSection>
        <DialogSectionContentVertical>
          <DialogSectionHeader>Address</DialogSectionHeader>
          <DialogSectionBody>{claim?.location.parcelAddress}</DialogSectionBody>
        </DialogSectionContentVertical>
      </DialogSection>
      <DialogSection>
        <DialogSectionContentVertical>
          <DialogSectionHeader>Region</DialogSectionHeader>
          <DialogSectionBody>{claim?.location.region}</DialogSectionBody>
        </DialogSectionContentVertical>
      </DialogSection>
    </>
  );
}

function AutoAddress({ claim }: Props) {
  return (
    <>
      <DialogSection>
        <DialogSectionContentVertical>
          <DialogSectionHeader>Address</DialogSectionHeader>
          <DialogSectionBody>{claim?.location.address}</DialogSectionBody>
        </DialogSectionContentVertical>
      </DialogSection>
      <DialogSection>
        <DialogSectionContentVertical>
          <DialogSectionHeader>Region</DialogSectionHeader>
          <DialogSectionBody>{claim?.location.region}</DialogSectionBody>
        </DialogSectionContentVertical>
      </DialogSection>
    </>
  );
}
