import Button from "@mui/material/Button";
import styled from "@emotion/styled";

import { useDropzone } from "react-dropzone";
import { FC } from "react";
import { Stack } from "@mui/system";
import {
  DialogSection,
  DialogSectionBody,
  DialogSectionContentVertical,
  DialogSectionHeader,
} from "../Dialog";

interface SelectFileProps {
  onFileSelected: (file: File) => void;
}

export const SelectFile: FC<SelectFileProps> = ({ onFileSelected }) => {
  const { getRootProps, getInputProps, isDragActive, fileRejections } =
    useDropzone({
      onDrop: (acceptedFiles) => {
        if (acceptedFiles.length !== 1) {
          return;
        }

        onFileSelected(acceptedFiles[0]);
      },
      accept: { CSV: [".csv"] },
      maxFiles: 1,
    });

  return (
    <DialogSection>
      <DialogSectionContentVertical>
        <DialogSectionHeader>
          Upload your policy data here. Accepted formats include .CSV
        </DialogSectionHeader>
        <DialogSectionBody>
          <DropZone {...getRootProps()}>
            <input {...getInputProps()} multiple={false} />
            <Stack alignItems={"center"} justifyContent={"center"}>
              <img src="/images/icons/cloud-file.svg" alt="file" />
              {isDragActive ? (
                <h4>Drop the files here ...</h4>
              ) : (
                <>
                  <h4>Drag &apos;n&apos; drop file here to upload</h4>
                  <p>or use button below</p>
                </>
              )}
              <Button
                variant="outlined"
                type="submit"
                sx={{
                  width: "240px",
                }}
                disabled
              >
                <span>Choose File</span>
              </Button>
              {fileRejections.map(({ file, errors }) => (
                <li key={file.name}>
                  {file.name}
                  <ul>
                    {errors.map((e) => (
                      <li key={e.code}>{e.message}</li>
                    ))}
                  </ul>
                </li>
              ))}
            </Stack>
          </DropZone>
        </DialogSectionBody>
      </DialogSectionContentVertical>
    </DialogSection>
  );
};

const DropZone = styled.div`
  height: 320px;
  border: 1px solid lightgray;
  border-radius: 1rem;
  display: flex;
  justify-content: center;
  margin-top: 1.5rem;
`;
