import {
  ReportTemplateStylesSchema,
  TReportTemplateStyles,
  defaultStyles,
  generateStyleSheet,
} from "@athena/server/src/api/types/reportTemplate";
import { zodResolver } from "@hookform/resolvers/zod";
import CloseIcon from "@mui/icons-material/Close";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tooltip,
} from "@mui/material";
import { useDebounceEffect } from "ahooks";
import { useCallback, useEffect, useState } from "react";
import { Path, useForm } from "react-hook-form";
import { MockReport } from "./Templating/MockReport";
import { StyleEditor } from "./Templating/StyleEditor";

export type TemplateStylesModalProps = {
  styles?: TReportTemplateStyles;
  onSave: (styling: TReportTemplateStyles) => void;
  onClose: () => void;
};
export const TemplateStylesModal = (props: TemplateStylesModalProps) => {
  const { onSave, onClose, styles } = props;
  const [showResetConfirmation, setShowResetConfirmation] = useState(false);
  const [showUnsavedChanges, setShowUnsavedChanges] = useState(false);
  const [currentStyles, setCurrentStyles] = useState<TReportTemplateStyles>(
    styles || defaultStyles
  );

  useEffect(() => {
    const styles = document.createElement("style");
    styles.innerHTML = generateStyleSheet(currentStyles, ".template-styles");
    document.head.appendChild(styles);
    return () => {
      document.head.removeChild(styles);
    };
  }, [currentStyles]);

  const onSubmit = useCallback((styles: TReportTemplateStyles) => {
    onSave(styles);
    onClose();
  }, []);

  const {
    control,
    handleSubmit,
    formState: { isDirty },
    reset,
    resetField,
    setValue,
    getValues,
    watch,
    ...rest
  } = useForm<TReportTemplateStyles>({
    resolver: zodResolver(ReportTemplateStylesSchema),
    defaultValues: {
      ...currentStyles,
    },
  });

  useDebounceEffect(
    () => {
      const subscription = watch((value) => {
        const parsed = ReportTemplateStylesSchema.safeParse(value);
        if (parsed.success) {
          setCurrentStyles(parsed.data);
        } else {
          console.log(parsed.error);
        }
      });
      return () => subscription.unsubscribe();
    },
    [watch],
    { wait: 500 }
  );

  const handleReset = (path: Path<TReportTemplateStyles>) => {
    resetField(path);
  };

  const handleOnClose = () => {
    if (isDirty) {
      setShowUnsavedChanges(true);
    } else {
      onClose();
    }
  };

  return (
    <>
      {showResetConfirmation && (
        <>
          <Dialog open>
            <DialogTitle>Reset to Default?</DialogTitle>
            <DialogContent>
              This will clear all your styles and revert back the default
              settings.{" "}
              <p style={{ fontSize: 12, fontStyle: "italic" }}>
                You can undo this action if you don&apos;t click save.
              </p>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  reset(defaultStyles);
                  setShowResetConfirmation(false);
                }}
                variant="contained"
                color="warning"
              >
                Reset
              </Button>
              <Button onClick={() => setShowResetConfirmation(false)}>
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
        </>
      )}
      {showUnsavedChanges && (
        <>
          <Dialog open>
            <DialogTitle>You have unsaved changes!</DialogTitle>
            <DialogContent>
              Your changes will be lost if you don&apos;t save them.
              <p> Are you sure you want to continue?</p>
            </DialogContent>
            <DialogActions>
              <Button onClick={onClose} variant="contained" color="warning">
                Continue
              </Button>
              <Button onClick={() => setShowUnsavedChanges(false)}>
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
        </>
      )}
      <Dialog
        open={true}
        onClose={handleOnClose}
        maxWidth={false}
        sx={{
          " .MuiPaper-root": { flexWrap: "nowrap" },
          width: "90vw",
          maxWidth: "1800px",
          margin: "auto",
        }}
      >
        <form
          onSubmit={handleSubmit(onSubmit)}
          style={{ position: "relative" }}
        >
          <DialogTitle
            sx={{ borderBottom: "1px solid lightgray", marginBottom: "1rem" }}
          >
            Edit Template Styles
          </DialogTitle>
          <DialogContent
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "1rem",
              padding: "0 1rem",
            }}
          >
            <MockReport />

            <StyleEditor
              onChange={setCurrentStyles}
              control={control}
              onReset={handleReset}
              setValue={setValue}
            />
          </DialogContent>
          <DialogActions
            sx={{
              display: "flex",
              flexDirection: "row-reverse",
              justifyContent: "space-between",
              gap: "1rem",
              padding: "0 1rem 1rem 1rem",
              borderTop: "1px solid lightgray",
            }}
          >
            <Button
              variant="contained"
              type="submit"
              sx={{ width: "300px", margin: "1rem 0 0 0 !important" }}
            >
              Save
            </Button>

            <Tooltip title="Warning! This will clear all your styles and revert back the default settings">
              <Button
                onClick={() => {
                  setShowResetConfirmation(true);
                }}
                variant="contained"
                color="error"
                sx={{ width: "300px", margin: "1rem 0 0 0 !important" }}
              >
                Reset all styles to default
              </Button>
            </Tooltip>
          </DialogActions>

          <CloseIcon
            sx={{
              position: "absolute",
              top: "1rem",
              right: "1rem",
              cursor: "pointer",
            }}
            onClick={handleOnClose}
          />
        </form>
      </Dialog>
    </>
  );
};
