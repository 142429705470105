import { Mark, mergeAttributes } from "@tiptap/core";
import uniqolor from "uniqolor";
export interface CommentOptions {
  HTMLAttributes: Record<string, any>;
  userName: string;
  userId: string;
  color: string;
}

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    comment: {
      /**
       * Set a comment mark
       */
      setComment: (comment: string) => ReturnType;
      resolveComment: (uuid: string) => ReturnType;
    };
  }
}

export const CommentExtension = Mark.create<CommentOptions>({
  name: "comment",
  priority: 10000,
  excludes: "",
  addAttributes() {
    return {
      comment: {
        default: null,
        parseHTML: (el) => (el as HTMLSpanElement).getAttribute("data-comment"),
        renderHTML: (attrs) => {
          return {
            "data-comment": attrs.comment,
            style: `border-color: ${
              uniqolor(JSON.parse(attrs.comment).userId, {
                format: "rgb",
                lightness: 40,
              }).color
            };`,
          };
        },
      },
      commentId: {
        default: null,
        parseHTML: (el) => (el as HTMLSpanElement).getAttribute("comment-id"),
        renderHTML: (attrs) => ({
          "comment-id": attrs.commentId,
        }),
      },
    };
  },
  parseHTML() {
    return [
      {
        tag: "span[data-comment]",
      },
    ];
  },
  renderHTML({ HTMLAttributes }) {
    return [
      "span",
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes),
      0,
    ];
  },
  // @ts-ignore Fix later
  addCommands() {
    return {
      setComment:
        (comment: string) =>
        ({ commands }) => {
          return commands.setMark("comment", {
            comment,
            active: true,
            commentId: JSON.parse(comment).uuid,
          });
        },
      resolveComment: (uuid: string) => (editor) => {
        // const { tr } = editor.state;
        // editor.state.doc.descendants((node, pos) => {
        //   if (!node.isText) {
        //     return;
        //   }
        //   node.marks.forEach((mark) => {
        //     if (mark.type.name !== "comment") {
        //       // assuming mark type is 'comment'
        //       return;
        //     }
        //     const commentData = JSON.parse(mark.attrs["comment"]);
        //     // update the marks attributes
        //     if (commentData.uuid === uuid) {
        //       const from = pos;
        //       const to = pos + node.nodeSize;
        //       editor.commands.setTextSelection({ from, to });
        //       // tr.removeMark(from, to, mark);
        //       // const newMark = editor.state.doc.type.schema.marks.comment.create({
        //       //   ...mark.attrs,
        //       //   active: false,
        //       // });
        //       // tr.addMark(from, to, newMark);
        //       editor.commands.updateAttributes("comment", {
        //         ...mark.attrs,
        //         active: false,
        //       });
        //     }
        //   });
        // });
        // return true;
      },
    };
  },
});
