import { FoundClaim } from "@athena/server/src/trpc/routers/claims";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import { Chip } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { GridApiCommunity } from "@mui/x-data-grid/internals";
import { format } from "date-fns";
import { Link } from "react-router-dom";
import { ReportDownloadButton } from "src/modules/intel/pages/EngineeringClaimDetails/components/ReportDownload";

export enum EngineeringClaimsTableColumn {
  Reference = "reference",
  InsurerReference = "insurerReference",
  LossAdjuster = "lossAdjuster",
  InsuranceCompany = "insuranceCompany",
  EngineeringCompany = "engineeringCompany",
  Address = "address",
  ServiceRequestedDate = "serviceRequestedDate",
  Updated = "updated",
  Created = "created",
  Status = "status",
}
enum AvailableReport {
  None,
  Draft,
  Final,
}

type FoundClaimWithOrg = FoundClaim & {
  engineeringCompany: string;
};

const columns: GridColDef<FoundClaimWithOrg>[] = [
  {
    field: EngineeringClaimsTableColumn.Reference,

    flex: 0.7,
    headerName: "Project Number",
    // valueGetter: (p) => p.row.reference || "TBC",
    renderCell: (p) => (
      <Link to={`/claim/${p.row.organisationId}/${p.row.claimId}`}>
        {p.row.reference || "TBC"}
      </Link>
    ),
  },
  {
    field: EngineeringClaimsTableColumn.InsurerReference,
    flex: 0.5,
    headerName: "Insurer Reference",
    valueGetter: (p) => p.row.insurerReference || "TBC",
  },
  {
    field: EngineeringClaimsTableColumn.InsuranceCompany,
    flex: 0.75,
    headerName: "Insurer",
    valueGetter: (p) => p.row.insurer?.name || "TBC",
  },
  {
    field: EngineeringClaimsTableColumn.LossAdjuster,
    flex: 0.75,
    headerName: "Loss Adjuster",
    valueGetter: (p) => p.row.lossAdjusterName || "TBC",
  },
  {
    field: EngineeringClaimsTableColumn.EngineeringCompany,
    flex: 0.75,
    headerName: "Engineering Company",
    valueGetter: (p) => p.row.engineeringCompany || "TBC",
  },
  {
    field: EngineeringClaimsTableColumn.Address,
    flex: 2,
    headerName: "Address",
    valueGetter: (p) => p.row.location.address,
  },
  {
    field: EngineeringClaimsTableColumn.Status,
    flex: 1,
    headerName: "Status",
    renderCell: (p) => <Chip label={p.row.status} />,
  },
  {
    field: EngineeringClaimsTableColumn.ServiceRequestedDate,
    flex: 0.5,
    headerName: "Service Requested Date",
    valueGetter: (p) =>
      p.row.serviceRequestDate
        ? format(p.row.serviceRequestDate, "dd/MM/yy")
        : "Not Set",
  },
  {
    field: EngineeringClaimsTableColumn.Updated,
    flex: 0.5,
    headerName: "Last Updated",
    renderCell: (p) => {
      return (
        <div style={{ paddingLeft: "0.5rem" }}>
          {format(new Date(p.row.created), "h:mma")}
          <br />
          {format(new Date(p.row.created), "dd/MM/yy")}
        </div>
      );
    },
  },
  {
    field: EngineeringClaimsTableColumn.Created,
    flex: 0.5,
    headerName: "Created",
    valueGetter: (p) => format(new Date(p.row.created), "dd/MM/yy"),
  },
  {
    field: "reportDownload",
    disableExport: true,
    width: 10,
    renderHeader: () => <CloudDownloadIcon fontSize={"medium"} />,
    renderCell: (p) => (
      <ReportDownloadButton
        claim={{
          claimId: p.row.claimId,
          status: p.row.status,
          organisationId: p.row.organisationId,
        }}
        variant="icon"
      />
    ),
  },
];

interface EngineeringClaimsTableProps {
  claims: FoundClaimWithOrg[];
  tableApiRef?: React.MutableRefObject<GridApiCommunity>;
  pageSize?: number;
}

export function EngineeringClaimsTable({
  claims,
  tableApiRef,
  pageSize = 5,
}: EngineeringClaimsTableProps) {
  return (
    <DataGrid
      apiRef={tableApiRef}
      disableColumnMenu={true}
      rows={claims}
      columns={columns}
      initialState={{
        pagination: {
          paginationModel: {
            pageSize,
          },
        },
      }}
      getRowId={(row) => row.claimId}
      rowHeight={52}
      autoHeight={true}
      getRowHeight={() => "auto"}
      disableRowSelectionOnClick
      sx={{
        ".MuiDataGrid-columnHeader": {
          borderRight: "1px solid #efefef",
          borderRadius: 0,
          paddingLeft: "1rem",
          fontWeight: "500",
        },
        ".MuiDataGrid-columnHeaders": {
          borderRadius: 0,
        },
        ".MuiDataGrid-columnHeadersInner": {
          backgroundColor: "white",
        },
        ".MuiDataGrid-row:nth-child(odd)": {
          backgroundColor: "white",
        },
        ".MuiDataGrid-row:nth-child(even)": {
          backgroundColor: "#fcfcfc",
        },
        ".MuiDataGrid-footerContainer": {
          backgroundColor: "white",
        },
        ".MuiDataGrid-cellContent": {
          padding: "1.5rem 0.5rem",
        },
        ".MuiDataGrid-cell": {
          borderRight: "1px solid #efefef",
        },
      }}
    />
  );
}
