import { LoadingSpinner } from "@athena/components";
import { Capacitor } from "@capacitor/core";
import { useOrganizationList, useSignIn, useSignUp } from "@clerk/clerk-react";
import {
  Button,
  Dialog,
  DialogContent,
  TextField,
  Typography,
} from "@mui/material";
import { Box, Stack } from "@mui/system";
import { useEffect, useState } from "react";
import ReactCodeInput from "react-verification-code-input";
import { config } from "src/lib/config";
import styled from "styled-components";

export const SignIn = () => {
  const [verifyingMagicCode, setVerifyingMagicCode] = useState(false);
  const { signIn } = useSignIn();
  const { signUp } = useSignUp();
  const [showSignUp, setShowSignUp] = useState(false);
  const [ssoTransfer, setSsoTransfer] = useState(false);

  useEffect(() => {
    (async () => {
      if (!signIn) return;
    })();
  }, [signIn]);

  useEffect(() => {
    if (signIn?.firstFactorVerification.status === "transferable") {
      setSsoTransfer(true);
      setVerifyingMagicCode(true);
      setShowSignUp(true);
    }
  }, [signIn?.firstFactorVerification.status]);

  if (showSignUp)
    return (
      <Dialog
        open
        sx={{
          ".MuiPaper-root": { margin: "1rem !important" },
        }}
      >
        <DialogContent
          sx={{
            maxWidth: "600px",
            margin: "0",
            padding: "2rem",
            border: "2px solid lightgray",
          }}
        >
          <img
            src="/images/athena.svg"
            style={{
              width: "100%",
              height: "4rem",
              objectFit: "contain",
              margin: "1.5rem auto",
              display: "block",
            }}
          />
          <Typography
            variant="h3"
            component="h1"
            sx={{ margin: "1.5rem", textAlign: "center" }}
          >
            Create an Athena account
          </Typography>

          <div
            style={{
              display: "flex",
              gap: "1rem",
              border: "1px solid lightgray",
              borderRadius: "12px",
              padding: "1rem",
            }}
          >
            <img src="/images/icons/info.svg" style={{ maxWidth: "1.5rem" }} />
            <p>
              You will need an approved email address to create an Athena
              account. Don&apos;t have an invitation?{" "}
              <a href="https://forms.gle/pRmj3LPtkYtuWsiB6">Contact us</a>
            </p>
          </div>
          <Stack>
            {!verifyingMagicCode && (
              <>
                <Button
                  onClick={async () => {
                    await signUp?.authenticateWithRedirect({
                      redirectUrl:
                        Capacitor.getPlatform() === "web"
                          ? config.clientUrl
                          : "daa://sso-callback",
                      redirectUrlComplete:
                        Capacitor.getPlatform() === "web"
                          ? config.clientUrl
                          : "daa://sso-callback",
                      strategy: "oauth_microsoft",
                    });
                  }}
                  variant="outlined"
                  sx={{ mb: 2, mt: 2 }}
                >
                  <img
                    src="/images/icons/microsoft.ico"
                    style={{ width: 16, height: 16, marginRight: 6 }}
                  />
                  Continue with Microsoft
                </Button>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    mb: 2,
                  }}
                >
                  <Box sx={{ borderBottom: "1px solid lightgray", flex: 1 }} />
                  <span style={{ marginLeft: 12, marginRight: 12 }}>or</span>
                  <Box sx={{ borderBottom: "1px solid lightgray", flex: 1 }} />
                </Box>
              </>
            )}
            <SignUpMagicLink
              verifying={verifyingMagicCode}
              transfer={ssoTransfer}
              onVerifyingChange={(val) => {
                setVerifyingMagicCode(val);
              }}
            />
            {!verifyingMagicCode && (
              <Box
                sx={{
                  borderTop: "1px solid lightgray",
                  mt: "2rem",
                  pt: "1.5rem",
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <p style={{ color: "#333" }}>Already have an account?</p>
                <Button
                  sx={{ mt: "1rem" }}
                  variant="outlined"
                  fullWidth
                  onClick={() => setShowSignUp(false)}
                >
                  Sign in
                </Button>
              </Box>
            )}
          </Stack>
        </DialogContent>
      </Dialog>
    );
  // Return a div centered in page 50%, with two buttons, one for azure login and one for email
  return (
    <Dialog
      open
      sx={{
        margin: "1.5rem",
        ".MuiPaper-root": {
          margin: "0 auto !important",
          width: "100%",
          display: "block",
          maxWidth: "480px",
        },
      }}
    >
      <DialogContent
        sx={{
          margin: "0",
          padding: "2rem",
          border: "2px solid lightgray",
        }}
      >
        <img
          src="/images/athena.svg"
          style={{
            height: "4rem",
            objectFit: "contain",
            display: "block",
            width: "100%",
            objectPosition: "center",
          }}
          alt="athena logo"
        />
        <Typography
          variant="h3"
          component="h1"
          sx={{ margin: "1.5rem 0", textAlign: "center" }}
        >
          Athena Login
        </Typography>
        <Stack>
          {!verifyingMagicCode && (
            <>
              <Button
                onClick={async () => {
                  await signIn?.authenticateWithRedirect({
                    redirectUrl:
                      Capacitor.getPlatform() === "web"
                        ? config.clientUrl
                        : "daa://sso-callback",
                    redirectUrlComplete:
                      Capacitor.getPlatform() === "web"
                        ? config.clientUrl
                        : "daa://sso-callback",
                    strategy: "oauth_microsoft",
                  });
                }}
                variant="outlined"
                sx={{ mb: 2, mt: 0 }}
              >
                <img
                  src="/images/icons/microsoft.ico"
                  style={{ width: 16, height: 16, marginRight: 6 }}
                  alt="microsoft logo"
                />
                Login with Microsoft
              </Button>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  mb: 2,
                }}
              >
                <Box sx={{ borderBottom: "1px solid lightgray", flex: 1 }} />
                <span style={{ marginLeft: 12, marginRight: 12 }}>or</span>
                <Box sx={{ borderBottom: "1px solid lightgray", flex: 1 }} />
              </Box>
            </>
          )}
          <SignInMagicLink
            verifying={verifyingMagicCode}
            onVerifyingChange={(val) => {
              setVerifyingMagicCode(val);
            }}
          />
          {!verifyingMagicCode && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                mt: "1.5rem",
                pt: "1.5rem",
                borderTop: "1px solid lightgray",
              }}
            >
              <span style={{ marginBottom: "0.5rem" }}>
                Don&apos;t have an account?
              </span>
              <Button
                fullWidth
                variant="outlined"
                onClick={() => setShowSignUp(true)}
              >
                Register
              </Button>
            </Box>
          )}
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

type SignInMagicLinkProps = {
  verifying: boolean;
  onVerifyingChange: (val: boolean) => void;
  transfer?: boolean;
};

function SignInMagicLink({
  verifying,
  onVerifyingChange,
}: SignInMagicLinkProps) {
  const { signIn, setActive } = useSignIn();
  const { userMemberships } = useOrganizationList({ userMemberships: true });
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState<string>();
  const [validating, setValidating] = useState(false);
  const [validatingError, setValidatingError] = useState<string>();
  const [loading, setLoading] = useState(false);
  async function onClick(e: any) {
    e.preventDefault();
    if (!email) {
      setEmailError("Required");
      return;
    }
    if (!signIn) return;
    setLoading(true);

    // Kick off the sign-in process, passing the user's
    // authentication identifier. In this case it's their
    // phone number.
    try {
      const { supportedFirstFactors } = await signIn
        .create({
          identifier: email,
        })
        .catch((e) => {
          setEmailError("Account doesn't exist");
          throw e;
        });

      // Find the phoneNumberId from all the available first factors for the current sign in
      const firstPhoneFactor = supportedFirstFactors.find((factor) => {
        return factor.strategy === "email_code";
      });

      const { emailAddressId } = firstPhoneFactor as { emailAddressId: string };
      // Prepare first factor verification, specifying
      // the phone code strategy.
      await signIn.prepareFirstFactor({
        strategy: "email_code",
        emailAddressId,
      });
      onVerifyingChange(true);
    } catch (e) {
      console.log(e);
    }
    setLoading(false);
  }

  const verify = async (val: string) => {
    if (!signIn) return;
    setValidating(true);
    setValidatingError(undefined);

    // Attempt to verify the user providing the
    // one-time code they received.
    try {
      await signIn.attemptFirstFactor({
        strategy: "email_code",
        code: val,
      });
      console.log(userMemberships);
      await setActive({
        session: signIn.createdSessionId,
        // organization: user?.organizationMemberships[0].organization.id,
      });
    } catch (e) {
      console.log(e);
      setValidatingError("Invalid code");
    }

    setValidating(false);
  };

  if (verifying) {
    return (
      <Box
        sx={{
          mt: 2,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography variant="subtitle1">
          Check your email and enter the code below
        </Typography>
        <ReactCodeInput
          className="code-input"
          onChange={(val) => {
            if (val.length === 6) {
              verify(val);
            }
          }}
          loading={validating}
        />

        {validatingError && (
          <Typography color="darkred" sx={{ mt: "1rem" }}>
            Invalid code. Please try again
          </Typography>
        )}
        <Button
          variant="outlined"
          sx={{ mt: 2 }}
          onClick={() => {
            onVerifyingChange(false);
          }}
        >
          Login with another method
        </Button>
      </Box>
    );
  }

  return (
    <form onSubmit={onClick}>
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <TextField
          value={email}
          onChange={(e) => {
            if (e.target.value !== "") setEmailError(undefined);
            setEmail(e.target.value);
          }}
          label="Email address"
          sx={{ mb: 1 }}
          error={!!emailError}
          helperText={emailError ? emailError : ""}
        />
        <Button variant="contained" type="submit">
          Continue {loading && <LoadingSpinner size={60} />}
        </Button>
      </Box>
    </form>
  );
}

function SignUpMagicLink({
  verifying,
  onVerifyingChange,
  transfer,
}: SignInMagicLinkProps) {
  const { signUp, setActive, isLoaded } = useSignUp();
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [firstNameError, setFirstNameError] = useState<string>();
  const [lastName, setLastName] = useState("");
  const [lastNameError, setLastNameError] = useState<string>();
  const [emailError, setEmailError] = useState<string>();
  const [validating, setValidating] = useState(false);
  const [validatingError, setValidatingError] = useState<string>();
  const [loading, setLoading] = useState(false);

  // Check if user is being transfered from OAuth_Microsoft
  // and proceed with verifying user's email address
  useEffect(() => {
    (async () => {
      if (transfer) {
        await signUp?.create({ transfer });
        await signUp?.prepareEmailAddressVerification();
        onVerifyingChange(true);
      }
    })();
  }, [isLoaded]);

  async function onClick(e: any) {
    e.preventDefault();
    if (!email) {
      setEmailError("Required");
    }
    if (!firstName) {
      setFirstNameError("Required");
    }
    if (!lastName) {
      setLastNameError("Required");
    }
    if (!email || !firstName || !lastName) return;

    if (!signUp) return;
    setLoading(true);

    // Kick off the sign-in process, passing the user's
    // authentication identifier. In this case it's their
    // phone number.
    try {
      await signUp
        .create({
          firstName,
          lastName,
          emailAddress: email,
        })
        .catch((e) => {
          setEmailError("Email already exists");
          throw e;
        });
      // Prepare first factor verification, specifying
      // the phone code strategy.
      await signUp.prepareEmailAddressVerification();
      onVerifyingChange(true);
    } catch (e) {
      console.log(e);
    }
    setLoading(false);
  }

  const verify = async (val: string) => {
    if (!signUp) return;
    setValidating(true);
    setValidatingError(undefined);

    // Attempt to verify the user providing the
    // one-time code they received.
    try {
      await signUp.attemptEmailAddressVerification({
        code: val,
      });
      await setActive({ session: signUp.createdSessionId });
    } catch (e) {
      console.log(e);
      setValidatingError("Invalid code");
    }

    setValidating(false);
  };

  if (verifying) {
    return (
      <Box
        sx={{
          mt: 2,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          textAlign: "center",
        }}
      >
        <Typography variant="subtitle1">
          Check your email and enter the code below
        </Typography>
        {validatingError && <Typography color="red">Invalid code</Typography>}
        <ReactCodeInput
          className="code-input"
          onChange={(val) => {
            if (val.length === 6) {
              verify(val);
            }
          }}
          loading={validating}
        />
        <Button
          variant="outlined"
          sx={{ mt: 2 }}
          onClick={() => {
            onVerifyingChange(false);
          }}
        >
          Login with another method
        </Button>
      </Box>
    );
  }

  return (
    <form onSubmit={onClick}>
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <NameWrapper>
          <TextField
            value={firstName}
            sx={{ flex: 1 }}
            onChange={(e) => {
              if (e.target.value !== "") setFirstNameError(undefined);
              setFirstName(e.target.value);
            }}
            label="First Name"
            error={!!firstNameError}
            helperText={firstNameError ? firstNameError : ""}
          />{" "}
          <TextField
            value={lastName}
            sx={{ flex: 1 }}
            onChange={(e) => {
              if (e.target.value !== "") setLastNameError(undefined);
              setLastName(e.target.value);
            }}
            label="Last Name"
            error={!!lastNameError}
            helperText={lastNameError ? lastNameError : ""}
          />
        </NameWrapper>
        <TextField
          value={email}
          onChange={(e) => {
            if (e.target.value !== "") setEmailError(undefined);
            setEmail(e.target.value);
          }}
          label="Email address"
          sx={{ mb: 1 }}
          error={!!emailError}
          helperText={emailError ? emailError : ""}
        />
        <Button variant="contained" type="submit">
          Continue {loading && <LoadingSpinner size={60} />}
        </Button>
      </Box>
    </form>
  );
}

const NameWrapper = styled.div`
  display: flex;
  gap: 0.5rem;
  margin-bottom: 0.5rem;
  flex-direction: row;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;
