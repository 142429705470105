export enum IsoCountryAlpha2 {
  NewZealand = "NZ",
}

export interface SearchLocation {
  (search: string): Promise<SearchLocationResponse>
}

export interface SearchLocationResponse {
  geo: GeoJSON.Point;
  address: string;
  region: string;
}
