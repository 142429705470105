import { Browser } from "@capacitor/browser";
import { Capacitor, CapacitorCookies } from "@capacitor/core";
import { axiosClient } from "src/lib/axiosClient";
import { config } from "src/lib/config";
import * as Sentry from "@sentry/react";
import Clerk from "@clerk/clerk-js";
import {
  FapiRequestInit,
  FapiResponse,
} from "@clerk/clerk-js/dist/types/core/fapiClient";

export const clerkPubKey = import.meta.env.VITE_CLERK_PUBLISHABLE_KEY;

export function doAppLogout() {
  Sentry.setUser(null);
}

export let clerk: Clerk | undefined = undefined;

export const clearClerk = () => {
  clerk = undefined;
};

export const initClerk = () => {
  clerk = new Clerk(clerkPubKey);

  // For capacitor, we use a bearer auth instead of cookies with Clerk
  clerk.__unstable__onBeforeRequest(async (requestInit: FapiRequestInit) => {
    if (Capacitor.getPlatform() !== "web") {
      requestInit.credentials = "omit";

      requestInit.url?.searchParams.append("_is_native", "1");

      const jwt = localStorage.getItem("jwt");
      const headers = new Headers(requestInit.headers);
      headers.set("authorization", jwt || "");
      requestInit.headers = headers;
    }
  });

  clerk.__unstable__onAfterResponse(
    async (_: FapiRequestInit, response?: FapiResponse<any>) => {
      if (Capacitor.getPlatform() !== "web") {
        const authHeader = response?.headers.get("authorization");

        if (authHeader) {
          localStorage.setItem("jwt", authHeader);
        }
      }
    }
  );
};

initClerk();
