import { useEffect, useState } from "react";
import { getAccessTokenFromCookie } from "./dataUtils/getAccessToken";
import { useNetworkContext } from "./hooks/useNetworkContext";
import { axiosClient } from "src/lib/axiosClient";
import { CapacitorHttp } from "@capacitor/core";
import { config } from "src/lib/config";
import { dataURLToBlob } from "./dataUtils/base64ToBlob";

/**
 * Capacitor doesn't set the cookie on <img src="..."/> requests
 * So we have to load it and set as b64 instead.
 */
export const AuthenticatedImage = ({
  src,
  ...rest
}: {
  src: string;
} & React.ImgHTMLAttributes<HTMLImageElement>) => {
  const { online } = useNetworkContext();
  const [image, setImage] = useState<string>();

  const authenticatedSrc = src + "?accessToken=" + getAccessTokenFromCookie();
  useEffect(() => {
    const fetchImage = async () => {
      const response = await CapacitorHttp.get({
        url: src,
        webFetchExtra: { credentials: "include" },
        responseType: "blob",
      });
      const blob = dataURLToBlob("data:image/png;base64," + response.data);
      if (!blob) return;
      setImage(URL.createObjectURL(blob));
    };
    fetchImage();
  }, [authenticatedSrc]);
  if (!online)
    return (
      <div {...rest}>
        This images was uploaded online and isn&apos;t available offline
      </div>
    );
  return <img {...rest} src={image} />;
};
