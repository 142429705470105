export type PointIconProps = {
  color: string;
};

export const PointIcon = (props: PointIconProps) => {
  const { color } = props;
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="4" y="4" width="16" height="16" rx="8" fill={color} />
    </svg>
  );
};
