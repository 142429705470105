import { Autocomplete, TextField } from "@mui/material";
import { axiosClient } from "src/lib/axiosClient";
import { useQuery } from "react-query";
import { User } from "src/lib/types";
import { useMemo } from "react";

interface UserPickerMultiProps {
  placeholder?: string;
  label?: string;
  id: string;
  value?: { id: string; name: string }[];
  onChange: (users: { id: string; name: string }[]) => void;
  role?: string;
  error?: boolean;
  helperText?: string;
}

export function UserPickerMulti(props: UserPickerMultiProps) {
  const { placeholder, label, value, onChange, role, error, helperText } =
    props;
  const { data: users } = useQuery(
    `users-role-${role}`,
    async (): Promise<User[]> => {
      return (await axiosClient.get(`/users${role ? `?role=${role}` : ""}`))
        .data;
    }
  );
  const options = useMemo(
    () =>
      (users || [])?.map((u) => ({
        name: u.firstName + " " + u.lastName,
        id: u.userId,
      })),
    [users]
  );

  return (
    <Autocomplete
      size="small"
      filterSelectedOptions
      options={options}
      multiple
      value={value}
      forcePopupIcon={false}
      placeholder={placeholder}
      getOptionLabel={(opt) => {
        return opt?.name || "";
      }}
      onChange={(_, users) => {
        return onChange(users);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={value?.length ? "" : placeholder}
          label={label}
          InputProps={{
            ...params.InputProps,
            notched: false,
          }}
          error={error}
          helperText={helperText}
        />
      )}
      renderOption={(props, option) => {
        return (
          <li {...props} key={option.id}>
            {option.name}
          </li>
        );
      }}
    />
  );
}
