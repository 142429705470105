import VectorTileSource from "ol/source/VectorTile";
import { UpdateClaim } from "@athena/server/src/api/types/claimAssessment";
import { useAsyncEffect, useNetwork } from "ahooks";
import BaseLayer from "ol/layer/Base";
import { useEffect, useMemo, useState } from "react";
import { useQuery } from "react-query";
import { axiosClient } from "src/lib/axiosClient";
import { store } from "src/lib/storage";
import { getGISUrl } from "../api/api";
import VectorSource from "ol/source/Vector";
import GeoJSON from "ol/format/GeoJSON";
import { geoJson } from "src/modules/olr/utils/olToTurf";
import { useClaimId } from "../../hooks/useClaimId";
import { Collection, Feature } from "ol";
import { useNetworkContext } from "src/shared/hooks/useNetworkContext";

export const useOfflineLayer = (layerUrl: string, disableLoader = false) => {
  const { online } = useNetworkContext();

  const claimId = useClaimId();

  const onlineSource = useMemo(() => {
    const source = new VectorSource({
      url: layerUrl,
      format: new GeoJSON({}),
      loader: disableLoader
        ? undefined
        : function (extent, resolution, projection, success, failure) {
            store
              .get(`claim-${claimId}-unsynced-changes`)
              .then((unsyncedJson) => {
                const unsynced = JSON.parse(unsyncedJson);
                if (unsynced || !online) {
                  store.get(`layer-${layerUrl}-${claimId}`).then((layer) => {
                    if (!layer) success?.([]);
                    source.addFeatures(
                      (JSON.parse(layer) || []).map((feature: Feature) =>
                        geoJson.readFeature(feature)
                      )
                    );
                    success?.(
                      (JSON.parse(layer) || []).map((feature: Feature) =>
                        geoJson.readFeature(feature)
                      )
                    );
                  });
                } else {
                  axiosClient.get(layerUrl).then(async (data) => {
                    source.addFeatures(geoJson.readFeatures(data.data));
                    success?.(geoJson.readFeatures(data.data));
                    await store.set(
                      `layer-${layerUrl}-${claimId}`,
                      JSON.stringify(data.data.features)
                    );
                  });
                }
              });
          },
    });
    return source;
  }, [layerUrl, claimId, online]);

  return onlineSource;
};

export const offlineLayer = (
  layerUrl: string,
  online: boolean,
  claimId: string
) => {
  const sauce = new VectorSource({
    url: layerUrl,
    format: new GeoJSON({}),
    loader: function (extent, resolution, projection, success, failure) {
      if (online) {
        axiosClient.get(layerUrl).then(async (data) => {
          sauce.addFeatures(geoJson.readFeatures(data.data));
          success?.(geoJson.readFeatures(data.data));
          await store.set(
            `layer-${layerUrl}-${claimId}`,
            JSON.stringify(data.data.features)
          );
        });
      } else {
        store.get(`layer-${layerUrl}-${claimId}`).then((layer) => {
          if (!layer) success?.([]);

          sauce.addFeatures(
            (JSON.parse(layer) || []).map((feature: Feature) =>
              geoJson.readFeature(feature)
            )
          );
        });
      }
    },
  });

  return sauce;
};
