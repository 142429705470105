import turfArea from "@turf/area";
import turfCentroid from "@turf/centroid";
import turfLength from "@turf/length";
import turfAlong from "@turf/along";
import { measureFrom } from "measure-ts";
import formatcoords from "formatcoords";
import { lineString } from "@turf/helpers";

import {
  ModeProps,
  Tooltip,
  FeatureCollection,
  GeoJsonEditMode,
} from "@nebula.gl/edit-modes";
import { DrawingType } from "../../../components/DrawToolbar";

export interface TooltipWithType extends Tooltip {
  type: DrawingType
}

export function getTooltips(this: GeoJsonEditMode, props: ModeProps<FeatureCollection>): TooltipWithType[] {
  const tooltips: Array<TooltipWithType> = [];
  const tentativeGuide = this.getTentativeGuide(props);

  for (const feature of [...props.data.features, tentativeGuide]) {
    if (!feature) {
      continue;
    }

    switch (feature.geometry.type) {
      case "Polygon": {
        const centroid = turfCentroid(feature.geometry);
        const area = turfArea(feature.geometry);
        const text = String(measureFrom("m2", "km2")(area));
  
        tooltips.push({
          // @ts-expect-error turf types diff
          position: centroid.geometry.coordinates,
          text,
          type: DrawingType.Polygon,
        })
        break;
      }
  
      case "LineString": {
        const length = turfLength(lineString(feature.geometry.coordinates), {
          units: "meters",
        });
  
        const centerDistance = length / 2;
  
        const position = turfAlong(feature.geometry, centerDistance, { units: "meters" });
        const text = String(measureFrom("m", "km")(length));
  
        tooltips.push({
          // @ts-expect-error turf types diff
          position: position.geometry.coordinates,
          text,
          type: DrawingType.Line,
        })
        break;
      }
  
      case "Point": {
        const text = formatcoords(
          feature.geometry.coordinates[1],
          feature.geometry.coordinates[0]
        ).format({ decimalPlaces: 2 });
  
        tooltips.push({
          position: feature.geometry.coordinates,
          text,
          type: DrawingType.Point,
        })
        break;
      }
    }
  }

  return tooltips;
}