import { GeoJsonCollectionName } from "./constants";
import { InferFeaturePropertiesType } from "./types";

interface QueryEquals<TValue> {
  equals: TValue,
}

interface QueryGreaterThan<TValue> {
  greaterThan: TValue,
}

interface QueryGreaterThanOrEquals<TValue> {
  greaterThanOrEquals: TValue,
}

interface QueryLessThan<TValue> {
  lessThan: TValue,
}

interface QueryLessThanOrEquals<TValue> {
  lessThanOrEquals: TValue,
}

interface QueryIn<TValue> {
  in: Array<TValue>,
}

interface QueryIsNull<TValue> {
  isNull: TValue extends null ? boolean : never,
}


type Query<TValue> =
  | QueryEquals<TValue>
  | QueryGreaterThan<TValue>
  | QueryGreaterThanOrEquals<TValue>
  | QueryLessThan<TValue>
  | QueryLessThanOrEquals<TValue>
  | QueryIn<TValue>
  | QueryIsNull<TValue>

type QueryNode<TCollection extends GeoJsonCollectionName, TProps = InferFeaturePropertiesType<TCollection>> = Partial<{
  [k in keyof TProps]: Query<TProps[k]>
}>

interface QueryLogicalAnd<TCollection extends GeoJsonCollectionName> {
  and: Array<GeoJsonCollectionFilter<TCollection> | undefined>,
}

interface QueryLogicalOr<TCollection extends GeoJsonCollectionName> {
  or: Array<GeoJsonCollectionFilter<TCollection> | undefined>,
}

interface QueryLogicalNot<TCollection extends GeoJsonCollectionName> {
  not: GeoJsonCollectionFilter<TCollection>,
}

type QueryLogical<TCollection extends GeoJsonCollectionName> =
  | QueryLogicalAnd<TCollection>
  | QueryLogicalOr<TCollection>
  | QueryLogicalNot<TCollection>

export type GeoJsonCollectionFilter<TCollection extends GeoJsonCollectionName> = QueryLogical<TCollection> | QueryNode<TCollection>

const formatValue = (value: unknown) => {
  if (typeof value === "string") {
    return `"${value}"`
  } else {
    return `${value}`;
  }
}

export const buildFilter = <TCollection extends GeoJsonCollectionName>(filters: GeoJsonCollectionFilter<TCollection>): string => {
  if ("and" in filters) {
    return filters.and.filter((x): x is Exclude<typeof x, undefined> => !!x).map(buildFilter).map(x => `(${x})`).join(" AND ");
  }

  if ("or" in filters) {
    return filters.or.filter((x): x is Exclude<typeof x, undefined> => !!x).map(buildFilter).map(x => `(${x})`).join(" OR ");
  }

  if ("not" in filters) {
    return `NOT (${buildFilter(filters.not)})`;
  }

  return Object.keys(filters).reduce((sum, key) => {
    const propertyName = key as keyof typeof filters;
    const query = filters[propertyName]!;
    let queryString = "";
    if ("equals" in query) {
      queryString = `${String(propertyName)} = ${formatValue(query.equals)}`;
    } else if ("greaterThan" in query) {
      queryString = `${String(propertyName)} > ${formatValue(query.greaterThan)}`;
    } else if ("greaterThanOrEquals" in query) {
      queryString = `${String(propertyName)} >= ${formatValue(query.greaterThanOrEquals)}`;
    } else if ("lessThan" in query) {
      queryString = `${String(propertyName)} < ${formatValue(query.lessThan)}`;
    } else if ("lessThanOrEquals" in query) {
      queryString = `${String(propertyName)} <= ${formatValue(query.lessThanOrEquals)}`;
    } else if ("in" in query) {
      queryString = `${String(propertyName)} IN (${query.in.map(formatValue).join(',')})`;
    }  else if ("isNull" in query) {
      queryString = `${String(propertyName)} IS ${query.isNull ? "NULL" : "NOT NULL"}`;
    }

    if (sum.length > 0) {
      return sum + ` AND ${queryString}`;
    } else {
      return queryString;
    }
  }, "");
}
