import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { Chip } from "@mui/material";
import { CreateInsurancePolicy } from "@athena/server/src/trpc/routers/insurancePolicies/schema";

export enum PreviewImportColumn {
  PolicyReference = "policyReference",
  Address = "address",
  Updated = "updated",
  Created = "created",
  Status = "status",
  // LossAdjuster = "lossAdjuster",
}

const columns: GridColDef<CreateInsurancePolicy>[] = [
  {
    field: PreviewImportColumn.PolicyReference,
    flex: 1,
    headerName: "Policy Reference",
    valueGetter: (p) => p.row.policyReference || "Not Mapped",
  },
  {
    field: PreviewImportColumn.Address,
    flex: 1,
    headerName: "Address",
    valueGetter: (p) => p.row.address || "Not Mapped",
  },
  {
    field: PreviewImportColumn.Status,
    flex: 1,
    headerName: "Status",
    renderCell: (p) => {
      return (
        (p.row.status && (
          <Chip sx={{ margin: "0.5rem" }} label={p.row.status} />
        )) ||
        "Not Mapped"
      );
    },
  },
  {
    field: "additionalData",
    flex: 2,
    headerName: "Additional Data",
    renderCell: (p) => {
      return (
        <>
          <Chip
            sx={{ margin: "0.5rem" }}
            title={Object.entries(p.row.additionalData).reduce(
              (sum, [rowKey, rowValue]) => `${sum}${rowKey}: ${rowValue}\r\n`,
              ""
            )}
            label={`${Object.keys(p.row.additionalData).length} unmapped`}
          />
          {p.row.latitude !== undefined && (
            <Chip
              sx={{ margin: "0.5rem" }}
              title={`LAT: ${p.row.latitude}`}
              label={"Latitude"}
            />
          )}
          {p.row.longitude !== undefined && (
            <Chip
              sx={{ margin: "0.5rem" }}
              title={`LNG: ${p.row.longitude}`}
              label={"Longitude"}
            />
          )}
        </>
      );
    },
  },
];

interface PreviewImportTableProps {
  rows: Array<Partial<CreateInsurancePolicy>>;
}

const useCounter = () => {
  let counter = 0;
  return () => counter++;
};

export function PreviewImportTable({ rows }: PreviewImportTableProps) {
  const nextId = useCounter();

  return (
    <DataGrid
      sx={{
        marginTop: "0.5rem",
        marginBottom: "0.5rem",
      }}
      rows={rows as Array<CreateInsurancePolicy>}
      columns={columns}
      initialState={{
        pagination: {
          paginationModel: {
            pageSize: 10,
          },
        },
      }}
      getRowId={() => nextId()}
      getRowHeight={() => "auto"}
      autoHeight={true}
      disableRowSelectionOnClick
    />
  );
}
