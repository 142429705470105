import * as Sentry from "@sentry/react";
import { Feature } from "ol";
import { FeatureUrlFunction } from "ol/featureloader";
import { Geometry } from "ol/geom";
import { axiosClient } from "src/lib/axiosClient";
import { config } from "src/lib/config";
import { store } from "src/lib/storage";
import { geoJson } from "src/modules/olr/utils/olToTurf";
import { useNetworkContext } from "src/shared/hooks/useNetworkContext";
import { enqueueErrorSnackbar } from "src/shared/snackbar/SnackbarHelper";
import { useClaimStore } from "../../useOfflineClaim";
import { backupClaimData } from "../../utils";
import { trimUrl } from "../api/api";

type useOfflineFeatureMutationsProps = {
  claimId: string;
};

type StoredFeature = {
  feature: Feature;
  url: string;
};

const addFeatureToStore = async (
  feature: Feature<Geometry>,
  url: string,
  storeKey: string
) => {
  const features = JSON.parse(await store.get(storeKey)) || [];
  await store.set(
    storeKey,
    JSON.stringify([
      ...features,
      { feature: geoJson.writeFeatureObject(feature), url },
    ])
  );
};

export const useOfflineFeatureMutations = ({
  claimId,
}: useOfflineFeatureMutationsProps) => {
  const { online } = useNetworkContext();
  const setNeedsSync = useClaimStore((state) => state.setNeedsSync);

  const layerStoreKey = (layerUrl: string) => `layer-${layerUrl}-${claimId}`;
  const featuresToCreateStoreKey = `featuresToCreate-${claimId}`;
  const featuresToUpdateStoreKey = `featuresToUpdate-${claimId}`;
  const featuresToDeleteStoreKey = `featuresToDelete-${claimId}`;
  const featuresFailedToCreateStoreKey = `featuresFailedToCreate-${claimId}`;
  const featuresFailedToUpdateStoreKey = `featuresFailedToUpdate-${claimId}`;
  const featuresFailedToDeleteStoreKey = `featuresFailedToDelete-${claimId}`;
  const failedSyncFeaturesStoreKey = `featuresFailedSync-${claimId}`;

  const sync = async () => {
    const featuresToCreate = (
      JSON.parse(await store.get(featuresToCreateStoreKey)) || []
    ).map((feature: StoredFeature) => ({
      feature: geoJson.readFeature(feature.feature),
      url: feature.url,
    }));
    const featuresToUpdate = (
      JSON.parse(await store.get(featuresToUpdateStoreKey)) || []
    ).map((feature: StoredFeature) => ({
      feature: geoJson.readFeature(feature.feature),
      url: feature.url,
    }));
    const featuresToDelete = (
      JSON.parse(await store.get(featuresToDeleteStoreKey)) || []
    ).map((feature: StoredFeature) => ({
      feature: geoJson.readFeature(feature.feature),
      url: feature.url,
    }));
    let hasError = false;
    try {
      if (featuresToCreate) {
        for (const { feature, url } of featuresToCreate) {
          const toAPI = geoJson.writeFeatureObject(feature);
          try {
            const result = await axiosClient.post(
              `${config.gisApiUrl}${trimUrl(url)}`,
              toAPI
            );
            const serverResponse = geoJson.readFeature(result.data);
            feature.setProperties(serverResponse.getProperties());
            console.log("------- CREATE COMPLETED -------");
          } catch (e) {
            hasError = true;
            addFeatureToStore(feature, url, failedSyncFeaturesStoreKey);
            addFeatureToStore(feature, url, featuresFailedToCreateStoreKey);

            Sentry.withScope((scope) => {
              scope.setContext("feature", {
                claimId,
                feature: geoJson.writeFeatureObject(feature),
                url,
              });
              Sentry.captureException(e);
            });
          }
        }
      }

      if (featuresToUpdate) {
        for (const { feature, url } of featuresToUpdate) {
          const toAPI = geoJson.writeFeatureObject(feature);
          try {
            const result = await axiosClient.put(
              `${config.gisApiUrl}${trimUrl(url)}/${feature.get("feature_id")}`,
              toAPI
            );
            const serverResponse = geoJson.readFeature(result.data);
            feature.setProperties(serverResponse.getProperties());
            console.log("------- UPDATE COMPLETED -------");
          } catch (e) {
            hasError = true;
            addFeatureToStore(feature, url, failedSyncFeaturesStoreKey);
            addFeatureToStore(feature, url, featuresFailedToUpdateStoreKey);
            Sentry.withScope((scope) => {
              scope.setContext("feature", {
                claimId,
                feature: geoJson.writeFeatureObject(feature),
                url,
              });
              Sentry.captureException(e);
            });
          }
        }
      }

      if (featuresToDelete) {
        for (const { feature, url } of featuresToDelete) {
          try {
            await axiosClient.delete(
              `${config.gisApiUrl}${trimUrl(url)}/${feature.get("feature_id")}`
            );
            console.log("------- DELETE COMPLETED -------");
          } catch (e) {
            hasError = true;
            addFeatureToStore(feature, url, failedSyncFeaturesStoreKey);
            addFeatureToStore(feature, url, featuresFailedToDeleteStoreKey);
            Sentry.withScope((scope) => {
              scope.setContext("feature", {
                claimId,
                feature: geoJson.writeFeatureObject(feature),
                url,
              });
              Sentry.captureException(e);
            });
          }
        }
      }
    } catch (e) {
      hasError = true;
      Sentry.captureException(e);
    }
    if (hasError) {
      enqueueErrorSnackbar(
        "Failed to sync a change. Please try again. If it continues to fail, please contact Infinity Studio and we can help resolve the issue."
      );
      await backupClaimData(claimId);
    }

    await store.set(
      featuresToCreateStoreKey,
      await store.get(featuresFailedToCreateStoreKey)
    );
    await store.set(featuresFailedToCreateStoreKey, "[]");

    await store.set(
      featuresToUpdateStoreKey,
      await store.get(featuresFailedToUpdateStoreKey)
    );
    await store.set(featuresFailedToUpdateStoreKey, "[]");

    await store.set(
      featuresToDeleteStoreKey,
      await store.get(featuresFailedToDeleteStoreKey)
    );
    await store.set(featuresFailedToUpdateStoreKey, "[]");
    return !hasError;
  };
  const createFeature = async ({
    feature,
    url,
    layer,
  }: {
    feature: Feature;
    url: string | FeatureUrlFunction | undefined;
    layer: Feature[];
  }) => {
    url = url?.toString() || "";

    await store.set(
      layerStoreKey(url),
      JSON.stringify(layer.map((feat) => geoJson.writeFeatureObject(feat)))
    );

    const featuresToCreate =
      JSON.parse(await store.get(featuresToCreateStoreKey)) || [];

    if (!online) {
      await store.set(
        featuresToCreateStoreKey,
        JSON.stringify([
          ...featuresToCreate,
          { feature: geoJson.writeFeatureObject(feature), url },
        ])
      );

      await store.set(
        `claim-${claimId}-unsynced-changes`,
        JSON.stringify(true)
      );
      setNeedsSync(true);
      return;
    }
    const toAPI = geoJson.writeFeatureObject(feature);

    try {
      const result = await axiosClient.post(
        `${config.gisApiUrl}${trimUrl(url)}`,
        toAPI
      );
      const serverResponse = geoJson.readFeature(result.data);
      feature.setProperties(serverResponse.getProperties());
      console.log("------- CREATE COMPLETED -------");
    } catch (e) {
      console.log(e);
      enqueueErrorSnackbar("Failed to create feature");
    }
  };

  const updateFeature = async ({
    feature,
    url,
    layer,
  }: {
    feature: Feature<Geometry>;
    url: string | FeatureUrlFunction | undefined;
    layer: Feature<Geometry>[];
  }) => {
    url = url?.toString() || "";
    await store.set(
      layerStoreKey(url),
      JSON.stringify(
        layer.map((feature) => geoJson.writeFeatureObject(feature))
      )
    );

    const featuresToUpdate =
      JSON.parse(await store.get(featuresToUpdateStoreKey)) || [];
    await store.set(
      featuresToUpdateStoreKey,
      JSON.stringify([
        ...featuresToUpdate,
        { feature: geoJson.writeFeatureObject(feature), url },
      ])
    );

    if (!online) {
      await store.set(
        `claim-${claimId}-unsynced-changes`,
        JSON.stringify(true)
      );
      setNeedsSync(true);
      return;
    }

    const toAPI = geoJson.writeFeatureObject(feature);
    try {
      const result = await axiosClient.put(
        `${config.gisApiUrl}${trimUrl(url)}/${feature.get("feature_id")}`,
        toAPI
      );
      const serverResponse = geoJson.readFeature(result.data);
      feature.setProperties(serverResponse.getProperties());
      console.log("------- UPDATE COMPLETED -------");
    } catch (e) {
      console.log(e);
      enqueueErrorSnackbar("Failed to update feature");
    }
  };

  const deleteFeature = async ({
    feature,
    url,
    layer,
  }: {
    feature: Feature;
    url: string | FeatureUrlFunction | undefined;
    layer: Feature[];
  }) => {
    url = url?.toString() || "";

    if (!online) {
      // Existing server features, mark to be deleted when syncing
      const featuresToDelete =
        JSON.parse(await store.get(featuresToDeleteStoreKey)) || [];
      await store.set(
        featuresToDeleteStoreKey,
        JSON.stringify([
          ...featuresToDelete,
          { feature: geoJson.writeFeatureObject(feature), url },
        ])
      );
    }
    await store.set(
      layerStoreKey(url),
      JSON.stringify(
        layer.map((feature) => geoJson.writeFeatureObject(feature))
      )
    );

    if (!online) {
      await store.set(
        `claim-${claimId}-unsynced-changes`,
        JSON.stringify(true)
      );
      setNeedsSync(true);
      return;
    }

    try {
      await axiosClient.delete(
        `${config.gisApiUrl}${trimUrl(url)}/${feature.get("feature_id")}`
      );
      console.log("------- DELETE COMPLETED -------");
    } catch (e) {
      console.log(e);
      enqueueErrorSnackbar("Failed to delete feature");
    }
  };

  return { createFeature, updateFeature, deleteFeature, sync };
};
