import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { JSONContent } from "@tiptap/react";
import { useSize } from "ahooks";
import { Ref, useState } from "react";
import { Banner } from "@athena/components";
import { InlineEditor } from "src/shared/reportBuilder/InlineEditor";
import { TextEditorRef } from "src/shared/reportBuilder/TextEditor";
import { TemplateWithVariables } from "src/shared/reportBuilder/templateVariables/TemplateWithVariables";
import ReportGmailerrorredIcon from "@mui/icons-material/ReportGmailerrorred";
export type CSSModalProps = {
  css: string;
  onChange: (css: string) => void;
  onClose: VoidFunction;
};
export const CSSModal = (props: CSSModalProps) => {
  const { css: initialCss, onChange, onClose } = props;
  const [css, setCss] = useState(initialCss);
  return (
    <Dialog
      open={true}
      onClose={onClose}
      maxWidth={false}
      sx={{ " .MuiPaper-root": { flexWrap: "nowrap" } }}
    >
      <DialogTitle>Styles</DialogTitle>
      <DialogContent sx={{}}>
        <Banner
          icon={<ReportGmailerrorredIcon />}
          subtitle={
            "Do not change this unless you know what you are doing. It will break the report style."
          }
          title="CAUTION:"
          variant="warning"
        />
        <TextField
          value={css}
          onChange={(e) => {
            setCss(e.target.value);
          }}
          multiline
          minRows={10}
          sx={{ minWidth: 1000 }}
        />
      </DialogContent>
      <DialogActions
        sx={{
          display: "flex",
          flexDirection: "column",
          margin: "0 1rem 1rem 1rem",
        }}
      >
        <Button
          variant="contained"
          sx={{ width: "100%", margin: "1rem 0 0 0 !important" }}
          onClick={() => {
            onChange(css);
            onClose();
          }}
        >
          Save
        </Button>
        <Button
          onClick={() => {
            onClose();
          }}
          variant="outlined"
          sx={{ width: "100%", margin: "1rem 0 0 0 !important" }}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};
