import { UpdateClaim } from "@athena/server/src/api/types/claimAssessment";
import {
  TReportTemplateStyles,
  defaultStyles,
  generateStyleSheet,
} from "@athena/server/src/api/types/reportTemplate";
import { User } from "@athena/server/src/trpc/routers/user/types";
import styled from "@emotion/styled";
import type { Editor } from "@tiptap/core";
import { JSONContent, getMarksBetween } from "@tiptap/core";
import BubbleMenuExtension from "@tiptap/extension-bubble-menu";
import Color from "@tiptap/extension-color";
import Superscript from "@tiptap/extension-superscript";
import Table from "@tiptap/extension-table";
import TableHeader from "@tiptap/extension-table-header";
import TableRow from "@tiptap/extension-table-row";
import TextAlign from "@tiptap/extension-text-align";
import Underline from "@tiptap/extension-underline";
import { BubbleMenu, EditorContent, useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import { Node } from "prosemirror-model";
import {
  Ref,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import uniqolor from "uniqolor";
import { v4 as uuidv4 } from "uuid";
import { TextStyleExtended } from "./FontSize";
import { ImageResize } from "./ImageResize";
import { LockedValue } from "./LockedValue";
import { MenuBar } from "./MenuBar";
import { PageBreak } from "./PageBreak";
import { TableCellExtension } from "./TableCellExtension";
import { TemplateVariable } from "./TemplateVariable";
import "./style.css";
import { AddCommentCard } from "./trackChanges/AddCommentCard";
// https://github.com/sereneinserenade/tiptap-comment-extension-react
import Highlight from "@tiptap/extension-highlight";
import { CommentExtension } from "./trackChanges/CommentExtension";
import { FloatingToolbar } from "./trackChanges/FloatingToolbar";
import { TrackedChangeBox } from "./trackChanges/TrackedChangeBox";
import TrackChangeExtension from "./trackChanges/TrackedChangesExtension";

interface TextEditorProps {
  className?: string;
  currentUser: User;
  trackedChangesEnabled: boolean;
  trackedChangesMarkupEnabled?: boolean;
  disableInlineMenu?: boolean;
  content: string | JSONContent;
  onContentChange?: (content: JSONContent) => void;
  onLoad?: VoidFunction;
  onImageUpload?: (file: File) => Promise<{ url: string }>;
  scrollToElOnLoadId?: string | null;
  onCommentsChange?: (comments: Comment[]) => void;
  onSelectedCommentChange?: (uuid: string | undefined) => void;
  onSelectedTrackedChangeChange?: (uuid: string | undefined) => void;
  id?: string;
  claim?: UpdateClaim;
  onTrackedChangesEnabledChange?: (enabled: boolean) => void;
  onTrackedChangesMarkupEnabledChange?: (enabled: boolean) => void;
  readOnly?: boolean;
  org?: { logoUrl: string };
  styles?: TReportTemplateStyles;
}

export interface Comment {
  uuid: string;
  text: string;
  userId: string;
  userName: string;
  timestamp: number;
  node: Element;
  reportBlock?: JSONContent;
  active: boolean;
  position: number;
}

export type TextEditorRef = {
  // Define the methods or properties that you want to expose
  // through the ref object
  addTemplateVariable: (
    value: string,
    label: string,
    description: string,
    hasConfig: boolean
  ) => void;
  getHtml: () => string | undefined;
  editor: Editor | null;
};

export const TextEditor = forwardRef(
  (
    {
      className,
      currentUser,
      trackedChangesEnabled,
      trackedChangesMarkupEnabled,
      content,
      onContentChange,
      onImageUpload,
      onSelectedCommentChange,
      onLoad,
      id,
      claim,
      onTrackedChangesEnabledChange,
      onTrackedChangesMarkupEnabledChange,
      org,
      readOnly = false,
      disableInlineMenu = false,
      styles,
    }: TextEditorProps,
    ref: Ref<TextEditorRef>
  ) => {
    const [mountedEditor, setMountedEditor] = useState(false);
    const [addingComment, setAddingComment] = useState(false);
    const editorRef = useRef<HTMLDivElement>(null);
    const fileInput = useRef<HTMLInputElement>(null);
    useEffect(() => {
      // Hack, useEditor on create is triggering before content dom elements are rendered
      if (mountedEditor) return;
      const element = document.querySelector(".ProseMirror"); // or document.getElementById('my-id')
      if (element) {
        setMountedEditor(true);
        onLoad?.();
      }
    });

    useEffect(() => {
      const cleanup = () => {
        const styleElement = document.head.querySelectorAll("#editor-styles");
        if (styleElement) {
          styleElement.forEach((el) => el.remove());
        }
      };

      cleanup();
      const style = document.createElement("style");
      style.id = "editor-styles";
      style.innerHTML = generateStyleSheet(
        styles || defaultStyles,
        ".report-editor"
      );
      document.head.appendChild(style);
      return () => {
        cleanup();
      };
    }, [styles]);

    const editor = useEditor(
      {
        editorProps: {
          attributes: {
            id: id || `editor`,
            style: "position: relative;",
            class: `${className || ""} ${
              trackedChangesMarkupEnabled ? "" : "not-tracked-changes"
            }`,
          },
        },
        parseOptions: {
          preserveWhitespace: "full",
        },
        editable: !readOnly,
        extensions: [
          TrackChangeExtension.configure({
            enabled: trackedChangesEnabled,
            userId: currentUser.userId,
            userName: currentUser.firstName + " " + currentUser.lastName,
          }),
          CommentExtension.configure({
            userId: currentUser.userId,
            userName: currentUser.firstName + " " + currentUser.lastName,
            color: uniqolor(currentUser.userId, {
              format: "rgb",
              saturation: 80,
              lightness: [70, 80],
            }).color,
          }),

          PageBreak,
          StarterKit,
          Table,
          TableCellExtension,
          TableRow,
          TableHeader,
          Highlight.configure({
            multicolor: true,
          }),
          BubbleMenuExtension,
          ImageResize,
          Superscript,
          Underline,
          Color.configure({
            types: ["textStyle"],
          }),
          TextStyleExtended,
          TemplateVariable,
          TextAlign.configure({
            types: ["heading", "paragraph"],
          }),
          LockedValue.configure({
            getData: () => {
              return { currentUser, claim, org };
            },
          }),
          // ReadOnlyValueExtension,
        ],
        onUpdate: (e) => {
          handleCommentUpdate(e.editor);
          onContentChange?.(e.editor.getJSON());
        },
        onTransaction: (e) => {
          onContentChange?.(e.editor.getJSON());
        },
        onCreate: () => {
          onLoad?.();
        },
        onSelectionUpdate: (e) => {
          handleCommentUpdate(e.editor);
        },
        content,
      },
      [trackedChangesEnabled, trackedChangesMarkupEnabled]
    );

    useImperativeHandle(ref, () => ({
      addTemplateVariable(
        value: string,
        label: string,
        description: string,
        hasConfig: boolean
      ) {
        if (editor) {
          if (hasConfig) {
            editor
              .chain()
              .focus()
              .splitBlock()
              .setTemplateVariable({ label, value, description, hasConfig })
              .run();
          } else {
            editor
              .chain()
              .focus()
              .setTemplateVariable({ label, value, description, hasConfig })
              .run();
          }
        }
      },
      getHtml() {
        if (editor) {
          return editor.getHTML();
        }
      },
      editor,
    }));
    const handleCommentUpdate = (editor: Editor) => {
      if (!editor) {
        return;
      }

      const comment = editor.getAttributes("comment");
      if (comment.comment) {
        const { uuid } = JSON.parse(comment.comment);
        onSelectedCommentChange?.(uuid);
        return;
      }
      onSelectedCommentChange?.(undefined);
    };

    const toggleComment = () => {
      if (!editor) {
        return;
      }

      setAddingComment(true);
    };

    const commitComment = (comment: string) => {
      if (!editor) {
        return;
      }

      editor
        .chain()
        .focus()
        .setComment(
          JSON.stringify({
            uuid: uuidv4(),
            text: comment,
            userId: currentUser.userId,
            userName: currentUser.firstName + " " + currentUser.lastName,
            createdAt: new Date().getTime().toString(),
          })
        )
        .run();
      setAddingComment(false);
    };

    function findMarkPosition(mark: any, doc: Node, from: number, to: number) {
      // This might be really slow, cant find a better solution atm
      let markPos = { start: -1, end: -1 };
      try {
        doc.nodesBetween(from, to, (node: Node, pos) => {
          // stop recursing if result is found
          if (markPos.start > -1) {
            return false;
          }
          if (markPos.start === -1 && mark.isInSet(node.marks)) {
            // expect to see something like `italic('my text')`
            node.toString();
            markPos = {
              start: pos,
              end: pos + Math.max(node.textContent.length, 1),
            };
            return !!markPos;
          }
          return false;
        });
      } catch (e) {
        console.log(e);
      }

      return markPos;
    }
    let deletionMark: any | undefined = undefined;
    let insertionMark: any | undefined = undefined;
    if (editor && editor.state) {
      const { doc, selection } = editor.state;
      const { $from } = selection;
      try {
        if (editor.isActive("deletion") || editor.isActive("insertion")) {
          // const isDeletion = editor.isActive("deletion");
          // Get the current node at the start position
          const node = editor.state.doc.nodeAt($from.pos)?.marks[0];
          const pos = findMarkPosition(node, doc, 0, doc.nodeSize);
          // Use the following lines if we want to add replaced logic, it will check for neigbouring marks
          // const start = isDeletion ? pos.start : pos.start - 1;
          // const end = isDeletion ? pos.end + 1 : pos.end;

          const trackedChangeMarks = getMarksBetween(pos.start, pos.end, doc);
          deletionMark = trackedChangeMarks.find(
            (mark) => mark.mark.type.name === "deletion"
          );
          insertionMark = trackedChangeMarks.find((mark) => {
            return mark.mark.type.name === "insertion";
          });
        }
      } catch (e) {
        console.log(e);
      }
    }
    return (
      <div style={{ width: "100%" }}>
        <input
          type="file"
          name="file"
          accept="image/*"
          onChange={(event) => {
            if (!event.target.files) return;
            onImageUpload?.(event.target.files[0]).then(
              (res: { url: string }) => {
                if (editor && fileInput.current) {
                  fileInput.current.value = "";
                  editor
                    .chain()
                    .focus()
                    .setImage({
                      src: res.url,
                    })
                    .run();
                }
              }
            );
          }}
          ref={fileInput}
          style={{ display: "none" }}
        />
        {editor && !disableInlineMenu && (
          <FloatingToolbar
            editor={editor}
            toggleAddingComment={toggleComment}
          />
        )}

        <EditorContainer ref={editorRef}>
          {editor && !readOnly && (
            <MenuBar
              editor={editor}
              onImageAdd={() => {
                if (fileInput.current) {
                  fileInput.current.click();
                }
              }}
              trackedChangesEnabled={trackedChangesEnabled}
              trackedChangesMarkupEnabledChange={trackedChangesMarkupEnabled}
              sticky
              onTrackedChangesEnabledChange={onTrackedChangesEnabledChange}
              onTrackedChangesMarkupEnabledChange={
                onTrackedChangesMarkupEnabledChange
              }
            />
          )}
          <OverflowContainer>
            <ContentContainer>
              <EditorContent editor={editor} />
            </ContentContainer>
          </OverflowContainer>
        </EditorContainer>
        {editor && (
          <BubbleMenu
            editor={editor}
            tippyOptions={{ duration: 100 }}
            shouldShow={(p) =>
              p.editor.isActive("deletion") || p.editor.isActive("insertion")
            }
          >
            <TrackedChangeBox
              deleteTrackedChange={
                deletionMark
                  ? JSON.parse(deletionMark?.mark?.attrs?.change || "{}")
                  : undefined
              }
              insertTrackedChange={
                insertionMark
                  ? JSON.parse(insertionMark?.mark?.attrs?.change || "{}")
                  : undefined
              }
              onAccept={() => {
                editor.chain().acceptChange().acceptChange().run();
              }}
              onReject={() => {
                const isReplacement = !!editor?.view.state.doc
                  .resolve(
                    editor.view.state.selection.$anchor.pos > 1
                      ? editor.view.state.selection.$anchor.pos - 2
                      : 0
                  )
                  .marks()[0]?.attrs?.user;
                editor.chain().focus().rejectChange().run();
                if (isReplacement) {
                  editor.chain().focus().rejectChange().run();
                }
              }}
            />
          </BubbleMenu>
        )}
        {addingComment && editor && (
          <AddCommentCard
            commitComment={commitComment}
            abortComment={() => setAddingComment(false)}
            editor={editor}
          />
        )}
      </div>
    );
  }
);

const EditorContainer = styled.div`
  border-radius: 10px;
`;

const ContentContainer = styled.div`
  display: flex;
  margin-left: auto;
  margin-right: auto;
  border: 1px solid lightgrey;
  border-radius: 4px;
  position: relative;
  flex: 1;
  width: 218mm; /* Width of A4 paper in millimeters */
  /* height: 297mm;  */
  /* Height of A4 paper in millimeters */
  border: 1px solid #ccc; /* Optional: Add border for visual separation */
  padding: 0.5rem;
  @media screen {
    max-width: 218mm;
  }
`;

const OverflowContainer = styled.div`
  @media (max-width: 640px) {
    max-width: calc(100vw - 2rem);
    overflow-x: scroll;
  }
`;
