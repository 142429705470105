import { z } from "zod";
import { AdditionalStatus, Status } from "./claimStatuses.ts";

export enum Urgency {
  Normal = "Normal",
  Urgent = "Urgent",
}

export const constructiblilityEnum = z
  .enum(["easy", "medium", "hard", "n/a"])
  .or(z.literal(""));

export const EventSchema = z.object({
  eventId: z.string().uuid(),
  claimId: z.string().uuid().optional(),
  organisationId: z.string().uuid().optional(),
  name: z.string(),
  dateOfEvent: z.string().datetime().or(z.null()).optional(),
  eventCoordinatorId: z.string().uuid().optional(),
  eventCoordinator: z
    .object({
      id: z.string().optional(),
      name: z.string().optional(),
    })
    .optional(),
});
export const CreateClaimSchema = z.object({
  claimId: z.string().uuid().optional(),
  serviceRequestDate: z.string().datetime().optional(),
  events: z
    .array(
      z.object({
        id: z.string(),
        name: z.string(),
        dateOfEvent: z.string().datetime().optional(),
      })
    )
    .min(1, "Required"),
  regionalCoordinator: z.object({
    id: z.string(),
    name: z.string(),
  }),
  urgency: z.string({ required_error: "Required" }),
  lossCause: z.object(
    {
      id: z.string(),
      name: z.string(),
    },
    { invalid_type_error: "Required" }
  ),
  associatedClaims: z.string().optional(),
  bauLossDate: z.string().optional(),
  location: z
    .object({
      geo: z.object(
        {
          type: z.literal("Point"),
          coordinates: z.array(z.coerce.number()).max(2).min(2, "Required"),
        },
        { invalid_type_error: "Required" }
      ),
      address: z
        .string({
          invalid_type_error: "Required",
          required_error: "Required",
        })
        .optional(),
      parcelAddress: z
        .string({
          invalid_type_error: "Required",
          required_error: "Required",
        })
        .optional(),
      region: z.string({ required_error: "Required" }).optional(),
      manual: z
        .object({
          address: z.string().optional(),
          suburb: z.string().optional(),
          region: z.string().optional(),
          city: z.string().optional(),
          postcode: z.string().optional(),
        })
        .optional(),
    })
    .refine(
      (data) =>
        data.parcelAddress != null ||
        data.address != null ||
        data.manual?.address != null,
      {
        message: "An address is required",
      }
    ),
  customers: z.array(
    z.object({
      email: z.string().email().optional(),
      name: z.string(),
      contactNumber: z.string(),
      primaryContact: z.boolean().default(false),
    })
  ),

  lossAdjusterId: z.string().uuid().or(z.null()).optional(),
  lossAdjusterUserContactNumber: z.string().optional(),
  lossAdjusterUserEmail: z.string().optional(),
  lossAdjusterPurchaseOrderNumber: z.string().optional(),
  lossAdjusterUserId: z.string().uuid().optional(),
  lossAdjusterUserName: z.string().optional(),
  lossAdjusterName: z.string().or(z.null()).optional(),
  insurerReference: z.string({ required_error: "Required" }).optional(),
  insurerId: z.string().optional(),
  insurer: z
    .object(
      {
        id: z.string().optional(),
        name: z.string().optional(),
      },
      { invalid_type_error: "Required" }
    )
    .or(z.null())
    .optional(),
  reference: z.string().optional(),
  claimName: z.string({ required_error: "Required" }).optional(),
  assignedOffice: z
    .object({
      id: z.string().optional(),
      name: z.string().optional(),
    })
    .or(z.null())
    .optional(),
  generalNotes: z.string().optional(),
  internalNotes: z.string().optional(),
});

export const ClaimSchema = z.object({
  updated: z.string().optional(), // one-table managed
  created: z.string().optional(), // one-table managed
  insUpdTs: z.string().optional(), // one-table managed
  serviceRequestDate: z.string().datetime().optional(),
  claimId: z.string(),
  reference: z.string().optional(),
  claimName: z
    .string({ required_error: "Required" })
    .min(1, "Required")
    .optional(),
  assignedOffice: z
    .object({
      id: z.string().optional(),
      name: z.string().optional(),
    })
    .or(z.null())
    .optional(),
  generalNotes: z
    .string({ required_error: "Required" })
    .min(1, "Required")
    .optional(),
  internalNotes: z.string().optional(),
  insurerReference: z
    .string({ required_error: "Required" })
    .min(1, "Required")
    .optional(),
  completedAt: z.string().datetime().optional(),
  completed: z.boolean(),
  status: z.nativeEnum(Status).optional(),
  organisationId: z.string().optional(),
  assignedEngineerIds: z.array(z.string()).optional(),
  reviewingEngineerIds: z.array(z.string()).optional(),
  additionalStatuses: z.array(z.nativeEnum(AdditionalStatus)).optional(),
  urgency: z.nativeEnum(Urgency, { required_error: "Required" }),
  lossCause: z.object(
    {
      id: z.string(),
      name: z.string(),
    },
    { invalid_type_error: "Required" }
  ),
  insurerId: z.string().optional(),
  regionalCoordinator: z.object(
    {
      id: z.string(),
      name: z.string(),
    },
    { required_error: "Required", invalid_type_error: "Required" }
  ),
  // lossAdjuster: z.object(
  //   {
  //     name: z.string().min(1, "Required"),
  //     email: z.string().email(),
  //     contactNumber: z.string().min(1, "Required"),
  //     purchaseOrder: z.string().min(1, "Required"),
  //   },
  //   { required_error: "Required", invalid_type_error: "Required" }
  // ),
  lossAdjusterId: z.string().or(z.null()).optional(),
  lossAdjusterName: z.string().or(z.null()).optional(),
  lossAdjusterPurchaseOrderNumber: z.string().optional(),
  lossAdjusterUserId: z.string().optional(),
  lossAdjusterUserName: z.string().optional(),
  lossAdjusterUserEmail: z.string().optional(),
  lossAdjusterUserContactNumber: z.string().optional(),
  assignedEngineers: z
    .array(
      z.object({
        id: z.string(),
        name: z.string(),
        accepted: z.boolean().optional(),
      })
    )
    .optional(),
  reviewingEngineers: z
    .array(
      z.object({
        id: z.string(),
        name: z.string(),
        accepted: z.boolean().optional(),
      })
    )
    .optional(),
  insurer: z
    .object(
      {
        id: z.string().optional(),
        name: z.string().optional(),
      },
      { invalid_type_error: "Required" }
    )
    .or(z.null())
    .optional(),
  customerContacted: z.boolean().optional(),
  firstSiteVisitDate: z.date({ coerce: true }).or(z.null()).optional(),
  customers: z.array(
    z.object({
      email: z.string().email().optional(),
      name: z.string(),
      contactNumber: z.string(),
      primaryContact: z.boolean().default(false),
    })
  ),
  events: z
    .array(
      z.object({
        id: z.string(),
        name: z.string(),
        dateOfEvent: z.string().datetime().optional(),
      })
    )
    .min(1, "Required"),
  location: z
    .object({
      geo: z.object(
        {
          type: z.literal("Point"),
          coordinates: z
            .array(z.coerce.number())
            .max(2)
            .min(2, "Please set the location pin on the map"),
        },
        {
          required_error: "Please set the location pin on the map",
        }
      ),
      address: z
        .string({
          invalid_type_error: "Required",
          required_error: "Required",
        })
        .optional(),
      parcelAddress: z
        .string({
          invalid_type_error: "Required",
          required_error: "Required",
        })
        .optional(),
      region: z.string({ required_error: "Required" }).optional(),
      manual: z
        .object({
          address: z.string(),
          suburb: z.string().optional(),
          region: z.string().optional(),
          city: z.string().optional(),
          postcode: z.string().optional(),
        })
        .optional(),
    })
    .refine(
      (data) =>
        data.address != null ||
        data.parcelAddress != null ||
        data.manual?.address != null,
      {
        message: "An address is required",
      }
    ),
  associatedClaims: z.string().optional(),
  bauLossDate: z.string().optional(),

  // Assessment Form
  siteDescription: z.string().optional(),
  naturalDisasterDamage: z.boolean().optional(),
  evidenceOfOlderInstability: z.boolean().optional(),
  detailsOfOlderInstability: z.string().optional(),
  multiplePropertiesAffected: z.boolean(),
  multiplePropertiesAffectedNotes: z.string().optional(),

  checkListHealthAndSafety: z.boolean(),
  checkListPhotosTaken: z.boolean(),
  checkListAddressedQuestionsAndDamage: z.boolean(),
  checkListSitePlanAndCrossSection: z.boolean(),
  checkListBoundariesIdentified: z.boolean(),

  constructabilityConstructionAccess: constructiblilityEnum.optional(),
  constructabilityEarthworks: constructiblilityEnum.optional(),
  constructabilityReinstatement: constructiblilityEnum.optional(),
});

export type Claim = z.infer<typeof ClaimSchema>;
export type CreateClaim = z.infer<typeof CreateClaimSchema>;
export type Event = z.infer<typeof EventSchema>;
