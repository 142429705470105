import { AxiosInstance } from "axios";

import {
  IsoCountryAlpha2,
  SearchLocationResponse,
  SearchLocation,
} from "./types.ts";

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace MapboxGeocoding {
  /**
   * The data types available in the geocoder, listed from the largest to the most granular
   */
  export enum Type {
    /**
     * Generally recognized countries or, in some cases like Hong Kong, an area of quasi-national administrative status that has been given a designated country code under [ISO 3166-1](https://www.iso.org/iso-3166-country-codes.html).
     */
    Country = "country",
    /**
     * 	Top-level sub-national administrative features, such as states in the United States or provinces in Canada or China.
     */
    Region = "region",
    /**
     * Postal codes used in country-specific national addressing systems.
     */
    Postcode = "postcode",
    /**
     * Features that are smaller than top-level administrative features but typically larger than cities, in countries that use such an additional layer in postal addressing (for example, prefectures in China).
     */
    District = "district",
    /**
     * 	Typically these are cities, villages, municipalities, etc. They’re usually features used in postal addressing, and are suitable for display in ambient end-user applications where current-location context is needed (for example, in weather displays).
     */
    Place = "place",
    /**
     * 	Official sub-city features present in countries where such an additional administrative layer is used in postal addressing, or where such features are commonly referred to in local parlance. Examples include city districts in Brazil and Chile and arrondissements in France.
     */
    Locality = "locality",
    /**
     * Colloquial sub-city features often referred to in local parlance. Unlike locality features, these typically lack official status and may lack universally agreed-upon boundaries.
     */
    Neighborhood = "neighborhood",
    /**
     * 	Individual residential or business addresses.
     */
    Address = "address",
    /**
     * Points of interest. These include restaurants, stores, concert venues, parks, museums, etc.
     */
    // PointOfInterest = "poi"
  }

  /**
   * The properties.accuracy property in a Geocoding API response object is a point accuracy metric for the returned address feature. This list is subject to change.
   */
  export enum Accuracy {
    /**
     * Result is for a specific building/entrance
     */
    Rooftop = "rooftop",
    /**
     * Result is derived from a parcel centroid
     */
    Parcel = "parcel",
    /**
     * Result is a known address point but has no specific accuracy
     */
    Point = "point",
    /**
     * Result has been interpolated from an address range
     */
    Interpolated = "interpolated",
    /**
     * Result is for a block or intersection
     */
    Intersection = "intersection",
    /**
     * 	Result is an approximate location
     */
    Approximate = "approximate",
    /**
     * Result is a street centroid
     */
    Street = "street",
  }

  export interface FeatureCollection {
    /**
     * "FeatureCollection", a GeoJSON type from the [GeoJSON specification](https://datatracker.ietf.org/doc/html/rfc7946).
     */
    type: "FeatureCollection";
    /**
     * **Forward geocodes:** An array of space and punctuation-separated strings from the original query.
     * **Reverse geocodes:** An array containing the coordinates being queried.
     */
    query: string[];
    /**
     * 	An array of feature objects.
     *
     *  **Forward geocodes:** Returned features are ordered by relevance.
     *  **Reverse geocodes:** Returned features are ordered by index hierarchy, from most specific features to least specific features that overlap the queried coordinates.
     *
     *  Read the [Search result prioritization guide](https://docs.mapbox.com/help/getting-started/geocoding/#search-result-prioritization) to learn more about how returned features are organized in the Geocoding API response.
     */
    features: Feature[];
    /**
     * Attributes the results of the Mapbox Geocoding API to Mapbox.
     */
    attribution: string;
  }

  export interface Feature {
    /**
     * A feature ID in the format {type}.{id} where {type} is the lowest hierarchy feature in the place_type field. The {id} suffix of the feature ID is unstable and may change within versions.
     */
    id: `${Type}.${string}`;
    /**
     * "Feature", a GeoJSON type from the [GeoJSON specification](https://datatracker.ietf.org/doc/html/rfc7946).
     */
    type: "Feature";
    /**
     * 	An array of feature types describing the feature. Options are country, region, postcode, district, place, locality, neighborhood, address, and poi. Most features have only one type, but if the feature has multiple types, all applicable types will be listed in the array. (For example, Vatican City is a country, region, and place.)
     */
    place_type: Array<Type>;
    /**
     * Indicates how well the returned feature matches the user's query on a scale from 0 to 1. 0 means the result does not match the query text at all, while 1 means the result fully matches the query text. You can use the relevance property to remove results that don’t fully match the query. Learn more about textual relevance in the [Search result prioritization](https://docs.mapbox.com/help/getting-started/geocoding/#search-result-prioritization) guide.
     */
    relevance: number;
    /**
     * A string representing the feature in the requested language, if specified.
     */
    text: string;
    /**
     * A string representing the feature in the requested language, if specified, and its full result hierarchy.
     */
    place_name: string;
    /**
     * Optional. The house number for the returned address feature. Note that unlike the address property for poi features, this property is outside the properties object.
     */
    address?: string;
    /**
     * A bounding box array in the form [minX,minY,maxX,maxY].
     */
    bbox?: [number, number, number, number];
    /**
     * The coordinates of the feature’s center in the form [longitude,latitude]. This may be the literal centroid of the feature’s geometry, or the center of human activity within the feature (for example, the downtown area of a city).
     */
    center: [number, number];
    /**
     * 	An array representing the hierarchy of encompassing parent features. Each parent feature may include any of the above properties.
     */
    context?: Array<Partial<Feature>>;
    /**
     * Optional. An object with the routable points for the feature.
     */
    routable_points?: {
      /**
       * Optional. An array of points in the form of [{ coordinates: [lon, lat] }], or null if no points were found.
       */
      points: null | Array<{ coordinates: [number, number] }>;
    };
    /**
     * Optional. A string analogous to the text field that more closely matches the query than results in the specified language. For example, querying Köln, Germany with language set to English (en) might return a feature with the text Cologne and the matching_text Köln.
     *
     * Category matches will not appear as matching_text. For example, a query for coffee, Köln with language set to English (en) would return a poi Café Reichard, but this feature will not include a matching_text field.
     */
    matching_text?: string;
    /**
     * Optional. A string analogous to the place_name field that more closely matches the query than results in the specified language. For example, querying Köln, Germany with language set to English (en) might return a feature with the place_name Cologne, Germany and a matching_place_name of Köln, North Rhine-Westphalia, Germany.
     *
     * Category matches will not appear in the matching_place_name field. For example, a query for coffee, Köln with language set to English (en) would return a matching_place_name of Café Reichard, Unter Fettenhennen 11, Köln, North Rhine-Westphalia 50667, Germany instead of a matching_place_name of coffee, Unter Fettenhennen 11, Köln, North Rhine-Westphalia 50667, Germany.
     */
    matching_place_name?: string;
    /**
     * 	An object describing the spatial geometry of the returned feature.
     */
    geometry: Geometry;
    properties: Properties;
  }

  export interface Properties {
    /**
     * Optional. A point accuracy metric for the returned address feature. Can be one of rooftop, parcel, point, interpolated, intersection, street. Note that this list is subject to change. For details on these options, see the [Point accuracy for address features section](https://docs.mapbox.com/api/search/geocoding/#point-accuracy-for-address-features).
     */
    accuracy?: Accuracy;
    /**
     * Optional. The [Wikidata](https://www.wikidata.org) identifier for the returned feature.
     */
    wikidata?: string;
    /**
     * Optional. The [ISO 3166-1](https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2) country and [ISO 3166-2](https://en.wikipedia.org/wiki/ISO_3166-2) region code for the returned feature.
     */
    short_code?: string;
    /**
     * Optional. The full street address for the returned poi feature. Note that unlike the address property for address features, this property is inside the properties object.
     */
    // address?: string;
    /**
     * Describes whether or not the feature is in the poi.landmark data type. This data type is deprecated, and this property will be present on all poi features for backwards compatibility reasons but will always be true.
     */
    // landmark?: boolean;
    /**
     * @deprecated A formatted string of the telephone number for the returned poi feature.
     */
    // tel: string;
    /**
     * Optional. Comma-separated categories for the returned poi feature.
     */
    // category?: string;
    /**
     * Optional. The name of a suggested [Maki](https://www.mapbox.com/maki-icons/) icon to visualize a poi feature based on its category.
     */
    // maki?: string;
  }

  export interface Geometry {
    /**
     * "Point", a GeoJSON type from the [GeoJSON specification](https://datatracker.ietf.org/doc/html/rfc7946).
     */
    type: "Point";
    /**
     * An array in the format [longitude,latitude] at the center of the specified bbox.
     */
    coordinates: [number, number];
    /**
     * Optional. If present, indicates that an address is interpolated along a road network. The geocoder can usually return exact address points, but if an address is not present the geocoder can use interpolated data as a fallback. In edge cases, interpolation may not be possible if surrounding address data is not present, in which case the next fallback will be the center point of the street feature itself.
     */
    interpolated?: boolean;
    /**
     * 	Optional. If present, indicates an out-of-parity match. This occurs when an interpolated address is not in the expected range for the indicated side of the street.
     */
    omitted?: boolean;
  }
}

export enum MapboxGeocodingEndpoint {
  Temporary = "mapbox.places",
  Permanent = "mapbox.places-permanent"
}

interface MapboxGeocodingProps {
  search: string;
  types?: Array<MapboxGeocoding.Type>;
  country: IsoCountryAlpha2;
  accessToken: string;
  axiosClient: AxiosInstance;
  endpoint: MapboxGeocodingEndpoint
}

export const createMapboxGeocodingUrl = ({
  search,
  types,
  country,
  accessToken,
  endpoint,
}: MapboxGeocodingProps) => {
  const url = new URL(
    `https://api.mapbox.com/geocoding/v5/${endpoint}/${search}.json`
  );

  for (const type of types || []) {
    url.searchParams.append("types", type);
  }

  if (country) {
    url.searchParams.set("country", country);
  }

  url.searchParams.set("access_token", accessToken);

  return url.toString();
};

export const queryMapboxGeocodingApi = async (props: MapboxGeocodingProps) => {
  const url = createMapboxGeocodingUrl(props);
  const result = await props.axiosClient<MapboxGeocoding.FeatureCollection>({
    url,
    method: "GET",
    withCredentials: false,
  });
  if (result.status !== 200) {
    throw new Error("Error querying mapbox api");
  }

  return result.data;
};

interface SearchLocationMapboxProps {
  accessToken: string;
  axiosClient: AxiosInstance;
  endpoint: MapboxGeocodingEndpoint
}

export const createSearchLocationMapbox =
  ({ accessToken, axiosClient, endpoint }: SearchLocationMapboxProps): SearchLocation =>
  async (search: string) => {
    const data = await queryMapboxGeocodingApi({
      country: IsoCountryAlpha2.NewZealand,
      search: search,
      accessToken,
      axiosClient,
      endpoint
    });

    const mostRelevantFeature = data.features.sort(
      (a, b) => b.relevance - a.relevance
    )[0];

    const region = mostRelevantFeature.context?.find((x) =>
      x.id?.includes("region")
    )?.text;
    if (!region) {
      throw new Error("Could not find region");
    }

    const location: SearchLocationResponse = {
      geo: mostRelevantFeature.geometry,
      address: mostRelevantFeature.place_name,
      region,
    };

    return location;
  };
