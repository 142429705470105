import { Status } from "@athena/server/src/api/types/claimStatuses";
import {
  Bar,
  BarChart,
  Cell,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { ChartLoader } from "./ChartLoader";

interface Props {
  isLoading?: boolean;
  data: {
    [key: string]: {
      label: string;
      age0To5?: number;
      age5To10?: number;
      age10To15?: number;
      age15To20?: number;
      age20To25?: number;
      age25To30?: number;
      age30To35?: number;
      age35To40?: number;
      age40To45?: number;
      age45To50?: number;
      age50To55?: number;
      age55To60?: number;
      age60Plus?: number;
    };
  };
}

export const ClaimAgeChart = ({ data, isLoading }: Props) => {
  const colors = [
    "#ED5151",
    "#F199C3",
    "#FFE35B",
    "#F38115",
    "#8181DC",
    "#BDE579",
    "#37ACD5",
    "#C791C8",
    "#4EB265",
  ];
  const sortedData = Object.values(data);

  return (
    <div style={{ width: "100%", marginTop: "1.5rem", position: "relative" }}>
      {isLoading && <ChartLoader />}
      <ResponsiveContainer width="100%" height={300}>
        <BarChart data={sortedData}>
          <XAxis dataKey="label" />
          <YAxis />
          <Tooltip />
          {[
            Status.NeedsEngineer,
            Status.WaitingForCustomer,
            Status.VisitBooked,
            Status.VisitCompleted,
            Status.PreparingDraft,
            Status.UnderInternalReview,
            Status.UnderClientReview,
            Status.ClientAccepted,
          ].map((status, index) => {
            return (
              <Bar
                key={status}
                dataKey={`${status}`}
                name={status}
                stackId="a"
                fill={colors[index]}
              >
                {sortedData.map((_: any, index: number) => (
                  <Cell cursor="pointer" key={`cell-${index}`} />
                ))}
              </Bar>
            );
          })}
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};
