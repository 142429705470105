import { TReportTemplateStyles } from "@athena/server/src/api/types/reportTemplate";
import ReplayIcon from "@mui/icons-material/Replay";
import { Button, InputLabel, Tooltip } from "@mui/material";
import { Box } from "@mui/system";
import { Control, FieldValues, Path } from "react-hook-form";
import { FormTextField } from "src/shared/form/FormTextField";
import { SectionsWithFont } from "./HeadingStyle";

const height = 130;
const width = 200;
const positions = [
  ["left", "top"],
  ["top", "left"],
  ["right", "bottom"],
  ["bottom", "right"],
] as const;

const calculateTransform = (position: string) => {
  let transform =
    position === "left" ? "translate(-50%, 5px)" : "translate(5px, -50%)";
  transform = position === "right" ? `translate(+50%, -5px)` : transform;
  transform = position === "bottom" ? `translate(-5px, +50%)` : transform;
  return transform;
};

interface PaddingEditorProps<T extends FieldValues> {
  type: SectionsWithFont;
  control: Control<T>;
  setValue: (path: Path<TReportTemplateStyles>, value: any) => void;
}

export const PaddingEditor = (
  props: PaddingEditorProps<TReportTemplateStyles>
) => {
  const { type, control, setValue } = props;

  const resetToZero = () => {
    positions.forEach((position) => {
      setValue(`${type}.padding.${position[1]}`, "0");
      setValue(`${type}.margin.${position[1]}`, "0");
    });
  };
  return (
    <>
      <div
        style={{
          width: "100%",
          marginTop: "1rem",
          paddingTop: "1.5rem",
          borderTop: "1px solid #ececec",
        }}
      >
        <h4>Positioning</h4>
        <p style={{ color: "gray", marginBottom: "1rem", marginTop: "0.5rem" }}>
          This will change spacing, positioning and can be complicated to use.
          If you get stuck, reach out with any questions or{" "}
          <a
            href="https://infinitystudiohelp.zendesk.com/hc/en-us/sections/7456296136207-Athena-FAQ"
            target="_blank"
            rel="noreferrer"
          >
            learn more here
          </a>
          .
        </p>
        <Box
          sx={{
            borderRadius: "0.5rem",
            border: "1px solid #ececec",
            position: "relative",
            height: height + 100,
            width: "100%",
            maxWidth: "400px",
            bgcolor: "#fbfbfb",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              position: "relative",
              maxHeight: height,
              maxWidth: width,
              height: height,
              width: width,
              bgcolor: "#f0f0f0",
              border: "2px solid #bebebe",
              borderRadius: "0.5rem",
            }}
          >
            <InputLabel sx={{ position: "absolute", top: 4, left: 4 }}>
              Padding
            </InputLabel>
            {positions.map((position) => {
              return (
                <FormTextField
                  key={`${type}.padding.${position[1]}`}
                  control={control}
                  name={`${type}.padding.${position[1]}`}
                  defaultValue="0"
                  InputProps={{
                    endAdornment: "pt",
                    style: {
                      fontSize: 13,
                    },
                  }}
                  placeholder={position[0]}
                  sx={{
                    position: "absolute",
                    [position[0]]: "50%",
                    [position[1]]: "0",
                    transform: calculateTransform(position[0]),
                    width: "70px",
                    padding: "0",
                    margin: "0",
                    textAlign: "center",
                  }}
                />
              );
            })}
          </Box>
          <InputLabel sx={{ position: "absolute", top: 4, left: 4 }}>
            Margins
          </InputLabel>
          {positions.map((position) => {
            return (
              <FormTextField
                key={`${type}.margin.${position[1]}`}
                control={control}
                name={`${type}.margin.${position[1]}`}
                defaultValue="0"
                InputProps={{
                  endAdornment: "pt",
                  style: {
                    fontSize: 13,
                  },
                }}
                placeholder={position[0]}
                sx={{
                  position: "absolute",
                  [position[0]]: "50%",
                  [position[1]]: "0",
                  transform: calculateTransform(position[0]),
                  width: "70px",
                  padding: "0",
                  margin: "0",
                  textAlign: "center",
                }}
              />
            );
          })}
        </Box>
      </div>
      <Tooltip title="Reset padding/margin to zero">
        <Button
          variant="outlined"
          onClick={resetToZero}
          type="button"
          sx={{ display: "flex", flexDirection: "row", gap: "0.5rem" }}
        >
          {" "}
          <ReplayIcon
            sx={{
              color: "#1976d2",

              cursor: "pointer",
            }}
          />
          Reset Positioning
        </Button>
      </Tooltip>
    </>
  );
};
