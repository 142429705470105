import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Typography,
  Stack,
  Box,
  Divider,
} from "@mui/material";
import styled from "@emotion/styled";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { useEffect, useState } from "react";
import { enqueueSnackbar } from "notistack";
import { useMutation, useQueryClient } from "react-query";
import { axiosClient } from "src/lib/axiosClient";
import {
  enqueueSavingSnackbar,
  enqueueSuccessSnackbar,
} from "src/shared/snackbar/SnackbarHelper";
import { parseISO } from "date-fns";
import React, { useRef } from "react";
import LinearProgress from "@mui/material/LinearProgress";
import { bytesToSize } from "./SurveyManagement";
import { Uploader } from "./Uploader";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

type NewSurveyModalProps = {
  onClose: VoidFunction;
};

export type Survey = {
  surveyName?: string;
  surveyDate?: string;
  surveyId?: string;
};

export const NewSurveyModal = (props: NewSurveyModalProps) => {
  const { onClose } = props;
  const [survey, setSurvey] = useState<Survey>({});
  const [errors, setErrors] = useState<{
    date?: string;
    name?: string;
    orthomosaic?: string;
  }>({});
  const queryClient = useQueryClient();
  const [uploadProgresses, setUploadProgresses] = useState<{
    [key: string]: {
      type: string;
      fileName: string;
      fileSize: string;
      progress: number;
    };
  }>({});

  const onFileChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    type: string
  ) => {
    if (!e.target.files) return;
    for (const file of e.target.files) {
      setUploadProgresses((prevState) => ({
        ...prevState,
        [file.name]: {
          progress: 0,
          type,
          fileName: file.name,
          fileSize: `${Math.round((file.size / 1024 / 1024) * 100) / 100}MB`,
        },
      }));
      const uploader = new Uploader({
        file,
        metadata: {
          type,
          surveyId: survey.surveyId,
          size: file.size,
          filename: file.name,
        },
      })
        .onProgress(({ percentage }: { percentage: number }) => {
          setUploadProgresses((prevState) => ({
            ...prevState,
            [file.name]: {
              progress: percentage,
              type,
              fileName: file.name,
              fileSize: bytesToSize(file.size),
            },
          }));
        })
        .onComplete(() => {
          !filesUploadedRef.current
            ? (filesUploadedRef.current = 1)
            : (filesUploadedRef.current = filesUploadedRef.current + 1);
          setUploadFinished(true);
          setUploadProgresses((prevState) => ({
            ...prevState,
            [file.name]: {
              progress: 100,
              type,
              fileName: file.name,
              fileSize: bytesToSize(file.size),
            },
          }));
        })
        .onError(() => {
          console.error("upload error");
        });

      uploader.start();
    }
  };

  const orthomosaicFileInput = useRef<HTMLInputElement>(null);
  const pointCloudFileInput = useRef<HTMLInputElement>(null);
  // Bit of a hack, we use the ref to track finished async uploads, and set uploadFinished to trigger re-render
  const filesUploadedRef = useRef<number>();
  const [uploadFinished, setUploadFinished] = useState(true);
  const validate = (surveyToValidate: Survey) => {
    const newErrors: { orthomosaic?: string; name?: string; date?: string } =
      {};
    if (
      Object.values(uploadProgresses).filter((upload) => {
        return upload.type === "orthomosaic";
      }).length === 0
    ) {
      newErrors["orthomosaic"] = "You must add orthomosaics";
    }
    if (!surveyToValidate.surveyName) {
      newErrors["name"] = "You must add a survey name";
    }

    if (!surveyToValidate.surveyDate) {
      newErrors["date"] = "You must add a survey date";
    }
    setErrors(newErrors);
    return Object.values(newErrors).length === 0;
  };

  const { mutate: createSurvey } = useMutation(
    async (survey: Survey): Promise<Survey> => {
      const res = (await axiosClient.post(`/surveys`, survey)).data;
      return res;
    },
    {
      onSuccess: (data) => {
        setSurvey(data);
      },
      onError: () => {
        enqueueSnackbar("Something went wrong", { variant: "error" });
      },
      onSettled: () => {
        queryClient.invalidateQueries({
          queryKey: [`surveys`],
        });
      },
    }
  );

  const { mutate: saveSurvey } = useMutation(
    async (survey: Survey): Promise<Survey> => {
      const finishSaving = enqueueSavingSnackbar();

      const res = (
        await axiosClient.put(`/surveys/${survey.surveyId}`, {
          surveyName: survey.surveyName,
          surveyDate: survey.surveyDate,
          completedUpload: true,
        })
      ).data;
      finishSaving();

      return res;
    },
    {
      onSuccess: () => {
        enqueueSuccessSnackbar();
      },
      onError: () => {
        enqueueSnackbar("Something went wrong", { variant: "error" });
      },
      onSettled: () => {
        queryClient.invalidateQueries({
          queryKey: [`surveys`],
        });
      },
    }
  );

  const { mutate: deleteSurvey } = useMutation(
    async (): Promise<undefined> => {
      const res = (await axiosClient.delete(`/surveys/${survey.surveyId}`))
        .data;
      return res;
    },
    {
      onError: () => {
        enqueueSnackbar("Something went wrong", { variant: "error" });
      },
      onSettled: () => {
        queryClient.invalidateQueries({
          queryKey: [`surveys`],
        });
      },
    }
  );
  useEffect(() => {
    createSurvey({});
  }, []);
  return (
    <Dialog open onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Add Survey</DialogTitle>
      <DialogContent>
        <Box>
          <InfoText>
            <InfoOutlinedIcon sx={{ mr: 1 }} />
            <span>
              Surveys take up to 30 minutes to be processed and available, check
              back here to see when the status changes.
            </span>
          </InfoText>
        </Box>
        <input
          type="file"
          accept="image/tiff, image/jpeg, image/jp2, image/jpg"
          onChange={(e) => onFileChange(e, "orthomosaic")}
          style={{ display: "none" }}
          multiple
          ref={orthomosaicFileInput}
        />
        <input
          type="file"
          accept="application/vnd.las"
          onChange={(e) => onFileChange(e, "pointCloud")}
          style={{ display: "none" }}
          multiple
          ref={pointCloudFileInput}
        />
        <Wrapper>
          <UploadOption
            title={"Upload Orthomosaic"}
            subtitle="Supported formats: .TIFF .JPG .JPEG .JP2"
            buttonText="Upload Imagery"
            onSelect={() => {
              if (orthomosaicFileInput.current) {
                orthomosaicFileInput.current.click();
              }
            }}
            files={Object.values(uploadProgresses).filter((upload) => {
              return upload.type === "orthomosaic";
            })}
          />
          <UploadOption
            title={"Upload Point Cloud (Optional)"}
            subtitle="Supported formats: .LAS .LAZ"
            buttonText="Upload Point Cloud"
            onSelect={() => {
              if (pointCloudFileInput.current) {
                pointCloudFileInput.current.click();
              }
            }}
            files={Object.values(uploadProgresses).filter((upload) => {
              return upload.type === "pointCloud";
            })}
          />
          <TextField
            label="Survey Name"
            placeholder="Try a place name and a date"
            sx={{ width: "100%" }}
            value={survey?.surveyName}
            onChange={(e) => {
              setSurvey({ ...survey, surveyName: e.target.value });
              if (e.target.value) setErrors({ ...errors, name: undefined });
            }}
            error={!!errors.name}
            helperText={errors.name}
          />
          <Typography variant="body2">Survey Date</Typography>
          <DesktopDatePicker
            onChange={(date) => {
              setSurvey({ ...survey, surveyDate: date?.toISOString() });
              if (date) setErrors({ ...errors, date: undefined });
            }}
            value={
              survey?.surveyDate ? parseISO(survey?.surveyDate) : undefined
            }
            sx={{ width: "100%" }}
            slotProps={{
              textField: {
                error: !!errors.date,
                helperText: errors.date,
              },
            }}
          />
        </Wrapper>
      </DialogContent>
      <DialogActions
        sx={{
          display: "flex",
          flexDirection: "column",
          margin: "0 1rem 1rem 1rem",
        }}
      >
        <Button
          variant="contained"
          type="submit"
          form="eventform"
          sx={{ width: "100%", margin: "1rem 0 0 0 !important" }}
          onClick={() => {
            if (validate(survey)) {
              saveSurvey(survey);
              onClose();
            }
          }}
          disabled={
            Object.values(uploadProgresses).length !==
            (filesUploadedRef.current || 0)
          }
        >
          Submit
        </Button>
        <Button
          onClick={() => {
            deleteSurvey();
            onClose();
          }}
          variant="outlined"
          sx={{ width: "100%", margin: "1rem 0 0 0 !important" }}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const Wrapper = styled.div`
  & > :not(:last-child) {
    margin-bottom: 1rem;
  }
`;

type UploadOptionProps = {
  title: string;
  subtitle: string;
  onSelect: VoidFunction;
  buttonText: string;
  files?: { fileName: string; fileSize: string; progress: number }[];
};
export const UploadOption = (props: UploadOptionProps) => {
  const { buttonText, onSelect, subtitle, title, files } = props;
  return (
    <UploadOptionContainer>
      <Stack divider={<Divider />}>
        <UploadOptionAction>
          <UploadOptionText>
            <Typography variant="subtitle2" sx={{ fontWeight: 500 }}>
              {title}
            </Typography>
            <Typography variant="subtitle2">{subtitle}</Typography>
          </UploadOptionText>
          <Button variant="outlined" onClick={onSelect} sx={{ width: 200 }}>
            {buttonText}
          </Button>
        </UploadOptionAction>
        {files?.map((file) => {
          return (
            <div key={file.fileName}>
              <Typography>{file.fileName}</Typography>
              <Typography>{file.fileSize}</Typography>
              {file.progress < 100 && (
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Box sx={{ width: "100%", mr: 1 }}>
                    <LinearProgress
                      variant="determinate"
                      value={file.progress}
                    />
                  </Box>
                  <Box sx={{ minWidth: 35 }}>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                    >{`${Math.round(file.progress)}%`}</Typography>
                  </Box>
                </Box>
              )}
            </div>
          );
        })}
      </Stack>
    </UploadOptionContainer>
  );
};

const UploadOptionAction = styled.div`
  display: flex;
  justify-content: space-between;
`;
const UploadOptionContainer = styled.div`
  border: 1px solid lightgrey;
  border-radius: 4px;
  padding: 1rem;
`;
const UploadOptionText = styled.div``;

const InfoText = styled.div`
  display: flex;
  align-items: center;
  background-color: #d8e9ff;
  border-radius: 12px;
  padding: 0.5rem;
  border: 1px solid black;
  margin-top: 1rem;
  margin-bottom: 1rem;
`;
