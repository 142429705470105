import {
  DataGrid,
  GridCallbackDetails,
  GridColDef,
  GridColumnHeaderParams,
  GridPaginationModel,
  GridSortModel,
} from "@mui/x-data-grid";
import { format } from "date-fns";
import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogTitle,
  Stack,
  SxProps,
  TextField,
  Theme,
} from "@mui/material";
import { GetInsuranceClaim } from "@athena/server/src/trpc/routers/insuranceClaims/schema";
import { trpc } from "src/lib/api/trpc";
import { FC, ReactNode, useCallback, useState } from "react";
import {
  DialogSection,
  DialogSectionContentVertical,
  DialogSectionHeader,
  DialogSectionBody,
  DialogSectionContentHorizontal,
  LoadingSpinner,
} from "@athena/components";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm, Controller } from "react-hook-form";
import { z } from "zod";
import { DataMismatchSection } from "../../DataMismatchSection";
import { insuranceClaimSchema } from "..";

export enum InsuranceClaimsTableColumn {
  ClaimReference = "claimReference",
  PolicyReference = "policyReference",
  Address = "address",
  Updated = "updated",
  Created = "created",
  Status = "status",
  LossAdjuster = "lossAdjuster",
  Insurer = "insurer",
  Issues = "issues",
  AdditionalData = "additionalData",
  Action = "action",
}

interface InsuranceClaimsTableProps {
  claims: Array<z.infer<typeof insuranceClaimSchema>>;
  pagination: GridPaginationModel;
  onPaginationChange: (model: GridPaginationModel) => void;
  sort: GridSortModel;
  onSortChange: (model: GridSortModel) => void;
  rowCount: number;
}

export function InsuranceClaimsTable({
  claims,
  rowCount,
  pagination,
  onPaginationChange,
  sort,
  onSortChange,
}: InsuranceClaimsTableProps) {
  const trpcContext = trpc.useContext();

  const [sortedBy, setSortedBy] = useState<string>("updated");

  const updateClaimMutation = trpc.insuranceClaims.updateClaim.useMutation({
    onSuccess: () => {
      trpcContext.insuranceClaims.invalidate();
    },
  });

  const [editingClaimId, setEditingClaimId] = useState<string | undefined>(
    undefined
  );

  const claimToEdit = claims.find((x) => x.claimReference === editingClaimId);

  const handleSortChange = (
    model: GridSortModel,
    details: GridCallbackDetails
  ) => {
    setSortedBy(model[0].field);
    onSortChange(model);
  };

  const customHeader = (
    params: GridColumnHeaderParams<z.infer<typeof insuranceClaimSchema>>
  ) => {
    const color =
      params.colDef.sortable && params.colDef.field != sortedBy
        ? "#0067df"
        : "#000";
    return <span style={{ color }}>{params.colDef.headerName}</span>;
  };

  const onClaimChangesSaved = useCallback(
    (updates: UpdateInsuranceClaim) => {
      if (!claimToEdit) {
        return;
      }

      const updatedClaim = {
        claimReference: claimToEdit.claimReference,
        ...updates,
      };

      updateClaimMutation.mutate(updatedClaim, {
        onSuccess: () => {
          setEditingClaimId(undefined);
        },
      });
    },
    [setEditingClaimId, claimToEdit, updateClaimMutation]
  );

  const onClaimUpdatesDialogClosed = useCallback(() => {
    setEditingClaimId(undefined);
  }, [setEditingClaimId]);

  const columns: Array<GridColDef<z.infer<typeof insuranceClaimSchema>>> = [
    {
      field: InsuranceClaimsTableColumn.ClaimReference,
      flex: 0.75,
      sortable: false,
      headerName: "Claim Reference",
      renderHeader: customHeader,
    },
    {
      field: InsuranceClaimsTableColumn.PolicyReference,
      flex: 0.75,
      sortable: false,
      headerName: "Policy Number",
      renderHeader: customHeader,
    },
    {
      field: InsuranceClaimsTableColumn.Address,
      flex: 2,
      sortable: false,
      headerName: "Claim",
      valueGetter: (p) => p.row.location.address,
      renderHeader: customHeader,
    },
    {
      field: InsuranceClaimsTableColumn.Updated,
      flex: 0.75,
      headerName: "Last Updated",
      renderHeader: customHeader,
      renderCell: (p) => {
        return (
          <div style={{ paddingLeft: "0.5rem" }}>
            {format(new Date(p.row.created), "h:mma")}
            <br />
            {format(new Date(p.row.created), "dd/MM/yy")}
          </div>
        );
      },
    },
    {
      field: InsuranceClaimsTableColumn.Created,
      flex: 0.75,
      headerName: "Created",
      valueGetter: (p) => format(new Date(p.row.created), "dd/MM/yy"),
      renderHeader: customHeader,
    },
    {
      field: InsuranceClaimsTableColumn.Status,
      flex: 1,
      sortable: false,
      headerName: "Status",
      renderCell: (p) => <Chip label={p.row.status} />,
      renderHeader: customHeader,
    },
    {
      field: InsuranceClaimsTableColumn.LossAdjuster,
      flex: 1,
      sortable: false,
      headerName: "Loss Adjuster",
      valueGetter: (p) => p.row.additionalData?.["Loss Adjuster"] || "NA",
      renderHeader: customHeader,
    },
    // {
    //   field: InsuranceClaimsTableColumn.
    // },
    // {
    //   field: InsuranceClaimsTableColumn.AdditionalData,
    //   sortable: false,
    //   flex: 1.5,
    //   headerName: "Additional Data",
    //   renderCell: (p) => {
    //     return (
    //       <div style={{ padding: "0.5rem 0" }}>
    //         {Object.keys(p.row.additionalData || {}).map((key) => (
    //           <p key={key} style={{ fontSize: "0.8rem" }}>
    //             {key}: {p.row.additionalData?.[key]}
    //           </p>
    //         ))}
    //       </div>
    //     );
    //   },
    // },
    // {
    //   field: InsuranceClaimsTableColumn.Issues,
    //   sortable: false,
    //   flex: 1,
    //   headerName: "Issues",
    //   renderHeader: customHeader,
    //   renderCell: (p) => {
    //     const ifFixingDataMismatch =
    //       Object.values(p.row.issues?.dataMismatch || {}).filter((x) => x)
    //         .length > 0;

    //     const issues: Array<string> = [];
    //     if (ifFixingDataMismatch) {
    //       issues.push("Data mismatch");
    //     }

    //     if (issues.length === 0) {
    //       return null;
    //     }

    //     return (
    //       <>
    //         {issues.map((issue) => (
    //           <Chip key={issue} label={issue} />
    //         ))}
    //       </>
    //     );
    //   },
    // },
    {
      field: InsuranceClaimsTableColumn.Action,
      sortable: false,
      headerName: "Action",
      renderHeader: customHeader,
      renderCell: (p) => {
        const ifFixingDataMismatch =
          Object.values(p.row.issues?.dataMismatch || {}).filter((x) => x)
            .length > 0;

        return (
          <Button
            sx={{ minWidth: "5rem" }}
            variant={ifFixingDataMismatch ? "contained" : "outlined"}
            size="large"
            color="primary"
            onClick={() => setEditingClaimId(p.row.claimReference)}
          >
            {ifFixingDataMismatch ? "Fix" : "Update"}
          </Button>
        );
      },
    },
  ];

  return (
    <>
      <DataGrid
        disableColumnMenu={true}
        rows={claims}
        columns={columns}
        paginationMode="server"
        paginationModel={pagination}
        onPaginationModelChange={onPaginationChange}
        sortingMode="server"
        sortingOrder={["asc", "desc"]}
        sortModel={sort}
        onSortModelChange={handleSortChange}
        getRowId={(row) => row.claimReference}
        getRowHeight={() => "auto"}
        rowCount={rowCount}
        autoHeight={true}
        disableRowSelectionOnClick
        sx={{
          ".MuiDataGrid-columnHeader": {
            borderRight: "1px solid #efefef",
            borderRadius: 0,
            paddingLeft: "1rem",
            fontWeight: "500",
          },
          ".MuiDataGrid-columnHeaders": {
            borderRadius: 0,
          },
          ".MuiDataGrid-columnHeadersInner": {
            backgroundColor: "white",
          },
          ".MuiDataGrid-row:nth-child(odd)": {
            backgroundColor: "white",
          },
          ".MuiDataGrid-row:nth-child(even)": {
            backgroundColor: "#fcfcfc",
          },
          ".MuiDataGrid-footerContainer": {
            backgroundColor: "white",
          },
          ".MuiDataGrid-cellContent": {
            padding: "1.5rem 0.5rem",
          },
          ".MuiDataGrid-cell": {
            borderRight: "1px solid #efefef",
          },
        }}
      />
      {(Object.values(claimToEdit?.issues?.dataMismatch || {}).filter((x) => x)
        .length > 0 && (
        <InsuranceClaimDataMismatchResolver
          claim={claimToEdit}
          onSave={onClaimChangesSaved}
          onClose={onClaimUpdatesDialogClosed}
        />
      )) || (
        <InsuranceClaimEdit
          claim={claimToEdit}
          onSave={onClaimChangesSaved}
          onClose={onClaimUpdatesDialogClosed}
        />
      )}
    </>
  );
}

interface InsuranceClaimEditProps {
  claim?: z.infer<typeof insuranceClaimSchema>;
  onSave: (updates: UpdateInsuranceClaim) => void;
  onClose: () => void;
}

const UpdateInsuranceClaimFormSchema = z.object({
  policyReference: z.string().optional(),
  status: z.string().optional(),
  address: z.string().optional(),
  latitude: z.number().optional(),
  longitude: z.number().optional(),
});

type UpdateInsuranceClaim = z.infer<typeof UpdateInsuranceClaimFormSchema>;

const UpdateTextFieldStyle: SxProps<Theme> = {
  marginTop: "1rem",
  ".MuiInputBase-root": {
    width: "400px",
    height: "48px",
    padding: "6px",
  },
  ".MuiChip-root": {
    height: "28px",
  },
  ".MuiInputBase-input": {},
};

export function InsuranceClaimEdit({
  claim,
  onSave,
  onClose,
}: InsuranceClaimEditProps) {
  const { handleSubmit, control } = useForm<UpdateInsuranceClaim>({
    resolver: zodResolver(UpdateInsuranceClaimFormSchema),
    values: {
      policyReference: claim?.policyReference,
      status: claim?.status,
      address: claim?.location.address,
      longitude: claim?.location.geo.coordinates[0],
      latitude: claim?.location.geo.coordinates[1],
    },
  });

  const [isSaving, setIsSaving] = useState<boolean>(false);

  const onSubmit = useCallback(
    (values: UpdateInsuranceClaim) => {
      setIsSaving(true);
      onSave(values);
    },
    [onSave]
  );

  if (!claim) {
    return;
  }

  return (
    <Dialog
      open={true}
      onClose={onClose}
      fullWidth
      maxWidth="md"
      sx={{
        border: "1px solid lightgray",
        " .MuiPaper-root": {
          overflow: "hidden",
          flexWrap: "nowrap",
        },
      }}
    >
      <DialogTitle sx={{ borderBottom: "1px solid lightgray" }}>
        Edit Insurance Claim
      </DialogTitle>
      {(isSaving && <LoadingSpinner />) || (
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack direction={"column"} sx={{ padding: "1rem" }}>
            <Controller
              render={({ field }) => {
                return (
                  <TextField
                    {...field}
                    sx={UpdateTextFieldStyle}
                    label="Policy Reference"
                  />
                );
              }}
              name={"policyReference"}
              control={control}
            />
            <Controller
              render={({ field }) => {
                return (
                  <TextField
                    {...field}
                    sx={UpdateTextFieldStyle}
                    label="Status"
                  />
                );
              }}
              name={"status"}
              control={control}
            />
            <Controller
              render={({ field }) => {
                return (
                  <TextField
                    {...field}
                    sx={UpdateTextFieldStyle}
                    label="Address"
                  />
                );
              }}
              name={"address"}
              control={control}
            />
            <Controller
              render={({ field }) => {
                const { onChange, ...props } = field;
                return (
                  <TextField
                    {...props}
                    sx={UpdateTextFieldStyle}
                    label="Longitude"
                    onChange={(event) => {
                      const floatValue = parseFloat(
                        event.target.value.toString().replace(/[^\d.]/g, "")
                      );

                      if (!isNaN(floatValue)) {
                        onChange(floatValue);
                      } else {
                        onChange(0);
                      }
                    }}
                  />
                );
              }}
              name={"longitude"}
              control={control}
            />
            <Controller
              render={({ field }) => {
                const { onChange, ...props } = field;
                return (
                  <TextField
                    {...props}
                    sx={UpdateTextFieldStyle}
                    label="Latitude"
                    onChange={(event) => {
                      const floatValue = parseFloat(
                        event.target.value.toString().replace(/[^\d]/g, "")
                      );

                      if (!isNaN(floatValue)) {
                        onChange(floatValue);
                      } else {
                        onChange(0);
                      }
                    }}
                  />
                );
              }}
              name={"latitude"}
              control={control}
            />
          </Stack>
          <DialogActions
            sx={{
              display: "flex",
              flexDirection: "row",
              padding: "1rem",
              borderTop: "1px solid lightgray",
            }}
          >
            <Button type="submit" variant="contained" sx={{ width: "100%" }}>
              Save
            </Button>
            <Button
              onClick={onClose}
              variant="contained"
              sx={{ width: "100%" }}
            >
              Cancel
            </Button>
          </DialogActions>
        </form>
      )}
    </Dialog>
  );
}

interface InsuranceClaimDataMismatchResolverProps {
  claim?: z.infer<typeof insuranceClaimSchema>;
  onSave: (updates: UpdateInsuranceClaim) => void;
  onClose: () => void;
}

export function InsuranceClaimDataMismatchResolver({
  claim,
  onSave,
  onClose,
}: InsuranceClaimDataMismatchResolverProps) {
  const {
    policyReference: newPolicyReference,
    address: newAddress,
    status: newStatus,
    longitude: newLongitude,
    latitude: newLatitude,
  } = claim?.issues?.dataMismatch || {};

  const [updatedPolicyReference, setUpdatedPolicyReference] = useState<
    string | undefined
  >(undefined);

  const [updatedStatus, setUpdatedStatus] = useState<string | undefined>(
    undefined
  );

  const [updatedAddress, setUpdatedAddress] = useState<string | undefined>(
    undefined
  );

  const [updatedLongitude, setUpdatedLongitude] = useState<number | undefined>(
    undefined
  );

  const [updatedLatitude, setUpdatedLatitude] = useState<number | undefined>(
    undefined
  );

  const [isSaving, setIsSaving] = useState<boolean>(false);

  const onSaveHandler = useCallback(() => {
    setIsSaving(true);
    onSave({
      status: updatedStatus,
      address: updatedAddress,
      latitude: updatedLatitude,
      longitude: updatedLongitude,
      policyReference: updatedPolicyReference,
    });
  }, [updatedStatus, updatedAddress]);

  const isPolicyReferenceComplete = !newPolicyReference
    ? true
    : !!updatedPolicyReference;
  const isStatusComplete = !newStatus ? true : !!updatedStatus;
  const isAddressComplete = !newAddress ? true : !!updatedAddress;
  const isLongitudeComplete = !newLongitude ? true : !!updatedLongitude;
  const isLatitudeComplete = !newLatitude ? true : !!updatedLatitude;

  const isComplete =
    isPolicyReferenceComplete &&
    isStatusComplete &&
    isAddressComplete &&
    isLongitudeComplete &&
    isLatitudeComplete;

  if (!claim) {
    return;
  }

  return (
    <Dialog
      open={true}
      onClose={onClose}
      fullWidth
      maxWidth="md"
      sx={{
        border: "1px solid lightgray",
        " .MuiPaper-root": {
          flexWrap: "nowrap",
        },
      }}
    >
      <DialogTitle sx={{ borderBottom: "1px solid lightgray" }}>
        Resolve data mismatch
      </DialogTitle>
      {(isSaving && <LoadingSpinner />) || (
        <Stack direction={"column"} sx={{ padding: "1rem" }}>
          {claim.issues?.dataMismatch?.policyReference &&
            newPolicyReference && (
              <DataMismatchSection
                label="Policy Reference"
                oldValue={claim.policyReference}
                newValue={newPolicyReference}
                selectedValue={updatedPolicyReference}
                onSelect={setUpdatedPolicyReference}
              />
            )}
          {claim.issues?.dataMismatch?.status && newStatus && (
            <DataMismatchSection
              label="Status"
              oldValue={claim.status}
              newValue={newStatus}
              selectedValue={updatedStatus}
              onSelect={setUpdatedStatus}
            />
          )}
          {claim.issues?.dataMismatch?.address && newAddress && (
            <DataMismatchSection
              label="Address"
              oldValue={claim.location.address}
              newValue={newAddress}
              selectedValue={updatedAddress}
              onSelect={setUpdatedAddress}
            />
          )}
          {claim.issues?.dataMismatch?.longitude && newLongitude && (
            <DataMismatchSection
              label="Longitude"
              oldValue={claim.location.geo.coordinates[0]}
              newValue={newLongitude}
              selectedValue={updatedLongitude}
              onSelect={setUpdatedLongitude}
            />
          )}
          {claim.issues?.dataMismatch?.latitude && newLatitude && (
            <DataMismatchSection
              label="Latitude"
              oldValue={claim.location.geo.coordinates[1]}
              newValue={newLatitude}
              selectedValue={updatedLatitude}
              onSelect={setUpdatedLatitude}
            />
          )}
        </Stack>
      )}
      <DialogActions
        sx={{
          display: "flex",
          flexDirection: "row",
          padding: "1rem",
          borderTop: "1px solid lightgray",
        }}
      >
        <Button
          type="submit"
          variant="contained"
          sx={{ width: "100%" }}
          disabled={!isComplete}
          onClick={onSaveHandler}
        >
          Save
        </Button>
        <Button onClick={onClose} variant="contained" sx={{ width: "100%" }}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
