import {
  Paper,
  TextField,
  InputAdornment,
  Button,
  Stack,
  Dialog,
  DialogActions,
  DialogTitle,
  Checkbox,
  FormControlLabel,
  Typography,
  DialogContent,
} from "@mui/material";
import { SetOfficeCapacity } from "@athena/server/src/trpc/routers/offices/schema";
import { useCallback, useState } from "react";
import { trpc } from "src/lib/api/trpc";

import { useCurrentUser } from "src/shared/hooks/useCurrentUser";
import { LoadingSpinner } from "@athena/components";
import {
  addMonths,
  addWeeks,
  formatISO,
  format,
  startOfMonth,
  isBefore,
} from "date-fns";
import { getStartOfWeek } from "src/lib/date";

const FUTURE_WEEKS_AMOUNT = 9;

const getOfficeWeeks = () => {
  const now = new Date();
  let start = getStartOfWeek(now);

  const endDate = addWeeks(now, FUTURE_WEEKS_AMOUNT);
  const weeks: Array<Date> = [];

  while (start <= endDate) {
    weeks.push(start);
    start = getStartOfWeek(addWeeks(start, 1));
  }

  return weeks.map((x) => formatISO(x, { representation: "date" }));
};

export const OfficeCapacity = () => {
  const trpcContext = trpc.useContext();
  const { userData } = useCurrentUser();
  const { organisation } = userData || {};

  const { data: assignedOffices, isLoading: isLoadingAssignedOffices } =
    trpc.offices.getAssignedOffices.useQuery();

  const { data: officeCapacities, isLoading: isLoadingOfficeCapacities } =
    trpc.offices.getOfficeCapacities.useQuery();

  const { data: allInsurers, isLoading: isLoadingInsurers } =
    trpc.organisations.getInsurers.useQuery();

  const saveOfficeCapacityMutation = trpc.offices.setOfficeCapacity.useMutation(
    {
      onSuccess: () => {
        trpcContext.offices.invalidate();
      },
    }
  );

  const [updatedCapacities, setUpdatedCapacities] = useState<
    Array<SetOfficeCapacity>
  >([]);
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const updateCapacity = useCallback(
    (update: SetOfficeCapacity) => {
      const existingCapacity = updatedCapacities.find(
        (x) =>
          x.assignedOfficeId === update.assignedOfficeId &&
          x.insurerId === update.insurerId &&
          x.weekStart === update.weekStart
      );

      if (existingCapacity) {
        existingCapacity.capacity = update.capacity;
      } else {
        updatedCapacities.push(update);
      }

      setUpdatedCapacities([...updatedCapacities]);
    },
    [updatedCapacities, setUpdatedCapacities]
  );

  const getCapacity = useCallback(
    (q: { insurerId: string; assignedOfficeId: string; weekStart: string }) => {
      const updated = updatedCapacities.find(
        (x) =>
          x.assignedOfficeId === q.assignedOfficeId &&
          x.insurerId === q.insurerId &&
          x.weekStart === q.weekStart
      );

      if (updated) {
        return updated.capacity;
      }

      const fromBackend = officeCapacities?.find(
        (x) =>
          x.assignedOfficeId === q.assignedOfficeId &&
          x.insurerId === q.insurerId &&
          x.weekStart === q.weekStart
      );

      if (fromBackend) {
        return fromBackend.capacity;
      }

      return 0;
    },
    [updatedCapacities, officeCapacities]
  );

  const onSave = useCallback(async () => {
    setIsSaving(true);
    await saveOfficeCapacityMutation.mutateAsync(updatedCapacities);
    setIsSaving(false);
    setUpdatedCapacities([]);
  }, [updatedCapacities, setUpdatedCapacities, setIsSaving]);

  const insurers = (allInsurers || []).map((insurer) => ({
    name: insurer.name,
    id: insurer.organisationId,
    isSelected: (organisation?.assignedInsurerIds || []).some(
      (x) => x === insurer.organisationId
    ),
  }));

  const [isSelectingInsurers, setIsSelectingInsurers] =
    useState<boolean>(false);

  const weeks = getOfficeWeeks();

  if (
    isLoadingAssignedOffices ||
    isLoadingOfficeCapacities ||
    isLoadingInsurers ||
    isSaving
  ) {
    return <LoadingSpinner />;
  }

  const canSave = updatedCapacities.length > 0;

  return (
    <div>
      <Paper
        sx={{
          padding: 2,
          mb: 2,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Stack direction={"row"} width={"100%"} justifyContent={"end"}>
          <Button
            variant="outlined"
            onClick={() => setIsSelectingInsurers(true)}
          >
            Edit Insurers
          </Button>
          <Button variant="contained" disabled={!canSave} onClick={onSave}>
            Save Changes
          </Button>
        </Stack>
      </Paper>
      {isSelectingInsurers && (
        <SelectedInsurersDialog
          insurers={insurers}
          onClose={() => setIsSelectingInsurers(false)}
        />
      )}
      {(assignedOffices || [])
        .sort((a, b) => (a.name > b.name ? 1 : 0))
        .map((office) => (
          <Paper
            sx={{
              padding: 2,
              marginBottom: 2,
            }}
            key={office.assignedOfficeId}
          >
            <Typography
              variant="subtitle1"
              sx={{
                borderBottom: "1px solid lightgray",
                paddingBottom: "1rem",
              }}
            >
              {office.name}
            </Typography>
            {insurers
              .filter((x) => x.isSelected)
              .map((insurer) => {
                return (
                  <>
                    <Typography
                      variant="subtitle1"
                      sx={{
                        marginTop: "0.5rem",
                      }}
                    >
                      {insurer.name}
                    </Typography>
                    <Stack
                      direction={"row"}
                      sx={{
                        padding: "1rem",
                        ":not(:last-child)": {
                          borderBottom: "1px solid lightgray",
                        },
                      }}
                    >
                      {weeks.map((week) => {
                        const value = getCapacity({
                          insurerId: insurer.id,
                          assignedOfficeId: office.assignedOfficeId,
                          weekStart: week,
                        });

                        return (
                          <TextField
                            key={week}
                            label={`${format(
                              new Date(week),
                              "dd MMM"
                            )} - ${format(
                              addWeeks(new Date(week), 1),
                              "dd MMM"
                            )}`}
                            variant="outlined"
                            size="small"
                            sx={{ width: "9rem" }}
                            value={value}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  Claims
                                </InputAdornment>
                              ),
                            }}
                            onChange={(event) => {
                              const integerValue = parseInt(
                                event.target.value
                                  .toString()
                                  .replace(/[^\d]/g, "")
                              );
                              if (!isNaN(integerValue)) {
                                updateCapacity({
                                  insurerId: insurer.id,
                                  assignedOfficeId: office.assignedOfficeId,
                                  weekStart: week,
                                  capacity: integerValue,
                                });
                              }
                            }}
                          />
                        );
                      })}
                    </Stack>
                  </>
                );
              })}
          </Paper>
        ))}
    </div>
  );
};

interface InsurerSelection {
  name: string;
  id: string;
  isSelected: boolean;
}

interface SelectedInsurersDialogProps {
  insurers: Array<InsurerSelection>;
  onClose: () => void;
}

export function SelectedInsurersDialog({
  insurers,
  onClose,
}: SelectedInsurersDialogProps) {
  const trpcContext = trpc.useContext();
  const saveAssignedInsurersMutation =
    trpc.settings.setAssignedInsurers.useMutation({
      onSuccess: () => {
        trpcContext.user.invalidate();
      },
    });

  const [selectedInsurers, setSelectedInsurers] = useState(insurers);
  const onSelectInsurer = useCallback(
    (insurerId: string, value: boolean) => {
      const insurer = selectedInsurers.find((x) => x.id === insurerId);
      if (!insurer) {
        return;
      }

      insurer.isSelected = value;
      setSelectedInsurers([...selectedInsurers]);
    },
    [selectedInsurers]
  );

  const [isSaving, setIsSaving] = useState<boolean>(false);

  const onClickSave = useCallback(async () => {
    setIsSaving(true);
    const insurers = selectedInsurers
      .filter((x) => x.isSelected)
      .map((x) => x.id);

    await saveAssignedInsurersMutation.mutateAsync({
      assignedInsurerIds: insurers,
    });
    setIsSaving(false);
    onClose();
  }, [selectedInsurers]);

  return (
    <Dialog open={true} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle>Update selected insurers</DialogTitle>
      <DialogContent>
        {(isSaving && <LoadingSpinner />) || (
          <Stack direction={"column"} sx={{ padding: "1rem" }}>
            {selectedInsurers.map((insurer) => (
              <FormControlLabel
                key={insurer.id}
                control={
                  <Checkbox
                    checked={insurer.isSelected}
                    onChange={(event) => {
                      onSelectInsurer(insurer.id, event.target.checked);
                    }}
                  />
                }
                label={insurer.name}
              />
            ))}
          </Stack>
        )}
      </DialogContent>
      <DialogActions
        sx={{
          display: "flex",
          flexDirection: "column",
          margin: "0 1rem 1rem 1rem",
        }}
      >
        <Button
          variant="contained"
          sx={{ width: "100%", margin: "1rem 0 0 0 !important" }}
          onClick={onClickSave}
        >
          Save
        </Button>
        <Button
          onClick={onClose}
          variant="outlined"
          sx={{ width: "100%", margin: "1rem 0 0 0 !important" }}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
