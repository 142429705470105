import {
  DataGrid,
  GridCallbackDetails,
  GridColDef,
  GridColumnHeaderParams,
  GridSortModel,
} from "@mui/x-data-grid";
import { format } from "date-fns";
import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogTitle,
  Stack,
  SxProps,
  TextField,
  Theme,
} from "@mui/material";
import { GetInsurancePolicy } from "@athena/server/src/trpc/routers/insurancePolicies/schema";
import { useCallback, useState } from "react";
import { z } from "zod";
import { Controller, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { trpc } from "src/lib/api/trpc";
import {
  DialogSection,
  DialogSectionBody,
  DialogSectionContentHorizontal,
  DialogSectionContentVertical,
  DialogSectionHeader,
} from "@athena/components/Dialog";
import { LoadingSpinner } from "@athena/components";
import { DataMismatchSection } from "../../DataMismatchSection";
import { formatDate, formatDateTime } from "src/lib/date";

export enum InsurancePoliciesTableColumn {
  PolicyReference = "policyReference",
  Address = "address",
  Updated = "updated",
  Created = "created",
  Status = "status",
  Issues = "issues",
  AdditionalData = "additionalData",
  Action = "action",
}

interface InsurancePoliciesTableProps {
  policies: Array<GetInsurancePolicy>;
  pageSize?: number;
}

export function InsurancePoliciesTable({
  policies,
  pageSize = 5,
}: InsurancePoliciesTableProps) {
  const trpcContext = trpc.useContext();
  const [sortedBy, setSortedBy] = useState<string>("");

  const updatePolicyMutation = trpc.insurancePolicies.updatePolicy.useMutation({
    onSuccess: () => {
      trpcContext.insurancePolicies.invalidate();
    },
  });

  const [editingPolicyId, setEditingPolicyId] = useState<string | undefined>(
    undefined
  );

  const policyToEdit = policies.find(
    (x) => x.policyReference === editingPolicyId
  );

  const onPolicyChangesSaved = useCallback(
    (updates: UpdateInsurancePolicy) => {
      if (!policyToEdit) {
        return;
      }

      const updatedPolicy = {
        policyReference: policyToEdit.policyReference,
        ...updates,
      };

      updatePolicyMutation.mutate(updatedPolicy, {
        onSuccess: () => {
          setEditingPolicyId(undefined);
        },
      });
    },
    [setEditingPolicyId, policyToEdit, updatePolicyMutation]
  );

  const onPolicyUpdatesDialogClosed = useCallback(() => {
    setEditingPolicyId(undefined);
  }, [setEditingPolicyId]);

  const handleSortChange = (
    model: GridSortModel,
    details: GridCallbackDetails
  ) => {
    setSortedBy(model[0].field);
  };

  const customHeader = (params: GridColumnHeaderParams<GetInsurancePolicy>) => {
    const color =
      params.colDef.sortable && params.colDef.field != sortedBy
        ? "#0067df"
        : "#000";
    return <span style={{ color }}>{params.colDef.headerName}</span>;
  };

  const columns: GridColDef<GetInsurancePolicy>[] = [
    {
      field: InsurancePoliciesTableColumn.PolicyReference,
      flex: 0.75,
      headerName: "Policy Reference",
      renderHeader: customHeader,
    },
    {
      field: InsurancePoliciesTableColumn.Address,
      flex: 2,
      sortable: false,
      headerName: "Address",
      renderHeader: customHeader,
      valueGetter: (p) => p.row.location.address,
    },
    {
      field: InsurancePoliciesTableColumn.Updated,
      flex: 0.75,
      headerName: "Last Updated",
      renderHeader: customHeader,
      renderCell: (p) => {
        return (
          <div style={{ paddingLeft: "0.5rem" }}>
            {format(new Date(p.row.created), "h:mma")}
            <br />
            {format(new Date(p.row.created), "dd/MM/yy")}
          </div>
        );
      },
    },
    {
      field: InsurancePoliciesTableColumn.Created,
      flex: 0.75,
      headerName: "Created",
      renderHeader: customHeader,
      valueGetter: (p) => formatDate(p.row.updated),
    },
    {
      field: InsurancePoliciesTableColumn.Status,
      flex: 1,
      headerName: "Status",
      renderHeader: customHeader,
      renderCell: (p) => <Chip label={p.row.status} />,
    },
    // {
    //   field: InsurancePoliciesTableColumn.AdditionalData,
    //   flex: 1.5,
    //   headerName: "Additional Data",
    //   renderHeader: customHeader,
    //   renderCell: (p) => {
    //     return (
    //       <div style={{ padding: "0.5rem 0" }}>
    //         {Object.keys(p.row.additionalData).map((key) => (
    //           <p key={key} style={{ fontSize: "0.8rem" }}>
    //             {key}: {p.row.additionalData[key]}
    //           </p>
    //         ))}
    //       </div>
    //     );
    //   },
    // },
    {
      field: InsurancePoliciesTableColumn.Issues,
      flex: 1,
      headerName: "Issues",
      renderHeader: customHeader,
      renderCell: (p) => {
        const ifFixingDataMismatch =
          Object.keys(p.row.issues?.dataMismatch || {}).length > 0;

        const issues: Array<string> = [];
        if (ifFixingDataMismatch) {
          issues.push("Data mismatch");
        }

        if (issues.length === 0) {
          return null;
        }

        return (
          <>
            {issues.map((issue) => (
              <Chip key={issue} label={issue} />
            ))}
          </>
        );
      },
    },
    {
      field: InsurancePoliciesTableColumn.Action,
      sortable: false,
      headerName: "Action",
      renderHeader: customHeader,
      renderCell: (p) => {
        const ifFixingDataMismatch =
          Object.keys(p.row.issues?.dataMismatch || {}).length > 0;

        return (
          <Button
            sx={{ minWidth: "5rem" }}
            variant="outlined"
            size="large"
            onClick={() => setEditingPolicyId(p.row.policyReference)}
          >
            {ifFixingDataMismatch ? "Fix" : "Update"}
          </Button>
        );
      },
    },
  ];

  return (
    <>
      <DataGrid
        disableColumnMenu={true}
        rows={policies}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize,
            },
          },
        }}
        getRowId={(row) => row.policyReference}
        getRowHeight={() => "auto"}
        autoHeight={true}
        onSortModelChange={handleSortChange}
        disableRowSelectionOnClick
        sx={{
          borderRadius: 0,
          ".MuiDataGrid-columnHeader": {
            borderRight: "1px solid #efefef",
            borderRadius: 0,
            paddingLeft: "1rem",
            fontWeight: "500",
          },
          ".MuiDataGrid-columnHeaders": {
            borderRadius: 0,
          },
          ".MuiDataGrid-columnHeadersInner": {
            backgroundColor: "white",
          },
          ".MuiDataGrid-row:nth-child(odd)": {
            backgroundColor: "white",
          },
          ".MuiDataGrid-row:nth-child(even)": {
            backgroundColor: "#fcfcfc",
          },
          ".MuiDataGrid-footerContainer": {
            backgroundColor: "white",
          },
          ".MuiDataGrid-cellContent": {
            padding: "1.5rem 0.5rem",
          },
          ".MuiDataGrid-cell": {
            borderRight: "1px solid #efefef",
          },
        }}
      />
      {(Object.keys(policyToEdit?.issues?.dataMismatch || {}).length > 0 && (
        <InsurancePolicyDataMismatchResolver
          policy={policyToEdit}
          onSave={onPolicyChangesSaved}
          onClose={onPolicyUpdatesDialogClosed}
        />
      )) || (
        <InsurancePolicyEdit
          policy={policyToEdit}
          onSave={onPolicyChangesSaved}
          onClose={onPolicyUpdatesDialogClosed}
        />
      )}
    </>
  );
}

interface InsurancePolicyEditProps {
  policy?: GetInsurancePolicy;
  onSave: (updates: UpdateInsurancePolicy) => void;
  onClose: () => void;
}

const UpdateInsurancePolicyFormSchema = z.object({
  status: z.string().optional(),
  address: z.string().optional(),
  latitude: z.number().optional(),
  longitude: z.number().optional(),
});

type UpdateInsurancePolicy = z.infer<typeof UpdateInsurancePolicyFormSchema>;

const UpdateTextFieldStyle: SxProps<Theme> = {
  marginTop: "1rem",
  ".MuiInputBase-root": {
    width: "400px",
    height: "48px",
    padding: "6px",
  },
  ".MuiChip-root": {
    height: "28px",
  },
  ".MuiInputBase-input": {},
};

export function InsurancePolicyEdit({
  policy,
  onSave,
  onClose,
}: InsurancePolicyEditProps) {
  const { handleSubmit, control } = useForm<UpdateInsurancePolicy>({
    resolver: zodResolver(UpdateInsurancePolicyFormSchema),
    values: {
      status: policy?.status,
      address: policy?.location.address,
      longitude: policy?.location.geo.coordinates[0],
      latitude: policy?.location.geo.coordinates[1],
    },
  });

  const [isSaving, setIsSaving] = useState<boolean>(false);

  const onSubmit = useCallback(
    (values: UpdateInsurancePolicy) => {
      setIsSaving(true);
      onSave(values);
    },
    [onSave]
  );

  if (!policy) {
    return;
  }

  return (
    <Dialog
      open={true}
      onClose={onClose}
      fullWidth
      maxWidth="md"
      sx={{
        border: "1px solid lightgray",
        " .MuiPaper-root": {
          flexWrap: "nowrap",
        },
      }}
    >
      <DialogTitle sx={{ borderBottom: "1px solid lightgray" }}>
        Edit Policy
      </DialogTitle>
      {(isSaving && <LoadingSpinner />) || (
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack direction={"column"} sx={{ padding: "1rem" }}>
            <Controller
              render={({ field }) => {
                return (
                  <TextField
                    {...field}
                    sx={UpdateTextFieldStyle}
                    label="Status"
                  />
                );
              }}
              name={"status"}
              control={control}
            />
            <Controller
              render={({ field }) => {
                return (
                  <TextField
                    {...field}
                    sx={UpdateTextFieldStyle}
                    label="Address"
                  />
                );
              }}
              name={"address"}
              control={control}
            />
            <Controller
              render={({ field }) => {
                const { onChange, ...props } = field;
                return (
                  <TextField
                    {...props}
                    sx={UpdateTextFieldStyle}
                    label="Longitude"
                    onChange={(event) => {
                      const floatValue = parseFloat(
                        event.target.value.toString().replace(/[^\d.]/g, "")
                      );

                      if (!isNaN(floatValue)) {
                        onChange(floatValue);
                      } else {
                        onChange(0);
                      }
                    }}
                  />
                );
              }}
              name={"longitude"}
              control={control}
            />
            <Controller
              render={({ field }) => {
                const { onChange, ...props } = field;
                return (
                  <TextField
                    {...props}
                    sx={UpdateTextFieldStyle}
                    label="Latitude"
                    onChange={(event) => {
                      const floatValue = parseFloat(
                        event.target.value.toString().replace(/[^\d]/g, "")
                      );

                      if (!isNaN(floatValue)) {
                        onChange(floatValue);
                      } else {
                        onChange(0);
                      }
                    }}
                  />
                );
              }}
              name={"latitude"}
              control={control}
            />
          </Stack>
          <DialogActions
            sx={{
              display: "flex",
              flexDirection: "row",
              padding: "1rem",
              borderTop: "1px solid lightgray",
            }}
          >
            <Button type="submit" variant="contained" sx={{ width: "100%" }}>
              Save
            </Button>
            <Button
              onClick={onClose}
              variant="contained"
              sx={{ width: "100%" }}
            >
              Cancel
            </Button>
          </DialogActions>
        </form>
      )}
    </Dialog>
  );
}

interface InsurancePolicyDataMismatchResolverProps {
  policy?: GetInsurancePolicy;
  onSave: (updates: UpdateInsurancePolicy) => void;
  onClose: () => void;
}

export function InsurancePolicyDataMismatchResolver({
  policy,
  onSave,
  onClose,
}: InsurancePolicyDataMismatchResolverProps) {
  const {
    address: newAddress,
    status: newStatus,
    longitude: newLongitude,
    latitude: newLatitude,
  } = policy?.issues?.dataMismatch || {};

  const [updatedStatus, setUpdatedStatus] = useState<string | undefined>(
    undefined
  );

  const [updatedAddress, setUpdatedAddress] = useState<string | undefined>(
    undefined
  );

  const [updatedLongitude, setUpdatedLongitude] = useState<number | undefined>(
    undefined
  );

  const [updatedLatitude, setUpdatedLatitude] = useState<number | undefined>(
    undefined
  );

  const [isSaving, setIsSaving] = useState<boolean>(false);

  const onSaveHandler = useCallback(() => {
    setIsSaving(true);
    onSave({
      status: updatedStatus,
      address: updatedAddress,
      latitude: updatedLatitude,
      longitude: updatedLongitude,
    });
  }, [updatedStatus, updatedAddress]);

  const isStatusComplete = newStatus === undefined ? true : !!updatedStatus;
  const isAddressComplete = newAddress === undefined ? true : !!updatedAddress;
  const isLongitudeComplete =
    newLongitude === undefined ? true : !!updatedLongitude;
  const isLatitudeComplete =
    newLatitude === undefined ? true : !!updatedLatitude;

  const isComplete =
    isStatusComplete &&
    isAddressComplete &&
    isLongitudeComplete &&
    isLatitudeComplete;

  if (!policy) {
    return;
  }

  return (
    <Dialog
      open={true}
      onClose={onClose}
      fullWidth
      maxWidth="md"
      sx={{
        border: "1px solid lightgray",
        " .MuiPaper-root": {
          flexWrap: "nowrap",
        },
      }}
    >
      <DialogTitle sx={{ borderBottom: "1px solid lightgray" }}>
        Resolve data mismatch
      </DialogTitle>
      {(isSaving && <LoadingSpinner />) || (
        <Stack direction={"column"} sx={{ padding: "1rem" }}>
          {policy.issues?.dataMismatch?.status && newStatus && (
            <DataMismatchSection
              label="Status"
              oldValue={policy.status}
              newValue={newStatus}
              selectedValue={updatedStatus}
              onSelect={setUpdatedStatus}
            />
          )}
          {policy.issues?.dataMismatch?.address && newAddress && (
            <DataMismatchSection
              label="Address"
              oldValue={policy.location.address}
              newValue={newAddress}
              selectedValue={updatedAddress}
              onSelect={setUpdatedAddress}
            />
          )}
          {policy.issues?.dataMismatch?.longitude && newLongitude && (
            <DataMismatchSection
              label="Longitude"
              oldValue={policy.location.geo.coordinates[0]}
              newValue={newLongitude}
              selectedValue={updatedLongitude}
              onSelect={setUpdatedLongitude}
            />
          )}
          {policy.issues?.dataMismatch?.latitude && newLatitude && (
            <DataMismatchSection
              label="Latitude"
              oldValue={policy.location.geo.coordinates[1]}
              newValue={newLatitude}
              selectedValue={updatedLatitude}
              onSelect={setUpdatedLatitude}
            />
          )}
        </Stack>
      )}
      <DialogActions
        sx={{
          display: "flex",
          flexDirection: "row",
          padding: "1rem",
          borderTop: "1px solid lightgray",
        }}
      >
        <Button
          type="submit"
          variant="contained"
          sx={{ width: "100%" }}
          disabled={!isComplete}
          onClick={onSaveHandler}
        >
          Save
        </Button>
        <Button onClick={onClose} variant="contained" sx={{ width: "100%" }}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
