import { LoadingSpinner } from "@athena/components";
import { Typography } from "@mui/material";
import { Box } from "@mui/system";

export const ChartLoader = () => {
  return (
    <Box
      sx={{
        position: "absolute",
        display: "flex",
        justifyContent: "center",
        width: "100%",
        height: 250,
      }}
    >
      <LoadingSpinner />
    </Box>
  );
};

export const ChartComingSoon = () => {
  return (
    <Box
      sx={{
        position: "absolute",
        display: "flex",
        justifyContent: "center",
        placeItems: "center",
        zIndex: 10,
        width: "100%",
        height: "80%",
      }}
    >
      <Typography
        variant="h1"
        sx={{
          padding: "1rem",
          borderRadius: "1rem",
          color: "#989898",
          border: "0.5rem solid #989898",
          opacity: 0.5,
        }}
      >
        Coming soon!
      </Typography>
    </Box>
  );
};
