import { FormControl, InputLabel, Select, SxProps } from "@mui/material";
import { ReactElement } from "react";
import {
  Control,
  Controller,
  FieldValues,
  Path,
  PathValue,
} from "react-hook-form";
import { SelectChangeEvent, SelectProps } from "@mui/material/Select/Select";

interface FormSelectProps<T extends FieldValues>
  extends Pick<SelectProps<T>, "onBlur"> {
  name: Path<T>;
  control: Control<T>;
  label?: string;
  placeholder?: string;
  defaultValue?: PathValue<T, Path<T>> | undefined;
  children: ReactElement[];
  sx?: SxProps;
  error?: boolean;
  onChange?: (e: SelectChangeEvent) => void;
}

export function FormSelect<T extends FieldValues>(props: FormSelectProps<T>) {
  const {
    control,
    label,
    name,
    placeholder,
    defaultValue,
    children,
    sx,
    error,
    onBlur,
    onChange,
  } = props;
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field }) => (
        <FormControl size="small" sx={sx}>
          <InputLabel id={`${name}-label`} shrink>
            {label}
          </InputLabel>
          <Select
            id={name}
            variant="outlined"
            size="small"
            placeholder={placeholder}
            displayEmpty
            error={error}
            {...field}
            onChange={(e) => {
              field.onChange(e);
              onChange?.(e);
            }}
            onBlur={(e) => {
              field.onBlur();
              onBlur?.(e);
            }}
          >
            {children}
          </Select>
        </FormControl>
      )}
    />
  );
}
