import { ErrorOutline } from "@mui/icons-material";
import { Chip } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { IconOnlyChip } from "@athena/components";
import {
  Status,
  underClientReviewStatuses,
  AdditionalStatus,
  underInternalReviewStatuses,
  preVisitStatuses,
  postVisitStatuses,
} from "@athena/server/src/api/types/claimStatuses";
import DirectionsWalkIcon from "@mui/icons-material/DirectionsWalk";
import ReportIcon from "@mui/icons-material/Report";
import UpdateIcon from "@mui/icons-material/Update";
import styled from "@emotion/styled";
import { Urgency } from "@athena/server/src/api/types/claim";
import PendingActionsIcon from "@mui/icons-material/PendingActions";

type ClaimStatusChipsProps = {
  status: Status;
  additionalStatuses: AdditionalStatus[];
  size?: "small" | "medium";
  urgency?: Urgency;
  onStatusClick?: (status: string) => void;
  onAdditionalStatusClick?: (additionalStatus: string) => void;
  onUrgencyClick?: (urgency: string) => void;
};

export const getChipColor = (status: Status) => {
  if (preVisitStatuses.includes(status)) {
    return "#E8E8E8";
  }

  if (postVisitStatuses.includes(status)) {
    return "#E8E8E8";
  }

  if (underInternalReviewStatuses.includes(status)) {
    return "#D8EAFF";
  }

  if (underClientReviewStatuses.includes(status)) {
    return "#D8EAFF";
  }

  if (status === Status.Done) {
    return "#D7FFD7";
  }
};

export function ClaimStatusChips(props: ClaimStatusChipsProps) {
  const {
    status,
    additionalStatuses = [],
    size = "medium",
    onAdditionalStatusClick,
    onStatusClick,
    onUrgencyClick,
    urgency,
  } = props;
  return (
    <ChipContainer>
      <Chip
        label={status}
        size={size}
        sx={{ backgroundColor: getChipColor(status as Status) }}
        onClick={
          onStatusClick
            ? () => {
                onStatusClick(status);
              }
            : undefined
        }
      />
      {additionalStatuses.includes(
        AdditionalStatus.AdditionalDetailsRequested
      ) && (
        <Tooltip title={AdditionalStatus.AdditionalDetailsRequested}>
          <span>
            <IconOnlyChip
              icon={<HelpOutlineIcon />}
              sx={{ width: "2rem" }}
              size={size}
              onClick={
                onAdditionalStatusClick
                  ? () => {
                      onAdditionalStatusClick?.(
                        AdditionalStatus.AdditionalDetailsRequested
                      );
                    }
                  : undefined
              }
            />
          </span>
        </Tooltip>
      )}
      {additionalStatuses.includes(AdditionalStatus.SiteRevisit) && (
        <Tooltip title={AdditionalStatus.SiteRevisit}>
          <span>
            <IconOnlyChip
              icon={<DirectionsWalkIcon />}
              sx={{ width: "2rem" }}
              size={size}
              onClick={
                onAdditionalStatusClick
                  ? () => {
                      onAdditionalStatusClick?.(AdditionalStatus.SiteRevisit);
                    }
                  : undefined
              }
            />
          </span>
        </Tooltip>
      )}
      {additionalStatuses.includes(AdditionalStatus.DetailsIncomplete) && (
        <Tooltip title={AdditionalStatus.DetailsIncomplete}>
          <span>
            <IconOnlyChip
              icon={<PendingActionsIcon />}
              sx={{ width: "2rem" }}
              size={size}
              onClick={
                onAdditionalStatusClick
                  ? () => {
                      onAdditionalStatusClick?.(
                        AdditionalStatus.DetailsIncomplete
                      );
                    }
                  : undefined
              }
            />
          </span>
        </Tooltip>
      )}
      {additionalStatuses.includes(AdditionalStatus.TimeExempt) && (
        <Tooltip title={AdditionalStatus.TimeExempt}>
          <span>
            <IconOnlyChip
              icon={<UpdateIcon sx={{ fill: "#7a7a7a" }} />}
              sx={{ width: "2rem" }}
              size={size}
              onClick={
                onAdditionalStatusClick
                  ? () => {
                      onAdditionalStatusClick?.(AdditionalStatus.TimeExempt);
                    }
                  : undefined
              }
            />
          </span>
        </Tooltip>
      )}
      {additionalStatuses.includes(
        AdditionalStatus.AdditionalRequestFromClient
      ) && (
        <Tooltip title={AdditionalStatus.AdditionalRequestFromClient}>
          <span>
            <IconOnlyChip
              icon={<ErrorOutline />}
              sx={{ width: "2rem" }}
              size={size}
              onClick={
                onAdditionalStatusClick
                  ? () => {
                      onAdditionalStatusClick?.(
                        AdditionalStatus.AdditionalRequestFromClient
                      );
                    }
                  : undefined
              }
            />
          </span>
        </Tooltip>
      )}
      {additionalStatuses.includes(AdditionalStatus.ConflictOfInterest) && (
        <Tooltip title={AdditionalStatus.ConflictOfInterest}>
          <span>
            <IconOnlyChip
              icon={<ReportIcon />}
              sx={{ width: "2rem" }}
              size={size}
              onClick={
                onAdditionalStatusClick
                  ? () => {
                      onAdditionalStatusClick?.(
                        AdditionalStatus.ConflictOfInterest
                      );
                    }
                  : undefined
              }
            />
          </span>
        </Tooltip>
      )}
      {additionalStatuses.includes(AdditionalStatus.Overdue) && (
        <Tooltip title={AdditionalStatus.Overdue}>
          <span>
            <IconOnlyChip
              icon={<AccessTimeIcon />}
              sx={{ width: "2rem" }}
              size={size}
              onClick={
                onAdditionalStatusClick
                  ? () => {
                      onAdditionalStatusClick?.(AdditionalStatus.Overdue);
                    }
                  : undefined
              }
            />
          </span>
        </Tooltip>
      )}
      {additionalStatuses.includes(AdditionalStatus.AutoOverdue) && (
        <Tooltip title={AdditionalStatus.Overdue}>
          <span>
            <IconOnlyChip
              icon={<AccessTimeIcon />}
              sx={{ width: "2rem" }}
              size={size}
              onClick={
                onAdditionalStatusClick
                  ? () => {
                      onAdditionalStatusClick?.(AdditionalStatus.AutoOverdue);
                    }
                  : undefined
              }
            />
          </span>
        </Tooltip>
      )}
      {urgency === Urgency.Urgent && (
        <Tooltip title={"Urgent"}>
          <span>
            <IconOnlyChip
              icon={
                <AccessTimeIcon sx={{ color: "#ffffff" }} color="primary" />
              }
              sx={{ width: "2rem", backgroundColor: "red" }}
              size={size}
              onClick={
                onAdditionalStatusClick
                  ? () => {
                      onUrgencyClick?.(Urgency.Urgent);
                    }
                  : undefined
              }
            />
          </span>
        </Tooltip>
      )}
    </ChipContainer>
  );
}

const ChipContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  flex-wrap: nowrap;
  @media (max-width: 600px) {
    flex-wrap: wrap;
    div:last-child {
      order: -1;
    }
  }
`;
