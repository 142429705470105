import { Urgency } from "@athena/server/src/api/types/claim";
import { Status } from "@athena/server/src/api/types/claimStatuses";
import { RiskTypes } from "src/modules/claims/Maps/Config";
import z from "zod";
import { GeoJsonCollectionName } from "./constants";

export enum InsuredState {
  Insured8m = "8m",
  Insured60m = "60m",
}

export enum DamageType {
  Cracked = "Cracked",
  Rotated = "Rotated",
  Spalling = "Spalling",
  Squashed = "Squashed",
  Disconnected = "Disconnected",
  Displaced = "Displaced",
  ImpactDamage = "Impact damage",
  Other = "Other",
}

export enum StructureClass {
  Dwelling = "dwelling",
  Appurtenant = "appurtenant",
}

export enum DamageSourceType {
  Landslip = "LANDSLIP",
  StormAndFlood = "STORMANDFLOOD",
}

const baseFeaturePropertiesSchema = z.object({
  feature_id: z.string(),
  claim_id: z.string(),
  engineering_org_id: z.string(),
  created: z.date({ coerce: true }),
  updated: z.date({ coerce: true }),
});

export type BaseFeatureProperties = z.infer<typeof baseFeaturePropertiesSchema>;

const lineSchema = z.object({
  length: z.number(),
});

const polylineSchema = z.object({
  area: z.number(),
});

export const geoJsonCollectionSchemas = {
  [GeoJsonCollectionName.EvacuatedLandAreaV1]: baseFeaturePropertiesSchema
    .merge(polylineSchema)
    .merge(
      z.object({
        insured_state: z.nativeEnum(InsuredState).nullable(),
        damage_source_id: z.string(),
        damage_source_type: z.nativeEnum(DamageSourceType).nullable(),
        evacuated_land: z.number({ coerce: true }),
        sixty_m_evacuated_land: z.number({ coerce: true }),
        reference: z.string().nullable(),
        insurer_reference: z.string().nullable(),
      })
    ),
  [GeoJsonCollectionName.ClaimPointV1]: baseFeaturePropertiesSchema.merge(
    z.object({
      claim_name: z.string().nullable(),
      claim_address: z.string().nullable(),
      reference: z.string().nullable(),
      insurer_reference: z.string().nullable(),
      claim_status: z.nativeEnum(Status).nullable(),
      urgency: z.nativeEnum(Urgency).nullable(),
      completed: z.boolean().nullable(),
      health_and_safety: z.string().nullable(),
      insurer_name: z.string().nullable(),
      insurer_id: z.string().nullable(),
      loss_adjuster_name: z.string().nullable(),
      engineering_company: z.string(),
      engineering_org_id: z.string().nullable(),
      loss_cause_name: z.string().nullable(),
      assigned_office: z.string().nullable(),
      claim_age: z.number(),
      regional_coordinator_name: z.string().nullable(),
      service_request_date: z.date({ coerce: true }).nullable(),
      assigned_engineers: z.array(
        z.object({
          user_name: z.string(),
          accepted: z.boolean().nullable(),
        })
      ),
      events: z.array(
        z.object({
          date_of_event: z.date({ coerce: true }),
          event_name: z.string(),
        })
      ),
    })
  ),
  [GeoJsonCollectionName.DroneSurveyAreaV1]: z.object({
    survey_id: z.string(),
    survey_name: z.string(),
    survey_date: z.date({ coerce: true }),
  }),
  [GeoJsonCollectionName.InsurerClaimPointV1]: z.object({
    feature_id: z.string(),
    policy_number: z.string(),
    claim_number: z.string(),
    claim_address: z.string(),
    claim_status: z.string(),
    claim_created: z.date({ coerce: true }),
  }),
  [GeoJsonCollectionName.InsurerPolicyPointV1]: z.object({
    feature_id: z.string(),
    policy_number: z.string(),
    policy_address: z.string(),
    policy_start_date: z.date({ coerce: true }),
  }),
  [GeoJsonCollectionName.AccesswayAreaV1]:
    baseFeaturePropertiesSchema.merge(polylineSchema),
  [GeoJsonCollectionName.AppurtenantStructureAreaV1]:
    baseFeaturePropertiesSchema.merge(polylineSchema).merge(
      z.object({
        name: z.string(),
        structure_type: z.string(),
      })
    ),
  [GeoJsonCollectionName.AppurtenantStructurePointV1]:
    baseFeaturePropertiesSchema.merge(
      z.object({
        name: z.string(),
        structure_type: z.string(),
      })
    ),
  [GeoJsonCollectionName.HeadscarpLineV1]: baseFeaturePropertiesSchema
    .merge(lineSchema)
    .merge(
      z.object({
        damage_source_id: z.string(),
        landslip_name: z.string().nullable(),
        material: z.string().nullable(),
        mechanism_of_failure: z.string().nullable(),
        slope_form: z.string().nullable(),
        landslip_width: z.number({ coerce: true }).nullable(),
        landslip_length: z.number({ coerce: true }).nullable(),
        headscarp_height: z.number({ coerce: true }).nullable(),
      })
    ),
  [GeoJsonCollectionName.ImminentRiskAreaV1]: baseFeaturePropertiesSchema
    .merge(polylineSchema)
    .merge(
      z.object({
        insured_state: z.nativeEnum(InsuredState).nullable(),
        damage_source_id: z.string(),
        risk_type: z.nativeEnum(RiskTypes),
        imminent_evacuated_land: z.number({ coerce: true }).nullable(),
        imminent_risk_of_inundation: z.number({ coerce: true }).nullable(),
        imminent_risk_of_inundation_approx_volume_debris: z
          .number({ coerce: true })
          .nullable(),
        imminent_risk_of_new_inundation: z.number({ coerce: true }).nullable(),
        imminent_risk_of_new_inundation_approx_volume_debris: z
          .number({ coerce: true })
          .nullable(),
        sixty_m_imnt_evacuated_land: z.number({ coerce: true }).nullable(),
        sixty_m_imnt_risk_of_inundation: z.number({ coerce: true }).nullable(),
        sixty_m_imnt_risk_of_inundation_approx_volume_debris: z
          .number({ coerce: true })
          .nullable(),
        sixty_m_imnt_risk_of_new_inundation: z
          .number({ coerce: true })
          .nullable(),
        sixty_m_imnt_risk_of_new_inundation_approx_volume_debris: z
          .number({ coerce: true })
          .nullable(),
        reference: z.string().nullable(),
        insurer_reference: z.string().nullable(),
        damage_source_type: z.literal(DamageSourceType.Landslip).nullable(),
      })
    ),
  [GeoJsonCollectionName.InsuredLandAreaV1]: baseFeaturePropertiesSchema
    .merge(polylineSchema)
    .merge(
      z.object({
        insured_area_type: z.nativeEnum(InsuredState).nullable(),
      })
    ),
  [GeoJsonCollectionName.InundatedLandAreaV1]: baseFeaturePropertiesSchema
    .merge(polylineSchema)
    .merge(
      z.object({
        insured_state: z.nativeEnum(InsuredState).nullable(),
        damage_source_id: z.string(),
        damage_source_type: z.nativeEnum(DamageSourceType).nullable(),
        inundated_land: z.number({ coerce: true }).nullable(),
        inundated_land_approx_volume_debris: z
          .number({ coerce: true })
          .nullable(),
        sixty_m_inundated_land: z.number({ coerce: true }).nullable(),
        sixty_m_inundated_land_approx_volume_debris: z
          .number({ coerce: true })
          .nullable(),
        reference: z.string().nullable(),
        insurer_reference: z.string().nullable(),
      })
    ),
  [GeoJsonCollectionName.TensionCrackLineV1]: baseFeaturePropertiesSchema
    .merge(lineSchema)
    .merge(
      z.object({
        damage_source_id: z.string(),
        tension_crack_depth: z.number({ coerce: true }).nullable(),
        reference: z.string().nullable(),
        insurer_reference: z.string().nullable(),
      })
    ),
  [GeoJsonCollectionName.RetainingWallLineV1]: baseFeaturePropertiesSchema
    .merge(lineSchema)
    .merge(
      z.object({
        reference: z.string().nullable(),
        label: z.string(),
        wall_type: z.string(),
        pile_spacing: z.string(),
        pile_thickness: z.string(),
        damage_type: z.nativeEnum(DamageType),
        maximum_retained_height: z.number({ coerce: true }),
        minimum_retained_height: z.number({ coerce: true }),
        total_length: z.number({ coerce: true }),
        insured_length: z.number({ coerce: true }),
        associated_landslip: z.string(),
        damaged_length: z.number({ coerce: true }),
        damaged_face_area: z.number({ coerce: true }),
        imminent_damage_face_area: z.number({ coerce: true }),
        location_description: z.string(),
      })
    ),
  [GeoJsonCollectionName.PrimaryDwellingAreaV1]: baseFeaturePropertiesSchema
    .merge(polylineSchema)
    .merge(
      z.object({
        roof: z.string().nullable(),
        cladding: z.string().nullable(),
        foundations: z.string().nullable(),
        number_of_levels: z.number({ coerce: true }).nullable(),
        finished_floor_level: z.number({ coerce: true }).nullable(),
      })
    ),
  [GeoJsonCollectionName.ExclusiveUseAreaV1]:
    baseFeaturePropertiesSchema.merge(polylineSchema),
  [GeoJsonCollectionName.DamagedServiceLineV1]: baseFeaturePropertiesSchema
    .merge(lineSchema)
    .merge(
      z.object({
        label: z.string(),
      })
    ),
  [GeoJsonCollectionName.DamagedServicePointV1]:
    baseFeaturePropertiesSchema.merge(
      z.object({
        label: z.string(),
      })
    ),
  [GeoJsonCollectionName.ProposedRemediationAreaV1]: baseFeaturePropertiesSchema
    .merge(polylineSchema)
    .merge(
      z.object({
        label: z.string(),
        need_anchors: z.boolean().nullable(),
        solution_type: z.string().nullable(),
        reference: z.string().nullable(),
      })
    ),
  [GeoJsonCollectionName.ProposedRemediationLineV1]: baseFeaturePropertiesSchema
    .merge(lineSchema)
    .merge(
      z.object({
        label: z.string(),
        need_anchors: z.boolean().nullable(),
        solution_type: z.string().nullable(),
        reference: z.string().nullable(),
      })
    ),
  [GeoJsonCollectionName.ObservationAreaV1]: baseFeaturePropertiesSchema
    .merge(polylineSchema)
    .merge(
      z.object({
        label: z.string(),
        notes: z.string().nullable(),
      })
    ),
  [GeoJsonCollectionName.ObservationLineV1]: baseFeaturePropertiesSchema
    .merge(lineSchema)
    .merge(
      z.object({
        label: z.string(),
        notes: z.string().nullable(),
      })
    ),
  [GeoJsonCollectionName.ObservationPointV1]: baseFeaturePropertiesSchema.merge(
    z.object({
      label: z.string(),
      notes: z.string().nullable(),
    })
  ),
  [GeoJsonCollectionName.CrossSectionLineV1]: baseFeaturePropertiesSchema
    .merge(lineSchema)
    .merge(
      z.object({
        label: z.string(),
      })
    ),
  [GeoJsonCollectionName.StructuralDamageAreaV1]: baseFeaturePropertiesSchema
    .merge(polylineSchema)
    .merge(
      z.object({
        structure_class: z.nativeEnum(StructureClass),
        label: z.string(),
        damaged: z.boolean(),
        at_imminent_risk: z.boolean(),
        structure_damage: z.string(),
        notes: z.string(),
      })
    ),
  [GeoJsonCollectionName.StructuralDamageLineV1]: baseFeaturePropertiesSchema
    .merge(lineSchema)
    .merge(
      z.object({
        structure_class: z.nativeEnum(StructureClass),
        label: z.string(),
        damaged: z.boolean(),
        at_imminent_risk: z.boolean(),
        structure_damage: z.string(),
        notes: z.string(),
      })
    ),
  [GeoJsonCollectionName.StructuralDamagePointV1]:
    baseFeaturePropertiesSchema.merge(
      z.object({
        structure_class: z.nativeEnum(StructureClass),
        label: z.string(),
        damaged: z.boolean(),
        at_imminent_risk: z.boolean(),
        structure_damage: z.string(),
        notes: z.string(),
      })
    ),
  [GeoJsonCollectionName.HistoricHeadscarpLineV1]: baseFeaturePropertiesSchema
    .merge(lineSchema)
    .merge(
      z.object({
        label: z.string(),
      })
    ),
};

export type GeoJsonCollectionGeometries = {
  [GeoJsonCollectionName.EvacuatedLandAreaV1]: GeoJSON.MultiPolygon;
  [GeoJsonCollectionName.ClaimPointV1]: GeoJSON.Point;
  [GeoJsonCollectionName.AccesswayAreaV1]: GeoJSON.MultiPolygon;
  [GeoJsonCollectionName.AppurtenantStructureAreaV1]: GeoJSON.MultiPolygon;
  [GeoJsonCollectionName.AppurtenantStructurePointV1]: GeoJSON.Point;
  [GeoJsonCollectionName.HeadscarpLineV1]: GeoJSON.LineString;
  [GeoJsonCollectionName.ImminentRiskAreaV1]: GeoJSON.MultiPolygon;
  [GeoJsonCollectionName.InsuredLandAreaV1]: GeoJSON.MultiPolygon;
  [GeoJsonCollectionName.InundatedLandAreaV1]: GeoJSON.MultiPolygon;
  [GeoJsonCollectionName.TensionCrackLineV1]: GeoJSON.LineString;
  [GeoJsonCollectionName.RetainingWallLineV1]: GeoJSON.LineString;
  [GeoJsonCollectionName.PrimaryDwellingAreaV1]: GeoJSON.MultiPolygon;
  [GeoJsonCollectionName.ExclusiveUseAreaV1]: GeoJSON.MultiPolygon;
  [GeoJsonCollectionName.DamagedServiceLineV1]: GeoJSON.LineString;
  [GeoJsonCollectionName.DamagedServicePointV1]: GeoJSON.Point;
  [GeoJsonCollectionName.ProposedRemediationAreaV1]: GeoJSON.MultiPolygon;
  [GeoJsonCollectionName.ProposedRemediationLineV1]: GeoJSON.LineString;
  [GeoJsonCollectionName.ObservationAreaV1]: GeoJSON.MultiPolygon;
  [GeoJsonCollectionName.ObservationLineV1]: GeoJSON.LineString;
  [GeoJsonCollectionName.ObservationPointV1]: GeoJSON.Point;
  [GeoJsonCollectionName.CrossSectionLineV1]: GeoJSON.LineString;
  [GeoJsonCollectionName.StructuralDamageAreaV1]: GeoJSON.MultiPolygon;
  [GeoJsonCollectionName.StructuralDamageLineV1]: GeoJSON.LineString;
  [GeoJsonCollectionName.StructuralDamagePointV1]: GeoJSON.Point;
  [GeoJsonCollectionName.HistoricHeadscarpLineV1]: GeoJSON.LineString;
  [GeoJsonCollectionName.InsurerClaimPointV1]: GeoJSON.Point;
  [GeoJsonCollectionName.InsurerPolicyPointV1]: GeoJSON.Point;
  [GeoJsonCollectionName.DroneSurveyAreaV1]: GeoJSON.MultiPolygon;
};

export type InferFeaturePropertiesType<
  TCollection extends GeoJsonCollectionName
> = z.infer<(typeof geoJsonCollectionSchemas)[TCollection]>;

export type InferFeatureGeometryType<
  TCollection extends GeoJsonCollectionName
> = GeoJsonCollectionGeometries[TCollection];

export type InferFeatureType<TCollection extends GeoJsonCollectionName> =
  GeoJSON.Feature<
    InferFeatureGeometryType<TCollection>,
    InferFeaturePropertiesType<TCollection>
  >;

export type InferFeatureCollectionType<
  TCollection extends GeoJsonCollectionName
> = GeoJSON.FeatureCollection<
  InferFeatureGeometryType<TCollection>,
  InferFeaturePropertiesType<TCollection>
>;
