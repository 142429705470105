import { Typography, Popover, Paper } from "@mui/material";

type CantBeEditedProps = {
  onClose: VoidFunction;
  anchorEl: Element;
};

export const CantBeEdited = (props: CantBeEditedProps) => {
  const { onClose, anchorEl } = props;
  return (
    <Popover
      open
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      disableRestoreFocus
    >
      <Paper sx={{ maxWidth: 300, padding: "1rem" }} elevation={1}>
        <Typography>This property can&apos;t be edited.</Typography>
      </Paper>
    </Popover>
  );
};
