import { useQuery } from "react-query";
import { getFeatures } from "../getFeatures";
import { GeoJsonCollectionName } from "../constants";
import { GetGeoJsonCollectionApiUrlProps } from "../utils";

export function useGeoJsonCollectionQuery<TCollection extends GeoJsonCollectionName>(props: GetGeoJsonCollectionApiUrlProps<TCollection>) {
  return useQuery(
    [props.collectionName, props.filter, props.boundingBox, props.boundingBoxCRS],
    () => getFeatures(props)
  );
}