import { z } from "zod";

export const EventSchema = z.object({
  eventId: z.string().optional(),
  name: z.string(),
  status: z.enum(["Requested", "Active", "Inactive"]).optional(),
  dateOfEvent: z.string().datetime().optional(),
  insUpdTs: z.string().datetime().optional(),
  created: z.string().datetime().optional(),
  requestedByOrganisationId: z.string().optional(),
});

export type Event = z.infer<typeof EventSchema>;
