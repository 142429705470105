import { AssessmentAttachment } from "@athena/server/src/api/types/claimAssessment";
import { FC, useCallback, useState } from "react";
import {
  PopupSection,
  PopupSectionBody,
  PopupSectionHeader,
  PopupSectionContentHorizontal,
} from "../../mapLib/components/FeaturePopup/components/styled";
import styled from "@emotion/styled";
import { Button, Dialog, IconButton, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import CloseIcon from "@mui/icons-material/Close";
import { config } from "src/lib/config";

const NUM_OF_ATTACHMENTS_TO_SHOW = 4;

interface AttachmentsProps {
  attachments?: Array<AssessmentAttachment>;
}

export const Attachments: FC<AttachmentsProps> = ({ attachments }) => {
  const maxIndex = (attachments || []).length - 1;
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [imageToView, setImageToView] = useState<
    AssessmentAttachment | undefined
  >(undefined);

  const back = useCallback(() => {
    let newValue = selectedIndex - 1;
    if (newValue < 0) {
      newValue = maxIndex;
    }

    setSelectedIndex(newValue);
  }, [selectedIndex, maxIndex]);

  const forward = useCallback(() => {
    let newValue = selectedIndex + 1;
    if (newValue > maxIndex) {
      newValue = 0;
    }

    setSelectedIndex(newValue);
  }, [selectedIndex, maxIndex]);

  if (!attachments || attachments.length === 0) {
    return;
  }

  const endIndex = selectedIndex + NUM_OF_ATTACHMENTS_TO_SHOW;
  let selectedAttachments = attachments.slice(selectedIndex, endIndex);
  if (selectedAttachments.length < NUM_OF_ATTACHMENTS_TO_SHOW) {
    const amountLeft = NUM_OF_ATTACHMENTS_TO_SHOW - selectedAttachments.length;
    const restOfAttachments = attachments.slice(0, amountLeft);
    selectedAttachments = [...selectedAttachments, ...restOfAttachments];
  }

  const uniqueSelectedAttachments = [
    ...new Map(selectedAttachments.map((a) => [a.s3Key, a])).values(),
  ];

  const arrowDisplay = attachments.length > 2 ? "block" : "none";

  return (
    <>
      <PopupSection>
        <Stack direction={"row"} justifyContent={"center"} flexWrap={"nowrap"}>
          <PopupSectionHeader>Related Media</PopupSectionHeader>
        </Stack>
        <PopupSectionBody>
          <Stack
            direction={"row"}
            justifyContent={"center"}
            flexWrap={"nowrap"}
            columnGap={0}
            gap="3px"
            marginTop="0.5rem"
            marginBottom="0.5rem"
          >
            <IconButton
              aria-label="Previous"
              sx={{
                height: "4.5rem",
                padding: 0,
              }}
              onClick={back}
            >
              <ArrowBackIosIcon
                sx={{
                  color: "#005AC4",
                  verticalAlign: "middle",
                  fontSize: "4rem",
                  height: "4rem",
                  maxWidth: "2rem",
                  display: arrowDisplay,
                }}
              />
            </IconButton>
            {uniqueSelectedAttachments.map((attachment) => (
              <AttachmentImage
                key={attachment.s3Key}
                onClick={(e) => {
                  e.preventDefault();
                  setImageToView(attachment);
                }}
                src={`${config.apiUrl}/${
                  attachment.annotatedImageUrl || attachment.imageUrl
                }`}
              />
            ))}
            <IconButton
              aria-label="Next"
              sx={{
                height: "4.5rem",
                padding: 0,
              }}
              onClick={forward}
            >
              <ArrowForwardIosIcon
                sx={{
                  color: "#005AC4",
                  verticalAlign: "middle",
                  fontSize: "4rem",
                  height: "4rem",
                  maxWidth: "2rem",
                  display: arrowDisplay,
                }}
              />
            </IconButton>
          </Stack>
        </PopupSectionBody>
      </PopupSection>
      <ViewAttachmentDialog
        attachment={imageToView}
        onClose={() => setImageToView(undefined)}
      />
    </>
  );
};

interface ViewAttachmentDialogProps {
  attachment?: AssessmentAttachment;
  onClose: () => void;
}

const ViewAttachmentDialog: FC<ViewAttachmentDialogProps> = ({
  attachment,
  onClose,
}) => {
  return (
    <Dialog onClose={onClose} open={!!attachment} maxWidth="lg">
      <Stack direction={"column"} sx={{ padding: "0.75rem" }}>
        {attachment && (
          <>
            <PopupSection>
              <PopupSectionContentHorizontal>
                <ViewAttachmentDialogTitle>Photo</ViewAttachmentDialogTitle>
                <Stack
                  direction={"row"}
                  flexWrap={"nowrap"}
                  rowGap={0}
                  gap={0}
                  columnGap={0}
                >
                  <IconButton
                    aria-label="Close"
                    sx={{
                      height: "2rem",
                      padding: 0,
                    }}
                    onClick={onClose}
                  >
                    <CloseIcon
                      sx={{
                        color: "#005AC4",
                        verticalAlign: "middle",
                        fontSize: "2rem",
                        height: "2rem",
                      }}
                    />
                  </IconButton>
                </Stack>
              </PopupSectionContentHorizontal>
            </PopupSection>
            <PopupSection>
              <ViewAttachmentDialogImage
                src={`${config.apiUrl}/${
                  attachment.annotatedImageUrl || attachment.imageUrl
                }`}
              />
            </PopupSection>
            {attachment.caption && attachment.description && (
              <PopupSection>
                <Typography
                  fontWeight={700}
                  fontSize={"20px"}
                  lineHeight={"24.2px"}
                  fontFamily={"Inter"}
                  color="#333333"
                >
                  {attachment.caption}
                </Typography>
                <Typography
                  fontWeight={400}
                  fontSize={"16px"}
                  lineHeight={"19.36px"}
                  fontFamily={"Inter"}
                  color="#333333"
                  marginTop={"0.5rem"}
                  marginBottom={"0.75rem"}
                >
                  {attachment.description}
                </Typography>
              </PopupSection>
            )}
            <PopupSection>
              <Stack
                direction={"row"}
                justifyContent={"space-between"}
                sx={{
                  paddingTop: "0.5rem",
                }}
              >
                <Button
                  variant="outlined"
                  target="_blank"
                  download
                  href={`${config.apiUrl}/${
                    attachment.annotatedImageUrl || attachment.imageUrl
                  }`}
                  sx={{
                    width: "240px",
                  }}
                >
                  <span>Downloads</span>
                </Button>
                <Button
                  autoFocus
                  onClick={onClose}
                  variant="outlined"
                  sx={{
                    width: "240px",
                  }}
                >
                  <span>Close</span>
                </Button>
              </Stack>
            </PopupSection>
          </>
        )}
      </Stack>
    </Dialog>
  );
};

const AttachmentImage = styled.img`
  width: 4.5rem;
  height: 4.5rem;
  cursor: pointer;
`;

const ViewAttachmentDialogTitle = styled.h4`
  font-size: 1.375rem;
  font-weight: 600;
  line-height: 2rem;
`;

const ViewAttachmentDialogImage = styled.img`
  max-width: 700px;
`;
