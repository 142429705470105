import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { JSONContent } from "@tiptap/core";
import { useRef } from "react";
import { InlineEditor, InlineEditorRef } from "../InlineEditor";
import { TemplateVariables } from "../TemplateVariables";

export type TemplateWithVariablesProps = {
  content: JSONContent | string;
  onContentChange: (content: JSONContent, html: string) => void;
  title: string;
  onLoad?: VoidFunction;
  onImageUpload?: (file: File) => Promise<{ url: string }>;
  editorId?: string;
  includeStyles?: boolean;
};

export const TemplateWithVariables = (props: TemplateWithVariablesProps) => {
  const editorRef = useRef<InlineEditorRef>(null);
  const {
    content,
    onContentChange,
    title,
    onImageUpload,
    includeStyles = true,
  } = props;

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        border: "1px solid lightgrey",
        padding: "1rem",
        borderRadius: 4,
        width: "100%",
        minWidth: "50vw",
        gap: "1rem",
      }}
    >
      <Box>
        <Typography variant="h4">{title}</Typography>
        <InlineEditor
          content={content}
          onContentChange={(content) => {
            onContentChange(content, editorRef.current?.getHtml() || "");
          }}
          ref={editorRef}
          onLoad={props.onLoad}
          onImageUpload={onImageUpload}
          includeStyles={includeStyles}
          editorId={props.editorId}
        />
      </Box>
      <TemplateVariables
        onVariableClick={(value, label, description, hasConfig) => {
          if (editorRef.current) {
            editorRef.current.addTemplateVariable(
              value,
              label,
              description,
              hasConfig
            );
          }
        }}
      />
    </Box>
  );
};
