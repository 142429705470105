import { axiosClient } from "src/lib/axiosClient";
import { GetGeoJsonCollectionApiUrlProps, getGeoJsonCollectionApiUrl } from "./utils";
import { GeoJsonCollectionName } from "./constants";
import { InferFeatureCollectionType, InferFeatureType, geoJsonCollectionSchemas } from "./types";

export async function getFeatures<TCollection extends GeoJsonCollectionName>(
  props: GetGeoJsonCollectionApiUrlProps<TCollection>
): Promise<InferFeatureCollectionType<TCollection>>
 {
  const schema = geoJsonCollectionSchemas[props.collectionName];
  const collectionUrl = getGeoJsonCollectionApiUrl(props);

  const { data } = await axiosClient.get<InferFeatureCollectionType<TCollection>>(collectionUrl);

  const validatedFeatures: Array<InferFeatureType<TCollection>> = []
  
  for (const feature of data.features) {
    const parsed = schema.safeParse(feature.properties);
    if (parsed.success) {
      validatedFeatures.push({
        ...feature,
        properties: parsed.data,
      })
    } else {
      console.error(
        `Schema validation for incoming feature failed: ${parsed.error.toString()}`
      );
    }
  }

  return {
    ...data,
    features: validatedFeatures,
  };
}
