import { RefObject, useEffect, useState } from "react";
import { useInViewport } from "ahooks";
import styled from "@emotion/styled";

interface useCoverBlockProps {
  mapActive: boolean;
  mapRef: RefObject<HTMLDivElement>;
  onActivated?: VoidFunction;
}

export const useCoverBlock = ({
  mapActive,
  mapRef,
  onActivated,
}: useCoverBlockProps) => {
  const [active, setActive] = useState(mapActive ?? false);
  const [inViewport] = useInViewport(mapRef);

  useEffect(() => {
    if (!mapActive && !inViewport) {
      setActive(false);
    }
  }, [inViewport, mapActive]);

  return (
    <CoverBlock
      style={active ? { visibility: "hidden" } : {}}
      onClick={() => {
        setActive(true);
        onActivated?.();
      }}
    >
      Click to activate the map
    </CoverBlock>
  );
};

const CoverBlock = styled.div`
  display: flex;
  position: absolute;
  height: 100%;
  width: 100%;

  justify-content: center;
  align-items: center;

  user-select: none;

  font-size: 2rem;
  font-weight: 600;

  background: rgba(100, 96, 96, 0.7);

  color: white;
  z-index: 7;
`;
