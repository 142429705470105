import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Paper, Stack, Box } from "@mui/material";
import styled from "@emotion/styled";
type ClaimProgressItem = {
  title: string;
  id: string;
  completed: boolean;
};

interface ClaimProgressProps {
  claimProgressItems: ClaimProgressItem[];
}

export function ClaimProgress({ claimProgressItems }: ClaimProgressProps) {
  return (
    <ClaimProgressContainer>
      <Paper
        sx={{
          padding: "1.5rem",
          alignSelf: "flex-start",
          position: "sticky",
          top: "5.5rem",
        }}
        elevation={4}
      >
        <Stack spacing={1}>
          {claimProgressItems.map((claimProgressItem) => {
            return (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
                key={claimProgressItem.title}
              >
                <ProgressContainer>
                  <ProgressLink href={"#" + claimProgressItem.id}>
                    {claimProgressItem.title}
                  </ProgressLink>
                  {claimProgressItem.completed && (
                    <CheckCircleIcon color="success" fontSize="small" />
                  )}
                </ProgressContainer>
              </Box>
            );
          })}
        </Stack>
      </Paper>
    </ClaimProgressContainer>
  );
}

const ProgressLink = styled.a`
  color: #1976d2;
  text-decoration: none;
  font-family: inherit;
  font-weight: 500;
  :hover {
    color: blue;
  }
`;

const ProgressContainer = styled.div`
  display: flex;
  align-content: center;
  gap: 0.5rem;
`;

const ClaimProgressContainer = styled.div`
  @media (max-width: 1200px) {
    display: none;
    /* remove after demo */
  }
`;
