import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import { JSONContent } from "@tiptap/core";
import { useState } from "react";
import { InlineEditor } from "../InlineEditor";

type ConceptualRemedialWorksListProps = {
  showModal: boolean;
  onClose: VoidFunction;
  setDirty: (state: boolean) => void;
  onSave: (context: Context) => void;
  context: Context;
};

type Context = {
  hasConceptualRemedialSolution: JSONContent;
  hasTimberPoleRetainingWall: JSONContent;
  hasTimberCribRetainingWall: JSONContent;
  hasSlopeReprofilingAndHydroseed: JSONContent;
  hasTimberPoleCatchFence: JSONContent;
  hasAnchoredShotcreteWall: JSONContent;
  hasConcreteBlockWall: JSONContent;
  hasConcretePalisadeWall: JSONContent;
  hasAnchoredMacMat: JSONContent;
  hasGabionBasket: JSONContent;
};

export const ConceptualRemedialWorksList = ({
  showModal,
  onClose,
  setDirty,
  onSave,
  context,
}: ConceptualRemedialWorksListProps) => {
  const initialState = {
    hasConceptualRemedialSolution:
      context?.hasConceptualRemedialSolution || "Placeholder",
    hasTimberPoleRetainingWall:
      context?.hasTimberPoleRetainingWall || "Placeholder",
    hasTimberCribRetainingWall:
      context?.hasTimberCribRetainingWall || "Placeholder",
    hasSlopeReprofilingAndHydroseed:
      context?.hasSlopeReprofilingAndHydroseed || "Placeholder",
    hasTimberPoleCatchFence: context?.hasTimberPoleCatchFence || "Placeholder",
    hasAnchoredShotcreteWall:
      context?.hasAnchoredShotcreteWall || "Placeholder",
    hasConcreteBlockWall: context?.hasConcreteBlockWall || "Placeholder",
    hasConcretePalisadeWall: context?.hasConcretePalisadeWall || "Placeholder",
    hasAnchoredMacMat: context?.hasAnchoredMacMat || "Placeholder",
    hasGabionBasket: context?.hasGabionBasket || "Placeholder",
  };

  const [remedialWorks, setRemedialWorks] = useState(initialState);
  const handleChange = (change: Partial<typeof remedialWorks>) => {
    setRemedialWorks({ ...remedialWorks, ...change });
    setDirty(true);
  };
  return (
    <>
      {showModal && (
        <Dialog
          open={showModal}
          onClose={onClose}
          maxWidth="xl"
          sx={{ " .MuiPaper-root": { flexWrap: "nowrap" } }}
        >
          <DialogTitle sx={{ borderBottom: "1px solid lightgrey" }}>
            EQC Consideration Details
          </DialogTitle>
          <DialogContent sx={{ overflow: "auto" }}>
            <Stack>
              <Typography variant="h4">
                If there is conceptual remedial solution
              </Typography>
              <InlineEditor
                content={remedialWorks.hasConceptualRemedialSolution}
                onContentChange={(val) =>
                  handleChange({
                    hasConceptualRemedialSolution: val,
                  })
                }
              />
              <Typography variant="h4">
                If there is a timber pole retaining wall
              </Typography>
              <InlineEditor
                content={remedialWorks.hasTimberPoleRetainingWall}
                onContentChange={(val) =>
                  handleChange({
                    hasTimberPoleRetainingWall: val,
                  })
                }
              />
              <Typography variant="h4">
                If there is a timber crib retaining wall
              </Typography>
              <InlineEditor
                content={remedialWorks.hasTimberCribRetainingWall}
                onContentChange={(val) =>
                  handleChange({
                    hasTimberCribRetainingWall: val,
                  })
                }
              />
              <Typography variant="h4">
                If there is slope reprofiling and hydroseed
              </Typography>
              <InlineEditor
                content={remedialWorks.hasSlopeReprofilingAndHydroseed}
                onContentChange={(val) =>
                  handleChange({
                    hasSlopeReprofilingAndHydroseed: val,
                  })
                }
              />
              <Typography variant="h4">
                If there is a timber pole catch fence
              </Typography>
              <InlineEditor
                content={remedialWorks.hasTimberPoleCatchFence}
                onContentChange={(val) =>
                  handleChange({
                    hasTimberPoleCatchFence: val,
                  })
                }
              />
              <Typography variant="h4">
                If there is an anchored shotcrete wall
              </Typography>
              <InlineEditor
                content={remedialWorks.hasAnchoredShotcreteWall}
                onContentChange={(val) =>
                  handleChange({
                    hasAnchoredShotcreteWall: val,
                  })
                }
              />
              <Typography variant="h4">
                If there is a concrete block wall
              </Typography>
              <InlineEditor
                content={remedialWorks.hasConcreteBlockWall}
                onContentChange={(val) =>
                  handleChange({
                    hasConcreteBlockWall: val,
                  })
                }
              />
              <Typography variant="h4">
                If there is a concrete palisade wall
              </Typography>
              <InlineEditor
                content={remedialWorks.hasConcretePalisadeWall}
                onContentChange={(val) =>
                  handleChange({
                    hasConcretePalisadeWall: val,
                  })
                }
              />
              <Typography variant="h4">
                If there is an anchored MacMat
              </Typography>
              <InlineEditor
                content={remedialWorks.hasAnchoredMacMat}
                onContentChange={(val) =>
                  handleChange({
                    hasAnchoredMacMat: val,
                  })
                }
              />
              <Typography variant="h4">If there is a gabion basket</Typography>
              <InlineEditor
                content={remedialWorks.hasGabionBasket}
                onContentChange={(val) =>
                  handleChange({
                    hasGabionBasket: val,
                  })
                }
              />
            </Stack>
          </DialogContent>
          <DialogActions
            sx={{
              display: "flex",
              flexDirection: "row",
              padding: "1rem",
              alignItems: "flex-end",
              borderTop: "1px solid lightgrey",
            }}
          >
            <Button
              variant="contained"
              onClick={() => {
                onSave(remedialWorks);
              }}
              sx={{ width: 200 }}
            >
              Save
            </Button>
            <Button
              variant="outlined"
              onClick={() => {
                onClose();
              }}
              sx={{ width: 200 }}
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};
