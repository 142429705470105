import { format, startOfWeek, endOfWeek } from "date-fns";

export const formatDateTime = (date: Date | string) => {
  if (!date) return "";
  return format(new Date(date), "h:mm a dd/MM/yy");
};

export const formatDate = (date: Date | string) => {
  if (!date) return "";
  return format(new Date(date), "dd/MM/yy");
};

export const formatTime = (date: Date | string) => {
  if (!date) return "";
  return format(new Date(date), "h:mm a");
};

export const getStartOfWeek = (date: Date) => {
  return startOfWeek(date, { weekStartsOn: 1 })
}

export const getEndOfWeek = (date: Date) => {
  return endOfWeek(date, { weekStartsOn: 1 })
}