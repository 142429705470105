import {
  DialogSection,
  DialogSectionContentVertical,
  DialogSectionHeader,
  DialogSectionBody,
  DialogSectionContentHorizontal,
} from "@athena/components";
import { Button } from "@mui/material";
import { Stack } from "@mui/system";
import { ReactNode } from "react";

interface DataMismatchSectionProps<TValue extends ReactNode> {
  label: string;
  oldValue: TValue;
  newValue: TValue;
  selectedValue?: TValue;
  onSelect: (value: TValue) => void;
}

enum SelectedVariant {
  Old,
  New,
}

export function DataMismatchSection<TValue extends ReactNode>({
  label,
  oldValue,
  newValue,
  selectedValue,
  onSelect,
}: DataMismatchSectionProps<TValue>) {
  const selectedVariant =
    selectedValue === undefined
      ? undefined
      : selectedValue === oldValue
      ? SelectedVariant.Old
      : SelectedVariant.New;

  return (
    <DialogSection>
      <DialogSectionContentVertical>
        <DialogSectionHeader>{label}</DialogSectionHeader>
        <DialogSectionBody>
          <DialogSectionContentHorizontal>
            <DialogSectionContentVertical>
              <DialogSectionHeader>
                Old{selectedVariant === SelectedVariant.Old && " (Selected)"}
              </DialogSectionHeader>
              <DialogSectionBody>{oldValue}</DialogSectionBody>
            </DialogSectionContentVertical>
            <DialogSectionContentVertical>
              <DialogSectionHeader>
                New{selectedVariant === SelectedVariant.New && " (Selected)"}
              </DialogSectionHeader>
              <DialogSectionBody>{newValue}</DialogSectionBody>
            </DialogSectionContentVertical>
            <Stack
              direction={"row"}
              sx={{ padding: "1rem", alignItems: "end" }}
            >
              <Button
                variant="outlined"
                sx={{ height: "2.5rem" }}
                color={
                  selectedVariant === SelectedVariant.Old
                    ? "success"
                    : "primary"
                }
                onClick={() => onSelect(oldValue)}
              >
                Keep old
              </Button>
              <Button
                variant="outlined"
                sx={{ height: "2.5rem" }}
                color={
                  selectedVariant === SelectedVariant.New
                    ? "success"
                    : "primary"
                }
                onClick={() => onSelect(newValue)}
              >
                Accept new
              </Button>
            </Stack>
          </DialogSectionContentHorizontal>
        </DialogSectionBody>
      </DialogSectionContentVertical>
    </DialogSection>
  );
}
