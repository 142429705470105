import {
  AdditionalStatus,
  Status,
  getStatusDescription,
} from "@athena/server/src/api/types/claimStatuses";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Chip,
  Typography,
  Checkbox,
} from "@mui/material";
import { getChipColor } from "./ClaimStatusChips";
import styled from "@emotion/styled";
import { useState } from "react";
import DirectionsWalkIcon from "@mui/icons-material/DirectionsWalk";
import ReportIcon from "@mui/icons-material/Report";
import UpdateIcon from "@mui/icons-material/Update";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import MedicalServicesIcon from "@mui/icons-material/MedicalServices";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

type ClaimStatusModalProps = {
  status: Status;
  additionalStatuses: AdditionalStatus[];
  onClose: VoidFunction;
  onAdditionalStatusesSave: (additionalStatuses: AdditionalStatus[]) => void;
};

const getColorForAdditionalStatus = (additionalStatus: AdditionalStatus) => {
  if (additionalStatus === AdditionalStatus.AdditionalDetailsRequested)
    return "#fefff1";
  if (additionalStatus === AdditionalStatus.TimeExempt) return "#fefff1";
  if (additionalStatus === AdditionalStatus.SiteRevisit) return "#fefff1";
  if (additionalStatus === AdditionalStatus.HealthAndSafety) return "#fefff1";
  if (additionalStatus === AdditionalStatus.AdditionalRequestFromClient)
    return "#ffecec";
  if (additionalStatus === AdditionalStatus.ConflictOfInterest)
    return "#ffecec";
  if (additionalStatus === AdditionalStatus.Overdue) return "#ffecec";
};
const getContentForAdditionalStatus = (additionalStatus: AdditionalStatus) => {
  if (additionalStatus === AdditionalStatus.AdditionalDetailsRequested)
    return "Request more information about this claim";
  if (additionalStatus === AdditionalStatus.TimeExempt)
    return "Exempt this claim from KPI tracking and pause work on the claim";
  if (additionalStatus === AdditionalStatus.SiteRevisit)
    return "Mark that the claim requires another site visit";
  if (additionalStatus === AdditionalStatus.AdditionalRequestFromClient)
    return "Claim has been reopened after final report";
  if (additionalStatus === AdditionalStatus.ConflictOfInterest)
    return "Use this when an engineer has a conflict of interest with the assessed property";
  if (additionalStatus === AdditionalStatus.Overdue)
    return "Use this to manually mark a claim as overdue";
  if (additionalStatus === AdditionalStatus.HealthAndSafety)
    return "Exceptional H&S circumstance - refer to site notes";
};
const getIconForAdditionalStatus = (additionalStatus: AdditionalStatus) => {
  if (additionalStatus === AdditionalStatus.AdditionalDetailsRequested)
    return <HelpOutlineIcon sx={{ height: "2.5rem", width: "2.5rem" }} />;
  if (additionalStatus === AdditionalStatus.HealthAndSafety)
    return <MedicalServicesIcon sx={{ height: "2.5rem", width: "2.5rem" }} />;
  if (additionalStatus === AdditionalStatus.TimeExempt)
    return <UpdateIcon sx={{ height: "2.5rem", width: "2.5rem" }} />;
  if (additionalStatus === AdditionalStatus.SiteRevisit)
    return <DirectionsWalkIcon sx={{ height: "2.5rem", width: "2.5rem" }} />;
  if (additionalStatus === AdditionalStatus.AdditionalRequestFromClient)
    return <AddCircleOutlineIcon sx={{ height: "2.5rem", width: "2.5rem" }} />;
  if (additionalStatus === AdditionalStatus.ConflictOfInterest)
    return <ReportIcon sx={{ height: "2.5rem", width: "2.5rem" }} />;
  if (additionalStatus === AdditionalStatus.Overdue)
    return <AccessTimeIcon sx={{ height: "2.5rem", width: "2.5rem" }} />;
};

export const ClaimStatusModal = (props: ClaimStatusModalProps) => {
  const { onClose, onAdditionalStatusesSave, status, additionalStatuses } =
    props;
  const [newAdditionalStatuses, setNewAdditionalStatuses] =
    useState(additionalStatuses);
  return (
    <Dialog
      open
      onClose={onClose}
      fullWidth
      maxWidth="md"
      sx={{
        border: "1px solid lightgray",
        " .MuiPaper-root": {
          flexWrap: "nowrap",
        },
      }}
    >
      <DialogTitle sx={{ borderBottom: "1px solid lightgray" }}>
        Edit Claim Status
      </DialogTitle>
      <DialogContent>
        <Typography variant={"subtitle1"} sx={{ mb: "1rem", mt: "1rem" }}>
          <strong>Current status</strong>
        </Typography>
        <CurrentStatusContainer>
          <Typography variant={"body1"}>
            <strong>{status}</strong>
            <br />
            {getStatusDescription(status)}
          </Typography>
          <Chip
            label={status}
            sx={{ backgroundColor: getChipColor(status as Status) }}
          />
        </CurrentStatusContainer>
        <AdditionalContainer>
          {/* <div>
            {" "}
            <Typography variant={"subtitle1"} sx={{ mb: "1rem" }}>
              <strong>Select next steps</strong>
            </Typography>{" "}
            <StatusContainer>
              <Typography variant={"body1"}>
                <strong> Available Option</strong>
                <br />
                Description goes here
              </Typography>
              <Button variant="outlined">Move to STATE</Button>
            </StatusContainer>
            <StatusContainer>
              <Typography variant={"body1"}>
                <strong> Available Option</strong>
                <br />
                Description goes here
              </Typography>
              <Button variant="outlined">Move to STATE</Button>
            </StatusContainer>
          </div> */}
          <div>
            <Typography variant={"subtitle1"} sx={{ mb: "1rem" }}>
              <strong>Additional statuses (Optional)</strong>
            </Typography>
            {Object.values(AdditionalStatus)
              // These are automatic statuses
              .filter(
                (status) =>
                  status !== AdditionalStatus.AutoOverdue &&
                  status !== AdditionalStatus.DetailsIncomplete
              )
              .map((additionalStatus) => {
                return (
                  <StatusContainer
                    key={status}
                    style={{
                      backgroundColor:
                        getColorForAdditionalStatus(additionalStatus),
                    }}
                  >
                    <Spacer>
                      {getIconForAdditionalStatus(additionalStatus)}

                      <Typography variant={"body1"}>
                        <strong> {additionalStatus}</strong>
                        <br />
                        {getContentForAdditionalStatus(additionalStatus)}
                      </Typography>
                    </Spacer>
                    <Checkbox
                      defaultChecked={newAdditionalStatuses.includes(
                        additionalStatus
                      )}
                      value={newAdditionalStatuses.includes(additionalStatus)}
                      onChange={(e) => {
                        const newStatuses = newAdditionalStatuses.filter(
                          (aStatus) => aStatus !== additionalStatus
                        );

                        setNewAdditionalStatuses([
                          ...newStatuses,
                          ...(e.target.checked ? [additionalStatus] : []),
                        ]);
                      }}
                    />
                  </StatusContainer>
                );
              })}
          </div>
        </AdditionalContainer>
      </DialogContent>
      <DialogActions
        sx={{
          display: "flex",
          flexDirection: "column",
          padding: "1rem",
          borderTop: "1px solid lightgray",
        }}
      >
        <Button
          onClick={() => {
            onAdditionalStatusesSave(newAdditionalStatuses);
            onClose();
          }}
          variant="contained"
          sx={{ width: "100%" }}
        >
          Save
        </Button>
        <Button
          onClick={() => {
            onClose();
          }}
          variant="outlined"
          sx={{ width: "100%", margin: "1rem 0 0 0 !important" }}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const StatusContainer = styled.label`
  padding: 1rem;
  border-left: 1px solid lightgray;
  border-right: 1px solid lightgray;
  border-top: 1px solid lightgray;
  display: flex;
  gap: 0.5rem;
  justify-content: space-between;
  p {
    text-align: left;
  }

  :first-of-type {
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
  }
  :last-of-type {
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    border-bottom: 1px solid lightgray;
  }
`;
const Spacer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
`;

const AdditionalContainer = styled.div`
  margin-top: 1.5rem;
  display: grid;
  grid-template-columns: 1fr;
  gap: 1.5rem;
`;
const CurrentStatusContainer = styled.div`
  border: 1px solid lightgray;
  border-radius: 12px;
  padding: 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
