import { Status } from "@athena/server/src/api/types/claimStatuses";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import {
  Bar,
  BarChart,
  Cell,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { ChartComingSoon, ChartLoader } from "./ChartLoader";

interface Props {
  data: ClaimStatusCounts[];
  isLoading?: boolean;
  isComingSoon?: boolean;
}

type ClaimStatusCounts = {
  status: Status;
  "0 Days"?: number;
  "1-2 Days"?: number;
  "3-4 Days"?: number;
  "4+ Days"?: number;
};

export const DurationSinceLastActivityChart = ({
  data,
  isLoading,
  isComingSoon,
}: Props) => {
  const navigate = useNavigate();

  const navigateToFilteredClaims = useCallback(
    (x: ClaimStatusCounts) => {
      navigate(`/engineering-claims?statuses=${x.status}`);
    },
    [navigate]
  );

  //Fields to show on the chart
  //Explicit incase fields are added later
  const requiredFields = [
    Status.NeedsEngineer,
    Status.WaitingForCustomer,
    Status.VisitBooked,
    Status.VisitCompleted,
    Status.PreparingDraft,
    Status.UnderInternalReview,
    Status.UnderClientReview,
    Status.ClientAccepted,
  ];

  const filteredData = data.filter((x) => requiredFields.includes(x.status));
  const sortedData = filteredData.sort((a, b) => {
    const aIndex = Object.values(Status).indexOf(a.status as Status);
    const bIndex = Object.values(Status).indexOf(b.status as Status);
    return aIndex - bIndex;
  });

  return (
    <div style={{ width: "100%", marginTop: "1.5rem", position: "relative" }}>
      {isLoading && <ChartLoader />}
      {isComingSoon && <ChartComingSoon />}
      <ResponsiveContainer width="100%" height={300}>
        <BarChart data={sortedData}>
          <XAxis dataKey="status" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar
            dataKey="0 Days"
            name="Active today"
            stackId="a"
            fill={"#42d4f4"}
            onClick={navigateToFilteredClaims}
          >
            {sortedData.map((_: any, index: number) => (
              <Cell cursor="pointer" key={`cell-${index}`} />
            ))}
          </Bar>
          <Bar
            dataKey="1-2 Days"
            name="Claim status not changed in 1-2 days"
            stackId="a"
            fill={"#FEDA8B"}
            onClick={navigateToFilteredClaims}
          >
            {sortedData.map((_: any, index: number) => (
              <Cell cursor="pointer" key={`cell-${index}`} />
            ))}
          </Bar>
          <Bar
            dataKey="3-4 Days"
            name="Claim status not changed in 3-4 days"
            stackId="a"
            fill={"#FDB366"}
            onClick={navigateToFilteredClaims}
          >
            {sortedData.map((_: any, index: number) => (
              <Cell cursor="pointer" key={`cell-${index}`} />
            ))}
          </Bar>
          <Bar
            dataKey="4+ Days"
            name="Claim status not changed in 4+ days"
            stackId="a"
            fill={"#F67E4B"}
            onClick={navigateToFilteredClaims}
          >
            {sortedData.map((_: any, index: number) => (
              <Cell cursor="pointer" key={`cell-${index}`} />
            ))}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};
