import { createContext, useContext } from "react";

export interface NetworkContextType {
  online: boolean;
}

export const NetworkContext = createContext<NetworkContextType | null>(null);

export const useNetworkContext = () => {
  const networkContext = useContext(NetworkContext);
  if (!networkContext) {
    throw new Error(
      "useNetworkContext must be used within an NetworkContextProvider"
    );
  }
  return networkContext;
};