import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import { JSONContent } from "@tiptap/core";
import { useState } from "react";
import { InlineEditor } from "../InlineEditor";
import { TemplateWithVariables } from "./TemplateWithVariables";

type PropertyDamageDetailsProps = {
  showModal: boolean;
  onClose: VoidFunction;
  setDirty: (state: boolean) => void;
  onSave: (context: Context) => void;
  context: Context;
};

type Context = {
  hasLandslipDamage: JSONContent;
  hasEvacuatedInsuredLandLandslip: JSONContent;
  hasInundationOfInsuredLandLandslip: JSONContent;
  hasDwellingDamageLandslip: JSONContent;
  hasAppurtenantStructureDamageLandslip: JSONContent;
  hasRetainingWallDamageLandslip: JSONContent;
  hasServicesDamageLandslip: JSONContent;
  hasBridgeOrCulvertDamageLandslip: JSONContent;
  hasStormFloodDamage: JSONContent;
  hasEvacuatedInsuredLandStormFlood: JSONContent;
  hasInundationOfInsuredLandStormFlood: JSONContent;
  hasDwellingDamageStormFlood: JSONContent;
  hasAppurtenantStructureDamageStormFlood: JSONContent;
  hasRetainingWallDamageStormFlood: JSONContent;
  hasServicesDamageStormFlood: JSONContent;
  hasBridgeOrCulvertDamageStormFlood: JSONContent;
};

export const PropertyDamageDetails = ({
  showModal,
  onClose,
  setDirty,
  onSave,
  context,
}: PropertyDamageDetailsProps) => {
  const [propertyDamage, setPropertyDamage] = useState<Context>({
    hasLandslipDamage: context?.hasLandslipDamage || "Placeholder",
    hasEvacuatedInsuredLandLandslip:
      context?.hasEvacuatedInsuredLandLandslip || "Placeholder",
    hasInundationOfInsuredLandLandslip:
      context?.hasInundationOfInsuredLandLandslip || "Placeholder",
    hasDwellingDamageLandslip:
      context?.hasDwellingDamageLandslip || "Placeholder",
    hasAppurtenantStructureDamageLandslip:
      context?.hasAppurtenantStructureDamageLandslip || "Placeholder",
    hasRetainingWallDamageLandslip:
      context?.hasRetainingWallDamageLandslip || "Placeholder",
    hasServicesDamageLandslip:
      context?.hasServicesDamageLandslip || "Placeholder",
    hasBridgeOrCulvertDamageLandslip:
      context?.hasBridgeOrCulvertDamageLandslip || "Placeholder",
    hasStormFloodDamage: context?.hasStormFloodDamage || "Placeholder",
    hasEvacuatedInsuredLandStormFlood:
      context?.hasEvacuatedInsuredLandStormFlood || "Placeholder",
    hasInundationOfInsuredLandStormFlood:
      context?.hasInundationOfInsuredLandStormFlood || "Placeholder",
    hasDwellingDamageStormFlood:
      context?.hasDwellingDamageStormFlood || "Placeholder",
    hasAppurtenantStructureDamageStormFlood:
      context?.hasAppurtenantStructureDamageStormFlood || "Placeholder",
    hasRetainingWallDamageStormFlood:
      context?.hasRetainingWallDamageStormFlood || "Placeholder",
    hasServicesDamageStormFlood:
      context?.hasServicesDamageStormFlood || "Placeholder",
    hasBridgeOrCulvertDamageStormFlood:
      context?.hasBridgeOrCulvertDamageStormFlood || "Placeholder",
  });

  const handleChange = (change: Partial<typeof propertyDamage>) => {
    setPropertyDamage({ ...propertyDamage, ...change });
    setDirty(true);
  };

  return (
    <>
      {showModal && (
        <Dialog
          open={showModal}
          onClose={onClose}
          maxWidth={false}
          sx={{ " .MuiPaper-root": { flexWrap: "nowrap" } }}
        >
          <DialogTitle>Property Damage Details</DialogTitle>
          <DialogContent>
            <Stack>
              <Typography variant="h3">Landslips</Typography>
              <TemplateWithVariables
                title="Landslip"
                content={propertyDamage.hasLandslipDamage}
                onContentChange={(val) =>
                  handleChange({
                    hasLandslipDamage: val,
                  })
                }
              />
              <Typography variant="h4">
                When there is any evacuation of insured land, put the following
                text in the report
              </Typography>
              <InlineEditor
                content={propertyDamage.hasEvacuatedInsuredLandLandslip}
                onContentChange={(val) =>
                  handleChange({
                    hasEvacuatedInsuredLandLandslip: val,
                  })
                }
              />
              <Typography variant="h4">
                When there is any inundation of insured land
              </Typography>
              <InlineEditor
                content={propertyDamage.hasInundationOfInsuredLandLandslip}
                onContentChange={(val) =>
                  handleChange({
                    hasInundationOfInsuredLandLandslip: val,
                  })
                }
              />
              <TemplateWithVariables
                title="For each dwelling damage"
                content={propertyDamage.hasDwellingDamageLandslip}
                onContentChange={(val) =>
                  handleChange({
                    hasDwellingDamageLandslip: val,
                  })
                }
              />
              <TemplateWithVariables
                title="For each appurtenant structure damage"
                content={propertyDamage.hasAppurtenantStructureDamageLandslip}
                onContentChange={(val) =>
                  handleChange({
                    hasAppurtenantStructureDamageLandslip: val,
                  })
                }
              />
              <TemplateWithVariables
                title="For each retaining wall damage"
                content={propertyDamage.hasRetainingWallDamageLandslip}
                onContentChange={(val) =>
                  handleChange({
                    hasRetainingWallDamageLandslip: val,
                  })
                }
              />
              <TemplateWithVariables
                title="For each services damage"
                content={propertyDamage.hasServicesDamageLandslip}
                onContentChange={(val) =>
                  handleChange({
                    hasServicesDamageLandslip: val,
                  })
                }
              />
              <TemplateWithVariables
                title="For each bridge or culvert damage"
                content={propertyDamage.hasBridgeOrCulvertDamageLandslip}
                onContentChange={(val) =>
                  handleChange({
                    hasBridgeOrCulvertDamageLandslip: val,
                  })
                }
              />

              <Typography variant="h3">Storm / Flood</Typography>
              <Typography variant="h4">Storm / Flood Damage</Typography>
              <InlineEditor
                content={propertyDamage.hasStormFloodDamage}
                onContentChange={(val) =>
                  handleChange({
                    hasStormFloodDamage: val,
                  })
                }
              />
              <Typography variant="h4">
                When there is any evacuation of insured land, put the following
                text in the report
              </Typography>
              <InlineEditor
                content={propertyDamage.hasEvacuatedInsuredLandStormFlood}
                onContentChange={(val) =>
                  handleChange({
                    hasEvacuatedInsuredLandStormFlood: val,
                  })
                }
              />
              <Typography variant="h4">
                When there is any inundation of insured land
              </Typography>
              <InlineEditor
                content={propertyDamage.hasInundationOfInsuredLandStormFlood}
                onContentChange={(val) =>
                  handleChange({
                    hasInundationOfInsuredLandStormFlood: val,
                  })
                }
              />
              <TemplateWithVariables
                title="For each dwelling damage"
                content={propertyDamage.hasDwellingDamageStormFlood}
                onContentChange={(val) =>
                  handleChange({
                    hasDwellingDamageStormFlood: val,
                  })
                }
              />
              <TemplateWithVariables
                title="For each appurtenant structure damage"
                content={propertyDamage.hasAppurtenantStructureDamageStormFlood}
                onContentChange={(val) =>
                  handleChange({
                    hasAppurtenantStructureDamageStormFlood: val,
                  })
                }
              />
              <TemplateWithVariables
                title="For each retaining wall damage"
                content={propertyDamage.hasRetainingWallDamageStormFlood}
                onContentChange={(val) =>
                  handleChange({
                    hasRetainingWallDamageStormFlood: val,
                  })
                }
              />

              <TemplateWithVariables
                title="For each services damage"
                content={propertyDamage.hasServicesDamageStormFlood}
                onContentChange={(val) =>
                  handleChange({
                    hasServicesDamageStormFlood: val,
                  })
                }
              />
              <TemplateWithVariables
                title="For each bridge or culvert damage"
                content={propertyDamage.hasBridgeOrCulvertDamageStormFlood}
                onContentChange={(val) =>
                  handleChange({
                    hasBridgeOrCulvertDamageStormFlood: val,
                  })
                }
              />
            </Stack>
          </DialogContent>
          <DialogActions
            sx={{
              display: "flex",
              flexDirection: "row",
              margin: "0 1rem 1rem 1rem",
              alignItems: "flex-end",
            }}
          >
            <Button
              variant="contained"
              onClick={() => {
                onSave(propertyDamage);
              }}
              sx={{ width: 200 }}
            >
              Save
            </Button>
            <Button
              variant="outlined"
              onClick={() => {
                onClose();
              }}
              sx={{ width: 200 }}
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};
