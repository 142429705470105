import { TRPCRootRouter } from "@athena/server/src/trpc/index";
import styled from "@emotion/styled";
import { Button, Tab, Tabs } from "@mui/material";
import { inferProcedureInput, inferProcedureOutput } from "@trpc/server";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { trpc } from "src/lib/api/trpc";
import Menu from "src/modules/menu/Menu";
import { useCurrentUser } from "src/shared/hooks/useCurrentUser";
import { usePageName } from "src/shared/hooks/usePageName";
import { ClaimCompletion } from "../components/ClaimCompletion";
import { EngineeringCapacity } from "../components/EngineeringCapacity";
import {
  FiltersValues,
  NewEngineeringClaimFilters,
} from "../components/NewEngineeringClaimFilters";

type ServiceLevelAgreements = inferProcedureOutput<
  TRPCRootRouter["serviceLevelAgreements"]["getBatchSLA"]
>;
type ServiceLevelAgreementsInput = inferProcedureInput<
  TRPCRootRouter["serviceLevelAgreements"]["getBatchSLA"]
>;

export const Intelligence = () => {
  usePageName("Intelligence");
  const { userData } = useCurrentUser();
  const { organisation } = userData || {};

  const [params, setParams] = useSearchParams();
  const [selectedTab, setSelectedTab] = useState(0);
  const [showFilters, setShowFilters] = useState(false);
  const [filters, setFilters] = useState<FiltersValues>({});
  const engineeringClaimsQuery = trpc.claims.getAllClaims.useQuery({
    ...filters,
    includeDone: true,
  });
  const [events, setEvents] = useState<ServiceLevelAgreementsInput>([]);

  const claims = (engineeringClaimsQuery.data || []).map((x) => ({
    ...x,
    created: new Date(x.created),
    updated: new Date(x.updated),
    insUpdTs: new Date(x.insUpdTs),
    completedAt: x.completedAt,
    serviceRequestDate:
      (x.serviceRequestDate && new Date(x.serviceRequestDate)) || undefined,
    firstSiteVisitDate:
      (x.firstSiteVisitDate && new Date(x.firstSiteVisitDate)) || undefined,
    exportedMaps: [],
  }));

  const insurerEvents = trpc.serviceLevelAgreements.getBatchSLA.useQuery(
    events,
    {
      enabled: events.length > 0,
    }
  );

  useEffect(() => {
    const events: ServiceLevelAgreementsInput = [];
    for (const claim of claims) {
      claim.events.forEach((event) => {
        if (event.id && claim.insurer?.id) {
          const existingEvent = events.find(
            (x) => x.eventId === event.id && x.insurerId === claim.insurer?.id
          );
          if (!existingEvent) {
            events.push({
              eventId: event.id,
              insurerId: claim.insurer?.id,
            });
          }
        }
      });
    }
    setEvents(events);
  }, [engineeringClaimsQuery.data]);

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  return (
    <Menu>
      <WelcomeContainer>
        <ButtonContainer>
          <div>
            <h1>{organisation?.name || "Loading..."}</h1>
          </div>
          <ButtonContainer2>
            <Button
              variant="outlined"
              size="large"
              onClick={() => setShowFilters(true)}
            >
              Filter
            </Button>
            <Button
              disabled={Object.keys(filters).length === 0}
              variant="outlined"
              size="large"
              onClick={() => {
                setFilters({});
                setParams({});
              }}
            >
              Clear
            </Button>
            {/* <Button variant="outlined" size="large">
              Customise
            </Button> */}
          </ButtonContainer2>
        </ButtonContainer>
        <NewEngineeringClaimFilters
          activeClaims={claims || []}
          isOpen={showFilters}
          onChange={(filters) => setFilters(filters)}
          onFiltersClosed={() => setShowFilters(false)}
        />
        <Tabs
          variant="fullWidth"
          value={selectedTab}
          onChange={handleChangeTab}
        >
          <Tab label="Engineering Capacity" />
          <Tab label="Claim Completion" />
        </Tabs>
        {selectedTab === 0 && (
          <EngineeringCapacity
            claims={claims || []}
            events={insurerEvents.data || []}
            filters={filters}
            isLoadingActiveClaims={
              engineeringClaimsQuery.isLoading || insurerEvents.isLoading
            }
          />
        )}

        {selectedTab === 1 && <ClaimCompletion filters={filters} />}
      </WelcomeContainer>
    </Menu>
  );
};

const WelcomeContainer = styled.div`
  max-width: 1600px;
  width: 100%;
  margin: 1.5rem auto;
  padding: 0 1.5rem;
`;
const ButtonContainer = styled.div`
  display: flex;

  justify-content: space-between;
`;

const ButtonContainer2 = styled.div`
  display: flex;
  gap: 0.5rem;
  button {
    max-height: 48px;
  }
`;
