import {
  Paper,
  TextField,
  InputAdornment,
  Button,
  Typography,
  Chip,
} from "@mui/material";
import React, { useState } from "react";
import { useQuery } from "react-query";
import { axiosClient } from "src/lib/axiosClient";
import { formatDate } from "src/lib/date";
import SearchIcon from "@mui/icons-material/Search";
import styled from "@emotion/styled";
import { User } from "src/lib/types";
import { EditUserModal } from "./EditUserModal";
import { useCurrentUser } from "src/shared/hooks/useCurrentUser";
import { LoadingPage } from "@athena/components";

const BaseUserInfo = styled.div``;
const BaseUserCell = styled.div`
  &:not(:nth-last-of-type(-n + 5)) {
    border-bottom: 1px solid #d3d3d3;
  }
  padding-top: 1rem;
  padding-bottom: 1rem;
  display: flex;
  align-items: center;
  word-break: break-word;
  flex-wrap: "wrap";
`;
const BaseUserTable = styled.div`
  display: grid;
  grid-template-columns: 2fr 3fr 1fr 1fr 1fr;
  width: 100%;
`;

export const Users = () => {
  const [search, setSearch] = useState("");

  const { userData } = useCurrentUser();
  const { organisation } = userData || {};

  const userRolesMap = {
    insurer: ["user", "admin"],
    engineering: [
      "assessing-engineer",
      "reviewing-engineer",
      "admin",
      "data-entry",
      "coordinator",
    ],
    default: [] as string[],
  } as { [key: string]: string[] };

  const { isLoading, data: users } = useQuery(
    `users`,
    async (): Promise<User[]> => (await axiosClient.get(`/users`)).data
  );
  const [editUserId, setEditUserId] = useState<string>();
  if (isLoading) return <LoadingPage />;
  const editingUser = users?.find((user) => user.userId === editUserId);

  return (
    <div>
      {editUserId && editingUser && (
        <EditUserModal
          onClose={() => {
            setEditUserId(undefined);
          }}
          user={editingUser}
          options={userRolesMap[organisation?.orgType || "default"]}
        />
      )}
      <Paper
        sx={{
          padding: 2,
          mb: 2,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <TextField
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          value={search}
          placeholder="Search users"
          onChange={(e) => setSearch(e.target.value)}
        />
      </Paper>
      <Paper sx={{ padding: 2 }}>
        <BaseUserTable>
          <Typography
            variant="subtitle1"
            sx={{
              color: "#0067DF",
              borderBottom: "1px solid lightgray",
              paddingBottom: "1rem",
            }}
          >
            User
          </Typography>
          <Typography
            variant="subtitle1"
            sx={{
              color: "#0067DF",
              borderBottom: "1px solid lightgray",
              paddingBottom: "1rem",
            }}
          >
            Email
          </Typography>
          <Typography
            variant="subtitle1"
            sx={{
              color: "#0067DF",
              borderBottom: "1px solid lightgray",
              paddingBottom: "1rem",
            }}
          >
            Date Added
          </Typography>
          <Typography
            variant="subtitle1"
            sx={{
              color: "#0067DF",
              borderBottom: "1px solid lightgray",
              paddingBottom: "1rem",
            }}
          >
            Roles
          </Typography>
          <div
            style={{
              borderBottom: "1px solid lightgray",
              paddingBottom: "1rem",
            }}
          ></div>
          {users
            ?.filter((user) => {
              return (
                (
                  user.firstName?.toLowerCase() +
                  " " +
                  user.lastName?.toLowerCase()
                ).includes(search.toLowerCase()) ||
                user.email.toLowerCase().includes(search.toLowerCase()) ||
                user.roles.find((role) =>
                  role.toLowerCase().includes(search.toLowerCase())
                )
              );
            })
            ?.map((user: any) => {
              return (
                <React.Fragment key={user.userId}>
                  <BaseUserCell>
                    <BaseUserInfo>
                      <Typography variant={"body1"}>
                        {user.firstName
                          ? user.firstName + " " + user.lastName
                          : "Needs Setup"}
                      </Typography>
                    </BaseUserInfo>
                  </BaseUserCell>
                  <BaseUserCell>
                    <Typography
                      variant={"caption"}
                      sx={{ paddingRight: "1rem" }}
                    >
                      {user.email}
                    </Typography>
                  </BaseUserCell>
                  <BaseUserCell>
                    <Typography variant={"caption"}>
                      {user?.created ? formatDate(user?.created) : ""}
                    </Typography>
                  </BaseUserCell>
                  <BaseUserCell>
                    {user.roles?.map((role: string) => {
                      // convert role from kebab case to title case
                      const titleCaseRole = role
                        .split("-")
                        .map(
                          (word: string) =>
                            word[0].toUpperCase() + word.slice(1)
                        )
                        .join(" ");
                      return (
                        <Chip
                          key={role}
                          label={titleCaseRole}
                          sx={{
                            paddingLeft: 2,
                            paddingRight: 2,
                            mr: 2,
                          }}
                        />
                      );
                    })}
                  </BaseUserCell>
                  <BaseUserCell>
                    <Button
                      variant="outlined"
                      sx={{ width: 100 }}
                      onClick={() => {
                        setEditUserId(user.userId);
                      }}
                    >
                      Edit
                    </Button>
                  </BaseUserCell>
                </React.Fragment>
              );
            })}
        </BaseUserTable>
      </Paper>
    </div>
  );
};
