import { Box, Tab, Tabs, Typography } from "@mui/material";
import React from "react";
import ClaimReportReadOnly from "src/modules/claims/claimReport/ClaimReportReadOnly";
import Menu from "../../menu/Menu";
import { EngineeringClaimDetails } from "./EngineeringClaimDetails";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}
function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export const LossAdjusterClaimPage = () => {
  const [value, setValue] = React.useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Menu>
      <Box sx={{ maxWidth: "1800px", margin: "auto" }}>
        <Box sx={{ borderBottom: 2, borderColor: "divider", mt: 3 }}>
          <Tabs value={value} onChange={handleChange} variant="fullWidth">
            <Tab label="Claim Details" {...a11yProps(0)} />
            <Tab label="Report" {...a11yProps(1)} />
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
          <EngineeringClaimDetails />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <ClaimReportReadOnly />
        </CustomTabPanel>
      </Box>
    </Menu>
  );
};
