import { LoadingSpinner } from "@athena/components";
import { SortOrder } from "@athena/server/src/lib/typeSense/searchTypesenseCollection";
import styled from "@emotion/styled";
import SearchIcon from "@mui/icons-material/Search";
import { InputAdornment, TextField } from "@mui/material";
import Button from "@mui/material/Button";
import { GridPaginationModel, GridSortModel } from "@mui/x-data-grid";
import { createRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { trpc } from "src/lib/api/trpc";
import { z } from "zod";
import { ImportCsvFile } from "../ImportClaimsCsvFile";
import {
  InsuranceClaimFilters,
  InsuranceClaimsFiltersValues,
} from "../InsuranceClaimFilters";
import {
  InsuranceClaimsTable,
  InsuranceClaimsTableColumn,
} from "./components/InsuranceClaimsTable";

import { useDebounceFn } from "ahooks";
import { useCurrentUser } from "src/shared/hooks/useCurrentUser";

export const insuranceClaimSchema = z.object({
  claimReference: z.string(),
  policyReference: z.string(),
  created: z.date({ coerce: true }),
  updated: z.date({ coerce: true }),
  status: z.string(),
  additionalData: z.record(z.string(), z.string()).optional().nullable(),
  location: z.object({
    geo: z.object({
      type: z.literal("Point"),
      coordinates: z.tuple([z.number(), z.number()]),
    }),
    // originalAddress: z.string(),
    address: z.string(),
    // region: z.string(),
  }),
  issues: z
    .object({
      dataMismatch: z
        .object({
          status: z.string().optional().nullable(),
          address: z.string().optional().nullable(),
          latitude: z.number({ coerce: true }).optional().nullable(),
          longitude: z.number({ coerce: true }).optional().nullable(),
          policyReference: z.string().optional().nullable(),
        })
        .optional(),
    })
    .optional()
    .nullable(),
});

type ClaimCounts = {
  [key: string]: number;
};

export function InsuranceClaimsList() {
  const { userData } = useCurrentUser();

  const [params, setParams] = useSearchParams();
  const [isChoosingFile, setIsChoosingFile] = useState<boolean>(false);
  const [showFilters, setShowFilters] = useState(false);
  const [filters, setFilters] = useState<InsuranceClaimsFiltersValues>({});
  const [claimSearch, setClaimSearch] = useState<string>("");
  const [pagination, setPagination] = useState<GridPaginationModel>({
    page: 0,
    pageSize: 100,
  });

  const searchRef = createRef<HTMLInputElement>();
  const [sort, setSort] = useState<GridSortModel>([
    {
      field: InsuranceClaimsTableColumn.Updated,
      sort: "desc",
    },
  ]);

  const insuranceClaimsQuery = trpc.insuranceClaims.get.useQuery(
    {
      pagination: {
        page: pagination.page + 1,
        pageSize: pagination.pageSize,
      },
      sort: {
        field:
          (sort[0]?.field as Exclude<
            InsuranceClaimsTableColumn,
            | InsuranceClaimsTableColumn.Action
            | InsuranceClaimsTableColumn.Issues
            | InsuranceClaimsTableColumn.AdditionalData
            | InsuranceClaimsTableColumn.LossAdjuster
            | InsuranceClaimsTableColumn.Insurer
          >) || InsuranceClaimsTableColumn.Updated,
        direction: (sort[0]?.sort || "desc") as SortOrder,
      },
      filters,
      search: claimSearch,
    },
    {
      refetchInterval: 5000,
    }
  );

  const claims = (insuranceClaimsQuery.data?.currentPage || []).map((x) =>
    insuranceClaimSchema.parse({
      claimReference: x.claimReference,
      policyReference: x.policyReference,
      created: x.created,
      updated: x.insUpdTs,
      status: x.status,
      additionalData: x.additionalData,
      issues: x.issues,
      location: x.location,
    })
  );

  const isLoading = insuranceClaimsQuery.isLoading;

  const resetFilters = () => {
    setFilters({});
    setClaimSearch("");
    setParams({});
    if (searchRef.current) searchRef.current.value = "";
  };

  const { run } = useDebounceFn<
    (e: React.ChangeEvent<HTMLInputElement>) => void
  >(
    (e) => {
      setClaimSearch(e.target.value);
    },
    {
      wait: 500,
    }
  );

  return (
    <>
      <ClaimHeader>
        <div className="claimActions">
          <TextField
            sx={{
              minHeight: "3rem",
              ".MuiOutlinedInput-root ": { height: "100%" },
            }}
            id="input-with-icon-textfield"
            variant="outlined"
            placeholder="Search Claims"
            onChange={run}
            InputProps={{
              inputRef: searchRef,
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
          <Button
            variant="outlined"
            onClick={() => setShowFilters(!showFilters)}
            sx={{ width: 100 }}
          >
            Filter
          </Button>
          <Button
            variant="outlined"
            disabled={Object.keys(filters).length === 0 && claimSearch === ""}
            onClick={resetFilters}
            sx={{ width: 100 }}
          >
            Clear
          </Button>
        </div>

        {userData?.organisation?.orgType !== "eqc" && (
          <Button
            component="label"
            variant="contained"
            sx={{ width: "240px" }}
            onClick={() => setIsChoosingFile(true)}
          >
            Upload Claims
          </Button>
        )}
      </ClaimHeader>

      <InsuranceClaimFilters
        isOpen={showFilters}
        onChange={(filters) => setFilters(filters)}
        onFiltersClosed={() => setShowFilters(false)}
      />

      {isLoading ? (
        <LoadingContainer>
          <LoadingSpinner />
          <p>Loading Claims...</p>
        </LoadingContainer>
      ) : (
        <InsuranceClaimsTable
          claims={claims}
          rowCount={insuranceClaimsQuery.data?.total || 0}
          pagination={pagination}
          onPaginationChange={(p) => setPagination(p)}
          sort={sort}
          onSortChange={(s) => setSort(s)}
        />
      )}
      {isChoosingFile && (
        <ImportCsvFile onClose={() => setIsChoosingFile(false)} />
      )}
    </>
  );
}
const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;
  height: 70vh;
`;

const ClaimHeader = styled.div`
  background-color: white;
  border-bottom: 1px solid lightgray;
  padding: 1.5rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  .claimActions {
    display: flex;
    gap: 1rem;
  }
`;

const ClaimStats = styled.div`
  background-color: #f4f6ff;
  border-bottom: 1px solid lightgray;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  a {
    text-decoration: none;
    color: #0067df;
    font-weight: 500;
  }
  h3 {
    margin: 0;
    font-size: 2rem;
  }
  div {
    flex: 1;
    border-right: 1px solid lightgray;
    padding: 1rem 1.5rem;
  }

  @media (max-width: 800px) {
    h3 {
      font-size: 1.5rem;
    }
    a {
      font-size: 0.8rem;
    }
  }
`;
