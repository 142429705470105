import ContentLoader from "react-content-loader";

export const FeaturePopupSkeleton = () => (
  <ContentLoader
    speed={2}
    width={320}
    height={100}
    viewBox="0 0 320 100"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
  >
    <rect y="5" x="6" rx="3" ry="3" width="100" height="16" />
    <rect y="5" x="258" rx="3" ry="3" width="16" height="16" />
    <rect y="5" x="278" rx="3" ry="3" width="16" height="16" />
    <rect y="5" x="298" rx="3" ry="3" width="16" height="16" />
    <rect y="25" x="6" rx="1" ry="1" width="310" height="2" />
    <rect y="31" x="6" rx="3" ry="3" width="202" height="16" />
    <rect y="31" x="246" rx="3" ry="3" width="67" height="16" />
    <rect y="52" x="6" rx="1" ry="1" width="310" height="2" />
    <rect y="58" x="6" rx="3" ry="3" width="100" height="16" />
    <rect y="79" x="6" rx="3" ry="3" width="240" height="16" />
  </ContentLoader>
);
