import { UserData } from "@athena/server/src/trpc/routers/user/types";
import * as Sentry from "@sentry/react";
import { useAsyncEffect } from "ahooks";
import { useEffect, useState } from "react";
import { trpc } from "src/lib/api/trpc";
import { setUserId } from "src/lib/user";
import { useNetworkContext } from "./useNetworkContext";
import { useStore } from "./useStore";

const STORE_KEY = "current-user-data";

export const useCurrentUser = () => {
  const { online } = useNetworkContext();

  const userDataStore = useStore<UserData>(STORE_KEY);
  const [currentUserData, setCurrentUserData] = useState<UserData>();
  const [initializing, setInitializing] = useState(true);

  useEffect(() => {
    if (!online) {
      (async () => {
        const userData = await userDataStore.get();
        setCurrentUserData(userData);
        setInitializing(false);
      })();
    }
  }, [online]);

  const {
    data: userData,
    isLoading,
    isFetched,
  } = trpc.user.getUserData.useQuery(undefined, {
    enabled: online,
  });
  useAsyncEffect(async () => {
    if (isFetched && userData) {
      const { user } = userData;
      const storedUserData = await userDataStore.get();
      if (
        user &&
        storedUserData &&
        storedUserData.user &&
        storedUserData.user.userId !== user.userId
      ) {
        await userDataStore.clear();
      }
      await userDataStore.set(userData);
      setCurrentUserData(userData);
      Sentry.setUser({ email: user.email });
      setUserId(user.userId);
    }
  }, [isFetched, userData]);

  if (online === false && !initializing) {
    return { userData: currentUserData, isLoading: false, isFetched: true };
  }

  return { userData, isLoading, isFetched };
};
