import { useAuth, useOrganizationList } from "@clerk/clerk-react";
import { useTimeout } from "ahooks";
import { useState, useEffect } from "react";
import { LoadingPage } from "@athena/components";

export const ActiveOrg = (props: React.PropsWithChildren) => {
  const [hasActiveOrg, setHasActiveOrg] = useState(false);
  const { orgId, isLoaded } = useAuth();
  const {
    userMemberships,
    setActive,
    isLoaded: orgsLoaded,
  } = useOrganizationList({
    userMemberships: true,
  });
  useEffect(() => {
    if (!hasActiveOrg && isLoaded) {
      if (userMemberships.data?.length) {
        setActive?.({ organization: userMemberships.data[0].organization.id });
      }
    }
  }, [userMemberships.data, isLoaded, orgsLoaded]);
  useEffect(() => {
    if (orgId) setHasActiveOrg(true);
  }, [orgId]);

  useTimeout(() => {
    if (isLoaded && orgsLoaded && userMemberships.data.length === 0) {
      window.location.reload();
    }
  }, 5000);

  if (!hasActiveOrg) return <LoadingPage />;

  return props.children;
};
