import { Banner, LoadingSpinner } from "@athena/components";
import { CreateClaim, Urgency } from "@athena/server/src/api/types/claim";
import { UpdateClaim } from "@athena/server/src/api/types/claimAssessment";
import {
  Status,
  getStatusNextAction,
} from "@athena/server/src/api/types/claimStatuses";
import CloudSyncIcon from "@mui/icons-material/CloudSync";
import ScheduleIcon from "@mui/icons-material/Schedule";
import { Box, Button, Container, Tabs } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useQuery } from "react-query";
import { Outlet, useLocation, useParams } from "react-router-dom";
import { PageNameContext } from "src/App";
import { axiosClient } from "src/lib/axiosClient";
import { ErrorBoundary } from "src/shared/ErrorBoundary";
import { useNetworkContext } from "src/shared/hooks/useNetworkContext";
import { LinkTab } from "src/shared/linkTab/LinkTab";
import {
  enqueueRedirectSnackbar,
  enqueueWarningSnackbar,
} from "src/shared/snackbar/SnackbarHelper";
import Menu from "../menu/Menu";
import { ClaimStatus } from "./ClaimStatus";
import { useOfflineClaim } from "./useOfflineClaim";

const getLabelForClaim = (claim?: UpdateClaim | CreateClaim) => {
  if (!claim) return "New Claim";
  let label = "Claim ";
  if (claim.reference) label += claim.reference;
  if (claim.location) {
    if (claim.location.address) label += " - " + claim.location.address;
    else if (claim.location.manual?.address)
      label += " - " + claim.location.manual.address;
    else if (claim.location.parcelAddress)
      label += " - " + claim.location.parcelAddress;
  }
  return label;
};

export function Claims() {
  const { claimId } = useParams();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const pathSections = location.pathname.split("/");
  const lastSection = pathSections[pathSections.length - 1];
  const {
    claim: savedClaim,
    loading,
    needsSync,
    syncClaim,
    formIsDirty,
  } = useOfflineClaim(claimId);
  const { setPageName } = useContext(PageNameContext);
  useEffect(() => {
    if (loading) return;
    setPageName(getLabelForClaim(savedClaim));
  }, [savedClaim, loading]);
  const { online } = useNetworkContext();
  const claim =
    claimId === "new"
      ? {
          claimId: "new",
          location: { geo: {} },
          events: [],
          additionalStatuses: [],
          assignedEngineerIds: [],
          assignedEngineers: [],
          reviewingEngineerIds: [],
          reviewingEngineers: [],
          completed: false,
          customers: [{}],
          status: Status.Setup,
          urgency: Urgency.Normal,
        }
      : savedClaim;
  const [selectedTab, setSelectedTab] = useState(lastSection || "claimDetails");
  const { isLoading: loadingClaimReport, data: claimReport } = useQuery(
    `/claims/${claimId}/report`,
    async () => (await axiosClient.get(`/claims/${claimId}/report`)).data,
    { enabled: online }
  );
  useEffect(() => {
    setSelectedTab(lastSection);
  }, [lastSection]);
  useEffect(() => {
    if (queryParams.get("reportRedirectElId")) {
      enqueueRedirectSnackbar(
        "We've redirected you to edit a locked value.",
        `/claim/${claimId}/report#${queryParams.get("reportRedirectElId")}`
      );
    }
  }, [queryParams.get("reportRedirectElId")]);
  if (loading || loadingClaimReport) {
    return (
      <Menu>
        <LoadingSpinner />
      </Menu>
    );
  } else if (!claim) {
    // Hack guard for silencing type errors
    return <Menu>Sorry an error has occurred... Please try again later</Menu>;
  }

  const isNewClaim = claimId === "new";
  return (
    <Menu>
      <ErrorBoundary>
        <Box sx={{ flexGrow: 1, mb: 2 }}>
          <Container
            sx={{
              marginTop: 3,
              maxWidth: "1800px",
            }}
            maxWidth={false}
          >
            <Banner
              icon={<ScheduleIcon />}
              subtitle={getStatusNextAction(claim.status as Status)}
              title="Next action:"
            />{" "}
            {needsSync && (
              <Banner
                icon={<CloudSyncIcon />}
                subtitle={
                  <Box
                    sx={{
                      display: "inline-flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      flex: 1,
                    }}
                  >
                    {!online && (
                      <span>
                        You have unsynced changes, when you go back online, you
                        will be able to sync them.
                      </span>
                    )}
                    {online && (
                      <>
                        <span>
                          You have unsynced changes, click sync to save them.
                          This will overwrite any changes made on other devices.
                        </span>
                        <Button
                          variant="contained"
                          sx={{ width: 150 }}
                          onClick={() => {
                            if (formIsDirty) {
                              enqueueWarningSnackbar(
                                "Please save your changes before syncing."
                              );
                            } else {
                              syncClaim();
                            }
                          }}
                        >
                          Sync
                        </Button>
                      </>
                    )}
                  </Box>
                }
                title="Data sync:"
                variant={"warning"}
              />
            )}
            {!loading && (
              <>
                {!isNewClaim && (
                  <ClaimStatus
                    status={claim.status as Status}
                    additionalStatuses={claim.additionalStatuses || []}
                    assignedEngineers={claim.assignedEngineers || []}
                    reviewingEngineers={claim.reviewingEngineers || []}
                    claimId={claimId}
                    urgency={claim?.urgency}
                  />
                )}
              </>
            )}
            <Box sx={{ borderBottom: 2, borderColor: "divider", mb: 3 }}>
              {!isNewClaim && (
                <Tabs
                  value={selectedTab}
                  onChange={(_, newTab) => {
                    setSelectedTab(newTab);
                  }}
                  aria-label="basic tabs example"
                  variant="fullWidth"
                >
                  <LinkTab
                    label="Claim Details"
                    value={"details"}
                    link={"details"}
                  />
                  <LinkTab
                    label="Assessment"
                    value={"assessment"}
                    link={"assessment"}
                  />
                  {claimReport && (
                    <LinkTab label="Report" value={"report"} link={"report"} />
                  )}
                  <LinkTab label="Review" value={"review"} link={"review"} />
                </Tabs>
              )}
            </Box>
            {!loading && <Outlet />}
          </Container>
        </Box>
      </ErrorBoundary>
    </Menu>
  );
}
