import { TReportTemplateStyles } from "@athena/server/src/api/types/reportTemplate";
import { Button, Tab, Tabs } from "@mui/material";
import { Box } from "@mui/system";
import { useMemo, useState } from "react";
import { Control, FieldValues, Path } from "react-hook-form";
import { FontStyle } from "./FontStyle";
import { HeadingStyle } from "./HeadingStyle";
import { PhotographStyle } from "./PhotographStyle";
import { TableStyle } from "./TableStyles";

function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

type StyleTab = {
  label: string;
  sub?: string;
  header?: string;
  type: Path<TReportTemplateStyles>;
  content: JSX.Element;
  notes?: string[];
};

interface StyleEditorProps<TReportTemplateStyles extends FieldValues> {
  onChange: (styles: TReportTemplateStyles) => void;
  onReset: (path: Path<TReportTemplateStyles>) => void;
  setValue: (path: Path<TReportTemplateStyles>, value: any) => void;
  control: Control<TReportTemplateStyles>;
}

export const StyleEditor = (props: StyleEditorProps<TReportTemplateStyles>) => {
  const [selectedTab, setSelectedTab] = useState(0);
  const { control, onChange, setValue } = props;

  const handleTabChange = (_: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  const tabs = useMemo(
    (): StyleTab[] => [
      {
        label: "Heading 1",
        sub: "h1",
        type: "h1",
        content: <HeadingStyle type="h1" {...props} />,
        notes: [
          "*Right hand margin only applies to between the heading number template variable and the heading text*",
        ],
      },
      {
        label: "Heading 2",
        type: "h2",
        content: <HeadingStyle type="h2" {...props} />,
        notes: [
          "*Right hand margin only applies to between the heading number template variable and the heading text*",
        ],
      },
      {
        label: "Heading 3",
        type: "h3",
        content: <HeadingStyle type="h3" {...props} />,
        notes: [
          "*Right hand margin only applies to between the heading number template variable and the heading text*",
        ],
      },
      {
        label: "Paragraph",
        type: "p",
        content: <HeadingStyle type="p" {...props} />,
      },
      {
        label: "Superscript",
        type: "other.superscript",
        content: (
          <div style={{ paddingLeft: "1rem" }}>
            <FontStyle type="other.superscript" {...props} />
          </div>
        ),
      },
      {
        label: "Body",
        type: "body",
        content: <HeadingStyle type="body.font" {...props} />,
      },
      {
        label: "Table Border",
        type: "table",
        content: <TableStyle type="table" {...props} />,
      },
      {
        label: "Table Header",
        type: "table.title",
        content: <TableStyle type="table.title" {...props} />,
      },
      {
        label: "Table Body",
        type: "table.content",
        content: <TableStyle type="table.content" {...props} />,
      },
      {
        label: "Photographs",
        type: "photograph",
        content: <PhotographStyle type="photograph" {...props} />,
      },
      {
        label: "Photographs Caption",
        type: "photograph.caption",
        content: <PhotographStyle type="photograph.caption" {...props} />,
      },
    ],
    []
  );

  return (
    <div
      style={{
        position: "relative",
        flex: 1,
        border: "1px solid #ececec",
        marginBottom: "1rem",

        borderRadius: "1rem",
        display: "flex",
        height: "60vh",
        overflowY: "scroll",
      }}
    >
      <Box
        sx={{
          top: "0",
          borderRight: 1,
          borderColor: "divider",
          position: "sticky",
        }}
      >
        <Tabs
          value={selectedTab}
          onChange={handleTabChange}
          aria-label="style editing tabs"
          orientation="vertical"
          variant="scrollable"
          sx={{
            width: "150px",
            top: "0",
            height: "60vh",
          }}
        >
          {tabs.map((tab, index) => (
            <Tab
              key={tab.label}
              label={tab.label}
              {...a11yProps(index)}
              sx={{
                alignItems: "start",
                borderBottom: "1px solid #ececec",

                textAlign: "start",
                padding: "1rem",
              }}
            />
          ))}
        </Tabs>
      </Box>
      <Box sx={{ padding: "1rem" }}>
        {tabs.map((tab, index) => (
          <Box key={`${tab.label}-content`} hidden={selectedTab !== index}>
            <div
              style={{
                width: "100%",
                borderBottom: "1px solid #ececec",
                paddingBottom: "1rem",
                paddingLeft: "1rem",
                marginBottom: "0.5rem",
              }}
            >
              <h2>{tab.header || tab.label} styles</h2>
              <p style={{ color: "gray", marginTop: "0.5rem" }}>
                This will change how {tab.label || tab.type} components appear
                in your report
              </p>
            </div>
            {tab.content}

            {tab.notes && (
              <Box
                sx={{
                  paddingLeft: "2rem",
                  paddingTop: "1rem",
                  fontSize: 12,
                  fontWeight: "bold",
                }}
              >
                {tab.notes.map((note) => (
                  <span key={note}>{note}</span>
                ))}
              </Box>
            )}
            <div
              style={{
                borderTop: "1px solid #ececec",
                margin: "1rem",

                paddingTop: "1.5rem",
              }}
            >
              <h4 style={{ paddingTop: "0.5rem" }}>
                Reset {tab.label} styling
              </h4>
              <p
                style={{
                  color: "gray",
                  marginTop: "0.5rem",
                  marginBottom: "1rem",
                }}
              >
                This will reset all styles for {tab.label} to default settings
              </p>
              <Button
                variant="outlined"
                style={{ marginBottom: "2rem" }}
                onClick={() => {
                  props.onReset(tab.type);
                }}
              >
                Reset {tab.label} styling
              </Button>
            </div>
          </Box>
        ))}
      </Box>
    </div>
  );
};
