import { createContext, useContext } from "react";
import { UserData } from "@athena/server/src/trpc/routers/user/types";

export const CurrentUserContext = createContext<UserData | undefined>(undefined);

export const useCurrentUserContext = () => {
  const currentUserContext = useContext(CurrentUserContext);
  if (!currentUserContext) {
    throw new Error(
      "useCurrentUserContext must be used within an CurrentUserContextProvider"
    );
  }
  return currentUserContext;
};