export enum Status {
  Setup = "Needs Setup",
  NeedsEngineer = "Needs Engineer",
  WaitingForCustomer = "Contact Customer", // After engineer assigned
  // WaitingForEquipment = "Waiting For Equipment",
  VisitBooked = "Visit Booked",
  VisitCompleted = "Visit Completed",
  PreparingDraft = "Preparing Draft",
  // Move these down
  // NeedsRevisit = "Needs Revisit",
  // NeedsRevisitWithEquipment = "Needs Revisit With Equipment",
  // NeedsRevisitWithSenior = "Needs Revisit With Senior",
  UnderInternalReview = "Under Internal Review",
  // AuthorizedDraft = "Authorized Draft",
  UnderClientReview = "Under Client Review",
  // ReportReceivedByClient = "Report Received By Client",
  // ChangesRequested = "Changes Requested",
  ClientAccepted = "Client Accepted",
  // PreparingForFinalRelease = "Preparing For Final Release",
  Done = "Done", // woohoo
}

export enum AdditionalStatus {
  AdditionalDetailsRequested = "Additional Details Required to Finish the Claim",
  TimeExempt = "Time Exempt",
  SiteRevisit = "Site Revisit",
  HealthAndSafety = "Health and Safety Considerations",
  AdditionalRequestFromClient = "Additional Request from Client",
  ConflictOfInterest = "Conflict of Interest",
  Overdue = "Overdue",
  AutoOverdue = "AutoOverdue",
  DetailsIncomplete = "Claim Details Incomplete",
}

export enum ClaimEventHistoryType {
  CommentAdded = "CommentAdded",
  CommentReply = "CommentReply",
  StatusChange = "StatusChange",
  FinalReportSent = "FinalReportSent",
  ApprovedForFinalRelease = "ApprovedForFinalRelease",
  EditedReport = "EditedReport",
  LossAdjusterApprovedClaimReport = "LossAdjusterApprovedClaimReport",
  ApprovedDraftReport = "ApprovedDraftReport",
  EditedDraftReport = "EditedDraftReport",
  EditedClaimAssessment = "EditedClaimAssessment",
  LossAdjusterRequestedChanges = "LossAdjusterRequestedChanges",
  LossAdjusterReceivedReport = "LossAdjusterReceivedReport",
  LossAdjusterSentReport = "LossAdjusterSentReport",
  ReviewingEngineerAssigned = "ReviewingEngineerAssigned",
  SubmittedDraftReportForReview = "SubmittedDraftReportForReview",
  GeneratedDraftReport = "GeneratedDraftReport",
  AssessmentStarted = "AssessmentStarted",
  EngineerAssigned = "EngineerAssigned",
  EditedClaimDetails = "EditedClaimDetails",
  CompletedClaimDetails = "CompletedClaimDetails",
  ClaimCreated = "ClaimCreated",
  SnapshotCreated = "SnapshotCreated",
}

export const preVisitStatuses = [
  Status.Setup,
  Status.NeedsEngineer,
  Status.WaitingForCustomer,
  Status.VisitBooked,
];

export const postVisitStatuses = [
  Status.PreparingDraft,
  Status.UnderInternalReview,
  Status.UnderClientReview,
  Status.ClientAccepted,
  // Status.NeedsRevisit,
  // Status.NeedsRevisitWithEquipment,
  // Status.NeedsRevisitWithSenior,
];

export const prepStatuses = [
  Status.Setup,
  Status.NeedsEngineer,
  Status.WaitingForCustomer,
  Status.VisitBooked,
];

export const backlogStatuses = [Status.Setup];

export const inProgressStatuses = [
  Status.NeedsEngineer,
  Status.WaitingForCustomer,
  Status.VisitBooked,
  Status.PreparingDraft,
  // Status.NeedsRevisit,
  // Status.NeedsRevisitWithEquipment,
  // Status.NeedsRevisitWithSenior,
];

export const inSetupStatuses = [Status.Setup];

export const underInternalReviewStatuses = [Status.UnderInternalReview];

export const underReviewStatuses = [
  Status.UnderInternalReview,
  Status.UnderClientReview,
  Status.ClientAccepted,
];

export const underClientReviewStatuses = [
  Status.UnderClientReview,
  Status.ClientAccepted,
];

export const beingPreparedStatuses = [
  Status.Setup,
  Status.NeedsEngineer,
  Status.WaitingForCustomer,
  Status.VisitBooked,
];

export const underAssessmentStatuses = [
  Status.PreparingDraft,
  Status.UnderInternalReview,
  // Status.NeedsRevisit,
  // Status.NeedsRevisitWithEquipment,
  // Status.NeedsRevisitWithSenior,
];

export const completeStatuses = [Status.Done];

export const getStatusDescription = (status: Status) => {
  if (status === Status.Setup)
    return "The claim needs setup information before it can begin.";
  if (status === Status.NeedsEngineer)
    return "The claim needs an engineer before it can begin.";
  if (status === Status.WaitingForCustomer)
    return "The claim needs a response from the customer before it can begin.";
  // if (status === Status.WaitingForEquipment)
  //   return "The claim needs specific equipment before it can begin.";
  if (status === Status.VisitBooked)
    return "The claim has an engineer booked to visit the claim site.";
  if (status === Status.VisitCompleted)
    return "The engineer has visited the site and has filled out the claim assessment form.";
  if (status === Status.PreparingDraft)
    return "The engineer has visited the site and has started preparing the report.";
  // if (status === Status.NeedsRevisit)
  //   return "The engineer has visited the site and has tried preparing the report, but needs more information to continue.";
  // if (status === Status.NeedsRevisitWithEquipment)
  //   return "The engineer has visited the site and has tried preparing the report, but needs more information requiring specific equipment to continue.";
  // if (status === Status.NeedsRevisitWithSenior)
  //   return "The engineer has visited the site and has tried preparing the report, but needs more information from a senior to continue.";
  if (status === Status.UnderInternalReview)
    return "The engineer has completed the report for the site. The report is being reviewed by the reviewing engineer";
  // if (status === Status.AuthorizedDraft)
  //   return "The reviewing engineer has signed off the report. It is now ready to be sent to the client.";
  if (status === Status.UnderClientReview)
    return "The report has been sent to the client.";
  // if (status === Status.ReportReceivedByClient)
  //   return "The report has been opened by the client.";
  // if (status === Status.ChangesRequested)
  //   return "The client has requested changes to the report.";
  if (status === Status.ClientAccepted)
    return "The client has accepted the report. It now needs to be reviewed for final release by the reviewing engineer.";
  // if (status === Status.PreparingForFinalRelease)
  //   return "The assessing engineer is finalising the report for final release.";
  if (status === Status.Done)
    return "The final release has been sent to the client.";
};

export const getStatusNextAction = (status: Status) => {
  if (status === Status.Setup)
    return "Fill out Claim details so that work can continue on this claim";
  if (status === Status.NeedsEngineer)
    return "Find an engineer to add to the claim";
  if (status === Status.WaitingForCustomer) return "Contact the customer";
  // if (status === Status.WaitingForEquipment) return "Organise equipment";
  if (status === Status.VisitBooked) return "Visit the site";
  if (status === Status.VisitCompleted)
    return "Check over the claim assessment and generate your report";
  if (status === Status.PreparingDraft) return "Prepare the draft report";
  // if (status === Status.NeedsRevisit) return "Revisit the site";
  // if (status === Status.NeedsRevisitWithEquipment)
  //   return "Revisit the site with equipment";
  // if (status === Status.NeedsRevisitWithSenior)
  //   return "Revisit the site with a senior";
  if (status === Status.UnderInternalReview) return "Review the draft report";
  // if (status === Status.AuthorizedDraft)
  //   return "Send the report to the client for review";
  if (status === Status.UnderClientReview) return "Client to review the report";
  // if (status === Status.ReportReceivedByClient)
  //   return "Client to review the report";
  // if (status === Status.ChangesRequested)
  //   return "Review the report changes requested by the client";
  if (status === Status.ClientAccepted)
    return "Review the report for final release";
  // if (status === Status.PreparingForFinalRelease)
  //   return "Finalise the report for final release";
  if (status === Status.Done)
    // Hide the banner?
    return "The claim is completed, there is no more to do";

  return "";
};

export const getStatusTransitions = (status: Status) => {
  if (status === Status.Setup) return [Status.NeedsEngineer];
  if (status === Status.NeedsEngineer)
    return [
      Status.WaitingForCustomer,
      // Status.WaitingForEquipment,
      Status.VisitBooked,
    ];
  if (status === Status.WaitingForCustomer)
    return [
      Status.NeedsEngineer,
      // Status.WaitingForEquipment,
      Status.VisitBooked,
    ];
  // if (status === Status.WaitingForEquipment)
  //   return [
  //     Status.NeedsEngineer,
  //     Status.WaitingForCustomer,
  //     Status.VisitBooked,
  //   ];
  if (status === Status.VisitBooked) return [Status.VisitCompleted];
  if (status === Status.VisitCompleted) return [Status.PreparingDraft];
  if (status === Status.PreparingDraft)
    return [
      // Status.NeedsRevisit,
      // Status.NeedsRevisitWithEquipment,
      // Status.NeedsRevisitWithSenior,
      Status.UnderInternalReview,
    ];
  // if (status === Status.NeedsRevisit)
  //   return [
  //     Status.PreparingDraft,
  //     Status.NeedsRevisitWithEquipment,
  //     Status.NeedsRevisitWithSenior,
  //     Status.UnderInternalReview,
  //   ];
  // if (status === Status.NeedsRevisitWithEquipment)
  //   return [
  //     Status.PreparingDraft,
  //     Status.NeedsRevisit,
  //     Status.NeedsRevisitWithSenior,
  //     Status.UnderInternalReview,
  //   ];
  // if (status === Status.NeedsRevisitWithSenior)
  //   return [
  //     Status.PreparingDraft,
  //     Status.NeedsRevisit,
  //     Status.NeedsRevisitWithEquipment,
  //     Status.UnderInternalReview,
  //   ];
  // if (status === Status.UnderInternalReview) return [Status.AuthorizedDraft];
  // if (status === Status.AuthorizedDraft) return [Status.UnderClientReview];
  // if (status === Status.UnderClientReview)
  //   return [Status.ReportReceivedByClient];
  // if (status === Status.ReportReceivedByClient)
  //   return [Status.ChangesRequested, Status.ClientAccepted];
  // if (status === Status.ChangesRequested) return [Status.UnderInternalReview];
  // if (status === Status.ClientAccepted)
  //   return [Status.PreparingForFinalRelease];
  // if (status === Status.PreparingForFinalRelease) return [Status.Done];
  if (status === Status.Done) return [];
  return [];
};
