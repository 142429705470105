import { LoadingSpinner } from "@athena/components";
import { UpdateClaim } from "@athena/server/src/api/types/claimAssessment";
import {
  annotatedAerialPhotographs,
  sketches,
} from "@athena/server/src/api/types/templateVariables";
import styled from "@emotion/styled";
import { Button, Divider, Paper, Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { JSONContent } from "@tiptap/core";
import { Ref, useEffect, useRef, useState } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { trpc } from "src/lib/api/trpc";
import { axiosClient } from "src/lib/axiosClient";
import { useCurrentUserContext } from "src/shared/hooks/useCurrentUserContext";
import { InlineEditor } from "src/shared/reportBuilder/InlineEditor";
import {
  TextEditor,
  TextEditorRef,
} from "../../../shared/reportBuilder/TextEditor";
import { ClaimReportComment } from "./ClaimReportComment";
// interface ClaimReportProps {
//   claim: Claim;
// }

export type TrackedChange = {
  id: string;
  username: string;
  content: string;
  type: "insert" | "delete";
};

export type Report = {
  reportId: string;
  versions: { publicVersion: string; s3Key: string; pdfKey?: string }[];
  isReviewing: boolean;
  isApproved: boolean;
  templateId: string;
  isClientApproved: boolean;
  footer: string;
  template: any;
  comments: any[];
};

export default function ClaimReportReadOnly() {
  const { claimId, organisationId } = useParams();
  const [report, setReport] = useState<JSONContent>();
  const [isDirty, setIsDirty] = useState(false);
  const [trackedChangesEnabled, setTrackedChangesEnabled] = useState(false);
  const [trackedChangesMarkupEnabled, setTrackedChangesMarkupEnabled] =
    useState(true);
  const { user } = useCurrentUserContext();
  const [selectedVersion, setSelectedVersion] = useState<string>();
  const editorRef = useRef<TextEditorRef>();
  const aerialsRef = useRef<TextEditorRef>();
  const sketchesRef = useRef<TextEditorRef>();
  const footerRef = useRef<TextEditorRef>();
  const [comments, setComments] = useState<any[]>([]);
  const [trackedChanges, setTrackedChanges] = useState<any[]>([]);
  const [selectedCommentUuid, setSelectedCommentUuid] = useState<string>();
  const [otherUsersEditing, setOtherUsersEditing] = useState([]);
  const [editorInitialized, setEditorInitialized] = useState(false);
  const [openPosition, setOpenPosition] = useState<string>();
  const [showResolvedComments, setShowResolvedComments] = useState(false);
  if (!claimId || !organisationId) {
    throw new Error("");
  }
  const {
    isLoading,
    data: claim,
    refetch: refetchClaim,
  } = trpc.claims.getClaim.useQuery({
    claimId,
    organisationId,
  });

  const { isLoading: loadingClaimReport, data: claimReport } = useQuery(
    `claim/${claimId}/${organisationId}/report`,
    async (): Promise<Report> =>
      (await axiosClient.get(`/claims/${claimId}/${organisationId}/report`))
        .data
  );
  const { isLoading: loadingOrg, data: org } = useQuery(
    `org`,
    async () => (await axiosClient.get(`/organisations/current`)).data
  );
  // const queryClient = useQueryClient();
  // const { mutate: saveComment } = useMutation(
  //   async (comment: Comment) => {
  //     await axiosClient.post(
  //       `/claims/${claimId}/report/${claimReport?.reportId}/comments`,
  //       {
  //         message: comment.text,
  //         commentId: comment.uuid,
  //         reportBlock: comment.reportBlock,
  //       }
  //     );
  //   },
  //   {
  //     onError: () => {
  //       enqueueErrorSnackbar("Error saving comments");
  //     },
  //     onSettled: () => {
  //       queryClient.invalidateQueries({
  //         queryKey: [`claim/${claimId}/report/comments`],
  //       });
  //     },
  //   }
  // );

  // const { isLoading: loadingTemplate, data: reportTemplate } = useQuery(
  //   `reportTemplates/${claimReport?.templateId}`,
  //   async () =>
  //     (await axiosClient.get(`/reportTemplates/${claimReport?.templateId}`))
  //       .data
  // );

  // useInterval(
  //   () => {
  //     if (isDirty) {
  //       axiosClient
  //         .put(
  //           `/claims/${claimId}/report/${claimReport?.reportId}/editing-event`
  //         )
  //         .then((res: any) => {
  //           setOtherUsersEditing(
  //             res.data
  //               .filter((event: any) => event.userId !== user.userId)
  //               .map((event: any) => event.username)
  //           );
  //         });
  //     } else {
  //       axiosClient
  //         .get(
  //           `/claims/${claimId}/report/${claimReport?.reportId}/editing-event`
  //         )
  //         .then((res: any) => {
  //           setOtherUsersEditing(
  //             res.data
  //               .filter((event: any) => event.userId !== user.userId)
  //               .map((event: any) => event.username)
  //           );
  //         });
  //     }
  //   },
  //   60 * 1000,
  //   { immediate: true }
  // );
  // const { mutate: replyToComment } = useMutation(
  //   async (comment: { commentId: string; message: string }) => {
  //     await axiosClient.post(
  //       `/claims/${claimId}/report/${claimReport?.reportId}/comment/${comment.commentId}/reply`,
  //       {
  //         message: comment.message,
  //       }
  //     );
  //   },
  //   {
  //     onSuccess: () => {
  //       enqueueSuccessSnackbar();
  //     },
  //     onError: () => {
  //       enqueueErrorSnackbar("Error saving comments");
  //     },
  //     onSettled: () => {
  //       queryClient.invalidateQueries({
  //         queryKey: [`claim/${claimId}/report/comments`],
  //       });
  //     },
  //   }
  // );

  const currentVersion =
    selectedVersion ||
    claimReport?.versions?.[claimReport.versions.length - 1]?.publicVersion;

  const { isLoading: loadingReportContent, data: reportContent } = useQuery(
    `/claims/${claimId}/${organisationId}/report/${currentVersion}/content`,
    async () =>
      (
        await axiosClient.get(
          `/claims/${claimId}/${organisationId}/report/${currentVersion}/content`
        )
      ).data,
    { enabled: !!currentVersion, cacheTime: 0 }
  );

  // uuid: string;
  // text: string;
  // userId: string;
  // userName: string;
  // timestamp: number;
  // node: Element;
  // reportBlock?: JSONContent;
  // active: boolean;
  // position: number;;

  // const { isLoading: loadingComments, data: reportComments } = useQuery(
  //   `claim/${claimId}/report/comments`,
  //   async () =>
  //     (
  //       await axiosClient.get(
  //         `/claims/${claimId}/report/${claimReport?.reportId}/comments`
  //       )
  //     ).data,
  //   { enabled: !!claimReport?.reportId }
  // );

  // const updateTrackedChangesAndComments = () => {
  //   if (!reportComments) return;
  //   const elementsWithChangeAttribute = document.querySelectorAll("[change]");

  //   const trackedChanges = Array.from(elementsWithChangeAttribute).map(
  //     (el) => ({
  //       ...JSON.parse(el.getAttribute("change") || ""),
  //       content: el.innerHTML,
  //     })
  //   );
  //   const elementsWithCommentAttribute =
  //     document.querySelectorAll("[data-comment]");
  //   const comments = Array.from(elementsWithCommentAttribute).map((el) => {
  //     // round the position to nearest 50
  //     const pos =
  //       Math.round(
  //         (el?.getBoundingClientRect().top +
  //           window.scrollY -
  //           el?.getBoundingClientRect().height) /
  //           50
  //       ) * 50;
  //     const attributes = JSON.parse(el.getAttribute("data-comment") || "");
  //     const reportComment = reportComments.find(
  //       (rc: any) => rc.reportCommentId === attributes.uuid
  //     );
  //     const active = reportComment && !reportComment.resolved;

  //     return {
  //       ...attributes,
  //       active,
  //       content: el.innerHTML,
  //       position: pos,
  //     };
  //   });
  //   setTrackedChanges(trackedChanges);
  //   // make comments unique by uuid
  //   const uniqueComments = Array.from(new Set(comments.map((a) => a.uuid))).map(
  //     (uuid) => {
  //       return comments.find((a) => a.uuid === uuid);
  //     }
  //   );

  //   setComments(uniqueComments);

  //   for (const comment of uniqueComments) {
  //     if (reportComments.find((c: any) => c.reportCommentId === comment.uuid)) {
  //       continue;
  //     }
  //     let reportBlock = {};
  //     editorRef.current?.editor?.state.doc.descendants((node) => {
  //       if (node.isText) {
  //         return;
  //       }

  //       let hasCommentMark = false;
  //       node.forEach((childNode) => {
  //         childNode.marks.forEach((mark) => {
  //           if (mark.type.name === "comment") {
  //             hasCommentMark = true;
  //           }
  //         });
  //       });

  //       if (hasCommentMark) {
  //         // This node has a child with a comment mark.
  //         // Use toJSON method to convert the node to a ProseMirror JSON representation
  //         reportBlock = node.toJSON();
  //       }
  //     });

  //     saveComment({ ...comment, reportBlock });
  //   }
  // };

  // useEffect(() => {
  //   updateTrackedChangesAndComments();
  // }, [JSON.stringify(report), JSON.stringify(reportComments)]);

  useEffect(() => {
    setComments(claimReport?.comments || []);
  }, [claimReport]);

  if (loadingClaimReport || loadingReportContent || loadingOrg || isLoading)
    return <LoadingSpinner />;

  const visibleComments = comments.filter((comment) => {
    const savedComment = claimReport?.comments?.find(
      (c: any) => c.reportCommentId === comment.reportCommentId
    );
    return showResolvedComments || !savedComment?.resolved;
  });

  if (claim) {
    const attachments = claim.assessmentAttachments?.map((attachment) => {
      return {
        ...attachment,
        imageUrl: `${attachment.imageUrl}/${organisationId}/${claimId}`,
        annotatedImageUrl:
          attachment.annotatedImageUrl &&
          `${attachment.annotatedImageUrl}/${organisationId}/${claimId}`,
      };
    });
    claim.assessmentAttachments = attachments;
  }

  return (
    <>
      <Paper
        elevation={4}
        sx={{
          padding: "1.5rem",
          mb: "1.5rem",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          flexWrap: "wrap",
        }}
      >
        <Typography variant="h2" component="h2">
          View Claim Report
        </Typography>
      </Paper>

      <ReportReviewGrid>
        <div>
          <Paper
            elevation={4}
            sx={{ padding: 2, maxWidth: "calc(100vw - 2.5rem)" }}
          >
            {claimReport && reportContent ? (
              <ReportContainer selectedCommentId={selectedCommentUuid}>
                <TextEditor
                  className="report-editor"
                  styles={claimReport.template?.styles}
                  id={`${selectedVersion}-${trackedChangesEnabled}-report-editor`}
                  currentUser={user}
                  trackedChangesEnabled={trackedChangesEnabled}
                  trackedChangesMarkupEnabled={
                    trackedChangesEnabled && trackedChangesMarkupEnabled
                  }
                  onTrackedChangesMarkupEnabledChange={
                    setTrackedChangesMarkupEnabled
                  }
                  content={report || reportContent}
                  onLoad={() => {
                    setEditorInitialized(true);
                  }}
                  key={`${selectedVersion}-${trackedChangesEnabled}-report-editor`}
                  ref={editorRef as Ref<TextEditorRef>}
                  onCommentsChange={(comments) => setComments(comments)}
                  onSelectedCommentChange={(uuid) => {
                    if (uuid) {
                      setSelectedCommentUuid(uuid);
                    }
                  }}
                  onContentChange={(content) => {
                    if (!isDirty && report && content !== report) {
                      setIsDirty(true);
                    }
                    setReport(content);
                  }}
                  claim={claim as UpdateClaim}
                  onTrackedChangesEnabledChange={(enabled: boolean) => {
                    localStorage.setItem("trackChanges", enabled.toString());
                    setTrackedChangesEnabled(enabled);
                  }}
                  readOnly
                  org={org}
                />
                <Box sx={{ margin: "auto", mt: 2 }}>
                  <Typography variant="h4">
                    Annotated aerial photographs
                  </Typography>
                  <InlineEditor
                    readOnly
                    content={`<span property="${annotatedAerialPhotographs.value}"></span>`}
                    claim={claim as UpdateClaim}
                    editorId="aerials-editor"
                    ref={aerialsRef as Ref<TextEditorRef>}
                    org={org}
                  />
                </Box>
                <Box sx={{ margin: "auto", mt: 2, width: "80%" }}>
                  <Typography variant="h4">Sketches</Typography>
                  <InlineEditor
                    readOnly
                    content={`<span property="${sketches.value}"></span>`}
                    claim={claim as UpdateClaim}
                    editorId="sketches-editor"
                    ref={sketchesRef as Ref<TextEditorRef>}
                    org={org}
                  />
                </Box>
                <Box sx={{ margin: "auto", mt: 2, pb: 2 }}>
                  <Typography
                    variant="h4"
                    sx={{
                      fontWeight: "bold",
                      marginRight: 1,
                      display: "inline-block",
                    }}
                    component="h4"
                  >
                    Attachment summary
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    variant="h4"
                    sx={{
                      fontWeight: "bold",
                      marginRight: 1,
                      display: "inline-block",
                    }}
                    component="h4"
                  >
                    Page Footer
                  </Typography>
                  <InlineEditor
                    readOnly
                    content={claimReport.footer}
                    claim={claim as UpdateClaim}
                    editorId="sketches-editor"
                    ref={footerRef as Ref<TextEditorRef>}
                    org={org}
                  />
                </Box>
              </ReportContainer>
            ) : (
              <Typography variant="body1">
                No report has been created yet
              </Typography>
            )}
          </Paper>
        </div>
        <Stack spacing={3}>
          <Stack sx={{ flex: 1 }}>
            <Box
              sx={{
                display: "block",
                position: "sticky",
                top: "4rem",
              }}
            >
              <Paper
                elevation={4}
                sx={{
                  padding: 2,
                  marginBottom: 2,
                  overflow: "auto",
                  maxHeight: "calc(60vh)",
                }}
              >
                <Stack spacing={0.5}>
                  <Typography
                    variant="subtitle2"
                    sx={{
                      fontWeight: "bold",
                      marginRight: 1,
                      display: "inline-block",
                    }}
                    component="div"
                  >
                    Actions
                  </Typography>
                  <Divider />
                  <Button variant="outlined">Request changes</Button>
                  <Button variant="contained">Accept</Button>
                </Stack>
              </Paper>
              <Paper
                elevation={4}
                sx={{
                  padding: 2,
                  overflow: "auto",
                  maxHeight: "calc(60vh)",
                }}
              >
                <Stack divider={<Divider />}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      variant="subtitle2"
                      sx={{
                        fontWeight: "bold",
                        marginRight: 1,
                        display: "inline-block",
                      }}
                      component="div"
                    >
                      Comments
                    </Typography>
                    <Button
                      size="small"
                      onClick={() => {
                        setShowResolvedComments(!showResolvedComments);
                      }}
                    >
                      {showResolvedComments ? "Show Unresolved" : "Show All"}
                    </Button>
                  </Box>
                  <Stack divider={<Divider />}>
                    {visibleComments.length === 0 && (
                      <Typography variant="body2">None</Typography>
                    )}
                    {visibleComments.map((comment) => {
                      const savedComment = claimReport?.comments?.find(
                        (c: any) =>
                          c.reportCommentId === comment.reportCommentId
                      );
                      return (
                        <ClaimReportComment
                          key={comment.reportCommentId}
                          accepted={savedComment.accepted}
                          rejected={savedComment.rejected}
                          author={comment.user.name}
                          comment={comment.message}
                          replies={[]}
                          selected={
                            comment.reportCommentId === selectedCommentUuid
                          }
                          onReply={(comment) => {}}
                          commentId={comment.reportCommentId}
                          onAccept={() => {
                            if (!editorRef.current?.editor) return;
                            editorRef.current.editor
                              .chain()
                              .resolveComment(comment.reportCommentId)
                              .run();
                          }}
                          onReject={() => {
                            if (!editorRef.current?.editor) return;
                            editorRef.current.editor
                              .chain()
                              .resolveComment(comment.reportCommentId)
                              .run();
                          }}
                          onClick={() => {
                            setSelectedCommentUuid(comment.reportCommentId);
                            // All elements that have a data-comment, parse the data-comment json and if the uuid matches comment.uuid then set css background color
                            const elementsWithCommentAttribute =
                              document.querySelectorAll("[data-comment]");
                            elementsWithCommentAttribute.forEach(
                              async (element) => {
                                const currentComment =
                                  element.getAttribute("data-comment");

                                if (
                                  currentComment &&
                                  JSON.parse(currentComment).uuid ===
                                    comment.uuid
                                ) {
                                  // scroll to the element
                                  element.scrollIntoView({
                                    behavior: "smooth",
                                    block: "center",
                                    inline: "center",
                                  });
                                }
                              }
                            );
                          }}
                          onDeselected={() => {}}
                        />
                      );
                    })}
                  </Stack>
                </Stack>
              </Paper>
            </Box>
          </Stack>
        </Stack>
      </ReportReviewGrid>
    </>
  );
}

const ReportReviewGrid = styled.div`
  display: grid;
  grid-template-columns: 3fr 1fr;
  gap: 1.5rem;
  margin: auto;

  @media (max-width: 1200px) {
    grid-template-columns: 1fr;
  }
`;

const ReportContainer = styled.div<{ selectedCommentId?: string }>`
  span[comment-id="${(props) => props.selectedCommentId}"] {
    background-color: rgb(208, 216, 253);
  }
`;

const mergeTrackedChanges = (
  trackedChanges: TrackedChange[]
): TrackedChange[] => {
  const merged: { [key: string]: TrackedChange } = {};

  trackedChanges.forEach((trackedChange) => {
    if (!merged[trackedChange.id]) {
      merged[trackedChange.id] = { ...trackedChange };
    } else {
      merged[trackedChange.id].content = `${merged[trackedChange.id].content}${
        trackedChange.content
      }`;
    }
  });
  return Object.values(merged);
};
