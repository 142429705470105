import { Event, EventSchema } from "@athena/server/src/api/types/event";
import { zodResolver } from "@hookform/resolvers/zod";
import { Paper, Typography, Divider, Stack, Button } from "@mui/material";
import { Box } from "@mui/system";
import { FormProvider, useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { axiosClient } from "src/lib/axiosClient";
import { FormDatePicker } from "src/shared/form/FormDatePicker";
import { FormTextField } from "src/shared/form/FormTextField";
import {
  enqueueSavingSnackbar,
  enqueueSuccessSnackbar,
} from "src/shared/snackbar/SnackbarHelper";

type NewEventProps = {};

export const NewEvent = () => {
  const { control, handleSubmit, reset, ...rest } = useForm<Event>({
    resolver: zodResolver(EventSchema),
  });
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const addEvent = async (name: string, dateOfEvent?: string) => {
    const finishedSaving = enqueueSavingSnackbar();
    const res = (
      await axiosClient.post(`/events/request`, { name, dateOfEvent })
    ).data;
    finishedSaving();
    enqueueSuccessSnackbar("Requested");
    queryClient.invalidateQueries({
      queryKey: [`events`],
    });
    return res;
  };

  const onSubmit = (data: Event) => {
    if (Object.keys(rest.formState.errors).length) return;
    addEvent(data.name, data.dateOfEvent).then((event) => {
      navigate(`/event/${event.eventId}`);
    });
  };
  return (
    <Paper
      elevation={4}
      sx={{
        padding: "1.5rem",
        mb: "1.5rem",
        display: "flex",
        flexWrap: "wrap",
        flexDirection: "column",
        mt: 4,
      }}
    >
      <FormProvider
        {...rest}
        reset={reset}
        control={control}
        handleSubmit={handleSubmit}
      >
        <form
          key={"event"}
          onSubmit={(e) => {
            e.stopPropagation();
            handleSubmit(onSubmit)(e);
          }}
        >
          <Typography variant="h2" component="h2">
            Request Event
          </Typography>
          <Divider sx={{ mb: 2, mt: 2 }} />
          <Stack
            direction={"row"}
            sx={{
              maxWidth: "1000px",
              mb: 2,
            }}
          >
            <FormTextField
              control={control}
              label="Event Name"
              name="name"
              sx={{ minWidth: 400 }}
              error={!!rest.formState.errors.name}
              helperText={rest.formState.errors.name?.message}
            />
            <FormDatePicker
              control={control}
              label="Event Date"
              name="dateOfEvent"
              sx={{ minWidth: 400 }}
            />
          </Stack>
          <Button
            variant="contained"
            sx={{ width: 250 }}
            onClick={() => {
              handleSubmit(
                (data) => onSubmit(data),
                (e) => {
                  console.log(e);
                }
              )();
            }}
          >
            Request
          </Button>
        </form>
      </FormProvider>
    </Paper>
  );
};
