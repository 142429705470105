import { Event } from "@athena/server/src/api/types/event";
import {
  FormControl,
  InputLabel,
  TextField,
  Button,
  Typography,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { useState } from "react";

type RequestedEventProps = {
  addEvent: (eventId: string, name: string, date?: string) => void;
  event: Event;
  requestedByOrgName: string;
};

export const RequestedEvent = (props: RequestedEventProps) => {
  const { addEvent, event, requestedByOrgName } = props;
  const [eventName, setEventName] = useState(event.name);
  const [eventDate, setEventDate] = useState<string | undefined>(
    event.dateOfEvent
  );
  return (
    <>
      <FormControl sx={{ m: 1, minWidth: 220, ml: 2 }} size="small">
        <InputLabel shrink>Name</InputLabel>
        <TextField
          value={eventName}
          onChange={(e) => {
            setEventName(e.target.value);
          }}
        />
      </FormControl>
      <FormControl sx={{ m: 1, minWidth: 220 }} size="small">
        <InputLabel shrink>Date of Event</InputLabel>
        <DatePicker
          onChange={(event) => {
            setEventDate(event ? event.toISOString() : undefined);
          }}
          value={eventDate ? new Date(eventDate) : null}
          sx={{ width: 219 }}
        />
      </FormControl>
      <Typography variant="subtitle1">
        Requested by: {requestedByOrgName}
      </Typography>
      <Button
        variant={"contained"}
        disabled={!eventName}
        onClick={() => {
          if (!eventName || !event.eventId) return;
          addEvent(event.eventId, eventName, eventDate);
        }}
        sx={{ minWidth: 100, ml: 2 }}
      >
        Add
      </Button>
    </>
  );
};
