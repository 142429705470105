import { Layer, PickingInfo } from "@deck.gl/core/typed";
import { ReactNode } from "react";

export interface PickingInfoWithPopups extends PickingInfo {
  picked: true,
  popups?: Array<{
    component: ReactNode;
    title: string;
    key: string;
  }>;
}

export interface PickingInfoWithTooltips extends PickingInfo {
  picked: true,
  tooltips?: Array<{
    component: ReactNode;
  }>;
}

export const isPickingInfoWithPopup = (
  pickingInfo: PickingInfo
): pickingInfo is Required<PickingInfoWithPopups> => {
  return (
    pickingInfo.picked &&
    "popups" in pickingInfo &&
    typeof pickingInfo.popups === "object" &&
    pickingInfo.popups !== null
  );
};

export const isPickingInfoWithTooltip = (
  pickingInfo: PickingInfo
): pickingInfo is Required<PickingInfoWithTooltips> => {
  return (
    pickingInfo.picked &&
    "tooltips" in pickingInfo &&
    typeof pickingInfo.tooltips === "object" &&
    pickingInfo.tooltips !== null
  );
};

interface MenuOptions {
  displayName: string;
  icon?: () => ReactNode;
  checkBoxIcon?: () => ReactNode;
  defaultVisibility?: boolean;
}
export interface AthenaLayerProps<TPickingInfo extends PickingInfo = PickingInfo> {
  id: string;
  menu: MenuOptions,
  zIndex?: number,
  visibility: Record<string, boolean>;
  popup?: (
      pickingInfo: Exclude<TPickingInfo, { picked: false }> & { picked: true } & Required<Pick<TPickingInfo, "object">>
    ) => {
    key: string,
    component: ReactNode;
    title: string;
  };
  tooltip?: (
      pickingInfo: Exclude<TPickingInfo, { picked: false }> & { picked: true } & Required<Pick<TPickingInfo, "object">>
    ) => {
    component: ReactNode;
  };
}

export type AthenaLayerCollection<TPickingInfo extends PickingInfo = PickingInfo> = Array<Layer<AthenaLayerProps<TPickingInfo>>>;