import { Select, TextField, MenuItem as _MenuItem } from "@mui/material";
import { Fragment } from "react";

import { Divider } from "@mui/material";
import { Stack } from "@mui/system";
import { Editor } from "@tiptap/react";
import { enqueueWarningSnackbar } from "../snackbar/SnackbarHelper";
import { MenuItem } from "./MenuItem";

const fontSizes = [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 16, 18, 20, 24, 30, 36, 48, 60,
  72, 90,
];

const highlightColours = [
  "#FFFF00",
  "#00FF00",
  "#0000FF",
  "#FF0000",
  "#800080",
  "#FFA500",
  "#FFC0CB",
];

type MenuBarProps = {
  editor: Editor;
  onImageAdd: VoidFunction;
  trackedChangesEnabled: boolean;
  trackedChangesMarkupEnabledChange?: boolean;
  onTrackedChangesEnabledChange?: (enabled: boolean) => void;
  onTrackedChangesMarkupEnabledChange?: (enabled: boolean) => void;
  sticky?: boolean;
};

export const MenuBar = ({
  editor,
  onImageAdd,
  trackedChangesEnabled,
  trackedChangesMarkupEnabledChange,
  onTrackedChangesEnabledChange,
  onTrackedChangesMarkupEnabledChange,
  sticky = false,
}: MenuBarProps) => {
  const items = [
    {
      icon: "bold",
      title: "Bold",
      action: () => editor.chain().focus().toggleBold().run(),
      isActive: () => editor.isActive("bold"),
    },
    {
      icon: "italic",
      title: "Italic",
      action: () => editor.chain().focus().toggleItalic().run(),
      isActive: () => editor.isActive("italic"),
    },
    {
      icon: "strikethrough",
      title: "Strike",
      action: () => editor.chain().focus().toggleStrike().run(),
      isActive: () => editor.isActive("strike"),
    },
    {
      icon: "underline",
      title: "Underline",
      action: () => editor.chain().focus().toggleUnderline().run(),
      isActive: () => editor.isActive("underline"),
    },
    {
      icon: "superscript",
      title: "Superscript",
      action: () => {
        if (editor.isActive("superscript")) {
          editor.chain().focus().toggleSuperscript().unsetFontSize().run();
        } else {
          editor.chain().focus().toggleSuperscript().run();
        }
      },
      isActive: () => editor.isActive("superscript"),
    },
    {
      type: "divider",
    },
    {
      icon: "align-left",
      title: "Align Left",
      action: () => editor.chain().focus().setTextAlign("left").run(),
      isActive: () => editor.isActive({ textAlign: "left" }),
    },
    {
      icon: "align-center",
      title: "Align Center",
      action: () => editor.chain().focus().setTextAlign("center").run(),
      isActive: () => editor.isActive({ textAlign: "center" }),
    },
    {
      icon: "align-right",
      title: "Align Right",
      action: () => editor.chain().focus().setTextAlign("right").run(),
      isActive: () => editor.isActive({ textAlign: "right" }),
    },
    {
      icon: "align-justify",
      title: "Align Justify",
      action: () => editor.chain().focus().setTextAlign("justify").run(),
      isActive: () => editor.isActive({ textAlign: "justify" }),
    },
    {
      icon: "font-size",
      title: "Font Size",
      action: () => editor.chain().focus().setTextAlign("justify").run(),
      isActive: () => editor.isActive({ textAlign: "justify" }),
      menuChildren: [
        <_MenuItem
          key={0}
          onClick={() => {
            editor.chain().focus().unsetFontSize().run();
          }}
        >
          Auto
        </_MenuItem>,
      ].concat(
        fontSizes.map((fontSize) => (
          <_MenuItem
            key={fontSize}
            onClick={() => {
              editor.chain().focus().setFontSize(fontSize.toString()).run();
            }}
          >
            {fontSize}
          </_MenuItem>
        ))
      ),
    },
    {
      icon: "paint-fill",
      title: "Text Color",
      action: () => editor.chain().focus().toggleHeaderCell().run(),
      hideForTrackedChanges: true,
      menuChildren: (
        <_MenuItem>
          <TextField
            label="Text Color"
            onChange={(e) => {
              editor.commands.setColor(e.target.value);
            }}
            type="color"
            sx={{ width: 200 }}
          />
        </_MenuItem>
      ),
    },
    {
      icon: "mark-pen-fill",
      title: "Highlight",
      hideForTrackedChanges: false,
      action: () => editor.chain().focus().toggleHighlight().run(),
      menuChildren: highlightColours.map((color) => (
        <_MenuItem
          key={color}
          onClick={() => {
            editor.chain().focus().toggleHighlight({ color }).run();
          }}
          sx={{
            backgroundColor: color,
            width: 20,
            height: 20,
            borderRadius: "0.5rem",
          }}
        ></_MenuItem>
      )),
    },
    {
      type: "divider",
    },
    {
      icon: "h-1",
      title: "Heading 1",
      action: () => editor.chain().focus().toggleHeading({ level: 1 }).run(),
      isActive: () => editor.isActive("heading", { level: 1 }),
    },
    {
      icon: "h-2",
      title: "Heading 2",
      action: () => editor.chain().focus().toggleHeading({ level: 2 }).run(),
      isActive: () => editor.isActive("heading", { level: 2 }),
    },
    {
      icon: "h-3",
      title: "Heading 3",
      action: () => editor.chain().focus().toggleHeading({ level: 3 }).run(),
      isActive: () => editor.isActive("heading", { level: 3 }),
    },
    {
      icon: "paragraph",
      title: "Paragraph",
      action: () => editor.chain().focus().setParagraph().run(),
      isActive: () => editor.isActive("paragraph"),
    },
    {
      icon: "list-unordered",
      title: "Bullet List",
      action: () => editor.chain().focus().toggleBulletList().run(),
      isActive: () => editor.isActive("bulletList"),
      hideForTrackedChanges: false,
    },
    {
      icon: "list-ordered",
      title: "Ordered List",
      action: () => editor.chain().focus().toggleOrderedList().run(),
      isActive: () => editor.isActive("orderedList"),
      hideForTrackedChanges: false,
    },
    {
      icon: "image-line",
      title: "Image",
      action: () => {
        onImageAdd();
      },
      isActive: () => editor.isActive("image"),
      hideForTrackedChanges: true,
    },
    {
      type: "divider",
    },
    {
      icon: "split-cells-vertical",
      title: "Page Break",
      action: () => {
        editor.chain().focus().setPageBreak().run();
      },
    },
    {
      icon: "separator",
      title: "Horizontal Rule",
      action: () => editor.chain().focus().setHorizontalRule().run(),
    },
    {
      type: "divider",
    },
    {
      icon: "arrow-go-back-line",
      title: "Undo",
      action: () => editor.chain().focus().undo().run(),
    },
    {
      icon: "arrow-go-forward-line",
      title: "Redo",
      action: () => editor.chain().focus().redo().run(),
    },
    {
      type: "divider",
      hideForTrackedChanges: true,
    },
    {
      icon: "table-line",
      title: "Add Table",
      action: () =>
        editor
          .chain()
          .focus()
          .insertTable({ rows: 3, cols: 3, withHeaderRow: true })
          .run(),
      hideForTrackedChanges: true,
    },
    {
      icon: "insert-column-left",
      title: "Add Column Before",
      action: () => editor.chain().focus().addColumnBefore().run(),
      hideForTrackedChanges: true,
    },
    {
      icon: "insert-column-right",
      title: "Add Column After",
      action: () => editor.chain().focus().addColumnAfter().run(),
      hideForTrackedChanges: true,
    },
    {
      icon: "delete-column",
      title: "Delete Column",
      action: () => {
        const { state } = editor.view;
        const { $from, $to } = state.selection;

        // Check if the currently selected node contains a "lockedValue" node type
        let containsLockedValue = false;
        state.doc.nodesBetween($from.pos, $to.pos, (node) => {
          if (node.type.name === "lockedValue") {
            containsLockedValue = true;
            return false; // Stop further traversal
          }
        });

        if (containsLockedValue) {
          enqueueWarningSnackbar(
            "Unable to delete a column that contains a locked value."
          );
          return;
        }

        editor.chain().focus().deleteColumn().run();
      },
      hideForTrackedChanges: true,
    },
    {
      icon: "insert-row-top",
      title: "Add Row Before",
      action: () => editor.chain().focus().addRowBefore().run(),
      hideForTrackedChanges: true,
    },
    {
      icon: "insert-row-bottom",
      title: "Add Row After",
      action: () => editor.chain().focus().addRowAfter().run(),
      hideForTrackedChanges: true,
    },
    {
      icon: "delete-row",
      title: "Delete Row",
      action: () => {
        const { state, dispatch } = editor.view;
        const { $from, $to } = state.selection;

        // Check if the currently selected node contains a "lockedValue" node type
        let containsLockedValue = false;
        state.doc.nodesBetween($from.pos, $to.pos, (node) => {
          if (node.type.name === "lockedValue") {
            containsLockedValue = true;
            return false; // Stop further traversal
          }
        });

        if (containsLockedValue) {
          enqueueWarningSnackbar(
            "Unable to delete a row that contains a locked value."
          );
          return;
        }

        editor.chain().focus().deleteRow().run();
        dispatch(editor.view.state.tr);
      },
      hideForTrackedChanges: true,
    },
    {
      icon: "merge-cells-horizontal",
      title: "Merge Cells",
      action: () => editor.chain().focus().mergeCells().run(),
      hideForTrackedChanges: true,
    },
    {
      icon: "split-cells-horizontal",
      title: "Split Cells",
      action: () => editor.chain().focus().splitCell().run(),
      hideForTrackedChanges: true,
    },
    {
      icon: "layout-row-fill",
      title: "Toggle Header Row",
      action: () => editor.chain().focus().toggleHeaderCell().run(),
      hideForTrackedChanges: true,
    },
    {
      icon: "layout-column-fill",
      title: "Toggle Header Cell",
      action: () => editor.chain().focus().toggleHeaderCell().run(),
      hideForTrackedChanges: true,
    },
    {
      icon: "paint-fill",
      title: "Cell Color",
      action: () => editor.chain().focus().toggleHeaderCell().run(),
      hideForTrackedChanges: true,
      menuChildren: (
        <_MenuItem>
          <TextField
            label="Cell Color"
            onChange={(e) => {
              editor
                .chain()
                .focus()
                .setCellAttribute("backgroundColor", e.target.value)
                .run();
            }}
            type="color"
            sx={{ width: 200 }}
          />
        </_MenuItem>
      ),
    },
    {
      icon: "paint-fill",
      title: "Cell Border Color",
      action: () => editor.chain().focus().toggleHeaderCell().run(),
      hideForTrackedChanges: true,
      menuChildren: (
        <_MenuItem>
          <TextField
            label="Cell Border Color"
            onChange={(e) => {
              editor
                .chain()
                .focus()
                .setCellAttribute("borderColor", e.target.value)
                .run();
            }}
            type="color"
            sx={{ width: 200 }}
          />
        </_MenuItem>
      ),
    },
    {
      icon: "delete-bin-line",
      title: "Delete Table",
      action: () => {
        const { state, dispatch } = editor.view;
        const { $from, $to } = state.selection;

        // Check if the currently selected node contains a "lockedValue" node type
        let containsLockedValue = false;
        state.doc.nodesBetween($from.pos, $to.pos, (node) => {
          if (node.type.name === "lockedValue") {
            containsLockedValue = true;
            return false; // Stop further traversal
          }
        });

        if (containsLockedValue) {
          enqueueWarningSnackbar(
            "Unable to delete a table that contains a locked value."
          );
          return;
        }

        editor.chain().focus().deleteTable().run();
        dispatch(editor.view.state.tr);
      },
      hideForTrackedChanges: true,
    },
  ];

  return (
    <Stack
      direction="row"
      spacing={0}
      useFlexGap
      sx={{
        rowGap: 0,
        columnGap: 0.5,
        mb: 2,
        borderBottom: "1px solid lightgray",
        padding: "1rem",
        position: sticky ? "sticky" : "initial",
        top: "4rem",
        backgroundColor: "white",
        zIndex: "2",
        borderRadius: "10px 10px 0 0",
      }}
    >
      {items
        .filter((item) => {
          if (!trackedChangesEnabled) return true;
          return !item.hideForTrackedChanges;
        })
        .map((item, index) => (
          <Fragment key={index}>
            {item.type === "divider" ? (
              <Divider orientation="vertical" flexItem />
            ) : (
              <MenuItem {...(item as any)} />
            )}
          </Fragment>
        ))}
      {onTrackedChangesEnabledChange && (
        <>
          <Select
            value={trackedChangesEnabled ? "suggesting" : "editing"}
            onChange={(e) => {
              onTrackedChangesEnabledChange(e.target.value === "suggesting");
            }}
            id={"region"}
            variant="outlined"
            size="small"
            displayEmpty
          >
            <_MenuItem value={"editing"}>Tracked Changes Off</_MenuItem>
            <_MenuItem value={"suggesting"}>Tracked Changes On</_MenuItem>
          </Select>
          {trackedChangesEnabled && (
            <Select
              value={
                trackedChangesMarkupEnabledChange ? "all-markup" : "no-markup"
              }
              onChange={(e) => {
                onTrackedChangesMarkupEnabledChange?.(
                  e.target.value === "all-markup"
                );
              }}
              id={"region"}
              variant="outlined"
              size="small"
              displayEmpty
            >
              <_MenuItem value={"all-markup"}>All Markup</_MenuItem>
              <_MenuItem value={"no-markup"}>No Markup</_MenuItem>
            </Select>
          )}
        </>
      )}
    </Stack>
  );
};
