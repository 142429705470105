import styled from "@emotion/styled";
import EditIcon from "@mui/icons-material/Edit";
import UndoIcon from "@mui/icons-material/Undo";
import RedoIcon from "@mui/icons-material/Redo";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CancelIcon from "@mui/icons-material/Cancel";
import DeleteIcon from "@mui/icons-material/Delete";
import React, {
  FC,
  Fragment,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from "react";
import { ButtonBase, css } from "@mui/material";
import GestureIcon from "@mui/icons-material/Gesture";

export enum DrawingType {
  Polygon = "polygon",
  Line = "line",
  Point = "point",
}

export enum DrawMode {
  SelectPoints = "select-points",
  Freehand = "freehand",
}

export enum DrawToolbarButton {
  Freehand = "freehand",
  Pencil = "pencil",
  Undo = "undo",
  Redo = "redo",
  Confirm = "confirm",
  Remove = "remove",
  Clear = "clear",
}

const iconMap: Record<DrawToolbarButton, ReactNode> = {
  [DrawToolbarButton.Freehand]: <GestureIcon />,
  [DrawToolbarButton.Pencil]: <EditIcon />,
  [DrawToolbarButton.Undo]: <UndoIcon />,
  [DrawToolbarButton.Redo]: <RedoIcon />,
  [DrawToolbarButton.Confirm]: <CheckCircleOutlineIcon />,
  [DrawToolbarButton.Remove]: <CancelIcon />,
  [DrawToolbarButton.Clear]: <DeleteIcon />,
};

type ButtonConfig = Partial<
  Record<
    DrawToolbarButton,
    { isDisabled?: boolean; isSelected?: boolean } | boolean
  >
>;

interface DrawToolbarProps {
  buttons: ButtonConfig;
  onClick: (button: DrawToolbarButton) => void;
}

export const DrawToolbar: FC<DrawToolbarProps> = ({ buttons, onClick }) => {
  const buttonKeys = Object.keys(buttons) as Array<DrawToolbarButton>;
  return (
    <Container>
      <Bar>
        {buttonKeys.map((button, index) => {
          const buttonConfig = buttons[button];
          if (!buttonConfig) {
            return undefined;
          }

          const isSelected =
            typeof buttonConfig === "object" && buttonConfig.isSelected;
          const isDisabled =
            typeof buttonConfig === "object" && buttonConfig.isDisabled;

          const onClickHandler = () => onClick(button);
          const icon = iconMap[button];
          return (
            <Fragment key={button}>
              <Button
                selected={isSelected}
                disabled={isDisabled}
                onClick={onClickHandler}
              >
                {icon}
              </Button>
              {index < buttonKeys.length - 1 && <Separator />}
            </Fragment>
          );
        })}
      </Bar>
    </Container>
  );
};

const Container = styled.div`
  position: absolute;
  bottom: 1.5rem;
  z-index: 1;

  width: 100%;
`;

const Bar = styled.div`
  display: flex;
  gap: 1rem;

  justify-content: center;
  align-items: center;

  height: 3rem;
  margin: auto;
  width: fit-content;

  background: #fff;
  border-radius: 4px;

  padding-left: 1rem;
  padding-right: 1rem;

  svg {
    fill: #0067df;
  }
`;

const Separator = styled.div`
  width: 2px;
  height: 1.5rem;
  background: #d3d3d3;
  border-radius: 123px;
`;

const Button = styled(ButtonBase)<{ selected?: boolean }>`
  padding: 0.25rem 0.5rem;
  border-radius: 4px;
  :disabled svg {
    fill: lightgray;
  }

  ${(p) =>
    p.selected &&
    css`
      background-color: #0067df;
      svg {
        fill: #fff;
      }
    `};
`;
