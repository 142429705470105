import { Status } from "@athena/server/src/api/types/claimStatuses";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import {
  Bar,
  BarChart,
  Cell,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { ChartLoader } from "./ChartLoader";

interface ClaimData {
  status?: string;
  overdueIn4Plus?: number;
  overdueIn2To3?: number;
  overdueIn1To2?: number;
  overdue?: number;
  exempt?: number;
}

interface Props {
  data: ClaimData[];
  isLoading?: boolean;
}

export const ClaimStatusChart = ({ data, isLoading }: Props) => {
  const navigate = useNavigate();

  const navigateToFilteredClaims = useCallback(
    (x: ClaimData) => {
      navigate(`/engineering-claims?statuses=${x.status}`);
    },
    [navigate]
  );

  const sortedData = data.sort((a, b) => {
    const aIndex = Object.values(Status).indexOf(a.status as Status);
    const bIndex = Object.values(Status).indexOf(b.status as Status);
    return aIndex - bIndex;
  });

  return (
    <div style={{ width: "100%", marginTop: "1.5rem", position: "relative" }}>
      {isLoading && <ChartLoader />}
      <ResponsiveContainer width="100%" height={300}>
        <BarChart data={sortedData}>
          <XAxis dataKey="status" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar
            dataKey="exempt"
            name="Time Exempt"
            stackId="a"
            fill={"#42d4f4"}
            onClick={navigateToFilteredClaims}
          >
            {data.map((_: any, index: number) => (
              <Cell cursor="pointer" key={`cell-${index}`} />
            ))}
          </Bar>
          <Bar
            dataKey="overdueIn4Plus"
            name="4+ days till overdue"
            stackId="a"
            fill={"#0067DF"}
            onClick={navigateToFilteredClaims}
          >
            {data.map((_: any, index: number) => (
              <Cell cursor="pointer" key={`cell-${index}`} />
            ))}
          </Bar>
          <Bar
            dataKey="overdueIn2To3"
            name="2-3 days till overdue"
            stackId="a"
            fill={"#5E99DE"}
            onClick={navigateToFilteredClaims}
          >
            {data.map((_: any, index: number) => (
              <Cell cursor="pointer" key={`cell-${index}`} />
            ))}
          </Bar>
          <Bar
            dataKey="overdueIn1To2"
            name="1-2 days till overdue"
            stackId="a"
            fill={"#A6CCF8"}
            onClick={navigateToFilteredClaims}
          >
            {data.map((_: any, index: number) => (
              <Cell cursor="pointer" key={`cell-${index}`} />
            ))}
          </Bar>
          <Bar
            dataKey="overdue"
            name="Overdue"
            stackId="a"
            fill={"#F67E4B"}
            onClick={navigateToFilteredClaims}
          >
            {data.map((_: any, index: number) => (
              <Cell cursor="pointer" key={`cell-${index}`} />
            ))}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};
