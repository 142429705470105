import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import { JSONContent } from "@tiptap/core";
import { useState } from "react";
import { InlineEditor } from "../InlineEditor";
import { TemplateWithVariables } from "./TemplateWithVariables";

type ImminentRiskDetailsProps = {
  showModal: boolean;
  onClose: VoidFunction;
  setDirty: (state: boolean) => void;
  onSave: (context: Context) => void;
  context: Context;
};

type Context = {
  hasImminentRisk: JSONContent;
  noImminentRisk: JSONContent;
  noDwellingDamage: JSONContent;
  hasImminentRiskOfEvacuatedInsuredLandLandslip: JSONContent;
  hasImminentRiskOfNewInundationInsuredLandLandslip: JSONContent;
  hasImminentRiskOfReInundationInsuredLandLandslip: JSONContent;
  hasDwellingDamageImminentRiskLandslip: JSONContent;
  hasAppurtenantStructureDamageImminentRiskLandslip: JSONContent;
  hasRetainingWallDamageImminentRiskLandslip: JSONContent;
  hasServicesDamageImminentRiskLandslip: JSONContent;
  hasBridgeOrCulvertDamageImminentRiskLandslip: JSONContent;
  hasDwellingDamageImminentRiskStorm: JSONContent;
  hasAppurtenantStructureDamageImminentRiskStorm: JSONContent;
  hasRetainingWallDamageImminentRiskStorm: JSONContent;
  hasServicesDamageImminentRiskStorm: JSONContent;
  hasBridgeOrCulvertDamageImminentRiskStorm: JSONContent;
  hasLandslipImminentRisk: JSONContent;
  hasStormFloodImminentRisk: JSONContent;
};

export const ImminentRiskDetails = ({
  showModal,
  onClose,
  setDirty,
  onSave,
  context,
}: ImminentRiskDetailsProps) => {
  const initialState = {
    hasImminentRisk: context?.hasImminentRisk || "Placeholder",
    noImminentRisk: context?.noImminentRisk || "Placeholder",
    noDwellingDamage: context?.noDwellingDamage || "Placeholder",
    hasImminentRiskOfEvacuatedInsuredLandLandslip:
      context?.hasImminentRiskOfEvacuatedInsuredLandLandslip || "Placeholder",
    hasImminentRiskOfNewInundationInsuredLandLandslip:
      context?.hasImminentRiskOfNewInundationInsuredLandLandslip ||
      "Placeholder",
    hasImminentRiskOfReInundationInsuredLandLandslip:
      context?.hasImminentRiskOfReInundationInsuredLandLandslip ||
      "Placeholder",
    hasDwellingDamageImminentRiskLandslip:
      context?.hasDwellingDamageImminentRiskLandslip || "Placeholder",
    hasAppurtenantStructureDamageImminentRiskLandslip:
      context?.hasAppurtenantStructureDamageImminentRiskLandslip ||
      "Placeholder",
    hasRetainingWallDamageImminentRiskLandslip:
      context?.hasRetainingWallDamageImminentRiskLandslip || "Placeholder",
    hasServicesDamageImminentRiskLandslip:
      context?.hasServicesDamageImminentRiskLandslip || "Placeholder",
    hasBridgeOrCulvertDamageImminentRiskLandslip:
      context?.hasBridgeOrCulvertDamageImminentRiskLandslip || "Placeholder",
    hasDwellingDamageImminentRiskStorm:
      context?.hasDwellingDamageImminentRiskStorm || "Placeholder",
    hasAppurtenantStructureDamageImminentRiskStorm:
      context?.hasAppurtenantStructureDamageImminentRiskStorm || "Placeholder",
    hasRetainingWallDamageImminentRiskStorm:
      context?.hasRetainingWallDamageImminentRiskStorm || "Placeholder",
    hasServicesDamageImminentRiskStorm:
      context?.hasServicesDamageImminentRiskStorm || "Placeholder",
    hasBridgeOrCulvertDamageImminentRiskStorm:
      context?.hasBridgeOrCulvertDamageImminentRiskStorm || "Placeholder",
    hasLandslipImminentRisk: context?.hasLandslipImminentRisk || "Placeholder",
    hasStormFloodImminentRisk:
      context?.hasStormFloodImminentRisk || "Placeholder",
  };

  const [imminentRisk, setImminentRisk] = useState(initialState);

  const handleChange = (change: Partial<typeof imminentRisk>) => {
    setImminentRisk({ ...imminentRisk, ...change });
    setDirty(true);
  };

  return (
    <>
      {showModal && (
        <Dialog
          open={showModal}
          onClose={onClose}
          maxWidth="xl"
          sx={{ " .MuiPaper-root": { flexWrap: "nowrap" } }}
        >
          <DialogTitle>Imminent risk paragraph</DialogTitle>
          <DialogContent sx={{ minWidth: 500 }}>
            <Stack>
              <Typography variant="h4">Has Imminent Risk</Typography>
              <InlineEditor
                content={imminentRisk.hasImminentRisk}
                onContentChange={(content) =>
                  handleChange({ hasImminentRisk: content })
                }
              />

              <Typography variant="h4">No Imminent Risk</Typography>
              <InlineEditor
                content={imminentRisk.noImminentRisk}
                onContentChange={(content) =>
                  handleChange({ noImminentRisk: content })
                }
              />

              <Typography variant="h4">
                No Dwelling Damage or Imminent Risk
              </Typography>
              <InlineEditor
                content={imminentRisk.noDwellingDamage}
                onContentChange={(content) =>
                  handleChange({
                    noDwellingDamage: content,
                  })
                }
              />
              <Typography variant="h3">Landslips</Typography>
              <TemplateWithVariables
                title="Landslip"
                content={imminentRisk.hasLandslipImminentRisk}
                onContentChange={(val) =>
                  handleChange({
                    hasLandslipImminentRisk: val,
                  })
                }
              />
              <Typography variant="h4">
                Has Imminent Risk Of Evacuated Insured Land
              </Typography>
              <InlineEditor
                content={
                  imminentRisk.hasImminentRiskOfEvacuatedInsuredLandLandslip
                }
                onContentChange={(content) =>
                  handleChange({
                    hasImminentRiskOfEvacuatedInsuredLandLandslip: content,
                  })
                }
              />

              <Typography variant="h4">
                Has Imminent Risk Of New Inundation Insured Land
              </Typography>
              <InlineEditor
                content={
                  imminentRisk.hasImminentRiskOfNewInundationInsuredLandLandslip
                }
                onContentChange={(content) =>
                  handleChange({
                    hasImminentRiskOfNewInundationInsuredLandLandslip: content,
                  })
                }
              />

              <Typography variant="h4">
                Has Imminent Risk Of Re-Inundation Insured Land
              </Typography>
              <InlineEditor
                content={
                  imminentRisk.hasImminentRiskOfReInundationInsuredLandLandslip
                }
                onContentChange={(content) =>
                  handleChange({
                    hasImminentRiskOfReInundationInsuredLandLandslip: content,
                  })
                }
              />
              <TemplateWithVariables
                title="Imminent Risk of Dwelling Damage"
                content={imminentRisk.hasDwellingDamageImminentRiskLandslip}
                onContentChange={(content) =>
                  handleChange({
                    hasDwellingDamageImminentRiskLandslip: content,
                  })
                }
              />
              <TemplateWithVariables
                title="Imminent Risk of Appurtenant Structure Damage"
                content={
                  imminentRisk.hasAppurtenantStructureDamageImminentRiskLandslip
                }
                onContentChange={(content) =>
                  handleChange({
                    hasAppurtenantStructureDamageImminentRiskLandslip: content,
                  })
                }
              />
              <TemplateWithVariables
                title="Imminent Risk of Retaining Wall Damage"
                content={
                  imminentRisk.hasRetainingWallDamageImminentRiskLandslip
                }
                onContentChange={(content) =>
                  handleChange({
                    hasRetainingWallDamageImminentRiskLandslip: content,
                  })
                }
              />
              <TemplateWithVariables
                title="Imminent Risk of Services Damage"
                content={imminentRisk.hasServicesDamageImminentRiskLandslip}
                onContentChange={(content) =>
                  handleChange({
                    hasServicesDamageImminentRiskLandslip: content,
                  })
                }
              />
              <TemplateWithVariables
                title="Imminent Risk of Bridge or Culvert Damage"
                content={
                  imminentRisk.hasBridgeOrCulvertDamageImminentRiskLandslip
                }
                onContentChange={(content) =>
                  handleChange({
                    hasBridgeOrCulvertDamageImminentRiskLandslip: content,
                  })
                }
              />
              <Typography variant="h3">Storm and Flood</Typography>
              <TemplateWithVariables
                title="Has Storm and Flood imminent risk"
                content={imminentRisk.hasStormFloodImminentRisk}
                onContentChange={(val) =>
                  handleChange({
                    hasStormFloodImminentRisk: val,
                  })
                }
              />

              <TemplateWithVariables
                title="Imminent Risk of Dwelling Damage Storm"
                content={imminentRisk.hasDwellingDamageImminentRiskStorm}
                onContentChange={(content) =>
                  handleChange({
                    hasDwellingDamageImminentRiskStorm: content,
                  })
                }
              />

              <TemplateWithVariables
                title="Imminent Risk of Appurtenant Structure Damage Storm"
                content={
                  imminentRisk.hasAppurtenantStructureDamageImminentRiskStorm
                }
                onContentChange={(content) =>
                  handleChange({
                    hasAppurtenantStructureDamageImminentRiskStorm: content,
                  })
                }
              />

              <TemplateWithVariables
                title="Imminent Risk of Retaining Wall Damage Storm"
                content={imminentRisk.hasRetainingWallDamageImminentRiskStorm}
                onContentChange={(content) =>
                  handleChange({
                    hasRetainingWallDamageImminentRiskStorm: content,
                  })
                }
              />

              <TemplateWithVariables
                title="Imminent Risk of Services Damage Storm"
                content={imminentRisk.hasServicesDamageImminentRiskStorm}
                onContentChange={(content) =>
                  handleChange({
                    hasServicesDamageImminentRiskStorm: content,
                  })
                }
              />

              <TemplateWithVariables
                title="Imminent Risk of Bridge Or Culvert Damage Storm"
                content={imminentRisk.hasBridgeOrCulvertDamageImminentRiskStorm}
                onContentChange={(content) =>
                  handleChange({
                    hasBridgeOrCulvertDamageImminentRiskStorm: content,
                  })
                }
              />
            </Stack>
          </DialogContent>
          <DialogActions
            sx={{
              display: "flex",
              flexDirection: "row",
              margin: "0 1rem 1rem 1rem",
              alignItems: "flex-end",
            }}
          >
            <Button
              variant="contained"
              onClick={() => {
                onSave(imminentRisk);
              }}
              sx={{ width: 200 }}
            >
              Save
            </Button>
            <Button
              variant="outlined"
              onClick={() => {
                onClose();
              }}
              sx={{ width: 200 }}
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};
