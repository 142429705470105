import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { JSONContent } from "@tiptap/react";
import { useSize } from "ahooks";
import { useState } from "react";
import { TemplateWithVariables } from "src/shared/reportBuilder/templateVariables/TemplateWithVariables";

export type FooterModalProps = {
  content: JSONContent | string;
  title: string;
  onChange: ({
    content,
    footerHeight,
  }: {
    content?: string | JSONContent;
    footerHeight?: string;
  }) => void;
  onClose: VoidFunction;
  footerHeight?: string;
  onImageUpload?: (file: File) => Promise<{ url: string }>;
};
export const FooterModal = (props: FooterModalProps) => {
  const {
    content,
    onChange,
    onClose,
    onImageUpload,
    title,
    footerHeight: initialFooterHeight,
  } = props;
  const [newContent, setNewContent] = useState(content);
  const [footerHeight, setFooterHeight] = useState(initialFooterHeight);
  const [editor, setEditor] = useState<HTMLElement | null>(null);
  const size = useSize(editor);
  return (
    <Dialog
      open={true}
      onClose={onClose}
      maxWidth={false}
      sx={{ " .MuiPaper-root": { flexWrap: "nowrap" } }}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent sx={{}}>
        <TemplateWithVariables
          title=""
          editorId="footer-editor"
          content={newContent}
          onImageUpload={onImageUpload}
          includeStyles={false}
          onContentChange={(content) => {
            setNewContent(content);
          }}
          onLoad={() => {
            setEditor(document.getElementById("footer-editor"));
          }}
        />
      </DialogContent>
      <DialogActions
        sx={{
          display: "flex",
          flexDirection: "column",
          margin: "0 1rem 1rem 1rem",
        }}
      >
        <Typography>
          The current estimated height of the footer content is:{" "}
          {size?.height || 0} pixels
        </Typography>
        <Tooltip title="The footer height isn't dynamic. Adjust the screen pixel value and verify the PDF.">
          <TextField
            value={footerHeight}
            onChange={(e) => {
              setFooterHeight(e.target.value);
            }}
            label="Footer Height"
            InputProps={{
              endAdornment: "pixels",
            }}
          />
        </Tooltip>
        <Button
          variant="contained"
          sx={{ width: "100%", margin: "1rem 0 0 0 !important" }}
          onClick={() => {
            onChange({ content: newContent, footerHeight });
            onClose();
          }}
        >
          Save
        </Button>
        <Button
          onClick={() => {
            onClose();
          }}
          variant="outlined"
          sx={{ width: "100%", margin: "1rem 0 0 0 !important" }}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};
