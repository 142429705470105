import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Typography,
  Stack,
  Divider,
  Box,
} from "@mui/material";
import styled from "@emotion/styled";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { useEffect, useState } from "react";
import { enqueueSnackbar } from "notistack";
import { useMutation, useQueryClient } from "react-query";
import { axiosClient } from "src/lib/axiosClient";
import {
  enqueueSavingSnackbar,
  enqueueSuccessSnackbar,
} from "src/shared/snackbar/SnackbarHelper";
import { parseISO } from "date-fns";
import { bytesToSize } from "./SurveyManagement";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

type EditSurveyModalProps = {
  onClose: VoidFunction;
  survey: Survey;
};

export type Survey = {
  surveyName?: string;
  surveyDate?: string;
  surveyId?: string;
  orthomosaicFiles?: { name: string; fileSize: number }[];
  pointCloudFiles?: { name: string; fileSize: number }[];
  status?: string;
};

export const EditSurveyModal = (props: EditSurveyModalProps) => {
  const { onClose } = props;
  const [survey, setSurvey] = useState<Survey>(props.survey);
  const queryClient = useQueryClient();
  const { mutate: deleteSurvey } = useMutation(
    async (): Promise<undefined> => {
      const finishSaving = enqueueSavingSnackbar("Deleting");

      const res = (await axiosClient.delete(`/surveys/${survey.surveyId}`))
        .data;
      finishSaving();
      return res;
    },
    {
      onSuccess: () => {
        enqueueSuccessSnackbar("Successfully deleted");
      },
      onError: () => {
        enqueueSnackbar("Something went wrong", { variant: "error" });
      },
      onSettled: () => {
        queryClient.invalidateQueries({
          queryKey: [`surveys`],
        });
      },
    }
  );

  const { mutate: saveSurvey } = useMutation(
    async (survey: Survey): Promise<Survey> => {
      const finishSaving = enqueueSavingSnackbar();

      const res = (
        await axiosClient.put(`/surveys/${survey.surveyId}`, {
          surveyName: survey.surveyName,
          surveyDate: survey.surveyDate,
          completedUpload: true,
        })
      ).data;
      finishSaving();

      return res;
    },
    {
      onSuccess: () => {
        enqueueSuccessSnackbar();
      },
      onError: () => {
        enqueueSnackbar("Something went wrong", { variant: "error" });
      },
      onSettled: () => {
        queryClient.invalidateQueries({
          queryKey: [`surveys`],
        });
      },
    }
  );

  return (
    <Dialog open onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Edit Survey</DialogTitle>
      <DialogContent>
        <Wrapper>
          <Box>
            <InfoText>
              <InfoOutlinedIcon sx={{ mr: 1 }} />
              <span>
                Surveys take up to 30 minutes to be processed and available,
                check back here to see when the status changes.
              </span>
            </InfoText>
          </Box>
          <UploadOption
            title={"Orthomosaics"}
            files={survey.orthomosaicFiles}
          />
          <UploadOption title={"Point Cloud"} files={survey.pointCloudFiles} />
          <TextField
            label="Survey Name"
            placeholder="Try a place name and a date"
            sx={{ width: "100%" }}
            value={survey?.surveyName}
            onChange={(e) => {
              setSurvey({ ...survey, surveyName: e.target.value });
            }}
          />
          <Typography variant="body2">Survey Date</Typography>
          <DesktopDatePicker
            onChange={(date) => {
              setSurvey({ ...survey, surveyDate: date?.toISOString() });
            }}
            value={
              survey?.surveyDate ? parseISO(survey?.surveyDate) : undefined
            }
            sx={{ width: "100%" }}
          />
        </Wrapper>
      </DialogContent>
      <DialogActions
        sx={{
          display: "flex",
          flexDirection: "column",
          margin: "0 1rem 1rem 1rem",
        }}
      >
        <Button
          onClick={() => {
            saveSurvey(survey);
            onClose();
          }}
          variant="contained"
          sx={{ width: "100%", margin: "1rem 0 0 0 !important" }}
        >
          Save
        </Button>
        <Button
          onClick={() => {
            onClose();
          }}
          variant="outlined"
          sx={{ width: "100%", margin: "1rem 0 0 0 !important" }}
        >
          Cancel
        </Button>
        <Button
          onClick={() => {
            deleteSurvey();
            onClose();
          }}
          color="error"
          variant="contained"
          sx={{ width: "100%", margin: "1rem 0 0 0 !important" }}
        >
          Delete Survey
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const Wrapper = styled.div`
  & > :not(:last-child) {
    margin-bottom: 1rem;
  }
`;

type UploadOptionProps = {
  title: string;
  files?: { name: string; fileSize: number }[];
};
export const UploadOption = (props: UploadOptionProps) => {
  const { title, files } = props;
  return (
    <UploadOptionContainer>
      <Stack divider={<Divider />}>
        <UploadOptionAction>
          <UploadOptionText>
            <Typography variant="subtitle2" sx={{ fontWeight: 500 }}>
              {title}
            </Typography>
          </UploadOptionText>
        </UploadOptionAction>
        {files?.map((file) => {
          return (
            <div key={file.name}>
              <Typography>{file.name}</Typography>
              <Typography>{bytesToSize(file.fileSize)}</Typography>
            </div>
          );
        })}
      </Stack>
    </UploadOptionContainer>
  );
};

const UploadOptionAction = styled.div`
  display: flex;
  justify-content: space-between;
`;
const UploadOptionContainer = styled.div`
  border: 1px solid lightgrey;
  border-radius: 4px;
  padding: 1rem;
`;
const UploadOptionText = styled.div``;

const InfoText = styled.div`
  display: flex;
  align-items: center;
  background-color: #d8e9ff;
  border-radius: 12px;
  padding: 0.5rem;
  border: 1px solid black;
  margin-top: 1rem;
  margin-bottom: 1rem;
`;
