import { TReportTemplateStyles } from "@athena/server/src/api/types/reportTemplate";
import { MenuItem } from "@mui/material";
import { Control, FieldValues, Path } from "react-hook-form";
import { FormSelect } from "src/shared/form/FormSelect";
import { FormTextField } from "src/shared/form/FormTextField";
import { SectionsWithFont } from "./HeadingStyle";
import { PaddingEditor } from "./PaddingEditor";

interface FontStyleProps<T extends FieldValues> {
  type: SectionsWithFont;
  control: Control<T>;
  setValue: (path: Path<TReportTemplateStyles>, value: any) => void;
  children?: React.ReactNode;
  errors?: any;
}
const allowedFontSize = [
  4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 16, 18, 20, 22, 24, 26, 28, 36, 48, 72,
];

const allowedFontFamily = ["Calibri"];

export const FontStyle = (props: FontStyleProps<TReportTemplateStyles>) => {
  const { type, control, errors, children, setValue } = props;
  return (
    <div
      style={{
        display: "flex",
        flexWrap: "wrap",
        gap: "1rem",
        paddingLeft: "0rem",
      }}
    >
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          gap: "1rem",
        }}
      >
        <FormSelect
          control={control}
          name={`${type}.fontFamily`}
          placeholder="Font Family"
          label="Font Family"
          sx={{ minWidth: 200, maxWidth: 200 }}
        >
          {allowedFontFamily.map((i) => (
            <MenuItem key={`font-family-${i}`} value={i.toString()}>
              {i.toString()}
            </MenuItem>
          ))}
        </FormSelect>
        <FormSelect
          control={control}
          name={`${type}.fontSize`}
          placeholder="Font Size"
          label="Font Size"
          sx={{ minWidth: 70 }}
        >
          {allowedFontSize.map((i) => (
            <MenuItem key={`font-size-${i}`} value={i}>
              {i}
            </MenuItem>
          ))}
        </FormSelect>
        <FormTextField
          control={control}
          name={`${type}.color`}
          type="color"
          label="Font Color"
          sx={{ minWidth: 70 }}
        />
        <FormTextField
          control={control}
          name={`${type}.leading`}
          placeholder="Leading"
          label="Leading"
          sx={{ maxWidth: 70, minWidth: 70 }}
        />
        <FormSelect
          control={control}
          name={`${type}.fontWeight`}
          placeholder="Font Weight"
          label="Font Weight"
          sx={{ minWidth: 150, maxWidth: 150 }}
        >
          <MenuItem value="lighter">Lighter</MenuItem>
          <MenuItem value="normal">Normal</MenuItem>
          <MenuItem value="bold">Bold</MenuItem>
          <MenuItem value="bolder">Bolder</MenuItem>
        </FormSelect>
        <FormSelect
          control={control}
          name={`${type}.textAlign`}
          placeholder="Text Align"
          label="Text Align"
          sx={{ minWidth: 150, maxWidth: 150 }}
        >
          <MenuItem value="start">Left</MenuItem>
          <MenuItem value="center">Center</MenuItem>
          <MenuItem value="end">Right</MenuItem>
          <MenuItem value="justify">Justify</MenuItem>
        </FormSelect>
        {children}
      </div>

      <PaddingEditor {...props} />
    </div>
  );
};
