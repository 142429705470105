import styled from "@emotion/styled";
import { Box, Divider, Paper, Typography } from "@mui/material";
import { addDays, differenceInBusinessDays, startOfMonth } from "date-fns";
import { FC } from "react";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";

import { LoadingSpinner, SummaryGraph } from "@athena/components";
import {
  AdditionalStatus,
  Status,
  beingPreparedStatuses,
  postVisitStatuses,
  preVisitStatuses,
  underAssessmentStatuses,
  underClientReviewStatuses,
} from "@athena/server/src/api/types/claimStatuses";
import { Organisation } from "@athena/server/src/api/types/organisations";
import { TRPCRootRouter } from "@athena/server/src/trpc/index";

import { axiosClient } from "src/lib/axiosClient.ts";
import { config } from "src/lib/config";

import { inferProcedureOutput } from "@trpc/server";
import { useCurrentUser } from "src/shared/hooks/useCurrentUser";
import { FiltersValues } from "../components/EngineeringClaimFilters";
import { AppliedFilters } from "../components/NewEngineeringClaimFilters";
import { ClaimAgeChart } from "../components/charts/ClaimAgeChart";
import { ClaimBucketsTable } from "../components/charts/ClaimBucketsTable";
import { ClaimStatusChart } from "../components/charts/ClaimStatusChart";
import { DurationSinceLastActivityChart } from "../components/charts/DurationSinceLastActivityChart";

type ClaimStatusCounts = {
  claimData: {
    [key: string]: {
      "0 Days"?: number;
      "1-2 days"?: number;
      "3-4 Days"?: number;
      "4+ Days"?: number;
    };
  };
};

type ClaimStatus = {
  [key: string]: {
    status: string;
    overdue: number;
    overdueIn4Plus: number;
    overdueIn2To3: number;
    overdueIn1To2: number;
    exempt: number;
  };
};

const BaseChartValues = [
  Status.NeedsEngineer,
  Status.WaitingForCustomer,
  Status.VisitBooked,
  Status.VisitCompleted,
  Status.PreparingDraft,
  Status.UnderInternalReview,
  Status.UnderClientReview,
  Status.ClientAccepted,
];

type EventSLA = inferProcedureOutput<
  TRPCRootRouter["serviceLevelAgreements"]["getBatchSLA"]
>;

type Claim = inferProcedureOutput<TRPCRootRouter["claims"]["getAllClaims"]>[0];

interface Props {
  claims: Claim[];
  events: EventSLA;
  filters: FiltersValues;
  isLoadingActiveClaims: boolean;
}

export const EngineeringCapacity: FC<Props> = ({
  claims,
  filters,
  events,
  isLoadingActiveClaims,
}) => {
  const { userData } = useCurrentUser();

  const activeClaims = claims?.filter(
    (c) => c.status !== Status.Done && !c.completed
  );

  const stats = (activeClaims || []).reduce<{
    prepared: number;
    assessment: number;
    clientReview: number;
  }>(
    (stats, claim) => {
      if (beingPreparedStatuses.includes(claim.status as Status)) {
        return { ...stats, prepared: stats.prepared + 1 };
      }
      if (underAssessmentStatuses.includes(claim.status as Status)) {
        return { ...stats, assessment: stats.assessment + 1 };
      }
      if (underClientReviewStatuses.includes(claim.status as Status)) {
        return { ...stats, clientReview: stats.clientReview + 1 };
      }
      return stats;
    },
    { prepared: 0, assessment: 0, clientReview: 0 }
  );

  const startOfMonthDate = startOfMonth(new Date()).toISOString();

  const doneClaims = claims.filter(
    // (x) => x.completedAt && x.completedAt > startOfMonthDate,
    (x) => x.status === Status.Done
  );

  const { isLoading: isLoadingEngCompanies, data: engineeringCompanies } =
    useQuery(
      `engineeringCompanies`,
      async (): Promise<Organisation[]> =>
        (await axiosClient.get("/engineering-companies")).data
    );

  const { isLoading: loadingClaimStatus, data: claimStatusCounts } = useQuery(
    `claimStatusCounts`,
    async (): Promise<ClaimStatusCounts> =>
      (
        await axiosClient.get(
          `${config.gisApiUrl}/geo/ath/intelligencer/status-count`
        )
      ).data
  );

  const { isLoading: isLoadingLossAdjusters, data: lossAdjusters } = useQuery(
    `lossAdjusters`,
    async (): Promise<Organisation[]> =>
      (await axiosClient.get("/lossAdjusters")).data
  );

  const engineerOrgStats = [
    ...(activeClaims || []),
    ...(doneClaims || []),
  ].reduce<{
    [key: string]: {
      organisationId: string;
      inProgress: number;
      inReview: number;
      overdue: number;
      complete: number;
    };
  }>((stats, claim) => {
    if (!engineeringCompanies) return stats;
    const engOrg = engineeringCompanies.find(
      (org) => org.organisationId === claim.organisationId
    );
    if (!engOrg || !engOrg.organisationId) return stats;
    if (!stats[engOrg.name]) {
      stats[engOrg.name] = {
        organisationId: engOrg.organisationId,
        inProgress: 0,
        inReview: 0,
        overdue: 0,
        complete: 0,
      };
    }
    if (preVisitStatuses.includes(claim.status as Status)) {
      stats[engOrg.name].inProgress++;
    }
    if (postVisitStatuses.includes(claim.status as Status)) {
      stats[engOrg.name].inReview++;
    }
    if (
      ((claim.additionalStatuses || []).includes(AdditionalStatus.Overdue) ||
        (claim.additionalStatuses || []).includes(
          AdditionalStatus.AutoOverdue
        )) &&
      claim.status !== Status.Done &&
      !claim.completed
    ) {
      stats[engOrg.name].overdue++;
    }
    if (claim.status === Status.Done) {
      stats[engOrg.name].complete++;
    }

    return stats;
  }, {});

  const lossAdjusterStats = [
    ...(activeClaims || []),
    ...(doneClaims || []),
  ].reduce<{
    [key: string]: {
      lossAdjusterId: string;
      inProgress: number;
      inReview: number;
      overdue: number;
      complete: number;
    };
  }>((stats, claim) => {
    if (!lossAdjusters) return stats;
    const laOrg = lossAdjusters.find(
      (org) => org.organisationId === claim.lossAdjusterId
    );
    if (!laOrg || !laOrg.organisationId) return stats;
    if (!stats[laOrg.name]) {
      stats[laOrg.name] = {
        lossAdjusterId: laOrg.organisationId,
        inProgress: 0,
        inReview: 0,
        overdue: 0,
        complete: 0,
      };
    }
    if (preVisitStatuses.includes(claim.status as Status)) {
      stats[laOrg.name].inProgress++;
    }
    if (postVisitStatuses.includes(claim.status as Status)) {
      stats[laOrg.name].inReview++;
    }
    if (
      ((claim.additionalStatuses || []).includes(AdditionalStatus.Overdue) ||
        (claim.additionalStatuses || []).includes(
          AdditionalStatus.AutoOverdue
        )) &&
      claim.status !== Status.Done &&
      !claim.completed
    ) {
      stats[laOrg.name].overdue++;
    }
    if (claim.status === Status.Done) {
      stats[laOrg.name].complete++;
    }

    return stats;
  }, {});

  const startingStatus = Object.values(BaseChartValues).reduce<ClaimStatus>(
    (acc, status) => {
      acc[status] = {
        status,
        overdue: 0,
        overdueIn4Plus: 0,
        overdueIn2To3: 0,
        overdueIn1To2: 0,
        exempt: 0,
      };
      return acc;
    },
    {}
  );

  const claimStatus = activeClaims?.reduce((claimStatuses, claim) => {
    if (!claim.status || !BaseChartValues.includes(claim.status))
      return claimStatuses;
    if (!claimStatuses[claim.status]) {
      claimStatuses[claim.status] = {
        status: claim.status,
        overdue: 0,
        overdueIn4Plus: 0,
        overdueIn2To3: 0,
        overdueIn1To2: 0,
        exempt: 0,
      };
    }
    if (
      claim.additionalStatuses?.includes(AdditionalStatus.Overdue) ||
      claim.additionalStatuses?.includes(AdditionalStatus.AutoOverdue)
    ) {
      claimStatuses[claim.status].overdue++;
    } else if (
      claim.additionalStatuses?.includes(AdditionalStatus.TimeExempt)
    ) {
      claimStatuses[claim.status].exempt++;
    } else {
      const overdueIn = Math.abs(daysTillOverdue(claim as any, events));
      if (overdueIn < 0) return claimStatuses;
      if (overdueIn >= 4) {
        claimStatuses[claim.status].overdueIn4Plus++;
      } else if (overdueIn >= 2) {
        claimStatuses[claim.status].overdueIn2To3++;
      } else if (overdueIn >= 0) {
        claimStatuses[claim.status].overdueIn1To2++;
      }
    }
    return claimStatuses;
  }, startingStatus);

  //If filters for statuses are set, but not records match, pad with zero to keep the chart consistent
  if (filters.statuses && filters.statuses.length > 0) {
    filters.statuses.map((status) => {
      if (!claimStatus[status]) {
        claimStatus[status] = {
          status: status,
          overdue: 0,
          overdueIn4Plus: 0,
          overdueIn2To3: 0,
          overdueIn1To2: 0,
          exempt: 0,
        };
      }
    });
  }

  const getClaimAgeLabel = (age: number) => {
    if (age >= 0 && age <= 5) {
      return "0to5";
    } else if (age > 5 && age <= 10) {
      return "5to10";
    } else if (age > 10 && age <= 15) {
      return "10to15";
    } else if (age > 15 && age <= 20) {
      return "15to20";
    } else if (age > 20 && age <= 25) {
      return "20to25";
    } else if (age > 25 && age <= 30) {
      return "25to30";
    } else if (age > 30 && age <= 35) {
      return "30to35";
    } else if (age > 35 && age <= 40) {
      return "35to40";
    } else if (age > 40 && age <= 45) {
      return "40to45";
    } else if (age > 45 && age <= 50) {
      return "45to50";
    } else if (age > 50 && age <= 55) {
      return "50to55";
    } else if (age > 55 && age <= 60) {
      return "55to60";
    } else {
      return "60+";
    }
  };
  const claimAge = activeClaims?.reduce<{
    [key: string]: {
      label: string;
      [Status.Setup]?: number;
      [Status.NeedsEngineer]?: number;
      [Status.WaitingForCustomer]?: number;
      [Status.VisitBooked]?: number;
      [Status.VisitCompleted]?: number;
      [Status.PreparingDraft]?: number;
      [Status.UnderInternalReview]?: number;
      [Status.UnderClientReview]?: number;
      [Status.ClientAccepted]?: number;
      [Status.Done]?: number;
    };
  }>(
    (claimAge, claim) => {
      const status = claim.status;
      if (!status) return claimAge;
      if (!claim.serviceRequestDate && !claim.created) return claimAge;
      const age = differenceInBusinessDays(
        new Date(),
        new Date(claim.serviceRequestDate || claim.created || "")
      );
      const ageLabel = `age${getClaimAgeLabel(age)}`;
      if (!claimAge[ageLabel]) {
        claimAge[ageLabel] = {
          [status]: 0,
          label: claimAge[ageLabel].label,
          // label: getClaimAgeLabel(age).replace("to", " to ") + " days",
        };
      }
      const claimStatus = claimAge[ageLabel];
      const value = claimStatus[status] || 0;
      claimStatus[status] = value + 1;

      return claimAge;
    },
    {
      ["age0to5"]: { label: "0-5" },
      ["age5to10"]: { label: "5-10" },
      ["age10to15"]: { label: "10-15" },
      ["age15to20"]: { label: "15-20" },
      ["age20to25"]: { label: "20-25" },
      ["age25to30"]: { label: "25-30" },
      ["age30to35"]: { label: "30-35" },
      ["age35to40"]: { label: "35-40" },
      ["age40to45"]: { label: "40-45" },
      ["age45to50"]: { label: "45-50" },
      ["age50to55"]: { label: "50-55" },
      ["age55to60"]: { label: "55-60" },
      ["age60+"]: { label: "60+" },
    }
  );

  const durationChartData = Object.entries(
    claimStatusCounts?.claimData || []
  ).reduce(
    (acc, [status, vals]) => {
      const statusData = acc.findIndex((x) => x.status === status);
      if (statusData !== -1) {
        acc[statusData] = {
          ...acc[statusData],
          ...vals,
        };
      }
      return acc;
    },
    Object.values(BaseChartValues).map((status) => {
      return {
        ["1-2 Days"]: 0,
        ["3-4 Days"]: 0,
        ["4+ Days"]: 0,
        status: status as Status,
      };
    })
  );

  return (
    <>
      <Paper sx={{ mt: "1.5rem", padding: "1.5rem" }}>
        <Title>
          <div className="title-period">
            <h3>Claims</h3>
            {/* <aside> - This month</aside> */}
          </div>
          {activeClaims && doneClaims && (
            <a
              className="title-link"
              href="/engineering-claims?includeDone=true"
            >
              {activeClaims.length + doneClaims.length}
            </a>
          )}
        </Title>
        {isLoadingActiveClaims && !doneClaims ? (
          <LoadingSpinner />
        ) : (
          <SummaryGraph
            assessment={stats.assessment}
            clientReview={stats.clientReview}
            completed={doneClaims?.length || 0}
            prepared={stats.prepared}
          />
        )}
      </Paper>
      <StatContainer>
        <Paper>
          <div className="flex-1 line-item">
            <div className="status preparation"></div>
            <h4>Being prepared</h4>
          </div>
          {isLoadingActiveClaims ? (
            <LoadingSpinner />
          ) : (
            <>
              <Link
                className="line-item"
                to={"/engineering-claims?statusGroup=being-prepared"}
              >
                <div className="splitter">
                  <div className="flex-1">
                    <img src="./images/claim.png" />
                    <p>{stats.prepared} Claims</p>
                  </div>
                  <img src="./images/chevron-right.svg" />
                </div>
              </Link>
              <div className="flex-1 line-item">
                {/* <img src="./images/trending-up.svg" />
                  <p>30% increase month on month</p> */}
              </div>
            </>
          )}
        </Paper>
        <Paper>
          <div className="flex-1 line-item">
            <div className="status assessment"></div>
            <h4>Under assessment</h4>
          </div>
          {isLoadingActiveClaims ? (
            <LoadingSpinner />
          ) : (
            <>
              <Link
                className="line-item"
                to={"/engineering-claims?statusGroup=under-assessment"}
              >
                <div className="splitter">
                  <div className="flex-1">
                    <img src="./images/claim.png" />
                    <p>{stats.assessment} Claims</p>
                  </div>
                  <img src="./images/chevron-right.svg" />
                </div>
              </Link>
              <div className="flex-1 line-item">
                {/* <img src="./images/trending-up.svg" />
                  <p>30% increase month on month</p> */}
              </div>
            </>
          )}
        </Paper>
        <Paper>
          <div className="flex-1 line-item">
            <div className="status review"></div>
            <h4>Under client review</h4>
          </div>
          {isLoadingActiveClaims ? (
            <LoadingSpinner />
          ) : (
            <>
              <Link
                className="line-item"
                to={"/engineering-claims?statusGroup=under-client-review"}
              >
                <div className="splitter">
                  <div className="flex-1">
                    <img src="./images/claim.png" />
                    <p>{stats.clientReview} Claims</p>
                  </div>
                  <img src="./images/chevron-right.svg" />
                </div>
              </Link>
              <div className="flex-1 line-item">
                {/* <img src="./images/trending-up.svg" />
                  <p>30% increase month on month</p> */}
              </div>
            </>
          )}
        </Paper>
        <Paper>
          <div className="flex-1 line-item">
            <div className="status complete"></div>
            <h4>Complete</h4>
          </div>
          <>
            <Link
              className="line-item"
              to={"/engineering-claims?statusGroup=complete&includeDone=true"}
            >
              {" "}
              <div className="splitter">
                <div className="flex-1">
                  <img src="./images/claim.png" />
                  <p>{doneClaims?.length} Claims</p>
                </div>
                <img src="./images/chevron-right.svg" />
              </div>
            </Link>
            <div className="flex-1 line-item">
              {/* <img src="./images/trending-up.svg" />
                  <p>30% increase month on month</p> */}
            </div>
          </>
        </Paper>
      </StatContainer>
      <Paper sx={{ mt: "3rem", padding: "1.5rem" }}>
        <Title>
          <div className="title-period">
            <h3>Active and Overdue Claim - Health Status</h3>
            <AppliedFilters filters={filters} />
          </div>
        </Title>
        <ClaimStatusChart
          isLoading={isLoadingActiveClaims}
          data={Object.values(claimStatus || {})}
        />
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            pl: 2,
            pr: 2,
          }}
        >
          <div />
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography variant="h1" sx={{ mr: 2 }}>
              {activeClaims?.filter(
                (claim) =>
                  !claim.additionalStatuses?.includes(
                    AdditionalStatus.Overdue
                  ) &&
                  !claim.additionalStatuses?.includes(
                    AdditionalStatus.AutoOverdue
                  ) &&
                  claim.status !== Status.Done
              ).length || 0}{" "}
            </Typography>
            <Typography>Claims on time </Typography>
          </Box>
          <Divider orientation="vertical" flexItem />
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography variant="h1" sx={{ mr: 2 }}>
              {activeClaims?.filter(
                (claim) =>
                  claim.additionalStatuses?.includes(
                    AdditionalStatus.Overdue
                  ) ||
                  claim.additionalStatuses?.includes(
                    AdditionalStatus.AutoOverdue
                  )
              ).length || 0}{" "}
            </Typography>
            <Typography>Claims overdue</Typography>
          </Box>
          <div />
        </Box>
      </Paper>
      <Paper sx={{ mt: "3rem", padding: "1.5rem" }}>
        <Title>
          <div className="title-period">
            <h3>Duration since last activity</h3>
            <AppliedFilters filters={filters} availableFilters={["None"]} />
          </div>
        </Title>
        <DurationSinceLastActivityChart
          isLoading={loadingClaimStatus}
          isComingSoon={userData?.organisation?.orgType === "eqc"}
          data={durationChartData}
        />
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            pl: 2,
            pr: 2,
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography variant="h1" sx={{ mr: 2 }}>
              {activeClaims?.length || 0}{" "}
            </Typography>
            <Typography>Claims</Typography>
          </Box>
        </Box>
      </Paper>
      <Paper sx={{ mt: "3rem", padding: "1.5rem" }}>
        <Title>
          <div className="title-period">
            <h3>Cumulative Claim Age</h3>
            <AppliedFilters filters={filters} />
          </div>
        </Title>
        <ClaimAgeChart
          isLoading={isLoadingActiveClaims}
          data={claimAge || {}}
        />
        <ClaimAgeLegend>
          {Object.values(BaseChartValues).map((status, index) => {
            const colors = [
              "#ED5151",
              "#F199C3",
              "#FFE35B",
              "#F38115",
              "#8181DC",
              "#BDE579",
              "#37ACD5",
              "#C791C8",
              "#4EB265",
            ];
            return (
              <ClaimAgeLegendItem key={status}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      width: 20,
                      height: 20,
                      borderRadius: "50%",
                      backgroundColor: colors[index],
                      display: "inline-block",
                      marginRight: 8,
                    }}
                  />
                  {status}
                </Box>
                <Box sx={{ display: "flex", alignItems: "end", ml: 4 }}>
                  <Typography variant="h1" sx={{ mr: 1 }}>
                    {activeClaims?.filter((claim) => claim.status === status)
                      .length || 0}
                  </Typography>
                  <Typography
                    sx={{
                      pb: "2px",
                      fontWeight: 400,
                      fontSize: "1rem",
                    }}
                  >
                    Claims
                  </Typography>
                </Box>
              </ClaimAgeLegendItem>
            );
          })}
        </ClaimAgeLegend>
      </Paper>

      <Paper sx={{ mt: "3rem", padding: "1.5rem" }}>
        <Title>
          <div className="title-period">
            <h3>Loss Adjuster Performance</h3>
            <AppliedFilters filters={filters} />
            {/* <aside> - This month</aside> */}
          </div>
          <a className="title-link" href="#"></a>
        </Title>
        <ClaimBucketsTable
          stats={lossAdjusterStats}
          userLabel="Loss Adjuster"
          filterProperty="lossAdjusterId"
          urlFilterProperty="lossAdjusterId"
        />
      </Paper>
      <Paper sx={{ mt: "3rem", padding: "1.5rem" }}>
        <Title>
          <div className="title-period">
            <h3>Engineering Company Performance</h3>
            <AppliedFilters filters={filters} />
            {/* <aside> - This month</aside> */}
          </div>
          <a className="title-link" href="#"></a>
        </Title>
        <ClaimBucketsTable
          stats={engineerOrgStats}
          userLabel="Organisation"
          filterProperty="organisationId"
          urlFilterProperty="organisationId"
        />
      </Paper>
    </>
  );
};

const StatContainer = styled.div`
  display: flex;
  gap: 1.5rem;
  margin-top: 1.5rem;

  .MuiPaper-root {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 1.5rem;

    a p {
      color: #0067df;
    }

    div.line-item:first-of-type {
      padding-top: 0;
    }

    div.line-item:last-of-type {
      border-bottom: initial;
      padding-bottom: 0;
    }
  }

  .line-item {
    border-bottom: 1px solid lightgray;
    padding-bottom: 1.25rem;
    padding-top: 1.25rem;
    width: 100%;
  }

  .flex-1 {
    display: flex;
    flex-direction: row;
    gap: 1rem;
  }

  .splitter {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .preparation {
    background-color: black;
  }

  .assessment {
    background-color: #bee8ff;
  }

  .review {
    background-color: #aa66ff;
  }

  .complete {
    background-color: #51f44b;
  }

  .status {
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 1200px;
  }
`;

const Title = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  border-bottom: 1px solid lightgray;

  .title-link {
    text-decoration: none;
    color: #0067df;
  }

  .title-period {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5rem;

    aside {
      color: #666;
    }
  }
`;

const ClaimAgeLegend = styled.div`
  --gap: 2em;
  --line-offset: calc(var(--gap) / 2);
  --line-thickness: 2px;
  --line-color: lightgray;
  --legend-cols: 4;

  border-top: 1px solid var(--line-color);
  padding-top: var(--gap/2);
  grid-template-columns: repeat(var(--legend-cols), minmax(0, 1fr));
  gap: var(--gap);
  display: grid;
`;

const ClaimAgeLegendItem = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 1rem;
  margin-top: 2rem;
  position: relative;
`;

export const daysTillOverdue = (claim: Claim, events: EventSLA) => {
  const date = claim.serviceRequestDate || claim.created;
  if (!date) {
    return -1;
  }
  const createdDate = new Date(date);

  if (!createdDate) {
    console.log("No created date");
    return -1;
  }

  let sla = {
    initialCustomerContact: 3,
    initialCustomerContactUrgent: 1,
    siteVisitCompleted: 20,
    siteVisitCompletedUrgent: 2,
    siteReportCompleted: 40,
    siteReportCompletedUrgent: 15,
  };

  const slas = claim.events?.reduce((acc, event) => {
    const foundSla = events.find(
      (e) => e.eventId === event.id && e.insurerId === claim.insurer?.id
    )?.sla;
    if (foundSla) {
      acc.push(foundSla);
    }
    return acc;
  }, [] as EventSLA[0]["sla"][]);

  if (slas.length > 0) {
    sla = {
      initialCustomerContact: Math.min(
        ...slas.map((s) => s.intialCustomerContact)
      ),
      initialCustomerContactUrgent: Math.min(
        ...slas.map((s) => s.intialCustomerContactUrgent)
      ),
      siteVisitCompleted: Math.min(...slas.map((s) => s.siteVisitCompleted)),
      siteVisitCompletedUrgent: Math.min(
        ...slas.map((s) => s.siteVisitCompletedUrgent)
      ),
      siteReportCompleted: Math.min(...slas.map((s) => s.siteReportCompleted)),
      siteReportCompletedUrgent: Math.min(
        ...slas.map((s) => s.siteReportCompletedUrgent)
      ),
    };
  }

  const urgent = claim.urgency === "Urgent";
  const minimumSLA = {
    initialContact: urgent
      ? sla.initialCustomerContactUrgent
      : sla.initialCustomerContact,
    siteVisit: urgent ? sla.siteVisitCompletedUrgent : sla.siteVisitCompleted,
    siteReport: urgent
      ? sla.siteReportCompletedUrgent
      : sla.siteReportCompleted,
  };

  if (!claim.customerContacted) {
    return differenceInBusinessDays(
      new Date(),
      addDays(createdDate, minimumSLA.initialContact)
    );
  }

  const siteVisits = claim.siteVisits
    .filter((sv) => sv.siteVisited)
    .sort((a, b) => a.visitDate - b.visitDate);

  if (siteVisits.length === 0) {
    return differenceInBusinessDays(
      new Date(),
      addDays(createdDate, minimumSLA.siteVisit)
    );
  }

  const firstSiteVisitDate = new Date(siteVisits[0].visitDate);

  if (
    [
      Status.VisitCompleted,
      Status.PreparingDraft,
      Status.UnderInternalReview,
      Status.UnderClientReview,
    ].includes(claim.status as Status)
  ) {
    return differenceInBusinessDays(
      new Date(),
      addDays(firstSiteVisitDate, minimumSLA.siteReport)
    );
  }

  if (claim.status !== Status.Done) {
    return differenceInBusinessDays(
      new Date(),
      addDays(firstSiteVisitDate, minimumSLA.siteReport)
    );
  }
  return -1;
};
