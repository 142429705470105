import { useQuery } from "react-query";
import { axiosClient } from "src/lib/axiosClient";
import { useCurrentUserContext } from "src/shared/hooks/useCurrentUserContext";
import { LoadingSpinner } from "@athena/components";
import { EmailPreferences } from "src/modules/admin/general/EmailPreferences.tsx";

export const InsurerGeneralSettings = () => {
  const { user } = useCurrentUserContext();

  const { isLoading: loadingOrgPreferences, data: orgPreferences } = useQuery(
    `orgPreferences`,
    async () =>
      (
        await axiosClient.get(
          `/organisations/${user.organisation.id}/preferences`
        )
      ).data
  );

  if (loadingOrgPreferences) return <LoadingSpinner />;
  return (
    <>
      <EmailPreferences
        loadingOrgEmails={loadingOrgPreferences}
        orgEmails={orgPreferences.emails || []}
      />
    </>
  );
};
