import styled from "@emotion/styled";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Box, Tooltip, Typography } from "@mui/material";
import { PrivacyChip } from "../PrivacyChip";

interface ClaimDetailsSectionProps {
  title: string;
  id?: string;
  subtitle?: string;
  tooltip?: string;
  children?: React.ReactNode;
  shared?: boolean;
  visible?: boolean;
}

export function ClaimDetailsSection({
  children,
  title,
  id,
  tooltip,
  subtitle,
  shared = true,
  visible = true,
}: ClaimDetailsSectionProps) {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "start",
          flexDirection: "column",

          marginBottom: "1rem",
        }}
      >
        <Typography
          variant="h4"
          sx={{
            marginRight: 1,
            display: "flex",
            alignItems: "center",
            gap: "0.25rem",
          }}
          component="h2"
          id={id}
        >
          {title}{" "}
          {tooltip && (
            <Tooltip title={tooltip}>
              <InfoOutlinedIcon color="info" />
            </Tooltip>
          )}
          {visible && <PrivacyChip shared={shared} />}
        </Typography>

        {subtitle && (
          <ClaimDetailSectionSubtitle>{subtitle}</ClaimDetailSectionSubtitle>
        )}
      </Box>
      {children}
    </>
  );
}

const ClaimDetailSectionSubtitle = styled.p`
  margin-top: 0.5rem !important;
`;
