export type IconProps = {
  color: string;
  fillColor: string;
};

export const PolygonFillIcon = (props: IconProps) => {
  const { color, fillColor } = props;
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21 18C20.587 18 20.193 18.084 19.835 18.235L15.62 13.458C15.861 13.026 16 12.529 16 12C16 11.466 15.859 10.965 15.614 10.53L19.797 5.747C20.166 5.909 20.573 6 21 6C22.654 6 24 4.654 24 3C24 1.346 22.654 0 21 0C19.696 0 18.585 0.836 18.172 2H5.828C5.415 0.836 4.304 0 3 0C1.346 0 0 1.346 0 3C0 4.304 0.836 5.415 2 5.828V18.172C0.836 18.585 0 19.696 0 21C0 22.654 1.346 24 3 24C4.304 24 5.415 23.164 5.828 22H18.172C18.585 23.164 19.696 24 21 24C22.654 24 24 22.654 24 21C24 19.346 22.654 18 21 18Z"
        fill={fillColor}
      />
      <path
        d="M21 18C20.587 18 20.193 18.084 19.835 18.235L15.62 13.458C15.861 13.026 16 12.529 16 12C16 11.466 15.859 10.965 15.614 10.53L19.797 5.747C20.166 5.909 20.573 6 21 6C22.654 6 24 4.654 24 3C24 1.346 22.654 0 21 0C19.696 0 18.585 0.836 18.172 2H5.828C5.415 0.836 4.304 0 3 0C1.346 0 0 1.346 0 3C0 4.304 0.836 5.415 2 5.828V18.172C0.836 18.585 0 19.696 0 21C0 22.654 1.346 24 3 24C4.304 24 5.415 23.164 5.828 22H18.172C18.585 23.164 19.696 24 21 24C22.654 24 24 22.654 24 21C24 19.346 22.654 18 21 18ZM5.828 4H18.172C18.218 4.13 18.274 4.257 18.337 4.378L14.109 9.213C13.766 9.076 13.392 9 13 9C11.346 9 10 10.346 10 12C10 13.654 11.346 15 13 15C13.396 15 13.774 14.922 14.121 14.782L18.357 19.583C18.285 19.716 18.224 19.856 18.172 20H5.828C5.526 19.149 4.851 18.474 4 18.172V5.828C4.851 5.526 5.526 4.851 5.828 4Z"
        fill={color}
      />
    </svg>
  );
};
