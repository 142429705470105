import { Button, Paper, Typography } from "@mui/material";
import { enqueueSnackbar } from "notistack";
import { useRef } from "react";
import { useQueryClient, useMutation } from "react-query";
import { axiosClient } from "src/lib/axiosClient";
import { useCurrentUserContext } from "src/shared/hooks/useCurrentUserContext";
import {
  enqueueSavingSnackbar,
  enqueueSuccessSnackbar,
} from "src/shared/snackbar/SnackbarHelper";
import { config } from "src/lib/config";
import { AuthenticatedImage } from "src/shared/AuthenticatedImage";
import { trpc } from "src/lib/api/trpc";

export const GeneralSettings = () => {
  const { user } = useCurrentUserContext();
  const fileInput = useRef<HTMLInputElement>(null);
  const queryClient = useQueryClient();
  const trpcContext = trpc.useContext();

  const { mutate: deleteSignature } = useMutation(
    async (): Promise<undefined> => {
      const finishedSaving = enqueueSavingSnackbar("Deleting");

      const res = (await axiosClient.delete(`/users/${user.userId}/signature`))
        .data;
      finishedSaving();
      enqueueSuccessSnackbar("Deleted");

      return res;
    },
    {
      onError: () => {
        enqueueSnackbar("Something went wrong", { variant: "error" });
      },
      onSettled: () => {
        queryClient.invalidateQueries({
          queryKey: [`user`],
        });
        trpcContext.user.invalidate();
      },
    }
  );

  const uploadSignature = async (file: any) => {
    const formData = new FormData();
    formData.append("image", file);

    const finishedSaving = enqueueSavingSnackbar();
    const res = (
      await axiosClient.post(`/users/${user.userId}/signature`, formData)
    ).data;
    finishedSaving();
    enqueueSuccessSnackbar("Uploaded");
    queryClient.invalidateQueries({
      queryKey: [`user`],
    });
    trpcContext.user.invalidate();

    return res;
  };
  return (
    <Paper sx={{ padding: 2 }}>
      <Typography variant="h4">Report Signature</Typography>
      <input
        type="file"
        name="files"
        accept="image/*"
        onChange={(event) => {
          if (!event.target.files?.length) return;
          uploadSignature(event.target.files[0]);
        }}
        ref={fileInput}
        style={{ display: "none" }}
      />
      {user.signatureUrl && (
        <section>
          <div>
            <AuthenticatedImage
              src={`${config.apiUrl}/${user.signatureUrl}`}
              alt="file"
            />
          </div>
          <Button
            variant="outlined"
            sx={{ minWidth: "200px" }}
            onClick={() => deleteSignature()}
          >
            Remove
          </Button>
        </section>
      )}
      {!user.signatureUrl && (
        <Button
          variant="outlined"
          sx={{ minWidth: "200px" }}
          onClick={() => fileInput.current?.click()}
        >
          Upload
        </Button>
      )}
    </Paper>
  );
};
