import {
  enqueueSavingSnackbar,
  enqueueSuccessSnackbar,
} from "src/shared/snackbar/SnackbarHelper";

import { LoadingSpinner } from "@athena/components";
import { Button, TextField } from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { axiosClient } from "src/lib/axiosClient";

interface LinzRequest {
  key: string;
}

export const LinzManager = () => {
  const queryClient = useQueryClient();
  const [currentLinz, setCurrentLinz] = useState<LinzRequest>();

  const { isLoading: loading } = useQuery(
    `infinityGuardianLinz`,
    async (): Promise<LinzRequest> =>
      (await axiosClient.get("/admin/linz")).data,
    {
      onSuccess: (data) => {
        setCurrentLinz(data);
      },
    }
  );

  const saveLinz = async () => {
    const finishedSaving = enqueueSavingSnackbar();
    const res = (await axiosClient.put(`/admin/linz`, currentLinz)).data;
    finishedSaving();
    enqueueSuccessSnackbar("Updated Linz key");
    queryClient.invalidateQueries({
      queryKey: [`infinityGuardianLinz`],
    });
  };

  if (loading) return <LoadingSpinner />;
  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: 2,
          mb: 2,
        }}
      >
        <TextField
          label="Linz Key"
          value={currentLinz?.key}
          onChange={(e) => setCurrentLinz({ key: e.target.value })}
          sx={{ minWidth: 300 }}
        />

        <Button
          onClick={saveLinz}
          variant="contained"
          sx={{ minWidth: 100, height: 40, mt: 2.5 }}
        >
          Save
        </Button>
      </Box>
    </Box>
  );
};
