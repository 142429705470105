import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import styled from "@emotion/styled";
import { SmallButton } from "../components/styled";

interface OLRZoomProps {
  onIncrement?: () => void;
  onDecrement?: () => void;
}

export const ZoomButtons = ({ onIncrement, onDecrement }: OLRZoomProps) => {
  return (
    <Container>
      <SmallButton onClick={onIncrement}>
        <AddIcon />
      </SmallButton>
      <Separator />
      <SmallButton onClick={onDecrement}>
        <RemoveIcon />
      </SmallButton>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;

  border-radius: 4px;
  background-color: #fff !important;

  border: 1px solid lightgray;
`;

const Separator = styled.span`
  border-top: 1px solid lightgray;
`;
