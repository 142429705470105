import { Geometry, LineString, MultiLineString, Polygon } from "ol/geom";
import getArea from "@turf/area";
import getLength from "@turf/length";
import { geoJson } from "./utils/olToTurf";
import { roundToNearestHalf } from "../claims/Maps/util/math";

export const formatLength = function (line: LineString | MultiLineString) {
  const length = getLength(geoJson.writeGeometryObject(line) as any);
  return length * 1000;
};

export const formatArea = function (polygon: Polygon) {
  const area = getArea(geoJson.writeGeometryObject(polygon) as any);
  return Math.round(area * 100) / 100; //+ " " + "m<sup>2</sup>";
};

export const formatAreaToNearestHalf = function (polygon: Polygon) {
  const area = getArea(geoJson.writeGeometryObject(polygon) as any);
  return roundToNearestHalf(area);
};

export const roundUpArea = function (polygon: Polygon) {
  const area = getArea(geoJson.writeGeometryObject(polygon) as any);
  return Math.ceil(area);
};

export const getMeasurement = (geom: Geometry) => {
  let measurement = 0;

  if (geom instanceof Polygon) {
    measurement = formatArea(geom);
  } else if (geom instanceof LineString) {
    measurement = formatLength(geom);
  }

  return measurement;
};
