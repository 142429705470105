import { Status } from "@athena/server/src/api/types/claimStatuses";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import { config } from "src/lib/config";
import { useCurrentUserContext } from "src/shared/hooks/useCurrentUserContext";

export enum AvailableReport {
  None,
  Draft,
  Final,
}

type ReportDownloadButtonProps = {
  claim: {
    claimId: string;
    status: Status | undefined;
    organisationId: string | undefined;
  };
  variant: "icon" | "button";
};

export const ReportDownloadButton = (props: ReportDownloadButtonProps) => {
  const { claim, variant } = props;
  const { organisation } = useCurrentUserContext();
  let availableReport = AvailableReport.None;
  if (!claim.organisationId || !claim.status) return null;

  if (claim.status) {
    if (
      [Status.UnderInternalReview, Status.UnderClientReview].includes(
        claim.status
      )
    ) {
      availableReport = AvailableReport.Draft;
    } else if ([Status.ClientAccepted, Status.Done].includes(claim.status)) {
      availableReport = AvailableReport.Final;
    }
  }

  const eqcMode = organisation?.orgType === "eqc";
  const showLink = eqcMode
    ? availableReport === AvailableReport.Final
      ? true
      : false
    : availableReport > 0;

  const variants = {
    ["icon"]: <CloudDownloadIcon fontSize={"medium"} />,
    ["button"]: <Button variant="contained"> Download latest report </Button>,
  };
  return showLink ? (
    <Link
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      to={`${config.apiUrl}/claims/${claim.claimId}/latest-report/${claim.organisationId}`}
      target="_blank"
      rel="noopener noreferrer"
    >
      {variants[variant]}
    </Link>
  ) : null;
};
