import Chip, { ChipProps } from "@mui/material/Chip";
import { ReactElement } from "react";

type IconOnlyChipProps = { icon: ReactElement } & ChipProps;

export const IconOnlyChip = (props: IconOnlyChipProps) => {
  return (
    <Chip
      {...props}
      sx={{
        "& .MuiChip-icon": {
          marginLeft: 1,
          marginRight: 1,
        },
        "& .MuiChip-label": {
          display: "none",
        },
        ...(props.sx || {}),
      }}
    />
  );
};
