import styled from "@emotion/styled";
import { Box, Button, css } from "@mui/material";

export const IconImgExtraLarge = styled.img`
  height: 1.5rem;
  width: 1.5rem;
  object-fit: contain;
`;

const IconImgLarge = styled.img`
  height: 1.25rem;
  width: 1.25rem;
  object-fit: contain;
`;

const IconImgSmall = styled.img`
  height: 1rem;
  width: 1rem;
  object-fit: contain;
`;

const ListItem = styled.button<{ isSelected?: boolean }>`
  background: none;
  border: none;
  padding: 0.5rem 0.25rem;
  border-top: 1px solid lightgray;
  width: 100%;
  text-align: left;
  font-family: inherit;
  font-weight: 500;
  font-size: 1rem;
  color: #0067df;
  align-items: center;
  display: flex;
  gap: 1rem;
  z-index: 1;

  :hover {
    color: blue;
    cursor: pointer;
    & svg * {
      stroke: blue;
      fill: blue;
    }
  }

  ${(p) =>
    p.isSelected &&
    css`
      color: #333333;
      & svg * {
        stroke: #333333;
        fill: #333333;
      }
    `};
`;

const ButtonStyle = styled(Button)`
  min-height: 3.5rem !important;
  min-width: 3.5rem !important;
  border-radius: 16px;
  background-color: #fff;
  border: 2px solid lightgray;
  padding: 0;
  z-index: 1;
  &:hover {
    filter: brightness(90%);
    background-color: #fff;
  }
`;

const SmallButton = styled(Button)`
  min-height: 2rem !important;
  min-width: 2rem !important;
  width: 2rem;
  height: 2rem;
  border-radius: 4px;
  background-color: #fff !important;
  border: 1px solid lightgray;
  padding: 0.5rem;
  z-index: 1;
  &:hover {
    background-color: #0067df !important;
  }
`;


export { IconImgLarge, IconImgSmall, ListItem, ButtonStyle, SmallButton};
