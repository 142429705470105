import { Browser } from "@capacitor/browser";
import { Capacitor } from "@capacitor/core";
import { config } from "src/lib/config";
import { clerk } from "./auth";

export function redirectToAuth() {
  if (!clerk) {
    throw new Error("Clerk is not initialised")
  }
  clerk.signOut(() => {
    window.location.replace("/");
  });
}
