import { ClaimDetailsSection } from "@athena/components";
import { TRPCRootRouter } from "@athena/server/src/trpc";
import styled from "@emotion/styled";
import { Box, Paper, Stack } from "@mui/material";
import { inferRouterOutputs } from "@trpc/server";
import { config } from "src/lib/config";

interface Props {
  claim: inferRouterOutputs<TRPCRootRouter>["claims"]["getClaim"];
}

export function Attachments({ claim }: Props) {
  return (
    <Box
      sx={{
        flexGrow: 1,
        mb: 3,
      }}
    >
      <Paper
        elevation={4}
        sx={{
          padding: "1.5rem",
          mb: "1.5rem",
          scrollMarginTop: "5.5rem",
        }}
        id="attachments"
      >
        <ClaimDetailsSection title="Attachments">
          <Stack
            direction={"column"}
            sx={{
              maxWidth: "1000px",
              mb: 2,
            }}
          >
            <AttachmentContainer>
              {claim.attachments?.map((x) => (
                <section key={x.key}>
                  <div>
                    <img
                      src="/images/icons/common-file-double.svg"
                      alt="file"
                    />
                    <a
                      href={`${config.apiUrl}/${x.url}/${claim.organisationId}/${claim.claimId}`}
                      // onClick={(e) => {
                      //   e.preventDefault();
                      //   downloadFile(x.url, x.name);
                      // }}
                      rel="noreferrer noopener"
                      download={x.name}
                      target="_blank"
                    >
                      {x.name}
                    </a>
                  </div>
                </section>
              ))}
            </AttachmentContainer>
          </Stack>
        </ClaimDetailsSection>
      </Paper>
    </Box>
  );
}

const AttachmentContainer = styled.div`
  margin-bottom: 1rem;
  section:first-of-type {
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
  }
  section:last-of-type {
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    border-bottom: 1px solid lightgray;
  }

  section {
    width: 100%;
    padding: 1rem;
    border-left: 1px solid lightgray;
    border-right: 1px solid lightgray;
    border-top: 1px solid lightgray;
    display: flex;
    justify-content: space-between;

    div {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 1rem;
    }
    img {
      width: 2rem;
      height: 2rem;
    }
    p {
      text-align: left;
    }
  }
`;
