import { Drivers, Storage } from "@ionic/storage";
import CordovaSQLiteDriver from "localforage-cordovasqlitedriver";

const store = new Storage({
  driverOrder: [
    CordovaSQLiteDriver._driver,
    Drivers.IndexedDB,
    Drivers.LocalStorage,
  ],
});
let initializedStorage = false;
const initialize = async () => {
  await store.defineDriver(CordovaSQLiteDriver);
  await store.create();
  initializedStorage = true;
};

initialize();

export { store, initializedStorage };
