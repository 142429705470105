import styled from "@emotion/styled";
import { Autocomplete, Stack, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { FC, ReactNode, useState } from "react";
import { useDebounceEffect } from "ahooks";
import PlaceIcon from "@mui/icons-material/Place";
import { IconImgExtraLarge } from "./styled";
import { axiosClient } from "../../../../lib/axiosClient.ts";
import {
  MapboxGeocoding,
  MapboxGeocodingEndpoint,
  queryMapboxGeocodingApi,
  IsoCountryAlpha2,
} from "@athena/services";

const MAPBOX_TOKEN =
  "pk.eyJ1Ijoiamx5dGhpbmYiLCJhIjoiY2xsZnl3ZTZmMG9zMDNkcWF5bHI4Nm1keCJ9.lOgnaH1KPfT_tQVhRppcYQ";

export interface Searchable {
  title: string;
  subtitle: string;
  icon: ReactNode;
  fields: Array<string>;
  position: [number, number];
}

type SearchBoxProps = {
  searchables?: Array<Searchable>;
  onSearch: (value: SearchSuggestion | undefined) => void;
  containerElement: HTMLElement | null;
};

enum SearchSuggestionType {
  Claim = "claim",
  Geocoding = "geocoding",
}

const SearchSuggestionTypeLabels: Record<SearchSuggestionType, string> = {
  [SearchSuggestionType.Claim]: "Claims",
  [SearchSuggestionType.Geocoding]: "Addresses",
};

export interface SearchSuggestion {
  type: SearchSuggestionType;
  title: string;
  subtitle: string;
  position: [number, number];
  icon: ReactNode;
}

export const cleanForSearch = (input: string) =>
  input.toLowerCase().replace(/[^a-z0-9\s]/g, "");

const HighlightedSearchTerm: FC<{ searchTerm: string; text: string }> = ({
  searchTerm,
  text,
}) => {
  const terms = searchTerm.split(" ");
  let restOfText = text;
  const elements: Array<ReactNode> = [];
  for (const term of terms) {
    const index = restOfText.toLowerCase().indexOf(term);
    if (index === -1) {
      continue;
    }
    const before = restOfText.substring(0, index);
    const matched = restOfText.substring(index, index + term.length);
    restOfText = restOfText.substring(index + term.length);
    elements.push(<span key={`${before}-${elements.length}`}>{before}</span>);
    elements.push(<b key={`${matched}-${elements.length}`}>{matched}</b>);
  }
  if (restOfText.length > 0) {
    elements.push(
      <span key={`${restOfText}-${elements.length}`}>{restOfText}</span>
    );
  }

  return <>{elements}</>;
};

export const SearchBox = ({
  searchables,
  onSearch,
  containerElement,
}: SearchBoxProps) => {
  const [inputValue, setInputValue] = useState<string>("");
  const [claimSuggestions, setClaimSuggestions] = useState<
    Array<SearchSuggestion>
  >([]);

  const [geocodingSuggestions, setGeocodingSuggestions] = useState<
    Array<SearchSuggestion>
  >([]);

  const searchTerm = cleanForSearch(inputValue);

  useDebounceEffect(
    () => {
      const found =
        searchables?.filter((x) =>
          x.fields.some((f) => cleanForSearch(f).includes(searchTerm))
        ) || [];

      setClaimSuggestions(
        found.map((f) => ({
          type: SearchSuggestionType.Claim,
          title: f.title,
          subtitle: f.subtitle,
          position: f.position,
          icon: f.icon,
        }))
      );
    },
    [searchTerm],
    { wait: 333 }
  );

  useDebounceEffect(
    () => {
      const geocode = async () => {
        if (!inputValue) {
          setGeocodingSuggestions([]);
          return;
        }
        const geocodingData = await queryMapboxGeocodingApi({
          country: IsoCountryAlpha2.NewZealand,
          types: [
            MapboxGeocoding.Type.Region,
            MapboxGeocoding.Type.Postcode,
            MapboxGeocoding.Type.District,
            MapboxGeocoding.Type.Place,
            MapboxGeocoding.Type.Locality,
            MapboxGeocoding.Type.Neighborhood,
            MapboxGeocoding.Type.Address,
          ],
          search: inputValue,
          accessToken: MAPBOX_TOKEN,
          axiosClient: axiosClient,
          endpoint: MapboxGeocodingEndpoint.Temporary,
        });

        setGeocodingSuggestions(
          geocodingData.features.map((f) => ({
            type: SearchSuggestionType.Geocoding,
            title: f.text,
            subtitle: f.place_name,
            position: f.geometry.coordinates,
            icon: <PlaceIcon sx={{ color: "text.secondary" }} />,
          }))
        );
      };
      geocode().catch((e) => console.error(e));
    },
    [inputValue],
    { wait: 666 }
  );
  return (
    <SearchContainer>
      <SearchBackground
        sx={{
          bgcolor: "background.paper",
        }}
      >
        <IconImgExtraLarge src="/images/mapping/search.svg" />
        <Autocomplete
          sx={{
            width: "100%",
            "& fieldset": {
              border: "none",
            },
          }}
          componentsProps={{
            popper: {
              container: containerElement,
            },
          }}
          getOptionLabel={(option) =>
            (typeof option === "object" && option.subtitle) || ""
          }
          filterOptions={(x) => x}
          options={[...claimSuggestions, ...geocodingSuggestions]}
          groupBy={(o) => o.type}
          renderGroup={(params) => {
            return (
              <Stack key={params.key}>
                <h5 style={{ padding: "0.5rem 1rem" }}>
                  {
                    SearchSuggestionTypeLabels[
                      params.group as SearchSuggestionType
                    ]
                  }
                </h5>
                <ul style={{ padding: 0 }}>{params.children}</ul>
              </Stack>
            );
          }}
          autoComplete
          freeSolo
          includeInputInList
          filterSelectedOptions
          noOptionsText="No locations"
          onChange={(event, value) => {
            if (value && typeof value === "object") {
              onSearch(value);
            } else {
              onSearch(undefined);
            }
          }}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder={"Search"}
              sx={{ border: "none" }}
              fullWidth
            />
          )}
          renderOption={(props, option, { index }) => {
            return (
              <li
                {...props}
                key={`${option.title}${option.subtitle}${index}`}
                style={{ paddingLeft: 0, paddingRight: 0 }}
              >
                <Stack direction={"row"} padding={0} flexWrap={"nowrap"}>
                  <Stack
                    direction={"column"}
                    justifyContent={"start"}
                    paddingTop={"3px"}
                  >
                    <SearchIconContainer>{option.icon}</SearchIconContainer>
                  </Stack>

                  <Stack
                    direction={"column"}
                    sx={{
                      width: "calc(100% - 44px)",
                      wordWrap: "break-word",
                    }}
                  >
                    <Box component="p" sx={{ fontWeight: "semibold" }}>
                      <HighlightedSearchTerm
                        searchTerm={searchTerm}
                        text={option.title}
                      />
                    </Box>
                    <Typography variant="body2" color="text.secondary">
                      <HighlightedSearchTerm
                        searchTerm={searchTerm}
                        text={option.subtitle}
                      />
                    </Typography>
                  </Stack>
                </Stack>
              </li>
            );
          }}
        />
      </SearchBackground>
    </SearchContainer>
  );
};

const SearchIconContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 44px;
  justify-content: center;
  padding-left: 22px;
  & svg {
    height: 1.25rem;
  }
`;

const SearchContainer = styled.div`
  position: absolute;
  left: 5rem;
  top: 1rem;
  z-index: 20;
  display: flex;
  flex-direction: row;
  gap: 1rem;
  @media (max-width: 800px) {
    left: 1rem;
  }
`;

const SearchBackground = styled(Box)`
  position: relative;
  right: 0;
  top: 0;
  width: 24rem;
  height: 3.5rem;
  padding: 0 1rem;
  border-radius: 16px;
  border: 2px solid lightgray;
  display: flex;
  align-items: center;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
`;
