import { variables } from "@athena/server/src/api/types/templateVariables";
import styled from "@emotion/styled";
import SearchIcon from "@mui/icons-material/Search";
import { Chip, Divider, InputAdornment, Stack, TextField } from "@mui/material";
import { useState } from "react";
type TemplateVariablesProps = {
  usageCounts?: { [key: string]: number };
  onVariableClick: (
    value: string,
    label: string,
    description: string,
    hasConfig: boolean
  ) => void;
};

export const TemplateVariables = (props: TemplateVariablesProps) => {
  const { usageCounts, onVariableClick } = props;
  const [search, setSearch] = useState("");
  return (
    <TemplateVariablesContainer>
      <TextField
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        value={search}
        placeholder="Search..."
        onChange={(e) => setSearch(e.target.value)}
      />
      <VariablesContainer>
        <Stack divider={<Divider />}>
          {variables
            .filter((variable) =>
              variable.label.toLowerCase().includes(search.toLowerCase())
            )
            .sort((a, b) => a.label.localeCompare(b.label))
            .map((variable, i) => {
              const usageCount = usageCounts
                ? usageCounts[variable.value] || 0
                : 0;
              return (
                <VariableContainer
                  key={`${variable.value}-${i}`}
                  onClick={() => {
                    onVariableClick(
                      variable.value,
                      variable.label,
                      variable.description || "",
                      variable.hasConfig || false
                    );
                  }}
                >
                  <Variable>
                    <VariableName>{variable.label}</VariableName>
                    {!!usageCounts && (
                      <Chip
                        label={usageCount}
                        size="small"
                        sx={{ backgroundColor: "#E9F7FF" }}
                      />
                    )}
                  </Variable>
                </VariableContainer>
              );
            })}
        </Stack>
      </VariablesContainer>
    </TemplateVariablesContainer>
  );
};
const VariableName = styled.div`
  font-size: 11px;
  display: flex;
  align-items: center;
  color: #207ae3;
`;
const Variable = styled.div`
  display: flex;
  justify-content: space-between;
  flex: 1;
`;
const TemplateVariablesContainer = styled.div`
  & > :not(:last-child) {
    margin-right: 1rem;
    margin-bottom: 1rem;
  }
`;

const VariablesContainer = styled.div`
  max-height: 500px;
  overflow: auto;
`;

const VariableContainer = styled.div`
  display: inline-flex;
`;
