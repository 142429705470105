import { z } from "zod";

export const CreateInsurancePolicySchema = z.object({
  policyReference: z.string(),
  status: z.string(),
  address: z.string(),
  latitude: z.number({ coerce: true}).optional(),
  longitude: z.number({ coerce: true}).optional(),
  additionalData: z.record(z.string(), z.string()),
});

export type CreateInsurancePolicy = z.infer<typeof CreateInsurancePolicySchema>;

export const GetInsurancePolicySchema = z.object({
  policyReference: z.string(),
  created: z.date({ coerce: true }),
  updated: z.date({ coerce: true }),
  status: z.string(),
  additionalData: z.record(z.string(), z.string()),
  location: z.object({
    geo: z.object({
      type: z.literal("Point"),
      coordinates: z.tuple([z.number(), z.number()]),
    }),
    originalAddress: z.string(),
    address: z.string(),
  }),
  issues: z.object({
    dataMismatch: z.object({
      status: z.string().optional(),
      address: z.string().optional(),
      latitude: z.number({ coerce: true}).optional(),
      longitude: z.number({ coerce: true}).optional(),
    }).optional(),
  }).optional(),
});

export type GetInsurancePolicy = z.infer<typeof GetInsurancePolicySchema>;

export const UpdateInsurancePolicySchema = z.object({
  policyReference: z.string(),
  status: z.string().optional(),
  address: z.string().optional(),
  latitude: z.number({ coerce: true}).optional(),
  longitude: z.number({ coerce: true}).optional(),
});

export type UpdateInsurancePolicy = z.infer<typeof GetInsurancePolicySchema>;
