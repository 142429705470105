import { Button, Paper, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { InlineUser } from "src/shared/user/InlineUser";

type TrackedChangeBoxProps = {
  insertTrackedChange: {
    username: string;
    userid: string;
    created: string;
    id: string;
  };
  onAccept: VoidFunction;
  onReject: VoidFunction;
  deleteTrackedChange?: {
    userid: string;
    username: string;
    created: string;
    id: string;
  };
};

export const TrackedChangeBox = (props: TrackedChangeBoxProps) => {
  const { onAccept, onReject, deleteTrackedChange, insertTrackedChange } =
    props;
  if (!deleteTrackedChange?.id && !insertTrackedChange?.id) return null;
  return (
    <Paper sx={{ padding: 4 }}>
      <Stack>
        <InlineUser
          name={
            deleteTrackedChange?.username || insertTrackedChange?.username || ""
          }
        />
        <Typography variant="subtitle1">
          <>
            <strong>{deleteTrackedChange ? "Deleted" : "Added"}</strong>
            {deleteTrackedChange
              ? ` "${
                  document.querySelectorAll(
                    `[changeid="${deleteTrackedChange?.id}"]`
                  )[0]?.innerHTML
                }"`.replace(/(<([^>]+)>)/gi, "")
              : ` "${
                  document.querySelectorAll(
                    `[changeid="${insertTrackedChange?.id}"]`
                  )[0]?.innerHTML
                }"`.replace(/(<([^>]+)>)/gi, "")}
          </>
        </Typography>
        <Stack direction={"row"}>
          <Button size="small" onClick={onAccept}>
            Accept
          </Button>{" "}
          <Button size="small" onClick={onReject}>
            Reject
          </Button>
        </Stack>
      </Stack>
    </Paper>
  );
};
