import { useMemo } from "react";
import VectorSource from "ol/source/Vector";
import GeoJSON from "ol/format/GeoJSON";
import { config } from "src/lib/config";
import { useOfflineClaim } from "../../useOfflineClaim";
import { useClaimId } from "../../hooks/useClaimId";
import { useFormContext } from "react-hook-form";
import { UpdateClaim } from "@athena/server/src/api/types/claimAssessment";
import { calcBbox } from "src/lib/offlineMaps";
import { Point } from "ol/geom";
import { RiskTypes } from "../Config";
import { useOfflineLayer, offlineLayer } from "./useOfflineLayer";
import { useNetworkContext } from "src/shared/hooks/useNetworkContext";

export const useLoadSummarySources = (
  landslipDamageSourceIds: string[],
  stormFloodDamageSourceId: string
) => {
  const claimId = useClaimId();
  const { online } = useNetworkContext();
  const landslipSources = landslipDamageSourceIds.map((landslipId) => {
    const headscarpUrl = `${config.gisApiUrl}/geo/ath/features/collections/headscarp_line_v1/items?filter=claim_id="${claimId}" AND damage_source_id = "${landslipId}"`;
    const evacuatedUrl = `${config.gisApiUrl}/geo/ath/features/collections/evacuated_land_area_v1/items?filter=claim_id="${claimId}" AND damage_source_id = "${landslipId}"`;
    const inundatedUrl = `${config.gisApiUrl}/geo/ath/features/collections/inundated_land_area_v1/items?filter=claim_id="${claimId}" AND damage_source_id = "${landslipId}"`;
    const tensionUrl = `${config.gisApiUrl}/geo/ath/features/collections/tension_crack_line_v1/items?filter=claim_id="${claimId}" AND damage_source_id = "${landslipId}"`;
    const imminentRiskEvacUrl = `${config.gisApiUrl}/geo/ath/features/collections/imminent_risk_area_v1/items?filter=claim_id="${claimId}" AND risk_type="${RiskTypes.Evacuation}" AND damage_source_id = "${landslipId}"`;
    const imminentRiskInundationUrl = `${config.gisApiUrl}/geo/ath/features/collections/imminent_risk_area_v1/items?filter=claim_id="${claimId}" AND risk_type="${RiskTypes.Inundation}" AND damage_source_id = "${landslipId}"`;
    const imminentRiskInundationInsuredUrl = `${config.gisApiUrl}/geo/ath/features/collections/imminent_risk_area_v1/items?filter=claim_id="${claimId}" AND risk_type="${RiskTypes.Inundation}" AND insured_state IN ("8m", "60m") AND damage_source_id = "${landslipId}"`;
    const imminentRiskReInundationUrl = `${config.gisApiUrl}/geo/ath/features/collections/imminent_risk_area_v1/items?filter=claim_id="${claimId}" AND risk_type="${RiskTypes.ReInundation}" AND damage_source_id = "${landslipId}"`;
    return {
      landslipId,
      headscarp: offlineLayer(headscarpUrl, online, claimId),
      evacuated: offlineLayer(evacuatedUrl, online, claimId),
      inundated: offlineLayer(inundatedUrl, online, claimId),
      tension: offlineLayer(tensionUrl, online, claimId),
      imminentRiskEvacuation: offlineLayer(
        imminentRiskEvacUrl,
        online,
        claimId
      ),
      imminentRiskInundation: offlineLayer(
        imminentRiskInundationUrl,
        online,
        claimId
      ),
      imminentRiskInundationInsured: offlineLayer(
        imminentRiskInundationInsuredUrl,
        online,
        claimId
      ),
      imminentRiskReInundation: offlineLayer(
        imminentRiskReInundationUrl,
        online,
        claimId
      ),
    };
  });

  const polyUrl = `${config.gisApiUrl}/geo/ath/features/collections/bridge_culvert_area_v1/items?filter=claim_id="${claimId}"`;
  const lineUrl = `${config.gisApiUrl}/geo/ath/features/collections/bridge_culvert_line_v1/items?filter=claim_id="${claimId}"`;
  const pointUrl = `${config.gisApiUrl}/geo/ath/features/collections/bridge_culvert_point_v1/items?filter=claim_id="${claimId}"`;
  const dwellingPointUrl = `${config.gisApiUrl}/geo/ath/features/collections/structural_damage_point_v1/items?filter=claim_id="${claimId}" AND structure_class="Dwelling"`;
  const dwellingPolyUrl = `${config.gisApiUrl}/geo/ath/features/collections/structural_damage_area_v1/items?filter=claim_id="${claimId}" AND structure_class="Dwelling"`;
  const dwellingLineUrl = `${config.gisApiUrl}/geo/ath/features/collections/structural_damage_line_v1/items?filter=claim_id="${claimId}" AND structure_class="Dwelling"`;
  const appurtenantPointUrl = `${config.gisApiUrl}/geo/ath/features/collections/structural_damage_point_v1/items?filter=claim_id="${claimId}" AND structure_class="Appurtenant"`;
  const appurtenantPolyUrl = `${config.gisApiUrl}/geo/ath/features/collections/structural_damage_area_v1/items?filter=claim_id="${claimId}" AND structure_class="Appurtenant"`;
  const appurtenantLineUrl = `${config.gisApiUrl}/geo/ath/features/collections/structural_damage_line_v1/items?filter=claim_id="${claimId}" AND structure_class="Appurtenant"`;
  const conceptualPolyUrl = `${config.gisApiUrl}/geo/ath/features/collections/proposed_remediation_area_v1/items?filter=claim_id="${claimId}"`;
  const conceptualLineUrl = `${config.gisApiUrl}/geo/ath/features/collections/proposed_remediation_line_v1/items?filter=claim_id="${claimId}"`;
  const generalObservationPolyUrl = `${config.gisApiUrl}/geo/ath/features/collections/observation_area_v1/items?filter=claim_id="${claimId}"`;
  const generalObservationLineUrl = `${config.gisApiUrl}/geo/ath/features/collections/observation_line_v1/items?filter=claim_id="${claimId}"`;
  const generalObservationPointUrl = `${config.gisApiUrl}/geo/ath/features/collections/observation_point_v1/items?filter=claim_id="${claimId}"`;
  const historicHeadscarpUrl = `${config.gisApiUrl}/geo/ath/features/collections/historic_headscarp_line_v1/items?filter=claim_id="${claimId}"`;
  const crossSectionUrl = `${config.gisApiUrl}/geo/ath/features/collections/cross_section_line_v1/items?filter=claim_id="${claimId}"`;
  const mainDwellingUrl = `${config.gisApiUrl}/geo/ath/features/collections/primary_dwelling_area_v1/items?filter=claim_id="${claimId}"`;
  const accessWayUrl = `${config.gisApiUrl}/geo/ath/features/collections/accessway_area_v1/items?filter=claim_id="${claimId}"`;
  const appurtenantStructurePointUrl = `${config.gisApiUrl}/geo/ath/features/collections/appurtenant_structure_point_v1/items?filter=claim_id="${claimId}"`;
  const appurtenantStructurePolyUrl = `${config.gisApiUrl}/geo/ath/features/collections/appurtenant_structure_area_v1/items?filter=claim_id="${claimId}"`;
  const propertyBoundaryUrl = `${config.gisApiUrl}/geo/ath/features/collections/exclusive_use_area_v1/items?filter=claim_id="${claimId}"`;
  const insuredAreaUrl = `${config.gisApiUrl}/geo/ath/features/collections/insured_land_area_v1/items?filter=claim_id="${claimId}"`;

  const retainingWallUrl = `${config.gisApiUrl}/geo/ath/features/collections/retaining_wall_line_v1/items?filter=claim_id="${claimId}"`;
  const servicesLineUrl = `${config.gisApiUrl}/geo/ath/features/collections/damaged_service_line_v1/items?filter=claim_id="${claimId}"`;
  const servicesPointUrl = `${config.gisApiUrl}/geo/ath/features/collections/damaged_service_point_v1/items?filter=claim_id="${claimId}"`;

  const stormFloodEvacuated = useOfflineLayer(
    `${config.gisApiUrl}/geo/ath/features/collections/evacuated_land_area_v1/items?filter=claim_id="${claimId}" AND damage_source_id="${stormFloodDamageSourceId}"`
  );
  const stormFloodInundated = useOfflineLayer(
    `${config.gisApiUrl}/geo/ath/features/collections/inundated_land_area_v1/items?filter=claim_id="${claimId}" AND damage_source_id="${stormFloodDamageSourceId}"`
  );

  const mainDwelling = useOfflineLayer(mainDwellingUrl);
  const accessWay = useOfflineLayer(accessWayUrl);
  const appurtenantStructurePoint = useOfflineLayer(
    appurtenantStructurePointUrl
  );
  const appurtenantStructurePoly = useOfflineLayer(appurtenantStructurePolyUrl);
  const propertyBoundary = useOfflineLayer(propertyBoundaryUrl);
  const insuredArea = useOfflineLayer(insuredAreaUrl);
  const historicHeadscarp = useOfflineLayer(historicHeadscarpUrl);
  const bridgePoly = useOfflineLayer(polyUrl);
  const bridgeLine = useOfflineLayer(lineUrl);
  const bridgePoint = useOfflineLayer(pointUrl);
  const retainingWall = useOfflineLayer(retainingWallUrl);
  const servicesLine = useOfflineLayer(servicesLineUrl);
  const servicesPoint = useOfflineLayer(servicesPointUrl);
  const crossSection = useOfflineLayer(crossSectionUrl);
  const dwellingPoint = useOfflineLayer(dwellingPointUrl);
  const dwellingPoly = useOfflineLayer(dwellingPolyUrl);
  const dwellingLine = useOfflineLayer(dwellingLineUrl);
  const appurtenantPoint = useOfflineLayer(appurtenantPointUrl);
  const appurtenantPoly = useOfflineLayer(appurtenantPolyUrl);
  const appurtenantLine = useOfflineLayer(appurtenantLineUrl);
  const conceptualPoly = useOfflineLayer(conceptualPolyUrl);
  const conceptualLine = useOfflineLayer(conceptualLineUrl);
  const generalObservationPoly = useOfflineLayer(generalObservationPolyUrl);
  const generalObservationLine = useOfflineLayer(generalObservationLineUrl);
  const generalObservationPoint = useOfflineLayer(generalObservationPointUrl);

  return {
    mainDwellingSource: mainDwelling,
    accessWaySource: accessWay,
    appurtenantStructurePointSource: appurtenantStructurePoint,
    appurtenantStructurePolySource: appurtenantStructurePoly,
    propertyBoundarySource: propertyBoundary,
    insuredAreaSource: insuredArea,
    bridgePoly: bridgePoly,
    bridgeLine: bridgeLine,
    bridgePoint: bridgePoint,
    stormFloodEvacuated,
    stormFloodInundated,
    dwellingPoint,
    dwellingPoly,
    dwellingLine,
    appurtenantPoint,
    appurtenantPoly,
    appurtenantLine,
    conceptualPoly,
    conceptualLine,
    generalObservationPoly,
    generalObservationLine,
    generalObservationPoint,
    retainingWall,
    servicesLine,
    servicesPoint,
    crossSection,
    historicHeadscarp,
    landslipSources,
  };
};
