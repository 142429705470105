import {
  Dialog,
  DialogTitle,
  Button,
  DialogContent,
  Typography,
  Popover,
  Paper,
} from "@mui/material";
import { Ref } from "react";

type NavigateToTabProps = {
  onClose: VoidFunction;
  propertyLabel: string;
  propertyValue: string;
  claimId: string;
  tabName: string;
  anchorEl: Element;
};

export const NavigateToTab = (props: NavigateToTabProps) => {
  const { onClose, propertyLabel, propertyValue, claimId, tabName, anchorEl } =
    props;
  return (
    <Popover
      open
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      disableRestoreFocus
    >
      <Paper sx={{ maxWidth: 300, padding: "1rem" }} elevation={1}>
        <Typography>
          To edit {propertyLabel}, go to the{" "}
          <a
            href={`/claim/${claimId}/${tabName}?reportRedirectElId=${propertyValue}`}
          >
            claim {tabName} tab
          </a>{" "}
          and update the appropriate field.
        </Typography>
      </Paper>
    </Popover>
  );
};
