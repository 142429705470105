// UTILITIES
export const MeasureLayer = "measure-layer";

// LANDSLIP
export const HeadscarpLayer = "headscarp";
export const EvacuatedLandLayer = "evacuated-land";
export const InundatedLandLayer = "inundated-land";
export const ImminentRiskOfEvacuationLayer = "imminent-risk-of-evacuation";
export const ImminentRiskOfInundationLayer = "imminent-risk-of-inundation";
export const ImminentRiskOfNewInundationLayer =
  "imminent-risk-of-new-inundation";
export const TensionCracksLayer = "tension-cracks";
export const RetainingWallLayer = "retaining-wall";

export const StormFloodEvacuatedLandLayer = "storm-evacuated-land";
export const StormFloodInundatedLandLayer = "storm-inundated-land";

// BUILDING DAMAGE
export const DwellingDamagePolyLayer = "dwelling-damage-poly";
export const DwellingDamagePointLayer = "dwelling-damage-point";
export const DwellingDamageLineLayer = "dwelling-damage-line";
export const AppurtenantDamagePolyLayer = "appurtenant-damage-poly";
export const AppurtenantDamagePointLayer = "appurtenant-damage-point";
export const AppurtenantDamageLineLayer = "appurtenant-damage-line";

// Services Damage
export const ServiceDamageLineLayer = "service-damage-line";
export const ServiceDamagePointLayer = "service-damage-point";

// Bridges and Culverts
export const BridgeAndCulvertPolyLayer = "bridge-culvert-poly";
export const BridgeAndCulvertLineLayer = "bridge-culvert-line";
export const BridgeAndCulvertPointLayer = "bridge-culvert-point";

// SITE DETAILS
export const MainDwellingLayer = "main-dwelling";
export const InsuredLayer = "insured-area";
export const AccesswayLayer = "accessway";
export const AppurtenantStructurePointLayer = "appurtenant-structure-point";
export const AppurtenantStructurePolyLayer = "appurtenant-structure-poly";
export const PropertyBoundaryLayer = "property-boundary";
export const InsuredBufferLayer = "insured-buffer-layer";

// CROSS SECTION
export const CrossSectionLineLayer = "cross-section-line";

// CONCEPTUAL REMEDIAL SOLUTION
export const ConceptualRemedialSolutionPolyLayer =
  "conceptual-remedial-solution-poly";
export const ConceptualRemedialSolutionLineLayer =
  "conceptual-remedial-solution-line";
export const ConceptualRemedialSolutionPointLayer =
  "conceptual-remedial-solution-point";

// OBSERVATIONS
export const GeneralObservationsPolyLayer =
  "general-observations-solution-poly";
export const GeneralObservationsLineLayer =
  "general-observations-solution-line";
export const GeneralObservationsPointLayer =
  "general-observations-solution-point";
export const GeneralObservationsHistoricHeadScarpLineLayer =
  "general-observations-historic-headscarp-line";

export const ParcelLayer = "parcel";

export * from "./styles";

export enum RiskTypes {
  "Evacuation" = "Evacuation",
  "Inundation" = "Inundation",
  "ReInundation" = "Re-Inundation",
}

export interface AssociatedLayers {
  name: string;
  type: layerType;
}

export type layerType = "layer" | "layerGroup";

export const AssociatedOtherLandslipLayers: AssociatedLayers[] = [
  {
    name: HeadscarpLayer + "_alt",
    type: "layer",
  },
  {
    name: EvacuatedLandLayer + "_alt",
    type: "layer",
  },
  {
    name: InundatedLandLayer + "_alt",
    type: "layer",
  },
  {
    name: ImminentRiskOfEvacuationLayer + "_alt",
    type: "layer",
  },
  {
    name: ImminentRiskOfInundationLayer + "_alt",
    type: "layer",
  },
  {
    name: ImminentRiskOfNewInundationLayer + "_alt",
    type: "layer",
  },
  {
    name: TensionCracksLayer + "_alt",
    type: "layer",
  },
];

export const SummaryLayerGroup = "summary";
export const SurroundingClaimsLayerGroup = "surrounding-claims";
export const SiteDetailsLayerGroup = "site-details";
export const AppurtenantStructureLayerGroup = "appurtenant-structure";
export const LandslipLayerGroup = "landslip-group";
export const EvacuatedLandGroup = "evacuated-land-group";
export const InundatedLandGroup = "inundated-land-group";
export const ImminentRiskOfEvacuationGroup = "ImminentRiskOfEvacuationGroup";
export const ImminentRiskOfInundationGroup = "ImminentRiskOfInundationGroup";
export const BridgeGroup = "BridgeGroup";
export const DwellingGroup = "DwellingGroup";
export const AppurtenantGroup = "AppurtenantGroup";
export const ConceptualGroup = "ConceptualGroup";
export const GeneralObservationGroup = "GeneralObservationGroup";
export const RetainingWallGroup = "RetainingWallGroup";
export const ServiceGroup = "ServiceGroup";
export const CrossSectionGroup = "CrossSectionGroup";
export const StormGroup = "StormGroup";

export const PinLayer = "PinLayer";
