import { Status } from "@athena/server/src/api/types/claimStatuses";
import { Urgency } from "@athena/server/src/api/types/claim";
import { AthenaLayerProps } from "src/modules/spatialPortal/mapLib/types";
import { GeoJsonCollectionName } from "src/modules/spatialPortal/mapLib/api/constants";
import { renderToString } from "react-dom/server";
import {
  InferFeatureCollectionType,
  InferFeatureType,
} from "src/modules/spatialPortal/mapLib/api/types";

import {
  GeoCollectionLayerProps,
  GeoJsonCollectionLayer,
  GeoJsonCollectionPickingInfo,
} from "src/modules/spatialPortal/mapLib/layers/GeoJsonCollectionLayer";
import { ClaimPopup } from "./ClaimPopup";
import { DiamondIcon, PinIcon, CircleIcon } from "src/lib/icons";
import Color from "color";
import { filterClaims } from "../../ClaimsMap/functions/filterClaims";
import { emptyClaimFilters } from "../../ClaimsMap/components/ClaimFilterModal";
import { statusColorMap } from "src/shared/statusColorMap";
import {
  SearchSuggestion,
  cleanForSearch,
} from "src/modules/spatialPortal/mapLib/components/SearchBox";
import { deckGlColorFromHex } from "src/modules/spatialPortal/mapLib/functions/deckGlColor";
import { OrgType } from "@athena/server/src/trpc/routers/user/types";
interface ClaimGroupLayerProps
  extends Omit<
    AthenaLayerProps<
      GeoJsonCollectionPickingInfo<GeoJsonCollectionName.ClaimPointV1>
    >,
    "menu"
  > {
  data:
    | InferFeatureCollectionType<GeoJsonCollectionName.ClaimPointV1>
    | undefined;
  filter: {
    status: Status;
    urgency: Urgency;
  };
  searchResult: SearchSuggestion | undefined;
  orgType: OrgType;
}

const NORMAL_STROKE_DARKEN_AMOUNT = 0.6;
const HIGHLIGHTED_STROKE_LIGHTEN_AMOUNT = 0.8;
const NORMAL_CLAIM_SIZE = 30;
const NORMAL_CLAIM_SIZE_FOR_INSURER = 15;
const SELECTED_CLAIM_SIZE = 35;

export const getClaimIconComponent = (
  status: Status,
  urgency: Urgency,
  orgType: OrgType
) => {
  const fillColor = statusColorMap[status];
  const strokeColor = new Color(fillColor, "hex")
    .darken(NORMAL_STROKE_DARKEN_AMOUNT)
    .toString();

  const selectedColor = new Color(fillColor, "hex")
    .lighten(HIGHLIGHTED_STROKE_LIGHTEN_AMOUNT)
    .toString();

  const normalProps = {
    fill: fillColor,
    stroke: strokeColor,
  };

  const selectedProps = {
    fill: fillColor,
    stroke: selectedColor,
  };

  const normalIcon = orgType === OrgType.Engineering ? PinIcon : CircleIcon;

  const IconComponent = urgency === Urgency.Urgent ? DiamondIcon : normalIcon;

  return {
    normal: <IconComponent {...normalProps} />,
    highlighted: <IconComponent {...selectedProps} />,
  };
};

const getIcon = (icon: JSX.Element, orgType: OrgType) => {
  const url = `data:image/svg+xml;base64,${btoa(renderToString(icon))}`;

  if (orgType === OrgType.Engineering) {
    return {
      url,
      width: 48,
      height: 48,
      anchorY: 48,
    };
  } else {
    return {
      url,
      width: 48,
      height: 48,
      anchorY: 24,
    };
  }
};

export class ClaimGroupLayer extends GeoJsonCollectionLayer<GeoJsonCollectionName.ClaimPointV1> {
  static layerName = "ClaimGroupLayer";

  constructor(props: ClaimGroupLayerProps) {
    const data = filterClaims({
      claims: props.data,
      filter: {
        ...emptyClaimFilters,
        statuses: { [props.filter.status]: true },
        urgencies: [props.filter.urgency],
      },
    });

    const icons = getClaimIconComponent(
      props.filter.status,
      props.filter.urgency,
      props.orgType
    );

    const renderedHighlightedIcon = getIcon(icons.highlighted, props.orgType);
    const renderedNormalIcon = getIcon(icons.normal, props.orgType);

    const displayName = `${props.filter.status}${
      props.filter.urgency === Urgency.Urgent ? " - Urgent" : ""
    }`;

    const addressFromSearch =
      (props.searchResult && cleanForSearch(props.searchResult.subtitle)) ||
      undefined;

    const propsToPass: GeoCollectionLayerProps<GeoJsonCollectionName.ClaimPointV1> =
      {
        ...props,
        menu: {
          displayName,
          checkBoxIcon: () => icons.normal,
        },
        data: data || { features: [], type: "FeatureCollection" },
        pickable: true,
        pointType: "icon",
        getIcon: (
          feature: InferFeatureType<GeoJsonCollectionName.ClaimPointV1>
        ) => {
          if (
            addressFromSearch &&
            feature?.properties.claim_address &&
            cleanForSearch(feature?.properties.claim_address).includes(
              addressFromSearch
            )
          ) {
            return renderedHighlightedIcon;
          } else {
            return renderedNormalIcon;
          }
        },
        getIconSize: (feature) => {
          const claimFeature =
            feature as InferFeatureType<GeoJsonCollectionName.ClaimPointV1>;
          if (
            addressFromSearch &&
            claimFeature?.properties.claim_address &&
            cleanForSearch(claimFeature?.properties.claim_address).includes(
              addressFromSearch
            )
          ) {
            return SELECTED_CLAIM_SIZE;
          } else {
            return props.orgType === OrgType.Engineering
              ? NORMAL_CLAIM_SIZE
              : claimFeature.properties.urgency === Urgency.Urgent
              ? NORMAL_CLAIM_SIZE_FOR_INSURER * 2
              : NORMAL_CLAIM_SIZE_FOR_INSURER;
          }
        },
        wrapLongitude: true,
        getText: (f: InferFeatureType<GeoJsonCollectionName.ClaimPointV1>) =>
          f.properties?.claim_name,
        getTextSize: 1,
        textSizeMaxPixels: 15,
        textSizeUnits: "meters",
        textBackground: true,
        getTextAnchor: "middle",
        getTextAlignmentBaseline: "bottom",
        textSizeScale: 1,
        popup: (pickInfo) => ({
          component: <ClaimPopup pickInfo={pickInfo} />,
          title: "Claim",
          key: `claim-${pickInfo.object.id}`,
        }),
      };

    super(propsToPass);
  }
}
