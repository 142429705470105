import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { formatDate, getStartOfWeek } from "src/lib/date";
import { FutureCapacityData } from "./ActiveAndOverdueClaimHealth";
import { ChartComingSoon } from "./ChartLoader";

export interface PastCompletionData {
  startedCount: number | null;
  finishedCount: number | null;
  date: Date;
}

interface Props {
  pastData: Array<PastCompletionData>;
  futureData: Array<FutureCapacityData>;
  startingWeekCapacity: number;
  isComingSoon?: boolean;
}

interface DataPoint {
  startedCount: number | null;
  finishedCount: number | null;
  capacity: number | null;
  label: string;
}

const STROKE_WIDTH = 3;

export const ClaimCompletionChart = ({
  pastData,
  futureData,
  startingWeekCapacity,
  isComingSoon,
}: Props) => {
  const data: Array<DataPoint> = [];
  const startOfTheWeek = getStartOfWeek(new Date());

  for (const pastDataPoint of pastData) {
    data.push({
      startedCount: pastDataPoint.startedCount,
      finishedCount: pastDataPoint.finishedCount,
      capacity: null,
      label: formatDate(pastDataPoint.date),
    });
  }

  for (const futureDataPoint of futureData) {
    data.push({
      startedCount: null,
      finishedCount: null,
      capacity: futureDataPoint.capacity,
      label: formatDate(futureDataPoint.date),
    });
  }

  //Attch the starting week capacity to the first data point
  data.forEach((dataPoint) => {
    if (dataPoint.label === formatDate(startOfTheWeek)) {
      dataPoint.capacity = startingWeekCapacity;
      return;
    }
  });

  return (
    <div style={{ width: "100%", marginTop: "1.5rem", position: "relative" }}>
      {isComingSoon && <ChartComingSoon />}
      <ResponsiveContainer width="100%" height={300}>
        <LineChart data={data}>
          <CartesianGrid horizontal={false} fill={"#EFEFEF"} />
          <XAxis dataKey={"label"} height={40} tickSize={0} tickMargin={15} />
          <YAxis tickSize={0} tickMargin={5} />
          <Tooltip />
          <Line
            type="linear"
            dataKey="startedCount"
            name="Started Claims"
            stroke="#A055FF"
            strokeWidth={STROKE_WIDTH}
            activeDot={{ r: 8 }}
          />
          <Line
            type="linear"
            dataKey="finishedCount"
            name="Finished Claims"
            stroke="#20DA0F"
            strokeWidth={STROKE_WIDTH}
          />
          <Line
            type="stepAfter"
            dataKey="capacity"
            name="Estimated Capacity"
            stroke="#8BB1DE"
            strokeWidth={STROKE_WIDTH}
            activeDot={{ r: 8 }}
          />
          <ReferenceLine
            x={formatDate(startOfTheWeek)}
            stroke={"#777777"}
            label={{ value: "Now", angle: -90, position: "center", dx: -10 }}
          />
          <Legend
            iconType="circle"
            align="right"
            wrapperStyle={{
              borderTop: "1px solid lightgray",
              paddingTop: "1rem",
            }}
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};
