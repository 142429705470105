import { ClaimDetailsSection, LoadingSpinner } from "@athena/components";
import { TRPCRootRouter } from "@athena/server/src/trpc";
import Timeline from "@mui/lab/Timeline";
import TimelineItem, { timelineItemClasses } from "@mui/lab/TimelineItem";
import { Paper, Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { inferRouterOutputs } from "@trpc/server";
import { useQuery } from "react-query";
import { axiosClient } from "src/lib/axiosClient";
import { getTimelineEventContent } from "src/modules/claims/ClaimReview";
import { ErrorBoundary } from "src/shared/ErrorBoundary";

interface Props {
  claim: inferRouterOutputs<TRPCRootRouter>["claims"]["getClaim"];
}

export const TimelineComponent = ({ claim }: Props) => {
  const { isLoading, data } = useQuery(
    `claimHistory${claim?.claimId}`,
    async () =>
      (
        await axiosClient.get(
          `/claims/${claim?.claimId}/${claim.organisationId}/history`
        )
      ).data,
    { enabled: !!claim?.claimId }
  );
  if (isLoading) return <LoadingSpinner />;
  if (!data) return null;

  return (
    <Timeline
      sx={{
        [`& .${timelineItemClasses.root}:before`]: {
          flex: 0,
          padding: 0,
        },
        [`& .MuiTimelineContent-root`]: {
          paddingRight: 0,
        },
        paddingRight: "0",
      }}
    >
      {isLoading && <LoadingSpinner />}
      {data?.map((event: any, i: number) => {
        return (
          <TimelineItem key={event.claimHistoryEventId}>
            {getTimelineEventContent(event, i + 1 != data.length, i != 0)}
          </TimelineItem>
        );
      })}
    </Timeline>
  );
};

export const TimelineFallback = () => {
  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <Typography variant="h5">
        Sorry, something went wrong loading this claims timeline.
      </Typography>
      <Typography>If the problem persists, please contact us.</Typography>
    </Box>
  );
};

export const TimelineView = ({ claim }: Props) => {
  return (
    <Paper
      elevation={4}
      sx={{
        padding: "1.5rem",
        mb: "1.5rem",
        scrollMarginTop: "5.5rem",
      }}
      id="timeline"
    >
      <ClaimDetailsSection title="Timeline">
        <Stack
          direction={"column"}
          sx={{
            maxWidth: "1000px",
            mb: 2,
            marginLeft: "-1.5rem",
          }}
        >
          <ErrorBoundary fallback={<TimelineFallback />}>
            <TimelineComponent claim={claim} />
          </ErrorBoundary>
        </Stack>
      </ClaimDetailsSection>
    </Paper>
  );
};
