import {
  GetPickingInfoParams,
  LayerExtension,
  PickingInfo,
} from "@deck.gl/core/typed";
import { AthenaLayerProps, PickingInfoWithPopups, PickingInfoWithTooltips } from "../types";
import { GeoJsonLayer } from "@deck.gl/layers/typed";

import { GeoJsonCollectionName } from "src/modules/spatialPortal/mapLib/api/constants";
import { GeoJsonLayerProps } from "@deck.gl/layers/typed/geojson-layer/geojson-layer";
import { getGeoJsonCollectionApiUrl } from "src/modules/spatialPortal/mapLib/api/utils";
import { InferFeatureCollectionType, InferFeatureType } from "../api/types";
import { GeoJsonCollectionFilter } from "../api/buildFilter";

/* @ts-ignore */
import { PathStyleExtension } from "@deck.gl/extensions";

interface DataSourceConfig<TCollection extends GeoJsonCollectionName> {
  collectionName: TCollection;
  filter?: GeoJsonCollectionFilter<TCollection>;
}

export type GeoCollectionLayerProps<TCollection extends GeoJsonCollectionName> =
  Omit<GeoJsonLayerProps, "data"> &
    AthenaLayerProps<GeoJsonCollectionPickingInfo<TCollection>> & {
      data:
        | DataSourceConfig<TCollection>
        | InferFeatureCollectionType<TCollection>
        | GeoJsonLayerProps["data"];
      getDashArray?: Array<number>;
      dashJustified?: boolean;
    };

export interface GeoJsonCollectionPickingInfoNotPicked extends PickingInfo {
  object: undefined;
  picked: false;
}

export interface GeoJsonCollectionPickingInfoPicked<
  TCollection extends GeoJsonCollectionName = GeoJsonCollectionName
> extends PickingInfo {
  object: InferFeatureType<TCollection>;
  picked: true;
}

export type GeoJsonCollectionPickingInfo<
  TCollection extends GeoJsonCollectionName = GeoJsonCollectionName
> =
  | GeoJsonCollectionPickingInfoNotPicked
  | GeoJsonCollectionPickingInfoPicked<TCollection>;

export class GeoJsonCollectionLayer<
  TCollection extends GeoJsonCollectionName
> extends GeoJsonLayer<GeoCollectionLayerProps<TCollection>> {
  static layerName = "GeoJsonCollectionLayer";

  constructor(props: GeoCollectionLayerProps<TCollection>) {
    const { id, data, visibility, popup } = props;
    let dataSource:
      | InferFeatureCollectionType<TCollection>
      | GeoJsonLayerProps["data"]
      | string;
    if (typeof data === "object" && "collectionName" in data) {
      const { collectionName, filter } = data;
      dataSource = getGeoJsonCollectionApiUrl({ collectionName, filter });
    } else {
      dataSource = data;
    }

    if (props.menu.defaultVisibility === undefined) {
      props.menu.defaultVisibility = true;
    }

    const visible = visibility[id];

    const propsToPass = {
      ...props,
      visible: visible !== undefined ? visible : props.menu.defaultVisibility,
      data: dataSource,
      loadOptions: {
        fetch: {
          credentials: "include",
        },
      },
      extensions: [
        new PathStyleExtension({
          dash: true,
          highPrecisionDash: true,
        }) as PathStyleExtension & LayerExtension<unknown>,
      ],
    };
    super(propsToPass);
  }

  getPickingInfo(
    params: GetPickingInfoParams
  ): GeoJsonCollectionPickingInfo<TCollection> {
    const { popup, tooltip } = this.props;
    const info = super.getPickingInfo(
      params
    ) as GeoJsonCollectionPickingInfo<TCollection>;
    // if (params.mode !== "query") {
    //   return info;
    // }

    const pickingInfo: GeoJsonCollectionPickingInfo<TCollection> = {
      ...info,
      sourceLayer: info.sourceLayer as GeoJsonCollectionLayer<TCollection>,
    };

    if (pickingInfo.picked && pickingInfo.object) {
      let extendedPickingInfo: GeoJsonCollectionPickingInfoPicked<TCollection> &
        PickingInfoWithPopups & PickingInfoWithTooltips = {
        ...pickingInfo,
      }
      if (popup) {
        const popupData = popup(pickingInfo);
        extendedPickingInfo = {
          ...pickingInfo,
          popups: [
            {
              component: popupData.component,
              title: popupData.title,
              key: popupData.key,
            },
          ],
        };
      }

      if (tooltip) {
        const tooltipData = tooltip(pickingInfo);
        extendedPickingInfo = {
          ...pickingInfo,
          tooltips: [
            {
              component: tooltipData.component,
            },
          ],
        };
      }

      return extendedPickingInfo;
    }
    
    return pickingInfo;
  }
}
