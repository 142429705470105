import { ReactNode } from "react";
import styled from "@emotion/styled";

import { Box, ButtonBase, ButtonBaseProps } from "@mui/material";

interface CornerDropdownProps {
  title: ReactNode;
  children: ReactNode;
  onClose?: () => void;
}

export const CornerDropdown = ({
  title,
  children,
  onClose,
}: CornerDropdownProps) => {
  return (
    <ContentDropdownPositioner>
      <ContentDropdownContainer
        sx={{
          bgcolor: "background.paper",
        }}
      >
        <CornerDropdownHeader>
          <CornerDropdownTitle>{title}</CornerDropdownTitle>
          <CrossButton onClick={onClose} />
        </CornerDropdownHeader>
        <CornerDropdownContent>{children}</CornerDropdownContent>
      </ContentDropdownContainer>
    </ContentDropdownPositioner>
  );
};

const CrossButton = (args: ButtonBaseProps) => (
  <ButtonBase {...args}>
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 17L17 1"
        stroke="#0067DF"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17 17L1 1"
        stroke="#0067DF"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </ButtonBase>
);

const ContentDropdownPositioner = styled.div`
  position: absolute;
  right: 1rem;
  padding: 0;
  top: 5rem;
  z-index: 20;
  display: flex;
  flex-direction: row;
  gap: 1rem;
  z-index: 1;
`;

const ContentDropdownContainer = styled(Box)`
  position: relative;
  min-width: 21rem;
  padding: 1rem 0 1rem 1rem;
  border-radius: 16px;
  height: fit-content;
  border: 2px solid lightgray;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  z-index: 1;
`;

const CornerDropdownHeader = styled.div`
  display: flex;
  padding-bottom: 1rem;
  padding-right: 1rem;
  align-items: center;

  .h4 {
    margin-bottom: 0;
  }
`;

const CornerDropdownContent = styled.div`
  overflow-y: auto;
  max-height: 60vh;
  padding-right: 1rem;
  padding-bottom: 0.25rem;
`;

const CornerDropdownTitle = styled.h4`
  flex: 1;
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 1px;
`;
