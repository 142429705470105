import { useState } from "react";

import { Menu } from "../components/Menu";
import { IconImgExtraLarge } from "../components/styled";
import { AthenaMapControl } from "../components/Map";
import styled from "@emotion/styled";
import { BBox } from "geojson";

interface Props {
  baseMaps: Record<
    string,
    {
      label: string;
      thumbnailUrl: string;
      styleUrl: string;
    }
  >;
  additionalMaps?: {
    title: string;
    maps: Array<{
      label: string;
      thumbnailUrl: string;
      url: string;
      bounds: BBox;
    }>;
  };
}

export function useBasemapSelector({ baseMaps, additionalMaps }: Props) {
  const [basemap, setBasemap] = useState<string>(Object.keys(baseMaps)[0]);
  const [additionalBasemapUrl, setAdditionalBaseMapUrl] = useState<string>();

  const baseMapStyleUrl = baseMaps[basemap].styleUrl;
  const icon = <IconImgExtraLarge src="/images/mapping/basemap.svg" />;

  const dropdown = (
    <>
      <Menu
        options={Object.keys(baseMaps).map((baseMapKey) => {
          const baseMapConfig = baseMaps[baseMapKey];
          return {
            key: baseMapKey,
            label: baseMapConfig.label,
            thumbnail: baseMapConfig.thumbnailUrl,
          };
        })}
        onSelected={setBasemap}
        selectedOption={basemap}
      />
      {additionalMaps && additionalMaps.maps.length > 0 && (
        <>
          <AdditionalMapsTitle>{additionalMaps.title}</AdditionalMapsTitle>
          <Menu
            options={additionalMaps.maps.map((config) => {
              return {
                key: config.url,
                label: config.label,
                thumbnail: config.thumbnailUrl,
              };
            })}
            onSelected={setAdditionalBaseMapUrl}
            selectedOption={additionalBasemapUrl}
          />
        </>
      )}
    </>
  );

  const control: AthenaMapControl = {
    icon,
    title: "Available Basemaps",
    components: {
      dropdown,
    },
  };

  const additionalBasemapBbox = additionalMaps?.maps.find(
    (x) => x.url === additionalBasemapUrl
  )?.bounds;

  return {
    control,
    styleUrl: baseMapStyleUrl,
    additionalBasemap: additionalBasemapBbox &&
      additionalBasemapUrl && {
        url: additionalBasemapUrl,
        bbox: additionalBasemapBbox,
      },
  };
}

const AdditionalMapsTitle = styled.h4`
  font-size: 1rem;
  font-weight: 600;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
`;
