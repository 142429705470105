/// <reference types="vite-plugin-svgr/client" />

import styled from "@emotion/styled";

export { ReactComponent as PinIcon } from "./pin.svg";
export { ReactComponent as DiamondIcon } from "./diamond.svg";
export { ReactComponent as DrawLineIcon } from "./draw-line.svg";
export { ReactComponent as DrawPointIcon } from "./draw-point.svg";
export { ReactComponent as TriangleIcon } from "./triangle.svg";
export { ReactComponent as CircleIcon } from "./circle.svg";

import { ReactComponent as DrawPolygonSvgIcon } from "./draw-polygon.svg";

export const DrawPolygonIcon = styled(DrawPolygonSvgIcon)`
  path {
    fill: ${(p) => p.fill};
    stroke: ${(p) => p.stroke};
  }
  circle {
    fill: ${(p) =>
      p.stroke}; // Need to set circle's fill to stroke color to allow setting fill color for inner area only
    stroke: ${(p) => p.stroke};
  }
`;
