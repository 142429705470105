import {
	StatusCodes,
} from 'http-status-codes';
import { config } from 'src/lib/config';
import { axiosClient } from "src/lib/axiosClient";
import { InferPitResponseType, IntelligencerFilter, IntelligencerPit, pitSchemas } from './types';


const setFilterQuery = (params: URLSearchParams, props: IntelligencerFilter) => {
  if(props.startDate) {
    params.set("startDate", props.startDate.toISOString());
  }

  if(props.endDate) {
    params.set("endDate", props.endDate.toISOString());
  }

  if(props.insuranceClaimNo) {
    params.set("insuranceClaimNo", props.insuranceClaimNo);
  }

  if (props.statuses && props.statuses.length > 0) {
    for (const status of props.statuses) {
      params.append("status", status);
    }
  }

  if (props.additionalStatuses) {
    for (const additionalStatus of props.additionalStatuses) {
      params.append("additionalStatus", additionalStatus);
    }
  }

  if(props.urgency) {
    params.set("urgency", props.urgency);
  }

  if (props.eventIds) {
    for (const eventId of props.eventIds) {
      params.append("event", eventId);
    }
  }

  if(props.lossAdjuster) {
    params.set("lossAdjuster", props.lossAdjuster);
  }

  if(props.assignedOffice) {
    params.set("assignedOffice", props.assignedOffice);
  }

  if(props.region) {
    params.set("region", props.region);
  }

  return params
}

export const buildUrl = (pit: IntelligencerPit, filter?: IntelligencerFilter) => {
  const url = new URL(`${config.gisApiUrl}/geo/ath/intelligencer/pit/${pit}`);

  url.searchParams.set("weeklyAggregation", "true");

  if (filter) {
    setFilterQuery(url.searchParams, filter);
  }

  return url;
}
          
export async function getIntelligencerPitData<TPit extends IntelligencerPit>(pit: TPit, filter?: IntelligencerFilter): Promise<InferPitResponseType<TPit>> {
  const url = buildUrl(pit, filter);
  const { data, status } = await axiosClient.get(url.toString());

  if (status !== StatusCodes.OK) {
    throw new Error(`Error getting intelligencer data for pit ${pit}`);
  }

  const schema = pitSchemas[pit];
  const parseResult = schema.safeParse(data);

  if (!parseResult.success) {
    throw new Error(`Unexpected response from intelligencer data for pit ${pit}: ${JSON.stringify(parseResult.error.flatten().fieldErrors, null, 2)}`);
  }

  return parseResult.data;
}
