import { config } from "src/lib/config";
import { GeoJsonCollectionFilter, buildFilter } from "./buildFilter";
import { GeoJsonCollectionName, VectorTilesCollectionName } from "./constants";
import { GeoBoundingBox } from "@deck.gl/geo-layers/typed";

export interface GetGeoJsonCollectionApiUrlProps<TCollection extends GeoJsonCollectionName> {
  collectionName: TCollection,
  filter?: GeoJsonCollectionFilter<TCollection>,
  boundingBox?: GeoBoundingBox,
  boundingBoxCRS?: string,
}

export const getGeoJsonCollectionApiUrl = <TCollection extends GeoJsonCollectionName>({
  collectionName,
  filter,
  boundingBox,
  boundingBoxCRS,
}: GetGeoJsonCollectionApiUrlProps<TCollection>
) => {
  const cqlQuery = filter && buildFilter(filter);
  const bbox = boundingBox ? [boundingBox.west, boundingBox.south, boundingBox.east, boundingBox.north].join(",") : undefined;
  const url = new URL(`${config.gisApiUrl}/geo/ath/features/collections/${collectionName}/items`);

  if (cqlQuery) {
    url.searchParams.append("filter", cqlQuery)
  }

  if (bbox) {
    url.searchParams.append("bbox", bbox)
  }

  if (boundingBoxCRS) {
    url.searchParams.append("bbox-crs", boundingBoxCRS)
  }

  return url.toString();
}


export const getVectorTilesCollectionApiUrl = (
  collection: VectorTilesCollectionName,
) =>
  `${config.gisApiUrl}/geo/features/collections/${collection}/tiles/WebMercatorQuad/{z}/{x}/{y}.pbf`;
              