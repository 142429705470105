import {
  Box,
  Button,
  Divider,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import { InlineUser } from "src/shared/user/InlineUser";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import React, { useState } from "react";
import { useClickAway } from "ahooks";

interface ClaimReportCommentProps {
  comment: string;
  commentId: string;
  author: string;
  selected: boolean;
  accepted: boolean;
  rejected: boolean;
  onAccept: any;
  onReject: any;
  onReply: (comment: { commentId: string; message: string }) => void;
  replies: {
    message: string;
    date: string;
    user: { name: string; id: string };
  }[];
  onClick: VoidFunction;
  onDeselected: VoidFunction;
}

export function ClaimReportComment(props: ClaimReportCommentProps) {
  const {
    author,
    comment,
    selected,
    accepted,
    rejected,
    onAccept,
    onReject,
    commentId,
    replies = [],
    onClick,
    onDeselected,
  } = props;
  const [reply, setReply] = useState("");
  const [showReplies, setShowReplies] = useState(false);
  const ref = React.useRef(null);
  useClickAway(() => {
    onDeselected();
  }, ref);
  return (
    <Stack
      sx={{
        padding: 1,
        backgroundColor: selected
          ? "rgb(233, 237, 255)"
          : accepted || rejected
          ? "#f2f2f2"
          : "",
      }}
      onClick={onClick}
      ref={ref}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "space-between",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <InlineUser name={author} size="sm" />
        </Box>
        {accepted && (
          <Box sx={{ ml: "auto", display: "flex", alignItems: "center" }}>
            <CheckCircleOutlineIcon
              color="success"
              sx={{
                marginRight: 0.5,
                display: "inline-block",
              }}
            />
            Resolved
          </Box>
        )}
        {rejected && (
          <Box sx={{ ml: "auto", display: "flex", alignItems: "center" }}>
            <CancelOutlinedIcon
              color="error"
              sx={{
                marginRight: 0.5,
                display: "inline-block",
              }}
            />
            Rejected
          </Box>
        )}
        {!accepted && !rejected && (
          <Box sx={{ ml: "auto" }}>
            <CheckCircleOutlineIcon
              sx={{
                marginRight: 0.5,
                display: "inline-block",
                color: "#0067DF",
                cursor: "pointer",
              }}
              onClick={() => {
                onAccept();
              }}
            />
            <CancelOutlinedIcon
              sx={{
                marginRight: 0.5,
                display: "inline-block",
                color: "#0067DF",
                cursor: "pointer",
              }}
              onClick={() => {
                onReject();
              }}
            />
          </Box>
        )}
      </Box>
      <Typography
        variant="subtitle2"
        sx={{
          marginRight: 1,
          display: "inline-block",
        }}
        component="div"
      >
        {comment}
      </Typography>
      {/* {replies.length > 0 && <Divider />} */}
      <Stack>
        {showReplies &&
          replies.map((reply) => {
            return (
              <Box
                key={reply.date}
                sx={{
                  marginLeft: 1,
                  paddingLeft: 1,
                  borderLeft: "1px solid lightgray",
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <InlineUser name={reply.user.name} size="sm" />
                </Box>
                <Typography
                  variant="subtitle2"
                  sx={{
                    marginRight: 1,
                    display: "inline-block",
                  }}
                  component="div"
                >
                  {reply.message}
                </Typography>
              </Box>
            );
          })}
        {replies.length > 0 && (
          <Typography
            variant="subtitle2"
            sx={{ color: "#0067DF", cursor: "pointer" }}
            onClick={() => {
              setShowReplies(!showReplies);
            }}
          >
            {showReplies ? "Hide replies" : `Show replies (${replies.length})`}
          </Typography>
        )}
      </Stack>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <TextField
          multiline
          sx={{
            display: "inline-flex",
            flex: 1,
            mr: 2,
            width: "50%",
            " .MuiInputBase-multiline": { paddingTop: 0, paddingBottom: 0 },
          }}
          placeholder="Reply here"
          value={reply}
          onChange={(e) => {
            setReply(e.target.value);
          }}
        />
        <Button
          variant="contained"
          sx={{ minWidth: "50px" }}
          onClick={() => {
            props.onReply({ message: reply, commentId });
            setReply("");
          }}
          disabled={reply.length === 0}
        >
          Send
        </Button>
      </Box>
    </Stack>
  );
}
