import {
  Button,
  Chip,
  InputAdornment,
  Paper,
  TextField,
  Typography,
} from "@mui/material";

import { TReportTemplate } from "@athena/server/src/api/types/reportTemplate";
import styled from "@emotion/styled";
import SearchIcon from "@mui/icons-material/Search";
import StarIcon from "@mui/icons-material/Star";
import React, { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { Link } from "react-router-dom";
import { axiosClient } from "src/lib/axiosClient";
import { formatDate } from "src/lib/date";
import { useCurrentUserContext } from "src/shared/hooks/useCurrentUserContext";
import {
  enqueueErrorSnackbar,
  enqueueSavingSnackbar,
  enqueueSuccessSnackbar,
} from "src/shared/snackbar/SnackbarHelper";

export const ReportTemplateList = () => {
  const { user } = useCurrentUserContext();
  const queryClient = useQueryClient();
  const { isLoading: loadingTemplates, data: templates } = useQuery(
    `ReportTemplates`,
    async (): Promise<TReportTemplate[]> =>
      (await axiosClient.get(`/reportTemplates`)).data
  );
  // const { mutate: createTemplate } = useMutation(
  //   async () => {
  //     const finishSaving = enqueueSavingSnackbar();

  //     const res = (
  //       await axiosClient.post(`/ReportTemplateLists`, {
  //         template,
  //         isPrimary: true,
  //       })
  //     ).data;
  //     finishSaving();
  //     return res;
  //   },
  //   {
  //     onSuccess: () => {
  //       enqueueSuccessSnackbar();
  //     },
  //     onError: () => {
  //       enqueueErrorSnackbar();
  //     },
  //     onSettled: () => {
  //       queryClient.invalidateQueries({
  //         queryKey: [`ReportTemplateLists`],
  //       });
  //     },
  //   }
  // );

  const { mutate: toggleTemplateActiveState } = useMutation(
    async (templateId: string) => {
      const finishSaving = enqueueSavingSnackbar();
      const template = templates?.find(
        (template) => template.reportTemplateId === templateId
      );
      if (template?.isPrimary) {
        finishSaving();
        enqueueErrorSnackbar("Primary template cannot be deactivated");
        return;
      }
      const res = (
        await axiosClient.put(`/reportTemplates/${templateId}`, {
          isActive: !template?.isActive,
        })
      ).data;
      finishSaving();
      return res;
    },
    {
      onSuccess: (res) => {
        if (!res) return;

        enqueueSuccessSnackbar();
      },
      onError: () => {
        enqueueErrorSnackbar();
      },
      onSettled: () => {
        queryClient.invalidateQueries({
          queryKey: [`ReportTemplates`],
        });
      },
    }
  );

  const { mutate: setPrimary } = useMutation(
    async (templateId: string) => {
      const finishSaving = enqueueSavingSnackbar();

      const res = (
        await axiosClient.put(`/reportTemplates/${templateId}`, {
          isPrimary: true,
          isActive: true,
        })
      ).data;
      const otherTemplates =
        templates?.filter(
          (template) => template.reportTemplateId !== templateId
        ) || [];
      // Not very resiliant, what if some requests fail
      for (const template of otherTemplates) {
        await axiosClient.put(`/reportTemplates/${template.reportTemplateId}`, {
          isPrimary: false,
        });
      }
      finishSaving();
      return res;
    },
    {
      onSuccess: () => {
        enqueueSuccessSnackbar();
      },
      onError: () => {
        enqueueErrorSnackbar();
      },
      onSettled: () => {
        queryClient.invalidateQueries({
          queryKey: [`ReportTemplates`],
        });
      },
    }
  );
  const [search, setSearch] = useState("");
  return (
    <div>
      <Paper
        sx={{
          padding: 2,
          mb: 2,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <TextField
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          value={search}
          placeholder="Search templates"
          onChange={(e) => setSearch(e.target.value)}
        />
        <Link to={`/admin/reportTemplates/new`}>
          {" "}
          <Button variant="contained" sx={{ minWidth: "200px" }}>
            Add Report Template
          </Button>
        </Link>
      </Paper>
      <Paper sx={{ padding: 2 }}>
        <BaseSurveyTable>
          <div
            style={{
              borderBottom: "1px solid lightgray",
              paddingBottom: "1rem",
            }}
          ></div>
          <Typography
            variant="subtitle1"
            sx={{
              color: "#0067DF",
              borderBottom: "1px solid lightgray",
              paddingBottom: "1rem",
            }}
          >
            Name
          </Typography>
          <Typography
            variant="subtitle1"
            sx={{
              color: "#0067DF",
              borderBottom: "1px solid lightgray",
              paddingBottom: "1rem",
            }}
          >
            Created
          </Typography>
          <Typography
            variant="subtitle1"
            sx={{
              color: "#0067DF",
              borderBottom: "1px solid lightgray",
              paddingBottom: "1rem",
            }}
          >
            Last Modified
          </Typography>
          <Typography
            variant="subtitle1"
            sx={{
              color: "#0067DF",
              borderBottom: "1px solid lightgray",
              paddingBottom: "1rem",
            }}
          >
            Status
          </Typography>
          <div
            style={{
              borderBottom: "1px solid lightgray",
              paddingBottom: "1rem",
            }}
          ></div>
          <div
            style={{
              borderBottom: "1px solid lightgray",
              paddingBottom: "1rem",
            }}
          ></div>
          {templates
            ?.filter((template) => {
              return template.reportTemplateId
                .toLowerCase()
                .includes(search.toLowerCase());
            })
            ?.map((template) => {
              if (!template.isActive && template.isPrimary) {
                template.isActive = true;
              }
              return (
                <React.Fragment key={template.reportTemplateId}>
                  <BaseSurveyCell>
                    <StarIcon
                      sx={{
                        color: template.isPrimary ? "#FFD700" : "lightgray",
                        cursor: "pointer",
                      }}
                      onClick={() => setPrimary(template.reportTemplateId)}
                    />
                  </BaseSurveyCell>
                  <BaseSurveyCell>
                    <BaseSurveyInfo>
                      <Typography variant={"caption"}>
                        {template.name || "Report Template"}
                      </Typography>
                    </BaseSurveyInfo>
                  </BaseSurveyCell>
                  <BaseSurveyCell>
                    <Typography variant={"caption"}>
                      {template.created && formatDate(template.created)}
                    </Typography>
                  </BaseSurveyCell>
                  <BaseSurveyCell>
                    <Typography variant={"caption"}>
                      {template.insUpdTs && formatDate(template.insUpdTs)}
                    </Typography>
                  </BaseSurveyCell>
                  <BaseSurveyCell>
                    <Chip
                      label={template.isActive ? "Active" : "Inactive"}
                      sx={{
                        minWidth: "100px",
                        ...(template.isActive
                          ? { backgroundColor: "#009706", color: "#ffffff" }
                          : { backgroundColor: "red", color: "#ffffff" }),
                        paddingLeft: 2,
                        paddingRight: 2,
                      }}
                    />
                  </BaseSurveyCell>
                  <BaseSurveyCell>
                    <Button
                      variant="contained"
                      disabled={template.isPrimary}
                      onClick={() =>
                        toggleTemplateActiveState(template.reportTemplateId)
                      }
                      sx={{ width: "120px" }}
                    >
                      {template.isActive ? "Deactivate" : "Activate"}
                    </Button>
                  </BaseSurveyCell>
                  <BaseSurveyCell>
                    <Link
                      to={`/admin/reportTemplates/${template.reportTemplateId}`}
                    >
                      Edit
                    </Link>
                  </BaseSurveyCell>
                </React.Fragment>
              );
            })}
        </BaseSurveyTable>
      </Paper>
    </div>
  );
};

const BaseSurveyInfo = styled.div``;
const BaseSurveyCell = styled.div`
  &:not(:nth-last-of-type(-n + 7)) {
    border-bottom: 1px solid #d3d3d3;
  }
  padding-top: 1rem;
  padding-bottom: 1rem;
  display: flex;
  align-items: center;
`;
const BaseSurveyTable = styled.div`
  display: grid;
  grid-template-columns: 0.2fr 3fr 1fr 1fr 1fr 1fr 1fr;
  width: 100%;
`;
