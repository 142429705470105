import { lazy, Suspense, useContext, useEffect } from "react";
import { Claims } from "./modules/claims/Claims";

import { LoadingPage, LoadingSpinner } from "@athena/components";
import { Box } from "@mui/system";
import * as Sentry from "@sentry/react";
import { Route, Routes } from "react-router-dom";
import { PageNameContext } from "./App";
import { MapTest } from "./MapTest";
import { initializedStorage } from "./lib/storage";
import { Admin } from "./modules/admin/Admin";
import { GeneralSettings as AdminGeneralSettings } from "./modules/admin/GeneralSettings";
import { OfficeCapacity } from "./modules/admin/OfficeCapacity";
import { ReportTemplate } from "./modules/admin/ReportTemplate";
import { ReportTemplateList } from "./modules/admin/ReportTemplateList";
import { Users } from "./modules/admin/users/Users";
import { LossAdjusterHome } from "./modules/home/LossAdjusterHome";
import { InfinityGuardian } from "./modules/infinityGuardian/InfinityGuardian";
import { Event } from "./modules/intel/components/events/Event";
import { EventsList } from "./modules/intel/components/events/EventsList";
import { EngineeringClaimDetails } from "./modules/intel/pages/EngineeringClaimDetails";
import { EngineeringClaimsPage } from "./modules/intel/pages/EngineeringClaimsPage";
import { InsuranceClaimsPage } from "./modules/intel/pages/InsuranceClaimsPage";
import { InsurancePoliciesPage } from "./modules/intel/pages/InsurancePoliciesPage";
import { Intelligence } from "./modules/intel/pages/Intelligence";
import { LossAdjusterClaimPage } from "./modules/intel/pages/LossAdjusterClaimPage";
import Menu from "./modules/menu/Menu";
import { GeneralSettings } from "./modules/settings/GeneralSettings";
import { Settings } from "./modules/settings/Settings";
import { SurveyManagement } from "./modules/settings/surveyManagement/SurveyManagement";
import { SignUp } from "./modules/signUp/SignUp";
import { useCurrentUser } from "./shared/hooks/useCurrentUser";
import { CurrentUserContext } from "./shared/hooks/useCurrentUserContext";
import { InsurerGeneralSettings } from "src/modules/admin/general/InsurerGeneral.tsx";

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

const Home = lazy(() => import("./modules/home/Home"));
const ClaimList = lazy(() => import("./modules/claims/ClaimList"));
const ReportList = lazy(() => import("./modules/claims/ReportList"));
const MapPage = lazy(() => import("./modules/spatialPortal"));
const Performance = lazy(() => import("./performance/Performance"));
const ClaimDetails = lazy(() => import("./modules/claims/ClaimDetails"));
const ClaimAssessment = lazy(() => import("./modules/claims/ClaimAssessment"));
const ClaimReport = lazy(
  () => import("./modules/claims/claimReport/ClaimReport")
);
const ClaimReview = lazy(() => import("./modules/claims/ClaimReview"));

// type PageNameContext = {
//   pageName: string;
//   setPageName: React.Dispatch<React.SetStateAction<string>>;
// };

// export const PageNameContext = createContext<PageNameContext>({
//   pageName: "",
//   setPageName: () => {},
// });

export function AppRoutes() {
  const { userData, isFetched } = useCurrentUser();
  const { user, organisation, permissions } = userData || {};
  const { pageName } = useContext(PageNameContext);

  useEffect(() => {
    if (!isFetched) return;
    const titles = {
      ["insurer"]: "Athena Intel",
      ["eqc"]: "Athena Intel",
      ["loss-adjuster"]: "Athena TPA",
      ["engineering"]: "Athena Assess",
      ["default"]: "Athena Assess",
    };

    document.title = `${titles[organisation?.orgType || "default"]}`;
    if (pageName) document.title += ` - ${pageName}`;
  }, [organisation, isFetched, pageName]);

  if (!user && isFetched) {
    return (
      <Box
        sx={{
          // make this absolutely positioned and center
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img src="/images/infinity-black.png"></img>
        <h1
          style={{
            fontFamily: "'Inter', sans-self",
            fontWeight: "500",
            margin: "1.5rem 0 0 0",
          }}
        >
          Please login with an internet connection before you use the app
          offline
        </h1>
      </Box>
    );
  }

  if (!user || !initializedStorage) return <LoadingPage />;

  return (
    <CurrentUserContext.Provider value={userData}>
      {user.needsSetup ? (
        <SignUp />
      ) : (
        <Suspense fallback={<LoadingSpinner />}>
          <SentryRoutes>
            {organisation?.orgType === "loss-adjuster" && (
              <>
                <Route path="/" element={<Home />} />
                <Route path="/home" element={<LossAdjusterHome />} />
                <Route path="/map" element={<MapPage />} />
                <Route path="/claims" element={<EngineeringClaimsPage />} />
                <Route
                  path="/claim/:organisationId/:claimId"
                  element={<LossAdjusterClaimPage />}
                />
              </>
            )}
            {organisation?.orgType === "eqc" && (
              <>
                <Route path="/" element={<MapPage />} />
                <Route path="/events" element={<EventsList />}></Route>
                <Route path="/event/:eventId" element={<Event />} />
                <Route path="/intelligence/*" element={<Intelligence />} />
                <Route
                  path="/engineering-claims"
                  element={<EngineeringClaimsPage />}
                />
                <Route
                  path="/claim/:organisationId/:claimId"
                  element={
                    <Menu>
                      <EngineeringClaimDetails />
                    </Menu>
                  }
                />
              </>
            )}
            {organisation?.orgType === "insurer" && (
              <>
                <Route path="/" element={<MapPage />} />
                <Route path="/events" element={<EventsList />}></Route>
                <Route path="/event/:eventId" element={<Event />} />
                <Route path="/intelligence/*" element={<Intelligence />} />
                <Route
                  path="/insurance-policies"
                  element={<InsurancePoliciesPage />}
                />
                <Route
                  path="/insurance-claims"
                  element={<InsuranceClaimsPage />}
                />
                <Route
                  path="/engineering-claims"
                  element={<EngineeringClaimsPage />}
                />
                <Route
                  path="/claim/:organisationId/:claimId"
                  element={
                    <Menu>
                      <EngineeringClaimDetails />
                    </Menu>
                  }
                />
                {user.roles.includes("admin") && (
                  <Route path="/admin" element={<Admin />}>
                    <Route
                      path="general"
                      element={<InsurerGeneralSettings />}
                    />
                    <Route path="users" element={<Users />} />
                  </Route>
                )}
              </>
            )}
            {organisation?.orgType === "engineering" && (
              <>
                <Route path="/" element={<Home />}></Route>
                <Route path="/map-test" element={<MapTest />}></Route>
                <Route path="/reports" element={<ReportList />} />
                <Route path="/claim" element={<ClaimList />} />
                <Route path="/claim/:claimId" element={<Claims />}>
                  <Route path="details" element={<ClaimDetails />} />
                  <Route path="assessment" element={<ClaimAssessment />} />
                  <Route path="report" element={<ClaimReport />} />
                  <Route path="review" element={<ClaimReview />} />
                </Route>

                <Route path="/performance/*" element={<Performance />} />
                <Route path="/map/*" element={<MapPage />} />
                {user.roles.includes("admin") && (
                  <Route path="/admin" element={<Admin />}>
                    <Route path="general" element={<AdminGeneralSettings />} />
                    <Route path="users" element={<Users />} />
                    <Route
                      path="reportTemplates"
                      element={<ReportTemplateList />}
                    />
                    <Route
                      path="reportTemplates/:templateId"
                      element={<ReportTemplate />}
                    />
                    <Route path="config" element={<Users />} />
                    <Route
                      path="office-capacity"
                      element={<OfficeCapacity />}
                    />
                  </Route>
                )}
                <Route path="/settings" element={<Settings />}>
                  <Route path="general" element={<GeneralSettings />} />
                  <Route
                    path="surveyManagement"
                    element={<SurveyManagement />}
                  />
                </Route>
              </>
            )}

            {user.roles.includes("infinity-guardian") && (
              <Route path="infinity-guardian" element={<InfinityGuardian />} />
            )}
          </SentryRoutes>
        </Suspense>
      )}
    </CurrentUserContext.Provider>
  );
}
