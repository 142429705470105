import { TReportTemplateStyles } from "@athena/server/src/api/types/reportTemplate";
import { Control, FieldValues, Path } from "react-hook-form";
import { FontStyle } from "./FontStyle";

export type SectionsWithFont =
  | "h1"
  | "h2"
  | "h3"
  | "p"
  | "body.font"
  | "table.title.font"
  | "table.content.font"
  | "photograph.font"
  | "photograph.caption"
  | "other.superscript";
interface HeadingStyleProps<T extends FieldValues> {
  type: SectionsWithFont;
  control: Control<T>;
  setValue: (path: Path<TReportTemplateStyles>, value: any) => void;
  errors?: any;
}

export const HeadingStyle = (
  props: HeadingStyleProps<TReportTemplateStyles>
) => {
  return (
    <div
      style={{
        display: "flex",
        flexWrap: "wrap",
        gap: "1rem",
        paddingLeft: "1rem",
      }}
    >
      <FontStyle {...props} />
    </div>
  );
};
