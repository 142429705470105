import styled from "@emotion/styled";
import { Avatar, Typography } from "@mui/material";

type InlineUserProps = {
  name: string;
  size?: "sm" | "md";
};

export const InlineUser = (props: InlineUserProps) => {
  const { name, size } = props;
  return (
    <ProfileItem>
      <UserAvatar name={name} size={size} />
      <Typography variant={size === "sm" ? "subtitle2" : "subtitle1"}>
        {name}
      </Typography>
    </ProfileItem>
  );
};

type UserAvatarProps = {
  name: string;
  size?: "sm" | "md";
};

export const UserAvatar = (props: UserAvatarProps) => {
  const { name, size } = props;
  return (
    <Avatar
      sx={{
        bgcolor: "#333",
        ...(size ? { width: 24, height: 24 } : {}),
      }}
    >
      <Typography
        variant={size === "sm" ? "caption" : "subtitle1"}
        component="p"
        sx={{ color: "#FFFFFF" }}
      >
        {capitalizeFirstLetters(name)}
      </Typography>
    </Avatar>
  );
};

const capitalizeFirstLetters = (str: string) => {
  const words = str.split(" "); // split the string into an array of words
  const capitalizedWords = words.map((word) => word.charAt(0).toUpperCase()); // get the first letter of each word and capitalize it
  return capitalizedWords.join(""); // join the capitalized words back into a string
};

const stringToColor = (string: string) => {
  let hash = 0;
  let i;

  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }

  return color;
};

const ProfileItem = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;

  img {
    height: 2rem;
    width: 2rem;
    object-fit: cover;
    object-position: center;
    border-radius: 120px;
  }
`;
