import Menu from "../../menu/Menu";

import { PageNameContext } from "src/App";
import { useContext, useEffect } from "react";
import { EngineeringClaimsList } from "../components/EngineeringClaimsList";

export function EngineeringClaimsPage() {
  const { setPageName } = useContext(PageNameContext);
  useEffect(() => {
    setPageName("Engineering Claims");
  }, [setPageName]);

  return (
    <Menu>
      <EngineeringClaimsList />
    </Menu>
  );
}
