export const dataURLToBlob = (dataURL: string) => {
  const parts = dataURL.split(",");
  if (!parts.length) return;
  const match = parts[0].match(/:(.*?);/);
  if (!match?.length) return;
  const contentType = match[1];
  const base64Data = atob(parts[1]);
  const arrayBuffer = new ArrayBuffer(base64Data.length);
  const uint8Array = new Uint8Array(arrayBuffer);

  for (let i = 0; i < base64Data.length; i++) {
    uint8Array[i] = base64Data.charCodeAt(i);
  }

  return new Blob([arrayBuffer], { type: contentType });
};
