import { Button, Divider, Paper, Typography } from "@mui/material";
import { enqueueSnackbar } from "notistack";
import { useRef } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { axiosClient } from "src/lib/axiosClient";
import { useCurrentUserContext } from "src/shared/hooks/useCurrentUserContext";
import { LoadingSpinner } from "@athena/components";
import {
  enqueueSavingSnackbar,
  enqueueSuccessSnackbar,
} from "src/shared/snackbar/SnackbarHelper";
import { config } from "src/lib/config";
import { AuthenticatedImage } from "src/shared/AuthenticatedImage";
import { acceptedImages } from "@athena/server/src/api/types/mimeTypes";

export const GeneralSettings = () => {
  const { user } = useCurrentUserContext();
  const logoInput = useRef<HTMLInputElement>(null);
  const draftInput = useRef<HTMLInputElement>(null);
  const queryClient = useQueryClient();

  //TODO: re-enable when the feature is ready
  // const { isLoading: loadingOrgPreferences, data: orgPreferences } = useQuery(
  //   `orgPreferences`,
  //   async () =>
  //     (
  //       await axiosClient.get(
  //         `/organisations/${user.organisation.id}/preferences`
  //       )
  //     ).data
  // );
  const { mutate: deleteDraftImage } = useMutation(
    async (): Promise<undefined> => {
      const finishedSaving = enqueueSavingSnackbar("Deleting");

      const res = (
        await axiosClient.delete(
          `/organisations/${user.organisation.id}/draft-image`
        )
      ).data;
      finishedSaving();
      enqueueSuccessSnackbar("Deleted");

      return res;
    },
    {
      onError: () => {
        enqueueSnackbar("Something went wrong", { variant: "error" });
      },
      onSettled: () => {
        queryClient.invalidateQueries({
          queryKey: [`org`],
        });
      },
    }
  );

  const uploadOrgLogo = async (file: any) => {
    const formData = new FormData();
    formData.append("image", file);

    const finishedSaving = enqueueSavingSnackbar();
    const res = (
      await axiosClient.post(
        `/organisations/${user.organisation.id}/logo`,
        formData
      )
    ).data;
    finishedSaving();
    enqueueSuccessSnackbar("Uploaded");
    queryClient.invalidateQueries({
      queryKey: [`org`],
    });
    return res;
  };

  const { mutate: deleteOrgLogo } = useMutation(
    async (): Promise<undefined> => {
      const finishedSaving = enqueueSavingSnackbar("Deleting");

      const res = (
        await axiosClient.delete(`/organisations/${user.organisation.id}/logo`)
      ).data;
      finishedSaving();
      enqueueSuccessSnackbar("Deleted");

      return res;
    },
    {
      onError: () => {
        enqueueSnackbar("Something went wrong", { variant: "error" });
      },
      onSettled: () => {
        queryClient.invalidateQueries({
          queryKey: [`org`],
        });
      },
    }
  );

  const uploadDraftImage = async (file: any) => {
    const formData = new FormData();
    formData.append("image", file);

    const finishedSaving = enqueueSavingSnackbar();
    const res = (
      await axiosClient.post(
        `/organisations/${user.organisation.id}/draft-image`,
        formData
      )
    ).data;
    finishedSaving();
    enqueueSuccessSnackbar("Uploaded");
    queryClient.invalidateQueries({
      queryKey: [`org`],
    });
    return res;
  };

  const { isLoading: loadingOrg, data: org } = useQuery(
    `org`,
    async () => (await axiosClient.get(`/organisations/current`)).data
  );

  if (loadingOrg) return <LoadingSpinner />;
  return (
    <>
      <section
        style={{
          display: "grid",
          gridTemplateColumns: "1fr 2fr",
          gap: "3rem",
          margin: "1.5rem",
        }}
      >
        <div>
          <Typography
            variant="h2"
            sx={{
              mb: "0.5rem",
            }}
          >
            Image Settings
          </Typography>
          <Typography
            variant="body1"
            sx={{
              mb: "1.5rem",
              pb: "1.5rem",
            }}
          >
            These settings are used to define images used in your report
            templates
          </Typography>
        </div>

        <Paper sx={{ padding: "1.5rem" }}>
          <Typography variant="h4">Aerials Organisation Logo</Typography>
          <Typography variant="body1" sx={{ mt: "0.5rem" }}>
            This image will be used in your Claim Reports whenever you generate
            an Aerial in your report.
          </Typography>
          <input
            type="file"
            // accept={acceptedImages.join(",")}
            name="files"
            onChange={(event) => {
              if (!event.target.files?.length) return;
              uploadOrgLogo(event.target.files[0]);
            }}
            ref={logoInput}
            style={{ display: "none" }}
          />
          {org.logoUrl && (
            <section
              style={{
                display: "flex",
                gap: "3rem",
                border: "1px solid lightgray",
                padding: "1.5rem",
                borderRadius: "12px",
                margin: "1.5rem 0",
                width: "fit-content",
              }}
            >
              <div>
                <AuthenticatedImage
                  src={`${config.apiUrl}/${org.logoUrl}`}
                  alt="file"
                  style={{ maxHeight: "120px" }}
                />
              </div>

              <Button
                variant="outlined"
                sx={{
                  minWidth: "200px",
                  maxHeight: "3rem",
                  alignSelf: "center",
                }}
                onClick={() => deleteOrgLogo()}
              >
                Remove
              </Button>
            </section>
          )}
          {!org.logoUrl && (
            <>
              <section
                style={{
                  display: "flex",
                  gap: "2rem",
                  border: "1px solid lightgray",
                  padding: "1.5rem",
                  borderRadius: "12px",
                  width: "fit-content",
                  margin: "1.5rem 0",
                }}
              >
                <Typography variant="body1">
                  <strong>No logo yet</strong>
                  <br />
                  Upload an image to use it in your claim reports
                </Typography>
                <Button
                  variant="outlined"
                  sx={{ minWidth: "200px" }}
                  onClick={() => logoInput.current?.click()}
                >
                  Upload Image
                </Button>
              </section>
            </>
          )}
          <Divider sx={{ mt: 6, mb: 6 }} />
          <Typography variant="h4">Report Draft Stamp</Typography>
          <Typography variant="body1" sx={{ mt: "0.5rem" }}>
            This image will be used in your draft reports to mark it as a draft
          </Typography>
          <input
            type="file"
            accept={acceptedImages.join(",")}
            name="files"
            onChange={(event) => {
              if (!event.target.files?.length) return;
              uploadDraftImage(event.target.files[0]);
            }}
            ref={draftInput}
            style={{ display: "none" }}
          />
          {org.draftImageUrl && (
            <section
              style={{
                display: "flex",
                gap: "3rem",
                border: "1px solid lightgray",
                padding: "1.5rem",
                borderRadius: "12px",
                width: "fit-content",
                justifyContent: "space-between",
                margin: "1.5rem 0",
              }}
            >
              <div>
                <AuthenticatedImage
                  src={`${config.apiUrl}/${org.draftImageUrl}`}
                  alt="file"
                  style={{ maxHeight: "120px" }}
                />
              </div>
              <Button
                variant="outlined"
                sx={{
                  minWidth: "200px",
                  maxHeight: "3rem",
                  alignSelf: "center",
                }}
                onClick={() => deleteDraftImage()}
              >
                Remove
              </Button>
            </section>
          )}
          {!org.draftImageUrl && (
            <section
              style={{
                display: "flex",
                gap: "3rem",
                border: "1px solid lightgray",
                padding: "1.5rem",
                borderRadius: "12px",
                width: "fit-content",
                margin: "1.5rem 0",
                alignItems: "center",
              }}
            >
              <Typography variant="body1">
                <strong>No Draft Stamp Yet</strong>
                <br />
                Upload an image to use it in your claim reports as a draft stamp
              </Typography>
              <Button
                variant="outlined"
                sx={{ minWidth: "200px", maxHeight: "3rem" }}
                onClick={() => draftInput.current?.click()}
              >
                Upload Image
              </Button>
            </section>
          )}
        </Paper>
      </section>
      {/*TODO: re-enable when the feature is ready */}
      {/*<EmailPreferences*/}
      {/*  loadingOrgEmails={loadingOrgPreferences}*/}
      {/*  orgEmails={orgPreferences.emails || []}*/}
      {/*/>*/}
    </>
  );
};
