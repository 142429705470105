import styled from "@emotion/styled";
import {
  ClaimDetailsSection,
  DialogSection,
  DialogSectionBody,
  DialogSectionContentVertical,
  DialogSectionHeader,
} from "@athena/components";
import { Box, Paper, Stack } from "@mui/material";
import { inferRouterOutputs } from "@trpc/server";
import { TRPCRootRouter } from "@athena/server/src/trpc";

interface Props {
  claim: inferRouterOutputs<TRPCRootRouter>["claims"]["getClaim"];
}

export function SiteVisits({ claim }: Props) {
  return (
    <Box
      sx={{
        flexGrow: 1,
        mb: 3,
      }}
    >
      <Paper
        elevation={4}
        sx={{
          padding: "1.5rem",
          mb: "1.5rem",
          scrollMarginTop: "5.5rem",
        }}
        id="siteVisits"
      >
        <ClaimDetailsSection title="Site visits">
          <Stack
            direction={"column"}
            sx={{
              maxWidth: "1000px",
              mb: 2,
            }}
          >
            {claim.siteVisits?.map((x, i) => (
              <SiteVisitContainer key={x.siteVisitId}>
                <h3>Site Visit {i + 1}</h3>
                <DialogSection>
                  <DialogSectionContentVertical>
                    <DialogSectionHeader>
                      Site visit date booked
                    </DialogSectionHeader>
                    <DialogSectionBody>{x.visitDate}</DialogSectionBody>
                  </DialogSectionContentVertical>
                </DialogSection>
                <DialogSection>
                  <DialogSectionContentVertical>
                    <DialogSectionHeader>
                      Discussion with customer
                    </DialogSectionHeader>
                    <DialogSectionBody>
                      {(x.discussionWithClient && "Yes") || "No"}
                    </DialogSectionBody>
                  </DialogSectionContentVertical>
                </DialogSection>
                <DialogSection>
                  <DialogSectionContentVertical>
                    <DialogSectionHeader>Visit booked</DialogSectionHeader>
                    <DialogSectionBody>
                      {(x.visitBooked && "Yes") || "No"}
                    </DialogSectionBody>
                  </DialogSectionContentVertical>
                </DialogSection>
                <DialogSection>
                  <DialogSectionContentVertical>
                    <DialogSectionHeader>
                      Site visit completed
                    </DialogSectionHeader>
                    <DialogSectionBody>
                      {(x.siteVisited && "Yes") || "No"}
                    </DialogSectionBody>
                  </DialogSectionContentVertical>
                </DialogSection>
                <DialogSection>
                  <DialogSectionContentVertical>
                    <DialogSectionHeader>Notes</DialogSectionHeader>
                    <DialogSectionBody>{x.notes}</DialogSectionBody>
                  </DialogSectionContentVertical>
                </DialogSection>
              </SiteVisitContainer>
            ))}
          </Stack>
        </ClaimDetailsSection>
      </Paper>
    </Box>
  );
}

const SiteVisitContainer = styled.div`
  margin-bottom: 1rem;
  :not(:last-child) {
    border-bottom: 1px solid #d3d3d3;
    padding-bottom: 0.75rem;
  }
`;
