import { Claim } from "@athena/server/src/api/types/claim";

export const getAddressForClaim = (claim: Claim): string => {
  if (claim.location) {
    if (claim.location.address) return claim.location.address;
    else if (claim.location.manual?.address)
      return claim.location.manual.address;
    else if (claim.location.parcelAddress) return claim.location.parcelAddress;
  }
  return "";
};
