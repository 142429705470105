export type IconProps = {
  color: string;
};

export const PolygonDashedIcon = (props: IconProps) => {
  const { color } = props;
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_542_16790)">
        <path
          d="M14.1924 21.75H16.3564"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.39453 21.75H10.5585"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.4424 2.25H15.6064"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.39453 2.25H10.5585"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3 9.8371V7.6731"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3 16.3271V14.1631"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M17.166 7.91754L18.248 6.04346"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M18.248 17.668L17.166 15.7939"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <circle cx="3" cy="2.25" r="2.25" fill={color} />
        <circle cx="21" cy="2.25" r="2.25" fill={color} />
        <circle cx="15" cy="12" r="2.25" fill={color} />
        <circle cx="3" cy="21.75" r="2.25" fill={color} />
        <circle cx="21" cy="21.75" r="2.25" fill={color} />
        <circle cx="3" cy="3" r="3" fill={color} />
        <circle cx="21" cy="3" r="3" fill={color} />
        <circle cx="15" cy="12" r="3" fill={color} />
        <circle cx="3" cy="21" r="3" fill={color} />
        <circle cx="21" cy="21" r="3" fill={color} />
      </g>
      <defs>
        <clipPath id="clip0_542_16790">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
