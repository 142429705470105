import { useContext, useEffect } from "react";

import Menu from "../../menu/Menu";
import { PageNameContext } from "src/App";
import { InsurancePoliciesList } from "../components/InsurancePoliciesList";

export function InsurancePoliciesPage() {
  const { setPageName } = useContext(PageNameContext);
  useEffect(() => {
    setPageName("Insurance Policies");
  }, [setPageName]);

  return (
    <Menu>
      <InsurancePoliciesList />
    </Menu>
  );
}
