import styled from "@emotion/styled";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import DescriptionIcon from "@mui/icons-material/Description";
import EqualizerIcon from "@mui/icons-material/Equalizer";
import EventIcon from "@mui/icons-material/Event";
import HelpCenterIcon from "@mui/icons-material/HelpCenter";
import HomeIcon from "@mui/icons-material/Home";
import ListIcon from "@mui/icons-material/List";
import MapIcon from "@mui/icons-material/Map";
import MenuIcon from "@mui/icons-material/Menu";
import PolicyIcon from "@mui/icons-material/Policy";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import SettingsIcon from "@mui/icons-material/Settings";
import WaterDamageIcon from "@mui/icons-material/WaterDamage";
import { Drawer, Menu, MenuItem, Select } from "@mui/material";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import _Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import * as Sentry from "@sentry/react";
import React, { useContext, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { Link } from "react-router-dom";
import { PageNameContext } from "src/App";
import { trpc } from "src/lib/api/trpc";
import { axiosClient } from "src/lib/axiosClient";
import { redirectToAuth } from "src/lib/redirectToAuth";
import { useCurrentUser } from "../hooks/useCurrentUser";
import { useNetworkContext } from "../hooks/useNetworkContext";
import {
  enqueueErrorSnackbar,
  enqueueSavingSnackbar,
  enqueueSuccessSnackbar,
} from "../snackbar/SnackbarHelper";
import { User } from "../user/User";

export function Toolbar() {
  const { userData, isFetched } = useCurrentUser();
  const { user, organisation } = userData || {};
  const { pageName } = useContext(PageNameContext);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const trpcContext = trpc.useContext();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const { online } = useNetworkContext();
  const queryClient = useQueryClient();

  const [menuOpen, setOpen] = useState(false);

  const handleLogout = () => {
    Sentry.setUser(null);
    redirectToAuth();
  };

  const { mutate: saveUser } = useMutation(
    async (preferredHomePage: string) => {
      const finishSaving = enqueueSavingSnackbar();
      const res = (
        await axiosClient.put(`/users/${user?.userId}`, {
          preferredHomePage,
        })
      ).data;
      finishSaving();
      return res;
    },
    {
      onSuccess: () => {
        enqueueSuccessSnackbar();
      },
      onError: () => {
        enqueueErrorSnackbar();
      },
      onSettled: () => {
        queryClient.invalidateQueries({
          queryKey: [`user`],
        });
        trpcContext.user.invalidate();
      },
    }
  );
  if (!isFetched || !user) return null;

  const homePages = {
    engineering: [
      ["assessing-engineer", "Assessing Engineer"],
      ["reviewing-engineer", "Reviewing Engineer"],
      ["data-entry", "Data Entry Specialist"],
      ["coordinator", "Regional Coordinator"],
    ],
    "loss-adjuster": [
      ["home", "Overview"],
      ["map", "Spatial Portal"],
    ],
  } as {
    [key: string]: [string, string][];
  };

  return (
    <Box
      position="sticky"
      sx={{ backgroundColor: "#fff", zIndex: "10", top: 0 }}
    >
      <Drawer
        anchor={"left"}
        open={menuOpen}
        onClose={() => setOpen(false)}
        sx={{
          " .MuiPaper-root": {
            borderRadius: "0",
            borderRight: "1px solid lightgray",
            width: "100%",
            maxWidth: "400px",
          },
        }}
      >
        <SidebarChildren>
          <div>
            <SidebarHeading>
              <Link to="/">
                <img src="/images/athena.svg" style={{ maxWidth: "6.5rem" }} />
              </Link>
            </SidebarHeading>
            {["loss-adjuster"].includes(organisation?.orgType || "") && (
              <>
                <Link to="/">
                  <SidebarMenuItem aria-label="menu">
                    <HomeIcon fontSize="medium" sx={{}} />
                    <p>Home</p>
                  </SidebarMenuItem>
                </Link>
                <Link to="/home">
                  <SidebarMenuItem aria-label="menu">
                    <EqualizerIcon fontSize="medium" sx={{}} />
                    <p>Overview</p>
                  </SidebarMenuItem>
                </Link>
                <Link to="/map">
                  <SidebarMenuItem aria-label="menu">
                    <MapIcon fontSize="medium" sx={{}} />
                    <p>Spatial Portal</p>
                  </SidebarMenuItem>
                </Link>
                <Link to="/claims">
                  <SidebarMenuItem aria-label="menu">
                    <ListIcon fontSize="medium" sx={{}} />
                    <p>Claims</p>
                  </SidebarMenuItem>
                </Link>
              </>
            )}
            {["eqc", "insurer"].includes(organisation?.orgType || "") && (
              <>
                <Link to="/">
                  <SidebarMenuItem aria-label="menu">
                    <HomeIcon fontSize="medium" sx={{}} />
                    <p>Home</p>
                  </SidebarMenuItem>
                </Link>
                <Link to="/engineering-claims">
                  <SidebarMenuItem aria-label="menu">
                    <PolicyIcon fontSize="medium" sx={{}} />
                    <p>Engineering Claims</p>
                  </SidebarMenuItem>
                </Link>
                {organisation?.orgType != "eqc" && (
                  <>
                    <Link to="/insurance-policies">
                      <SidebarMenuItem aria-label="menu">
                        <PolicyIcon fontSize="medium" sx={{}} />
                        <p>Policies</p>
                      </SidebarMenuItem>
                    </Link>
                    <Link to="/insurance-claims">
                      <SidebarMenuItem aria-label="menu">
                        <WaterDamageIcon fontSize="medium" sx={{}} />
                        <p>Insurance Claims</p>
                      </SidebarMenuItem>
                    </Link>
                  </>
                )}
                <Link to="/intelligence">
                  <SidebarMenuItem aria-label="menu">
                    <QueryStatsIcon fontSize="medium" sx={{}} />
                    <p>Intelligence</p>
                  </SidebarMenuItem>
                </Link>
                <Link to="/events">
                  <SidebarMenuItem aria-label="menu">
                    <EventIcon fontSize="medium" sx={{}} />
                    <p>Events</p>
                  </SidebarMenuItem>
                </Link>
              </>
            )}
            {(organisation?.orgType === "engineering" ||
              !organisation?.orgType) && (
              <>
                <Link to="/">
                  <SidebarMenuItem aria-label="menu">
                    <HomeIcon fontSize="medium" sx={{}} />
                    <p>Home</p>
                  </SidebarMenuItem>
                </Link>
                <Link to="/claim">
                  <SidebarMenuItem aria-label="menu">
                    <ListIcon fontSize="medium" sx={{}} />
                    <p>Claims</p>
                  </SidebarMenuItem>
                </Link>
                <Link to="/reports">
                  <SidebarMenuItem aria-label="menu">
                    <DescriptionIcon fontSize="medium" sx={{}} />
                    <p>Reports</p>
                  </SidebarMenuItem>
                </Link>
                <Link to="/map">
                  <SidebarMenuItem aria-label="menu">
                    <MapIcon fontSize="medium" sx={{}} />
                    <p>Spatial Portal</p>
                  </SidebarMenuItem>
                </Link>
                <a
                  href="https://infinitystudiohelp.zendesk.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <SidebarMenuItem aria-label="menu">
                    <HelpCenterIcon fontSize="medium" sx={{}} />
                    <p>Support</p>
                  </SidebarMenuItem>
                </a>
                <Link to="/settings">
                  <SidebarMenuItem aria-label="menu">
                    <SettingsIcon fontSize="medium" sx={{}} />
                    <p>Settings</p>
                  </SidebarMenuItem>
                </Link>
                {user.roles.includes("coordinator") ||
                  (user.roles.includes("admin") && (
                    <Link to="/performance">
                      <SidebarMenuItem aria-label="menu">
                        <EqualizerIcon fontSize="medium" sx={{}} />
                        <p>Performance</p>
                      </SidebarMenuItem>
                    </Link>
                  ))}
                {user.roles.includes("admin") && (
                  <Link to="/admin">
                    <SidebarMenuItem aria-label="menu">
                      <AdminPanelSettingsIcon fontSize="medium" sx={{}} />
                      <p>Admin Settings</p>
                    </SidebarMenuItem>
                  </Link>
                )}
              </>
            )}
          </div>

          <SidebarFooter>
            {organisation?.orgType &&
              ["engineering", "loss-adjuster"].includes(
                organisation?.orgType
              ) && (
                <TestingContainer>
                  <h4>Preferred Home Page</h4>
                  <Select
                    sx={{ width: 225, mr: 2 }}
                    value={user.preferredHomePage}
                    onChange={(e) => {
                      saveUser(e.target.value);
                    }}
                  >
                    {homePages[organisation?.orgType].map((option) => (
                      <MenuItem key={option[0]} value={option[0]}>
                        {option[1]}
                      </MenuItem>
                    ))}
                  </Select>
                </TestingContainer>
              )}

            <MenuItem sx={{ padding: "1rem" }} onClick={handleLogout}>
              Logout
            </MenuItem>
            <p>Engineered by your friends at Infinity Studio</p>
          </SidebarFooter>
        </SidebarChildren>
      </Drawer>
      <_Toolbar
        sx={{
          paddingRight: "0 !important",
          paddingLeft: "0 !important",
          borderBottom: "1px solid lightgray",
        }}
      >
        <IconButton
          edge="start"
          aria-label="menu"
          sx={{
            display: "flex",
            width: "4rem",
            borderRight: "1px solid lightgray",
            borderRadius: "0",
            alignItems: "center",
            justifyItems: "center",
            margin: "0 1rem 0 0",
            padding: 0,
            height: "4rem",
            color: "#0067DF",
          }}
          onClick={() => setOpen(true)}
        >
          <MenuIcon
            fontSize="large"
            sx={{
              color: "#0067DF",
            }}
          />
        </IconButton>

        <Typography variant="h6" component="h1" sx={{ flexGrow: 1 }}>
          {pageName}
        </Typography>
        <span
          style={{
            marginRight: "2rem",
            display: "inline-flex",
            alignItems: "center",
          }}
        >
          <div
            style={{
              height: 8,
              width: 8,
              backgroundColor: online ? "#6de477" : "#e4ce6d",
              borderRadius: "12px",
              marginRight: "0.5rem",
            }}
          ></div>
          {online ? "Online" : "Offline"}
        </span>
        {
          <MobileHide>
            <User
              companyName={user.organisation.name}
              fullName={user.firstName + " " + user.lastName}
              onClick={handleClick}
            />
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem onClick={handleLogout}>Logout</MenuItem>
            </Menu>
          </MobileHide>
        }
      </_Toolbar>
    </Box>
  );
}

const MobileHide = styled.div`
  border-left: 1px solid lightgray;
  min-height: 64px;
  padding-left: 1rem;
  display: flex;
  flex-direction: row;
  @media screen and (max-width: 900px) {
    display: none;
  }
`;

const SidebarMenuItem = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.75rem;
  padding: 1rem;
  border-bottom: 1px solid #ececec;
  align-items: center;
  p {
    font-size: 1rem;
  }
  :hover {
    background-color: #e4e4ff;
  }
`;

const SidebarChildren = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

const SidebarHeading = styled.div`
  display: flex;
  min-height: 4rem;
  justify-content: space-between;
  border-bottom: 1px solid #ececec;
  a {
    padding-left: 1rem;
    align-items: center;
    display: flex;
  }
  .maxButton {
    border-left: 1px solid #ececec;
  }
`;

const SidebarFooter = styled.div`
  border-top: 1px solid #ececec;
  p {
    padding: 1rem;
    border-top: 1px solid #ececec;
    color: #666;
  }
`;

const TestingContainer = styled.div`
  padding: 1rem;
  border-bottom: 1px solid #ececec;
`;
