import { Box, Container, Paper } from "@mui/material";
import { ReactNode } from "react";
import { useAthenaMapControlContext } from "./Map";

interface ModalProps {
  children: ReactNode;
}

export function Modal({ children }: ModalProps) {
  const athenaMapControlContext = useAthenaMapControlContext();
  return (
    <Box
      sx={{
        position: "absolute",
        zIndex: 29,
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(0, 0, 0, 0.3)",
      }}
      onClick={() => athenaMapControlContext?.resetControl()}
    >
      <Container
        maxWidth={false}
        sx={{
          position: "absolute",
          zIndex: 30,
          pt: "1rem",
          width: "100%",
        }}
      >
        <Container
          maxWidth={false}
          sx={{
            maxWidth: "1312px",
            overflow: "hidden",
          }}
        >
          <Paper
            sx={{
              maxHeight: "92vh",
              overflowY: "scroll",
              scrollbarColor: "trasparent",
              bgcolor: "background.paper",
              padding: "1rem",
              borderRadius: "1rem",
            }}
            onClick={(e) => e.stopPropagation()}
          >
            {children}
          </Paper>
        </Container>
      </Container>
    </Box>
  );
}
