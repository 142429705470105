import { FC, useCallback } from "react";
import { SmallButton, IconImgSmall } from "../components/styled";

interface GeoLocationProps {
  onLocationReceived: (location: GeolocationPosition) => void;
}

export const GeoLocation: FC<GeoLocationProps> = ({ onLocationReceived }) => {
  const requestLocation = useCallback(() => {
    navigator.geolocation.getCurrentPosition(onLocationReceived, (error) =>
      console.error("Error getting user location:", error)
    );
  }, []);

  return (
    <SmallButton onClick={requestLocation}>
      <IconImgSmall src="/images/mapping/location.svg" />
    </SmallButton>
  );
};
