import {
  ClaimDetailsSection,
  DialogSection,
  DialogSectionBody,
  DialogSectionContentVertical,
  DialogSectionHeader,
} from "@athena/components";
import { Box, Chip, Paper, Stack } from "@mui/material";
import { format } from "date-fns";
import { inferRouterOutputs } from "@trpc/server";
import { TRPCRootRouter } from "@athena/server/src/trpc";

interface Props {
  claim: inferRouterOutputs<TRPCRootRouter>["claims"]["getClaim"];
}

export function Customer({ claim }: Props) {
  return (
    <Box
      sx={{
        flexGrow: 1,
        mb: 3,
      }}
    >
      <Paper
        elevation={4}
        sx={{
          padding: "1.5rem",
          mb: "1.5rem",
          scrollMarginTop: "5.5rem",
        }}
        id="customer"
      >
        <ClaimDetailsSection title="Customer details">
          <Stack
            direction={"column"}
            sx={{
              maxWidth: "1000px",
              mb: 2,
            }}
          >
            {claim.customers.map((x) => (
              <div key={x.name}>
                <DialogSection>
                  <DialogSectionContentVertical>
                    <DialogSectionHeader>Customer Name</DialogSectionHeader>
                    <DialogSectionBody>{x.name}</DialogSectionBody>
                  </DialogSectionContentVertical>
                </DialogSection>
                <DialogSection>
                  <DialogSectionContentVertical>
                    <DialogSectionHeader>
                      Customer contact number
                    </DialogSectionHeader>
                    <DialogSectionBody>{x.contactNumber}</DialogSectionBody>
                  </DialogSectionContentVertical>
                </DialogSection>
                <DialogSection>
                  <DialogSectionContentVertical>
                    <DialogSectionHeader>Customer email</DialogSectionHeader>
                    <DialogSectionBody>{x.email}</DialogSectionBody>
                  </DialogSectionContentVertical>
                </DialogSection>
                <DialogSection>
                  <DialogSectionContentVertical>
                    <DialogSectionHeader>Primary contact</DialogSectionHeader>
                    <DialogSectionBody>
                      {(x.primaryContact && "Yes") || "No"}
                    </DialogSectionBody>
                  </DialogSectionContentVertical>
                </DialogSection>
              </div>
            ))}
          </Stack>
        </ClaimDetailsSection>
      </Paper>
    </Box>
  );
}
