import { debounce } from "@mui/material";
import { useDeepCompareEffect } from "ahooks";
import { useCallback, useEffect, useRef } from "react";
import { useFormContext, useWatch } from "react-hook-form";

export const AutoSave = ({ defaultValues, onSubmit }: Props) => {
  // Get the closest form methods
  const methods = useFormContext();
  const shouldSaveRef = useRef(true);
  // Save if this function is called and then not called again within 1000ms
  // eslint-disable-next-line
  useEffect(() => {
    shouldSaveRef.current =
      methods.formState.isDirty && !methods.formState.isSubmitting;
  }, [methods.formState.isDirty, methods.formState.isSubmitting]);
  const debouncedSave = useCallback(
    debounce(() => {
      if (shouldSaveRef.current) {
        methods.handleSubmit(onSubmit)();
      }
    }, 5000),
    []
  );

  // Watch all the data, provide with defaultValues from server, this way we know if the new data came from server or where actually edited by user
  // const watchedData = methods.watch(undefined, defaultValues);
  const watchedData = useWatch({
    control: methods.control,
  });

  // So we try something else

  useDeepCompareEffect(() => {
    if (methods.formState.isDirty && !methods.formState.isSubmitting) {
      debouncedSave();
    }
  }, [watchedData]);

  return null;
};

type Props = {
  defaultValues?: any;
  onSubmit: any;
};
