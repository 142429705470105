import {
  EventServiceLevelAgreement,
  EventServiceLevelAgreementSchema,
} from "@athena/server/src/api/types/eventServiceLevelAgreement";
import styled from "@emotion/styled";
import { zodResolver } from "@hookform/resolvers/zod";
import { Button, Divider, Paper, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { enqueueSnackbar } from "notistack";
import { FormProvider, useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import { axiosClient } from "src/lib/axiosClient";
import { FormTextField } from "src/shared/form/FormTextField";
import {
  enqueueSavingSnackbar,
  enqueueSuccessSnackbar,
} from "src/shared/snackbar/SnackbarHelper";

type ServiceLevelAgreementsProps = {
  serviceLevelAgreements?: EventServiceLevelAgreement | null;
};

export const ServiceLevelAgreements = (props: ServiceLevelAgreementsProps) => {
  const { control, handleSubmit, reset, ...rest } =
    useForm<EventServiceLevelAgreement>({
      resolver: zodResolver(EventServiceLevelAgreementSchema),
      defaultValues: props.serviceLevelAgreements || {
        serviceLevelAgreement: {
          intialCustomerContact: 0,
          intialCustomerContactUrgent: 0,
          siteVisitCompleted: 0,
          siteVisitCompletedUrgent: 0,
          siteReportCompleted: 0,
          siteReportCompletedUrgent: 0,
        },
      },
    });
  const { eventId } = useParams();
  const { mutate: saveSLA } = useMutation(
    async ({
      serviceLevelAgreement,
    }: EventServiceLevelAgreement): Promise<EventServiceLevelAgreement> => {
      const finishSaving = enqueueSavingSnackbar();
      let res;
      if (props.serviceLevelAgreements) {
        res = (
          await axiosClient.put(`/service-level-agreement/${eventId}`, {
            ...props.serviceLevelAgreements,
            serviceLevelAgreement,
          })
        ).data;
      } else {
        res = (
          await axiosClient.post(`/service-level-agreement/${eventId}`, {
            serviceLevelAgreement,
          })
        ).data;
      }

      finishSaving();

      return res;
    },
    {
      onSuccess: () => {
        enqueueSuccessSnackbar();
      },
      onError: () => {
        enqueueSnackbar("Something went wrong", { variant: "error" });
      },
      onSettled: () => {
        queryClient.invalidateQueries({
          queryKey: [`event-${eventId}-sla`],
        });
      },
    }
  );
  const queryClient = useQueryClient();

  const addEvent = async (name: string, dateOfEvent?: string) => {
    const finishedSaving = enqueueSavingSnackbar();
    const res = (
      await axiosClient.post(`/events/request`, { name, dateOfEvent })
    ).data;
    finishedSaving();
    enqueueSuccessSnackbar("Requested");
    queryClient.invalidateQueries({
      queryKey: [`events`],
    });
    return res;
  };

  const onSubmit = (data: EventServiceLevelAgreement) => {
    if (Object.keys(rest.formState.errors).length) return;
    saveSLA(data);
  };

  return (
    <Paper
      elevation={4}
      sx={{
        padding: "1.5rem",
        mb: "1.5rem",
        display: "flex",
        flexWrap: "wrap",
        flexDirection: "column",
        mt: 4,
      }}
    >
      <FormProvider
        {...rest}
        reset={reset}
        control={control}
        handleSubmit={handleSubmit}
      >
        <form
          key={"event"}
          onSubmit={(e) => {
            e.stopPropagation();
            handleSubmit(onSubmit)(e);
          }}
        >
          <Typography variant="subtitle1">
            Use this to set SLA timing guidelines for insurers and loss
            adjusters working on this event
          </Typography>
          <Divider sx={{ mb: 2, mt: 2 }} />
          <FormGrid>
            <Box sx={{ borderBottom: "2px solid lightgray", padding: 2 }}>
              <Typography variant="subtitle1">
                <strong>State</strong>
              </Typography>
            </Box>
            <Box sx={{ borderBottom: "2px solid lightgray", padding: 2 }}>
              <Typography variant="subtitle1">
                <strong>Days Budgeted</strong>
              </Typography>
            </Box>
            <Box sx={{ borderBottom: "2px solid lightgray", padding: 2 }}>
              <Typography variant="subtitle1">
                <strong>Days After</strong>
              </Typography>
            </Box>
            <Box sx={{ borderBottom: "1px solid lightgray", padding: 2 }}>
              <Typography variant="subtitle1">
                Initial Customer Contact
              </Typography>
            </Box>
            <Box sx={{ borderBottom: "1px solid lightgray", padding: 2 }}>
              <FormTextField
                InputProps={{ endAdornment: "Days" }}
                type="number"
                control={control}
                name="serviceLevelAgreement.intialCustomerContact"
              />
            </Box>
            <Box sx={{ borderBottom: "1px solid lightgray", padding: 2 }}>
              <Typography variant="subtitle1">
                After service request date
              </Typography>
            </Box>
            <Box sx={{ borderBottom: "1px solid lightgray", padding: 2 }}>
              <Typography variant="subtitle1">
                Initial Customer Contact - Urgent
              </Typography>
            </Box>
            <Box sx={{ borderBottom: "1px solid lightgray", padding: 2 }}>
              <FormTextField
                InputProps={{ endAdornment: "Days" }}
                type="number"
                control={control}
                name="serviceLevelAgreement.intialCustomerContactUrgent"
              />
            </Box>
            <Box sx={{ borderBottom: "1px solid lightgray", padding: 2 }}>
              <Typography variant="subtitle1">
                After service request date
              </Typography>
            </Box>
            <Box sx={{ borderBottom: "1px solid lightgray", padding: 2 }}>
              <Typography variant="subtitle1">Site Visit Completed</Typography>
            </Box>
            <Box sx={{ borderBottom: "1px solid lightgray", padding: 2 }}>
              <FormTextField
                InputProps={{ endAdornment: "Days" }}
                type="number"
                control={control}
                name="serviceLevelAgreement.siteVisitCompleted"
              />
            </Box>
            <Box sx={{ borderBottom: "1px solid lightgray", padding: 2 }}>
              <Typography variant="subtitle1">
                After customer contacted date
              </Typography>
            </Box>
            <Box sx={{ borderBottom: "1px solid lightgray", padding: 2 }}>
              <Typography variant="subtitle1">
                Site Visit Completed - Urgent
              </Typography>
            </Box>
            <Box sx={{ borderBottom: "1px solid lightgray", padding: 2 }}>
              <FormTextField
                InputProps={{ endAdornment: "Days" }}
                type="number"
                control={control}
                name="serviceLevelAgreement.siteVisitCompletedUrgent"
              />
            </Box>
            <Box sx={{ borderBottom: "1px solid lightgray", padding: 2 }}>
              <Typography variant="subtitle1">
                After customer contacted date
              </Typography>
            </Box>
            <Box sx={{ borderBottom: "1px solid lightgray", padding: 2 }}>
              <Typography variant="subtitle1">Site Report Completed</Typography>
            </Box>
            <Box sx={{ borderBottom: "1px solid lightgray", padding: 2 }}>
              <FormTextField
                InputProps={{ endAdornment: "Days" }}
                type="number"
                control={control}
                name="serviceLevelAgreement.siteReportCompleted"
              />
            </Box>
            <Box sx={{ borderBottom: "1px solid lightgray", padding: 2 }}>
              <Typography variant="subtitle1">After site visit date</Typography>
            </Box>
            <Box sx={{ borderBottom: "1px solid lightgray", padding: 2 }}>
              <Typography variant="subtitle1">
                Site Report Completed - Urgent
              </Typography>
            </Box>
            <Box sx={{ borderBottom: "1px solid lightgray", padding: 2 }}>
              <FormTextField
                InputProps={{ endAdornment: "Days" }}
                type="number"
                control={control}
                name="serviceLevelAgreement.siteReportCompletedUrgent"
              />
            </Box>
            <Box sx={{ borderBottom: "1px solid lightgray", padding: 2 }}>
              <Typography variant="subtitle1">
                After site visit date{" "}
              </Typography>
            </Box>
          </FormGrid>

          <Button
            variant="contained"
            sx={{ width: 250 }}
            onClick={() => {
              handleSubmit(
                (data) => onSubmit(data),
                (e) => {
                  console.log(e);
                }
              )();
            }}
          >
            Save
          </Button>
        </form>
      </FormProvider>
    </Paper>
  );
};

const FormGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 2fr;
  margin-bottom: 2rem;
`;
