import React from "react";
import { NodeViewWrapper } from "@tiptap/react";

export const ImageResizer = (props: any) => {
  const handler = (mouseDownEvent: React.MouseEvent<HTMLImageElement>) => {
    const parent = (mouseDownEvent.target as HTMLElement).closest(
      ".image-resizer"
    );
    const image = parent?.querySelector("img.postimage") ?? null;
    if (image === null) return;
    const startSize = { x: image.clientWidth, y: image.clientHeight };
    const startPosition = { x: mouseDownEvent.pageX, y: mouseDownEvent.pageY };

    // Calculate the aspect ratio
    const aspectRatio = startSize.x / startSize.y;

    function onMouseMove(mouseMoveEvent: MouseEvent) {
      const deltaX = mouseMoveEvent.pageX - startPosition.x;
      const newWidth = startSize.x + deltaX;
      const newHeight = newWidth / aspectRatio;

      props.updateAttributes({
        width: newWidth,
        height: newHeight,
      });
    }

    function onMouseUp() {
      document.body.removeEventListener("mousemove", onMouseMove);
    }

    document.body.addEventListener("mousemove", onMouseMove);
    document.body.addEventListener("mouseup", onMouseUp, { once: true });
  };
  return (
    <NodeViewWrapper className="image-resizer">
      {props.extension.options.useFigure ? (
        <figure>
          <img {...props.node.attrs} className="postimage" />
        </figure>
      ) : (
        <img {...props.node.attrs} className="postimage" />
      )}
      <span className="resize-trigger" onMouseDown={handler}>
        {props.extension.options.resizeIcon}
      </span>
    </NodeViewWrapper>
  );
};
