import { Stack } from "@mui/material";
import {
  Bar,
  CartesianGrid,
  ComposedChart,
  DefaultLegendContent,
  Legend,
  Line,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

import { type Props as LegendProps } from "recharts/types/component/DefaultLegendContent";
import { formatDate, getStartOfWeek } from "src/lib/date";
import { ChartComingSoon } from "./ChartLoader";

export interface PastHealthData {
  onTime1to2days: number;
  onTime2to3days: number;
  onTime4plusDays: number;
  overdue1to2days: number;
  overdue2to3days: number;
  overdue4plusDays: number;
  date: Date;
}

export interface FutureCapacityData {
  capacity: number;
  date: Date;
}

interface Props {
  pastData: Array<PastHealthData>;
  futureData: Array<FutureCapacityData>;
  startingWeekCapacity: number;
  isComingSoon?: boolean;
}

interface DataPoint {
  onTime1to2days: number | null;
  onTime2to3days: number | null;
  onTime4plusDays: number | null;
  overdue1to2days: number | null;
  overdue2to3days: number | null;
  overdue4plusDays: number | null;
  capacity: number | null;
  label: string;
}

export const ActiveAndOverdueClaimHealth = ({
  pastData,
  futureData,
  startingWeekCapacity,
  isComingSoon,
}: Props) => {
  const startOfTheWeek = getStartOfWeek(new Date());
  const data: Array<DataPoint> = [];
  for (const pastDataPoint of pastData) {
    data.push({
      onTime1to2days: pastDataPoint.onTime1to2days,
      onTime2to3days: pastDataPoint.onTime2to3days,
      onTime4plusDays: pastDataPoint.onTime4plusDays,
      overdue1to2days: pastDataPoint.overdue1to2days,
      overdue2to3days: pastDataPoint.overdue2to3days,
      overdue4plusDays: pastDataPoint.overdue4plusDays,
      capacity: null,
      label: formatDate(pastDataPoint.date),
    });
  }

  // data.push({
  //   onTime1to2days: null,
  //   onTime2to3days: null,
  //   onTime4plusDays: null,
  //   overdue1to2days: null,
  //   overdue2to3days: null,
  //   overdue4plusDays: null,
  //   capacity: null,
  //   label: "Now",
  // });

  for (const futureDataPoint of futureData) {
    data.push({
      onTime1to2days: null,
      onTime2to3days: null,
      onTime4plusDays: null,
      overdue1to2days: null,
      overdue2to3days: null,
      overdue4plusDays: null,
      capacity: futureDataPoint.capacity,
      label: formatDate(futureDataPoint.date),
    });
  }

  //Attch the starting week capacity to the first data point
  data.forEach((dataPoint) => {
    if (dataPoint.label === formatDate(startOfTheWeek)) {
      dataPoint.capacity = startingWeekCapacity;
      return;
    }
  });

  return (
    <div style={{ width: "100%", marginTop: "1.5rem", position: "relative" }}>
      {isComingSoon && <ChartComingSoon />}
      <ResponsiveContainer width="100%" height={300}>
        <ComposedChart data={data}>
          <CartesianGrid horizontal={false} fill={"#EFEFEF"} />
          <XAxis
            dataKey={(x: DataPoint) => x.label}
            xAxisId={"middleBand"}
            height={40}
            tickSize={0}
            tickMargin={15}
            interval={0}
          />
          <XAxis
            dataKey={(x: DataPoint) => x.label}
            height={40}
            scale={"band"}
            tickSize={0}
            tickMargin={15}
            interval={0}
            hide
          />
          {/* <XAxis dataKey={"label"} height={40} tickSize={0} tickMargin={15} /> */}
          <YAxis tickSize={0} tickMargin={5} />
          <Tooltip />
          <Bar
            dataKey="onTime1to2days"
            name="Active claims 1-2 days from being overdue"
            stackId="a"
            fill={"#A6CCF8"}
          />
          <Bar
            dataKey="onTime2to3days"
            name="Active claims 2-3 days from being overdue"
            stackId="a"
            fill={"#5E99DE"}
          />
          <Bar
            dataKey="onTime4plusDays"
            name="Active claims 4+ days from being overdue"
            stackId="a"
            fill={"#0067DF"}
          />
          <Bar
            dataKey="overdue1to2days"
            name="Active claims overdue by 1-2 days"
            stackId="a"
            fill={"#FEDA8B"}
          />
          <Bar
            dataKey="overdue2to3days"
            name="Active claims overdue by 2-3 days"
            stackId="a"
            fill={"#FDB366"}
          />
          <Bar
            dataKey="overdue4plusDays"
            name="Active claims overdue by 4+ days"
            stackId="a"
            fill={"#F67E4B"}
          />
          <Line
            xAxisId={"middleBand"}
            type="step"
            dataKey="capacity"
            name="Estimated Capacity"
            stroke="#8BB1DE"
            strokeWidth={3}
            activeDot={{ r: 8 }}
          />
          <ReferenceLine
            x={formatDate(startOfTheWeek)}
            stroke={"#777777"}
            label={{ value: "Now", angle: -90, position: "center", dx: -10 }}
          />
          <Legend
            content={renderLegend}
            layout="vertical"
            iconType="circle"
            wrapperStyle={{ width: "100%" }}
          />
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
};

const renderLegend = (props: LegendProps) => {
  const { payload } = props;
  const { ref, ...propsToPass } = props;

  return (
    <Stack
      direction={"row"}
      justifyContent={"space-between"}
      paddingRight={"15rem"}
    >
      <div>
        <h4 style={{ paddingLeft: "1.2rem" }}>On-time claims for the period</h4>
        <DefaultLegendContent
          {...propsToPass}
          payload={payload?.filter((x) => {
            // built in types are incomplete
            const p = x as unknown as { dataKey: string };
            return p.dataKey.includes("onTime");
          })}
          layout="vertical"
        />
      </div>
      <div style={{ borderLeft: "1px solid lightgray", paddingLeft: "2rem" }}>
        <h4 style={{ paddingLeft: "1.2rem" }}>Overdue claims</h4>
        <DefaultLegendContent
          {...propsToPass}
          payload={payload?.filter((x) => {
            // built in types are incomplete
            const p = x as unknown as { dataKey: string };
            return p.dataKey.includes("overdue");
          })}
          layout="vertical"
        />
      </div>
      <div style={{ borderLeft: "1px solid lightgray", paddingLeft: "2rem" }}>
        <h4 style={{ paddingLeft: "1.2rem" }}>Estimated capacity</h4>
        <DefaultLegendContent
          {...propsToPass}
          payload={payload?.filter((x) => {
            // built in types are incomplete
            const p = x as unknown as { dataKey: string };
            return p.dataKey.includes("capacity");
          })}
          layout="vertical"
        />
      </div>
    </Stack>
  );

  // return (
  //   <ul>
  //     {(payload || []).map((entry, index) => (
  //       <li key={`item-${index}`}>{entry.value}</li>
  //     ))}
  //   </ul>
  // );
};
