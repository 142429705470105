import { Event, EventSchema } from "@athena/server/src/api/types/event";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Paper,
  Typography,
  Divider,
  Stack,
  Button,
  TextField,
  Chip,
} from "@mui/material";
import { Box } from "@mui/system";
import { DatePicker } from "@mui/x-date-pickers";
import { FormProvider, useForm } from "react-hook-form";
import { useQuery, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import { axiosClient } from "src/lib/axiosClient";
import { formatDate } from "src/lib/date";
import { FormDatePicker } from "src/shared/form/FormDatePicker";
import { FormTextField } from "src/shared/form/FormTextField";
import { LoadingSpinner } from "@athena/components";
import {
  enqueueSavingSnackbar,
  enqueueSuccessSnackbar,
} from "src/shared/snackbar/SnackbarHelper";

export const EventDetails = () => {
  const { eventId } = useParams();
  const { isLoading: loadingEvent, data: event } = useQuery(
    `event-${eventId}`,
    async (): Promise<Event> =>
      (await axiosClient.get(`/events/global/${eventId}`)).data
  );

  if (loadingEvent) return <LoadingSpinner />;

  return (
    <Paper
      elevation={4}
      sx={{
        padding: "1.5rem",
        mb: "1.5rem",
        display: "flex",
        flexWrap: "wrap",
        flexDirection: "column",
        mt: 4,
      }}
    >
      <Typography variant="h2" component="h2">
        {event?.name}
      </Typography>
      <Divider sx={{ mb: 2, mt: 2 }} />
      <Stack
        direction={"row"}
        sx={{
          maxWidth: "1000px",
          mb: 2,
        }}
      >
        <Box sx={{ mr: 8 }}>
          <Typography variant="subtitle1">
            <strong>Event Date</strong>
          </Typography>
          <Typography variant="subtitle1">
            {event?.dateOfEvent
              ? formatDate(new Date(event?.dateOfEvent))
              : "Not specified"}
          </Typography>
        </Box>
        <Box>
          <Typography variant="subtitle1">
            <strong>Status</strong>
          </Typography>
          <Chip
            label={event?.status}
            sx={{
              backgroundColor:
                event?.status === "Active" ? "#D7FFD7" : undefined,
            }}
          />
        </Box>
      </Stack>
    </Paper>
  );
};
