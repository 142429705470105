import Map from "ol/Map.js";
// import TileLayer from "ol/layer/Tile.js";
import { WebGLTile } from "ol/layer";
import View from "ol/View.js";
import XYZ from "ol/source/XYZ.js";
import { useEffect, useState } from "react";
import { store } from "./lib/storage";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import GeoJSON from "ol/format/GeoJSON";

export const MapTest = () => {
  const [img, setImg] = useState("");
  useEffect(() => {
    (async () => {
      const imgstr = await store.get("layer-13-8072-5000");
      setImg(imgstr);
    })();
  }, []);
  useEffect(() => {
    // DRAW OUR TEST EXTENT -
    // Polygon is [19454121.1, -4427138.8,  19460859.7, -4423298.3] (3857) which is the same as [174.759263, -36.914154, 174.819717, -36.886533] (4326)
    const a = {
      type: "FeatureCollection",
      crs: {
        type: "name",
        properties: {
          name: "EPSG:4326",
        },
      },
      features: [
        {
          type: "Feature",
          geometry: {
            type: "Polygon",
            coordinates: [
              [
                [19454121.1, -4427138.8],
                [19454121.1, -4423298.3],
                [19460859.7, -4423298.3],
                [19460859.7, -4427138.8],
                [19454121.1, -4427138.8],
              ],
            ],
          },
          properties: {},
        },
      ],
    };
    const extent_layer = new VectorLayer({
      source: new VectorSource({
        features: new GeoJSON().readFeatures(a),
      }),
    });

    const map = new Map({
      target: "map",
      layers: [
        // nzAerialBasemap,
        new WebGLTile({
          source: new XYZ({
            tileUrlFunction: (coord) => {
              return "";
            },
            // todo, tile doesn't have the getImage function on its type even though it exists
            tileLoadFunction: async (tile: any, src) => {
              const coords = tile.getTileCoord();

              // console.log(coords);
              const data = await store.get(
                `layer-${coords[0]}-${coords[1]}-${coords[2]}`
              );
              // if (data != null) {
              // console.log(data);
              const new_blob = new Blob([data], { type: "image/webp" });
              const url = URL.createObjectURL(new_blob);
              // console.log(tile);
              tile.getImage().src = url;
              // }
            },
          }),
        }),
        extent_layer,
      ],
      view: new View({
        center: [19456779.2, -4425188.5],
        zoom: 12,
      }),
    });
  }, []);
  return (
    <>
      <div id="map" className="map" style={{ width: 1500, height: 500 }}></div>
      <button>Download Tiles</button>
    </>
  );
};
