import {
  ClaimDetailsSection,
  DialogSection,
  DialogSectionBody,
  DialogSectionContentVertical,
  DialogSectionHeader,
} from "@athena/components";
import { Box, Chip, Paper, Stack } from "@mui/material";
import { format } from "date-fns";
import { inferRouterOutputs } from "@trpc/server";
import { TRPCRootRouter } from "@athena/server/src/trpc";

interface Props {
  claim: inferRouterOutputs<TRPCRootRouter>["claims"]["getClaim"];
}

export function EventDetails({ claim }: Props) {
  return (
    <Box
      sx={{
        flexGrow: 1,
        mb: 3,
      }}
    >
      <Paper
        elevation={4}
        sx={{
          padding: "1.5rem",
          mb: "1.5rem",
          scrollMarginTop: "5.5rem",
        }}
        id="event"
      >
        <ClaimDetailsSection title="Event details">
          <Stack
            direction={"column"}
            sx={{
              maxWidth: "1000px",
              mb: 2,
            }}
          >
            <DialogSection>
              <DialogSectionContentVertical>
                <DialogSectionHeader>Events</DialogSectionHeader>
                <DialogSectionBody>
                  <Box
                    sx={{
                      mt: "0.5rem",
                      display: "flex",
                      gap: "0.5rem",
                    }}
                  >
                    {" "}
                    {claim.events?.map((x) => (
                      <Chip key={x.id} label={x.name} />
                    ))}
                  </Box>
                </DialogSectionBody>
              </DialogSectionContentVertical>
            </DialogSection>
            <DialogSection>
              <DialogSectionContentVertical>
                <DialogSectionHeader>Regional coordinator</DialogSectionHeader>
                <DialogSectionBody>
                  {claim.regionalCoordinator.name ? (
                    claim.regionalCoordinator.name
                  ) : (
                    <p style={{ color: "gray" }}>--</p>
                  )}
                </DialogSectionBody>
              </DialogSectionContentVertical>
            </DialogSection>
            <DialogSection>
              <DialogSectionContentVertical>
                <DialogSectionHeader>Urgency</DialogSectionHeader>
                <DialogSectionBody>
                  {claim.urgency ? (
                    claim.urgency
                  ) : (
                    <p style={{ color: "gray" }}>--</p>
                  )}
                </DialogSectionBody>
              </DialogSectionContentVertical>
            </DialogSection>
            <DialogSection>
              <DialogSectionContentVertical>
                <DialogSectionHeader>Loss cause</DialogSectionHeader>
                <DialogSectionBody>
                  {claim.lossCause.name ? (
                    claim.lossCause.name
                  ) : (
                    <p style={{ color: "gray" }}>--</p>
                  )}
                </DialogSectionBody>
              </DialogSectionContentVertical>
            </DialogSection>
            <DialogSection>
              <DialogSectionContentVertical>
                <DialogSectionHeader>Loss date (BAU only)</DialogSectionHeader>
                <DialogSectionBody>
                  {(claim.bauLossDate &&
                    format(new Date(claim.bauLossDate), "dd/MM/yy")) || (
                    <p style={{ color: "gray" }}>--</p>
                  )}
                </DialogSectionBody>
              </DialogSectionContentVertical>
            </DialogSection>
            <DialogSection>
              <DialogSectionContentVertical>
                <DialogSectionHeader>Service request date</DialogSectionHeader>
                <DialogSectionBody>
                  {(claim.serviceRequestDate &&
                    format(new Date(claim.serviceRequestDate), "dd/MM/yy")) || (
                    <p style={{ color: "gray" }}>--</p>
                  )}
                </DialogSectionBody>
              </DialogSectionContentVertical>
            </DialogSection>
            <DialogSection>
              <DialogSectionContentVertical>
                <DialogSectionHeader>Associated claims</DialogSectionHeader>
                <DialogSectionBody>
                  {claim.associatedClaims ? (
                    claim.associatedClaims
                  ) : (
                    <p style={{ color: "gray" }}>--</p>
                  )}
                </DialogSectionBody>
              </DialogSectionContentVertical>
            </DialogSection>
          </Stack>
        </ClaimDetailsSection>
      </Paper>
    </Box>
  );
}
