import { AdditionalStatus } from "@athena/server/src/api/types/claimStatuses";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Link } from "react-router-dom";
import { InlineUser } from "src/shared/user/InlineUser";

type Stats = {
  userId?: string;
  lossAdjusterId?: string;
  organisationId?: string;
  inProgress: number;
  inReview: number;
  overdue: number;
  complete: number;
};
type ClaimBucketsTableProps = {
  userLabel?: string;
  filterProperty?: keyof Stats;
  urlFilterProperty?: keyof Stats;
  stats: {
    [key: string]: Stats;
  };
};

export function ClaimBucketsTable(props: ClaimBucketsTableProps) {
  const {
    stats,
    userLabel = "User",
    urlFilterProperty = "assignedEngineerId",
    filterProperty = "userId",
  } = props;
  return (
    <TableContainer component={Paper} sx={{ boxShadow: "none" }}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>{userLabel}</TableCell>
            <TableCell align="right">Pre site visit</TableCell>
            <TableCell align="right">Post site visit</TableCell>
            <TableCell align="right">Overdue</TableCell>
            <TableCell align="right">Complete</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.entries(stats).map(([key, value]) => {
            return (
              <TableRow
                key={key}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  <Link
                    to={`/engineering-claims?${urlFilterProperty}=${value[filterProperty]}&filteredForName=${key}`}
                  >
                    <InlineUser name={key} />
                  </Link>
                </TableCell>

                <TableCell align="right">
                  <Link
                    to={`/engineering-claims?${urlFilterProperty}=${value[filterProperty]}&statusGroup=pre-visit&filteredForName=${key}`}
                  >
                    {value.inProgress}
                  </Link>
                </TableCell>
                <TableCell align="right">
                  <Link
                    to={`/engineering-claims?${urlFilterProperty}=${value[filterProperty]}&statusGroup=post-visit&filteredForName=${key}`}
                  >
                    {value.inReview}
                  </Link>
                </TableCell>
                <TableCell align="right">
                  <Link
                    to={`/engineering-claims?${urlFilterProperty}=${value[filterProperty]}&additionalStatuses=${AdditionalStatus.Overdue},${AdditionalStatus.AutoOverdue}&filteredForName=${key}`}
                  >
                    {value.overdue}
                  </Link>
                </TableCell>
                <TableCell align="right">
                  {" "}
                  <Link
                    to={`/engineering-claims?${urlFilterProperty}=${value[filterProperty]}&includeDone=true&statusGroup=complete&filteredForName=${key}`}
                  >
                    {value.complete}
                  </Link>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

// const StaffBadge = styled.div`
//   display: flex;
//   flex-direction: row;
//   gap: 1rem;

//   img {
//     border-radius: 120px;
//     height: 3rem;
//     width: 3rem;
//     object-fit: cover;
//   }
// `;
