import { store } from "../../lib/storage";
import { useEffect, useState } from "react";
import { axiosClient } from "src/lib/axiosClient";
import { useNetwork } from "ahooks";
import { UpdateClaim } from "@athena/server/src/api/types/claimAssessment";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  enqueueSavingSnackbar,
  enqueueSuccessSnackbar,
  enqueueErrorSnackbar,
} from "src/shared/snackbar/SnackbarHelper";
import { CreateClaim } from "@athena/server/src/api/types/claim";
import { v4 as uuid } from "uuid";
import { useNavigate } from "react-router-dom";
import { create } from "zustand";
import { Feature } from "ol";

import { useOfflineFeatureMutations } from "./Maps/hooks/useOfflineFeatureMutations";
import { useNetworkContext } from "src/shared/hooks/useNetworkContext";
import { config } from "src/lib/config";
import { backupClaimData, uploadFile } from "./utils";
import { useAttachmentUploads } from "./hooks/useAttachmentUpload";
import { dataURLToBlob } from "src/shared/dataUtils/base64ToBlob";
import { CapacitorHttp } from "@capacitor/core";
import * as Sentry from "@sentry/react";

const claimCache = new Map();

// TODO improve typedef
type ExistingClaim = { claimId: string } & CreateClaim;

const isUpdatingClaim = (
  claim: CreateClaim | ExistingClaim
): claim is UpdateClaim => {
  return (claim as ExistingClaim).claimId !== undefined;
};

type Layer = {
  layer: { features: Feature[]; url: string };
};

type ClaimState = {
  claim?: UpdateClaim;
  setClaim: (claim: UpdateClaim) => void;
  needsSync: boolean;
  formIsDirty: boolean;
  setNeedsSync: (needsSync: boolean) => void;
  setFormIsDirty: (formIsDirty: boolean) => void;
  layers: Record<string, Layer>;
  updateLayers: (features: Record<string, Layer>) => void;
};

export const useClaimStore = create<ClaimState>((set) => ({
  claim: undefined,
  setClaim: (claim: UpdateClaim) => set(() => ({ claim })),
  needsSync: false,
  formIsDirty: false,
  setFormIsDirty: (formIsDirty: boolean) => set(() => ({ formIsDirty })),
  setNeedsSync: (needsSync: boolean) => set(() => ({ needsSync })),
  layers: {},
  updateLayers: (layers: Record<string, Layer>) => set(() => ({ layers })),
}));

/**
 * This hook is used to manage the offline claim state.
 * It handles offline claim details, you can't create a claim offline, but you can edit one.
 * It also handles offline assessment tab, same as details.
 * It handles offline maps by caching the features and layers in local storage.
 */

export const useOfflineClaim = (claimId?: string) => {
  const { online } = useNetworkContext();
  const needsSync = useClaimStore((state) => state.needsSync);
  const formIsDirty = useClaimStore((state) => state.formIsDirty);
  const setNeedsSync = useClaimStore((state) => state.setNeedsSync);
  const setFormIsDirty = useClaimStore((state) => state.setFormIsDirty);
  const cachedClaim: UpdateClaim | undefined = useClaimStore(
    (state) => state.claim
  );
  const setCachedClaim = useClaimStore((state) => state.setClaim);
  const [loading, setLoading] = useState(!claimCache.get(claimId));
  const [attachmentsToSave, setAttachmentsToSave] = useState<
    { file: File; data: string }[]
  >([]);
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { sync } = useOfflineFeatureMutations({ claimId: claimId || "" });
  const { uploadAttachment } = useAttachmentUploads(`claim${claimId}`);
  useEffect(() => {
    const loadClaimFromStore = async () => {
      let claim = claimCache.get(claimId);
      if (!claim) {
        claim = JSON.parse(await store.get(`claim-${claimId}`));
        if (!claim) {
          const claims: UpdateClaim[] = JSON.parse(await store.get("claims"));
          claim = claims.find((c) => c.claimId === claimId);
        }
        claimCache.set(claimId, claim);
      }
      const parsedNeedsSync = JSON.parse(
        await store.get(`claim-${claimId}-unsynced-changes`)
      );

      setNeedsSync(parsedNeedsSync);
      setCachedClaim(claim);
      setLoading(false);
    };

    if (claimId) {
      loadClaimFromStore();
    }
  }, [claimId]);

  const { isLoading, data } = useQuery(
    `claim${claimId}`,
    async (): Promise<UpdateClaim> => {
      const claim = (await axiosClient.get(`/claims/${claimId}`)).data;
      const parsedNeedsSync = JSON.parse(
        await store.get(`claim-${claimId}-unsynced-changes`)
      );
      if (!parsedNeedsSync) {
        // Don't update cache if we need to sync
        await store.set(`claim-${claimId}`, JSON.stringify(claim));
        const existingClaims = JSON.parse(await store.get("claims"));
        const claimCached = (existingClaims || []).find(
          (c: UpdateClaim) => c.claimId === claimId
        );
        await store.set(
          "claims",
          JSON.stringify(
            claimCached
              ? existingClaims.map((c: UpdateClaim) => {
                  if (c.claimId === claimId) return claim;
                  return c;
                })
              : [...(existingClaims || []), claim]
          )
        );
      }

      return claim;
    },
    { enabled: online && claimId !== "new" && !needsSync }
  );

  const saveForm = async (claim: CreateClaim | UpdateClaim) => {
    try {
      const finishedSaving = enqueueSavingSnackbar();
      let imagesUploaded = false;
      if (isUpdatingClaim(claim)) {
        try {
          // Because images can be uploaded offline, we have to create those images
          // from their offline blob, and then also upload the annotated image aswell
          // if it exists
          for (const attachment of claim.assessmentAttachments || []) {
            let attachmentId = attachment.assessmentAttachmentId;
            if (attachment.imageUrl?.includes("data:image")) {
              const res = await fetch(attachment.imageUrl);
              const blob = await res.blob();
              attachmentId = (
                await uploadAttachment(
                  new File([blob], attachment.attachmentName, {
                    type: attachment.fileType,
                  }),
                  attachment.featureId,
                  attachment.assessmentAttachmentId
                )
              ).assessmentAttachmentId;
              imagesUploaded = true;
            }
            if (
              attachment.annotationsJSON &&
              attachment.annotatedImageUrl?.includes("data:image")
            ) {
              const formData = new FormData();
              formData.append(
                "annotationsJSON",
                JSON.stringify(attachment.annotationsJSON)
              );
              const blob = dataURLToBlob(attachment.annotatedImageUrl);
              if (!blob) throw new Error("Failed to convert data url to blob");
              const file = new File([blob], "annotatedMap.png", {
                type: "image/png",
              });
              formData.append("image", file);
              const res = await fetch(
                `${config.apiUrl}/claims/${claimId}/attachment/${attachmentId}`,
                {
                  method: "PUT",
                  // dataType: "file",
                  body: formData,
                  credentials: "include",
                  // headers: {
                  //   "Content-Type": "multipart/form-data",
                  // },
                }
              );
              imagesUploaded = true;
            }
          }

          const updatedClaim = {
            ...claim,
            assessmentAttachments: claim.assessmentAttachments?.map(
              (attach) => ({
                ...attach,
                imageUrl: attach.imageUrl?.includes("data:image")
                  ? undefined
                  : attach.imageUrl,
                annotatedImageUrl: attach.annotatedImageUrl?.includes(
                  "data:image"
                )
                  ? undefined
                  : attach.annotatedImageUrl,
              })
            ),
            crossSections: claim.crossSections?.map((cs) => ({
              ...cs,
              imageUrl: cs.imageUrl?.includes("data:image")
                ? undefined
                : cs.imageUrl,
            })),
          };

          const res = await CapacitorHttp.request({
            method: "PUT",
            url: `${config.apiUrl}/claims/${claim.claimId}`,
            data: JSON.stringify(updatedClaim),
            webFetchExtra: { credentials: "include" },
            headers: {
              "Content-Type": "application/json",
            },
          });

          for (const crossSection of claim.crossSections || []) {
            if (crossSection.imageUrl?.includes("data:image")) {
              const blob = dataURLToBlob(crossSection.imageUrl);
              if (!blob) throw new Error("Failed to convert data url to blob");
              const formData = new FormData();

              const annotatedFile = new File([blob], "crosssection.jpg", {
                type: "image/jpeg",
              });
              formData.append("image", annotatedFile);
              formData.append(
                "annotationsJSON",
                JSON.stringify(crossSection.annotationsJSON)
              );
              formData.append(
                "legendItems",
                JSON.stringify(crossSection.legendItems)
              );
              const res = await fetch(
                `${config.apiUrl}/claims/${claimId}/cross-section/${crossSection.claimCrossSectionId}`,
                {
                  method: "PUT",
                  // dataType: "file",
                  body: formData,
                  credentials: "include",
                  // headers: {
                  //   "Content-Type": "multipart/form-data",
                  // },
                }
              );
              imagesUploaded = true;
            }
          }

          finishedSaving();
          enqueueSuccessSnackbar();

          setFormIsDirty(false);
          queryClient.invalidateQueries({
            queryKey: [`claim${claimId}`],
          });
          return { claim: res.data, hasNewImages: imagesUploaded };
        } catch (e) {
          Sentry.withScope((scope) => {
            scope.setContext("claim", {
              claimId,
              claim,
            });
            Sentry.captureException(e);
          });
          enqueueErrorSnackbar(
            "Something went wrong while saving. Please try again or contact Infinity Studio."
          );
          throw e;
        }
      }

      const { insurer, assignedOffice, ...rest } = claim;

      const claimToSend = {
        ...rest,
        claimId: uuid(),
        insurer: insurer?.id ? insurer : undefined,
        assignedOffice: assignedOffice?.id ? assignedOffice : undefined,
      };
      const res = (await axiosClient.post(`/claims`, claimToSend)).data;
      for (const attachment of attachmentsToSave) {
        uploadFile(attachment.file, res.claimId);
      }

      // Create the claim in GIS service so that features can be created immediately in assessment
      await axiosClient
        .post(
          `${config.gisApiUrl}/geo/ath/features/collections/claim_point_v1/items`,
          {
            properties: { claim_id: res.claimId },
            geometry: rest.location.geo,
            type: "Feature",
          }
        )
        .catch((e) => {
          console.log(e);
        });

      finishedSaving();
      enqueueSuccessSnackbar();

      setFormIsDirty(false);
      queryClient.invalidateQueries({
        queryKey: [`claim${claimId}`],
      });
      if (!isUpdatingClaim(claim))
        navigate(`/claim/${claimToSend.claimId}/details`);

      return res;
    } catch (e) {
      Sentry.withScope((scope) => {
        scope.setContext("claim", {
          claimId,
          claim,
        });
        Sentry.captureException(e);
      });
      enqueueErrorSnackbar(
        "Failed to sync a change. Please try again. If it continues to fail, please contact Infinity Studio and we can help resolve the issue."
      );

      if (!claimId) return;

      await backupClaimData(claimId);
      throw e;
    }
  };

  const updateCachedClaim = async (claim: UpdateClaim) => {
    if (claim.claimId) {
      claimCache.set(claimId, claim);
      claimCache.set(`${claimId}-needs-sync`, !online || needsSync);
      await Promise.all([
        store.set(`claim-${claimId}`, JSON.stringify(claim)),
        store.set(
          `claim-${claimId}-unsynced-changes`,
          JSON.stringify(!online || needsSync)
        ),
      ]);
    }

    setCachedClaim(claim);
    setNeedsSync(!online || needsSync);

    if (online && !needsSync) {
      return await saveForm(claim);
    } else {
      setFormIsDirty(false);
      enqueueSuccessSnackbar();
      return { claim, hasNewImages: false };
    }
  };

  const syncClaim = async () => {
    if (!cachedClaim) return;
    await saveForm(cachedClaim);
    const finish = enqueueSavingSnackbar(
      "Syncronizing claim, please do not refresh or close this window"
    );
    try {
      const success = await sync();
      if (!success) {
        finish();
        return; // Leave the claim as unsynced if we failed to sync the features, so that it can be retried
      }
      claimCache.set(`${claimId}-needs-sync`, false);
      await store.set(
        `claim-${claimId}-unsynced-changes`,
        JSON.stringify(false)
      );
      setNeedsSync(false);
      finish();
      enqueueSuccessSnackbar("Successfully syncronized claim");
    } catch (e) {}
  };

  if (online && !needsSync) {
    return {
      loading: isLoading,
      claim: data,
      needsSync,
      saveClaim: updateCachedClaim,
      syncClaim,
      attachmentsToSave,
      setAttachmentsToSave,
      formIsDirty,
      setFormIsDirty,
    };
  }

  return {
    claim: cachedClaim,
    updateCachedClaim,
    saveClaim: updateCachedClaim,
    loading,
    needsSync,
    online,
    syncClaim,
    attachmentsToSave,
    setAttachmentsToSave,
    formIsDirty,
    setFormIsDirty,
  };
};
