import { TReportTemplateStyles } from "@athena/server/src/api/types/reportTemplate";
import { Box } from "@mui/system";
import { Control, FieldValues, Path } from "react-hook-form";
import { BorderStyle } from "./BorderStyle";
import { FontStyle } from "./FontStyle";

interface PhotographStyleProps<T extends FieldValues> {
  type: "photograph" | "photograph.caption";
  control: Control<T>;
  setValue: (path: Path<TReportTemplateStyles>, value: any) => void;
  errors?: any;
}

export const PhotographStyle = (
  props: PhotographStyleProps<TReportTemplateStyles>
) => {
  const { type, control, errors } = props;
  return (
    <div
      style={{
        display: "flex",
        flexWrap: "wrap",
        gap: "1rem",
        paddingLeft: "1rem",
      }}
    >
      {type === "photograph" ? (
        <FontStyle {...props} type={`${type}.font`}>
          <BorderStyle {...props} type="photograph" />
        </FontStyle>
      ) : (
        <Box>
          <FontStyle {...props} type={`${type}`} />
        </Box>
      )}
    </div>
  );
};
