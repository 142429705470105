import { FC } from "react";
import { SmallButton } from "../components/styled";

interface CompassProps {
  onClick: () => void;
  angle: number;
}

export const Compass: FC<CompassProps> = ({ onClick, angle }) => {
  return (
    <SmallButton onClick={onClick}>
      <img
        src="/images/mapping/compass.svg"
        style={{
          transform: `rotate(${angle}deg)`,
        }}
      />
    </SmallButton>
  );
};
