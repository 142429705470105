import { LoadingSpinner } from "@athena/components";
import { Status } from "@athena/server/src/api/types/claimStatuses";
import styled from "@emotion/styled";
import EngineeringIcon from "@mui/icons-material/Engineering";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import ShieldIcon from "@mui/icons-material/Shield";
import { Button, Paper } from "@mui/material";
import Typography from "@mui/material/Typography";
import Box from "@mui/system/Box";
import { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { PageNameContext } from "src/App";
import { trpc } from "src/lib/api/trpc";
import { useCurrentUserContext } from "src/shared/hooks/useCurrentUserContext";
import { ClaimStatusChips } from "../claims/ClaimStatusChips";
import Menu from "../menu/Menu";

export function LossAdjusterHome() {
  const { setPageName } = useContext(PageNameContext);
  useEffect(() => {
    setPageName("Home");
  }, []);
  const { user } = useCurrentUserContext();

  const engineeringClaimsQuery = trpc.claims.getAllClaims.useQuery({});
  const engineeringCompanies =
    trpc.organisations.getEngineeringCompanies.useQuery();

  const awaiting = engineeringClaimsQuery?.data?.filter((claim) =>
    [Status.UnderInternalReview].includes(claim.status as Status)
  );
  const awaitingComment = engineeringClaimsQuery?.data?.filter((claim) =>
    [Status.ClientAccepted].includes(claim.status as Status)
  );

  const approved = engineeringClaimsQuery?.data?.filter((claim) =>
    [Status.ClientAccepted].includes(claim.status as Status)
  );

  const needsEngineer = engineeringClaimsQuery?.data?.filter((claim) =>
    [
      Status.NeedsEngineer,
      Status.WaitingForCustomer,
      Status.VisitBooked,
    ].includes(claim.status as Status)
  );

  const underReview = engineeringClaimsQuery?.data?.filter((claim) =>
    [Status.UnderInternalReview, Status.PreparingDraft].includes(
      claim.status as Status
    )
  );

  const findEngineeringCompany = (id: string) =>
    engineeringCompanies.data?.find((company) => company.organisationId === id)
      ?.name;

  return (
    <>
      <Menu>
        {
          <WelcomeContainer>
            <Typography
              variant="h1"
              component="h1"
              sx={{
                borderBottom: "1px solid lightgray",
                marginBottom: "1.5rem",
                pb: "1.5rem",
              }}
            >
              Welcome back, {user.firstName}
            </Typography>
            <StatContainer>
              <Link to={"/claims?statuses=Needs+Engineer"}>
                <p>With an Engineer</p>

                <h3>{needsEngineer?.length || 10}</h3>

                <p className="link">See your claims</p>
              </Link>
              <Link
                to={"/claims?statuses=Under+Internal+Review%2CPreparing+Draft"}
              >
                <p>Under Review</p>

                <h3>{underReview?.length || 5}</h3>

                <p className="link">See your claims</p>
              </Link>
              <Link to={"/claims"}>
                <p>Awaiting Comment</p>

                <h3>{awaiting?.length || 3}</h3>

                <p className="link">See your claims</p>
              </Link>
              <Link to={"/claims?statuses=Client+Accepted"}>
                <p>Approved</p>

                <h3>{approved?.length || 15}</h3>

                <p className="link">See your claims</p>
              </Link>
            </StatContainer>

            <ClaimContainer>
              <Paper
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  height: "fit-content",
                  mt: 4,
                }}
              >
                <PaperHeading>Claims to awaiting your review:</PaperHeading>
                <Box
                  sx={{
                    width: "100%",
                    display: "grid",
                    gridTemplateColumns: "repeat(2, 1fr)",
                    gap: "1.5rem",
                    alignItems: "center",
                    justifyContent: "center",
                    flex: 1,
                  }}
                >
                  {engineeringClaimsQuery.isLoading ? (
                    <LoadingSpinner />
                  ) : (
                    awaiting?.map((claim) => (
                      <ClaimBucket
                        key={claim.claimId}
                        claim={claim}
                        company={findEngineeringCompany(
                          claim.organisationId || ""
                        )}
                      />
                    ))
                  )}
                </Box>
              </Paper>
            </ClaimContainer>
            <ClaimContainer>
              <Paper
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  height: "fit-content",
                  mt: 4,
                }}
              >
                <PaperHeading>Claims awaiting comment:</PaperHeading>
                <Box
                  sx={{
                    width: "100%",
                    display: "grid",
                    gridTemplateColumns: "repeat(2, 1fr)",
                    gap: "1.5rem",
                    alignItems: "center",
                    justifyContent: "center",
                    flex: 1,
                  }}
                >
                  {engineeringClaimsQuery.isLoading ? (
                    <LoadingSpinner />
                  ) : (
                    awaitingComment?.map((claim) => (
                      <ClaimBucket
                        key={claim.claimId}
                        claim={claim}
                        company={findEngineeringCompany(
                          claim.organisationId || ""
                        )}
                      />
                    ))
                  )}
                </Box>
              </Paper>
            </ClaimContainer>
          </WelcomeContainer>
        }
      </Menu>
    </>
  );
}

export const ClaimBucket = (props: {
  claim: any;
  company: string | undefined;
}) => {
  const { claim, company } = props;
  return (
    <ClaimRequest key={claim.claimId}>
      <ClaimRequestParent>
        <h3>
          <Link to={`/claim/${claim.organisationId}/${claim.claimId}`}>
            Claim {claim.reference}
          </Link>
        </h3>
        <ClaimStatusChips
          status={claim.status as Status}
          additionalStatuses={claim.additionalStatuses || []}
        />
      </ClaimRequestParent>
      <ClaimRequestBody>
        <ClaimRequestInfo>
          <LocationOnOutlinedIcon />
          <p>{claim.location?.address}</p>
        </ClaimRequestInfo>
        <div>
          <ClaimRequestInfoDivider>
            {company && (
              <ClaimRequestInfo>
                <EngineeringIcon />
                <p>{company}</p>
              </ClaimRequestInfo>
            )}

            {claim?.insurer?.name && (
              <ClaimRequestInfo>
                <ShieldIcon />
                <p>{claim?.insurer?.name}</p>
              </ClaimRequestInfo>
            )}
          </ClaimRequestInfoDivider>
        </div>
      </ClaimRequestBody>
      <div></div>

      <ClaimFooter>
        <Button
          variant="outlined"
          sx={{ minWidth: 200 }}
          onClick={() => undefined}
        >
          <span>Request Changes</span>
        </Button>
        <Button
          variant="contained"
          sx={{ minWidth: 200 }}
          onClick={() => undefined}
        >
          <span>Accept</span>
        </Button>
      </ClaimFooter>
    </ClaimRequest>
  );
};

const WelcomeContainer = styled.div`
  max-width: 1600px;
  width: 100%;
  margin: 1.5rem auto;
  padding: 0 1.5rem;
`;

const StatContainer = styled.div`
  display: grid;
  margin: 1.5rem 0;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 1.5rem;
  a {
    padding: 1.25rem 1.5rem;
    background: #ffffff;
    flex: 1;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.12);
    border-radius: 12px;
  }
  p,
  h3 {
    margin: 0;
    color: #333;
  }
  h3 {
    font-size: 2rem;
    margin-top: 0.5rem;
  }
  p.link {
    border-top: 1px solid lightgray;
    color: #0067df;
    margin-top: 0.5rem;
    padding-top: 1rem;
  }
  @media (max-width: 800px) {
    grid-template-columns: 1fr;
  }
  @media (max-width: 600px) {
    grid-template-columns: 1fr;
  }
  a:hover {
    .link {
      text-decoration: underline;
    }
  }
`;

const ClaimContainer = styled.div`
  display: grid;

  grid-template-columns: 1fr;
  gap: 1.5rem;

  .MuiPaper-root {
    padding: 1.5rem;
  }
  @media (max-width: 800px) {
    grid-template-columns: 1fr;
  }
  @media (max-width: 600px) {
    grid-template-columns: 1fr;
  }
`;
const PaperHeading = styled.h4`
  font-size: 1.25rem;
  font-weight: 600;
  border-bottom: 1px solid lightgray;
  padding-bottom: 1.25rem;
  margin-bottom: 1.25rem;
`;

const ClaimRequest = styled.div`
  border: 1px solid lightgray;
  border-radius: 12px;
`;

const ClaimRequestBody = styled.div`
  display: flex;
  padding: 1rem;
  gap: 1rem;
  flex-direction: column;
`;
const ClaimRequestParent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  padding: 1rem;
  border-bottom: 1px solid lightgray;
  align-items: center;

  @media (max-width: 600px) {
    flex-direction: column;
    align-items: start;
    gap: 1rem;
  }
`;

const ClaimRequestInfoDivider = styled.div`
  display: flex;
  flex-direction: row;
  gap: 2rem;
  @media (max-width: 600px) {
    flex-direction: column;
    gap: 1rem;
  }
`;

const ClaimRequestInfo = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;
  a {
    font-weight: 500;
  }
`;

const ClaimFooter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-top: 1px solid lightgray;
  padding: 1rem;
  @media (max-width: 600px) {
    flex-direction: column;
    gap: 1rem;
    button {
      order: 2;
    }
  }
`;
