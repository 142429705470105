import styled from "@emotion/styled";
import { Divider, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";
import { InlineUser } from "src/shared/user/InlineUser";

export type ClaimCommentRepliesProps = {
  replies: any[];
  threadResolved: boolean;
};

export const ClaimCommentReplies = (props: ClaimCommentRepliesProps) => {
  const { replies, threadResolved } = props;
  const [showReplies, setShowReplies] = useState(!threadResolved);
  return (
    <>
      {showReplies &&
        replies.map((reply) => {
          return (
            <>
              <MessageContainer
                key={reply.claimCommentId}
                id={`comment-${reply.claimCommentId}`}
              >
                <Box sx={{ mb: 1 }}>
                  <InlineUser name={reply.author.name} />
                </Box>

                {reply.message}
              </MessageContainer>
            </>
          );
        })}
      {showReplies && <Divider />}
      <Typography
        variant="subtitle2"
        sx={{ color: "#0067DF", cursor: "pointer", pl: 3, mb: 1, mt: 1 }}
        onClick={() => {
          setShowReplies(!showReplies);
        }}
        
      >
        {showReplies ? "Hide replies" : `Show replies (${replies.length})`}
      </Typography>
    </>
  );
};

const MessageContainer = styled.div`
  display: flex;
  padding: 1.5rem;
  flex-direction: column;
`;
