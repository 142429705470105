import {
  ClaimDetailsSection,
  DialogSection,
  DialogSectionBody,
  DialogSectionContentVertical,
  DialogSectionHeader,
} from "@athena/components";
import { Box, Paper, Stack } from "@mui/material";
import { inferRouterOutputs } from "@trpc/server";
import { TRPCRootRouter } from "@athena/server/src/trpc";

interface Props {
  claim: inferRouterOutputs<TRPCRootRouter>["claims"]["getClaim"];
}

export function Insurer({ claim }: Props) {
  return (
    <Box
      sx={{
        flexGrow: 1,
        mb: 3,
      }}
    >
      <Paper
        elevation={4}
        sx={{
          padding: "1.5rem",
          mb: "1.5rem",
          scrollMarginTop: "5.5rem",
        }}
        id="insurer"
      >
        <ClaimDetailsSection title="Insurer details">
          <Stack
            direction={"column"}
            sx={{
              maxWidth: "1000px",
              mb: 2,
            }}
          >
            <DialogSection>
              <DialogSectionContentVertical>
                <DialogSectionHeader>
                  Insurance claim number
                </DialogSectionHeader>
                <DialogSectionBody>
                  {claim.insurerReference ? (
                    claim.insurerReference
                  ) : (
                    <p style={{ color: "gray" }}>--</p>
                  )}
                </DialogSectionBody>
              </DialogSectionContentVertical>
            </DialogSection>
            <DialogSection>
              <DialogSectionContentVertical>
                <DialogSectionHeader>Insurer</DialogSectionHeader>
                <DialogSectionBody>
                  {claim.insurer?.name ? (
                    claim.insurer?.name
                  ) : (
                    <p style={{ color: "gray" }}>--</p>
                  )}
                </DialogSectionBody>
              </DialogSectionContentVertical>
            </DialogSection>
          </Stack>
        </ClaimDetailsSection>
      </Paper>
    </Box>
  );
}
