import Button from "@mui/material/Button";

import TextField from "@mui/material/TextField";
import { FC, useEffect } from "react";
import { Autocomplete } from "@mui/material";
import { Stack } from "@mui/system";
import { DialogSection, DialogSectionHeader } from "@athena/components";
import { z } from "zod";
import {
  Control,
  Controller,
  FieldValues,
  Path,
  useForm,
  useWatch,
} from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { CreateInsurancePolicy } from "@athena/server/src/trpc/routers/insurancePolicies/schema";
import { PreviewImportTable } from "./PreviewImportTable";

export enum Column {
  PolicyReference = "policyReference",
  Address = "address",
  Status = "status",
}

const ColumnLabels: Record<Column, string> = {
  [Column.PolicyReference]: "Policy Ref",
  [Column.Address]: "Address",
  [Column.Status]: "Status",
};

const CSVHeadersSchema = z.record(z.nativeEnum(Column), z.string());

export type CSVHeaders = z.infer<typeof CSVHeadersSchema>;

interface AutocompleteFieldProps<TControl extends FieldValues> {
  control: Control<TControl, any>;
  label: string;
  name: Path<TControl>;
  placeholder: string;
  options: Array<string>;
}

function AutocompleteField<TControl extends FieldValues>({
  control,
  label,
  placeholder,
  name,
  options,
}: AutocompleteFieldProps<TControl>) {
  return (
    <Controller
      render={({ field, fieldState }) => {
        const { onChange, ...props } = field;
        return (
          <Autocomplete
            options={options}
            filterSelectedOptions
            {...props}
            onChange={(e, data) => onChange(data)}
            renderInput={(params) => (
              <TextField
                sx={{
                  marginTop: "1rem",
                  ".MuiInputBase-root": {
                    width: "400px",
                    height: "48px",
                    padding: "6px",
                  },
                  ".MuiChip-root": {
                    height: "28px",
                  },
                  ".MuiInputBase-input": {},
                }}
                {...params}
                label={label}
                InputLabelProps={{ shrink: true }}
                placeholder={placeholder}
                error={fieldState.invalid}
              />
            )}
          />
        );
      }}
      name={name}
      control={control}
    />
  );
}

interface MapColumnsProps {
  options: Array<string>;
  policies: Array<CreateInsurancePolicy>;
  onChange: (values: CSVHeaders) => void;
  onSubmit: (values: CSVHeaders) => void;
  defaultMappedHeaders: CSVHeaders;
}

export const MapColumns: FC<MapColumnsProps> = ({
  options,
  onChange,
  onSubmit,
  policies,
  defaultMappedHeaders,
}) => {
  const {
    handleSubmit,
    control,
    formState: { errors, isDirty, isValid, isSubmitted },
    // reset,
    setValue,
  } = useForm<CSVHeaders>({
    resolver: zodResolver(CSVHeadersSchema),
    defaultValues: defaultMappedHeaders,
  });

  const formValues = useWatch({ control });
  useEffect(() => {
    onChange(formValues);
  }, [formValues]);

  const shouldShowError = isSubmitted && !isValid;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <DialogSection>
        <DialogSectionHeader>Choose column mapping</DialogSectionHeader>
        <Stack direction={"column"}>
          <Stack direction={"row"}>
            {[Column.PolicyReference, Column.Status].map((column) => (
              <AutocompleteField
                key={column}
                name={column}
                control={control}
                label={ColumnLabels[column]}
                placeholder={`Set ${ColumnLabels[column]}`}
                options={options}
              />
            ))}
          </Stack>
          <Stack direction={"row"}>
            {[Column.Address].map((column) => (
              <AutocompleteField
                key={column}
                name={column}
                control={control}
                label={ColumnLabels[column]}
                placeholder={`Set ${ColumnLabels[column]}`}
                options={options}
              />
            ))}
          </Stack>
          {shouldShowError && (
            <p style={{ color: "#d32f2f" }}>
              Make sure to map all required columns
            </p>
          )}
        </Stack>
      </DialogSection>
      <DialogSection>
        <PreviewImportTable rows={policies} />
      </DialogSection>
      <DialogSection>
        <Stack
          direction={"row"}
          justifyContent={"space-around"}
          sx={{
            paddingTop: "0.5rem",
          }}
        >
          <Button
            variant="contained"
            type="submit"
            sx={{
              width: "240px",
            }}
            disabled={shouldShowError}
          >
            <span>Import</span>
          </Button>
        </Stack>
      </DialogSection>
    </form>
  );
};
