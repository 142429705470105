import { Urgency } from "@athena/server/src/api/types/claim";
import { Status } from "@athena/server/src/api/types/claimStatuses";
import { z } from "zod";

export interface IntelligencerFilter {
  insuranceClaimNo?: string;
  statuses?: Array<Status>;
  additionalStatuses?: Array<string>;
  urgency?: Urgency;
  eventIds?: Array<string>;
  lossAdjuster?: string;
  assignedOffice?: string;
  region?: string;
  startDate?: Date;
  endDate?: Date;
}

export enum IntelligencerPit {
  ClaimCount = "claim-count",
  ClaimStatus = "claim-status",
}

const statusCountUnitSchema = z.object({
  "1": z.number(),
  "2": z.number(),
  "3": z.number(),
  "4+": z.number(),
});

export const pitSchemas = {
  [IntelligencerPit.ClaimCount]: z.record(
    z.string(),
    z.object({
      total_completed: z.number().nullable(),
      started_cumulative: z.number().nullable(),
    })
  ),
  [IntelligencerPit.ClaimStatus]: z.record(
    z.string(),
    z.record(
      z.string(),
      z.object({
        onTime: z.record(z.string(), z.number().catch(0)),
        onDueDate: z.number().catch(0),
        overdue: z.record(z.string(), z.number().catch(0)),
      })
    )
  ),
};

export type InferPitResponseType<TPit extends IntelligencerPit> = z.infer<
  (typeof pitSchemas)[TPit]
>;
