// When adding new variables, make sure you register it in LockedValue.tsx as well, which is what is used to display the values in the editor.
export const headerNumber = {
  label: "Header Number",
  value: "header_number",
  redirectPage: "no-edit",
  description: "",
  hasConfig: false,
};
export const clientId = {
  label: "Client ID",
  value: "client_id",
  redirectPage: "no-edit",
  description: "",
  hasConfig: false,
};
export const address = {
  label: "Address",
  value: "address",
  redirectPage: "details",
  description: "",
  hasConfig: false,
};
export const addressRegion = {
  label: "Address Region",
  value: "address_region",
  redirectPage: "details",
  description: "",
  hasConfig: false,
};
export const addressRegionEx = {
  label: "Address Region Extended",
  value: "address_region_ex",
  redirectPage: "details",
  description: "",
  hasConfig: false,
};
export const jobNumber = {
  label: "Job No",
  value: "job_number",
  redirectPage: "details",
  description: "",
  hasConfig: false,
};
export const dateExported = {
  label: "Date of issue",
  value: "date_export",
  redirectPage: "no-edit",
  description: "",
  hasConfig: false,
};
export const modifiedDate = {
  label: "Last modified date",
  value: "modified_date",
  redirectPage: "no-edit",
  description: "",
  hasConfig: false,
};
export const engagedName = {
  label: "Engaged Name",
  value: "engaged_name",
  redirectPage: "details",
  description: "",
  hasConfig: false,
};
export const lossAdjusterEmail = {
  label: "Loss Adjuster Email",
  value: "loss_adjuster_email",
  redirectPage: "details",
  description: "",
  hasConfig: false,
};
export const lossAdjusterName = {
  label: "Loss Adjuster Name",
  value: "loss_adjuster_name",
  redirectPage: "details",
  description: "",
  hasConfig: false,
};
export const lossAdjusterCompanyName = {
  label: "Loss Adjuster Company",
  value: "loss_adjuster_company_name",
  redirectPage: "details",
  description: "",
  hasConfig: false,
};
export const insurerCompany = {
  label: "Insurer Company",
  value: "insurer_company",
  redirectPage: "details",
  description: "",
  hasConfig: false,
};
export const assessmentDate = {
  label: "Assessment Date",
  value: "assessment_date",
  redirectPage: "details",
  description: "",
  hasConfig: false,
};
export const siteDescription = {
  label: "Site Description",
  value: "site_description",
  redirectPage: "assessment",
  description: "",
  hasConfig: false,
};
export const isNaturalDisaster = {
  label: "Is Natural Disaster",
  value: "is_natural_disaster_damage",
  redirectPage: "assessment",
  description: "",
  hasConfig: false,
};
export const areaOfInsuredLandDamaged = {
  label: "Area of Insured Land Damaged",
  value: "area_of_insured_land_damaged",
  redirectPage: "assessment",
  description: "",
  hasConfig: false,
};

export const inundatedLand = {
  label: "Inundated Land",
  value: "inundated_land",
  redirectPage: "assessment",
  description: "",
  hasConfig: false,
};

export const areaOfInsuredLandAtImminentRisk = {
  label: "Area of Insured Land at Imminent Risk",
  value: "area_of_insured_land_at_imminent_risk",
  redirectPage: "assessment",
  description: "",
  hasConfig: false,
};

export const customerName = {
  label: "Customer Name",
  value: "customer_name",
  redirectPage: "details",
  description: "",
  hasConfig: false,
};

export const insurerClaimNumber = {
  label: "Insurer Claim Number",
  value: "insurer_claim_number",
  redirectPage: "details",
  description: "",
  hasConfig: false,
};

export const eventType = {
  label: "Event Type",
  value: "event_type",
  redirectPage: "details",
  description: "",
  hasConfig: false,
};

export const summaryTable = {
  label: "Summary Table",
  value: "summary_table",
  redirectPage: "assessment",
  description: "Summary of information table",
  hasConfig: true,
};

export const retainingWallTable = {
  label: "Retaining Wall Table",
  value: "retaining_wall_table",
  redirectPage: "assessment",
  description: "Retaining walls table",
  hasConfig: true,
};

export const bridgeAndCulvertTable = {
  label: "Bridges and Culverts Table",
  value: "bridges_and_culverts_table",
  redirectPage: "assessment",
  description: "Bridges and Culverts table",
  hasConfig: true,
};

export const organisationName = {
  label: "Organisation",
  value: "organisation",
  redirectPage: "no-edit",
  description: "",
  hasConfig: false,
};

export const visitDates = {
  label: "Visit Dates",
  value: "visit_dates",
  redirectPage: "assessment",
  description: "",
  hasConfig: false,
};

export const damageTypes = {
  label: "Damage Types",
  value: "damage_types",
  redirectPage: "assessment",
  description: "",
  hasConfig: false,
};

export const eventDate = {
  label: "Event Date",
  value: "event_date",
  redirectPage: "details",
  description: "",
  hasConfig: false,
};

export const groundDetails = {
  label: "Ground Details",
  value: "ground_details",
  redirectPage: "assessment",
  description: "",
  hasConfig: false,
};

export const landslipMaterial = {
  label: "Landslip Material",
  value: "landslip_material",
  redirectPage: "assessment",
  description: "",
  hasConfig: false,
};

export const propertyDamageDetails = {
  label: "Property Damage Details",
  value: "property_damage_details",
  redirectPage: "assessment",
  description: "A summary of all defined property damage",
  hasConfig: true,
};

export const eqcConsiderationDetails = {
  label: "EQC Consideration Details",
  value: "eqc_consideration_details",
  redirectPage: "assessment",
  description: "A summary of all EQC considerations",
  hasConfig: true,
};

export const imminentRiskDetails = {
  label: "Imminent Risk Details",
  value: "imminent_risk_details",
  redirectPage: "assessment",
  description: "A summary of all imminent risks to the property",
  hasConfig: true,
};

export const insurerLegalName = {
  label: "Insurer Legal Name",
  value: "insurer_legal_name",
  redirectPage: "details",
  description: "",
  hasConfig: false,
};

export const conceptualRemedialWorksList = {
  label: "Conceptual Remedial Works List",
  value: "conceptual_remedial_works_List",
  redirectPage: "assessment",
  description:
    "A list of all potential conceptual remedial works that get listed, if applicable",
  hasConfig: true,
};

export const constructionIssuesTable = {
  label: "Construction Issues Table",
  value: "construction_issues_table",
  redirectPage: "assessment",
  description:
    "Difficulty of construction issues, as defined by the assessing engineer",
  hasConfig: true,
};

export const authorName = {
  label: "Author Name",
  value: "author_name",
  redirectPage: "details",
  description: "",
  hasConfig: false,
};

export const reviewerName = {
  label: "Reviewer Name",
  value: "reviewer_name",
  redirectPage: "status-bar",
  description: "",
  hasConfig: false,
};

export const photographQuantity = {
  label: "Photograph Quantity",
  value: "photograph_quantity",
  redirectPage: "assessment",
  description: "",
  hasConfig: false,
};

export const aerialPhotographQuantity = {
  label: "Aerial Photograph Quantity",
  value: "aerial_photograph_quantity",
  redirectPage: "assessment",
  description: "",
  hasConfig: false,
};

export const sketchQuantity = {
  label: "Sketch Quantity",
  value: "sketch_quantity",
  redirectPage: "assessment",
  description: "",
  hasConfig: false,
};

export const pageNumber = {
  label: "Page Number",
  value: "pageNumber",
  description: "Page number",
  redirectPage: "assessment",
  hasConfig: false,
};

export const totalPages = {
  label: "Total Pages",
  value: "totalPages",
  redirectPage: "assessment",
  description: "",
  hasConfig: false,
};

export const landslipNumber = {
  label: "Landslip Number",
  value: "landslip_number",
  redirectPage: "assessment",
  description: "",
  hasConfig: false,
};

export const landslipWidth = {
  label: "Landslip Width",
  value: "landslip_width",
  redirectPage: "assessment",
  description: "",
  hasConfig: false,
};

export const constructionAccessEasy = {
  label: "Construction Access Easy",
  value: "construction_access_easy",
  redirectPage: "assessment",
  description: "",
  hasConfig: false,
};

export const constructionAccessMedium = {
  label: "Construction Access Medium",
  value: "construction_access_medium",
  redirectPage: "assessment",
  description: "",
  hasConfig: false,
};

export const constructionAccessHard = {
  label: "Construction Access Hard",
  value: "construction_access_hard",
  redirectPage: "assessment",
  description: "",
  hasConfig: false,
};

export const constructionAccessNA = {
  label: "Construction Access N/A",
  value: "construction_access_na",
  redirectPage: "assessment",
  description: "",
  hasConfig: false,
};

export const earthworksEasy = {
  label: "Earthworks Easy",
  value: "earthworks_easy",
  redirectPage: "assessment",
  description: "",
  hasConfig: false,
};

export const earthworksMedium = {
  label: "Earthworks Medium",
  value: "earthworks_medium",
  redirectPage: "assessment",
  description: "",
  hasConfig: false,
};

export const earthworksHard = {
  label: "Earthworks Hard",
  value: "earthworks_hard",
  redirectPage: "assessment",
  description: "",
  hasConfig: false,
};

export const earthworksNA = {
  label: "Earthworks N/A",
  value: "earthworks_na",
  redirectPage: "assessment",
  description: "",
  hasConfig: false,
};

export const constructabilityEasy = {
  label: "Constructibility Easy",
  value: "constructability_easy",
  redirectPage: "assessment",
  description: "",
  hasConfig: false,
};

export const constructabilityMedium = {
  label: "Constructibility Medium",
  value: "constructability_medium",
  redirectPage: "assessment",
  description: "",
  hasConfig: false,
};

export const constructabilityHard = {
  label: "Constructibility Hard",
  value: "constructability_hard",
  redirectPage: "assessment",
  description: "",
  hasConfig: false,
};

export const constructabilityNA = {
  label: "Constructibility N/A",
  value: "constructability_na",
  redirectPage: "assessment",
  description: "",
  hasConfig: false,
};

export const serviceName = {
  label: "Service Name",
  value: "service_name",
  redirectPage: "assessment",
  description: "",
  hasConfig: false,
};

export const serviceType = {
  label: "Service Type",
  value: "service_type",
  redirectPage: "assessment",
  description: "",
  hasConfig: false,
};

export const landslipAreaOfInsuredLandDamagedEvacuated = {
  label: "Landslip Evacuated Land",
  value: "landslip_area_of_insured_land_damaged_evacuated",
  redirectPage: "assessment",
  description: "",
  hasConfig: false,
};

export const landslipAreaOfInsuredLandDamagedInundated = {
  label: "Landslip Inundated Land",
  value: "landslip_area_of_insured_land_damaged_inundated",
  redirectPage: "assessment",
  description: "",
  hasConfig: false,
};

export const landslipAreaOfInsuredLandAtImminentRiskEvacuation = {
  label: "Landslip Imminent Risk Evacuated Land",
  value: "landslip_area_of_insured_land_at_imminent_risk_evacuation",
  redirectPage: "assessment",
  description: "",
  hasConfig: false,
};

export const landslipAreaOfInsuredLandAtImminentRiskReInundated = {
  label: "Landslip Imminent Risk Re-inundated Land",
  value: "landslip_area_of_insured_land_at_imminent_risk_re_inundated",
  redirectPage: "assessment",
  description: "",
  hasConfig: false,
};

export const landslipAreaOfInsuredLandAtImminentRiskNewInundated = {
  label: "Landslip Imminent Risk New Inundated land",
  value: "landslip_area_of_insured_land_at_imminent_risk_new_inundated",
  redirectPage: "assessment",
  description: "",
  hasConfig: false,
};

export const landslipAreaOfInsuredLandDamagedOnOrSupportingMainAccessWayEvacuated =
  {
    label: "Landslip Sixty Meters Evacuated Land",
    value:
      "landslip_area_of_insured_land_damaged_on_or_supporting_main_access_way_evacuated",
    redirectPage: "assessment",
    description: "",
    hasConfig: false,
  };

export const landslipAreaOfInsuredLandDamagedOnOrSupportingMainAccessWayInundated =
  {
    label: "Landslip Sixty Meters Inundated Land",
    value:
      "landslip_area_of_insured_land_damaged_on_or_supporting_main_access_way_inundated",
    redirectPage: "assessment",
    description: "",
    hasConfig: false,
  };

export const landslipAreaOfInsuredLandAtImminentRiskOnOrSupportingMainAccessWayEvacuation =
  {
    label: "Landslip Sixty Meters Imminent Risk Evacuated Land",
    value:
      "landslip_area_of_insured_land_at_imminent_risk_on_or_supporting_main_access_way_evacuation",
    redirectPage: "assessment",
    description: "",
    hasConfig: false,
  };

export const landslipAreaOfInsuredLandAtImminentRiskOnOrSupportingMainAccessWayReInundated =
  {
    label: "Landslip Sixty Meters Imminent Risk Re-inundated Land",
    value:
      "landslip_area_of_insured_land_at_imminent_risk_on_or_supporting_main_access_way_re_inundated",
    redirectPage: "assessment",
    description: "",
    hasConfig: false,
  };

export const landslipAreaOfInsuredLandAtImminentRiskOnOrSupportingMainAccessWayNewInundated =
  {
    label: "Landslip Sixty Meters Imminent Risk New Inundated Land",
    value:
      "landslip_area_of_insured_land_at_imminent_risk_on_or_supporting_main_access_way_new_inundated",
    redirectPage: "assessment",
    description: "",
    hasConfig: false,
  };

export const stormAndFloodEvacuatedLand = {
  label: "Storm and Flood Evacuated Land",
  value: "storm_and_flood_evacuated_land",
  redirectPage: "assessment",
  description: "",
  hasConfig: false,
};

export const stormAndFloodInundatedLand = {
  label: "Storm and Flood Inundated Land",
  value: "storm_and_flood_inundated_land",
  redirectPage: "assessment",
  description: "",
  hasConfig: false,
};

export const stormAndFloodEvacuatedLand60m = {
  label: "Storm and Flood Evacuated Land 60m",
  value: "storm_and_flood_evacuated_land_60m",
  redirectPage: "assessment",
  description: "",
  hasConfig: false,
};

export const stormAndFloodInundatedLand60m = {
  label: "Storm and Flood Inundated Land 60m",
  value: "storm_and_flood_inundated_land_60m",
  redirectPage: "assessment",
  description: "",
  hasConfig: false,
};

export const photographs = {
  label: "Photographs",
  value: "photographs",
  redirectPage: "assessment",
  description: "",
  hasConfig: false,
};

export const annotatedAerialPhotographs = {
  label: "Annotated Aerial Photographs",
  value: "annotated_aerial_photographs",
  redirectPage: "assessment",
  description: "",
  hasConfig: false,
};

export const sketches = {
  label: "Sketches",
  value: "sketches",
  redirectPage: "assessment",
  description: "",
  hasConfig: false,
};

export const dwellingDamage = {
  entityIdProperty: "buildingDamageId",
  label: "Dwelling Damage",
  value: "dwelling_damage",
  redirectPage: "assessment",
  description: "",
  hasConfig: false,
};

export const appurtenantStructuresDamage = {
  entityIdProperty: "buildingDamageId",
  label: "Appurtenant Structure Damage",
  value: "appurtenant_structure_damage",
  redirectPage: "assessment",
  description: "",
  hasConfig: false,
};

export const retainingWallDamageLength = {
  entityIdProperty: "retainingWallId",
  label: "Retaining Wall Damage Length",
  value: "retaining_wall_damage_length",
  redirectPage: "assessment",
  description: "",
  hasConfig: false,
};

export const retainingWallNumber = {
  entityIdProperty: "retainingWallId",
  label: "Retaining Wall Number",
  value: "retaining_wall_number",
  redirectPage: "assessment",
  description: "",
  hasConfig: false,
};

export const retainingWallDamageType = {
  entityIdProperty: "retainingWallId",
  label: "Retaining Wall Damage Type",
  value: "retaining_wall_damage_type",
  redirectPage: "assessment",
  description: "",
  hasConfig: false,
};

export const serviceDamageLength = {
  entityIdProperty: "serviceDamageId",
  label: "Service Damage Length",
  value: "service_damage_length",
  redirectPage: "assessment",
  description: "",
  hasConfig: false,
};

export const serviceImminentRiskLength = {
  entityIdProperty: "serviceDamageId",
  label: "Service Imminent Risk Length",
  value: "service_imminent_risk_length",
  redirectPage: "assessment",
  description: "",
  hasConfig: false,
};

export const serviceDamageType = {
  entityIdProperty: "serviceDamageId",
  label: "Service Damage Type",
  value: "service_damage_type",
  redirectPage: "assessment",
  description: "",
  hasConfig: false,
};

export const bridgeCulvertImminentDamageLength = {
  entityIdProperty: "bridgesAndCulvertsId",
  label: "Bridge or Culvert Imminent Damage Length",
  value: "bridge_or_culvert_imminent_damage_length",
  redirectPage: "assessment",
  description: "",
  hasConfig: false,
};

export const bridgeCulvertDamageLength = {
  entityIdProperty: "bridgesAndCulvertsId",
  label: "Bridge or Culvert Damage Length",
  value: "bridge_or_culvert_damage_length",
  redirectPage: "assessment",
  description: "",
  hasConfig: false,
};

export const bridgeCulvertType = {
  entityIdProperty: "bridgesAndCulvertsId",
  label: "Bridge or Culvert Type",
  value: "bridge_or_culvert_type",
  redirectPage: "assessment",
  description: "",
  hasConfig: false,
};

export const bridgeCulvertNumber = {
  entityIdProperty: "bridgesAndCulvertsId",
  label: "Bridge or Culvert Number",
  value: "bridge_culvert_number",
  redirectPage: "assessment",
  description: "",
  hasConfig: false,
};

export const bridgeCulvertDamageType = {
  entityIdProperty: "bridgesAndCulvertsId",
  label: "Bridge or Culvert Damage Type",
  value: "bridge_culvert_damage_type",
  redirectPage: "assessment",
  description: "",
  hasConfig: false,
};

export const retainingWallWholeWallLength = {
  label: "Retaining Wall Whole Wall Length",
  value: "retaining_wall_whole_wall_length",
  redirectPage: "assessment",
  description: "",
  hasConfig: false,
};

export const retainingWallRetainedHeight = {
  label: "Retaining Wall Retained Height",
  value: "retaining_wall_retained_height",
  redirectPage: "assessment",
  description: "",
  hasConfig: false,
};

export const retainingWallDamaged = {
  label: "Retaining Wall Damaged",
  value: "retaining_wall_damaged",
  redirectPage: "assessment",
  description: "",
  hasConfig: false,
};

export const retainingWallImminentDamage = {
  label: "Retaining Wall Imminent Damage",
  value: "retaining_wall_imminent_damage",
  redirectPage: "assessment",
  description: "",
  hasConfig: false,
};

export const retainingWallInsuredWall = {
  label: "Retaining Wall Insured Wall",
  value: "retaining_wall_insured_wall",
  redirectPage: "assessment",
  description: "",
  hasConfig: false,
};

export const retainingWallTotalWall = {
  label: "Retaining Wall Total Wall",
  value: "retaining_wall_total_wall",
  redirectPage: "assessment",
  description: "",
  hasConfig: false,
};

export const retainingWallImminentRiskLength = {
  label: "Retaining Wall Imminent Risk Length",
  value: "retaining_wall_imminent_risk_length",
  redirectPage: "assessment",
  description: "",
  hasConfig: false,
};

export const hasNaturalDisasterText = {
  label: "Has Natural Disaster Text",
  value: "has_natural_disaster_text",
  redirectPage: "assessment",
  description: "",
  hasConfig: false,
};

export const variables: {
  label: string;
  value: string;
  description: string;
  hasConfig: boolean;
  redirectPage: string;
  entityIdProperty?:
    | "bridgesAndCulvertsId"
    | "serviceDamageId"
    | "retainingWallId"
    | "buildingDamageId"
    | "buildingDamageId";
}[] = [
  headerNumber,
  clientId,
  jobNumber,
  address,
  addressRegion,
  addressRegionEx,
  customerName,
  dateExported,
  engagedName,
  lossAdjusterEmail,
  lossAdjusterName,
  lossAdjusterCompanyName,
  assessmentDate,
  siteDescription,
  isNaturalDisaster,
  areaOfInsuredLandDamaged,
  inundatedLand,
  areaOfInsuredLandAtImminentRisk,
  summaryTable,
  insurerCompany,
  dateExported,
  modifiedDate,
  insurerClaimNumber,
  eventType,
  organisationName,
  landslipNumber,
  landslipWidth,
  visitDates,
  damageTypes,
  eventDate,
  groundDetails,
  landslipMaterial,
  propertyDamageDetails,
  eqcConsiderationDetails,
  imminentRiskDetails,
  insurerLegalName,
  conceptualRemedialWorksList,
  constructionIssuesTable,
  authorName,
  reviewerName,
  photographQuantity,
  aerialPhotographQuantity,
  sketchQuantity,
  constructionAccessEasy,
  constructionAccessMedium,
  constructionAccessHard,
  constructionAccessNA,
  earthworksEasy,
  earthworksMedium,
  earthworksHard,
  earthworksNA,
  constructabilityEasy,
  constructabilityMedium,
  constructabilityHard,
  constructabilityNA,
  hasNaturalDisasterText,
  dwellingDamage,
  appurtenantStructuresDamage,
  retainingWallDamageLength,
  retainingWallNumber,
  retainingWallDamageType,
  retainingWallImminentRiskLength,
  serviceDamageLength,
  serviceDamageType,
  bridgeCulvertDamageLength,
  bridgeCulvertImminentDamageLength,
  bridgeCulvertNumber,
  bridgeCulvertDamageType,
  serviceImminentRiskLength,
  landslipAreaOfInsuredLandDamagedEvacuated,
  landslipAreaOfInsuredLandDamagedInundated,
  landslipAreaOfInsuredLandAtImminentRiskEvacuation,
  landslipAreaOfInsuredLandAtImminentRiskReInundated,
  landslipAreaOfInsuredLandAtImminentRiskNewInundated,
  landslipAreaOfInsuredLandDamagedOnOrSupportingMainAccessWayEvacuated,
  landslipAreaOfInsuredLandDamagedOnOrSupportingMainAccessWayInundated,
  landslipAreaOfInsuredLandAtImminentRiskOnOrSupportingMainAccessWayEvacuation,
  landslipAreaOfInsuredLandAtImminentRiskOnOrSupportingMainAccessWayReInundated,
  landslipAreaOfInsuredLandAtImminentRiskOnOrSupportingMainAccessWayNewInundated,
  retainingWallTable,
  bridgeAndCulvertTable,
  bridgeCulvertType,
  retainingWallDamaged,
  retainingWallImminentDamage,
  retainingWallInsuredWall,
  retainingWallTotalWall,
  retainingWallWholeWallLength,
  retainingWallRetainedHeight,
  serviceName,
  serviceType,
  photographs,
  annotatedAerialPhotographs,
  sketches,
  pageNumber,
  totalPages,
];
