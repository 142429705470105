import { z } from "zod";

const preprocessedNumber = z.preprocess((val) => Number(val), z.number());

export const EventServiceLevelAgreementSchema = z.object({
  eventId: z.string().optional(),
  organisationId: z.string().optional(),
  eventServiceLevelAgreementId: z.string().optional(),
  insUpdTs: z.string().datetime().optional(),
  created: z.string().datetime().optional(),
  serviceLevelAgreement: z.object({
    intialCustomerContact: preprocessedNumber,
    intialCustomerContactUrgent: preprocessedNumber,
    siteVisitCompleted: preprocessedNumber,
    siteVisitCompletedUrgent: preprocessedNumber,
    siteReportCompleted: preprocessedNumber,
    siteReportCompletedUrgent: preprocessedNumber,
  }),
});

export type EventServiceLevelAgreement = z.infer<
  typeof EventServiceLevelAgreementSchema
>;
