import { SxProps, Typography } from "@mui/material";
import Box from "@mui/system/Box";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import {
  Control,
  Controller,
  FieldValues,
  Path,
  PathValue,
} from "react-hook-form";
import { parseISO } from "date-fns";

interface FormDatePickerProps<T extends FieldValues> {
  name: Path<T>;
  control: Control<T>;
  label: string;
  defaultValue?: PathValue<T, Path<T>> | undefined;
  sx?: SxProps;
  error?: boolean;
  helperText?: string;
}

export function FormDatePicker<T extends FieldValues>(
  props: FormDatePickerProps<T>
) {
  const { control, label, name, defaultValue, sx, error, helperText } = props;
  return (
    <Controller
      defaultValue={defaultValue}
      name={name}
      control={control}
      render={({ field: { onChange, value } }) => {
        const date = value ? parseISO(value) : null;
        return (
          <Box>
            <Typography
              variant="subtitle2"
              sx={{
                color: error ? "#d32f2f" : "#333",
                fontSize: "14px",
                lineHeight: "1.4375em",
                paddingBottom: "0px",
                marginBottom: "0",
              }}
            >
              {label}
            </Typography>
            <DesktopDatePicker
              onChange={(event) => {
                onChange(event?.toISOString());
              }}
              value={date}
              sx={{ width: "100%", ...sx }}
              slotProps={{
                textField: {
                  error,
                  helperText,
                },
              }}
            />
          </Box>
        );
      }}
    />
  );
}
